import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  setNumberToCollectionField,
} from '../../../_helpers';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * Key performance indicators others than product indicators or other than result indicators element.
 *
 * @returns {KpiOtherThanProductOrResultIndicators}
 */
export function KpiOtherThanProductOrResultIndicators() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();

  const {
    control, setValue,
  } = useFormContext();

  const collectionFieldName = 'kpi_other_than_product_or_result_indicators';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const config = fieldsConfig[collectionFieldName];
  const subfields = config.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  /**
   * Removes item from kpi_other_than_product_or_result_indicators collection.
   *
   * @param {number} index - field index
   */
  const removeItem = (index) => {
    remove(index);
    setNumberToCollectionField(
      collectionFieldName,
      index,
      setValue,
      fields,
    );
  };

  useEffect(() => {
    if (fields.length === 0) {
      append(fieldObject);
    }
  });

  return (
    <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
      {fields.map((item, index) => (
        <CollectionFieldContainer
          onRemove={removeItem}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={item.id}
          buttonRemoveVisible={fields.length > 1}
          isIndexed
        >
          {/* Nazwa KPI */}
          <FormField name={`${collectionFieldName}.${index}.kpi_name`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                multiline
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>

          {/* Opis KPI */}
          <FormField name={`${collectionFieldName}.${index}.kpi_description`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                multiline
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>

          {/* Rok osiągnięcia wartości docelowej */}
          <FormField name={`${collectionFieldName}.${index}.year_of_achievement_of_the_target_value`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                views={['year']}
                ariaLabel={label}
                format="yyyy"
                mask="____"
                placeholder="rrrr"
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          {/* Opis sposobu weryfikacji osiągnięcia KPI */}
          <FormField name={`${collectionFieldName}.${index}.description_of_how_to_verify_the_kpi_achievement`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                multiline
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}

      {!isReadonly && (
        <Grid item xs={12}>
          <Button
            id="xaRMZRdlfT7ovg6"
            variant="contained"
            color="secondary"
            onClick={() => append(fieldObject)}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
