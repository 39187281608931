import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import {
  useGlobalContext,
  useGlobalDialog,
  useReloadListener,
} from '../../../Context';
import { ContainerLoader } from '../../../Features/Application/Application/ContainerLoader';
import { HrfValidationConfiguratorForm } from './HrfValidationConfigurator.form';

/**
 * Hrf validation configurator dialog.
 *
 * @param {object} props - root props
 * @param {string} props.applicationTemplateId - application template id
 * @returns {HrfValidationConfigurator}
 */
export function HrfValidationConfigurator({ applicationTemplateId }) {
  const { notify } = useGlobalContext();
  const [projectBudgetLimits, setProjectBudgetLimits] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const { watch } = useReloadListener();
  const watcherName = 'hrf-validation-configurator';
  const { closeAll } = useGlobalDialog();

  const fetchProjectBudgetLimits = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.projectBudgetLimits
      .replace(':applicationTemplateId', applicationTemplateId));

    if (statusSuccess) {
      setProjectBudgetLimits(payload);
      setLoaded(true);

      return;
    }

    notify('Nie udało się wczytać wymaganych danych', 'error');
    closeAll();
  };

  useEffect(() => {
    fetchProjectBudgetLimits();
  }, [watch(watcherName)]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <HrfValidationConfiguratorForm
      projectBudgetLimitsData={projectBudgetLimits}
      applicationTemplateId={applicationTemplateId}
    />
  );
}

HrfValidationConfigurator.propTypes = {
  applicationTemplateId: PropTypes.string.isRequired,
};
