import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';

/**
 * Venture feasibility pdf element.
 *
 * @returns {VentureFeasibilityPdf}
 */
function VentureFeasibilityPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="venture_feasibility_does_applicant_include_development_costs" />
        {initialFormData?.venture_feasibility_does_applicant_include_development_costs === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField name="venture_feasibility_date_and_type_confirming_document" />
            <RowField name="venture_feasibility_contractor_name" isSingleRow />
            <RowField name="venture_feasibility_contractor_selecting_method" isSingleRow />
          </>
        )}
        <RowField name="venture_feasibility_payment_date" />
        <RowField name="venture_feasibility_venture_analysis_scope" isSingleRow />
        <RowField name="venture_feasibility_socio_economic_analysis" isSingleRow />
        <RowField name="venture_feasibility_institutional_and_legal_analysis" isSingleRow />
        <RowField name="venture_feasibility_technical_and_technological_analysis" isSingleRow />
        <RowField name="venture_feasibility_financial_analysis" isSingleRow />
        <RowField name="venture_feasibility_venture_financing_plan" isSingleRow />
        <RowField name="venture_feasibility_economic_or_environmental_effect" isSingleRow />
        <RowField name="venture_feasibility_effects_justification" isSingleRow />
        <RowField name="venture_feasibility_venture_assessment_success_chances" isSingleRow />
        <RowField name="venture_feasibility_venture_risks_assessment" isSingleRow />
        <RowField name="venture_feasibility_venture_advantages" isSingleRow />
        <RowField name="venture_feasibility_venture_disadvantages" isSingleRow />
        <RowField name="venture_feasibility_venture_alternative_variants_analysis" isSingleRow />
        <CollectionWrapper name="venture_feasibility_economic_or_environmental_effects">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.table}>
                  <RowField
                    name={`${collectionName}.${index}.economic_or_environmental_effect`}
                    value={item.economic_or_environmental_effect}
                    oldValue={previousRowData?.economic_or_environmental_effect}
                    dictionaryName={DICTIONARIES.ventureFeasibilityEconomicOrEnvironmentalEffect.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.effect_justification`}
                    value={item.effect_justification}
                    oldValue={previousRowData?.effect_justification}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default VentureFeasibilityPdf;
