import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { DICTIONARIES } from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';

/**
 *  GozTransformationImplementation element.
 *
 * @returns { GozTransformationImplementation }
 */
export function GozTransformationImplementation() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();

  const actionPath = 'goz_transformation_implementation_action_path';
  const {
    fields: actionPathFields, append: actionPathAppend, remove: actionPathRemove,
  } = useFieldArray({
    control,
    name: actionPath,
  });
  const collectionName = 'goz_transformation_implementation_product_life_cycle_processes';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.gozTransformationImplementationActionPath,
    DICTIONARIES.gozTransformationImplementationProductProcess,
  );

  const actionPathConfig = fieldsConfig[actionPath];
  const actionPathSubfields = actionPathConfig?.fields || {};
  const actionPathFieldObject = getCollectionFieldObject(actionPathSubfields, actionPathFields);

  const productLifeCycleProcessesConfig = fieldsConfig[collectionName];
  const subfields = productLifeCycleProcessesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber: actionPathMaxRowNumber,
    minRowNumber: actionPathMinRowNumber,
  } = actionPathConfig || {};

  const {
    maxRowNumber,
    minRowNumber,
  } = productLifeCycleProcessesConfig || {};

  useEffect(() => {
    if (actionPathFields.length === 0 && actionPathMinRowNumber > 0) {
      for (let i = 1; i <= actionPathMinRowNumber; i++) {
        actionPathAppend(getCollectionFieldObject(actionPathSubfields, actionPathFields, i));
      }
    }
  }, [actionPathMaxRowNumber, actionPathMinRowNumber]);

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {/* Tabela: Ścieżka działania ujęta w schemacie ReSOLVE */}
      <LabeledFieldRow fieldConfig={actionPathConfig} contextHelpLabel fullWidth>
        {actionPathFields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              actionPathFields,
              actionPathMinRowNumber,
              actionPathRemove,
              setValue,
              actionPath,
              index,
            )}
            buttonRemoveVisible={actionPathFields.length > actionPathMinRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(actionPathSubfields)}
            key={item.id}
            isIndexed
          >
            {/* Ścieżka działania ujęta w schemacie ReSOLVE, w którą wpisuje się proces wdrożenia rozwiązań
            przedstawionych w modelu biznesowym GOZ-transformacji */}
            <FormField name={`${actionPath}.${index}.action_path`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.gozTransformationImplementationActionPath.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Uzasadnienie wskazanej ścieżki */}
            <FormField name={`${actionPath}.${index}.justification_indicated_path`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && actionPathFields.length < actionPathMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="zTQAxtm3nTrp3bh"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                actionPathFields,
                actionPathMaxRowNumber,
                actionPathAppend,
                actionPathFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>

      {/* Tabela: Procesy cyklu życia produktu (wyrobu/usługi) */}
      <LabeledFieldRow
        fieldConfig={productLifeCycleProcessesConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={item.id}
            isIndexed
          >
            {/* Projekt obejmuje wybrane procesy cyklu życia produktu (wyroby/usługi) */}
            <FormField name={`${collectionName}.${index}.product_life_cycle_processes`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={filterOptions(get(DICTIONARIES.gozTransformationImplementationProductProcess.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Uzasadnienie wybranego procesu */}
            <FormField name={`${collectionName}.${index}.justification_selected_process`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="teEwOKmLTvsGSAv"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </>
  );
}
