import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  useElementContext,
  useApplicationContext,
} from '../../../../Context';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getOwnIndicators,
} from '../../../../_helpers';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  MODULE_IRIS,
} from '../../../../_constants';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';

/**
 * Innovation of the subject of implementation component
 *
 * @returns {InnovationImplementation}
 */
function InnovationImplementation() {
  const {
    control, watch, setValue, getValues,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    managedApplicationTemplate: {
      elementsConfig,
    },
  } = useApplicationContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.innovationTypes);

  const innovationsTypeValue = watch('innovation_implementation_module_essence_innovation_type');
  const collectionFieldName = 'innovation_implementation_module_essence_rd_result_novelty';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const innovationImplementationModuleEssenceRdResultNoveltyConfig = fieldsConfig[collectionFieldName];
  const subfields = innovationImplementationModuleEssenceRdResultNoveltyConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = innovationImplementationModuleEssenceRdResultNoveltyConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const noOptionsText = 'Przed wypełnieniem pola, dodaj w elemencie „Wskaźniki dot. modułu Wdrożenie '
    + 'innowacji” wskaźniki własne';

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            collectionFieldName,
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed
        >
          {/* Rodzaj innowacji */}
          <FormField name={`${collectionFieldName}.${index}.rd_result_novelty`} contextHelpLabel>
            {() => null}
          </FormField>
          {/* Rodzaj innowacji */}
          <FormField name={`${collectionFieldName}.${index}.innovation_type`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.innovationTypes.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Nazwa produktu / procesu biznesowego */}
          <FormField name={`${collectionFieldName}.${index}.product_name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Czy produkt / proces biznesowy będzie wdrażany w ramach modułu „Wdrożenie innowacji */}
          <FormField name={`${collectionFieldName}.${index}.innovation_implementation_module_essence_work_types`}>
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Poziom nowości */}
          <FormField name={`${collectionFieldName}.${index}.novelty_level`}>
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Opis innowacji produktowej / innowacji w procesie biznesowym */}
          <FormField name={`${collectionFieldName}.${index}.product_innovation_description`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Wskaźniki dotyczące cech/ funkcjonalności innowacji produktowej/ innowacji w procesie biznesowym */}
          <FormField name={`${collectionFieldName}.${index}.indicators_in_the_business_process`}>
            {({
              onChange, value, name, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                options={getOwnIndicators(elementsConfig, getValues, value, MODULE_IRIS.innovationImplementation)}
                disabled={isReadonly}
                optionsMapKeys={['id', 'name']}
                multiple
                noOptionsText={
                  getOwnIndicators(elementsConfig, getValues, value, MODULE_IRIS.innovationImplementation)
                    .length === 0
                    ? noOptionsText
                    : 'Brak opcji'
                }
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
          {/* Cecha / funkcjonalność innowacji produktowej / innowacji w procesie biznesowym */}
          <FormField name={`${collectionFieldName}.${index}.product_functionality`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Korzyść / przewaga */}
          <FormField name={`${collectionFieldName}.${index}.advantage`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Parametry techniczne */}
          <FormField name={`${collectionFieldName}.${index}.technical_parameters`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Znaczenie cechy dla odbiorców produktu */}
          <FormField name={`${collectionFieldName}.${index}.product_feature_emphasis_for_recipients`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && innovationsTypeValue.length === 0
        ? <Typography>Proszę uzupewnić pole &quot;Rodzaj innowacji&quot;</Typography>
        : fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="IP4S5K22ISR2bTh"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
    </>
  );
}

export default InnovationImplementation;
