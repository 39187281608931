import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import {
  useState,
  useMemo,
} from 'react';
import { formatDateString } from '../../../../_helpers';
import MaterialAndFinancialScheduleDialog from './MaterialAndFinancialSchedule.dialog';
import DeleteTaskDialog from './DeleteTask.dialog';
import ExpensesDialog from './Expenses/Expenses.dialog';
import { useElementContext } from '../../../../Context';
import ActualExpensesTable from './Expenses/ActualExpenses.table';
import FlatRateExpensesTable from './Expenses/FlatRateExpenses.table';
import { useApplicationTemplateStyles } from '../../../../styles/applicationTemplate.styles';
import {
  API_ROUTE,
  DATE,
  DICTIONARIES,
  EXPENSES_TYPE,
} from '../../../../_constants';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import ExpandableText from '../../../ExpandableText';

/**
 * Material and financial schedule table
 *
 * @param {object} props - root props
 * @param {object} props.data - task object
 * @param {string} props.itemIndex - task index
 * @param {Function} props.reloadData - reload data from API
 * @param {object} props.expensesSummaryRef - expenses summary ref
 * @param {Array} props.actualExpensesData - actual expenses data
 * @param {Array} props.lumpSumExpensesData - lumpSum expenses data
 * @param {Function} props.reloadExpensesData - reload optional expenses data from API
 * @param {string} props.reloadActualExpensesDataListener - actualExpenses grid reload listener
 * @param {string} props.reloadLumpSumExpensesDataListener - lumpSumExpenses grid reload listener
 * @returns {MaterialAndFinancialScheduleTable}
 */
function MaterialAndFinancialScheduleTable({
  data,
  itemIndex,
  reloadData,
  expensesSummaryRef,
  actualExpensesData,
  lumpSumExpensesData,
  reloadActualExpensesDataListener,
  reloadLumpSumExpensesDataListener,
  reloadExpensesData,
}) {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [expensesDialog, setExpensesDialog] = useState(false);

  const {
    id: elementId, fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    {
      name: `taskTypeForElement-${elementId}`,
      path: `${API_ROUTE.taskType}?templateElement.id=${elementId}`,
    },
    {
      name: `costCategoryForElement-${elementId}`,
      path: `${API_ROUTE.elementDictionaries.costCategories}?templateElement.id=${elementId}&itemsPerPage=100`,
    },
    DICTIONARIES.lumpSumCostNames,
  );

  const taskTypeForElement = get(`taskTypeForElement-${elementId}`);
  const costCategoryForElement = get(`costCategoryForElement-${elementId}`);
  const lumpSumCostNames = get(DICTIONARIES.lumpSumCostNames.name);
  const materialAndFinancialScheduleConfig = fieldsConfig[`tasks-${elementId}`];
  const subfields = materialAndFinancialScheduleConfig?.fields || {};

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    task_id: false,
    task_type: Boolean(subfields.task_type),
    data_rozpoczecia_zadania: Boolean(subfields.data_rozpoczecia_zadania),
    data_zakonczenia_zadania: Boolean(subfields.data_zakonczenia_zadania),
  });

  const { currentElementFieldsConfig } = useElementContext();

  const isActualExpenses = !!currentElementFieldsConfig?.find((element) => element.name === 'actual_expenses')?.name;
  const isLumpSumExpenses = !!currentElementFieldsConfig?.find((element) => element.name === 'lump_sum_expenses')?.name;

  const expensesDataLength = [
    ...actualExpensesData, ...lumpSumExpensesData].filter((item) => item.task_id === data.task_id).length;

  const ROW_TYPE_DESCRIPTION = 'ROW_TYPE_DESCRIPTION';

  const handleOpenExpensesDialog = () => {
    setExpensesDialog((prevState) => !prevState);
  };

  const handleModal = () => {
    setModal((prevState) => !prevState);
  };

  const getTransformedData = () => {
    const transformedTask = [];

    transformedTask.push(data);
    if (subfields?.opis_i_uzasadnienie) {
      transformedTask.push({
        id: uniqid(),
        type: ROW_TYPE_DESCRIPTION,
        label: subfields?.opis_i_uzasadnienie?.label,
        desc: data.opis_i_uzasadnienie,
      });
    }

    if (subfields?.planned_works_description) {
      transformedTask.push({
        id: uniqid(),
        type: ROW_TYPE_DESCRIPTION,
        label: subfields?.planned_works_description?.label,
        desc: data.planned_works_description,
      });
    }

    return transformedTask;
  };

  const getLabelForTaskType = (value) => taskTypeForElement.find((category) => category['@id'] === value)?.name || '';

  /**
   * Returns row actions
   *
   * @param {object} props - root props
   * @param {object} props.row - row data
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => [
    <Button
      id={uniqid()}
      type="button"
      variant="contained"
      color="secondary"
      onClick={handleModal}
    >
      {isReadonly ? 'Podgląd' : 'Edytuj'}
    </Button>,
    <>
      {!isReadonly && (
        <Button
          id={uniqid()}
          type="button"
          variant="outlined"
          onClick={() => setDeleteModal(
            <DeleteTaskDialog
              isOpen
              taskId={row.task_id}
              taskName={row.name}
              closeHandler={() => setDeleteModal(null)}
              reloadData={(value) => {
                reloadData(value);
                reloadExpensesData(value, isActualExpenses
                  ? EXPENSES_TYPE.actualExpenses : EXPENSES_TYPE.flatRateExpenses);
              }}
              expensesSummaryRef={expensesSummaryRef}
            />
          )}
        >
          Usuń
        </Button>
      )}
    </>,
  ];

  const columns = [
    {
      field: 'task_id',
    },
    {
      field: 'name',
      headerName: 'Nazwa zadania',
      flex: 2,
      sortable: false,
      filterable: false,
      colSpan: ({ row }) => {
        if (row.type === ROW_TYPE_DESCRIPTION) {
          return 5;
        }

        return undefined;
      },
      renderCell: ({
        value, row,
      }) => {
        if (row.type === ROW_TYPE_DESCRIPTION) {
          return (
            <Box>
              <Typography mt={3} mb={3} fontWeight="bold">
                {row.label}
              </Typography>
              {row.desc?.length > 200
                ? <ExpandableText variant="text" shortText={row.desc.slice(0, 200)} fullText={row.desc} />
                : <Typography mb={3} component="p">{row.desc}</Typography>}
            </Box>
          );
        }

        return value;
      },
    },
    {
      field: 'data_rozpoczecia_zadania',
      headerName: 'Data rozpoczęcia',
      valueFormatter: ({ value }) => formatDateString(value, DATE.defaultFormatWithoutTime),
      flex: 0.8,
      sortable: false,
      filterable: false,
    },
    {
      field: 'data_zakonczenia_zadania',
      headerName: 'Data zakończenia',
      valueFormatter: ({ value }) => formatDateString(value, DATE.defaultFormatWithoutTime),
      flex: 0.8,
      sortable: false,
      filterable: false,
    },
    {
      field: 'task_type',
      headerName: 'Rodzaj zadania',
      flex: 0.8,
      valueFormatter: ({ value }) => (value ? getLabelForTaskType(value) : ''),
      sortable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      sortable: false,
      headerName: 'Akcje',
      width: 200,
      getActions: getRowActions,
    },
  ];

  const memoizedDataGrid = useMemo(() => (
    <DataGrid
      columns={columns}
      rows={getTransformedData()}
      rowCount={2}
      autoHeight
      hideFooterPagination
      hideFooter
      getRowId={(row) => row?.task_id || row?.id}
      disableColumnMenu
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      sx={{
        fontSize: '1rem',
        ...useApplicationTemplateStyles.table,
      }}
    />
  ), [data, isLoaded]);

  return (
    <Grid container spacing={1} sx={useApplicationTemplateStyles.collectionFieldContainer}>
      <Grid item xs={12}>
        <Typography variant="pageHeading" textAlign="center">
          {`Zadanie: ${data.name}`}
        </Typography>
        {(!isReadonly && (isActualExpenses || isLumpSumExpenses)) && (
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              id="yIK7XLau62h9tK5"
              color="success"
              variant="contained"
              onClick={handleOpenExpensesDialog}
            >
              Dodaj wydatek
            </Button>
          </Box>
        )}
        {memoizedDataGrid}
      </Grid>
      <Box display="flex" justifyContent="flex-end" flexWrap="wrap" sx={{ width: '100%' }}>
        <Grid item xs={11}>
          <ActualExpensesTable
            reloadDataListener={reloadActualExpensesDataListener}
            taskId={data.task_id}
            taskName={data.name}
            reloadExpensesData={reloadExpensesData}
            indirectCosts={data.indirect_costs}
            taskType={getLabelForTaskType(data.task_type)}
            expensesData={actualExpensesData}
            costCategoryForElement={costCategoryForElement}
          />
        </Grid>
        <Grid item xs={11}>
          <FlatRateExpensesTable
            reloadDataListener={reloadLumpSumExpensesDataListener}
            taskId={data.task_id}
            taskName={data.name}
            reloadExpensesData={reloadExpensesData}
            indirectCosts={data.indirect_costs}
            taskType={getLabelForTaskType(data.task_type)}
            expensesData={lumpSumExpensesData}
            costCategoryForElement={costCategoryForElement}
            lumpSumCostNames={lumpSumCostNames}
          />
        </Grid>
      </Box>
      {modal && (
        <MaterialAndFinancialScheduleDialog
          reloadData={reloadData}
          isOpen
          closeModal={handleModal}
          itemIndex={itemIndex}
        />
      )}
      {deleteModal}
      {expensesDialog && (
        <ExpensesDialog
          handleClose={() => setExpensesDialog((prevState) => !prevState)}
          isOpen={expensesDialog}
          taskName={data.name}
          taskId={data.task_id}
          taskType={getLabelForTaskType(data.task_type)}
          indirectCosts={data.indirect_costs}
          reloadExpensesData={reloadExpensesData}
          expensesDataLength={expensesDataLength}
        />
      )}
    </Grid>
  );
}

MaterialAndFinancialScheduleTable.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  itemIndex: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  expensesSummaryRef: PropTypes.objectOf(Object).isRequired,
  actualExpensesData: PropTypes.arrayOf(Object).isRequired,
  lumpSumExpensesData: PropTypes.arrayOf(Object).isRequired,
  reloadExpensesData: PropTypes.func.isRequired,
  reloadActualExpensesDataListener: PropTypes.string.isRequired,
  reloadLumpSumExpensesDataListener: PropTypes.string.isRequired,
};

export default MaterialAndFinancialScheduleTable;
