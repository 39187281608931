import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { homeCardsStyleClasses } from '../styles/homeCards';
import {
  HOME_CARDS_CONFIG,
  PROFILES_TRANSLATION,
} from '../_constants';
import { useAuth } from '../_security';

/**
 * Layout of home cards designed for faster navigation
 *
 * @param {object} props - root props
 * @param {object} props.customCardStyles - specify card's look
 * @returns {HomeCards}
 */
function HomeCards({ customCardStyles }) {
  const classes = homeCardsStyleClasses;
  const navigate = useNavigate();
  const { profile } = useAuth();

  return (
    <Grid container>
      <Grid item xs />
      <Grid item xs={12} xl={8}>
        <Divider sx={classes.overHeading} />
        <Typography
          variant="pageHeading"
          sx={classes.sectionHeading}
        >
          {PROFILES_TRANSLATION[profile]}
        </Typography>
        <Stack
          id="UH6spvTc3XP82FA"
          sx={classes.cards}
          direction="row"
          justifyContent="space-evenly"
        >
          {HOME_CARDS_CONFIG[profile].map(({
            id, label, icon: CardIcon, url,
          }) => (
            <Card
              key={id}
              sx={{
                ...classes.card,
                ...customCardStyles,
              }}
            >
              <CardContent onClick={() => navigate(url)}>
                <CardIcon style={classes.cardIcon} />
                <div style={classes.cardNameBlock}>
                  <span style={classes.cardName}>{label}</span>
                </div>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

export default HomeCards;

HomeCards.propTypes = {
  customCardStyles: PropTypes.instanceOf(Object),
};

HomeCards.defaultProps = {
  customCardStyles: {},
};
