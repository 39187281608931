import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  API_ROUTE,
  HELPCENTER_LIST,
} from '../../_constants';
import CharsCounter from '../CharsCounter';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { styleClassesCommon } from '../../Dialogs/ApplicationTemplate/Common.style';
import { request } from '../../_services';
import { AttachmentsBox } from '../FileUpload';
import { ImprovedMaskedInput } from '../ImprovedMaskedInput';
import { useGlobalContext } from '../../Context';
import { handleError } from '../../_helpers';

/**
 * Help center form
 *
 * @param {object} props - root props
 * @param {object} props.initialData - initialData form data
 * @param {Function} props.closeHandler - function to invoke on close/cancel
 * @returns {HelpCenterForm}
 */
export function HelpCenterForm({
  initialData, closeHandler,
}) {
  const {
    control, handleSubmit, setValue, getValues, setError,
  } = useForm({
    defaultValues: {
      id: initialData?.id || '',
      title: initialData?.title || '',
      ordinalNumber: initialData?.ordinalNumber || 0,
      articleUrl: initialData?.articleUrl || '',
      justification: initialData?.justification || '',
      visibleOn: initialData?.visibleOn || [],
      attachments: initialData?.attachments || [],
    },
    mode: 'all',
  });
  const { notify } = useGlobalContext();
  const classes = styleClassesCommon;

  const submitExistingMessage = (payload) => (
    request.put(
      `${API_ROUTE.helpCenter}/${payload.id}`,
      payload
    )
  );

  const submitNewMessage = (payload) => (
    request.post(
      API_ROUTE.helpCenter,
      payload
    )
  );

  const submitHandler = async (data) => {
    const payload = {
      ...data,
      ordinalNumber: parseInt(data.ordinalNumber, 10),
    };
    if (data.articleUrl || data.justification) {
      const {
        statusSuccess, violations,
      } = (payload.id)
        ? await submitExistingMessage(payload)
        : await submitNewMessage(payload);

      if (statusSuccess) {
        notify('Zapisano nowy kafelek pomocy.', 'success');

        closeHandler();
      }

      handleError(setError, violations);

      return;
    }
    setError('justification', {
      type: 'manual',
      message: 'Proszę o uzupełnienia jednego z pól: "Opis pomocy" lub "Link do artykułu"',
    });
  };

  const checkboxChangeHandler = (checkboxValue) => {
    const currentValues = getValues('visibleOn') || [];
    const newSelected = currentValues.includes(checkboxValue)
      ? currentValues.filter((item) => item !== checkboxValue)
      : currentValues.concat(checkboxValue);

    setValue('visibleOn', newSelected, { shouldValidate: true });
  };

  const createMsg = (name) => `${name}: proszę uzupełnić pole`;

  return (
    <>
      <h2 style={classes.tableHeaderTitle}>
        {`${initialData ? 'Edytuj' : 'Dodaj'} kafelek pomocy`}
      </h2>
      <Grid container justifyContent="center" mx="auto" aria-colcount={2} spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="ordinalNumber" required>
            Kolejność
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="ordinalNumber"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: createMsg('Kolejność'),
                },
              }}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  id={name}
                  value={value?.toString()}
                  type="number"
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ inputComponent: ImprovedMaskedInput }}
                  inputProps={{ mask: Number }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="title" required>
            Tytuł
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: createMsg('Tytuł'),
                },
              }}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  name={name}
                  id={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value?.length} /> }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="articleUrl">
            Link do artykułu
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="articleUrl"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  <TextField
                    multiline
                    name={name}
                    id={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value?.length} maxLength={500} /> }}
                    inputProps={{ maxLength: 500 }}
                  />
                  {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                </>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="justification">
            Opis pomocy
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="justification"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  <CKEditor
                    id={name}
                    editor={ClassicEditor}
                    data={value}
                    onChange={(_event, editor) => {
                      onChange({
                        target: {
                          name,
                          value: editor.getData(),
                        },
                      });
                    }}
                  />
                  {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                </>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="attachments">
            Dodaj ikonę
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="attachments"
              control={control}
              rules={{
                validate: {
                  exactOne: (fieldValue) => fieldValue?.length === 1
                      || 'Ikona: proszę dodać 1 plik',
                },
              }}
              render={({
                field: {
                  value, name, onChange,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  <AttachmentsBox
                    config={{
                      fileUploadExtensions: [
                        'svg',
                      ],
                      fileUploadExtensionMap: {
                        svg: [
                          'image/svg',
                          'image/svg+xml',
                        ],
                      },
                      fileUploadMaxSize: 5000000,
                    }}
                    minAttachments={1}
                    maxAttachments={1}
                    allowedExtensions={[
                      'svg',
                    ]}
                    maxSize={5000000}
                    uploadedFilesCallback={(uploadedFiles) => {
                      onChange({
                        target: {
                          name,
                          value: uploadedFiles.map(({ iri }) => iri),
                        },
                      });
                    }}
                    existingFiles={value}
                    apiUrl={API_ROUTE.attachmentsAuth}
                    fetchUrl={`${initialData?.id
                      ? API_ROUTE.helpCenterAttachment.replace(':id', initialData?.id)
                      : ''}`}
                  />
                  {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3} display="flex" justifyContent="flex-start" alignItems="center">
          <InputLabel htmlFor="visibleOn" required>
            Gdzie wyświetlane
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="visibleOn"
              control={control}
              rules={{
                validate: {
                  atLeastOne: (val) => val?.length > 0
                  || 'Gdzie wyświetlane: proszę wybrać przynajmniej jedną z opcji',
                },
              }}
              render={({
                field: {
                  value,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  {HELPCENTER_LIST.map((checkbox) => (
                    <FormControlLabel
                      key={checkbox.value}
                      name={checkbox.value}
                      id={checkbox.value}
                      control={(
                        <Checkbox
                          onChange={() => checkboxChangeHandler(checkbox.value)}
                          checked={value?.includes(checkbox.value)}
                        />
                      )}
                      label={checkbox.text}
                    />
                  ))}
                  {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <SaveCancelButtons
            saveHandler={handleSubmit(submitHandler)}
            cancelHandler={closeHandler}
            saveButtonId="If3JHInJMFNnIpv"
            cancelButtonId="Ddaud59xq2FKXEH"
          />
        </Grid>
      </Grid>
    </>
  );
}

HelpCenterForm.propTypes = {
  initialData: PropTypes.instanceOf(Object),
  closeHandler: PropTypes.func.isRequired,
};

HelpCenterForm.defaultProps = {
  initialData: null,
};
