import { darken } from '@mui/material/styles';
import { theme } from '../../styles/theme';

export const LoginByEmailStyleClasses = {
  loginByEmailHeader: {
    fontSize: '2rem',
    marginBottom: theme.spacing(2),
  },
  jumboBtn: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  loginByEmailPaper: {
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(16),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  loginByEmailBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
    fontSize: '1.3em',
    lineHeight: '3rem',
    textTransform: 'inherit',
    borderWidth: '3px',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
  cancelBtn: {
    padding: '0',
    minWidth: '24px',
  },
  helperLink: {
    display: 'inline-block',
    width: '100%',
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    textAlign: 'right',
    fontSize: '0.75rem',
    textDecoration: 'none',
    color: theme.palette.text.brand,
  },
  linkWithBtn: {
    display: 'block',
    textDecoration: 'none !important',
  },
  registerBtn: {
    marginTop: theme.spacing(2),
    textTransform: 'inherit',
    fontSize: '1.3em',
    lineHeight: '3rem',
    color: '#fff',
    backgroundColor: '#6D7878',
    '&:hover': {
      backgroundColor: theme.palette.brandGray80.main,
    },
  },
};
