import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../_constants';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Regional Investment Aid Innovation Implementation Pdf element.
 *
 * @returns {RegionalInvestmentAidInnovationImplementationPdf}
 */
function RegionalInvestmentAidInnovationImplementationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const aid = 'regional_investment_aid_innovation_implementation_aid_for_participation_in_trade_fairs_in_current_year';

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="regional_investment_aid_innovation_implementation_is_regional" />
        {initialFormData?.regional_investment_aid_innovation_implementation_is_regional === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="regional_investment_aid_innovation_implementation_investment_aid">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.investment_type`}
                        value={item.investment_type}
                        oldValue={previousRowData?.investment_type}
                        dictionaryName={DICTIONARIES.initialInvestmentTypes.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.reason`}
                        value={item.reason}
                        oldValue={previousRowData?.reason}
                      />
                      <RowField
                        name={`${collectionName}.${index}.modernization_assets`}
                        value={item.modernization_assets}
                        oldValue={previousRowData?.modernization_assets}
                      />
                      <RowField
                        name={`${collectionName}.${index}.modernization_assets_value`}
                        value={item.modernization_assets_value}
                        oldValue={previousRowData?.modernization_assets_value}
                      />
                      <RowField
                        name={`${collectionName}.${index}.reused_assets`}
                        value={item.reused_assets}
                        oldValue={previousRowData?.reused_assets}
                      />
                      <RowField
                        name={`${collectionName}.${index}.reused_assets_value`}
                        value={item.reused_assets_value}
                        oldValue={previousRowData?.reused_assets_value}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
        <RowField name="regional_investment_aid_innovation_implementation_public_aid" isSingleRow />
        <RowField name={aid} />
      </FieldSorter>
    </View>
  );
}

export default RegionalInvestmentAidInnovationImplementationPdf;
