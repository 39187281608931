export const URL = {
  recruitment: {
    add: '/recruitments/add',
    edit: '/recruitments/edit/:recruitmentId',
    list: '/recruitments',
    ongoing: '/recruitments/ongoing',
    selection: '/recruitments/selection',
    applicationTemplate: {
      add: '/recruitments/application-template/add',
      edit: '/recruitments/:recruitmentId/application-template/edit/:applicationTemplateId',
    },
    expertPanelApplicationsList: '/recruitment/:id/expert-panels/:expertPanelId/applications-list/:panelNumber',
    expertPanelsList: '/recruitments/:recruitmentId/expert-panels',
    addExpertPanel: '/recruitments/:recruitmentId/expert-panels/add',
    editExpertPanel: '/recruitments/:recruitmentId/expert-panels/:expertPanelId/edit',
    shareEvaluationSheet: '/recruitments/share-evaluation-sheet',
    submittedApplications: '/recruitments/submitted-applications-list',
    submittedApplicationsByRecruitmentId: '/recruitments/:recruitmentId/submitted-applications-list',
    applicationStatusManagement: '/recruitments/application-status-management',
    projectAppraisalCommission: '/recruitments/:recruitmentId/project-appraisal-commission',
  },
  applications: {
    list: '/applications-list',
    submitted: '/submitted-applications-list',
    withdrawal: '/application-withdrawal/:id',
    submittedApplicationsWithOperator: '/submitted-applications-with-operator',
  },
  applicationSearch: '/application-search',
  elementDictionaries: {
    main: '/element-dictionaries/:templateElementId',
    tasks: '/element-dictionaries/:templateElementId/tasks',
    taskTypes: '/element-dictionaries/:templateElementId/task-types',
    costCategoryGroups: '/element-dictionaries/:templateElementId/cost-category-groups',
    costCategories: '/element-dictionaries/:templateElementId/cost-categories',
    indicators: '/element-dictionaries/:templateElementId/indicators',
  },

  contactForm: '/contact-form',

  declarations: '/declarations',

  expertsList: '/experts-list',
  expertCard: '/expert-card/:id',
  workEvaluation: '/expert-card/work-evaluation/:id',
  applicationExpertCard: '/application-expert-card/:id',
  applicationsListExpert: '/applications-list-expert',

  expert: {
    substantiveEvaluation: '/substantive-evaluation',
    expertPanels: '/expert-panels',
    expertPanelsAssessmentSheet: '/expert-panels/assessment-sheet/:id',
    expertPanelsPreliminaryRemark: '/expert-panels/preliminary-remark',
    opinionsProtests: '/opinions-protests',
    kopStatements: '/kop-statements',
    transferDetails: '/transfer-details',
    workEvaluation: '/work-evaluation',
    taxReturn: '/tax-return',
  },

  expertContract: '/expert-contracts',

  home: '/home',

  systemMessages: '/system-messages',
  systemMessagesByProfile: '/system-messages-by-profile',
  lsiDictionaries: '/lsi-dictionaries',
  listOfRatedApplications: '/list-of-rated-applications',
  listOfOrderAcceptanceProtocols: '/list-of-order-acceptance-protocols',

  applicationForm: {
    list: '/application-forms',
    add: '/application-forms/new',
    edit: '/application-forms/:id',
    read: '/application-forms/preview/:id',
  },

  expertApplicationFormData: '/expert-application-form-data',

  newsList: '/news-list',

  permissionsImport: '/permissions-import',

  reviews: '/reviews',
  reviewsSheet: '/reviews/sheet/:id/:protestId',

  application: {
    myApplications: '/my-applications',
    edit: '/applications/edit/:id',
    preview: '/applications/preview/:id',
    withdrawalDecisionView: '/applications/withdrawal-decision/:id',
    correction: '/applications/correction/:id',
    todoList: '/to-do-list',
    supplementCall: '/applications/supplement-call/:id',
    projectCard: {
      protests: {
        addProtest: '/applications/:id/project-card/protests/add',
        editProtest: '/applications/:id/project-card/protests/:protestId/edit',
      },
    },
  },
  applicant: {
    evaluationSheetsList: '/applications/:id/evaluation-sheets-list',
  },
  recruitmentEdit: {
    evaluationConfigurator: {
      addKit: '/recruitment-edit/:id/evaluation-configurator/add-kit',
      editKit: '/recruitment-edit/:id/evaluation-configurator/:kitId/edit-kit',
      manageCriteria: '/recruitment-edit/:id/evaluation-configurator/:kitId/manage-criteria',
    },
  },
  excludedEntitiesList: '/excluded-entities-list',
  redFlagsList: '/red-flags-list',
  excludedEntitiesRegistry: '/excluded-entities-registry',

  substantialEvaluation: '/substantial-evaluation',
  submitRecommendationPayment: '/submit-recommendation-payment',
  substantiveEvaluation: '/substantive-evaluation',
  cstDictionaries: '/cst-dictionaries',

  userAdd: '/user-add',
  usersList: '/users-list',
  userGuide: '/user-guide',
  userIssues: '/user-issues',

  config: {
    statements: '/statements',
    statementsAdd: '/statements/add',
  },

  users: {
    login: '/auth',
    passwordChange: '/password-change/',
    sessionEnded: '/session-ended',
    account: {
      settings: '/account/settings',
      notifications: '/account/notifications',
      activation: '/account/activation',
    },
    details: '/users/:id',
    permissions: '/users/:id/permissions',
  },

  project: {
    card: '/project-card/:id',
  },
  organizationAddress: '/organization-addresses',
  helpCenter: '/help-centers',

  exchangeDataWithCst: '/exchange-data-with-cst',

  reports: {
    main: '/reports',
    reportDetail: '/report-detail/:title',
  },
  informationClause: {
    list: '/information-clause',
    add: '/information-clause/add',
    edit: '/information-clause/:id/edit',
  },

  applicationPrints: '/application-prints',
  testRoutes: {
    editApplicationTemplate: '/application-template-edit/:applicationTemplateId',
  },
};
