import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import {
  cloneElement,
  useRef,
  useState,
} from 'react';
import PropTypes, { node } from 'prop-types';
import uniqid from 'uniqid';

const classes = {
  mainPopper: {
    zIndex: 9999,
  },
  mainButton: {
    textTransform: 'none',
  },
};

/**
 * Renders button with menu items as children.
 *
 * @param {object} props root props
 * @param {string} props.buttonTitle menu button title
 * @param {Array | node | null} props.children menu items
 * @param {string} props.openButtonId open button id
 * @param {string|null}props.closeButtonTitle close button title (appended if set)
 * @param {string} props.wrapperClass - optional class name applied to the component's wrapping element
 * @param {object} props.listStyles - optional styles applied to the component's list
 * @param {Element} props.icon - icon used as menu entry point, will be used instead of label if provided
 * @param {string} props.tooltipText - icon button hover tooltip text, if provided tooltip will be rendered
 * @param {object} props.buttonProps - props passed to button or icon button
 * @returns {ButtonMenu}
 */
export function ButtonMenu({
  buttonTitle,
  openButtonId,
  children,
  closeButtonTitle,
  wrapperClass,
  listStyles,
  icon,
  tooltipText,
  buttonProps,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  /**
   * Handle toggle.
   */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * Handle close menu.
   *
   * @param {Event} event handler event
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  /**
   * Keyboard TAB handle.
   *
   * @param {KeyboardEvent} event handler event
   */
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  /**
   * Appends close handler to menu item.
   *
   * @param {object} menuItem react element
   * @returns {object} cloned react element with close handler
   */
  const appendCloseHandler = (menuItem) => {
    const existingHandler = menuItem.props.onClick;

    let onClickProps = (event) => handleClose(event);
    if (typeof existingHandler === 'function') {
      onClickProps = (event) => {
        existingHandler(event);
        handleClose(event);
      };
    }

    return cloneElement(menuItem, { onClick: onClickProps });
  };

  /**
   * Returns children as array if only one given.
   *
   * @returns {Array} menu items
   */
  const getChildren = () => (Array.isArray(children) ? children : [children]);

  /**
   * Returns button title that depends on `closeButtonTitle` prop.
   *
   * @returns {string} button title
   */
  const getButtonTitle = () => {
    if (closeButtonTitle !== null) {
      return open ? closeButtonTitle : buttonTitle;
    }

    return buttonTitle;
  };

  /**
   * Returns button component based on selected variant.
   *
   * @returns {IconButton|Button}
   */
  const getButtonComponent = () => {
    const commonProps = {
      ref: anchorRef,
      'aria-controls': open ? 'menu-list-grow' : undefined,
      'aria-haspopup': true,
      'aria-label': getButtonTitle(),
      onClick: handleToggle,
      id: openButtonId,
      ...buttonProps,
    };
    if (icon) {
      return (
        <Tooltip
          title={tooltipText}
          id={uniqid()}
          disableHoverListener={tooltipText === ''}
          disableFocusListener={tooltipText === ''}
        >
          <IconButton {...commonProps}>
            {icon}
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Button
        variant="outlined"
        sx={classes.mainButton}
        {...commonProps}
      >
        {getButtonTitle()}
      </Button>
    );
  };

  return (
    <div style={{ wrapperClass }} role="menuitem">
      {getButtonComponent()}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={classes.mainPopper}
      >
        {({
          TransitionProps, placement,
        }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              minWidth: anchorRef.current?.offsetWidth,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="PRyUKcHGJDlTQT9"
                  onKeyDown={handleListKeyDown}
                  sx={listStyles}
                >
                  {children && getChildren().filter((child) => !!child).map((child) => appendCloseHandler(child))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

ButtonMenu.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  openButtonId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(node),
    PropTypes.node,
    PropTypes.oneOf([null]),
  ]),
  closeButtonTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  wrapperClass: PropTypes.string,
  listStyles: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.oneOf([null]),
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([null]),
  ]),
  tooltipText: PropTypes.string,
  buttonProps: PropTypes.instanceOf(Object),
};

ButtonMenu.defaultProps = {
  children: [],
  closeButtonTitle: null,
  wrapperClass: '',
  listStyles: null,
  icon: null,
  tooltipText: '',
  buttonProps: {},
};
