import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { implementationLevelParser } from '../../../ApplicationTemplate/Element/implementationLevelHelper';
import {
  APPLICATION_STATUSES_TRANSLATIONS,
  DATE,
} from '../../../../_constants';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * General program information pdf element
 *
 * @returns {GeneralProgramInformationPdf}
 */
function GeneralProgramInformationPdf() {
  const {
    application,
  } = useApplicationToPdfContext();
  const {
    implementationLevel, description,
  } = application.recruitment;
  const programNames = implementationLevelParser(implementationLevel);

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="application_number"
          value={application.applicationNumber}
          oldValue={application.applicationNumber}
        />
        <RowField
          name="application_submission_date"
          value={application.submissionDate}
          oldValue={application.submissionDate}
          formatDate={DATE.defaultFormatWithSeconds}
        />
        <RowField
          name="project_status"
          value={APPLICATION_STATUSES_TRANSLATIONS[application.status]}
          oldValue={APPLICATION_STATUSES_TRANSLATIONS[application.status]}
        />
        <RowField name="program" value={programNames.program} oldValue={programNames.program} />
        <RowField name="priority" value={programNames.priorytet} oldValue={programNames.priorytet} />
        <RowField
          name="activity"
          value={`${programNames.dzialanie} ${description ? `- ${description}` : ''}`}
          oldValue={programNames.dzialanie}
        />
        <RowField
          name="contracting_institution"
          value={implementationLevel?.instytucjaProwadzaca}
          oldValue={implementationLevel?.instytucjaProwadzaca}
        />
        <RowField
          name="project_settlement_institution"
          value={implementationLevel?.instytucjaProwadzaca}
          oldValue={implementationLevel?.instytucjaProwadzaca}
        />
        <RowField
          name="recruitment_number"
          value={application.recruitment.pelnyNumerNaboruWSl}
          oldValue={application.recruitment.pelnyNumerNaboruWSl}
        />
        <RowField
          name="project_type"
          value={application.recruitment.recruitmentType}
          oldValue={application.recruitment.recruitmentType}
        />
      </FieldSorter>
    </View>
  );
}

export default GeneralProgramInformationPdf;
