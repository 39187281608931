import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * HumanResourcesPotentialRdInfrastructure
 *
 * @returns {HumanResourcesPotentialRdInfrastructure}
 */
export default function HumanResourcesPotentialRdInfrastructure() {
  const {
    control, watch, setValue, resetField,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { cooperationStatuses } = ELEMENTS_DICTIONARIES_MAP;

  const collectionFieldName = 'human_resources_potential_rd_infrastructure_human_resources';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const humanResourcesPotentialRdInfrastructureHumanResourcesConfig = fieldsConfig[collectionFieldName];
  const subfields = humanResourcesPotentialRdInfrastructureHumanResourcesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = humanResourcesPotentialRdInfrastructureHumanResourcesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.roleInProjects,
    DICTIONARIES.cooperationStatuses,
  );

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <LabeledFieldRow
        fieldConfig={humanResourcesPotentialRdInfrastructureHumanResourcesConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((field, index) => (
          // Tabela \"Potencjał kadrowy B+R do realizacji modułu i Agendy Badawczej\"
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionFieldName,
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Imię */}
            <FormField name={`${collectionFieldName}.${index}.first_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nazwisko */}
            <FormField name={`${collectionFieldName}.${index}.last_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Wykształcenie (tytuł naukowy / stopień naukowy) / specjalizacja / kierunek */}
            <FormField name={`${collectionFieldName}.${index}.education`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Aktualne miejsca zatrudnienia */}
            <FormField name={`${collectionFieldName}.${index}.employment_place`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Rola  w projekcie */}
            <FormField name={`${collectionFieldName}.${index}.role_in_project`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.roleInProjects.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Status współpracy */}
            <FormField name={`${collectionFieldName}.${index}.cooperation_status`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={(event) => {
                    onChange(event);
                    if (event.target.value !== cooperationStatuses.plannedPerson.id) {
                      resetField(`${collectionFieldName}.${index}.job_description`, { defaultValue: FIELD_TYPES_MAP.text });
                    }
                  }}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.cooperationStatuses.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Doświadczenie naukowe/ zawodowe związane z tematyką modułu */}
            <FormField name={`${collectionFieldName}.${index}.experience`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  minRows={2}
                />
              )}
            </FormField>
            {/* Display field if "Status współpracy" is equal to "planowana" */}
            {watch(`${collectionFieldName}.${index}.cooperation_status`)
              === cooperationStatuses.plannedPerson.id
              && (
                // Opis planowanego do utworzenia stanowiska
                <FormField name={`${collectionFieldName}.${index}.job_description`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
            {/* Zakres obowiązków w module */}
            <FormField name={`${collectionFieldName}.${index}.duties_scope`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Wymiar zaangażowania w module */}
            <FormField name={`${collectionFieldName}.${index}.commitment_dimension`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="H8Y9oR2adkG4K3z"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Sposób zarządzania modułem (ścieżka decyzyjna) */}
      <FormField name="human_resources_potential_rd_infrastructure_management_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
