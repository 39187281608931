import IndicatorsTable from './Indicators/Indicators.table';
import { FieldSorter } from './FieldSorter';

/**
 * Indicators component
 *
 * @returns {Indicators}
 */
function Indicators() {
  return (
    <FieldSorter>
      <IndicatorsTable indicatorName="information_indicators" />
      <IndicatorsTable indicatorName="product_indicators" />
      <IndicatorsTable indicatorName="result_indicators" />
    </FieldSorter>
  );
}

export default Indicators;
