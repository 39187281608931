export const DISCLAIMER = {
  paragraph1: {
    // eslint-disable-next-line max-len
    pl: 'Administratorem Danych przetwarzanych w Systemie w zakresie wymaganym do rejestracji Konta i w celu utrzymania i rozwoju Systemu niezbędnego do obsługi realizowanych przez PARP zadań ustawowych jest Polska Agencja Rozwoju Przedsiębiorczości z siedzibą w Warszawie, przy ulicy Pańskiej 81/83. Dane kontaktowe do Inspektora Ochrony Danych - iod@parp.gov.pl',
  },
  paragraph2: {
    // eslint-disable-next-line max-len
    pl: 'Podanie danych jest dobrowolne, aczkolwiek odmowa ich podania jest równoznaczna z brakiem możliwości zarejestrowania w Systemie.',

  },
  paragraph3: {
    // eslint-disable-next-line max-len
    pl: 'Zobowiązuję się zachować w tajemnicy wszystkie informacje na niniejszym koncie użytkownika, które mogą mieć charakter informacji poufnych lub danych osobowych.',

  },
};
