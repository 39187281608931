export const copyToClipboard = async (valueToCopy) => {
  let isCopied = false;
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(valueToCopy);
    }
    isCopied = true;
    setTimeout(() => {
      isCopied = false;
    }, 2000);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Write to clipboard error:', error);
  }

  return isCopied;
};
