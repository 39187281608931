import { useElementContext } from '../../../Context';

/**
 * Children sorted by given fieldsOrder (by name) or by current element fields order config.
 *
 * @param {object} props - root props
 * @param {Array} props.children - FormField or LabeledFieldRow children
 * @param {Array|undefined} props.fieldsOrder - custom fields order by name
 * @returns {Element|Element[]}
 */
export const FieldSorter = ({
  children, fieldsOrder = undefined,
}) => {
  const childrenElements = [];
  const {
    currentElementFieldsConfig = [],
  } = useElementContext();

  const fieldsOrderByName = fieldsOrder || currentElementFieldsConfig?.map(({ name }) => name);

  children.forEach((child) => {
    if (typeof child?.type === 'function' && Object.values(child.props)?.length === 0) {
      if (child?.type()?.props?.children) {
        childrenElements.push(...child.type().props.children);
      }

      return;
    }

    if (typeof child?.type === 'function' && child.props?.isFieldsRow) {
      if (child?.type(child.props)?.props?.children) {
        childrenElements.push(...child.type(child.props).props.children);
      }

      return;
    }

    if (typeof child?.type === 'symbol' && child?.props?.children) {
      if (Array.isArray(child.props.children)) {
        childrenElements.push(...child.props.children);
      }

      return;
    }

    childrenElements.push(child);
  });

  return Array
    .from(childrenElements.filter((child) => typeof child === 'object'))
    .sort((a, b) => {
      const aName = a?.props?.name || a?.props?.fieldConfig?.name;
      const bName = b?.props?.name || b?.props?.fieldConfig?.name;

      if (!aName || !bName) {
        return -1;
      }

      return (
        fieldsOrderByName.indexOf(aName?.split('.').pop())
        - fieldsOrderByName.indexOf(bName?.split('.').pop())
      );
    });
};
