import { useFormContext } from 'react-hook-form';
import {
  Box,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useDictionaryLoader } from '../../../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';
import { useElementContext } from '../../../../../Context';
import { FieldSorter } from '../../FieldSorter';
import { FormField } from '../../../../Application/Application/FormField';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  BOOLEAN_VALUES,
  DICTIONARIES,
  EXPENSES_TYPE,
} from '../../../../../_constants';
import CharsCounter from '../../../../CharsCounter';
import { SaveCancelButtons } from '../../../../SaveCancelButtons';
import {
  MonetaryField,
  parsePastedText,
} from '../../../../MonetaryField';
import { Alert } from '../../../../Alert';
import {
  calculateCoFinancingPercent,
  calculateEligibleExpenses,
} from './helperFunctions';
import { CustomAutocomplete } from '../../../../CustomAutocomplete/CustomAutocomplete';
import { getImplementersAndApplicantName } from '../../../../../_helpers';
import { useCustomSnackbar } from '../../../../../_hooks';

/**
 * Flat rate expenses form
 *
 * @param {object} props - root props
 * @param {string} props.itemIndex - expenses index
 * @param {Function} props.handleClose - handle close dialog
 * @param {Function} props.reloadData - reload data from API
 * @param {boolean} props.indirectCosts - is indirect costs
 * @param {string} props.taskType - task type name
 * @param {string} props.taskId - task id
 * @returns {FlatRateExpensesForm}
 */
function FlatRateExpensesForm({
  itemIndex, handleClose, reloadData, indirectCosts, taskType, taskId,
}) {
  const costCategoryRef = useRef(null);
  const costNameDictionaryRef = useRef(null);
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { id: applicationId } = useParams();
  const {
    getValues, setValue, watch, setError,
  } = useFormContext();
  const {
    id: elementId, fieldsConfig, isReadonly,
  } = useElementContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const [fixedDecimalScale, setFixedDecimalScale] = useState({
    [`lump_sum_expenses-${elementId}.${itemIndex}.total_expenses`]: true,
    [`lump_sum_expenses-${elementId}.${itemIndex}.eligible_expenses`]: true,
    [`lump_sum_expenses-${elementId}.${itemIndex}.co_financing`]: true,
    [`lump_sum_expenses-${elementId}.${itemIndex}.indicator_value`]: true,
    [`lump_sum_expenses-${elementId}.${itemIndex}.rate_value`]: true,
    [`lump_sum_expenses-${elementId}.${itemIndex}.rate_count`]: true,
  });

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.lumpSumTypes,
    DICTIONARIES.lumpSumCostNames,
    DICTIONARIES.cstLimitCategories,
    DICTIONARIES.confirmationDocuments,
    {
      name: `costCategoryFlatRateExpenses-${elementId}-${taskId}`,
      // eslint-disable-next-line max-len
      path: `${API_ROUTE.elementDictionaries.costCategories}?templateElement.id=${elementId}&displayInIndirectTasks=${BOOLEAN_VALUES[indirectCosts.toString().toUpperCase()]}${taskType ? `&taskTypes.name=${taskType}` : ''}&itemsPerPage=100`,
    }
  );

  const lumpSumTypeValue = watch(`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_type`);

  const flatRateTypeValue = get(DICTIONARIES.lumpSumTypes.name)
    .find(({ '@id': id }) => id === lumpSumTypeValue)?.name || '';

  const expenseId = watch(`lump_sum_expenses-${elementId}.${itemIndex}`)?.lump_sum_expense_id || '';

  const lumpSumExpensesConfig = fieldsConfig[`lump_sum_expenses-${elementId}`];
  const subfields = lumpSumExpensesConfig?.fields || {};

  const isUnitRate = flatRateTypeValue === 'Stawka jednostkowa';
  const isFlatRate = flatRateTypeValue === 'Stawka ryczałtowa';
  const isLumpSum = flatRateTypeValue === 'Kwota ryczałtowa';

  const onSubmit = async () => {
    const payload = getValues(`lump_sum_expenses-${elementId}.${itemIndex}`);

    if (!payload.lump_sum_type) {
      setError(`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_type`, {
        type: 'manual',
        message: 'To pole nie może być puste.',
      });

      return;
    }

    setSubmitting(true);
    delete payload.id;

    const {
      payload: response, statusSuccess,
    } = expenseId
      ? await request.put(`${API_ROUTE.applicationExpenses
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/lump-sum-expenses/${expenseId}`, payload)
      : await request.post(`${API_ROUTE.applicationExpenses.replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/lump-sum-expenses`, payload);

    statusSuccess
      ? successNotification(`Wydatek został ${expenseId ? 'edytowany' : 'dodany'}.`)
      : errorNotification(`Nie udało się ${expenseId ? 'edytować' : 'dodać'} wydatku. Spróbuj ponownie.`);
    if (!statusSuccess) {
      handleClose(true);

      return;
    }

    setValue(`lump_sum_expenses-${elementId}.${itemIndex}`, response);
    reloadData(elementId, EXPENSES_TYPE.flatRateExpenses);
    handleClose();
  };

  const handleFixedDecimalScale = (fieldName, value) => {
    setFixedDecimalScale((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const setCalculatedCoFinancingPercent = () => {
    const coFinancingValue = getValues(`lump_sum_expenses-${elementId}.${itemIndex}.co_financing`);
    const eligibleExpensesValue = getValues(`lump_sum_expenses-${elementId}.${itemIndex}.eligible_expenses`);

    if (isUnitRate || isLumpSum) {
      setValue(`lump_sum_expenses-${elementId}.${itemIndex}.total_expenses`, eligibleExpensesValue);
    }

    setValue(
      `lump_sum_expenses-${elementId}.${itemIndex}.co_financing_percent`,
      calculateCoFinancingPercent(coFinancingValue, eligibleExpensesValue)
    );
  };

  const setCalculatedEligibleExpenses = () => {
    const rateValue = getValues(`lump_sum_expenses-${elementId}.${itemIndex}.rate_value`);
    const rateCount = getValues(`lump_sum_expenses-${elementId}.${itemIndex}.rate_count`);

    if (isUnitRate) {
      setValue(
        `lump_sum_expenses-${elementId}.${itemIndex}.eligible_expenses`,
        calculateEligibleExpenses(rateValue, rateCount)
      );
    }
  };

  const filteredLumpSumTypes = get(DICTIONARIES.lumpSumTypes.name)
    .filter((lumpSumType) => get(`costCategoryFlatRateExpenses-${elementId}-${taskId}`)
      .some((category) => lumpSumType['@id'] === category.lumpSumType));

  const filteredCostCategories = useMemo(
    () => get(`costCategoryFlatRateExpenses-${elementId}-${taskId}`)
      .filter((category) => category.lumpSumType
        === lumpSumTypeValue),
    [lumpSumTypeValue, get(`costCategoryFlatRateExpenses-${elementId}-${taskId}`)]
  );

  const filteredLumpSumCostNames = useMemo(
    () => get(DICTIONARIES.lumpSumCostNames.name)
      .filter((costName) => costName.lumpSumType
        === lumpSumTypeValue),
    [lumpSumTypeValue]
  );

  const costNameValue = watch(`lump_sum_expenses-${elementId}.${itemIndex}.cost_name_from_dictionary`);
  useEffect(() => {
    const value = filteredLumpSumCostNames.find((costName) => costName['@id'] === costNameValue)?.lumpSumRate || '0.00';

    if (watch(`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_rate`) !== value) {
      setValue(`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_rate`, value);
    }
  }, [costNameValue]);

  const lumpSumRateValue = useMemo(() => filteredLumpSumCostNames
    .find((costName) => costName['@id'] === costNameValue)?.lumpSumRate || '0.00', [costNameValue]);

  const clearValuesOnChangeLumpSumType = () => {
    setValue(`lump_sum_expenses-${elementId}.${itemIndex}.cost_name_in_words`, '');
    costCategoryRef?.current?.clear();
    costNameDictionaryRef?.current?.clear();
  };

  const rateValue = filteredLumpSumCostNames.find(({ '@id': id }) => costNameValue === id)
    ?.unitRateAmount || '0.00';
  const rateValueFieldName = `lump_sum_expenses-${elementId}.${itemIndex}.rate_value`;

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      <FieldSorter fieldsOrder={Object.keys(subfields)}>
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_type`}>
          {({
            name, onChange, value, filterOptions, onBlur, error,
          }) => (
            <CustomAutocomplete
              id={name}
              options={filterOptions(filteredLumpSumTypes)}
              initialValue={value}
              onChange={onChange}
              onBlur={(event) => {
                onBlur(event);
                clearValuesOnChangeLumpSumType();
              }}
              error={error}
              disabled={isReadonly}
            />
          )}
        </FormField>
        {(isLumpSum && subfields?.cost_name_in_words) && (
          <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.cost_name_in_words`}>
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                variant="outlined"
                defaultValue={value}
                onChange={onChange}
                onBlur={onBlur}
                required
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                error={!!error}
                helperText={error?.message}
                disabled={isReadonly}
              />
            )}
          </FormField>
        )}
        {(isFlatRate || isUnitRate || (isLumpSum && !subfields?.cost_name_in_words)) && (
          <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.cost_name_from_dictionary`}>
            {({
              name, onChange, value, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                options={filterOptions(filteredLumpSumCostNames)}
                initialValue={value}
                onChange={onChange}
                onBlur={(event) => {
                  onBlur(event);
                  setValue(rateValueFieldName, rateValue);
                }}
                disabled={!lumpSumTypeValue || isReadonly}
                ref={costNameDictionaryRef}
                error={error}
              />
            )}
          </FormField>
        )}
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.cost_category`}>
          {({
            name, onChange, value, filterOptions, onBlur, error,
          }) => (
            <CustomAutocomplete
              id={name}
              options={filterOptions(filteredCostCategories)}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={!lumpSumTypeValue || isReadonly}
              ref={costCategoryRef}
              error={error}
            />
          )}
        </FormField>
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.total_expenses`}>
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isUnitRate || isLumpSum || isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
        <FormField
          name={`lump_sum_expenses-${elementId}.${itemIndex}.eligible_expenses`}
          rules={{
            validate: {
              isLessThan: (
                value,
                formData,
              ) => Number(value) === Number(formData[`lump_sum_expenses-${elementId}`][itemIndex].total_expenses)
                || Number(value) < Number(formData[`lump_sum_expenses-${elementId}`][itemIndex].total_expenses)
                || Number(formData[`lump_sum_expenses-${elementId}`][itemIndex].total_expenses) === 0
            || 'Wartość w polu "Wydatki kwalifikowalne" nie może być większa niż wartość w polu "Wydatki ogółem"',
            },
          }}
        >
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                setCalculatedCoFinancingPercent();
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isUnitRate || isReadonly}
            />
          )}
        </FormField>
        <FormField
          name={`lump_sum_expenses-${elementId}.${itemIndex}.co_financing`}
          rules={{
            validate: {
              isLessThan: (
                value,
                formData,
              ) => Number(value) === Number(formData[`lump_sum_expenses-${elementId}`][itemIndex].eligible_expenses)
                || Number(value) < Number(formData[`lump_sum_expenses-${elementId}`][itemIndex].eligible_expenses)
            || 'Wartość w polu "Dofinansowanie" nie może być większa niż wartość w polu "Wydatki kwalifikowalne"',
            },
          }}
        >
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                setCalculatedCoFinancingPercent();
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.co_financing_percent`}>
          {({ name }) => (
            <Alert
              severity="status"
              sx={{
                minHeight: '50px',
                margin: 0,
              }}
            >
              {watch(name)}
            </Alert>
          )}
        </FormField>
        {getValues('has_implementers') === BOOLEAN_VALUES.TRUE && (
          <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.implementer_id`}>
            {({
              name, onChange, value, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={getImplementersAndApplicantName(getValues, applicationId)}
                error={error}
              />
            )}
          </FormField>
        )}
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.expense_justification`}>
          {({
            name, onChange, value, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
        <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.spending_on_accessibility`}>
          {({
            name, onChange, value, onBlur, error, filterOptions,
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              options={filterOptions(get(DICTIONARIES.cstLimitCategories.name))}
              error={error}
            />
          )}
        </FormField>
        {isLumpSum && (
          <>
            <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.indicator`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  required
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  error={!!error}
                  helperText={error?.message}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.indicator_value`}>
              {({
                name, onChange, value, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  onBlur={(event) => {
                    handleFixedDecimalScale(name, true);
                    onBlur(event);
                  }}
                  onFocus={() => handleFixedDecimalScale(name, false)}
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: fixedDecimalScale[name],
                    allowedDecimalSeparators: ['.', ','],
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                  required
                  error={!!error}
                  helperText={error?.message}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {isLumpSum && (
              <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.confirmation_documents`}>
                {({
                  name, onChange, value, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    options={filterOptions(get(DICTIONARIES.confirmationDocuments.name))}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                    disabled={isReadonly}
                  />
                )}
              </FormField>
            )}
          </>
        )}
        {isUnitRate && (
          <>
            <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.rate_value`}>
              {() => (
                <Alert
                  severity="status"
                  sx={{
                    minHeight: '50px',
                    margin: 0,
                  }}
                >
                  {rateValue}
                </Alert>
              )}
            </FormField>
            <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.rate_count`}>
              {({
                name, onChange, value, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  onBlur={(event) => {
                    setCalculatedEligibleExpenses();
                    handleFixedDecimalScale(name, true);
                    onBlur(event);
                  }}
                  onFocus={() => handleFixedDecimalScale(name, false)}
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: fixedDecimalScale[name],
                    allowedDecimalSeparators: ['.', ','],
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                  required
                  error={!!error}
                  helperText={error?.message}
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </>
        )}
        {isFlatRate && (
          <FormField name={`lump_sum_expenses-${elementId}.${itemIndex}.lump_sum_rate`}>
            {() => (
              <Alert
                severity="status"
                sx={{
                  minHeight: '50px',
                  margin: 0,
                }}
              >
                {lumpSumRateValue}
              </Alert>
            )}
          </FormField>
        )}
      </FieldSorter>
      <Box display="flex" justifyContent="center" mt={2} alignItems="center" sx={{ width: '100%' }}>
        <SaveCancelButtons
          cancelHandler={() => handleClose(true)}
          cancelButtonId="mGzjPXS6YXVRM3P"
          saveHandler={onSubmit}
          saveButtonId="03vnt4Bm2ydzbCj"
          saveDisabled={isSubmitting || isReadonly}
        />
      </Box>
    </>
  );
}

export default FlatRateExpensesForm;

FlatRateExpensesForm.propTypes = {
  itemIndex: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  indirectCosts: PropTypes.bool.isRequired,
  taskType: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
};
