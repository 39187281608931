/**
 * Supplement call statuses.
 *
 * @type {object}
 */
export const SUPPLEMENT_CALL_STATUSES = {
  edition: 'edition',
  sentToApplicant: 'sent_to_applicant',
  sentByApplicant: 'sent_by_applicant',
  sentByCron: 'sent_by_cron',
};

/**
 * Supplement call statuses translations.
 *
 * @type {object}
 */
export const SUPPLEMENT_CALL_STATUSES_TRANSLATIONS = {
  [SUPPLEMENT_CALL_STATUSES.edition]: 'Edycja',
  [SUPPLEMENT_CALL_STATUSES.sentToApplicant]: 'Wysłane do wnioskodawcy',
  [SUPPLEMENT_CALL_STATUSES.sentByApplicant]: 'Przesłane przez wnioskodawcę',
  [SUPPLEMENT_CALL_STATUSES.sentByCron]: 'Przesłane przez system',
};
