import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { CHARS_LIMIT } from '../../../_constants';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

const classes = {
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
  },
};

/**
 * Residence address form for expert personal data.
 *
 * @param {object} props - root props
 * @param {object} props.countriesDictionary - dictionary for country input
 * @param {boolean} props.isReadonly - is read only
 * @returns {ResidenceAddressForm}
 */
export function ResidenceAddressForm({
  countriesDictionary, isReadonly,
}) {
  const {
    control, watch, setValue, getValues,
  } = useFormContext();
  const residenceCountry = watch('residenceAddress.country') || getValues('residenceAddress.country');
  const isPolandSelected = residenceCountry === 'PL';

  return (
    <Grid
      container
      spacing={2}
      mb={2}
      sx={classes.container}
      id="NhLcFpqsi2ep9wo"
    >
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.country">
          Kraj
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            rules={{
              required: {
                value: true,
                message: 'Proszę uzupełnić pole "Kraj"',
              },
            }}
            name="residenceAddress.country"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                disabled={isReadonly}
                id={name}
                textFieldProps={{
                  error: !!error,
                  helperText: error?.message,
                  label: 'Kraj',
                }}
                initialValue={value}
                onChange={(event) => {
                  setValue('residenceAddress.postalCode', '');
                  onChange(event);
                }}
                options={Object.entries(countriesDictionary).map(([id, label]) => ({
                  id,
                  label,
                }))}
                optionsMapKeys={['id', 'label']}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.town">
          Miejscowość
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="residenceAddress.town"
            control={control}
            render={({
              field: {
                onChange, value = '', name,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />),
                }}
                inputProps={{
                  maxLength: CHARS_LIMIT.MEDIUM,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.postalCode">
          Kod pocztowy
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="residenceAddress.postalCode"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                value={value}
                variant="outlined"
                placeholder={isPolandSelected ? '__-___' : ''}
                onChange={onChange}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={isPolandSelected ? 6 : 10} />),
                  inputComponent: isPolandSelected ? ImprovedMaskedInput : undefined,
                }}
                inputProps={{
                  mask: '00-000',
                  maxLength: isPolandSelected ? 6 : 10,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.street">
          Ulica
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="residenceAddress.street"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />),
                }}
                inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.houseNumber">
          Nr budynku
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="residenceAddress.houseNumber"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={10} />),
                }}
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="residenceAddress.apartmentNumber">
          Nr lokalu
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="residenceAddress.apartmentNumber"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={10} />),
                }}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

ResidenceAddressForm.propTypes = {
  countriesDictionary: PropTypes.instanceOf(Object).isRequired,
  isReadonly: PropTypes.bool,
};

ResidenceAddressForm.defaultProps = {
  isReadonly: false,
};
