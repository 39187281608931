import { darken } from '@mui/material/styles';
import { theme } from '../../styles/theme';

export const styleClassesCommon = {
  formControl: {
    padding: theme.spacing(2),
  },
  actionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: theme.spacing(1),
      minWidth: '100px',
    },
  },
  tableHeaderTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  zIndex9999: {
    zIndex: 9999,
  },
  chip: {
    borderRadius: 0,
  },
  addButton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'left',
    backgroundColor: theme.palette.add.main,
    color: theme.palette.add.contrastText,
    '&:hover': {
      backgroundColor: darken(theme.palette.add.main, 0.2),
      color: theme.palette.add.contrastText,
    },
  },
  gridContainer: {
    paddingTop: theme.spacing(5),
    margin: '0px !important',
  },
  inputTitle: {
    textAlign: 'left',
    paddingLeft: '0px !important',
  },
  gridButtons: {
    paddingTop: `${theme.spacing(5)} !important`,
    paddingLeft: '0px !important',
    textAlign: 'left',
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  spacedHugeButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.brandGray40.main,
    textTransform: 'inherit',
    fontWeight: 700,
    fontSize: '1.2em',
    letterSpacing: '1px',
  },
};
