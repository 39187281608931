import PropTypes from 'prop-types';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useRef } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../../_constants';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import {
  setValuesForOtherGeographicalFields,
  townParser,
  getCollectionFieldObject,
  setNumberToCollectionField,
} from '../../../_helpers';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * Private partnership subfield of Implementers.
 *
 * @param {object} props - root props
 * @param {string} props.collectionName - collection name
 * @returns {PrivatePartnershipPartners}
 */
export function PrivatePartnershipPartners({ collectionName }) {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control,
    watch,
    setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const streetSelectRefs = useRef({});
  const partnersConfig = fieldsConfig.implementers.fields.implementers_partners_of_a_civil_partnership;
  const subfields = partnersConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = partnersConfig || {};

  /**
   * Adds item to collection.
   */
  const addItem = () => {
    if (fields.length + 1 > maxRowNumber) {
      return;
    }

    append(fieldObject);
  };

  /**
   * Removes item from collection.
   *
   * @param {number} index - field index
   */
  const removeItem = (index) => {
    if (fields.length - 1 < minRowNumber) {
      return;
    }

    remove(index);
    setNumberToCollectionField(
      collectionName,
      index,
      setValue,
      fields,
    );
  };

  return (
    <>
      {fields?.map((item, index) => {
        const mapKeys = [
          `${collectionName}.${index}.commune`,
          `${collectionName}.${index}.district`,
          `${collectionName}.${index}.voivodeship`,
          `${collectionName}.${index}.street`,
        ];

        return (
          <CollectionFieldContainer
            key={item.id}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            onRemove={removeItem}
            isIndexed
          >
            {/* Imię */}
            <FormField name={`${collectionName}.${index}.name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            {/* Nazwisko */}
            <FormField name={`${collectionName}.${index}.last_name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            {/* NIP */}
            <FormField name={`${collectionName}.${index}.nip`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.NIP} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? CHARS_LIMIT.NIP,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            {/* Wojewodztwo */}
            <FormField name={`${collectionName}.${index}.voivodeship`}>
              {({ value }) => (
                <TextField
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            {/* Powiat */}
            <FormField name={`${collectionName}.${index}.district`}>
              {({ value }) => (
                <TextField
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            {/* Gmina */}
            <FormField name={`${collectionName}.${index}.commune`}>
              {({ value }) => (
                <TextField
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            {/* Miejscowość */}
            <FormField name={`${collectionName}.${index}.city`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <ApiAutocomplete
                  id={name}
                  filterBy="name"
                  initialValue={value?.value}
                  initialValueFilterBy="id"
                  optionParser={townParser}
                  changeHandler={(selectedValue) => {
                    streetSelectRefs.current[index].clear();
                    onChange(selectedValue);
                    setValuesForOtherGeographicalFields(selectedValue, setValue, mapKeys);
                  }}
                  textFieldProps={{
                    onBlur,
                  }}
                  charLimitExclusionWords={['oś']}
                  baseUrl={API_ROUTE.geoDictionaries.towns}
                  disabled={isReadonly}
                  error={error}
                  staticFilters={{ itemsPerPage: '500' }}
                />
              )}
            </FormField>

            {/* Kod pocztowy */}
            <FormField name={`${collectionName}.${index}.postal_code`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={6} />,
                  }}
                  inputProps={{
                    mask: '00-000',
                    maxLength: 6,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            {/* Ulica */}
            <FormField name={`${collectionName}.${index}.street`}>
              {({
                onChange, value, name, onBlur, error,
              }) => {
                const selectedTown = watch(`${collectionName}.${index}.city`);

                return (
                  <ApiAutocomplete
                  /* eslint-disable-next-line no-return-assign */
                    ref={(el) => (streetSelectRefs.current = {
                      ...streetSelectRefs.current,
                      [index]: el,
                    })}
                    id={name}
                    disabled={!selectedTown?.value || isReadonly}
                    filterBy="name"
                    staticFilters={{ 'town.id': selectedTown?.value }}
                    initialValue={value?.value}
                    initialValueFilterBy="id"
                    optionParser={({
                      id, name: label,
                    }) => ({
                      value: id,
                      label,
                    })}
                    textFieldProps={{
                      onBlur,
                    }}
                    changeHandler={onChange}
                    baseUrl={API_ROUTE.geoDictionaries.streets}
                    error={error}
                    minCharsToRequest={2}
                  />
                );
              }}
            </FormField>

            {/* Nr budynku */}
            <FormField name={`${collectionName}.${index}.house_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            {/* Nr lokalu */}
            <FormField name={`${collectionName}.${index}.apartment_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        );
      })}

      {!isReadonly && (
        <Grid item xs={12}>
          <Button
            id="sD73LHLVnH1yvZO"
            variant="contained"
            color="secondary"
            onClick={addItem}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}

PrivatePartnershipPartners.propTypes = {
  collectionName: PropTypes.string.isRequired,
};
