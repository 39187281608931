import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import PrivatePartnershipPdf from './ApplicantGeneralInformation/PrivatePartnership.pdf';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';

/**
 * Project general information pdf element renderer.
 *
 * @returns {ApplicantGeneralInformationPdf}
 */
function ApplicantGeneralInformationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    }, getDictionary,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const pkdDictionary = getDictionary(DICTIONARIES.pkdCodes.name);

  const pkdForPdf = pkdDictionary.find((pkdItem) => pkdItem['@id'] === initialFormData?.pkd);
  const previousPkdForPdf = pkdDictionary.find((pkdItem) => pkdItem['@id'] === previousInitialFormData?.pkd);

  const getHorecaSectorPkdCodeValue = (data) => pkdDictionary
    .filter(({ '@id': pkdItemIri }) => data?.horeca_sector_pkd_codes?.includes(pkdItemIri))
    .map(({
      code, name,
    }) => `${code} ${name}`).join(', ');

  const { vatRecoveryPossibility } = ELEMENTS_DICTIONARIES_MAP;
  const vatRecoveryPossibilityValue = initialFormData?.vat_recovery_possibility;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="applicant_name" />
        <RowField name="start_date" />
        <RowField
          name="legal_form"
          dictionaryName={DICTIONARIES.legalEntityTypes.name}
        />
        <RowField
          name="applicant_type"
          dictionaryName={DICTIONARIES.applicantType.name}
        />
        <RowField
          name="company_size"
          dictionaryName={DICTIONARIES.companySize.name}
        />
        <RowField name="nip" />
        <RowField name="regon" />
        <RowField
          name="property_form"
          dictionaryName={DICTIONARIES.propertyForms.name}
        />
        <RowField name="krs_number" />
        <RowField
          name="pkd"
          value={`${pkdForPdf?.code} ${pkdForPdf?.name}`}
          oldValue={`${previousPkdForPdf?.code} ${previousPkdForPdf?.name}`}
        />
        <RowField
          name="vat_recovery_possibility"
          dictionaryName={DICTIONARIES.vatRecoveryPossibilities.name}
        />
        {((vatRecoveryPossibilityValue === vatRecoveryPossibility.no.id)
        || (vatRecoveryPossibilityValue === vatRecoveryPossibility.partially.id)) && (
          <RowField name="vat_recovery_possibility_justification" />
        )}
        <RowField name="country" value={initialFormData?.city?.country?.label} />
        <RowField name="city" value={initialFormData?.city?.label} />
        <RowField name="commune" value={initialFormData?.city?.commune?.label} />
        <RowField name="district" value={initialFormData?.city?.district?.label} />
        <RowField name="voivodeship" value={initialFormData?.city?.voivodeship?.label} />
        <RowField name="postal_code" />
        <RowField name="street" value={initialFormData?.street?.label} />
        <RowField name="house_number" />
        <RowField name="apartment_number" />
        <RowField name="email" />
        <RowField name="phone_number" />
        <RowField name="web_page" />
        <RowField name="delivery_box" />
        <RowField
          name="innovation_center_type"
          dictionaryName={DICTIONARIES.innovationCenterType.name}
        />
        <RowField name="justification_for_meeting_innovation_center_definition" isSingleRow />
        <CollectionWrapper name="partnership_partners">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <PrivatePartnershipPdf
                collectionName={collectionName}
                item={item}
                index={index}
                collectionData={collectionData}
                previousRowData={previousRowData}
              />
            );
          })}
        </CollectionWrapper>
        <RowField name="applicant_headquarters_address" isSingleRow />
        <RowField name="applicant_district_in_eastern_poland" isSingleRow />
        <RowField name="business_registration_date_in_eastern_poland" />
        <RowField
          name="applicant_general_information_entity_type"
          dictionaryName={DICTIONARIES.applicantGeneralInformationEntityTypes.name}
        />
        <RowField name="applicant_general_information_entity_types_justification" isSingleRow />
        <RowField
          name="horeca_sector_pkd_codes"
          value={getHorecaSectorPkdCodeValue(initialFormData)}
          oldValue={getHorecaSectorPkdCodeValue(previousInitialFormData)}
        />
        <RowField name="applicant_general_information_last_closed_fiscal_year" />
        <RowField name="applicant_general_information_revenues_value_in_the_last_closed_fiscal_year" />
      </FieldSorter>
    </View>
  );
}

export default ApplicantGeneralInformationPdf;
