import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useRef,
  useEffect,
} from 'react';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import {
  CHARS_LIMIT,
  API_ROUTE,
} from '../../../_constants';
import {
  townParser,
  setValuesForOtherGeographicalFields,
  getCollectionFieldObject,
  removeItem,
} from '../../../_helpers';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * Originator
 *
 * @returns {Originator}
 */
export function Originator() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    PESEL, PHONE,
  } = CHARS_LIMIT;

  const streetSelectRef = useRef({
    clear: () => {},
  });

  const {
    control, watch, setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'originator',
  });

  const originatorConfig = fieldsConfig.originator;
  const subfields = originatorConfig.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = originatorConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <LabeledFieldRow fieldConfig={originatorConfig} contextHelpLabel fullWidth>
      {fields.map((item, index) => {
        const selectedCity = watch(`originator.${index}.city`);

        return (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'originator',
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={item.id}
            buttonRemoveVisible={fields.length > 1}
            isIndexed
          >
            {/* Imię i nazwisko pomysłodawcy */}
            <FormField name={`originator.${index}.first_and_last_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Pesel */}
            <FormField name={`originator.${index}.pesel`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? PESEL} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? PESEL }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Zawód */}
            <FormField name={`originator.${index}.occupation`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Wykształcenie */}
            <FormField name={`originator.${index}.education`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Etykieta: Dane kontaktowe pomysłodawcy */}
            <FormField name={`originator.${index}.contact_information`} contextHelpLabel>
              {() => null}
            </FormField>
            {/* Telefon */}
            <FormField name={`originator.${index}.phone`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter
                    valueLength={value.length}
                    maxLength={maxLength ?? PHONE}
                  /> }}
                  inputProps={{ maxLength: maxLength ?? PHONE }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Adres e-mail */}
            <FormField name={`originator.${index}.email`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Etykieta: Adres pomysłodawcy */}
            <FormField name={`originator.${index}.address`} contextHelpLabel>
              {() => null}
            </FormField>
            {/* Województwo */}
            <FormField name={`originator.${index}.voivodeship`}>
              {({ value }) => (
                <TextField value={value?.label || ''} disabled />
              )}
            </FormField>
            {/* Powiat */}
            <FormField name={`originator.${index}.district`}>
              {({ value }) => (
                <TextField value={value?.label || ''} disabled />
              )}
            </FormField>
            {/* Gmina */}
            <FormField name={`originator.${index}.commune`}>
              {({ value }) => (
                <TextField value={value?.label || ''} disabled />
              )}
            </FormField>
            {/* Miejscowość */}
            <FormField name={`originator.${index}.city`}>
              {({
                onChange, value, name, onBlur,
              }) => (
                <ApiAutocomplete
                  id={name}
                  filterBy="name"
                  initialValue={value?.value}
                  initialValueFilterBy="id"
                  optionParser={townParser}
                  changeHandler={(selectedValue) => {
                    streetSelectRef.current.clear();
                    onChange(selectedValue);
                    setValuesForOtherGeographicalFields(
                      selectedValue,
                      setValue,
                      [
                        `originator.${index}.voivodeship`,
                        `originator.${index}.district`,
                        `originator.${index}.commune`,
                      ],
                    );
                  }}
                  textFieldProps={{
                    onBlur,
                  }}
                  charLimitExclusionWords={['oś']}
                  baseUrl={API_ROUTE.geoDictionaries.towns}
                  disabled={isReadonly}
                  staticFilters={{ itemsPerPage: '500' }}
                />
              )}
            </FormField>
            {/* Kod pocztowy */}
            <FormField name={`originator.${index}.postal_code`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  placeholder="__-___"
                  disabled={isReadonly}
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                  }}
                  inputProps={{
                    mask: '00-000',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Ulica */}
            <FormField name={`originator.${index}.street`}>
              {({
                onChange, value, name, onBlur,
              }) => (
                <ApiAutocomplete
                  id={name}
                  disabled={!selectedCity?.value || isReadonly}
                  ref={streetSelectRef}
                  filterBy="name"
                  staticFilters={{ 'town.id': selectedCity?.value }}
                  initialValue={value?.value}
                  initialValueFilterBy="id"
                  optionParser={({
                    id, name: label,
                  }) => ({
                    value: id,
                    label,
                  })}
                  textFieldProps={{
                    onBlur,
                  }}
                  changeHandler={onChange}
                  baseUrl={API_ROUTE.geoDictionaries.streets}
                  minCharsToRequest={2}
                />
              )}
            </FormField>
            {/* Nr budynku */}
            <FormField name={`originator.${index}.house_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nr lokalu */}
            <FormField name={`originator.${index}.apartment_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        );
      })}

      {!isReadonly && (
        <Grid item xs={12}>
          <Button
            id="nv1vTvE3JV8XXHs"
            variant="contained"
            color="secondary"
            onClick={() => append(fieldObject)}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
