export const REGEX = {
  email: {
    value: /^[\w-.]+@([\w-]+\.)+\w{2,10}$/,
    message: 'Wpisz poprawny adres e-mail. Nieprawidłowy format adresu e-mail',
  },
  pesel: {
    value: /^[0-9]{11}/,
    message: 'PESEL musi się składać z 11 cyfr',
  },
  iban: {
    value: /^\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{4}$/, // 26 digits + 6 spaces
    message: 'Numer IBAN musi się składać z 26 cyfr',
  },
  nip: {
    value: /^\d{10}$/,
    message: 'Błędny NIP. NIP musi mieć 10 znaków.',
  },
  regon: {
    value: /^\d{9}$/,
    message: 'Błędny REGON. REGON musi mieć 9 znaków.',
  },
  password: {
    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[$&+,:;=?@#|'<>.^*()%!{}[\]_~"`\-\\/]).{16,}$/,
  },
  /**
   * Used control characters inside this regex for exclude unicode characters ("\u0000")
   */
  /* eslint-disable no-control-regex */
  null: /[\x00]/g,
  /* eslint-enable no-control-regex */
};
