import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  createFilterOptions,
  TextField,
} from '@mui/material';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import uniqid from 'uniqid';
import { upperFirst } from 'lodash';
import {
  getMultipleInitialValue,
  getMultipleObjectForHandleChange,
  getSingleInitialValue,
  getSingleObjectForHandleChange,
} from '../../_helpers';

/**
 * MUI controlled Autocomplete component with custom function for initial value and handle change.
 *
 * @param {object} props - root props
 * @param {object} ref - ref to pass functions from imperative handle
 * @param {string} props.id - optional input id
 * @param {Array} props.options - autocomplete selection options
 * @param {Function} props.onChange - function invoked on option accept
 * @param {Function} props.onBlur - function passed to Autocomplete
 * @param {Array|string|number} props.initialValue - initial value loaded into component
 * @param {boolean} props.multiple - is input handle multiple (array) values
 * @param {object} props.textFieldProps - props passed to MUI TextField
 * @param {boolean} props.renderSelectAllButton - select all button will be rendered (only in multiple variant)
 * @param {boolean} props.disabled - disabled prop passed to autocomplete
 * @param {boolean} props.noOptionsText - information text if no options
 * @param {Array} props.optionsMapKeys - keys by which the options to be displayed are mapped
 * @param {object} props.error - field errors
 * @param {boolean} props.isUpperFirst - is the first character of the string to the upper case
 * @returns {CustomAutocomplete}
 */
export const CustomAutocomplete = forwardRef(({
  id,
  options,
  onChange,
  onBlur,
  initialValue,
  multiple,
  textFieldProps,
  renderSelectAllButton,
  disabled,
  noOptionsText,
  optionsMapKeys,
  error,
  isUpperFirst,
}, ref) => {
  useImperativeHandle(ref, () => ({
    /**
     * Clears selected value.
     */
    clear: () => {
      setValue(multiple ? [] : null);
      onChange(multiple ? [] : null);
    },
    setOwnValue: (ownValue) => {
      setValue(multiple ? getMultipleInitialValue(ownValue, getMappedOptions())
        : getSingleInitialValue(ownValue, getMappedOptions()));
    },
  }));

  const [value, setValue] = useState(multiple ? [] : null);
  const [inputValue, setInputValue] = useState('');
  const [initialized, setInitialized] = useState(false);
  const allOptionsSelected = multiple ? value.length === options.length : undefined;

  const getMappedOptions = () => options.map((option) => ({
    id: option[optionsMapKeys[0]],
    label: isUpperFirst ? upperFirst(option[optionsMapKeys[1]]) : option[optionsMapKeys[1]],
  }));

  /**
   * Loads initial value into Autocomplete.
   * Options can be loaded with a delay, so this function will
   * only load the default value when the options are loaded.
   */
  const loadInitialValue = useCallback(
    () => {
      if (getMappedOptions().length === 0 || initialized || initialValue === null) {
        return;
      }
      setValue(
        multiple ? getMultipleInitialValue(initialValue, getMappedOptions())
          : getSingleInitialValue(initialValue, getMappedOptions())
      );

      setInitialized(true);
    },
    [initialValue, initialized, multiple, getMappedOptions()],
  );

  /**
   * Triggers loadInitialValue() on deps changes.
   */
  useEffect(() => {
    loadInitialValue();
  }, [initialValue, loadInitialValue, getMappedOptions()]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.label,
  });

  /**
   * Manually filter options list by currently selected.
   *
   * @param {Array|string|number|null} selected - currently selected option/options
   * @param {object} state - autocomplete state
   * @returns {Array}
   */
  const filterSelectedOptions = (selected, state) => {
    const currentlySelected = multiple ? value.map(({ id: selectedId }) => selectedId) : value?.id;

    return filterOptions(
      selected.filter(
        ({ id: selectedId }) => (multiple ? !currentlySelected.includes(selectedId) : selectedId !== currentlySelected)
      ),
      state
    );
  };

  /**
   * Handle all selection operation.
   */
  const allAction = () => {
    if (multiple) {
      setValue(allOptionsSelected ? [] : getMappedOptions());
      setInputValue(allOptionsSelected ? [] : getMappedOptions().map(({ id: optionId }) => optionId));
      onChange(allOptionsSelected ? [] : getMultipleObjectForHandleChange(getMappedOptions(), id));
    }
  };

  return (
    <>
      <Autocomplete
        noOptionsText={noOptionsText}
        id={id}
        getOptionLabel={({ label }) => label}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        isOptionEqualToValue={(option, comparisonValue) => option?.id === comparisonValue?.id}
        multiple={multiple}
        filterOptions={filterSelectedOptions}
        value={value}
        options={getMappedOptions()}
        onChange={(_event, newValue, reason, details) => {
          setValue(newValue);
          onChange(
            (multiple ? getMultipleObjectForHandleChange(newValue, id)
              : getSingleObjectForHandleChange(newValue, id)),
            reason,
            details,
          );
        }}
        ChipProps={{ sx: { borderRadius: 0 } }}
        onBlur={onBlur}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        clearOnEscape
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            name={id}
            error={!!error}
            helperText={error?.message}
            {...textFieldProps}
            {...params}
          />
        )}
      />
      {multiple && renderSelectAllButton && !disabled && (
        <Button
          id={`${uniqid()}-add-remove-all`}
          onClick={allAction}
          aria-label={allOptionsSelected ? 'Odznacz wszystkie wybrane wartości' : 'Zaznacz wszystkie wybrane wartości'}
        >
          {allOptionsSelected ? 'Odznacz wszystkie' : 'Wybierz wszystkie'}
        </Button>
      )}
    </>
  );
});

CustomAutocomplete.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  multiple: PropTypes.bool,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  })),
  textFieldProps: PropTypes.instanceOf(Object),
  renderSelectAllButton: PropTypes.bool,
  disabled: PropTypes.bool,
  noOptionsText: PropTypes.string,
  optionsMapKeys: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.objectOf(Object),
  isUpperFirst: PropTypes.bool,
};

CustomAutocomplete.defaultProps = {
  id: uniqid('autocomplete-'),
  options: [],
  onChange: () => {},
  onBlur: () => {},
  multiple: false,
  initialValue: null,
  textFieldProps: {},
  renderSelectAllButton: true,
  disabled: false,
  noOptionsText: 'Brak opcji',
  optionsMapKeys: ['@id', 'name'],
  error: null,
  isUpperFirst: true,
};
