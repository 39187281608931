import { TextField } from '@mui/material';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../../_constants';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import {
  setValuesForOtherGeographicalFields,
  townParser,
} from '../../../_helpers';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';

/**
 * Mailing address element renderer.
 *
 * @returns {MailingAddress}
 */
export function MailingAddress() {
  const {
    isReadonly,
  } = useElementContext();
  const streetSelectRef = useRef({
    clear: () => {
    },
  });
  const {
    watch, setValue,
  } = useFormContext();

  const { PHONE } = CHARS_LIMIT;

  const selectedCity = watch('mailingAddress_city');

  const geographicalFieldNames = {
    country: 'mailingAddress_country',
    commune: 'mailingAddress_commune',
    district: 'mailingAddress_district',
    voivodeship: 'mailingAddress_voivodeship',
    street: 'mailingAddress_street',
  };

  return (
    <FieldSorter>
      <FormField name="mailingAddress_city">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
          // Miejscowość
            id={name}
            filterBy="name"
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={townParser}
            changeHandler={(selectedValue) => {
              streetSelectRef.current.clear();
              onChange(selectedValue);
              setValuesForOtherGeographicalFields(selectedValue, setValue, Object.values(geographicalFieldNames));
            }}
            textFieldProps={{
              onBlur,
            }}
            charLimitExclusionWords={['oś']}
            baseUrl={API_ROUTE.geoDictionaries.towns}
            disabled={isReadonly}
            error={error}
            staticFilters={{ itemsPerPage: '500' }}
          />
        )}
      </FormField>

      <FormField name={geographicalFieldNames.country}>
        {({ value }) => (
          <TextField
            // Kraj
            value={value?.label || ''}
            disabled
          />
        )}
      </FormField>

      <FormField name={geographicalFieldNames.commune}>
        {({ value }) => (
          <TextField
          // Gmina
            value={value?.label || ''}
            disabled
          />
        )}
      </FormField>

      <FormField name={geographicalFieldNames.district}>
        {({ value }) => (
          <TextField
          // Powiat
            value={value?.label || ''}
            disabled
          />
        )}
      </FormField>

      <FormField name={geographicalFieldNames.voivodeship}>
        {({ value }) => (
          <TextField
          // Województwo
            value={value?.label || ''}
            disabled
          />
        )}
      </FormField>

      <FormField name="mailingAddress_postal_code">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
          // Kod pocztowy
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            placeholder="__-___"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
            }}
            inputProps={{
              mask: '00-000',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="mailingAddress_street">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            // Ulica
            id={name}
            disabled={!selectedCity?.value || isReadonly}
            ref={streetSelectRef}
            filterBy="name"
            staticFilters={{ 'town.id': selectedCity?.value }}
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={({
              id, name: label,
            }) => ({
              value: id,
              label,
            })}
            textFieldProps={{
              onBlur,
            }}
            changeHandler={onChange}
            baseUrl={API_ROUTE.geoDictionaries.streets}
            error={error}
            minCharsToRequest={2}
          />
        )}
      </FormField>

      <FormField name="mailingAddress_house_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
          // Nr budynku
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="mailingAddress_apartment_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
          // Nr lokalu
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="mailingAddress_email">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
          // Email
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="mailingAddress_phone_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
          // Telefon
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? PHONE} /> }}
            inputProps={{ maxLength: maxLength ?? PHONE }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="mailingAddress_epuap_box">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
          // Skrzynka ePUAP
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
