import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { Fragment } from 'react';
import uniqid from 'uniqid';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { TitledContainer } from '../../../TitledContainer';
import { RULE_COMPONENTS } from '../../../../_constants';

/**
 * Validation wrapper component
 *
 * @param {object} props - root props
 * @param {Array} props.rules - array with rules
 * @param {string} props.type - type of field
 * @returns {Validations}
 */
function Validations({
  rules, type,
}) {
  const {
    control,
  } = useFormContext();

  if (rules.length === 0) {
    return '';
  }

  return (
    <TitledContainer
      title={`Walidacje ${type === 'collection' ? 'kolekcji' : 'pola'}`}
      variant="slim"
      textAlign="center"
    >
      <Grid container spacing={3}>
        {rules.map((rule, index) => {
          const RuleComponent = rule?.name in RULE_COMPONENTS ? RULE_COMPONENTS[rule.name] : null;

          if (!RuleComponent) {
            return '';
          }

          return (
            <Fragment key={uniqid()}>
              <Grid item xs={2} display="flex" alignItems="center">
                <Controller
                  name={`rules.${index}.enabled`}
                  control={control}
                  render={({
                    field: {
                      onChange, value, name,
                    },
                  }) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          id={name}
                          name={name}
                          onChange={onChange}
                          color="secondary"
                          checked={value}
                        />
                      )}
                      label={value ? 'Wyłącz' : 'Włącz'}
                      labelPlacement="start"
                    />
                  )}
                />
              </Grid>
              <RuleComponent rule={rule} index={index} />
            </Fragment>
          );
        })}
      </Grid>
    </TitledContainer>
  );
}

export default Validations;

Validations.propTypes = {
  rules: PropTypes.arrayOf(Object).isRequired,
  type: PropTypes.string.isRequired,
};
