import {
  RESULT_STATUSES,
  RESULT_STATUSES_TRANSLATIONS,
} from '../_constants';
/**
 * Result values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const RESULT_VALUES = [
  {
    id: RESULT_STATUSES.positive,
    label: RESULT_STATUSES_TRANSLATIONS.positive,
  },
  {
    id: RESULT_STATUSES.negative,
    label: RESULT_STATUSES_TRANSLATIONS.negative,
  },
];
