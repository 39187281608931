import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
// eslint-disable-next-line import/no-cycle
import { ELEMENT_VIOLATION_BY_TYPE } from '../../../../../_constants';
import { useApplicationTemplateStyles } from '../../../../../styles/applicationTemplate.styles';

/**
 * Single element component
 *
 * @param {object} props - root props
 * @param {string} props.elementName - element name
 * @param {Array} props.elementItems - element items
 * @returns {SingleElement}
 */
function SingleElement({
  elementName, elementItems,
}) {
  const filteredElement = elementItems.filter((item) => {
    if (item.type === 'field') {
      return true;
    }

    return item.items.length > 0 || item.violations.length > 0;
  });

  if (filteredElement.length === 0) {
    return '';
  }

  return (
    <Box sx={useApplicationTemplateStyles.collectionFieldContainer}>
      <Typography variant="h2" fontFamily="Montserrat-Light, sans-serif" fontWeight="bold" my={2}>
        {`Element: ${elementName}`}
      </Typography>
      {filteredElement.map(({
        items: childItems, label: childLabel, type, violations: childViolations,
      }) => {
        const ViolationsComponent = ELEMENT_VIOLATION_BY_TYPE[type];

        return (
          <ViolationsComponent
            key={uniqid()}
            items={childItems}
            label={childLabel}
            violations={childViolations}
          />
        );
      })}
    </Box>
  );
}

export default SingleElement;

SingleElement.propTypes = {
  elementName: PropTypes.string.isRequired,
  elementItems: PropTypes.arrayOf(Object).isRequired,
};
