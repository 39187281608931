import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * IncubationTakingIntoAccountIndustrySpecialization pdf element.
 *
 * @returns {IncubationTakingIntoAccountIndustrySpecializationPdf}
 */
function IncubationTakingIntoAccountIndustrySpecializationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="incubation_taking_into_account_industry_specialization_specialization_scope" />
        <RowField name="incubation_taking_into_account_industry_specialization_support_for_startups" />
        <RowField name="incubation_taking_into_account_industry_specialization_success_stories" />
      </FieldSorter>
    </View>
  );
}

export default IncubationTakingIntoAccountIndustrySpecializationPdf;
