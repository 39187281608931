import { IMPLEMENTATION_LEVEL_PROGRAM_TYPES } from '../../../_constants';
/**
 * Parser for acquiring implementationLevel name tree.
 *
 * @param {object} implementationLevel - implementationLevel
 * @returns {object}
 */
export const implementationLevelParser = (
  implementationLevel
) => {
  let data = {};
  let current = implementationLevel;
  do {
    const currentType = current.type;
    const type = Object.keys(
      IMPLEMENTATION_LEVEL_PROGRAM_TYPES
    )
      .find(
        (key) => IMPLEMENTATION_LEVEL_PROGRAM_TYPES[key] === currentType
      );
    data = {
      ...data,
      [type]: current.podtytul,
    };

    current = current.parent;
  } while (current);

  return data;
};
