import {
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { useState } from 'react';
import uniqid from 'uniqid';
import { URL } from '../../../_constants';
import { elementHelper } from '../../../_helpers';
import { ConfirmDialog } from '../../../Dialogs';
import { ButtonMenu } from '../../ButtonMenu';
import { theme } from '../../../styles/theme';

const classes = {
  actionColumnButtonsWrapper: {
    '& .MuiButtonBase-root': {
      marginBottom: theme.spacing(0.4),
      minWidth: '170px',
      display: 'block',
      margin: 'auto',
    },
  },
};

/**
 * ElementRow
 *
 * @param {object} props - root props
 * @param {number} props.index - element index
 * @param {object} props.templateElementData - template element data
 * @param {Function} props.removeElementHandler - remove element handler
 * @param {Function} props.selectedElementHandler - selected element handler
 * @returns {ElementRow}
 */
export function ElementRow({
  index, templateElementData, removeElementHandler, selectedElementHandler,
}) {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const isIndicators = templateElementData.elementDefinition['@type'] === 'Indicators';

  return (
    <Draggable draggableId={templateElementData.id} index={index} key={`table-row-${templateElementData.id}`}>
      {(provided) => (
        <TableRow
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          index={index}
        >
          <TableCell>
            {templateElementData.elementDefinition.title}
          </TableCell>
          <TableCell>
            {templateElementData.label}
          </TableCell>
          <TableCell>
            {templateElementData.modules.map((module) => <span style={{ display: 'block' }}>{module.name}</span>)}
          </TableCell>
          <TableCell>
            {templateElementData.groups.join(', ')}
          </TableCell>
          <TableCell sx={classes.actionColumnButtonsWrapper}>
            <ButtonMenu
              buttonTitle="Szczegóły"
              openButtonId={uniqid()}
              buttonProps={{
                color: 'secondary',
                variant: 'contained',
              }}
            >
              <MenuItem
                role="menuitem"
                key="edycja"
                variant="left-border"
                onClick={() => selectedElementHandler(templateElementData)}
              >
                Edycja
              </MenuItem>
              <MenuItem
                role="menuitem"
                key="walidacje"
                variant="left-border"
              >
                Walidacje
              </MenuItem>
              {elementHelper.hasDictionaries(templateElementData.elementDefinition['@type']) && (
                <MenuItem
                  role="menuitem"
                  key="slowniki"
                  variant="left-border"
                  onClick={() => {
                    sessionStorage.setItem('lastBreadcrumb', window.location.pathname);
                    navigate(
                      generatePath(
                        isIndicators ? URL.elementDictionaries.indicators : URL.elementDictionaries.main,
                        {
                          templateElementId: templateElementData.id,
                        }
                      )
                    );
                  }}
                >
                  Słowniki
                </MenuItem>
              )}
              <MenuItem
                role="menuitem"
                key="usun"
                variant="left-border-primary"
                onClick={() => setConfirmDialog(true)}
              >
                Usuń
              </MenuItem>
            </ButtonMenu>
          </TableCell>
          <ConfirmDialog
            closeHandler={() => setConfirmDialog(false)}
            open={confirmDialog}
            confirmButtonHandler={() => removeElementHandler(templateElementData.id)}
            confirmButtonLabel="Tak"
            cancelButtonLabel="Nie"
            cancelButtonColor="primary"
            dialogTitle="Usuń element"
          >
            {`Czy napewno chcesz usunąć element: ${templateElementData.label}`}
          </ConfirmDialog>
        </TableRow>
      )}
    </Draggable>
  );
}

ElementRow.propTypes = {
  templateElementData: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  removeElementHandler: PropTypes.func.isRequired,
  selectedElementHandler: PropTypes.func.isRequired,
};
