import logos from '../../images/loga.svg';

/**
 * Founders logos fragment of LSI Footer.
 *
 * @returns {Logos}
 */
export function Logos() {
  return (
    <img
      src={logos}
      style={{
        float: 'left',
        height: 'auto',
        minHeight: '100px',
        maxHeight: '100px',
        width: '100%',
        maxWidth: '577px',
      }}
      alt="logotypy, kolejno: Fundusze Europejskie, Rzeczpospolita Polska, dofinansowane przez Unię Europejską"
    />
  );
}
