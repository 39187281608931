import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormField } from '../../../Application/Application/FormField';
import { ApiAutocomplete } from '../../../Autocomplete/ApiAutocomplete';
import {
  API_ROUTE,
  DICTIONARIES,
} from '../../../../_constants';
import { useElementContext } from '../../../../Context';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { iriToId } from '../../../../_helpers';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { request } from '../../../../_services';

/**
 * Implementation addresses component
 *
 * @param {object} props - root props
 * @param {number} props.index - item index
 * @returns {ImplementationAddresses}
 */
function ImplementationAddresses({ index }) {
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [cities, setCities] = useState([]);

  const districtRef = useRef();
  const communeRef = useRef();
  const cityRef = useRef();
  const streetRef = useRef();

  const {
    isReadonly,
  } = useElementContext();
  const {
    watch, setValue,
  } = useFormContext();

  const {
    get,
  } = useDictionaryLoader(
    DICTIONARIES.geoDictionariesVoivodeships,
  );
  const voivodeshipValue = watch(`implementation_addresses.${index}.voivodeship`);
  const districtValue = watch(`implementation_addresses.${index}.district`);
  const communeValue = watch(`implementation_addresses.${index}.commune`);

  const getDistrict = async (value) => {
    if (value) {
      const {
        payload, statusSuccess,
      } = await request
        .get(`${API_ROUTE.geoDictionaries.districts}?voivodeship.id=${iriToId(value)}&itemsPerPage=100`);

      if (statusSuccess) {
        setDistricts(payload);
      }

      return;
    }

    setDistricts([]);
  };

  const getCommune = async (value) => {
    if (value) {
      const {
        payload, statusSuccess,
      } = await request
        .get(`${API_ROUTE.geoDictionaries.communes}?district.id=${iriToId(value)}&itemsPerPage=100`);

      if (statusSuccess) {
        setCommunes(payload);
      }

      return;
    }

    setCommunes([]);
  };

  const getCity = async (value) => {
    if (value) {
      const {
        payload, statusSuccess,
      } = await request
        .get(`${API_ROUTE.geoDictionaries.towns}?commune.id=${iriToId(value)}&itemsPerPage=500`);

      if (statusSuccess) {
        setCities(payload);
      }

      return;
    }

    setCities([]);
  };

  const subregion = districts.find((district) => district['@id'] === districtValue)?.subregion || '';

  const setSubregion = () => {
    if (subregion) {
      setValue(`implementation_addresses.${index}.subregion`, {
        value: subregion,
        label: subregion,
      });

      return;
    }

    setValue(`implementation_addresses.${index}.subregion`, null);
  };

  useEffect(() => {
    setSubregion();
  }, [subregion]);

  useEffect(() => {
    if (voivodeshipValue) {
      getDistrict(voivodeshipValue);
    }
    if (districtValue) {
      getCommune(districtValue);
    }
    if (communeValue) {
      getCity(communeValue);
    }
  }, []);

  return (
    <>
      {/* Województwo */}
      <FormField name={`implementation_addresses.${index}.voivodeship`}>
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              getDistrict(event?.target?.value);
              districtRef.current[index].clear();
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.geoDictionariesVoivodeships.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      {/* Powiat */}
      <FormField name={`implementation_addresses.${index}.district`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            /* eslint-disable-next-line no-return-assign */
            ref={(el) => (districtRef.current = {
              ...districtRef.current,
              [index]: el,
            })}
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              getCommune(event?.target?.value);
              communeRef.current[index].clear();
            }}
            onBlur={onBlur}
            options={districts}
            disabled={isReadonly || !voivodeshipValue}
            error={error}
          />
        )}
      </FormField>

      {/* Gmina */}
      <FormField name={`implementation_addresses.${index}.commune`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            /* eslint-disable-next-line no-return-assign */
            ref={(el) => (communeRef.current = {
              ...communeRef.current,
              [index]: el,
            })}
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              getCity(event?.target?.value);
              cityRef.current[index].clear();
            }}
            onBlur={onBlur}
            options={communes}
            disabled={isReadonly || !districtValue}
            error={error}
          />
        )}
      </FormField>

      {/* Miejscowość */}
      <FormField name={`implementation_addresses.${index}.city`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            /* eslint-disable-next-line no-return-assign */
            ref={(el) => (cityRef.current = {
              ...cityRef.current,
              [index]: el,
            })}
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              streetRef.current[index].clear();
            }}
            onBlur={onBlur}
            options={cities}
            disabled={isReadonly || !communeValue}
            error={error}
          />
        )}
      </FormField>

      {/* Podregion (NUTS) */}
      <FormField name={`implementation_addresses.${index}.subregion`}>
        {({ value }) => (
          <TextField
            value={value?.label || ''}
            disabled
          />
        )}
      </FormField>

      {/* Ulica */}
      <FormField name={`implementation_addresses.${index}.street`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            disabled={!watch(`implementation_addresses.${index}.city`) || isReadonly}
            /* eslint-disable-next-line no-return-assign */
            ref={(el) => (streetRef.current = {
              ...streetRef.current,
              [index]: el,
            })}
            filterBy="name"
            staticFilters={{
              'town.id': watch(`implementation_addresses.${index}.city`)
                ? iriToId(watch(`implementation_addresses.${index}.city`))
                : null,
            }}
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={({
              id, name: label,
            }) => ({
              value: id,
              label,
            })}
            textFieldProps={{
              onBlur,
            }}
            changeHandler={onChange}
            baseUrl={API_ROUTE.geoDictionaries.streets}
            error={error}
            minCharsToRequest={2}
          />
        )}
      </FormField>
    </>
  );
}

export default ImplementationAddresses;

ImplementationAddresses.propTypes = {
  index: PropTypes.number.isRequired,
};
