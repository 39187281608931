import {
  useCallback,
  useMemo,
} from 'react';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import { useElementContext } from '../../../Context';
import { LabeledFieldRow } from './LabeledFieldRow';
import { getValidationFieldByRules } from '../../ApplicationTemplate/Element/Validations/templateValidationHelper';
import { getFieldConfig } from '../../../_helpers';
import { useApiApplicationValidator } from '../../ApplicationTemplate/Element/Validations/useApiApplicationValidator';
import {
  FIELD_TYPES,
  REGEX,
} from '../../../_constants';

/**
 * FormField component used in application view.
 * It is not necessary to use this for field rendering.
 * FormField just care of register it to `react-hook-form` or display field as titled grid.
 * IMPORTANT - must be used inside ElementContextProvider, and will render only fields that
 * are inherited from provided element!
 *
 * @param {object} props - root props
 * @param {string} props.name - field name
 * @param {boolean} props.labelled - include field label as grid item
 * @param {Function} props.children - function will be invoked as children
 * @param {string} props.extraLabel - show extra label for field
 * @param {boolean} props.contextHelpLabel - shows context help next to label
 * @param {object} props.rules - rules
 * @returns {FormField}
 */
export function FormField({
  name, labelled, children, extraLabel, rules, contextHelpLabel,
}) {
  const {
    fieldsConfig,
  } = useElementContext();
  const {
    control, setError,
  } = useFormContext();

  /**
   * Field config based on field name.
   * Note that field can be nested. Dot separated notation is used to retrieve nested field.
   * Field also can be indexed (for collection fields) like 'field.0.name` is
   * equivalent of `field.name`.
   */
  const fieldConfig = useMemo(() => getFieldConfig(fieldsConfig, name), [name]);

  const filterOptions = (allChoices) => {
    if (
      fieldConfig?.type === FIELD_TYPES.choice
      && !fieldConfig?.apiEndpoint
      && !allChoices
    ) {
      return fieldConfig.choices;
    }

    if (fieldConfig?.type === FIELD_TYPES.limitedChoice) {
      return allChoices.filter((choice) => (fieldConfig.choices.length === 0
        ? true
        : fieldConfig.choices.includes(choice.id)));
    }

    return allChoices || [];
  };

  const getFilteredChoices = (
    allChoices = fieldConfig?.allChoices || []
  ) => allChoices.filter((choice) => (fieldConfig.choices.length === 0
    ? true
    : fieldConfig.choices.includes(choice.id)));

  const callbackPayload = useMemo(() => ({
    ...fieldConfig,
    name,
    allChoices: filterOptions(fieldConfig?.allChoices).map((choice) => ({
      '@id': choice.id,
      name: choice.label,
    })),
    filterOptions,
    getFilteredChoices,
  }), [fieldConfig, name]);

  const {
    validate,
  } = useApiApplicationValidator({
    fieldName: name,
    setError,
    rules: callbackPayload.rules,
  });

  /**
   * Function to delete unicode character ("\u0000") inside text fields
   *
   * @param {object} event - synthetic base event
   * @param {Function} onChange - function to change value
   */

  const onChangeHandler = (event, onChange) => {
    event?.target?.localName === 'textarea'
      ? onChange({
        target: {
          name,
          value: typeof event.target.value === 'string'
            ? event.target.value?.replace(REGEX.null, '')
            : event.target.value,
        },
      })
      : onChange(event);
  };
  const ControllerWrapper = useCallback((callback) => (

    <Controller
      rules={{
        ...rules,
        ...getValidationFieldByRules(callbackPayload?.rules || [], callbackPayload?.label || callbackPayload?.title),
      }}
      key={name}
      name={name}
      control={control}
      render={({
        field: {
          onChange, value = fieldConfig?.defaultValue || '', onBlur,
        },
        fieldState: {
          error,
        },
      }) => (
        callback({
          onChange: (event) => onChangeHandler(event, onChange),
          value,
          name,
          error,
          onBlur: (event) => {
            onBlur(event);
            validate(value);
          },
          defaultValue: fieldConfig?.defaultValue,
          ...callbackPayload,
        })
      )}
    />
  ), [name]);

  if (!labelled) {
    return ControllerWrapper(children);
  }

  if (!fieldConfig) {
    return '';
  }

  return (
    <LabeledFieldRow fieldConfig={fieldConfig} extraLabel={extraLabel} contextHelpLabel={contextHelpLabel}>
      {ControllerWrapper(children)}
    </LabeledFieldRow>
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  labelled: PropTypes.bool,
  children: PropTypes.func.isRequired,
  extraLabel: PropTypes.string,
  rules: PropTypes.instanceOf(Object),
  contextHelpLabel: PropTypes.bool,
};

FormField.defaultProps = {
  labelled: true,
  extraLabel: '',
  rules: {},
  contextHelpLabel: false,
};
