/**
 * @typedef {object} UserEntriesObject
 * @property {boolean} authenticated - is user authenticated
 * @property {string[]} roles - user roles
 * @property {string} username - username
 * @property {string} token - JWT token used to authenticate
 * @property {string} profile - user profile
 * @property {string[]} profileSwitching - profile switching made by user
 * @property {number} iat - JWT token creation timestamp
 * @property {number} exp - JWT token expire timestamp
 * @property {boolean} switched - user switched to other account
 */

/**
 * @internal
 */
export const sessionService = {
  /**
   * Write object per key to session storage.
   * Values will be stringified to JSON.
   *
   * @param {object} values - values to write
   */
  write: (values) => {
    Object
      .entries(values)
      .forEach(([key, value]) => {
        sessionStorage.setItem(key, JSON.stringify(value));
      });
  },

  /**
   * Returns JSON parsed value from session.
   *
   * @param {string} key - session key
   * @returns {any}
   */
  get: (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (exception) {
      return null;
    }
  },

  /**
   * Session clear.
   */
  clear: () => { sessionStorage.clear(); },

  /**
   * Returns user-related session entries.
   *
   * @returns {UserEntriesObject}
   */
  getUserEntries: () => ({
    authenticated: !!sessionService.get('username'),
    id: sessionService.get('id'),
    username: sessionService.get('username'),
    roles: sessionService.get('roles') || [],
    token: sessionService.get('token'),
    profile: sessionService.get('profile'),
    profileSwitching: sessionService.get('profileSwitching') || [],
    iat: sessionService.get('iat') || 0,
    exp: sessionService.get('exp') || 0,
    switched: sessionService.get('switched') || false,
    firstName: sessionService.get('firstName'),
    lastName: sessionService.get('lastName'),
  }),
};
