import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Rd Infrastructure Module Essence Pdf element.
 *
 * @returns {RdInfrastructureModuleEssencePdf}
 */
function RdInfrastructureModuleEssencePdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="rd_infrastructure_module_essence_module_purpose" />
        <RowField
          name="rd_infrastructure_module_essence_investment_type"
          dictionaryName={DICTIONARIES.investmentTypes.name}
        />
        <RowField name="rd_infrastructure_module_essence_investment_description" />
      </FieldSorter>
    </View>
  );
}

export default RdInfrastructureModuleEssencePdf;
