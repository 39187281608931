import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { printStyle } from '../print.style';
import {
  APPLICATION_STATUSES,
  EXPERT_APPLICATION_FORM_STATUSES,
  PROFILES,
} from '../../../_constants';
import {
  useExpertApplicationFormToPdfContext,
  useApplicationToPdfContext,
} from '../../../Context';

/**
 * Conditional printout watermark
 *
 * @param {object} props - root props
 * @param {string} props.scenario - scenario on the document title basis
 * @param {boolean} props.hideWatermark - powerful flag to force watermark to hide
 * @param {string} props.applicationStatus - application status
 * @param {string} props.expertApplicationStatus - expert application status
 * @returns {Watermark}
 */
function Watermark({
  scenario, hideWatermark, applicationStatus, expertApplicationStatus,
}) {
  const expertApplicationContext = useExpertApplicationFormToPdfContext();
  const applicationContext = useApplicationToPdfContext();

  const SCENARIOS = {
    application: 'Wniosek o dofinansowanie projektu',
    expertCandidate: 'Formularz zgłoszeniowy kandydata na eksperta',
    assessment: 'Arkusz oceny projektu',
  };

  const profile = useMemo(
    () => () => {
      let profileFromContext;
      if (scenario === SCENARIOS.application) {
        profileFromContext = applicationContext.profile;
      }
      if (scenario === SCENARIOS.expertCandidate) {
        profileFromContext = expertApplicationContext.profile;
      }

      return profileFromContext;
    },
    [scenario]
  );

  const RULES = {
    [SCENARIOS.application]: applicationStatus === APPLICATION_STATUSES.edition,
    [SCENARIOS.expertCandidate]: profile() === PROFILES.expert
                                && expertApplicationStatus === EXPERT_APPLICATION_FORM_STATUSES.inEdition,
    [SCENARIOS.assessment]: false,
  };

  const displayRule = scenario in RULES ? RULES[scenario] : true;

  return (
    displayRule && !hideWatermark && (
      <Text
        fixed
        style={printStyle.watermarkWrapper}
        render={() => ('Wydruk próbny')}
      />
    )
  );
}

export default Watermark;

Watermark.propTypes = {
  scenario: PropTypes.string.isRequired,
  hideWatermark: PropTypes.bool,
  applicationStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  expertApplicationStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
};

Watermark.defaultProps = {
  hideWatermark: false,
  applicationStatus: null,
  expertApplicationStatus: null,
};
