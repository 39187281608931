import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import RowField from '../../Components/RowField/RowField';

/**
 * Statements Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - elementData
 * @returns {StatementsPdf}
 */
function StatementsPdf({ elementData }) {
  const { elementDefinition: { fields } } = elementData;

  return (
    <View style={printStyle.table}>
      {fields.map((field) => (
        <Fragment key={field?.uuid}>
          <PrintoutTableRow>
            <PrintoutTableCell bgColor="gray" rowSpan={1}>
              {`${field.ordinalNumber}. ${field.label}`}
            </PrintoutTableCell>
          </PrintoutTableRow>
          {field.hasAdditionalOptions && field.options.map((option) => (
            <RowField
              key={option?.id}
              label={option.label}
              name={`${field.name}.${option.name}`}
            />
          ))}
        </Fragment>
      ))}
    </View>
  );
}

export default StatementsPdf;

StatementsPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
