import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Container,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { theme } from '../../../styles/theme';
/**
 * Application validation form.
 *
 * @param {object} props - root props
 * @param {Function} props.closeHandler - function to close modal
 * @param {object} props.violationsData - violations data
 * @param {boolean} props.isLoaded - is loaded violations
 * @returns {ApplicationValidationForm}
 */
export function ApplicationValidationForm({
  closeHandler, violationsData, isLoaded,
}) {
  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      <Container id="MJPpioZ4DoUQO1x" maxWidth="xl">
        {violationsData.length === 0 && (
          <Typography align="center">Brak błędów</Typography>
        )}
        {violationsData.map(({
          sectionTitle, subsectionTitle, rowTitle, fieldName, message,
        }) => (
          <Box key={uniqid()} mb={2}>
            {sectionTitle
            && (
              <Typography
                display="flex"
                textAlign="left"
                variant="h2"
                fontFamily='"Montserrat-Light",sans-serif'
                width="100%"
                height="4rem"
                color={theme.palette.text.brand}
                mt={0.5}
                mb={2}
                px={2}
                sx={{
                  backgroundColor: theme.palette.brandGray20.main,
                }}
              >
                {sectionTitle}
              </Typography>
            )}
            {subsectionTitle
            && (
              <Typography variant="h3" mt={3} mb={2} sx={{ fontFamily: '"Montserrat-Light",sans-serif' }}>
                {subsectionTitle}
              </Typography>
            )}
            {rowTitle
            && (
              <Typography variant="h4" mt={3} mb={2} sx={{ fontFamily: '"Montserrat-Light",sans-serif' }}>
                {rowTitle}
              </Typography>
            )}
            <Typography variant="string">
              {fieldName}
              {fieldName ? ': ' : ''}
              <Typography color={theme.palette.primary.main} variant="string">
                {message}
              </Typography>
            </Typography>
          </Box>
        ))}
      </Container>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          id="FDFBxNDa4cl2Z9c"
          variant="contained"
          color="secondary"
          onClick={closeHandler}
        >
          Zamknij
        </Button>
      </Box>
    </>
  );
}

ApplicationValidationForm.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func,
  violationsData: PropTypes.arrayOf(Object),
};

ApplicationValidationForm.defaultProps = {
  closeHandler: null,
  violationsData: null,
};
