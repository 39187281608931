import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Divider,
  Button,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import { ContactForLoggedInValidation } from './ContactForLoggedIn.validation';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../_constants';
import { styleClassesContactForLoggedIn } from './ContactForLoggedIn.style';
import { request } from '../../_services';
import CharsCounter from '../CharsCounter';
import { ImprovedMaskedInput } from '../ImprovedMaskedInput';
import { ContainerLoader } from '../Application/Application/ContainerLoader';
import { useGlobalContext } from '../../Context';
import { handleError } from '../../_helpers';

/**
 * Report issue form for authorized users.
 *
 * @param {object} props root props
 * @param {Function} props.onSuccess - on success handler
 * @param {('pageHeading'| 'dialogHeading')} props.headingVariant - heading typography variant:
 * 'pageHeading' for page,
 * 'dialogHeading' for dialog
 * @returns {ContactForLoggedIn} contact form
 */
export function ContactForLoggedIn({
  onSuccess,
  headingVariant = 'pageHeading',
}) {
  const classes = styleClassesContactForLoggedIn;
  const { notify } = useGlobalContext();
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [lockedForm, setLockedForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const redirectToUserIssuesList = () => {
    if (currentLocation.pathname === '/user-issues') {
      navigate('/home');
      navigate('/user-issues');
    }
    navigate('/user-issues');
  };

  const {
    control, handleSubmit, reset, setError,
  } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      phoneNumber: '',
      subject: '',
      description: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    loadUsersData();
  }, []);

  const loadUsersData = async () => {
    setLoading(true);
    const {
      payload,
      statusCode,
      statusSuccess,
    } = await request.get(API_ROUTE.usersMe);
    if (!statusSuccess && statusCode === 403 && payload) {
      navigate('/account/settings#SjsoVqEQS4rG7Nl');
      notify(
        payload.detail || 'Aby skorzystać z fomularza należy zaakceptować oświadczenia',
        'error',
        true
      );
      onSuccess(); // dialog close handler
    }
    if (statusSuccess) {
      reset(
        {
          fullName: `${payload.firstName} ${payload.lastName}`,
          email: payload.email,
          phoneNumber: payload.phoneNumber,
        }
      );
    }

    setLoading(false);
  };

  /**
   * Handle submit.
   *
   * @param {object} formData - form data
   */
  const submitHandler = async (formData) => {
    setLockedForm(true);
    const {
      statusSuccess,
      payload,
      violations,
    } = await request.post(
      API_ROUTE.issues,
      {
        full_name: formData.fullName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        application_number: formData.applicationNumber,
        subject: formData.subject,
        description: formData.description,
      }
    );

    if (statusSuccess) {
      if (onSuccess) {
        onSuccess();
      }
      redirectToUserIssuesList();
      setTimeout(() => {
        notify(payload.message, 'info');
      }, 100);

      return;
    }

    handleError(setError, violations || []);

    setLockedForm(false);
  };

  if (loading) {
    return <ContainerLoader />;
  }

  return (
    <Box sx={classes.mainForm}>
      <div style={classes.titleContainer}>
        <Typography variant={headingVariant} sx={classes.boxTitle}>Formularz kontaktowy</Typography>
      </div>

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="fullName"
          control={control}
          rules={ContactForLoggedInValidation.fullName}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              label="Imię i Nazwisko"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                inputComponent: ImprovedMaskedInput,
                endAdornment: (<CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.MEDIUM} />),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="email"
          control={control}
          rules={ContactForLoggedInValidation.email}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              label="E-mail"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.MEDIUM} />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="phoneNumber"
          control={control}
          rules={ContactForLoggedInValidation.phoneNumber}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              label="Telefon"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.MEDIUM} />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
            />
          )}
        />
      </FormControl>

      <Divider sx={classes.contentDivider} />

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="applicationNumber"
          control={control}
          rules={ContactForLoggedInValidation.applicationNumber}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              label="Numer wniosku"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.MEDIUM} />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="subject"
          control={control}
          rules={ContactForLoggedInValidation.subject}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              label="Temat zgłoszenia"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.LONG} />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.LONG }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={classes.inputContainer}>
        <Controller
          name="description"
          control={control}
          rules={ContactForLoggedInValidation.description}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              multiline
              rows={5}
              label="Opis"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value?.length ?? 0} maxLength={CHARS_LIMIT.STORY} />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.STORY }}
            />
          )}
        />
      </FormControl>

      <div style={classes.buttonWrapper}>
        <Button
          id="jIGI3BobmHk9ohJ"
          disabled={lockedForm}
          variant="contained"
          sx={classes.saveButton}
          color="primary"
          onClick={handleSubmit(submitHandler)}
        >
          Wyślij
        </Button>
      </div>
    </Box>
  );
}

ContactForLoggedIn.propTypes = {
  onSuccess: PropTypes.func || null,
  headingVariant: PropTypes.oneOf(['dialogHeading', 'pageHeading']).isRequired,
};

ContactForLoggedIn.defaultProps = {
  onSuccess: null,
};
