import HTMLReactParser from 'html-react-parser';
import { isArray } from 'lodash';
import { Text } from '@react-pdf/renderer';
import SingleElement from '../../Components/SingleElement/SingleElement';
import { useApplicationToPdfContext } from '../../../../Context';
import { convertHtmlTagsToPdfTags } from '../../../../_helpers';
import HtmlTag from '../../Components/HtmlTag/HtmlTag';

/**
 * Information clause pdf element.
 *
 * @returns {InformationClausePdf}
 */
function InformationClausePdf() {
  const {
    managedApplicationTemplate: {
      informationClause,
    },
  } = useApplicationToPdfContext();

  if (!informationClause) {
    return '';
  }

  const convertedStringToHtml = isArray(HTMLReactParser(informationClause))
    ? convertHtmlTagsToPdfTags(HTMLReactParser(informationClause))
    : informationClause;

  return (
    <SingleElement title="Klauzula informacyjna">
      {isArray(convertedStringToHtml)
        ? convertedStringToHtml.map((singleTag) => <HtmlTag tagData={singleTag} />)
        : (<Text>{convertedStringToHtml}</Text>)}
    </SingleElement>
  );
}

export default InformationClausePdf;
