import { ACTION_TYPES } from '../_constants';

const initialState = {
  dictionaries: {},
  dictionariesLoadQueue: 0,
  loadingState: {},
  openedMenuTab: {
    menuId: undefined,
    title: undefined,
    element: '',
  },
  actions: {
    save: 0,
    isOpenVerifyCorrectness: false,
    print: 0,
    submit: 0,
    isConfirmDialogOpen: false,
  },
  isCorrection: false,
  previousPath: null,
};

/**
 * Reducer for application store.
 *
 * @param {object} state - current state
 * @param {object} action - dispatched state
 * @returns {object} - reduced state
 */
// eslint-disable-next-line default-param-last
const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
  case ACTION_TYPES.DICTIONARY_POPULATE:
    return {
      ...state,
      dictionaries: {
        ...state.dictionaries,
        [action.name]: action.values,
      },
    };
  case ACTION_TYPES.RESOURCE_FETCH_START:
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        [action.name]: true,
      },
    };
  case ACTION_TYPES.RESOURCE_FETCH_FINISH:
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        [action.name]: undefined,
      },
    };
  case ACTION_TYPES.DICTIONARY_LOAD_QUEUE_ADD:
    return {
      ...state,
      dictionariesLoadQueue: state.dictionariesLoadQueue + 1,
    };
  case ACTION_TYPES.DICTIONARY_LOAD_QUEUE_SUBTRACT:
    return {
      ...state,
      dictionariesLoadQueue: state.dictionariesLoadQueue - 1,
    };
  case ACTION_TYPES.APPLICATION_SAVE:
    return {
      ...state,
      actions: {
        ...state.actions,
        save: state.actions.save + 1,
      },
    };
  case ACTION_TYPES.APPLICATION_SUBMIT:
    return {
      ...state,
      actions: {
        ...state.actions,
        submit: state.actions.submit + 1,
      },
    };
  case ACTION_TYPES.OPEN_APPLICATION_CONFIRM_DIALOG:
    return {
      ...state,
      actions: {
        ...state.actions,
        isConfirmDialogOpen: true,
      },
    };
  case ACTION_TYPES.CLOSE_APPLICATION_CONFIRM_DIALOG:
    return {
      ...state,
      actions: {
        ...state.actions,
        isConfirmDialogOpen: false,
      },
    };
  case ACTION_TYPES.RESET_BUTTONS_STATE:
    return {
      ...state,
      actions: {
        save: 0,
        isOpenVerifyCorrectness: state.actions.isOpenVerifyCorrectness === true,
        print: 0,
        submit: 0,
        isConfirmDialogOpen: false,
      },
    };
  case ACTION_TYPES.OPEN_MENU_GROUP:
    return {
      ...state,
      openedMenuTab: {
        menuId: action.menuId,
        element: action.element,
        title: action.title,
      },
    };
  case ACTION_TYPES.CLOSE_MENUS:
    return {
      ...state,
      openedMenuTab: {
        menuId: undefined,
        element: '',
        title: undefined,
      },
    };
  case ACTION_TYPES.APPLICATION_VERIFY_CORRECTNESS:
    return {
      ...state,
      actions: {
        ...state.actions,
        isOpenVerifyCorrectness: action.payload,
      },
    };
  case ACTION_TYPES.APPLICATION_IS_CORRECTION:
    return {
      ...state,
      isCorrection: action.payload.isCorrection,
      previousPath: action.payload.previousPath,
    };
  case ACTION_TYPES.SET_PREVIOUS_PATH:
    return {
      ...state,
      previousPath: action.previousPath,
    };
  default:
    return state;
  }
};

export default applicationReducer;
