import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';

/**
 * IncubationTakingIntoAccountIndustrySpecialization element renderer.
 *
 * @returns {IncubationTakingIntoAccountIndustrySpecialization}
 */
export default function IncubationTakingIntoAccountIndustrySpecialization() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Zakres specjalizacji branżowej/ścieżki tematycznej inkubacji (z wyłączeniem rozwiązań z obszaru IT/ICT),
      powiązanej z obszarami regionalnych inteligentnych specjalizacji wspólnych dla co najmniej dwóch województw
      Polski Wschodniej */}
      <FormField name="incubation_taking_into_account_industry_specialization_specialization_scope">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wsparcie startupów realizowane w ramach inkubacji branżowej/tematycznej */}
      <FormField name="incubation_taking_into_account_industry_specialization_support_for_startups">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Success stories – działania wnioskodawcy lub partnera projektowego oraz co najmnej jednego partnera
        ekosystemowego na rzecz startupów z wybranej specjalizacji branżowej/ścieżki tematycznej wraz z okresem
        tych działań */}
      <FormField name="incubation_taking_into_account_industry_specialization_success_stories">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
