/**
 * Evaluation types values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const evaluationTypesValues = [
  {
    id: 'evaluation_type_experts_panel',
    label: 'Panel ekspertów',
  },
  {
    id: 'evaluation_type_merit',
    label: 'Ocena merytoryczna',
  },
];
