import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  TableCell,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useFormContext } from 'react-hook-form';
import { useGlobalDialog } from '../../../Context';
import { SingleStatementForm } from './SingleStatement.form';

/**
 * Statements point row.
 *
 * @param {object} props - root props
 * @param {object} props.pointData - current row data
 * @param {number} props.index - row index
 * @param {Function} props.deletePoint - delete button handler
 * @returns {StatementsPointRow} - component
 */
export function StatementsPointRow({
  pointData,
  index,
  deletePoint,
}) {
  const {
    render, closeAll,
  } = useGlobalDialog();
  const { setValue } = useFormContext();

  const {
    label,
    ordinalNumber,
  } = pointData;

  return (
    <Draggable draggableId={`statement_${ordinalNumber}`} index={index} key={index}>
      {(provided) => (
        <TableRow
          key={ordinalNumber}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          index={index}
        >
          <TableCell>
            {ordinalNumber}
          </TableCell>
          <TableCell>
            {label}
          </TableCell>
          <TableCell>
            <Box display="flex" flexWrap="wrap" gap={1}>
              <Button
                color="secondary"
                variant="contained"
                aria-label="Edytuj punkt oświadczeń"
                id={uniqid()}
                onClick={() => render(
                  <SingleStatementForm
                    singleStatement={pointData}
                    handleValue={setValue}
                    index={index}
                    handleClose={closeAll}
                  />,
                  `Edycja oświadczenia: ${pointData.label}`
                )}
                fullWidth
              >
                Edycja
              </Button>
              <Button
                variant="contained"
                color="primary"
                aria-label="Usuń punkt z oświadczeń"
                id={uniqid()}
                onClick={() => deletePoint(index)}
                fullWidth
              >
                Usuń
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
}

StatementsPointRow.propTypes = {
  pointData: PropTypes.shape({
    ordinalNumber: PropTypes.number.isRequired,
    label: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  deletePoint: PropTypes.func.isRequired,
};
