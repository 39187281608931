import { BOOLEAN_VALUES } from '../_constants';

/**
 * Boolean values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const booleanValuesOptions = [
  {
    id: 'true',
    label: 'Tak',
  },
  {
    id: 'false',
    label: 'Nie',
  },
];

/**
 * Boolean values with polish id name for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const yesNoValues = [
  {
    id: BOOLEAN_VALUES.TRUE,
    label: 'Tak',
  },
  {
    id: BOOLEAN_VALUES.FALSE,
    label: 'Nie',
  },
];
