import { REGEX } from '../../_constants';

export const ContactForLoggedInValidation = {
  fullName: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Imię i nazwisko"',
    },
  },
  email: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Email"',
    },
    pattern: REGEX.email,
  },
  phoneNumber: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Telefon".',
    },
  },
  applicationNumber: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Numer wniosku".',
    },
  },
  subject: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Temat zgłoszenia".',
    },
  },
  description: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Opis".',
    },
  },
};
