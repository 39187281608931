import PropTypes from 'prop-types';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { useElementContext } from '../../../../Context';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../../_helpers';
import {
  innerCollectionStyles,
  innerCollectionWrapperStyles,
} from '../../../../styles/innerCollectionFieldContainer';

/**
 * Commitment person component
 *
 * @param {object} props - root props
 * @param {number} props.parentIndex - parent index
 * @returns {CommitmentPerson}
 */
function CommitmentPerson({ parentIndex }) {
  const collectionFieldName = `rd_project_team_project_team.${parentIndex}.commitment_person`;

  const {
    control, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const commitmentPersonConfig = fieldsConfig?.rd_project_team_project_team?.fields?.commitment_person;
  const commitmentPersonSubfields = commitmentPersonConfig?.fields || {};
  const commitmentPersonFieldObject = getCollectionFieldObject(
    commitmentPersonSubfields,
    fields,
  );

  const {
    maxRowNumber,
    minRowNumber,
  } = commitmentPersonConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(commitmentPersonSubfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <Box sx={innerCollectionWrapperStyles}>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            collectionFieldName,
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(commitmentPersonSubfields)}
          key={field.id}
          isIndexed
          sx={innerCollectionStyles}
        >
          <FormField name={`${collectionFieldName}.${index}.title`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`${collectionFieldName}.${index}.institution`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`${collectionFieldName}.${index}.role`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`${collectionFieldName}.${index}.involvement_dimension`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`${collectionFieldName}.${index}.involvement_start_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`${collectionFieldName}.${index}.involvement_end_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="8TksWOfZrlRknMn"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              commitmentPersonFieldObject,
            )}

          >
            Dodaj informację o zaangażowaniu
          </Button>
        </Grid>
      )}
    </Box>
  );
}

export default CommitmentPerson;

CommitmentPerson.propTypes = {
  parentIndex: PropTypes.number.isRequired,
};
