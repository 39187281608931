import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Project general information pdf element.
 *
 * @returns {ProjectGeneralInformationPdf}
 */
function ProjectGeneralInformationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Tytuł projektu */}
        <RowField name="project_title" isSingleRow />
        {/* Grupy docelowe */}
        <RowField name="target_groups" />
        {/* Data rozpoczęcia realizacji projektu */}
        <RowField name="project_start_date" />
        {/* Data zakończenia realizacji projektu */}
        <RowField name="project_finish_date" />
        {/* Opis projektu */}
        <RowField name="project_description" isSingleRow />
        {/* Dziedzina projektu */}
        <RowField name="project_area" dictionaryName={DICTIONARIES.projectAreas.name} />
        {/* Numer projektu w ramach programu DIGITAL/DEP */}
        <RowField name="project_number_digital_dep" />
        {/* Data podpisania umowy z programu DIGITAL/DEP */}
        <RowField name="signing_the_contract_date_digital_dep" />
        {/* Planowana data podpisania umowy z programu DIGITAL/DEP */}
        <RowField name="planned_signing_the_contract_date_digital_dep" />
        {/* Porównanie z zakresu projektów finansowanych z programów FENG oraz DIGITAL */}
        <RowField name="projects_financed_from_the_feng_and_digital_programs_comparison" />
        {/* Zgodnośc z zakresem naboru */}
        <RowField name="recruitment_scope_compliance" isSingleRow />
        {/* Obszar realizacji przedsięwzięcia */}
        <RowField
          name="project_implementation_regions"
          dictionaryName={DICTIONARIES.projectImplementationRegions.name}
        />
        {/* Platforma startowa */}
        <RowField name="launch_platform" dictionaryName={DICTIONARIES.launchPlatforms.name} />
        {/* Inny program inkubacji, jaki? */}
        <RowField name="other_incubation_program" isSingleRow />
        {/* Nazwa produktu */}
        <RowField name="project_general_information_product_name" isSingleRow />
        {/* Krótki opis produktu */}
        <RowField name="project_general_information_short_product_description" isSingleRow />
        {/* Kategoria konkursu */}
        <RowField
          name="project_general_information_competition_category"
          dictionaryName={DICTIONARIES.projectGeneralInformationCompetitionCategory.name}
        />
      </FieldSorter>
    </View>
  );
}

export default ProjectGeneralInformationPdf;
