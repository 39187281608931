import { useEffect } from 'react';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';

/**
 * Module implementation potential component
 *
 * @returns {ModuleImplementationPotential}
 */
function ModuleImplementationPotential() {
  const {
    fieldsConfig, isReadonly, id: elementId,
  } = useElementContext();

  const {
    control, setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: `module_implementation_potentials-${elementId}`,
  });

  const potentialConfig = fieldsConfig[`module_implementation_potentials-${elementId}`];
  const subfields = potentialConfig.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = potentialConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.moduleImplementationPotentialDocumentTypes);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <LabeledFieldRow fieldConfig={potentialConfig} contextHelpLabel fullWidth>
      {fields.map((item, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'module_implementation_potentials',
            index,
          )}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={item.id}
          buttonRemoveVisible={fields.length > minRowNumber}
          isIndexed
        >
          {/* eslint-disable-next-line max-len */}
          <FormField name={`module_implementation_potentials-${elementId}.${index}.module_implementation_potential_document_type`}>
            {({
              name, onChange, value, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                options={filterOptions(get(DICTIONARIES.moduleImplementationPotentialDocumentTypes.name))}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>

          <FormField
            name={`module_implementation_potentials-${elementId}.${index}.module_implementation_document_description`}
          >
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField
            name={`module_implementation_potentials-${elementId}.${index}.module_implementation_document_issued_at`}
          >
            {({
              name, onChange, value, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* eslint-disable-next-line max-len */}
          <FormField name={`module_implementation_potentials-${elementId}.${index}.module_implementation_document_planned_issue_at`}>
            {({
              name, onChange, value, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}

      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="Aw5jXYA3uA7zVd9"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}

export default ModuleImplementationPotential;
