import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { ModulesSelectionList } from './ModulesSelection.list';
import { ModulesSelectionForm } from './ModulesSelection.form';

/**
 * ModulesSelectionDialog component.
 *
 * @param {object} props root props
 * @param {string} props.applicationTemplateId application template id
 * @param {Function} props.closeHandler dialog close handler
 * @returns {ModulesSelectionDialog} modules selection dialog
 */
export function ModulesSelectionDialog({
  applicationTemplateId,
  closeHandler,
}) {
  const [changeWatcher, setChangeWatcher] = useState(0);
  const [modulesSelections, setModulesSelections] = useState([]);
  const [selectedModulesSelection, setSelectedModulesSelection] = useState(null);

  useEffect(() => {
    /**
     * Loads api data.
     */
    const loadApiData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationTemplates}/${applicationTemplateId}/modules-selections`
      );

      if (statusSuccess) {
        setModulesSelections(payload);
      }
    };

    loadApiData();
  }, [applicationTemplateId, changeWatcher]);

  /**
   * Increment change watcher to trigger useEffect to reload modules selections.
   */
  const reloadModules = () => {
    setChangeWatcher(changeWatcher + 1);
  };

  return (
    <>
      {selectedModulesSelection ? (
        <ModulesSelectionForm
          initialValues={{
            applicationTemplate: `/lsi/recruitments/api/application-templates/${applicationTemplateId}`,
            ...selectedModulesSelection,
          }}
          onSuccess={reloadModules}
          mode={Object.values(selectedModulesSelection).length === 0 ? 'create' : 'edit'}
          cancelButtonHandler={() => {
            setSelectedModulesSelection(null);
          }}
        />
      ) : (
        <>
          <ModulesSelectionList
            modulesSelections={modulesSelections}
            selectHandler={setSelectedModulesSelection}
            onRemoveSuccess={reloadModules}
          />
          <Box display="flex" justifyContent="center" mt={5}>
            <Button
              id="rpTYhnd5bxyrcBA"
              aria-label="Zamknij okno konfiguracji modułów"
              variant="outlined"
              color="primary"
              onClick={closeHandler}
            >
              Zamknij
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

ModulesSelectionDialog.propTypes = {
  applicationTemplateId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
