/**
 * Function to get own indicators.
 *
 * @param {object} elementsConfig - elements config
 * @param {Function | object} getValues - function from useForm hook to getValues
 * @param {Array} value - current value
 * @param {string} moduleName - module name
 * @returns {Array}
 */
export const getOwnIndicators = (elementsConfig, getValues, value, moduleName = null) => {
  const noApplicable = {
    id: null,
    name: 'Nie dotyczy',
  };
  const indicatorsWithoutModule = [];

  const generalIndicatorId = Object.values(elementsConfig)
    .filter(({ elementData }) => elementData.elementDefinition['@type'] === 'Indicators')
    .find(({ elementData: { modules } }) => (moduleName
      ? modules.includes(moduleName) : modules.length === 0))?.elementData?.id;

  if (typeof getValues === 'function') {
    Object.keys(getValues()).forEach((field) => {
      if (field.includes(generalIndicatorId) && getValues(field)) {
        indicatorsWithoutModule.push(...getValues(field));
      }
    });
  }

  if (typeof getValues === 'object') {
    Object.keys(getValues).forEach((field) => {
      if (field.includes(generalIndicatorId) && getValues[field]) {
        indicatorsWithoutModule.push(...getValues[field]);
      }
    });
  }

  const ownIndicators = indicatorsWithoutModule.filter((indicator) => indicator.own);
  if (ownIndicators.length === 0) {
    return [];
  }

  if (value?.includes(null)) {
    return [noApplicable];
  }

  if (value?.length > 0) {
    return ownIndicators;
  }

  return [...ownIndicators, noApplicable];
};
