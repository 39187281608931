import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  Typography,
  Box,
} from '@mui/material';
import uniqid from 'uniqid';

/**
 * Onclick expendable span text element.
 *
 * @param {object} props - root props
 * @param {string} props.shortText - short/partial text
 * @param {string} props.fullText - full text
 * @param {string} props.variant - type variant
 * @returns {ExpandableText}
 */
export default function ExpandableText({
  shortText, fullText, variant,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (variant === 'text') {
    return (
      <Typography mb={3} component="p">
        {expanded ? fullText : shortText}
        <Button
          type="button"
          variant="text"
          color="secondary"
          id={uniqid()}
          onClick={handleExpand}
          sx={{
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          {expanded ? 'Pokaż mniej' : '(...) Pokaż więcej'}
        </Button>
      </Typography>
    );
  }

  if (variant === 'html') {
    return <Box mb={3}>{expanded ? fullText : shortText}</Box>;
  }

  return (
    <span
      onClick={handleExpand}
      aria-hidden="true"
      style={{
        cursor: 'pointer',
        textAlign: 'left',
      }}
    >
      {expanded ? fullText : shortText}
    </span>
  );
}

ExpandableText.propTypes = {
  shortText: PropTypes.string.isRequired,
  fullText: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['id', 'text', 'html']),
};

ExpandableText.defaultProps = {
  variant: 'id',
};
