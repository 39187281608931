import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import RowField from '../../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../../_constants';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Ue Horizontal Policies Project Compliance Environmental Protection Pdf element.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf}
 */
function UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.legal_act`}
            value={item.legal_act}
            oldValue={previousRowData?.legal_act}
            dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectComplianceLegalActs.name}
          />
          <RowField
            name={`${collectionName}.${index}.justification`}
            value={item.justification}
            oldValue={previousRowData?.justification}
            isSingleRow
          />
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf;

UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf.defaultProps = {
  previousRowData: {},
};
