/**
   * Creates options for the selected number range for the "CustomAutocomplete" component.
   * ex. num1 = -1, num2 = 2 => [{id: "-1", label: "-1"}, {id: "0", label: "0"}, ...]
   *
   * @param {number} num1 - first value in the range
   * @param {number} num2 - last value in the range
   * @param {boolean} isNumeric - is id must be numeric
   * @returns {Array}
   */
export const createNumericOptionsFromRange = (num1, num2, isNumeric = false) => {
  const arr = [];
  for (let i = num1; i <= num2; i++) {
    arr.push({
      '@id': isNumeric ? i : i.toString(),
      name: i.toString(),
    });
  }

  return arr;
};
