import { isEmpty } from 'lodash';
import { getElement } from '../Features/ApplicationTemplate';
import { getPdfElement } from '../Features/PrintToPdf/PrintApplication/mappingRegistry.pdf';
import {
  DICTIONARIES,
  FIELD_TYPES_MAP,
} from '../_constants';

const defaultStore = {
  /**
   * Element configs grouped by template element id.
   */
  elementsConfig: {},

  /**
   * All application template fields as object.
   */
  fieldsConfig: {},

  /**
   * Initial form values inherited from application data.
   * It is simple flat object {formDataKey => value}.
   */
  initialFormData: {},

  /**
   * All dictionaries used in application
   */
  allDictionariesList: [],

  /**
   * Elements order
   */
  elementsOrder: [],

  /**
   * Information clause
   */
  informationClause: '',
};

const store = {
  ...defaultStore,
};

const printoutStore = {
  ...defaultStore,
};

export const MAPPING_TYPE = {
  application: 'application',
  printToPdf: 'printToPdf',
};

const MAPPING_ELEMENT_TYPE = {
  application: getElement,
  printToPdf: getPdfElement,
};

const STORE_BY_MAPPING_TYPE = {
  [MAPPING_TYPE.application]: store,
  [MAPPING_TYPE.printToPdf]: printoutStore,
};

/**
 * Renews store to its original state.
 *
 * @param {string} mappingType - mapping type that takes mapped elements
 */
const renewStore = (mappingType) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];

  selectedStore.elementsConfig = {};
  selectedStore.fieldsConfig = {};
  selectedStore.initialFormData = {};
  selectedStore.allDictionariesList = [];
};

/**
 * Gets initial value for field by given type.
 *
 * @param {string} type - input type
 * @returns {any} - initial value
 */
const getInitialValueByType = (type) => FIELD_TYPES_MAP[type] || '';

/**
 * Initialize single form field.
 *
 * @param {object} currentApplicationData - data for the current element
 * @param {string} fieldName - name of the form field
 * @param {*} fieldData - data for the form field
 * @param {string} mappingType - mapping type that takes mapped elements
 */
const initializeFormField = (currentApplicationData, fieldName, fieldData, mappingType) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];

  const { type } = fieldData;
  const collectionInitialData = type === 'collection'
    ? currentApplicationData[fieldName]?.filter((item) => item)
    : currentApplicationData[fieldName];
  selectedStore.initialFormData[fieldName] = !isEmpty(currentApplicationData)
    ? collectionInitialData : getInitialValueByType(type);
};

/**
 * Initialize form data values from application to store.
 *
 * @param {object} currentApplicationData - application data
 * @param {string} mappingType - mapping type that takes mapped elements
 */
const initializeFormData = (currentApplicationData, mappingType) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];

  Object.entries(selectedStore.fieldsConfig).forEach(([fieldName, fieldData]) => {
    initializeFormField(currentApplicationData, fieldName, fieldData, mappingType);

    if (fieldData['@type'] === 'CollectionField') {
      Object.values(fieldData.fields).forEach((field) => {
        initializeFormField(currentApplicationData, field.name, field, mappingType);
      });
    }
  });
};

/**
 * Types of items that may appear multiple times in a single application.
 * */
const typeMultipleElements = [
  'hrf',
  'risks',
  'indicators',
  'module_implementation_potential',
  'technical_resources_and_intangible_assets',
];

/**
 * Save the dictionaries used in the application
 *
 * @param {string} apiEndpoint - api endpoint
 * @param {string} mappingType - mapping type that takes mapped elements
 * @param {string} elementId - element id
 * @param {string} applicationId - application id
 */
const saveUsedDictionaries = (apiEndpoint, mappingType, elementId, applicationId) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];
  Object.values(DICTIONARIES).forEach((dictionary) => {
    if (dictionary?.path?.includes(apiEndpoint)) {
      selectedStore.allDictionariesList.push({
        name: `${dictionary.name}-${elementId}`,
        path: dictionary.path.includes('?')
          ? `${dictionary.path}&application.id=${applicationId}&templateElement.id=${elementId}`
          : `${dictionary.path}?application.id=${applicationId}&templateElement.id=${elementId}`,
      });
    }
  });
};

/**
 * Map subfields of field (if exists).
 * Subfields will be mapped from array to {subfieldName: subfieldData}.
 *
 * @param {object} fieldData - field data
 * @param {string} elementId - main element ID
 * @param {string} elementName - main element name
 * @param {string} mappingType - mapping type that takes mapped elements
 * @param {string} applicationId - application id
 * @returns {object}
 */
const mapSubfields = (fieldData, elementId, elementName, mappingType, applicationId) => {
  const { fields } = fieldData;

  if (!fields) {
    if (fieldData.apiEndpoint) {
      saveUsedDictionaries(fieldData.apiEndpoint, mappingType, elementId, applicationId);
    }

    return fieldData;
  }

  const isMultipleElements = typeMultipleElements.includes(elementName);

  return {
    ...fieldData,
    name: isMultipleElements ? `${fieldData.name}-${elementId}` : fieldData.name,
    fields: fields.reduce((prev, subfieldData) => {
      if (subfieldData.apiEndpoint) {
        saveUsedDictionaries(subfieldData.apiEndpoint, mappingType, elementId, applicationId);
      }

      return ({
        ...prev,
        [subfieldData.name]: subfieldData.type === 'collection'
          ? mapSubfields(subfieldData, elementId, subfieldData.name, mappingType, applicationId)
          : subfieldData,
      });
    }, {}),
  };
};

/**
 * Save element config to store.
 *
 * @param {object} elementData - template element data
 * @param {string} mappingType - mapping type that takes mapped elements
 * @param {string} applicationId - application id
 */
const saveElementConfig = (elementData, mappingType, applicationId) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];
  const {
    id, elementDefinition: {
      name, fields,
    },
  } = elementData;
  if (!(id in selectedStore.elementsConfig)) {
    selectedStore.elementsConfig[id] = {};
  }

  selectedStore.elementsConfig[id] = {
    elementData,
    Component: MAPPING_ELEMENT_TYPE[mappingType](name),
  };

  const isMultipleElements = typeMultipleElements.includes(name);

  selectedStore.fieldsConfig = {
    ...selectedStore.fieldsConfig,
    ...fields.reduce((prev, fieldData) => ({
      ...prev,
      [isMultipleElements
        ? `${fieldData.name}-${id}`
        : fieldData.name]: mapSubfields(fieldData, id, name, mappingType, applicationId),
    }), {}),
  };
};

/**
 * It is responsible for such things as:
 *  - creating the initial form data data for application
 *  - loading the current data into the application form
 *  - assigning appropriate components to the template elements
 *
 * @param {object} applicationData - current application data
 * @param {object} applicationTemplate - application template
 * @param {string} applicationId - application id
 * @param {Function} mappingType - mapping type that takes mapped elements
 * @param {string} informationClause - information clause
 * @returns {object}
 * */
export const applicationTemplateDataStore = (
  applicationData,
  applicationTemplate,
  applicationId,
  mappingType = MAPPING_TYPE.application,
  informationClause = '',
) => {
  const selectedStore = STORE_BY_MAPPING_TYPE[mappingType];

  renewStore(mappingType);
  if (!applicationData || !applicationTemplate || !('elements' in applicationTemplate)) {
    return selectedStore;
  }

  applicationTemplate.elements.forEach((templateElement) => {
    saveElementConfig(templateElement, mappingType, applicationId);
  });

  selectedStore.elementsOrder = applicationTemplate.elementsOrder;

  if (informationClause) {
    selectedStore.informationClause = informationClause;
  }

  initializeFormData(applicationData, mappingType);

  return selectedStore;
};
