import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * BusinessIdeaInformation Pdf element.
 *
 * @returns {BusinessIdeaInformationPdf}
 */
function BusinessIdeaInformationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="business_idea_information_new_idea_description" isSingleRow />
        <RowField
          name="business_idea_information_product_planned_type"
          dictionaryName={DICTIONARIES.productPlannedTypes.name}
        />
        <RowField name="business_idea_information_problems_description" isSingleRow />
        <RowField name="business_idea_information_new_features_description" isSingleRow />
        <RowField name="business_idea_information_main_feature_description" isSingleRow />
        <RowField name="business_idea_information_market_type" isSingleRow />
        <RowField name="business_idea_information_main_customer_segment_characteristic" isSingleRow />
        <RowField name="business_idea_information_target_customer_profile_description" isSingleRow />
        <RowField name="business_idea_information_competitive_offer_description" isSingleRow />
        <RowField name="business_idea_information_development_idea_current_stage_description" isSingleRow />
        <RowField name="business_idea_information_main_barriers" isSingleRow />
        <RowField name="business_idea_information_resource_description" isSingleRow />
        <RowField name="business_idea_information_develop_the_mvp_required_estimated_time" isSingleRow />
        <RowField name="business_idea_information_business_activity_planned_development_description" isSingleRow />
        <RowField name="business_idea_information_revenue_main_sources" isSingleRow />
        <RowField name="business_idea_information_introducing_product_to_the_market_costs" isSingleRow />
        <RowField
          name="business_idea_information_innovation_level"
          dictionaryName={DICTIONARIES.innovationLevels.name}
        />
        <RowField name="business_idea_information_justification" isSingleRow />
        <RowField name="business_idea_information_activities_so_far" isSingleRow />
        <RowField name="business_idea_information_business_idea_involves_bringing_to_market_solution" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default BusinessIdeaInformationPdf;
