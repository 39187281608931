import { useAuth } from '../_security';
import { EmployeeCards } from '../Features/Employee';
import { HomeCards } from '../Features';
import { PROFILES } from '../_constants';

/**
 * Home dashboard.
 *
 * @returns {Home}
 */
export function Home() {
  const { profile } = useAuth();

  const homeComponent = {
    [PROFILES.applicant]: <HomeCards customCardStyles={{ width: '300px' }} />,
    [PROFILES.employee]: <EmployeeCards />,
    [PROFILES.expert]: <HomeCards customCardStyles={{ width: '250px' }} />,
    [PROFILES.operator]: <HomeCards customCardStyles={{ width: '250px' }} />,
    [PROFILES.managementInstitution]: <HomeCards customCardStyles={{ width: '250px' }} />,
  };

  return homeComponent[profile];
}
