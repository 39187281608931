import PropTypes from 'prop-types';
import {
  FormControl,
  TextField as MuiTextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';

/**
 * TextField field component
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - current field data
 * @returns {TextField}
 */
export function TextField({ fieldData }) {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth margin="normal">
      <Controller
        name="maxLength"
        control={control}
        render={({
          field: {
            onChange, value, name, onBlur,
          },
          fieldState: {
            error,
          },
        }) => (
          <MuiTextField
            error={!!error}
            helperText={error?.message}
            name={name}
            onChange={onChange}
            defaultValue={value}
            variant="outlined"
            label="Liczba znaków"
            required
            onBlur={onBlur}
            disabled={fieldData.operatedBySl}
          />
        )}
      />
    </FormControl>
  );
}

TextField.propTypes = {
  fieldData: PropTypes.shape({
    operatedBySl: PropTypes.bool,
  }).isRequired,
};
