import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { request } from '../../_services';
import { useDataGrid } from './useDataGrid';
import { columnsProcessor } from './columnsProcessor';

/**
 * Data grid with API provided data.
 *
 * @param {object} props - root props
 * @param {string} props.apiSource - api source URL
 * @param {Array} props.columns - columns as defined mui.com/components/data-grid/getting-started/#define-columns
 * @param {boolean} props.disablePagination - pagination will be disabled
 * @param {boolean} props.useColumnsProcessor - columns will be processed by columnsProcessor
 * @param {boolean} props.disableFilterToolbar - toolbar filter will be disabled
 * @param {number} props.reloadListener - grid data will be reloaded on this prop change
 * @param {number} props.rowHeight - row height
 * @param {object} props.serverFilterModel - default serverside filters
 * @param {object} props.customNumberOfItemsPerPage - define custom number of items per page to overwrite default number
 * @param {Array} props.defaultSortModel - default sort model
 * @param {object} props.additionalProps - set extra config props
 * @param {object} props.defaultColumnVisibilityModel - default column visibility model
 * @param {string} props.name - key name in which the parameters will be stored
 * @see columnsProcessor for more info about useColumnsProcessor prop
 * @returns {CustomDataGrid}
 */
export function CustomDataGrid({
  apiSource,
  columns,
  disablePagination,
  useColumnsProcessor,
  disableFilterToolbar,
  reloadListener,
  rowHeight,
  serverFilterModel,
  customNumberOfItemsPerPage,
  defaultSortModel,
  additionalProps,
  defaultColumnVisibilityModel,
  name,
}) {
  const {
    paginationModel,
    sortModel,
    filterModel,
    dataGridProps,
  } = useDataGrid(disableFilterToolbar, defaultSortModel, defaultColumnVisibilityModel, name);

  const {
    pageSize: itemsPerPage,
    page,
  } = paginationModel;

  /**
   * All rows fetched from API.
   */
  const [rowsData, setRowsData] = useState([]);

  /**
   * Total rows count.
   */
  const [rowsCount, setRowsCount] = useState(0);

  /**
   * Whether the component has completed fetching the data.
   */
  const [apiDataLoaded, setApiDataLoaded] = useState(false);

  const cleanupFilterModel = (value) => {
    if (typeof value === 'object') {
      return value.length !== 0;
    }

    return value !== '';
  };

  /**
   * Makes API url based on current state.
   *
   * @returns {string}
   */
  const buildUrl = useCallback(() => {
    // eslint-disable-next-line max-len
    let url = `${apiSource}${apiSource.includes('?') ? '&' : '?'}itemsPerPage=${customNumberOfItemsPerPage || itemsPerPage}&`;
    if (!disablePagination) {
      url += `page=${page + 1}&`;
    }
    if (sortModel[0]) {
      const {
        field, sort,
      } = sortModel[0];
      url += `order[${field}]=${sort}&`;
    }

    const filters = Object.values(filterModel).filter((value) => cleanupFilterModel(value) === true)
      .length === 0 ? serverFilterModel : filterModel;

    Object.entries(filters)
      .filter(([_, filterValue]) => !!filterValue)
      .forEach(([columnField, filterValue]) => {
        if (typeof filterValue === 'object' && 'strategy' in filterValue) {
          url += `${columnField}[${filterValue.strategy}]=${filterValue.value}&`;

          return;
        }

        if (Array.isArray(filterValue)) {
          filterValue.forEach((value) => {
            url += `${columnField}[]=${value}&`;
          });

          return;
        }
        url += `${columnField}=${filterValue}&`;
      });

    return url;
  }, [apiSource, disablePagination, itemsPerPage, page, sortModel, filterModel]);

  useEffect(() => {
    const loadRows = async () => {
      setApiDataLoaded(false);
      const {
        payload,
        itemsCount,
        statusSuccess,
      } = await request.get(buildUrl());

      if (statusSuccess) {
        setRowsCount(itemsCount);
        setRowsData(payload);
      }

      setApiDataLoaded(true);
    };

    if (apiSource.length !== 0) {
      loadRows();
    }
  }, [apiSource, buildUrl, reloadListener]);

  return (
    <Box width="100%" overflow="auto">
      <Box minWidth="1200px" pt={5}>
        <DataGrid
          disableVirtualization
          rowCount={rowsCount}
          columns={useColumnsProcessor ? columnsProcessor(columns) : columns}
          rows={rowsData}
          loading={!apiDataLoaded}
          disableColumnFilter
          autoHeight
          rowHeight={rowHeight}
          hideFooterPagination={disablePagination}
          hideFooter={disablePagination}
          {...dataGridProps}
          {...additionalProps}
        />
      </Box>
    </Box>
  );
}

CustomDataGrid.propTypes = {
  apiSource: PropTypes.string,
  columns: PropTypes.arrayOf(Object).isRequired,
  disablePagination: PropTypes.bool,
  useColumnsProcessor: PropTypes.bool,
  disableFilterToolbar: PropTypes.bool,
  reloadListener: PropTypes.number,
  rowHeight: PropTypes.number,
  serverFilterModel: PropTypes.instanceOf(Object),
  customNumberOfItemsPerPage: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  defaultSortModel: PropTypes.arrayOf(Object),
  additionalProps: PropTypes.objectOf(Object),
  defaultColumnVisibilityModel: PropTypes.instanceOf(Object),
  name: PropTypes.string.isRequired,
};

CustomDataGrid.defaultProps = {
  apiSource: '',
  disablePagination: false,
  useColumnsProcessor: true,
  disableFilterToolbar: false,
  reloadListener: 0,
  rowHeight: 52,
  serverFilterModel: {},
  customNumberOfItemsPerPage: false,
  defaultSortModel: [],
  additionalProps: {},
  defaultColumnVisibilityModel: {},
};
