import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../_security';
import { Alert } from '../Alert';
import { useGlobalDialog } from '../../Context';
import LogoutConfirmationForm from '../Logout/LogoutConfirmation.form';
import { URL } from '../../_constants';

/**
 * Info alert rendered if user is switched to other account.
 *
 * @returns {SwitchedUser|null}
 */
export function SwitchedUser() {
  const {
    authenticated, switched, username, onUserUndoSwitch,
  } = useAuth();
  const { render } = useGlobalDialog();
  const navigate = useNavigate();

  if (authenticated && switched) {
    return (
      <Alert severity="info">
        {`Uwaga! Jesteś zalogowany jako użytkownik ${username}. W celu opuszczenia tego konta`}
        &nbsp;
        <Link
          sx={{ fontSize: '1rem' }}
          component="button"
          href="#"
          onClick={() => render(
            <LogoutConfirmationForm />,
            'Potwierdź wylogowanie',
            { maxWidth: 'xs' }
          )}
        >
          wyloguj się
        </Link>
        &nbsp;lub&nbsp;
        <Link
          sx={{ fontSize: '1rem' }}
          component="button"
          href="#"
          onClick={() => {
            onUserUndoSwitch();
            navigate(URL.home);
          }}
        >
          wróć na konto domenowe
        </Link>
        .
      </Alert>
    );
  }

  return null;
}
