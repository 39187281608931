import PropTypes from 'prop-types';
import {
  Text,
  View,
} from '@react-pdf/renderer';
import PageWrapper from '../Components/PageWrapper/PageWrapper';
import { printStyle } from '../print.style';
import SingleElement from '../Components/SingleElement/SingleElement';
import PrintoutTableRow from '../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../Components/Table/PrintoutTableCell';
import { ADDITIONAL_MODULE_IDS } from '../../../_constants';
import { handleNewlines } from '../../../_helpers';

/**
 * PrintoutAssessmentSheetWithModules element.
 *
 * @param {object} props - root props
 * @param {object} props.application - application data
 * @param {Array} props.modules - expert panel modules
 * @returns {PrintoutAssessmentSheetWithModules}
 */
function PrintoutAssessmentSheetWithModules({
  application, modules,
}) {
  return (
    <PageWrapper title="Arkusz oceny projektu" hideWatermark>
      <View style={{
        marginBottom: 10,
        flexDirection: 'row',
      }}
      >
        <Text style={{
          ...printStyle.sectionTitle,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 5,
        }}
        >
          Numer wniosku o dofinansowanie:
        </Text>
        <Text style={{ fontSize: 14 }}>{application.applicationNumber}</Text>
      </View>
      <View style={{
        marginBottom: 15,
        flexDirection: 'row',
      }}
      >
        <Text style={{
          ...printStyle.sectionTitle,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 5,
          maxWidth: '35%',
        }}
        >
          Nazwa wnioskodawcy:
        </Text>
        <Text style={{
          fontSize: 14,
          maxWidth: '65%',
        }}
        >
          {handleNewlines(application.applicantName)}
        </Text>
      </View>
      {modules.filter(({ items }) => items.length !== 0).map(({
        moduleName, moduleId, items,
      }) => (
        <SingleElement key={moduleId}>
          <View style={printStyle.table}>
            <PrintoutTableRow>
              <PrintoutTableCell bgColor="dkGray" isTextBold customWidth="80%">
                {moduleName}
              </PrintoutTableCell>
              <PrintoutTableCell bgColor="dkGray" isTextBold customWidth="20%">
                {moduleId === ADDITIONAL_MODULE_IDS.kryteriaRankingujace
                  ? 'Punkty przyznane'
                  : 'TAK/NIE\\n (1=TAK, 0=NIE)'}
              </PrintoutTableCell>
            </PrintoutTableRow>
            {items.map((item) => (
              <>
                <PrintoutTableRow>
                  <PrintoutTableCell bgColor="gray" rowSpan={1.25}>
                    {item.criteriaItem.name}
                  </PrintoutTableCell>
                  <PrintoutTableCell bgColor="gray" rowSpan={5}>
                    {item.points}
                  </PrintoutTableCell>
                </PrintoutTableRow>
                <PrintoutTableRow>
                  <PrintoutTableCell customWidth="99.6%">
                    {item.finalJustification}
                  </PrintoutTableCell>
                </PrintoutTableRow>
              </>
            ))}
          </View>
        </SingleElement>
      ))}
    </PageWrapper>
  );
}

export default PrintoutAssessmentSheetWithModules;

PrintoutAssessmentSheetWithModules.propTypes = {
  application: PropTypes.instanceOf(Object).isRequired,
  modules: PropTypes.arrayOf(Object).isRequired,
};
