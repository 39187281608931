/**
 * Group data set by set number
 *
 * @param {Array} datasetViolations - data set violations
 * @param {Array} datasetItems - field list with details
 * @param {string} collectionName - current collection name
 * @returns {Array}
 */
export const getGroupedViolationsBySetNumber = (
  datasetViolations,
  datasetItems,
  collectionName
) => datasetViolations?.map(({
  violations = [], setNumber, setName,
}) => ({
  label: `Tabela: "${collectionName}" - ${setName ? `${setName}` : `Zestaw numer ${setNumber}`}`,
  setNumber,
  setName,
  type: 'collection',
  items: Object.keys(violations).map((fieldViolationName) => ({
    label: datasetItems[fieldViolationName]?.label,
    violations: violations[fieldViolationName],
    violationsCount: violations[fieldViolationName].length,
    type: datasetItems[fieldViolationName]?.type,
  })),
}));

const getTransformedField = (field) => {
  if (field.violationsCount !== 0) {
    return field;
  }

  return null;
};
const getTransformedCollection = (collection) => {
  const {
    datasetViolations = [], datasetItems = [], label,
  } = collection;

  return ({
    ...collection,
    items: getGroupedViolationsBySetNumber(
      datasetViolations,
      datasetItems,
      label,
    ),
  });
};

const TRANSFORMED_FUNCTION_BY_TYPE = {
  collection: getTransformedCollection,
  field: getTransformedField,
};

const getTransformedByType = (items) => items?.map((item) => TRANSFORMED_FUNCTION_BY_TYPE[item.type](item));

/**
 * Get transformed violations data
 *
 * @param {object} violationsData - root violations data
 * @param {number} violationsData.violationsCount - violations count
 * @param {Array} violationsData.violations - violations
 * @param {Array} menuTabs - menu tabs
 * @returns {object}
 */
export const getTransformedViolations = ({
  violations, violationsCount, ...rest
}, menuTabs) => ({
  ...rest,
  violations: {
    ...violations,
    items: menuTabs.map(({
      label, id, templateElements,
    }) => ({
      id,
      label,
      items: templateElements.map((templateElement) => violations?.items
        ?.filter(({ elementId }) => templateElement.id === elementId)
        ?.map((element) => ({
          ...element,
          items: getTransformedByType(element.items),
        }))).flat(),
    })),
  },
  violationsCount,
});
