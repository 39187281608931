import PropTypes from 'prop-types';
import { useApplicationToPdfContext } from '../../../../Context';
import MaterialAndFinancialScheduleTablePdf from './MaterialAndFinancialSchedule/MaterialAndFinancialScheduleTable.pdf';
import ExpensesSummaryTablePdf from './MaterialAndFinancialSchedule/ExpensesSummaryTable.pdf';
import {
  getAddedRowData,
  getRemovedRowData,
} from '../../../../_helpers';

/**
 * MaterialAndFinancialSchedulePdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {MaterialAndFinancialSchedulePdf}
 */
function MaterialAndFinancialSchedulePdf({ elementData }) {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();
  const {
    id: elementId, elementDefinition,
  } = elementData;

  const tasks = `tasks-${elementId}` in initialFormData ? initialFormData[`tasks-${elementId}`] || [] : [];
  const previousTasks = `tasks-${elementId}` in previousInitialFormData ? previousInitialFormData[`tasks-${elementId}`] || [] : [];
  const newTasks = getAddedRowData(tasks, previousTasks, 'task_id');
  const removedTasks = getRemovedRowData(tasks, previousTasks, 'task_id');
  const tasksData = withChanges ? [...newTasks, ...removedTasks] : tasks;

  const expensesSummaryTitle = elementDefinition.fields.find(({ name }) => name === 'hrf_element_summary')?.label
    || 'Podsumowanie wydatków';

  return (
    <>
      {tasksData.map((task, index) => (
        <MaterialAndFinancialScheduleTablePdf
          taskData={task}
          key={task.task_id}
          index={index}
          elementId={elementId}
        />
      ))}
      <ExpensesSummaryTablePdf expensesSummaryTitle={expensesSummaryTitle} elementId={elementId} />
    </>
  );
}

export default MaterialAndFinancialSchedulePdf;

MaterialAndFinancialSchedulePdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
