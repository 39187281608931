import {
  useEffect,
  useState,
} from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Button,
  Box,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { format } from 'date-fns';
import {
  useExpertApplicationFormContext,
  useGlobalContext,
} from '../../../Context';
import InfoPopover from '../../InfoPopover/InfoPopover';
import { Alert } from '../../Alert';
import { request } from '../../../_services';
import {
  API_ROUTE,
  getAllSpecializationStatuses,
  SPECIALIZATIONS_TYPES,
  APPLICATION_CONTEXT_HELP_TEXT,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { handleError } from '../../../_helpers';

const classes = {
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'left',
    },
  },
};

/**
 * Specialization choice form for expert
 *
 * @returns {ChoiceSpecializationForm}
 */
export function ChoiceSpecializationForm() {
  const [kisDomains, setKisDomains] = useState([]);
  const [otherDomains, setOtherDomains] = useState([]);
  const [financialDomains, setFinancialDomains] = useState([]);
  const {
    isReadonly, reloadData, applicationFormData,
  } = useExpertApplicationFormContext();
  const { notify } = useGlobalContext();

  const {
    control, watch, handleSubmit, setError, reset,
  } = useFormContext();

  const specializationsFieldValue = watch('specializations');

  const isIndustryExpert = specializationsFieldValue?.includes(SPECIALIZATIONS_TYPES.industryExpert);
  const isFinancialExpert = specializationsFieldValue?.includes(SPECIALIZATIONS_TYPES.financialExpert);

  useEffect(() => {
    reset((formValues) => ({
      ...formValues,
      specializations: applicationFormData?.specializations,
      kisDomains: applicationFormData?.kisDomains,
      otherDomains: applicationFormData?.otherDomains,
    }));
    /**
     * Loads other domains from API.
     */
    const loadOtherDomains = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(API_ROUTE.otherDomains);

      if (statusSuccess) {
        setOtherDomains(payload);
      }
    };

    /**
     * Loads kis domains from API.
     */
    const loadKisDomains = async () => {
      const today = format(new Date(), 'yyyy-MM-dd');
      let route = API_ROUTE.kisDomains;
      switch (applicationFormData.status) {
      case 'editing':
        route = `${API_ROUTE.kisDomains}?availableFrom[before]=${today}&availableTo[after]=${today}`;
        break;
      case 'correction':
        route = `${API_ROUTE.kisDomains}?availableFrom[before]=${applicationFormData.submittedAt.substring(0, 10)}&availableTo[after]=${applicationFormData.submittedAt.substring(0, 10)}`;
        break;
      default:
        route = API_ROUTE.kisDomains;
      }

      const {
        statusSuccess, payload,
      } = await request.get(route);

      if (statusSuccess) {
        setKisDomains(payload);
      }
    };

    /**
     * Loads financial domains from API.
     */
    const loadFinancialDomains = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(API_ROUTE.financialDomains);

      if (statusSuccess) {
        setFinancialDomains(payload);
      }
    };

    loadKisDomains();
    loadOtherDomains();
    loadFinancialDomains();
  }, []);

  const submitForm = async (formData) => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.applicationForms}/${applicationFormData.id}`, {
      specializations: formData.specializations,
      kisDomains: formData.kisDomains,
      otherDomains: formData.otherDomains,
    });
    window.scroll(0, 0);

    if (statusSuccess) {
      reloadData();
      notify('Wprowadzono dane wyboru specjalizacji', 'success');

      return;
    }

    handleError(setError, violations);
    notify('Wystąpił błąd podczas zapisu wyboru specjalizacji', 'error');
  };

  return (
    <>
      <Grid
        container
        aria-colcount={2}
        spacing={2}
        mb={2}
        sx={classes.container}
        id="MuIxYb4yBJQcurp"
      >
        <Grid item xs={12} md={3} alignItems="center">
          <InputLabel htmlFor="specializations">
            Specjalizacja
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Specjalizacja"',
                },
              }}
              name="specializations"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  textFieldProps={{
                    error: !!error,
                    inputProps: {
                      required: true,
                    },
                  }}
                  initialValue={value}
                  onBlur={onBlur}
                  multiple
                  renderSelectAllButton={false}
                  onChange={onChange}
                  options={getAllSpecializationStatuses()}
                  optionsMapKeys={['name', 'label']}
                  error={error}
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.specializations}
          </InfoPopover>
        </Grid>
        {isIndustryExpert && (
          <>
            <Grid item xs={12} md={3} alignItems="center">
              <InputLabel htmlFor="kisDomains">
                Dziedziny KIS
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={7}>
              <FormControl
                variant="outlined"
                fullWidth
              >
                <Controller
                  name="kisDomains"
                  control={control}
                  render={({
                    field: {
                      onChange, value, name, onBlur,
                    },
                  }) => (
                    <CustomAutocomplete
                      id={name}
                      textFieldProps={{
                        inputProps: {
                          required: true,
                        },
                      }}
                      initialValue={value}
                      onBlur={onBlur}
                      multiple
                      renderSelectAllButton={false}
                      onChange={onChange}
                      options={kisDomains}
                    />
                  )}
                />
              </FormControl>
              <InfoPopover>
                {APPLICATION_CONTEXT_HELP_TEXT.kisDomains}
              </InfoPopover>
            </Grid>
            <Grid item xs={12} md={3} alignItems="center">
              <InputLabel htmlFor="otherDomains">
                Pozostałe dziedziny
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={7}>
              <FormControl
                variant="outlined"
                fullWidth
              >
                <Controller
                  name="otherDomains"
                  control={control}
                  render={({
                    field: {
                      onChange, value, name, onBlur,
                    },
                  }) => (
                    <CustomAutocomplete
                      id={name}
                      textFieldProps={{
                        inputProps: {
                          required: true,
                        },
                      }}
                      initialValue={value}
                      onBlur={onBlur}
                      multiple
                      renderSelectAllButton={false}
                      onChange={onChange}
                      options={otherDomains}
                    />
                  )}
                />
              </FormControl>
              <InfoPopover>
                {APPLICATION_CONTEXT_HELP_TEXT.otherDomains}
              </InfoPopover>
            </Grid>
          </>
        )}
        {isFinancialExpert && (
          <>
            <Grid item xs={12} md={3} alignItems="center">
              <Typography color="#757575" fontSize={14}>Dziedziny finansowe</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Alert severity="status" sx={{ margin: 0 }}>
                {financialDomains.map(({ name }) => name).join(', ')}
              </Alert>
              <InfoPopover>
                {APPLICATION_CONTEXT_HELP_TEXT.financialDomains}
              </InfoPopover>
            </Grid>
          </>
        )}
      </Grid>
      {isIndustryExpert && !watch('kisDomains')?.length && !watch('otherDomains')?.length && (
        <Typography color="primary" align="center">
          Proszę uzupełnić przynajmniej jedno z pól:
          &quot;Dziedziny KIS&quot; lub &quot;Pozostałe dziedziny&quot;
        </Typography>
      )}
      {!isReadonly && (
        <Box mt={5} display="flex" justifyContent="flex-start">
          <Button
            id="TBlcEBfGCvPaD9D"
            variant="contained"
            color="secondary"
            onClick={handleSubmit(submitForm)}
          >
            Zapisz
          </Button>
        </Box>
      )}
    </>
  );
}
