import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useElementContext } from '../../../../Context';
import CharsCounter from '../../../CharsCounter';
import { FormField } from '../../../Application/Application/FormField';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementersAndApplicantName,
} from '../../../../_helpers';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../../_constants';

/**
 * Internationalized product component
 *
 * @returns {InternationalizationModuleEssenceProductInternationalization}
 */
export default function InternationalizationModuleEssenceProductInternationalization() {
  const { id: applicationId } = useParams();
  const {
    control, getValues, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const { get } = useDictionaryLoader(DICTIONARIES.modulesWithNaOption);

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'internationalization_module_essence_product_internationalization',
  });

  const internationalizationModuleEssenceProductInternationalizationConfig = fieldsConfig
    .internationalization_module_essence_product_internationalization;
  const subfields = internationalizationModuleEssenceProductInternationalizationConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = internationalizationModuleEssenceProductInternationalizationConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <>
      {fields.map((field, index) => (
        // Tabela \"Produkt będący przedmiotem umiędzynarodowienia (promocji zagranicznej)\"
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'internationalization_module_essence_product_internationalization',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed
        >
          {/* Nazwa produktu/ grupy produktów */}
          <FormField name={`internationalization_module_essence_product_internationalization.${index}.product_name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Nazwa wnioskodawcy lub konsorcjanta wytwarzającego produkt */}
          <FormField name={`internationalization_module_essence_product_internationalization.${index}.applicant_name`}>
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={getImplementersAndApplicantName(getValues, applicationId)}
                multiple
                renderSelectAllButton={false}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Nazwa modułu, którego dotyczy promowany produkt/ grupa produktów */}
          <FormField name={`internationalization_module_essence_product_internationalization.${index}.module_name`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.modulesWithNaOption.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Opis produktu/ grupy produktów */}
          <FormField
            name={`internationalization_module_essence_product_internationalization.${index}.product_description`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Uzasadnienie wyboru sposobu i rynków promocji zagranicznej */}
          <FormField
            name={`internationalization_module_essence_product_internationalization.${index}.choice_justification`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Produkt wytwarzany na terytorium Polski */}
          <FormField
            name={`internationalization_module_essence_product_internationalization.${index}.manufactured_in_poland`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                multiline
                minRows={2}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="KDD8C6Oy0d0Vv6U"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
