import {
  createContext,
  useContext,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const GlobalDialogContext = createContext({});

const ACTION_TYPES = {
  OPEN: 'open',
  CLOSE: 'close',
};

const reducer = (state, action) => {
  switch (action.type) {
  case ACTION_TYPES.OPEN:
    return {
      ...state,
      dialogOpened: true,
      Component: action.value,
      maxWidth: action.maxWidth === false ? false : action.maxWidth || 'xl',
      wrapDialogContent: action.wrapDialogContent,
      variant: action.variant || 'outlined',
      color: action.color || 'secondary',
      title: action.title || null,
      sx: action.sx || null,
    };
  case ACTION_TYPES.CLOSE:
    return {
      dialogOpened: false,
    };
  default:
    return state;
  }
};

/**
 * Provides dialogs that can be opened from any place in application.
 *
 * @param {object} props - root props
 * @param {Element|Element[]} props.children - provider children
 * @returns {GlobalDialogProvider}
 */
export function GlobalDialogProvider({ children }) {
  const [state, dispatch] = useReducer(
    reducer,
    {
      dialogOpened: false,
    },
    undefined
  );

  return (
    <GlobalDialogContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        closeAll: () => {
          dispatch({
            type: ACTION_TYPES.CLOSE,
          });
        },
        render: (dialogComponent, title, options = {}, wrapDialogContent = true) => {
          dispatch({
            type: ACTION_TYPES.OPEN,
            value: dialogComponent,
            title,
            wrapDialogContent,
            ...options,
          });
        },
      }}
    >
      <Dialog
        open={state.dialogOpened}
        maxWidth={state.maxWidth}
        color={state.color}
        variant={state.variant}
        fullWidth
        onClose={() => {
          dispatch({ type: ACTION_TYPES.CLOSE });
        }}
        sx={state.sx}
      >
        {state.dialogOpened && (
          <>
            {state.title && (
              <DialogTitle component="div">
                <Typography variant="dialogHeading">
                  {state.title}
                </Typography>
              </DialogTitle>
            )}
            <Box component={state.wrapDialogContent ? DialogContent : 'div'}>
              {state.Component}
            </Box>
          </>
        )}
      </Dialog>
      {children}
    </GlobalDialogContext.Provider>
  );
}

GlobalDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Use application menu context.
 *
 * @returns {object}
 */
export const useGlobalDialog = () => useContext(GlobalDialogContext);
