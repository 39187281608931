import {
  Controller,
  useForm,
  useFieldArray,
} from 'react-hook-form';
import {
  Button,
  IconButton,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
  Box,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import uniqid from 'uniqid';
import { Fragment } from 'react';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import {
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../Context';
import { AdditionalActivityFormValidation } from './AdditionalActivity.form.validation';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { handleError } from '../../../_helpers';

const classes = {
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
  },
};

/**
 * Additional activity data form for expert
 *
 * @param {object} props - root props
 * @param {object} props.additionalActivity - additional activity of expert
 * @returns {AdditionalActivityForm}
 */
export function AdditionalActivityForm({ additionalActivity }) {
  const navigate = useNavigate();
  const {
    expertAdditionalActivity, reloadData, closeHandler, isReadonly,
  } = useExpertPersonalDataContext();
  const { notify } = useGlobalContext();
  const isBreakpointDownMd = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const {
    control, watch, handleSubmit, setError, resetField,
  } = useForm({
    defaultValues: {
      stepServices: additionalActivity?.stepServices?.toString() || null,
      innovationCoachServices: additionalActivity?.innovationCoachServices?.toString() || null,
      appraiserRights: additionalActivity?.appraiserRights?.toString() || null,
      additionalEntries: additionalActivity?.additionalEntries?.length > 0 ? additionalActivity?.additionalEntries : [],
    },
    mode: 'all',
    shouldUnregister: true,
  });

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'additionalEntries',
  });

  const addItem = () => {
    append({
      type: '',
      domain: '',
    });
  };

  const appraiserRightsFieldValue = watch('appraiserRights');

  /**
   * Handle form submit request.
   *
   * @param {object} formData - form data to send
   */
  const submitForm = async (formData) => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.additionalActivity}/${expertAdditionalActivity?.id}`, {
      stepServices: formData.stepServices === 'true',
      innovationCoachServices: formData.innovationCoachServices === 'true',
      appraiserRights: formData.appraiserRights === 'true',
      additionalEntries: formData.additionalEntries,
    });

    if (statusSuccess) {
      notify('Dodatkowa działalność została zaktualizowana.', 'success');
      reloadData();
      window.scrollTo(0, 0);

      return;
    }

    handleError(setError, violations);

    notify('Wystąpił błąd podczas zapisu dodatkowej działalności.', 'error');
    window.scrollTo(0, 0);
  };

  return (
    <Grid
      container
      aria-colcount={2}
      spacing={2}
      id="wZgV7OiS43T2nZP"
      mt={1}
      sx={classes.container}
    >
      <Grid item xs={12} md={8}>
        <InputLabel htmlFor="additionalActivityStepServices">
          Czy kandydat na eksperta świadczy usługi w ramach STEP?
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined">
          <Controller
            rules={AdditionalActivityFormValidation.stepServices}
            name="stepServices"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  sx={{ display: 'block' }}
                  onChange={onChange}
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <InputLabel htmlFor="additionalActivityInnovationCoachServices">
          Czy kandydat na eksperta świadczy usługi w ramach Innovation Coach?
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined">
          <Controller
            rules={AdditionalActivityFormValidation.innovationCoachServices}
            name="innovationCoachServices"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  sx={{ display: 'block' }}
                  onChange={onChange}
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <InputLabel htmlFor="additionalActivityAppraiserRights" variant="noShorten" sx={{ textAlign: 'left' }}>
          Czy kandydat na eksperta posiada uprawnienia rzeczoznawcy lub jest wpisany na listę biegłych sądowych?
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={4} justifyItems="flex-start">
        <FormControl variant="outlined">
          <Controller
            rules={AdditionalActivityFormValidation.appraiserRights}
            name="appraiserRights"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  sx={{ display: 'block' }}
                  onChange={(event) => {
                    onChange(event);
                    if (event.target.value === 'true') {
                      addItem();

                      return;
                    }
                    resetField('additionalEntries', { defaultValue: [] });
                  }}
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      {appraiserRightsFieldValue === 'true' && (
        <>
          {fields.map((item, index) => (
            <Fragment key={item.id}>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <Controller
                    name={`additionalEntries.${index}.type`}
                    control={control}
                    rules={AdditionalActivityFormValidation.type}
                    render={({
                      field: {
                        onChange, value, name,
                        onBlur,
                      },
                      fieldState: {
                        error,
                      },
                    }) => (
                      <TextField
                        disabled={isReadonly}
                        label="Rodzaj wpisu"
                        error={!!error}
                        helperText={error?.message}
                        id={name}
                        name={name}
                        onChange={onChange}
                        defaultValue={value}
                        variant="outlined"
                        onBlur={onBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {`${value.length}/500`}
                            </InputAdornment>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          maxLength: 500,
                          required: true,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl variant="outlined" fullWidth>
                  <Controller
                    name={`additionalEntries.${index}.domain`}
                    control={control}
                    rules={AdditionalActivityFormValidation.domain}
                    render={({
                      field: {
                        onChange, value, name,
                        onBlur,
                      },
                      fieldState: {
                        error,
                      },
                    }) => (
                      <TextField
                        disabled={isReadonly}
                        label="Dziedzina"
                        error={!!error}
                        helperText={error?.message}
                        id={name}
                        name={name}
                        onChange={onChange}
                        defaultValue={value}
                        variant="outlined"
                        onBlur={onBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {`${value.length}/${500}`}
                            </InputAdornment>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          maxLength: 500,
                          required: true,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                {!isReadonly && index !== 0 && (
                  <IconButton
                    id={uniqid()}
                    onClick={() => remove(index)}
                    aria-label="usuń"
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
              </Grid>
              {isBreakpointDownMd && (
                <Grid item xs={12}>
                  <Box width="100%">
                    <Divider />
                  </Box>
                </Grid>
              )}
            </Fragment>
          ))}
          {!isReadonly && (
            <Grid item xs={12}>
              <Button
                color="secondary"
                id="dmVFtEX4GGzyBEz"
                onClick={() => addItem()}
              >
                + Dodaj
              </Button>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} mt={4}>
        {!isReadonly && (
          <SaveCancelButtons
            saveButtonId="7VbuczLkA8M3PEY"
            cancelButtonId="cQu8yAr6yzsnBW0"
            cancelHandler={closeHandler ? () => closeHandler() : () => { navigate('/'); }}
            saveHandler={handleSubmit(submitForm)}
          />
        )}
      </Grid>
    </Grid>
  );
}

AdditionalActivityForm.propTypes = {
  additionalActivity: PropTypes.instanceOf(Object).isRequired,
};
