import {
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { ELEMENTS_DICTIONARIES_MAP } from '../../../_constants';

/**
 * MarketDemandAndRdProfitability element renderer.
 *
 * @returns {MarketDemandAndRdProfitability}
 */
export function MarketDemandAndRdProfitability() {
  const {
    isReadonly,
    fieldsConfig,
    config,
  } = useElementContext();
  const {
    managedApplicationTemplate: {
      elementsConfig,
    },
  } = useApplicationContext();
  const { getValues } = useFormContext();

  const { innovationTypes } = ELEMENTS_DICTIONARIES_MAP;

  const rdModuleEssenceType = 'RdModuleEssence';

  const rdModuleEssenceLabel = Object.values(elementsConfig)
    .find(({ elementData }) => elementData.elementDefinition['@type'] === rdModuleEssenceType)?.elementData?.label;

  const helperText = `W celu uzupełnienia elementu "${config.elementData.label}" konieczne jest 
    uzupełnienie danych w polu "${fieldsConfig.rd_module_essence_innovation_type.label}" w elemencie
     "${rdModuleEssenceLabel}"`;

  const isProductInnovationsValue = getValues('rd_module_essence_innovation_type')?.includes(
    innovationTypes.productInnovation.id
  );
  const isInnovationInTheBusinessProcessValue = getValues('rd_module_essence_innovation_type')?.includes(
    innovationTypes.innovationInTheBusinessProcess.id
  );

  return (
    <FieldSorter>
      {/* 1. Etykieta: Konkurencyjność */}
      {(isProductInnovationsValue || isInnovationInTheBusinessProcessValue) && (
        <FormField name="market_demand_and_rd_profitability_competitiveness" contextHelpLabel>
          {() => null}
        </FormField>
      )}

      {isProductInnovationsValue && (
        <>
          <FormField
            name="market_demand_and_rd_profitability_applicant_offer"
            extraLabel={fieldsConfig.market_demand_and_rd_profitability_product_innovation?.label}
            // 2. Etykieta: Innowacyjność produktowa
          >
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 3. Pole: Oferta wnioskodawcy.
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField name="market_demand_and_rd_profitability_competition_offer">
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 4. Pole: Oferta konkurencji.
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField name="market_demand_and_rd_profitability_target_market">
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 5. Pole: Rynek docelowy dla innowacji produktowej.
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField name="market_demand_and_rd_profitability_launching_plan">
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 6. Pole: Plan wprowadzenia rezultatu modułu na rynek – innowacja produktowa.
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}

      {isInnovationInTheBusinessProcessValue && (
        <>
          <FormField
            name="market_demand_and_rd_profitability_innovation_influence"
            extraLabel={fieldsConfig.market_demand_and_rd_profitability_process_innovation?.label}
            // 7. Etykieta: Innowacja procesowa
          >
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 8. Pole: Wpływ innowacji w procesie biznesowym na cykl produkcyjny
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField name="market_demand_and_rd_profitability_launching_profits">
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 9. Pole: Korzyści z wprowadzenia rezultatu modułu - innowacji w procesie biznesowym
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

          <FormField name="market_demand_and_rd_profitability_implementation_plan">
            {({
              name, onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                // 10. Plan wdrożenia rezultatu modułu - innowacji w procesie biznesowym
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>

        </>
      )}
      {(isProductInnovationsValue || isInnovationInTheBusinessProcessValue) && (
        <FormField name="market_demand_and_rd_profitability_implementation_profitability">
          {({
            name, onChange, value, maxLength, onBlur, error,
          }) => (
            <TextField
              // 11. Analiza opłacalności wdrożenia
              defaultValue={value}
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              multiline
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {isProductInnovationsValue || isInnovationInTheBusinessProcessValue
          || <Typography mt={2} sx={{ fontSize: '1.2rem' }}>{helperText}</Typography>}
    </FieldSorter>
  );
}
