import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
// eslint-disable-next-line import/no-cycle
import { ELEMENT_VIOLATION_BY_TYPE } from '../../../../../_constants';
import { useApplicationTemplateStyles } from '../../../../../styles/applicationTemplate.styles';
import SingleField from './SingleField';

/**
 * Single collection component
 *
 * @param {object} props - root props
 * @param {string} props.label - collection name
 * @param {Array} props.items - collection items
 * @param {string} props.subCollectionLabel - sub collection name
 * @param {Array} props.violations - collection violations
 * @returns {SingleCollection}
 */
function SingleCollection({
  label, items, subCollectionLabel, violations,
}) {
  if (items.length === 0 && violations.length === 0) {
    return '';
  }

  return (
    <Box pl={4} sx={useApplicationTemplateStyles.collectionFieldContainer}>
      <Typography variant="h4" fontFamily="Montserrat-Light, sans-serif" fontWeight="bold" my={2}>
        {label ? `Tabela: "${label}"` : subCollectionLabel}
      </Typography>
      {violations.length !== 0 && (
        <>
          <Typography variant="h4" fontFamily="Montserrat-Light, sans-serif" fontWeight="bold" my={2}>
            Tabela zawiera błędy ogólne:
          </Typography>
          <SingleField violations={violations} />
        </>
      )}
      {items.map(({
        items: childItems,
        label: childLabel,
        type,
        violations: childViolations,
      }) => {
        const ViolationsComponent = ELEMENT_VIOLATION_BY_TYPE[type];

        return (
          <ViolationsComponent
            key={uniqid()}
            items={childItems}
            subCollectionLabel={childLabel}
            violations={childViolations}
            label={type === 'field' ? childLabel : ''}
          />
        );
      })}
    </Box>
  );
}

export default SingleCollection;

SingleCollection.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(Object),
  subCollectionLabel: PropTypes.string,
  violations: PropTypes.arrayOf(String),
};

SingleCollection.defaultProps = {
  label: '',
  items: [],
  subCollectionLabel: '',
  violations: [],
};
