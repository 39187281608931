export const FIELD_TYPES_MAP = {
  choice: [],
  limited_choice: [],
  date: null,
  text: '',
  numeric: 0,
  email: '',
  attachments: [],
  collection: [],
};
