import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  useEffect,
  useRef,
} from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../../_constants';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import {
  setValuesForOtherGeographicalFields,
  townParser,
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * Private partnership subfield of ApplicantGeneralInformation.
 *
 * @returns {PrivatePartnership}
 */
export function PrivatePartnership() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control,
    watch,
    setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'partnership_partners',
  });

  const {
    NIP, PHONE,
  } = CHARS_LIMIT;

  const streetSelectRefs = useRef({});
  const partnersConfig = fieldsConfig.partnership_partners;
  const subfields = partnersConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = partnersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <>
      {fields.map((item, index) => {
        const mapKeys = [
          `partnership_partners.${index}.country`,
          `partnership_partners.${index}.commune`,
          `partnership_partners.${index}.district`,
          `partnership_partners.${index}.voivodeship`,
          `partnership_partners.${index}.street`,
        ];

        return (
          <CollectionFieldContainer
            key={item.id}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'partnership_partners',
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            <FormField name={`partnership_partners.${index}.first_name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Imię
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.last_name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Nazwisko
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.nip`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  // NIP
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? NIP} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? NIP,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.country`}>
              {({ value }) => (
                <TextField
                // Kraj
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.city`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <ApiAutocomplete
                  // Miejscowość
                  id={name}
                  filterBy="name"
                  initialValue={value?.value}
                  initialValueFilterBy="id"
                  optionParser={townParser}
                  changeHandler={(selectedValue) => {
                    streetSelectRefs.current[index].clear();
                    onChange(selectedValue);
                    setValuesForOtherGeographicalFields(selectedValue, setValue, mapKeys);
                  }}
                  textFieldProps={{
                    onBlur,
                  }}
                  charLimitExclusionWords={['oś']}
                  baseUrl={API_ROUTE.geoDictionaries.towns}
                  disabled={isReadonly}
                  error={error}
                  staticFilters={{ itemsPerPage: '500' }}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.commune`}>
              {({ value }) => (
                <TextField
                // Gmina
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.district`}>
              {({ value }) => (
                <TextField
                // Powiat
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.voivodeship`}>
              {({ value }) => (
                <TextField
                // Wojewodztwo
                  value={value?.label || ''}
                  disabled
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.postal_code`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  // Kod pocztowy
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={6} />,
                  }}
                  inputProps={{
                    mask: '00-000',
                    maxLength: 6,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.street`}>
              {({
                onChange, value, name, onBlur, error,
              }) => {
                const selectedTown = watch(`partnership_partners.${index}.city`);

                return (
                  <ApiAutocomplete
                    // Ulica
                  /* eslint-disable-next-line no-return-assign */
                    ref={(el) => (streetSelectRefs.current = {
                      ...streetSelectRefs.current,
                      [index]: el,
                    })}
                    id={name}
                    disabled={!selectedTown?.value || isReadonly}
                    filterBy="name"
                    staticFilters={{ 'town.id': selectedTown?.value }}
                    initialValue={value?.value}
                    initialValueFilterBy="id"
                    optionParser={({
                      id, name: label,
                    }) => ({
                      value: id,
                      label,
                    })}
                    textFieldProps={{
                      onBlur,
                    }}
                    changeHandler={onChange}
                    baseUrl={API_ROUTE.geoDictionaries.streets}
                    error={error}
                    minCharsToRequest={2}
                  />
                );
              }}
            </FormField>

            <FormField name={`partnership_partners.${index}.house_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Nr budynku
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.apartment_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Nr lokalu
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.email`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Email
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>

            <FormField name={`partnership_partners.${index}.phone_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  // Telefon
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? PHONE} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? PHONE }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        );
      })}

      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="XG8MF1aKawaFY6H"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
