import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { MonetaryField } from '../../MonetaryField';
import { Alert } from '../../Alert';

/**
 * ApplicantIncome element renderer.
 *
 * @returns {ApplicantIncome}
 */
export default function ApplicantIncome() {
  const { isReadonly } = useElementContext();

  const {
    setValue, getValues,
  } = useFormContext();

  const countPercentageManufacturingOperations = () => {
    const incomeSales = getValues('applicant_income_sales_income_from_last_closed_fiscal_year');
    const incomeProduction = getValues('applicant_income_production_income_from_last_closed_fiscal_year');

    if (incomeProduction && incomeSales > 0) {
      const calculatedValue = Number(incomeProduction) / Number(incomeSales) * 100;
      setValue(
        'applicant_income_percentage_sales_income_from_manufacturing_operations',
        calculatedValue === 0 ? '0.00' : calculatedValue
      );
    }
  };

  const countPercentageSalesIncome = () => {
    const incomeSales = getValues('applicant_income_sales_income_from_last_closed_fiscal_year');
    const incomeService = getValues('applicant_income_service_activities_income_from_last_closed_fiscal_year');
    if (incomeService && incomeSales > 0) {
      const calculateValue = Number(incomeService) / Number(incomeSales) * 100;
      setValue(
        'applicant_income_percentage_sales_income_from_service_business_operations',
        calculateValue === 0 ? '0.00' : calculateValue
      );
    }
  };

  return (
    <FieldSorter>
      {/* Wartość przychodów ze sprzedaży z ostatniego zamkniętego roku obrotowego */}
      <FormField name="applicant_income_sales_income_from_last_closed_fiscal_year">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            defaultValue={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countPercentageManufacturingOperations();
              countPercentageSalesIncome();
            }}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Wartość przychodów z działalności produkcyjnej z ostatniego zamkniętego roku obrotowego */}
      <FormField name="applicant_income_production_income_from_last_closed_fiscal_year">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            defaultValue={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countPercentageManufacturingOperations();
            }}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Wartość przychodów z działalności usługowej z ostatniego zamkniętego roku obrotowego */}
      <FormField name="applicant_income_service_activities_income_from_last_closed_fiscal_year">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            defaultValue={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countPercentageSalesIncome();
            }}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Procent przychodów ze sprzedaży w ramach prowadzonej działalności produkcyjnej */}
      <FormField name="applicant_income_percentage_sales_income_from_manufacturing_operations">
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>
      {/* Procent przychodów ze sprzedaży w ramach prowadzonej działalności usługowej */}
      <FormField name="applicant_income_percentage_sales_income_from_service_business_operations">
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>
    </FieldSorter>
  );
}
