import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../../Context';
import { DICTIONARIES } from '../../../../../_constants';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Internationalization Module Essence Product Internationalization Pdf element.
 *
 * @returns {InternationalizationModuleEssenceProductInternationalizationPdf}
 */
function InternationalizationModuleEssenceProductInternationalizationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  return (
    <CollectionWrapper name="internationalization_module_essence_product_internationalization">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.product_name`}
                  value={item.product_name}
                  oldValue={previousRowData?.product_name}
                />
                <RowField
                  name={`${collectionName}.${index}.applicant_name`}
                  value={item.applicant_name}
                  oldValue={previousRowData?.applicant_name}
                  externalDictionary={combinedApplicantName}
                />
                <RowField
                  name={`${collectionName}.${index}.module_name`}
                  value={item.module_name}
                  oldValue={previousRowData?.module_name}
                  dictionaryName={DICTIONARIES.modulesWithNaOption.name}
                />
                <RowField
                  name={`${collectionName}.${index}.product_description`}
                  value={item.product_description}
                  oldValue={previousRowData?.product_description}
                />
                <RowField
                  name={`${collectionName}.${index}.choice_justification`}
                  value={item.choice_justification}
                  oldValue={previousRowData?.choice_justification}
                />
                <RowField
                  name={`${collectionName}.${index}.manufactured_in_poland`}
                  value={item.manufactured_in_poland}
                  oldValue={previousRowData?.manufactured_in_poland}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default InternationalizationModuleEssenceProductInternationalizationPdf;
