import { TextField } from '@mui/material';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useElementContext } from '../../../Context';

/**
 * ProjectImplementationConcept element renderer.
 *
 * @returns {ProjectImplementationConcept}
 */
export function ProjectImplementationConcept() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Etap I - Przygotowanie do ogłoszenia konkursu, w tym promocja inwestycji i rekrutacja przedsięwzięć MŚP */}
      <FormField name="project_implementation_concept_preparation_for_announcement_of_the_competition">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap II - Uruchomienie konkursu na wybór przedsięwzięć MŚP */}
      <FormField name="project_implementation_concept_launching_the_competition">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap III - Ocena wniosków o objęcie wsparciem przedsięwzięć MŚP złożonych w ramach konkursu przez MŚP z
       branży hotelarskiej, gastronomicznej, turystyki lub kultury */}
      <FormField name="project_implementation_concept_applications_evaluation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap IV - Zawieranie umów o objęcie wsparciem przedsięwzięć MŚP  do otrzymania wsparcia */}
      <FormField name="project_implementation_concept_create_contracts">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap V - Realizacja umów o objęcie przedsięwzięcia MŚP wsparciem zwartych z MŚP */}
      <FormField name="project_implementation_concept_implementation_contracts">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap VI -Rozliczenie końcowe umów o objęcie przedsięwzięć MŚP wsparciem */}
      <FormField name="project_implementation_concept_settlement_contracts">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap VII - Kontrole realizowane przez Operatorów na miejscu realizacji przedsięwzięcia MŚP, na dokumentach
      oraz kontrole doraźne */}
      <FormField name="project_implementation_concept_operators_checks">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etap VIII - Sprawozdawczość na rzecz JW */}
      <FormField name="project_implementation_concept_jw_reporting">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Trwałość i wpływ rezultatów przedsięwzięcia */}
      <FormField name="project_implementation_concept_sustainability_and_impact_of_project_results">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
