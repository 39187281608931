import logo from '../images/FLAGA_UNIA_EUROPEJSKA.svg';

/**
 * Logo UE component.
 *
 * @returns {LogoUE}
 */
export default function LogoUE() {
  return <img src={logo} style={{ maxWidth: '55px' }} alt="Flaga Unii Europejskiej" />;
}
