import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * RdWorkImplementersPdf element.
 *
 * @returns {RdWorkImplementersPdf}
 */
function RdWorkImplementersPdf() {
  return (
    <CollectionWrapper name="innovation_implementation_module_essence_rd_work_implementers">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.name`}
                  value={item.name}
                  oldValue={previousRowData?.name}
                />
                <RowField
                  name={`${collectionName}.${index}.nip`}
                  value={item.nip}
                  oldValue={previousRowData?.nip}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default RdWorkImplementersPdf;
