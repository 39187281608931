import {
  useEffect,
  useState,
} from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { API_ROUTE } from '../../../_constants';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { request } from '../../../_services';
import { Alert } from '../../Alert';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { iriToId } from '../../../_helpers';

/**
 * Module classification generic element.
 *
 * @returns {ModuleClassification}
 */
export function ModuleClassification() {
  const {
    isReadonly,
    config,
    fieldsConfig,
  } = useElementContext();
  const {
    watch, getValues, setValue,
  } = useFormContext();
  const [kisDomainAreas, setKisDomainAreas] = useState([]);
  const elementName = config.elementData.elementDefinition.name;
  const kisDomainChoices = fieldsConfig?.[`${elementName}_kis_domain`]?.choices || [];
  const kisDomainAreaChoices = fieldsConfig?.[`${elementName}_kis_domain_area`]?.choices || [];
  const pkdCodesChoices = fieldsConfig?.[`${elementName}_pkd`]?.choices || [];

  /**
   * Get kis_domain entity from API
   *
   * @param {string} kisDomainArea - kis_domain_area uuid
   * @returns {void}
   */
  const loadKisDomain = async (kisDomainArea) => {
    const { payload } = await request.get(
      `${API_ROUTE.elementDictionaries.kisDomains}?areas.id=${iriToId(kisDomainArea)}`
    );

    setValue(`${elementName}_kis_domain`, payload[0]?.name ?? '');
  };

  const loadKisDomainAreas = async () => {
    const urlParams = kisDomainChoices.map((value) => `kisDomain.id[]=${value}`).join('&');
    const { payload } = await request.get(
      `${API_ROUTE.elementDictionaries.kisDomainAreas}?${urlParams}`
    );

    setKisDomainAreas(payload
      .filter((area) => (
        kisDomainAreaChoices.length === 0
          ? true
          : kisDomainAreaChoices.includes(area.id))));
  };

  useEffect(() => {
    loadKisDomainAreas();
  }, []);

  useEffect(() => {
    const kisDomainArea = getValues(`${elementName}_kis_domain_area`);
    kisDomainArea && typeof kisDomainArea === 'string'
      ? loadKisDomain(kisDomainArea)
      : setValue(`${elementName}_kis_domain`, '');
  }, [watch(`${elementName}_kis_domain_area`)]);

  return (
    <FieldSorter>
      <FormField name={`${elementName}_kis_domain_area`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={kisDomainAreas}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      <FormField name={`${elementName}_kis_domain`}>
        {({ value }) => (
          <Alert severity="status">
            {value}
          </Alert>
        )}
      </FormField>

      <FormField name={`${elementName}_kis_explanation`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name={`${elementName}_pkd`}>
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="name"
            initialValue={value?.value}
            initialValueFilterBy="id"
            changeHandler={onChange}
            optionParser={({
              id, code, name: pkdCodeName,
            }) => ({
              value: id,
              label: `${code}: ${pkdCodeName}`,
            })}
            textFieldProps={{
              onBlur,
            }}
            baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
            disabled={isReadonly}
            customFilter={pkdCodesChoices.length === 0
              ? () => true
              : (payloadObject) => (pkdCodesChoices.includes(payloadObject.id))}
            error={error}
          />
        )}
      </FormField>

      <FormField name={`${elementName}_business_description`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
