import { theme } from './theme';

export const useStylesDataGridTable = {
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    border: `1px solid ${theme.palette.brandGray20.main}`,
    fontSize: '1rem',
    '& .MuiDataGrid-columnHeader, & .MuiTableCell-head': {
      borderBottom: '0 !important',
    },
    '& .MuiDataGrid-columnHeaderTitle, & .MuiTableCell-head': {
      lineHeight: 1.5,
    },
    '& .MuiDataGrid-cellContent': {
      padding: theme.spacing(2, 0),
      lineHeight: 1.5,
      wordBreak: 'break-word',
    },
    '& .MuiTableCell-head, & .groupLabel': {
      fontWeight: 700,
    },
    '& .MuiTableHead-root, & .groupSummary': {
      backgroundColor: theme.palette.brandGray20.main,
    },
    '& .MuiTableCell-root, & .MuiTableCell-head': {
      fontSize: '1rem',
    },
  },
  numberField: {
    border: 0,
    backgroundColor: 'transparent',
    color: theme.palette.text.main,
    fontSize: '1rem',
    padding: 0,
    fontFamily: '"Montserrat-Light", sans-serif',
    letterSpacing: '0.01071em',
    lineHeight: 1.43,
    display: 'inline',
  },
  titleTableHead: {
    borderBottom: `1px solid ${theme.palette.brandGray40.main}`,
  },
};
