/**
 * Amount types.
 *
 * @type {object}
 */
export const AMOUNT_TYPES = {
  moduleAmountsCostCategoriesPreview: 'Kwoty dla modułu - grupy kategorii kosztów (podgląd)',
  projectAmountsModulesPreview: 'Kwoty dla projektu - moduły (podgląd)',
  projectAmountsPreview: 'Kwoty dla projektu (podgląd)',
  projectAmountsCostCategoriesPreview: 'Kwoty dla projektu - grupy kategorii kosztów (podgląd)',
  projectAmountsEdition: 'Kwota dla projektu (edycja)',
  projectAmountsCostCategoriesEdition: 'Kwoty dla projektu - grupy kategorii kosztów (edycja)',
};
