import {
  PROTEST_RESULT_STATUSES,
  PROTEST_RESULT_STATUSES_TRANSLATION,
} from '../_constants';
/**
 * Protest result statuses values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const protestResultStatusesOptions = [
  {
    id: PROTEST_RESULT_STATUSES.positive,
    label: PROTEST_RESULT_STATUSES_TRANSLATION.positive,
  },
  {
    id: PROTEST_RESULT_STATUSES.negative,
    label: PROTEST_RESULT_STATUSES_TRANSLATION.negative,
  },
  {
    id: PROTEST_RESULT_STATUSES.unconsidered,
    label: PROTEST_RESULT_STATUSES_TRANSLATION.unconsidered,
  },
];
