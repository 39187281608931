import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import { scopeOfStartupIncubation } from '../../../_optionsCustomAutocomplete';

/**
 * LaunchPadIncubationProgramConceptDescription element renderer.
 *
 * @returns {LaunchPadIncubationProgramConceptDescription}
 */
export default function LaunchPadIncubationProgramConceptDescription() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* 1.Działania informacyjno-promocyjnych programu inkubacji */}
      <FormField name="concept_description_information_and_promotion_activities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 2.Organizacja naboru i rekrutacji uczestników programu */}
      <FormField name="concept_description_recruitment_organization">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 3.Działania polegające na aktywnym oraz skutecznym włączaniu do udziału
      w program inkubacji osób z grup zagrożonych dyskryminacją (jeśli dotyczy) */}
      <FormField name="concept_description_active_and_effective_inclusion_activities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 4.Sposób selekcji i oceny zgłoszonych innowacyjnych pomysłów */}
      <FormField name="concept_description_selection_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 5.Zakres tematyczny koncepcji inkubacji startupów */}
      <FormField name="concept_description_startup_incubation_concept_scope">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            multiple
            onChange={onChange}
            onBlur={onBlur}
            options={scopeOfStartupIncubation}
            optionsMapKeys={['id', 'label']}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* 6.Sposób organizacji rund inkubacyjnych, metody i narzędzia inkubacji */}
      <FormField name="concept_description_organizing_incubation_rounds_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 7.Sposób przygotowania IPI  na rzecz inkubacji startupów, z zapewnieniem
      adekwatnego doboru wyspecyfikowanych, zindywidualizowanych usług podstawowych i specjalistycznych dla startupu */}
      <FormField name="concept_description_preparing_ipi_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 8.Sposób współpracy ze startupami w ramach inkubacji (narzędzia i tryb planowanej pracy) na rzecz rozwoju
      innowacyjnych pomysłów, opracowania i testowania produktu w wersji co najmniej MVP, rynkowej weryfikacji
      modelu biznesowego */}
      <FormField name="concept_description_cooperation_with_startups_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 9.Realizacja działań na rzecz promowania zasady zrównoważonego rozwoju w ramach naboru innowacyjnych
       pomysłów oraz standardu świadczenia usług na rzecz startupów (jeśli dotyczy) */}
      <FormField name="concept_description_activities_implementation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 10.Narzędzia i metody badania postępu i prawidłowości prac w ramach zaplanowanych IPI startupów oraz
      warunki i zasady przerwania procesu inkubacji */}
      <FormField name="concept_description_tools_and_progress_research_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 11.Sposób organizacji DemoDays dla startupów na zakończenie IPI */}
      <FormField name="concept_description_days_organization_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 12.Sposób organizacji eksperckiej, rankingujacej oceny startupów na zakończenie IPI */}
      <FormField name="concept_description_expert_organization_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
