import PropTypes from 'prop-types';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  useState,
  useEffect,
} from 'react';
import uniqid from 'uniqid';
import { useElementContext } from '../../../../Context';
import { LabeledFieldRow } from '../../../Application/Application/LabeledFieldRow';
import { request } from '../../../../_services';
import { API_ROUTE } from '../../../../_constants';
import FieldConfig from '../../../Application/Application/FieldConfig/FieldConfig';
import IndicatorRow from './Indicator.row';
import { getIndicatorTargetValueFromHrf } from '../../../../_helpers';
/**
 * Indicators table component
 *
 * @param {object} props - root props
 * @param {string} props.indicatorName - indicator name
 * @returns {IndicatorsTable}
 */
function IndicatorsTable({ indicatorName }) {
  const {
    control,
    setValue,
    getValues,
  } = useFormContext();
  const [currentRow, setCurrentRow] = useState(null);
  const {
    fieldsConfig,
    id: templateElementId,
    isReadonly,
  } = useElementContext();
  const indicatorConfig = fieldsConfig[`${indicatorName}-${templateElementId}`] ?? null;

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: `${indicatorName}-${templateElementId}`,
    keyName: 'field_id',
  });

  const getFieldsFromDictionary = async () => {
    if (indicatorConfig === null) {
      return;
    }

    const indicatorTypes = {
      product_indicators: 'wskaźnik produktu',
      information_indicators: 'wskaźnik informacyjny',
      result_indicators: 'wskaźnik rezultatu',
    };

    const indicatorType = indicatorTypes[indicatorName];

    const {
      payload,
      statusSuccess,
    } = await request.get(
      `${API_ROUTE.elementIndicators}?templateElement.id=${templateElementId}&itemsPerPage=200&indicator.indicatorType=${indicatorType}`
    );

    if (statusSuccess) {
      const predefinedIndicators = payload.map((item) => {
        const existIndicator = fields.find(({ id }) => id === item.id);

        return ({
          id: item.indicator.id,
          name: existIndicator?.name || item.indicator.name,
          unit: existIndicator?.unit || item.indicator.measurementUnit,
          base_value_o: existIndicator?.base_value_o.toString() ? existIndicator.base_value_o : item.defaultBaseValue,
          base_year: existIndicator?.base_year?.toString() ? existIndicator.base_year : item.defaultBaseYear,
          base_year_range: item.baseYearRange,
          base_year_locked: item.baseYearLocked,
          target_value_o: getIndicatorTargetValueFromHrf(getValues(), item, existIndicator),
          target_year: existIndicator?.target_year?.toString() ? existIndicator.target_year : item.defaultTargetYear,
          target_year_range: item.targetYearRange,
          target_year_locked: item.targetYearLocked,
          breakdown_by_gender: item.indicator.breakdownByGender,
          measuring_method: existIndicator?.measuring_method || item.measurementMethodDescription,
          own: Boolean(existIndicator?.own),
          ...item,
        });
      });

      const ownIndicators = fields.filter((indicator) => indicator.own);

      setValue(`${indicatorName}-${templateElementId}`, [...predefinedIndicators, ...ownIndicators]);
    }
  };

  useEffect(() => {
    getFieldsFromDictionary();
  }, []);

  const handleOpenRow = (rowId) => {
    if (rowId !== currentRow) {
      setCurrentRow(rowId);

      return;
    }

    setCurrentRow('');
  };

  const addItem = () => {
    const currentId = uniqid();
    append({
      id: currentId,
      name: 'Wskaźnik',
      base_year: '',
      target_year: '',
      base_value_o: '',
      target_value_o: '',
      unit: null,
      own: true,
      measuring_method: '',
    });

    handleOpenRow(currentId);
  };

  if (indicatorConfig === null) {
    return null;
  }

  return (
    <LabeledFieldRow fieldConfig={indicatorConfig} contextHelpLabel fullWidth>
      <TableContainer
        sx={{
          width: '100%',
          marginBottom: (theme) => theme.spacing(6),
        }}
      >
        <Table
          aria-colcount={4}
          aria-rowcount={fields.length}
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%" scope="column" sx={{ fontWeight: 700 }}>Nazwa wskaźnika</TableCell>
              <TableCell width="30%" scope="column" sx={{ fontWeight: 700 }}>Jednostka miary</TableCell>
              <TableCell width="30%" scope="column" sx={{ fontWeight: 700 }}>Wartość docelowa</TableCell>
              <TableCell width="10%" scope="column" sx={{ fontWeight: 700 }}>Akcje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <IndicatorRow
                key={field.id}
                row={field}
                handleOpenRow={handleOpenRow}
                currentRow={currentRow}
                index={index}
                indicatorName={indicatorName}
                onRemove={remove}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isReadonly && (
        <FieldConfig name={`${indicatorName}-${templateElementId}.utility_add_own_indicators`}>
          {() => (
            <Box mt={3}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={addItem}
              >
                Dodaj wskaźnik
              </Button>
            </Box>
          )}
        </FieldConfig>
      )}
    </LabeledFieldRow>
  );
}

export default IndicatorsTable;

IndicatorsTable.propTypes = {
  indicatorName: PropTypes.string.isRequired,
};
