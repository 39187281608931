import PageWrapper from '../Components/PageWrapper/PageWrapper';
import {
  useApplicationToPdfContext,
  ElementContextProvider,
} from '../../../Context';
import SingleElement from '../Components/SingleElement/SingleElement';
import SelectedModulesPdf from './Elements/SelectedModules.pdf';
import InformationClausePdf from './Elements/InformationClause.pdf';

/**
 * Printout application template
 *
 * @returns {PrintoutApplicationTemplate}
 */
function PrintoutApplicationTemplate() {
  const {
    managedApplicationTemplate: {
      elementsConfig,
      elementsOrder,
      fieldsConfig,
    },
    application: {
      status, applicationNumber,
    },
  } = useApplicationToPdfContext();

  return (
    <PageWrapper
      title="Wniosek o dofinansowanie projektu"
      applicationStatus={status}
      applicationNumber={applicationNumber}
    >
      {Object.keys(elementsConfig)
        .sort((idA, idB) => elementsOrder.indexOf(idA) - elementsOrder.indexOf(idB))
        .map((elementId) => {
          const ElementComponent = elementsConfig[elementId].Component;

          if (!ElementComponent) {
            return '';
          }

          return (
            <ElementContextProvider
              key={elementId}
              value={{
                id: elementId,
                config: elementsConfig[elementId],
                fieldsConfig,
                currentElementFieldsConfig: elementsConfig[elementId]?.elementData?.elementDefinition?.fields || [],
                getFieldData: (fieldName, subfieldName) => (
                  subfieldName
                    ? fieldsConfig[fieldName].fields[subfieldName]
                    : fieldsConfig[fieldName]
                ),
              }}
            >
              <SingleElement
                key={elementId}
                title={elementsConfig[elementId]?.elementData?.label}
              >
                <ElementComponent elementData={elementsConfig[elementId].elementData} />
              </SingleElement>
              {elementsConfig[elementId]?.elementData?.elementDefinition?.name === 'applicant_potential_competition'
                && (
                  <SelectedModulesPdf />
                )}
            </ElementContextProvider>
          );
        })}
      <InformationClausePdf />
    </PageWrapper>
  );
}

export default PrintoutApplicationTemplate;
