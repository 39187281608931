/**
 * Remove.
 *
 * @todo remove
 * @param {boolean} isSecured -is secured
 * @returns {{Authorization: string}|{}}
 */
import { sessionService } from '../_security';

const authHeader = (isSecured = false) => {
  const { token } = sessionService.getUserEntries();
  if (isSecured && token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
};

export default authHeader;
