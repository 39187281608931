import {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

/**
 * Application to pdf context wrapper.
 * Contains all data about application and application template.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
const ApplicationToPdfContext = createContext({
  /**
   * Application identifier.
   */
  id: null,

  /**
   * Application object.
   *
   * @see /apidoc#operation/getApplicationItem
   */
  application: null,

  /**
   * Application template and current application data combined.
   */
  managedApplicationTemplate: {},

  /**
   * Function take dictionary by name
   */
  getDictionary: () => {},
});

/**
 * Application to pdf context hook consumer.
 *
 * @returns {object}
 */
export const useApplicationToPdfContext = () => useContext(ApplicationToPdfContext);

/**
 * Application context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ApplicationToPdfContextProvider({
  children,
  value,
}) {
  return (
    <ApplicationToPdfContext.Provider value={value}>
      {children}
    </ApplicationToPdfContext.Provider>
  );
}

ApplicationToPdfContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};
