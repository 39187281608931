import {
  FormControl,
  TextField,
  Grid,
  InputLabel,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import FieldWrapper from '../FieldWrapper';

/**
 * RegExp validation
 *
 * @param {object} props - root props
 * @param {number} props.index - validation index
 * @param {object} props.rule - rule data
 * @returns {RegExp}
 */
export default function RegExp({
  index, rule,
}) {
  const {
    control, watch, setValue, getValues,
  } = useFormContext();

  const REGEXP_VALUE_LABELS = {
    regexp_match: 'Zgodność z wyrażeniami regularnymi',
    no_regexp_match: 'Nie zgodność z wyrażeniami regularnymi',
  };

  const isEnabled = watch(`rules.${index}.enabled`);

  useEffect(() => {
    if (isEnabled && Array.isArray(getValues(`rules.${index}.options`))) {
      setValue(`rules.${index}.options`, {
        pattern: null,
        message: null,
      });
    }
  }, []);

  return (
    <FieldWrapper name={`rules.${index}.options`} label={REGEXP_VALUE_LABELS[rule.name]}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} display="flex" alignItems="center">
          <InputLabel htmlFor={`rules.${index}.options.message`}>
            Komunikat
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <Controller
              name={`rules.${index}.options.message`}
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  disabled={!isEnabled}
                  onBlur={onBlur}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} display="flex" alignItems="center">
          <InputLabel htmlFor={`rules.${index}.options.pattern`}>
            Wzór
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
          >
            <Controller
              name={`rules.${index}.options.pattern`}
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  disabled={!isEnabled}
                  onBlur={onBlur}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </FieldWrapper>

  );
}

RegExp.propTypes = {
  index: PropTypes.number.isRequired,
  rule: PropTypes.instanceOf(Object).isRequired,
};
