import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * BusinessPartners Pdf element.
 *
 * @returns {BusinessPartnersPdf}
 */
function BusinessPartnersPdf() {
  return (
    <View style={printStyle.table}>
      <CollectionWrapper name="business_partners_partners_info">
        {({
          collectionName, collectionData,
        }) => collectionData.map((item, index) => (
          <SingleCollectionWrapper key={item.set_uuid} index={index}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.partner_name_and_business_address`}
                  value={item.partner_name_and_business_address}
                  isSingleRow
                />
                <RowField
                  name={`${collectionName}.${index}.partner_nip`}
                  value={item.partner_nip}
                />
                <RowField
                  name={`${collectionName}.${index}.applicant_has_relations_with_partner`}
                  value={item.applicant_has_relations_with_partner}
                />
                <RowField
                  name={`${collectionName}.${index}.applicant_formalized_cooperation_with_partner`}
                  value={item.applicant_formalized_cooperation_with_partner}
                />
                <RowField
                  name={`${collectionName}.${index}.applicant_plans_to_find_partner`}
                  value={item.applicant_plans_to_find_partner}
                />
                <RowField
                  name={`${collectionName}.${index}.partner_cooperation_scope`}
                  value={item.partner_cooperation_scope}
                  isSingleRow
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        ))}
      </CollectionWrapper>
    </View>
  );
}

export default BusinessPartnersPdf;
