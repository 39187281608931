import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { StatementsPointRow } from './StatementsPointRow';

/**
 * Statement list table
 *
 * @param {object} props - root props
 * @param {Function} props.removeStatement - remove statement
 * @param {Array} props.statements - statement list
 * @returns {StatementsListTable}
 */
export function StatementsListTable({
  removeStatement, statements,
}) {
  const { setValue } = useFormContext();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Handle dragging action.
   *
   * @param {object} event - draggable event
   */
  const handleDrag = (event) => {
    const {
      destination,
      source,
    } = event;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      statements,
      event.source.index,
      event.destination.index
    );

    setValue('statements', items);
  };

  return (
    <TableContainer>
      <Table aria-label="Lista zastosowanych oświadczeń">
        <TableHead>
          <TableRow>
            <TableCell scope="column">Kolejność</TableCell>
            <TableCell scope="column">Oświadczenia</TableCell>
            <TableCell scope="column" width={200}>Akcje</TableCell>
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="point-list-droppable">
            {(provided) => (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <>
                  {statements.map((statement, index) => (
                    <StatementsPointRow
                      pointData={statement}
                      index={index}
                      key={statement.id}
                      deletePoint={removeStatement}
                    />
                  ))}
                </>
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}

StatementsListTable.propTypes = {
  removeStatement: PropTypes.func.isRequired,
  statements: PropTypes.arrayOf(Object).isRequired,
};
