import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';

/**
 * Originator Pdf element.
 *
 * @returns {OriginatorPdf}
 */
function OriginatorPdf() {
  return (
    <CollectionWrapper name="originator">
      {({
        collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.first_and_last_name`}
                value={item.first_and_last_name}
                oldValue={previousRowData?.first_and_last_name}
              />
              <RowField
                name={`${collectionName}.${index}.pesel`}
                value={item.pesel}
                oldValue={previousRowData?.pesel}
              />
              <RowField
                name={`${collectionName}.${index}.occupation`}
                value={item.occupation}
                oldValue={previousRowData?.occupation}
              />
              <RowField
                name={`${collectionName}.${index}.education`}
                value={item.education}
                oldValue={previousRowData?.education}
              />
              <RowField
                name={`${collectionName}.${index}.contact_information`}
                value={item.contact_information}
                oldValue={previousRowData?.contact_information}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.phone`}
                value={item.phone}
                oldValue={previousRowData?.phone}
              />
              <RowField
                name={`${collectionName}.${index}.email`}
                value={item.email}
                oldValue={previousRowData?.email}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.address`}
                value={item.address}
                oldValue={previousRowData?.address}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.voivodeship`}
                value={item.voivodeship?.label}
                oldValue={previousRowData?.voivodeship?.label}
              />
              <RowField
                name={`${collectionName}.${index}.district`}
                value={item.district?.label}
                oldValue={previousRowData?.district?.label}
              />
              <RowField
                name={`${collectionName}.${index}.commune`}
                value={item.commune?.label}
                oldValue={previousRowData?.commune?.label}
              />
              <RowField
                name={`${collectionName}.${index}.city`}
                value={item.city?.label}
                oldValue={previousRowData?.city?.label}
              />
              <RowField
                name={`${collectionName}.${index}.postal_code`}
                value={item.postal_code}
                oldValue={previousRowData?.postal_code}
              />
              <RowField
                name={`${collectionName}.${index}.street`}
                value={item.street?.label}
                oldValue={previousRowData?.street?.label}
              />
              <RowField
                name={`${collectionName}.${index}.house_number`}
                value={item.house_number}
                oldValue={previousRowData?.house_number}
              />
              <RowField
                name={`${collectionName}.${index}.apartment_number`}
                value={item.apartment_number}
                oldValue={previousRowData?.apartment_number}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default OriginatorPdf;
