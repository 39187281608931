import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import uniqid from 'uniqid';
import { API_ROUTE } from '../../_constants';
import { CustomDataGrid } from '../DataGrid/CustomDataGrid';
import { request } from '../../_services';
import { useGlobalContext } from '../../Context';

/**
 * Organization address table.
 *
 * @param {object} props - root props
 * @param {object} props.editFormHandler - function to invoke form edition
 * @returns {OrganizationAddressTable}
 */
export function OrganizationAddressTable({ editFormHandler }) {
  const [reload, setReload] = useState(0);
  const { notify } = useGlobalContext();
  const columns = [
    {
      field: 'applicantName',
      headerName: 'Nazwa',
      valueFormatter: ({ value }) => value || 'Brak danych',
      flex: 1,
    },
    {
      field: 'nip',
      headerName: 'Numer NIP',
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      headerName: 'Akcje',
      getActions: (params) => getRowActions(params),
      width: 200,
    },
  ];

  const handleDeleteOrganizationAddress = async (row) => {
    const { statusSuccess } = await request.delete(
      `${API_ROUTE.organizationAddress}/${row.id}`,
    );

    if (statusSuccess) {
      setReload((prevState) => prevState + 1);
      notify('Adres organizacji został usunięty.', 'success');
    }
  };

  /**
   * Returns row actions.
   *
   * @param {object} row - row data
   * @returns {Element[]}
   */
  const getRowActions = (row) => [
    <Button
      key={`edit-${row.id}`}
      id={uniqid()}
      onClick={() => editFormHandler(row.id)}
      variant="contained"
      color="secondary"
      size="small"
    >
      Edytuj
    </Button>,
    <Button
      key={`delete-${row.id}`}
      id={uniqid()}
      onClick={() => handleDeleteOrganizationAddress(row)}
      variant="contained"
      aria-label="Usuń organizację"
      color="primary"
      size="small"
    >
      Usuń
    </Button>,
  ];

  return (
    <CustomDataGrid
      apiSource={API_ROUTE.organizationAddress}
      columns={columns}
      reloadListener={reload}
      name="organization-address-grid"
    />
  );
}

OrganizationAddressTable.propTypes = {
  editFormHandler: PropTypes.func.isRequired,
};
