import { REGEX } from '../../_constants';

export const passwordChangeValidation = {
  password: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Hasło"',
    },
    pattern: REGEX.password,
  },
  confirmedPassword: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Powtórz hasło"',
    },
  },
};
