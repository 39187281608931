import { darken } from '@mui/material/styles';
import { theme } from '../../styles/theme';

export const alertStyleClasses = {
  alert: {
    display: 'inline-flex',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: '12.65px 16px',
    fontSize: '1rem',
    alignItems: 'center',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    backgroundColor: 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  alertError: {
    color: theme.palette.alert.error.main,
    backgroundColor: '#FFF',
    borderColor: theme.palette.alert.error.main,
  },
  alertInfo: {
    color: theme.palette.alert.info.main,
    backgroundColor: '#FFF',
    borderColor: theme.palette.alert.info.main,
  },
  alertSuccess: {
    color: theme.palette.alert.success.dark,
    backgroundColor: '#FFF',
    borderColor: theme.palette.alert.success.dark,
  },
  alertWarn: {
    color: theme.palette.alert.warn.main,
    backgroundColor: '#FFF',
    borderColor: theme.palette.alert.warn.main,
  },
  alertStatus: {
    color: 'rgba(0, 0, 0, .66)',
    backgroundColor: '#e1e3e4',
    borderColor: '#00749a',
  },
  alertValidation: {
    color: darken(theme.palette.error.main, 0.25),
    borderColor: 'transparent',
  },
  alertIcon: {
    marginRight: theme.spacing(1),
  },
  alertTitle: {
    position: 'absolute',
    top: '-0.75rem',
    display: 'inherit',
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    transform: 'translate(-20px, 0) scale(0.75)',
    padding: '0 0.5em',
    maxWidth: 'fit-content',
    zIndex: '1',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
  },
  fullWidth: {
    width: '100%',
    margin: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  modal: {
    position: 'fixed !important',
    zIndex: '1301 !important',
    width: 'auto',
    top: theme.spacing(1),
    left: theme.spacing(2),
    right: theme.spacing(3),
    margin: 0,
  },
};
