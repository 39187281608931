import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Innovation Social Significance Pdf element.
 *
 * @returns {InnovationSocialSignificancePdf}
 */
function InnovationSocialSignificancePdf() {
  const {
    application,
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const innovationSocialSignificanceIsPositiveValue = initialFormData?.innovation_social_significance_is_positive;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="innovation_social_significance_is_positive" />
        {innovationSocialSignificanceIsPositiveValue === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField
              name="innovation_social_significance_module_where_innovation_occurs"
              externalDictionary={modules}
            />
            <RowField
              name="innovation_social_significance_description"
              isSingleRow
            />
          </>
        )}
      </FieldSorter>
    </View>
  );
}

export default InnovationSocialSignificancePdf;
