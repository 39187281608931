export const DICTIONARY_STATUSES = {
  idle: 'idle',
  inQueue: 'in queue',
  inProgress: 'in progress',
  done: 'done',
  failed: 'failed',
};

export const DICTIONARY_STATUSES_TRANSLATIONS = {
  [DICTIONARY_STATUSES.idle]: 'Bezczynny',
  [DICTIONARY_STATUSES.inQueue]: 'W kolejce',
  [DICTIONARY_STATUSES.inProgress]: 'W trakcie importu',
  [DICTIONARY_STATUSES.done]: 'Gotowy do pobrania',
  [DICTIONARY_STATUSES.failed]: 'Wystąpił błąd',
};

export const AVAILABLE_FORMATS = ['csv', 'xml', 'json'];

export const CST_EXPORT_STATUSES = {
  idle: 'idle',
  inQueue: 'in queue',
  inProgress: 'in progress',
  done: 'done',
  failed: 'failed',
};

export const CST_EXPORT_STATUSES_TRANSLATIONS = {
  [CST_EXPORT_STATUSES.idle]: 'Bezczynny',
  [CST_EXPORT_STATUSES.inQueue]: 'W kolejce',
  [CST_EXPORT_STATUSES.inProgress]: 'W trakcie eksportu',
  [CST_EXPORT_STATUSES.done]: 'Wysłany',
  [CST_EXPORT_STATUSES.failed]: 'Wystąpił błąd',
};
