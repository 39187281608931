import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  debounce,
  uniqBy,
} from 'lodash';
import { request } from '../../_services';

const ACTION_TYPES = {
  ADD_TO_QUEUE: 'add_to_queue',
  POPULATE: 'populate',
};

/**
 * Common reducer for queue and loaded dictionaries.
 *
 * @param {object|object[]} state - current state
 * @param {object} action - reduce action
 * @returns {object|object[]}
 */
const reducer = (state, action) => {
  switch (action.type) {
  case ACTION_TYPES.ADD_TO_QUEUE:
    return [
      ...state,
      ...action.value,
    ];
  case ACTION_TYPES.POPULATE:
    return {
      ...state,
      ...action.value,
    };
  default:
    return state;
  }
};

/**
 * Internal pure dictionary context.
 */
const DictionaryContext = createContext({});

/**
 * Provider wrapper for dictionaries.
 *
 * @param {object} props - root props
 * @param {Node|Node[]} props.children - provider children
 * @returns {DictionaryProvider}
 */
export function DictionaryProvider({ children }) {
  /**
   * Queued dictionaries are successfully loaded.
   */
  const [complete, setComplete] = useState(false);

  /**
   * Queued dictionaries to fetch/retrieve from context.
   */
  const [queuedDictionaries, queue] = useReducer(
    reducer,
    [],
    undefined
  );

  /**
   * Retrieved dictionaries from context or fetched from API.
   */
  const [loaded, populate] = useReducer(
    reducer,
    {},
    undefined
  );

  /**
   * Populate `loaded` dictionary from `queuedDictionaries`.
   * If dictionary data already exist, will be returned from context,
   * otherwise API request will be sent to retrieve this data.
   *
   * Note that there is and must be, debounce on this function.
   * This prevents excessive requests from being sent, when there is more than
   * one component (rendered at same time) using same dictionary.
   */
  const makeUpdate = useMemo(() => debounce(() => {
    /**
     * Make sure that dictionaries will be loaded only ONCE within given name.
     *
     * @type {object[]}
     */
    const queuedUnique = uniqBy(queuedDictionaries, 'name');
    if (queuedUnique.length > 0) {
      const dictionariesToResolve = queuedUnique
        .map(({
          path, name,
        }) => {
          if (loaded[name]) {
            return loaded[name];
          }

          return request.get(path);
        });

      const loadAll = async () => {
        const loadedDictionaries = await Promise.all(dictionariesToResolve);

        loadedDictionaries.forEach((dictionaryData, index) => {
          populate({
            type: ACTION_TYPES.POPULATE,
            value: { [queuedUnique[index].name]: dictionaryData?.payload || dictionaryData },
          });
        });
      };

      loadAll().then(() => {
        setComplete(true);
      });
    }
  }, 500), [queuedDictionaries]);

  /**
   * Triggers makeUpdate() for populate `loaded` dictionary.
   * There is queuedDictionaries as effect dependency, so it's called
   * only if this value is changing.
   */
  useEffect(() => {
    makeUpdate();
  }, [makeUpdate, queuedDictionaries]);

  return (
    <DictionaryContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoaded: complete,
        get: (dictionaryName) => loaded[dictionaryName] || [],
        dictionaries: loaded,
        load: (dictionaries) => {
          setComplete(false);
          queue({
            type: ACTION_TYPES.ADD_TO_QUEUE,
            value: dictionaries,
          });
        },
      }}
    >
      {children}
    </DictionaryContext.Provider>
  );
}

DictionaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * @typedef {object} useDictionaryContext
 * @property {Function} get - gets dictionary data by name
 * @property {object} dictionaries - dictionaries map
 * @property {Function} load - load function
 * @property {boolean} isLoaded - is queued dictionaries loading completed
 */

/**
 * This context is considered as internal thing.
 * Use `useDictionaryLoader` hook to retrieve dictionaries.
 *
 * @returns {useDictionaryContext}
 */
export const useDictionaryContext = () => useContext(DictionaryContext);
