import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantExperience Pdf element.
 *
 * @returns {ApplicantExperiencePdf}
 */
function ApplicantExperiencePdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Opis prowadzonej przez wnioskodawcę działalności */}
        <RowField name="applicant_experience_business_description" />
        {/* Opis rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      projektów inwestycyjnych */}
        <RowField name="applicant_experience_services_provided_for_investment_projects" />
        {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      projektów zawierających komponent szkoleniowy */}
        <RowField name="applicant_experience_services_provided_for_projects_with_training_component" />
        {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
       projektów zawierających komponent doradczy */}
        <RowField name="applicant_experience_services_provided_for_projects_with_consulting_component" />
        {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      wykorzystania i stosowania zielonych technologii z uwzględnieniem stosowania zasady DNSH */}
        <RowField name="applicant_experience_services_provided_in_use_and_application_green_technologies" />
        {/* Tabela "Nazwa stanowiska, wymagane kompetencje oraz doświadczenie" */}
        <CollectionWrapper name="applicant_experience_information_about_applicant_completed_projects_for_sme">
          {({
            collectionData, collectionName,
          }) => collectionData.map((item, index) => (
            <SingleCollectionWrapper key={item.set_uuid} index={index}>
              <View style={printStyle.x}>
                <RowField
                  name={`${collectionName}.${index}.project_title`}
                  value={item.project_title}
                />
                <RowField
                  name={`${collectionName}.${index}.project_number`}
                  value={item.project_number}
                />
                <RowField
                  name={`${collectionName}.${index}.project_value_pln`}
                  value={item.project_value_pln}
                />
                <RowField
                  name={`${collectionName}.${index}.implementation_start_date`}
                  value={item.implementation_start_date}
                />
                <RowField
                  name={`${collectionName}.${index}.implementation_end_date`}
                  value={item.implementation_end_date}
                />
                <RowField
                  name={`${collectionName}.${index}.funding_source`}
                  value={item.funding_source}
                />
                <RowField
                  name={`${collectionName}.${index}.role_in_project`}
                  value={item.role_in_project}
                />
                <RowField
                  name={`${collectionName}.${index}.recipients_number`}
                  value={item.recipients_number}
                />
              </View>
            </SingleCollectionWrapper>
          ))}
        </CollectionWrapper>
        {/* Data zamknięcia ostatniego roku obrotowego trwającego co najmniej 12 miesięcy */}
        <RowField name="last_fiscal_year_closing_date" />
      </FieldSorter>
    </View>
  );
}

export default ApplicantExperiencePdf;
