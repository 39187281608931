import { useState } from 'react';
import {
  Button,
  ButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { CustomDataGrid } from '../../Features/DataGrid/CustomDataGrid';
import { API_ROUTE } from '../../_constants';
import { request } from '../../_services';
import { formatDateString } from '../../_helpers';
import ExpandableUuid from '../../Features/ExpandableUuid';
import { useGlobalContext } from '../../Context';

/**
 * Logotypes configurator table component
 *
 * @param {object} props - root props
 * @param {object} props.onEdit - function to invoke form edition
 * @returns {LogotypesConfiguratorTable}
 */
function LogotypesConfiguratorTable({ onEdit }) {
  const [reload, setReload] = useState(0);
  const { notify } = useGlobalContext();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  /**
   * Returns row actions
   *
   * @param {object} row - table record data
   * @returns {Element[]}
   */
  const getRowActions = (row) => [
    <ButtonGroup orientation="vertical" variant="contained">
      <Button
        type="button"
        variant="contained"
        color="secondary"
        id={uniqid()}
        onClick={() => onEdit(row.row)}
      >
        Edytuj
      </Button>
      <Button
        type="button"
        variant="contained"
        color="primary"
        id={uniqid()}
        onClick={() => handleDeleteTemplateLogotype(row.row.id)}
      >
        Usuń
      </Button>
    </ButtonGroup>,
  ];

  const columns = [
    {
      field: 'id',
      renderCell: (({ value }: Object) => <ExpandableUuid uuid={value} wrap />),
    },
    {
      field: 'logotype.attachment.description',
      headerName: 'Nazwa logotypu',
      filterable: false,
      flex: 1,
    },
    {
      field: 'groups',
      headerName: 'Wybrany krok',
      filterable: false,
      valueFormatter: ({ value }) => value.join(', '),
      flex: 1,
    },
    {
      field: 'availableFrom',
      headerName: 'Data od',
      valueFormatter: ({ value }) => formatDateString(value),
      type: 'date',
      filterable: false,
      flex: 1,
    },
    {
      field: 'availableTo',
      headerName: 'Data do',
      valueFormatter: ({ value }) => formatDateString(value),
      type: 'date',
      filterable: false,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      filterable: false,
      headerName: 'Akcje',
      getActions: getRowActions,
    },
  ];

  const handleDeleteTemplateLogotype = async (id) => {
    const { statusSuccess } = await request.delete(
      `${API_ROUTE.templateLogotypes}/${id}`,
    );

    if (statusSuccess) {
      setReload((prevState) => prevState + 1);
      notify('Logotyp został usunięty ze wzoru wniosku.', 'success');
    }
  };

  return (
    <CustomDataGrid
      apiSource={API_ROUTE.templateLogotypes}
      columns={columns}
      disableFilterToolbar
      disablePagination
      reloadListener={reload}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
      name="logotypes-configurator-grid"
    />
  );
}

export default LogotypesConfiguratorTable;

LogotypesConfiguratorTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
};
