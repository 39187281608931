import { MODULE_IDS } from '../_constants';

const noConnections = {
  '@id': null,
  name: 'Brak powiązania',
};

export const getModules = (name, value, modules) => {
  if (value?.includes(null)) {
    return [noConnections];
  }

  const options = {
    link_between_modules_br: modules.filter(({ id }) => id !== MODULE_IDS.br),
    link_between_modules_rd_infrastructure: modules.filter(({ id }) => id !== MODULE_IDS.br
      && id !== MODULE_IDS.rdInfrastructure && id !== MODULE_IDS.innovationImplementation),
    link_between_modules_innovation_implementation: modules.filter(({ id }) => id !== MODULE_IDS.br
      && id !== MODULE_IDS.innovationImplementation),
    link_between_modules_competences: modules.filter(({ id }) => id === MODULE_IDS.internationalization),
    link_between_modules_digitization: modules.filter(({ id }) => id === MODULE_IDS.internationalization
      || id === MODULE_IDS.competences || id === MODULE_IDS.greening),
    link_between_modules_greening: modules.filter(({ id }) => id === MODULE_IDS.internationalization
      || id === MODULE_IDS.competences),
  };

  if (value?.length > 0) {
    return options[name];
  }

  return [...options[name], noConnections];
};
