import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * VentureImplementationJustification Pdf element.
 *
 * @returns {VentureImplementationJustificationPdf}
 */
function VentureImplementationJustificationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Cel przedsięwzięcia  */}
        <RowField name="venture_implementation_justification_venture_purpose" isSingleRow />
        {/* Opis realizacji przedsięwzięcia  */}
        <RowField name="venture_implementation_justification_venture_implementation_description" isSingleRow />
        {/* W jaki sposób przedsięwzięcie przyczyni się do realizacji celu inwestycji A.2.2.1 KPO,
      jakim jest ułatwienie transformacji przedsiębiorstw w kierunku gospodarki o obiegu zamkniętym (GOZ)
      poprzez wsparcie projektów wpisujących się w regulacje unijne (CEAP 2020) i krajowe („Mapa drogowa
      transformacji w kierunku gospodarki obiegu zamkniętego”)?  */}
        <RowField name="venture_implementation_justification_achieving_purpose_method" isSingleRow />
        {/* Opis bezpośredniego związku przedsięwzięcia z gospodarką o obiegu zamkniętym    */}
        <RowField name="venture_implementation_justification_venture_and_economy_connection_description" isSingleRow />
        {/* Opis sposobu wprowadzenia innowacji, w tym innowacji środowiskowej ( jeśli dotyczy)    */}
        <RowField
          name="venture_implementation_justification_implementation_innovation_method_description"
          isSingleRow
        />
        {/* Poziom innowacyjności rozwiązania */}
        <RowField
          name="venture_implementation_justification_solution_innovation_level"
          dictionaryName={DICTIONARIES.solutionInnovationLevels.name}
        />
        {/* Uzasadnienie poziomu innowacyjności rozwiązania */}
        <RowField name="venture_implementation_justification_innovation_level_justification" isSingleRow />
        {/* Opis sposobu wprowadzenia technologii środowiskowych związanej z gospodarką o obiegu
      zamkniętym ( jeśli dotyczy) */}
        <RowField
          name="venture_implementation_justification_environmental_technologies_methods_description"
          isSingleRow
        />
      </FieldSorter>
    </View>
  );
}

export default VentureImplementationJustificationPdf;
