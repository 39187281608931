import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../_security';
import { useGlobalDialog } from '../../Context';

/**
 * LogoutConfirmationForm element.
 *
 * @returns {LogoutConfirmationForm}
 */
function LogoutConfirmationForm() {
  const [closeAllSessions, setCloseAllSessions] = useState(false);
  const { onLogout } = useAuth();
  const { closeAll } = useGlobalDialog();

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            id="QTwKSqJm7vyuXcT"
            checked={closeAllSessions}
            name="closeAllSessions"
            color="secondary"
            onClick={(event) => setCloseAllSessions(event.target.checked)}
            inputProps={{ 'aria-label': 'Wyloguj mnie ze wszystkich sesji' }}
          />
        )}
        label="Wyloguj mnie ze wszystkich sesji"
      />
      <Box display="flex" justifyContent="center" gap={3} mt={5}>
        <Button
          id="W6osuY65Xt0KXtW"
          color="secondary"
          variant="contained"
          onClick={() => onLogout(closeAllSessions, closeAll)}
        >
          Wyloguj
        </Button>
        <Button
          id="fqb1igwtCNONwJ8"
          color="primary"
          variant="outlined"
          onClick={closeAll}
        >
          Anuluj
        </Button>
      </Box>
    </>
  );
}

export default LogoutConfirmationForm;
