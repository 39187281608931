import PropTypes from 'prop-types';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../../Context';
import { DICTIONARIES } from '../../../../../_constants';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * GreeningEnvironmentalProtectionAidTypePdf element.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {GreeningEnvironmentalProtectionAidTypePdf}
 */
function GreeningEnvironmentalProtectionAidTypePdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
        <RowField
          name={`${collectionName}.${index}.applicant_name`}
          value={item.applicant_name}
          oldValue={previousRowData?.applicant_name}
          externalDictionary={combinedApplicantName}
        />
        <RowField
          name={`${collectionName}.${index}.environmental_aid_type`}
          value={item.environmental_aid_type}
          oldValue={previousRowData?.environmental_aid_type}
          dictionaryName={DICTIONARIES.environmentalAidType.name}
        />
        <RowField
          name={`${collectionName}.${index}.condition_description`}
          value={item.condition_description}
          oldValue={previousRowData?.condition_description}
        />
      </FieldSorter>
    </SingleCollectionWrapper>
  );
}

export default GreeningEnvironmentalProtectionAidTypePdf;

GreeningEnvironmentalProtectionAidTypePdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

GreeningEnvironmentalProtectionAidTypePdf.defaultProps = {
  previousRowData: {},
};
