import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';

/**
 * ExportPotential element renderer.
 *
 * @returns {ExportPotential}
 */
export function ExportPotential() {
  const { isReadonly } = useElementContext();

  const {
    watch, resetField,
  } = useFormContext();

  const isProductBrandValue = watch('export_potential_product_brand_awarded')
    === BOOLEAN_VALUES.TRUE;

  const isRegularlyProductValue = watch('export_potential_applicant_regularly_notified_products')
  === BOOLEAN_VALUES.TRUE;

  const isPublicProcurementValue = watch(
    'export_potential_applicant_participated_in_international_public_procurement'
  ) === BOOLEAN_VALUES.TRUE;

  const isProcessInnovationValue = watch('export_potential_applicant_introduced_process_innovation')
  === BOOLEAN_VALUES.TRUE;

  return (
    <FieldSorter>
      {/* Zasoby finansowe, techniczne i kadrowe do realizacji działań promocyjnych */}
      <FormField name="export_potential_resources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Marka produktowa została nagrodzona/wyróżniona w konkursie lub ujęta w rankingu branżowym */}
      <FormField name="export_potential_product_brand_awarded">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'export_potential_product_brand_awarded_justification',
                  { defaultValue: FIELD_TYPES_MAP.limited_choice }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {isProductBrandValue && (
        <FormField name="export_potential_product_brand_awarded_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Wnioskodawca zgłaszał regularnie produkty do udziału w wydarzeniu targowym */}
      <FormField name="export_potential_applicant_regularly_notified_products">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'export_potential_applicant_regularly_notified_products_justification',
                  { defaultValue: FIELD_TYPES_MAP.limited_choice }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {isRegularlyProductValue && (
        <FormField name="export_potential_applicant_regularly_notified_products_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Wnioskodawca brał udział w międzynarodowych zamówieniach publicznych lub realizował te zamówienia */}
      <FormField name="export_potential_applicant_participated_in_international_public_procurement">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'export_potential_applicant_participated_in_international_public_procurement_justification',
                  { defaultValue: FIELD_TYPES_MAP.limited_choice }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {isPublicProcurementValue && (
        <FormField name="export_potential_applicant_participated_in_international_public_procurement_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Wnioskodawca wprowadził w przedsiębiorstwie innowację procesową lub produktową */}
      <FormField name="export_potential_applicant_introduced_process_innovation">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'export_potential_applicant_introduced_process_innovation_justification',
                  { defaultValue: FIELD_TYPES_MAP.limited_choice }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {isProcessInnovationValue && (
        <FormField name="export_potential_applicant_introduced_process_innovation_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
    </FieldSorter>
  );
}
