import { useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { FIELD_TYPES_MAP } from '../../../_constants';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import FieldConfig from '../../Application/Application/FieldConfig/FieldConfig';
import ImplementationAddresses from './ProjectImplementationArea/ImplementationAddresses';

/**
 * Project implementation area element renderer.
 *
 * @returns {ProjectImplementationArea}
 */
export function ProjectImplementationArea() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const { application } = useApplicationContext();
  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const {
    control, watch, getValues, setValue, resetField,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'implementation_addresses',
  });

  const projectImplementationAreaConfig = fieldsConfig.implementation_addresses ?? {};
  const subfields = projectImplementationAreaConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const implementedInRegionsValue = watch('implemented_in_regions');

  const {
    maxRowNumber,
    minRowNumber,
  } = projectImplementationAreaConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, implementedInRegionsValue]);

  const uncheckAllMainAddresses = () => {
    const addresses = getValues('implementation_addresses');
    for (let i = 0; i < addresses.length; i += 1) {
      addresses[i].main_implementation_address = false;
    }
    setValue('implementation_addresses', addresses);
  };

  const onChangeMainAddress = (changeEvent, defaultOnChange) => {
    if (changeEvent.target.checked === true) {
      uncheckAllMainAddresses();
    }

    return defaultOnChange(changeEvent);
  };

  const onChangeRegion = (field, defaultOnChange) => {
    field?.id === 'region'
      ? addItem()
      : resetField('implementation_addresses', { defaultValue: FIELD_TYPES_MAP.collection });

    return defaultOnChange(field);
  };

  return (
    <FieldSorter>
      {/* Obszar realizacji projektu */}
      <FormField name="implemented_in_regions">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(field) => onChangeRegion(field, onChange)}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Tabela "Obszary realizacji projektu" */}
      <LabeledFieldRow
        hide={implementedInRegionsValue !== 'region'}
        fieldConfig={projectImplementationAreaConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            key={item.id}
            isReadonly={isReadonly}
            itemIndex={index}
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'implementation_addresses',
              index,
            )}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Główny obszar realizacji projektu */}
            <FieldConfig name={`implementation_addresses.${index}.main_implementation_address`}>
              {({
                name, label, title,
              }) => (
                <Grid item xs={12} display="flex">
                  <FormField name={name} labelled={false}>
                    {({
                      onChange, value,
                    }) => (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            id={name}
                            name={name}
                            color="secondary"
                            checked={value}
                            onChange={(event) => onChangeMainAddress(event, onChange)}
                            sx={{
                              marginLeft: (theme) => theme.spacing(1),
                            }}
                          />
                        )}
                        label={label || title}
                        labelPlacement="start"
                      />
                    )}
                  </FormField>
                </Grid>
              )}
            </FieldConfig>

            <ImplementationAddresses index={index} isFieldsRow />

            {/* Nr budynku */}
            <FormField name={`implementation_addresses.${index}.house_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nr lokalu */}
            <FormField name={`implementation_addresses.${index}.apartment_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Kod pocztowy */}
            <FormField name={`implementation_addresses.${index}.postal_code`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  placeholder="__-___"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                  }}
                  inputProps={{
                    mask: '00-000',
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Tytuł prawny do nieruchomości, w której projekt będzie realizowany */}
            <FormField name={`implementation_addresses.${index}.legal_title_to_real_estate`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nazwa modułu, którego dotyczy obszar rozwoju kompetencji */}
            <FormField name={`implementation_addresses.${index}.module_name`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  multiple
                  options={filterOptions(modules)}
                  disabled={isReadonly}
                  renderSelectAllButton={false}
                  error={error}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="hnTAKlTKyt0CyM1"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
