import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  API_ROUTE,
  PROFILES,
  ACTION_TYPES,
} from '../../../_constants';
import { request } from '../../../_services';
import { useAuth } from '../../../_security';
import { useGlobalContext } from '../../../Context';

/**
 * QuickProfileSwitching development env component.
 *
 * @param {object} props root
 * @param {Function} props.closeHandler development menu tools close handler
 * @returns {QuickProfileSwitching} component that allows to quick profile switch
 */
export function QuickProfileSwitching({ closeHandler }) {
  const {
    profile: currentProfile, onUpdate,
  } = useAuth();
  const navigate = useNavigate();
  const { handleAction } = useGlobalContext();

  /**
   * Send request to API for profile switch.
   *
   * @param {string} profile - profile name
   */
  const handleProfileSwitch = async (profile) => {
    const { statusSuccess } = await request.put(
      API_ROUTE.switchProfile,
      { profile }
    );

    if (statusSuccess) {
      onUpdate({ profile });
      handleAction({ type: ACTION_TYPES.SHOW_NEW_MESSAGE_DIALOG });
      navigate('/');
      closeHandler();
    }
  };

  return (
    <>
      <MenuItem
        value={PROFILES.applicant}
        selected={currentProfile === PROFILES.applicant}
        onClick={() => handleProfileSwitch(PROFILES.applicant)}
      >
        Wnioskodawca
      </MenuItem>
      <MenuItem
        value={PROFILES.expert}
        selected={currentProfile === PROFILES.expert}
        onClick={() => handleProfileSwitch(PROFILES.expert)}
      >
        Ekspert
      </MenuItem>
      <MenuItem
        value={PROFILES.employee}
        selected={currentProfile === PROFILES.employee}
        onClick={() => handleProfileSwitch(PROFILES.employee)}
      >
        Pracownik
      </MenuItem>
      <MenuItem
        value={PROFILES.operator}
        selected={currentProfile === PROFILES.operator}
        onClick={() => handleProfileSwitch(PROFILES.operator)}
      >
        Operator
      </MenuItem>
      <MenuItem
        value={PROFILES.managementInstitution}
        selected={currentProfile === PROFILES.managementInstitution}
        onClick={() => handleProfileSwitch(PROFILES.managementInstitution)}
      >
        Instytucja Zarządzająca
      </MenuItem>
    </>
  );
}

QuickProfileSwitching.propTypes = {
  closeHandler: PropTypes.func,
};

QuickProfileSwitching.defaultProps = {
  closeHandler: () => {
  },
};
