import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import {
  useEffect,
  useCallback,
} from 'react';
import { findIndex } from 'lodash';
import { DICTIONARIES } from '../../../_constants';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * AccelerationProgramImplementationTeam element renderer.
 *
 * @returns {AccelerationProgramImplementationTeam}
 */
export function AccelerationProgramImplementationTeam() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    watch, setValue,
  } = useFormContext();
  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.responsibilityAreas,
  );
  const collectionName = 'acceleration_program_implementation_team_organizational_structure';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const accelerationProgram = watch(collectionName);
  const filterConsumed = useCallback(
    (fieldIndex, options) => {
      const consumed = accelerationProgram
        .map(({ responsibility_area: responsibilityAreas }, index) => ({
          iri: responsibilityAreas,
          index,
        }));

      const filterFn = ({ '@id': optionIri }) => {
        const consumedIndex = findIndex(consumed, { iri: optionIri });
        if (consumedIndex === -1) {
          return true;
        }

        return consumedIndex === fieldIndex;
      };

      return options.filter(filterFn);
    },
    [accelerationProgram],
  );

  const accelerationProgramConfig = fieldsConfig[collectionName];
  const subfields = accelerationProgramConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = accelerationProgramConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Doświadczenie i kompetencje 3 kluczowych członków zespołu realizującego program akceleracji */}
      <FormField name="acceleration_program_implementation_team_experience_and_competence_three_key_members">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Doświadczenie i kompetencje 2 kluczowych ekspertów oceniających startupy biorących udział w naborze */}
      <FormField name="acceleration_program_implementation_team_experience_and_competence_two_key_experts">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Nazwa stanowiska, wymagane kompetencje oraz doświadczenie" */}
      <LabeledFieldRow fieldConfig={accelerationProgramConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Obszar odpowiedzialności */}
            <FormField name={`${collectionName}.${index}.responsibility_area`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                  }}
                  options={filterConsumed(index, filterOptions(get(DICTIONARIES.responsibilityAreas.name)))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Nazwa stanowiska, wymagane kompetencje oraz doświadczenie */}
            <FormField name={`${collectionName}.${index}.job_title_required_competencies_and_experience`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Zakres zadań */}
            <FormField name={`${collectionName}.${index}.work_scope`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="2lkbFGX0erXWM9q"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
