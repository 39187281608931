/**
 * Evaluation methods.
 *
 * @type {object}
 */
export const EVALUATION_METHODS = {
  point_criterion: 'kryterium punktowe',
  yes_no_criterion: 'kryterium tak/nie',
  yes_no_not_applicable_criterion: 'kryterium tak/nie/nie dotyczy',
};
