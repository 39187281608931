import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import RowField from '../../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DICTIONARIES,
  MODULE_IRIS,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../../_constants';
import { useApplicationToPdfContext } from '../../../../../Context';
import { getOwnIndicators } from '../../../../../_helpers';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * RdModuleEssenceNoveltyOfResultsPdf element.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {Array} props.collectionData - collection data item
 * @param {object} props.previousRowData - previous row data
 * @returns {RdModuleEssenceNoveltyOfResultsPdf}
 */
function RdModuleEssenceNoveltyOfResultsPdf({
  collectionName, item, index, collectionData, previousRowData,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      elementsConfig,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const { innovationTypes } = ELEMENTS_DICTIONARIES_MAP;
  const innovationTypeValue = item?.innovation_type;
  const combinedIndicatorsInBusinessProcessDictionary = [
    ...getOwnIndicators(
      elementsConfig,
      initialFormData,
      item.indicators_in_the_business_process,
      MODULE_IRIS.br
    ),
    ...getOwnIndicators(
      elementsConfig,
      previousInitialFormData,
      previousRowData?.indicators_in_the_business_process,
      MODULE_IRIS.br
    ),
  ];

  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.innovation_type`}
            value={item.innovation_type}
            oldValue={previousRowData?.innovation_type}
            dictionaryName={DICTIONARIES.innovationTypes.name}
          />
          <RowField
            name={`${collectionName}.${index}.product_name`}
            value={item.product_name}
            oldValue={previousRowData?.product_name}
          />
          <RowField
            name={`${collectionName}.${index}.is_product_implemented`}
            value={item.is_product_implemented}
            oldValue={previousRowData?.is_product_implemented}
          />
          <RowField
            name={`${collectionName}.${index}.novelty_level`}
            value={item.novelty_level}
            oldValue={previousRowData?.novelty_level}
          />
          <RowField
            name={`${collectionName}.${index}.product_innovation_description`}
            value={item.product_innovation_description}
            oldValue={previousRowData?.product_innovation_description}
            isSingleRow
          />
          <RowField
            name={`${collectionName}.${index}.indicators_in_the_business_process`}
            value={item.indicators_in_the_business_process}
            oldValue={previousRowData?.indicators_in_the_business_process}
            externalDictionary={combinedIndicatorsInBusinessProcessDictionary.map((indicator) => ({
              '@id': indicator.id,
              name: indicator.name,
            }))}
          />
          <RowField
            name={`${collectionName}.${index}.product_innovation_feature`}
            value={item.product_innovation_feature}
            oldValue={previousRowData?.product_innovation_feature}
          />
          <RowField
            name={`${collectionName}.${index}.benefit`}
            value={item.benefit}
            oldValue={previousRowData?.benefit}
          />
          <RowField
            name={`${collectionName}.${index}.technical_parameters`}
            value={item.technical_parameters}
            oldValue={previousRowData?.technical_parameters}
          />
          {innovationTypeValue === innovationTypes.productInnovation.id && (
            <RowField
              name={`${collectionName}.${index}.product_feature_for_customers`}
              value={item.product_feature_for_customers}
              oldValue={previousRowData?.product_feature_for_customers}
            />
          )}
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default RdModuleEssenceNoveltyOfResultsPdf;

RdModuleEssenceNoveltyOfResultsPdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

RdModuleEssenceNoveltyOfResultsPdf.defaultProps = {
  previousRowData: {},
};
