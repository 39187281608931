import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../Components/RowField/RowField';

/**
 * Applicant potential competition pdf element renderer.
 *
 * @returns {ApplicantPotentialCompetitionPdf}
 */
function ApplicantPotentialCompetitionPdf() {
  return (
    <CollectionWrapper name="applicant_potential_competition">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.name`}
                value={item.name}
                oldValue={previousRowData?.name}
              />
              <RowField
                name={`${collectionName}.${index}.nip`}
                value={item.nip}
                oldValue={previousRowData?.nip}
              />
              <RowField
                name={`${collectionName}.${index}.description`}
                value={item.description}
                oldValue={previousRowData?.description}
                isSingleRow
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ApplicantPotentialCompetitionPdf;
