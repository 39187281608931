import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useElementContext } from '../../../../Context';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../../_constants';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementersAndApplicantName,
} from '../../../../_helpers';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';

/**
 * Internationalization module essence property protection component
 *
 * @returns {InternationalizationModuleEssencePropertyProtection}
 */
export default function InternationalizationModuleEssencePropertyProtection() {
  const { id: applicationId } = useParams();
  const {
    control, watch, getValues, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    get,
  } = useDictionaryLoader(
    DICTIONARIES.internationalizationModuleEssenceTicketCategories,
    DICTIONARIES.internationalizationModuleEssenceTicketModes,
    DICTIONARIES.countries,
  );

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'internationalization_module_essence_property_protection',
  });

  const internationalizationModuleEssencePropertyProtectionConfig = fieldsConfig
    .internationalization_module_essence_property_protection;
  const subfields = internationalizationModuleEssencePropertyProtectionConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = internationalizationModuleEssencePropertyProtectionConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  // eslint-disable-next-line max-len
  const otherTicketModeId = '/lsi/recruitments/api/internationalization-module-essence-ticket-modes/41d41d5f-5be9-4143-8e89-3745eede2123';

  return (
    <>
      {/* Tabela \"Uzyskanie ochrony własności przemysłowej poza Polską\" */}
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'internationalization_module_essence_property_protection',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed={!subfields.ticket_number}
        >
          {/* Nazwa wnioskodawcy ubiegającego się o obronę */}
          <FormField
            // Zgłoszenie nr
            extraLabel={`${subfields.ticket_number?.label} ${index + 1}`}
            name={`internationalization_module_essence_property_protection.${index}.applicant_name`}
          >
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={getImplementersAndApplicantName(getValues, applicationId)}
                multiple
                renderSelectAllButton={false}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Pełny tytuł/ nazwa przedmiotu zgłoszenia */}
          <FormField
            name={`internationalization_module_essence_property_protection.${index}.title`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Kategoria przedmiotu zgłoszenia */}
          <FormField
            name={`internationalization_module_essence_property_protection.${index}.ticket_category`}
          >
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceTicketCategories.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Tryb zgłoszenia */}
          <FormField
            name={`internationalization_module_essence_property_protection.${index}.ticket_mode`}
          >
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceTicketModes.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {watch(`internationalization_module_essence_property_protection.${index}.ticket_mode`) === otherTicketModeId
            && (
              // Inny tryb zgłoszenia
              <FormField
                name={`internationalization_module_essence_property_protection.${index}.other_ticket_mode`}
              >
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            )}
          {/* Obszar planowanej ochrony objętej zgłoszeniem */}
          <FormField
            name={`internationalization_module_essence_property_protection.${index}.protection_scope`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Obszar planowanej ochrony objętej zgłoszeniem – lista z krajami */}
          <FormField
            name={`internationalization_module_essence_property_protection.${index}.countries`}
          >
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                multiple
                options={filterOptions(get(DICTIONARIES.countries.name))}
                disabled={isReadonly}
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="uM08UeSAAhKLPFD"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
