import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useCustomSnackbar } from '../../../../_hooks';
import { request } from '../../../../_services';
import { API_ROUTE } from '../../../../_constants';
import { useElementContext } from '../../../../Context';
import { SaveCancelButtons } from '../../../SaveCancelButtons';

/**
 * Delete task dialog
 *
 * @param {object} props - root props
 * @param {string} props.taskId - task id
 * @param {string} props.taskName - task name
 * @param {Function} props.closeHandler - dialog close handler
 * @param {Function} props.reloadData - reload data from API
 * @param {boolean} props.isOpen - is open dialog
 * @param {object} props.expensesSummaryRef - expenses summary ref
 * @returns {DeleteTaskDialog}
 */
function DeleteTaskDialog({
  taskId, taskName, closeHandler, reloadData, isOpen, expensesSummaryRef,
}) {
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { id: applicationId } = useParams();
  const {
    id: elementId,
  } = useElementContext();
  const {
    control, setValue, getValues,
  } = useFormContext();

  const {
    fields, remove,
  } = useFieldArray({
    control,
    name: `tasks-${elementId}`,
  });

  const onDelete = async () => {
    setSubmitting(true);
    const { statusSuccess } = await request.delete(`${API_ROUTE.applicationTasks
      .replace(':applicationId', applicationId)
      .replace(':elementId', elementId)}/${taskId}`);

    statusSuccess
      ? successNotification(`Zadanie ${taskName} zostało usunięte.`)
      : errorNotification(`Nie udało się usunąć zadania ${taskName}. Spróbuj ponownie.`);
    if (!statusSuccess) {
      closeHandler();

      return;
    }

    closeHandler();
    reloadData(elementId);

    expensesSummaryRef?.current?.handleReloadData(elementId);

    remove(fields.findIndex((field) => field.task_id === taskId));

    const lumpSumExpensesCollectionName = `lump_sum_expenses-${elementId}`;
    const actualExpensesCollectionName = `actual_expenses-${elementId}`;

    const lumpSumExpenses = getValues(lumpSumExpensesCollectionName) || [];
    const actualExpenses = getValues(actualExpensesCollectionName) || [];

    const filtredLumpSumExpenses = lumpSumExpenses.filter((expense) => expense.task_id !== taskId);
    const filtredActualExpenses = actualExpenses.filter((expense) => expense.task_id !== taskId);

    setValue(lumpSumExpensesCollectionName, filtredLumpSumExpenses);
    setValue(actualExpensesCollectionName, filtredActualExpenses);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      variant="outlined"
      color="secondary"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle component="div">
        <Typography variant="dialogHeading">
          Usuń zadanie
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {`Czy na pewno chcesz usunąć zadanie "${taskName}"?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <SaveCancelButtons
          saveDisabled={isSubmitting}
          cancelHandler={closeHandler}
          cancelButtonId="K398PJ1BKK6r13t"
          cancelTitle="NIE"
          saveHandler={onDelete}
          saveButtonId="yDzSJbDWSSrZsp4"
          saveTitle="TAK"
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTaskDialog;

DeleteTaskDialog.propTypes = {
  taskId: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  expensesSummaryRef: PropTypes.objectOf(Object).isRequired,
};
