import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../../Context';
import { DATE } from '../../../../../_constants';

/**
 * Implemented Projects Pdf sub element.
 *
 * @param {object} props - root props
 * @param {string} props.parentCollectionName - sub collection name
 * @param {number} props.index - current parent index
 * @returns {ImplementedProjectsPdf}
 */
function ImplementedProjectsPdf({
  parentCollectionName, index,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      fieldsConfig,
    },
  } = useApplicationToPdfContext();

  const implementedProjectsConfig = fieldsConfig?.[parentCollectionName]?.fields?.implemented_projects;
  const implementedProjectsData = initialFormData[parentCollectionName][index]?.implemented_projects || [];

  return (
    <CollectionWrapper
      name={`${parentCollectionName}.${index}.implemented_projects`}
      externalFieldConfig={implementedProjectsConfig}
      externalData={implementedProjectsData}
    >
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, childIndex) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={childIndex} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${childIndex}.title`}
                value={item.title}
                oldValue={previousRowData?.title}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${childIndex}.budget_pln`}
                value={item.budget_pln}
                oldValue={previousRowData?.budget_pln}
              />
              <RowField
                name={`${collectionName}.${childIndex}.number`}
                value={item.number}
                oldValue={previousRowData?.number}
              />
              <RowField
                name={`${collectionName}.${childIndex}.implementation_start_date`}
                value={item.implementation_start_date}
                oldValue={previousRowData?.implementation_start_date}
                formatDate={DATE.onlyYear}
              />
              <RowField
                name={`${collectionName}.${childIndex}.implementation_end_date`}
                value={item.implementation_end_date}
                oldValue={previousRowData?.implementation_end_date}
                formatDate={DATE.onlyYear}
              />
              <RowField
                name={`${collectionName}.${childIndex}.is_consortium`}
                value={item.is_consortium}
                oldValue={previousRowData?.is_consortium}
              />
              <RowField
                name={`${collectionName}.${childIndex}.role`}
                value={item.role}
                oldValue={previousRowData?.role}
              />
              <RowField
                name={`${collectionName}.${childIndex}.main_effects`}
                value={item.main_effects}
                oldValue={previousRowData?.main_effects}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ImplementedProjectsPdf;

ImplementedProjectsPdf.propTypes = {
  parentCollectionName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
