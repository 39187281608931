import { URL } from './URL';
import { ReactComponent as ContractIcon } from '../images/contract.svg';
import { ReactComponent as RatingIcon } from '../images/rating.svg';
import { ReactComponent as PanelIcon } from '../images/panel.svg';
import { ReactComponent as ProtestsIcon } from '../images/protests.svg';
import { ReactComponent as OpinionsIcon } from '../images/opinions.svg';
import { ReactComponent as TodoListIcon } from '../images/todoList.svg';
import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { ReactComponent as LSILogo } from '../images/logo_LSI.svg';
import { PROFILES } from './profiles';

export const EXPERT_HOME_CARDS = [
  {
    id: '1c352588-c507-4f9f-9951-00886acb0b96',
    label: 'ocena',
    icon: RatingIcon,
    url: URL.expert.substantiveEvaluation,
  },
  {
    id: '76921b07-26c1-4603-ab81-9ad46f428575',
    label: 'panel',
    icon: PanelIcon,
    url: URL.expert.expertPanels,
  },
  {
    id: 'ae38f99f-9038-4db9-953c-775f12a5e930',
    label: 'protesty',
    icon: ProtestsIcon,
    url: URL.reviews,
  },
  {
    id: '0ba7d496-f697-4650-8b49-a537747510ec',
    label: 'opinie',
    icon: OpinionsIcon,
    url: URL.expert.opinions,
  },
  {
    id: 'c1f0e0ed-df72-4dfb-861c-6420455a51ea',
    label: 'umowy',
    icon: ContractIcon,
    url: URL.expertContract,
  },
];

export const APPLICANT_HOME_CARDS = [
  {
    id: '18af82d6-c471-4e03-b325-3e2ea7e9c0d0',
    label: 'moje wnioski',
    icon: ContractIcon,
    url: URL.application.myApplications,
  },
  {
    id: '341eebd4-4267-44a8-8f75-6410c2db7736',
    label: 'trwające nabory',
    icon: CalendarIcon,
    url: URL.recruitment.ongoing,
  },
  {
    id: '3b5826a9-faad-4443-a28e-bc129dfec9dd',
    label: 'lista zadań',
    icon: TodoListIcon,
    url: URL.application.todoList,
  },
];

export const MANAGEMENT_INSTITUTION_HOME_CARDS = [
  {
    id: '11d40b5f-3817-475a-94c4-7f6a0b266668',
    label: 'nabory',
    icon: ContractIcon,
    url: URL.recruitment.list,
  },
  {
    id: '145aba2c-5945-42aa-8404-c47a36a76959',
    label: 'pomoc',
    icon: PanelIcon,
    url: URL.userGuide,
  },
];
export const OPERATOR_HOME_CARDS = [
  {
    id: '00408411-8fd5-4c11-9e32-392f9ddd1255',
    label: 'wnioski',
    icon: ContractIcon,
    url: URL.applications.submittedApplicationsWithOperator,
  },
  {
    id: 'd0e4d920-331d-4dad-b03d-9fa8be4219e9',
    label: 'pomoc',
    icon: PanelIcon,
    url: URL.userGuide,
  },
];

export const EMPLOYEE_HOME_CARDS = [
  {
    id: '00822a85-b54b-462b-ae62-67e153ef1e24',
    label: `Witaj ${sessionStorage.getItem('firstName')} ${sessionStorage.getItem('lastName')}`,
    icon: LSILogo,
    url: URL.home,
  },
];

export const HOME_CARDS_CONFIG = {
  [PROFILES.operator]: OPERATOR_HOME_CARDS,
  [PROFILES.managementInstitution]: MANAGEMENT_INSTITUTION_HOME_CARDS,
  [PROFILES.applicant]: APPLICANT_HOME_CARDS,
  [PROFILES.expert]: EXPERT_HOME_CARDS,
  [PROFILES.employee]: EMPLOYEE_HOME_CARDS,
};
