import PropTypes from 'prop-types';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import {
  API_ROUTE,
  GROUPS,
} from '../../_constants';
import { request } from '../../_services';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';
import { CustomAutocomplete } from '../../Features/CustomAutocomplete/CustomAutocomplete';
import { handleError } from '../../_helpers';

/**
 * ModulesSelectionForm component.
 *
 * @param {object} props - root props
 * @param {object} props.initialValues - current data for edit
 * @param {Function} props.cancelButtonHandler - cancel button handler
 * @param {Function} props.onSuccess - function invoked on successfull response
 * @param {string} props.mode - form mode (edit|create)
 * @returns {ModulesSelectionForm} - modules selection edit/create form
 */
export function ModulesSelectionForm({
  initialValues,
  cancelButtonHandler,
  onSuccess,
  mode,
}) {
  const [modules, setModules] = useState([]);

  useEffect(() => {
    /**
     * Loads api data.
     */
    const loadApiData = async () => {
      const {
        payload,
        statusSuccess,
      } = await request.get(API_ROUTE.modules);

      if (statusSuccess) {
        setModules(payload);
      }
    };

    loadApiData();
  }, [mode]);

  const {
    control, handleSubmit, setError, formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      applicationTemplate: initialValues?.applicationTemplate || undefined,
      description: initialValues?.description || '',
      groups: initialValues?.groups || [],
      obligatoryModules: (initialValues?.obligatoryModules || []).map(({ '@id': id }) => id),
      obligatoryLabel: initialValues?.obligatoryLabel || '',
      obligatoryPrintable: initialValues?.obligatoryPrintable || false,
      facultativeModules: (initialValues?.facultativeModules || []).map(({ '@id': id }) => id),
      facultativeLabel: initialValues?.facultativeLabel || '',
      facultativePrintable: initialValues?.facultativePrintable || false,
    },
  });

  const submitHandler = async (payload) => {
    const apiUrl = mode === 'create' ? API_ROUTE.modulesSelections
      : `${API_ROUTE.modulesSelections}/${initialValues.id}`;
    const requestFunc = mode === 'create' ? request.post : request.put;

    const {
      statusSuccess, violations = [],
    } = await requestFunc(apiUrl, payload);

    if (!statusSuccess) {
      handleError(setError, violations);

      return;
    }

    cancelButtonHandler();
    onSuccess();
  };

  if (modules.length === 0) {
    return <ContainerLoader />;
  }

  return (
    <>
      <Grid container aria-colcount={2} spacing={2}>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="description">
            Instrukcja
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Instrukcja"',
                },
              }}
              name="description"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  <CKEditor
                    id={name}
                    editor={ClassicEditor}
                    config={{
                      language: 'pl',
                      link: {
                        addTargetToExternalLinks: true,
                      },
                      removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                    }}
                    data={value}
                    onChange={(_event, editor) => {
                      onChange({
                        target: {
                          name,
                          value: editor.getData(),
                        },
                      });
                    }}
                  />
                  {!!error && (
                    <FormHelperText error>{error?.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="groups">
            Kroki
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Kroki"',
                },
              }}
              name="groups"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  multiple
                  id={name}
                  options={GROUPS.map((group) => ({
                    '@id': group,
                    name: group,
                  }))}
                  initialValue={value}
                  onChange={onChange}
                  textFieldProps={{
                    error: !!error,
                    name,
                    required: true,
                  }}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <Divider />
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="obligatoryModules">
            Moduły obligatoryjne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="obligatoryModules"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  multiple
                  options={modules}
                  initialValue={value}
                  onChange={onChange}
                  textFieldProps={{
                    error: !!error,
                    name,
                  }}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="obligatoryLabel">
            Etykieta dla Moduły obligatoryjne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Etykieta dla pola Moduły obligatoryjne"',
                },
              }}
              name="obligatoryLabel"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="obligatoryPrintable">
            Wyświetlaj pole Moduły obligatoryjne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="obligatoryPrintable"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
              }) => (
                <Switch
                  id={name}
                  checked={value}
                  onChange={onChange}
                  color="secondary"
                  name={name}
                  inputProps={{
                    'aria-label': 'Wyświetlaj pole Moduły obligatoryjne',
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <Divider />
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="facultativeModules">
            Moduły fakultatywne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="facultativeModules"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  multiple
                  options={modules}
                  initialValue={value}
                  onChange={onChange}
                  textFieldProps={{
                    error: !!error,
                    name,
                  }}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="facultativeLabel">
            Etykieta dla Moduły fakultatywne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Etykieta dla pola Moduły fakultatywne"',
                },
              }}
              name="facultativeLabel"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center">
          <InputLabel htmlFor="facultativePrintable">
            Wyświetlaj pole Moduły fakultatywne
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="facultativePrintable"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                },
              }) => (
                <Switch
                  id={name}
                  checked={value}
                  onChange={onChange}
                  color="secondary"
                  name={name}
                  inputProps={{
                    'aria-label': 'Wyświetlaj pole Moduły fakultatywne',
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <SaveCancelButtons
        saveDisabled={isSubmitting}
        variant="center"
        cancelHandler={cancelButtonHandler}
        saveHandler={handleSubmit(submitHandler)}
        cancelButtonId="Y8M82NTyDAbqjgG"
        saveButtonId="tQQ4TB3veuCg3J6"
      />
    </>
  );
}

ModulesSelectionForm.propTypes = {
  cancelButtonHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  onSuccess: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']),
};

ModulesSelectionForm.defaultProps = {
  initialValues: null,
  mode: 'create',
};
