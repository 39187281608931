import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * BusinessModelDescription pdf element.
 *
 * @returns {BusinessModelDescriptionPdf}
 */
function BusinessModelDescriptionPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* a. Propozycja wartości */}
        <RowField name="business_model_description_value_proposition" isSingleRow />
        {/* b. Zidentyfikowany problem/problemy na które odpowiada produkt  */}
        <RowField name="business_model_description_product_addressed_problems" isSingleRow />
        {/* c. Zidentyfikowana potrzeba/potrzeby klientów na które odpowiada produkt */}
        <RowField name="business_model_description_product_addressed_customer_needs" isSingleRow />
        {/* d. Sparametryzowane przewagi konkurencyjne produktu w stosunku do istniejących na
        rynku rozwiązań konkurencyjnych */}
        <RowField name="business_model_description_product_competetive_advantages" isSingleRow />
        {/* e. Segment klientów */}
        <RowField name="business_model_description_customer_segment" isSingleRow />
        {/* f. Zidentyfikowana grupa/grupy odbiorców produktu */}
        <RowField name="business_model_description_product_audience_group" isSingleRow />
        {/* g. Rynek docelowy i zapotrzebowanie rynkowe na produkt  */}
        <RowField name="business_model_description_product_market_and_demand" isSingleRow />
        {/* Etykieta: h. Relacje z klientami */}
        <RowField name="business_model_description_customer_relations" isSingleRow />
        {/* i. Zidentyfikowane sposoby pozyskiwania i zatrzymywania klientów */}
        <RowField name="business_model_description_customer_attraction_and_retention" isSingleRow />
        {/* j. Zidentyfikowane kanały komunikacji */}
        <RowField name="business_model_description_identified_communication_channels" isSingleRow />
        {/* k. Zidentyfikowane kanały dystrybucji i sprzedaży */}
        <RowField name="business_model_description_identified_distribution_and_sales_channels" isSingleRow />
        {/* l. Kluczowe działania */}
        <RowField name="business_model_description_key_activities" isSingleRow />
        {/* m. Zidentyfikowane działania do podjęcia, aby oferować produkt dla klientów wybranymi kanałami,
         relacjami */}
        <RowField name="business_model_description_product_offer_actions_through_selected_channels" isSingleRow />
        {/* n. Zdiagnozowane czynniki strategiczne warunkujące prawidłową realizację projektu, wdrożenie produktu */}
        <RowField name="business_model_description_strategic_factors_for_proper_implementation" isSingleRow />
        {/* Etykieta: o. Strumienie przychodów */}
        <RowField name="business_model_description_revenue_streams" isSingleRow />
        {/* p. Zidentyfikowane źródła przychodów ze sprzedaży produktu */}
        <RowField name="business_model_description_revenue_sources_from_product_sales" isSingleRow />
        {/* r. Polityka cenowa produktu */}
        <RowField name="business_model_description_product_pricing_policy" isSingleRow />
        {/* s. Uzasadnienie dla szacowanej wysokości przychodów */}
        <RowField name="business_model_description_estimated_revenue_justification" isSingleRow />
        {/* Etykieta: t. Struktura kosztów */}
        <RowField name="business_model_description_costs_structure" isSingleRow />
        {/* u. Nakłady finansowe potrzebne do realizacji przedsięwzięcia */}
        <RowField name="business_model_description_project_financial_outlay" isSingleRow />
        {/* Etykieta: Kanały */}
        <RowField name="business_model_description_channels" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default BusinessModelDescriptionPdf;
