export const EXPERT_GROUP_NAMES = {
  ogolna: 'Ogólna',
  ogolnaFinansowa: 'Ogólna-finansowa',
  ogolnaModulowa: 'Ogólna-modułowa',
  modulowa: 'Modułowa',
  modulowaFinansowa: 'Modułowa-finansowa',
};

export const EXPERT_GROUPS = [
  EXPERT_GROUP_NAMES.ogolna,
  EXPERT_GROUP_NAMES.ogolnaFinansowa,
  EXPERT_GROUP_NAMES.ogolnaModulowa,
  EXPERT_GROUP_NAMES.modulowa,
  EXPERT_GROUP_NAMES.modulowaFinansowa,
];
