import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import {
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../Context';
import { languageSkillsFormValidation } from './LanguageSkills.form.validation';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { handleError } from '../../../_helpers';

/**
 * Personal data form for expert
 *
 * @returns {LanguageSkillsForm}
 */
export function LanguageSkillsForm() {
  const navigate = useNavigate();
  const {
    knownLanguages, id, citizenship, reloadData, closeHandler, isReadonly,
  } = useExpertPersonalDataContext();
  const { notify } = useGlobalContext();

  const {
    control, handleSubmit, setError,
  } = useForm({
    defaultValues: {
      english: knownLanguages?.english.toString(),
      german: knownLanguages?.german.toString(),
      french: knownLanguages?.french.toString(),
      polish: knownLanguages?.polish.toString(),
    },
    mode: 'all',
    shouldUnregister: true,
  });

  /**
   * Handle form submit request.
   *
   * @param {object} formData - form data to send
   */
  const submitForm = async (formData) => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.expertPersonalData}/${id}`, {
      knownLanguages: {
        english: formData.english === 'true',
        german: formData.german === 'true',
        french: formData.french === 'true',
        polish: formData.polish === 'true',
      },
    });

    if (statusSuccess) {
      notify('Znajomość języków została zaktualizowana.', 'success');
      reloadData();
      window.scrollTo(0, 0);

      return;
    }

    handleError(setError, violations);

    notify('Wystąpił błąd podczas zapisu znajomości języków.', 'error');
    window.scrollTo(0, 0);
  };

  return (
    <Grid
      container
      aria-colcount={2}
      spacing={2}
      id="IarzPOVbegTjrDK"
      my={2}
    >
      <Grid
        item
        xs={12}
        md={3}
        display="flex"
        justifyContent="left"
        alignItems="center"
      >
        <InputLabel htmlFor="languageSkillsEnglish" sx={{ fontSize: '1rem' }}>
          Angielski
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            rules={languageSkillsFormValidation.english}
            name="english"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  row
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        display="flex"
        justifyContent="left"
        alignItems="center"
      >
        <InputLabel htmlFor="languageSkillsGerman" sx={{ fontSize: '1rem' }}>
          Niemiecki
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            rules={languageSkillsFormValidation.german}
            name="german"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  row
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        display="flex"
        justifyContent="left"
        alignItems="center"
      >
        <InputLabel htmlFor="languageSkillsFrench" sx={{ fontSize: '1rem' }}>
          Francuski
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            rules={languageSkillsFormValidation.french}
            name="french"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  row
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      {citizenship !== 'PL' && (
        <>
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent="left"
            alignItems="center"
          >
            <InputLabel htmlFor="languageSkillsPolish" sx={{ fontSize: '1rem' }}>
              Polski
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                rules={{
                  required: citizenship !== 'PL' ? languageSkillsFormValidation.polish.required : {},
                }}
                name="polish"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <>
                    <RadioGroup
                      id={name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      row
                    >
                      <FormControlLabel
                        disabled={isReadonly}
                        value="true"
                        control={<Radio color="secondary" />}
                        label="Tak"
                      />
                      <FormControlLabel
                        disabled={isReadonly}
                        value="false"
                        control={<Radio color="secondary" />}
                        label="Nie"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                  </>
                )}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} mt={4}>
        {!isReadonly && (
          <SaveCancelButtons
            saveButtonId="4wFzJd3cqe0546l"
            cancelButtonId="ue5N8LDjebHJJ6q"
            cancelHandler={closeHandler ? () => closeHandler() : () => { navigate('/'); }}
            saveHandler={handleSubmit(submitForm)}
          />
        )}
      </Grid>
    </Grid>
  );
}
