import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import RdModuleEssenceNoveltyOfResultsPdf from './EssenceOfModule/RdModuleEssenceNoveltyOfResults.pdf';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * RdModuleEssence Pdf element.
 *
 * @returns {RdModuleEssencePdf}
 */
function RdModuleEssencePdf() {
  return (

    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="rd_module_essence_work_type" dictionaryName={DICTIONARIES.workTypes.name} />
        <RowField name="rd_module_essence_module_purpose" isSingleRow />
        <RowField name="rd_module_essence_rd_issue" isSingleRow />
        {/* @TODO - need to check how attachments are returned  */}
        <RowField name="rd_module_essence_rd_issue_attachments" />
        <RowField name="rd_module_essence_reaserch_method" isSingleRow />
        {/* @TODO - need to check how attachments are returned  */}
        <RowField name="rd_module_essence_reaserch_method_attachments" />
        <RowField name="rd_module_essence_innovation_type" dictionaryName={DICTIONARIES.innovationTypes.name} />

        <CollectionWrapper name="rd_module_essence_novelty_of_results">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <RdModuleEssenceNoveltyOfResultsPdf
                previousRowData={previousRowData}
                collectionName={collectionName}
                item={item}
                index={index}
                collectionData={collectionData}
              />
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default RdModuleEssencePdf;
