import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { Alert } from '../../Alert';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';

/**
 * ClusterData element renderer.
 *
 * @returns {ClusterData}
 */
export function ClusterData() {
  const {
    setValue, getValues,
  } = useFormContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.clusterTypes
  );

  const countPercentageSmeClusterMembers = () => {
    const clusterMembers = getValues('cluster_data_members_number');
    const enterprisesNumber = getValues('cluster_data_enterprises_number_with_sme_status');
    if (clusterMembers && enterprisesNumber > 0) {
      setValue(
        'cluster_data_cluster_members_percentage_with_sme_status',
        enterprisesNumber / clusterMembers * 100
      );

      return;
    }
    setValue(
      'cluster_data_cluster_members_percentage_with_sme_status',
      ''
    );
  };
  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Nazwa klastra */}
      <FormField name="cluster_data_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Rodzaj klastra */}
      <FormField name="cluster_data_type">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.clusterTypes.name))}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Adres strony www klastra */}
      <FormField name="cluster_data_www">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Liczba członków klastra ogółem */}
      <FormField name="cluster_data_members_number">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <NumericFormat
            id={name}
            name={name}
            onValueChange={(values) => onChange({
              target: {
                name,
                value: values.floatValue,
              },
            })}
            onBlur={() => {
              onBlur();
              countPercentageSmeClusterMembers();
            }}
            defaultValue={value}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
          />
        )}
      </FormField>
      {/* Liczba przedsiębiorstw wchodzących w skład klastra */}
      <FormField name="cluster_data_enterprises_number">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <NumericFormat
            id={name}
            name={name}
            onValueChange={(values) => onChange({
              target: {
                name,
                value: values.floatValue,
              },
            })}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
          />
        )}
      </FormField>
      {/* Liczba przedsiębiorstw posiadających status MŚP wchodzących w skład klastra */}
      <FormField name="cluster_data_enterprises_number_with_sme_status">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <NumericFormat
            id={name}
            name={name}
            onValueChange={(values) => onChange({
              target: {
                name,
                value: values.floatValue,
              },
            })}
            onBlur={() => {
              onBlur();
              countPercentageSmeClusterMembers();
            }}
            defaultValue={value}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
          />
        )}
      </FormField>
      {/* Odsetek członków klastra posiadających status MŚP w ogólnej liczbie członków klastra */}
      <FormField name="cluster_data_cluster_members_percentage_with_sme_status">
        {({ value }) => (
          <Alert severity="status">
            {value}
          </Alert>
        )}
      </FormField>
    </FieldSorter>
  );
}
