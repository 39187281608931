import {
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import { plPL as plDataGrid } from '@mui/x-data-grid';
import { plPL as muiPlLocale } from '@mui/material/locale';
import { plPL as plPickers } from '@mui/x-date-pickers/locales';
import { renderTimeViewClock } from '@mui/x-date-pickers';

const createdTheme = createTheme(
  {
    palette: {
      common: {
        black: '#0B0D0E',
        white: '#fff',
      },
      primary: {
        main: '#b61928',
      },
      secondary: {
        main: '#00749a',
      },
      background: {
        default: '#ffffff',
      },
      add: {
        main: '#00803C',
        contrastText: '#ffffff',
        dark: '#015426',
      },
      cancel: {
        main: '#ffffff',
        contrastText: '#b61928',
      },
      save: {
        main: '#00749a',
        contrastText: '#ffffff',
      },
      delete: {
        main: '#b61928',
        contrastText: '#ffffff',
      },
      publish: {
        main: '#626769',
        contrastText: '#ffffff',
      },
      edit: {
        main: '#094693',
        contrastText: '#ffffff',
      },
      brandGray: {
        main: '#626769',
        contrastText: '#ffffff',
      },
      brandGray80: {
        main: '#84898c',
        contrastText: '#ffffff',
      },
      brandGray60: {
        main: '#a4a8ab',
        contrastText: '#ffffff',
      },
      brandGray40: {
        main: '#c2c6c8',
        contrastText: '#ffffff',
      },
      brandGray20: {
        main: '#e1e3e4',
        contrastText: '#000000',
      },
      brandGray10: {
        main: '#f3f3f3',
        contrastText: '#000000',
      },
      text: {
        main: '#000000de',
        brand: '#484c4e',
      },
      alert: {
        error: {
          main: '#B61928',
        },
        info: {
          main: '#0C7492',
        },
        success: {
          main: '#109C39',
          dark: '#0A802D',
        },
        warn: {
          main: '#E27A05',
        },
      },
      success: {
        50: '#E9FBF0',
        100: '#C6F6D9',
        200: '#9AEFBC',
        300: '#6AE79C',
        400: '#3EE07F',
        500: '#21CC66',
        600: '#1DB45A',
        700: '#1AA251',
        800: '#178D46',
        900: '#0F5C2E',
        main: '#1AA251',
        light: '#6AE79C',
        dark: '#1AA251',
        contrastText: '#fff',
      },
      error: {
        50: '#FFF0F1',
        100: '#FFDBDE',
        200: '#FFBDC2',
        300: '#FF99A2',
        400: '#FF7A86',
        500: '#FF505F',
        600: '#EB0014',
        700: '#C70011',
        800: '#94000D',
        900: '#570007',
        main: '#EB0014',
        light: '#FF99A2',
        dark: '#C70011',
        contrastText: '#fff',
      },
    },
    typography: {
      baseHeading: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '2.5rem',
        letterSpacing: '1.5px !important',
        marginTop: '8px',
        marginBottom: '24px',
        fontFamily: [
          'NovelPro-Regular',
          'serif',
        ].join(','),
      },
    },
  },
  muiPlLocale,
  plPickers,
  plDataGrid,
);

const stylesTheme = responsiveFontSizes(createdTheme);

export const theme = {
  ...stylesTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Montserrat-Light, sans-serif',
          letterSpacing: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#fff',
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
        input: {
          background: '#fff',
          '&.Mui-disabled': {
            color: '#757575',
            WebkitTextFillColor: '#757575',
          },
        },
        inputMultiline: {
          padding: '0 !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
          '& input:valid + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& input:valid + div + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& select:valid + svg + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& input:valid + svg + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& textarea:valid + textarea + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& textarea:valid + textarea + div + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& input:valid + div + div + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
          '& input.MuiAutocomplete-input[aria-invalid=false] + div + fieldset': {
            borderColor: stylesTheme.palette.secondary.main,
          },
        },
        input: {
          padding: '0.95em 1em',
          borderRadius: 0,
        },
        notchedOutline: {
          paddingRight: '0',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            padding: stylesTheme.spacing(2),
            height: 'unset !important',
            ...((ownerState.color === 'primary' || ownerState.color === 'secondary') && {
              '& .MuiDialogTitle-root': {
                backgroundColor: stylesTheme.palette[ownerState.color].main,
                color: stylesTheme.palette.brandGray10.main,
                marginBottom: stylesTheme.spacing(2),
                fontFamily: [
                  'Montserrat-Light',
                  'sans-serif',
                ].join(','),
                '& .MuiTypography-dialogHeading': {
                  margin: 0,
                  fontSize: '1rem',
                  letterSpacing: '0 !important',
                  fontWeight: 500,
                  lineHeight: 1.6,
                  fontFamily: [
                    'Montserrat-Light',
                    'sans-serif',
                  ].join(','),
                },
              },
              '& .MuiDialogContent-root': {
                border: `1px solid ${stylesTheme.palette[ownerState.color].main}`,
                borderWidth: '1px',
                padding: `${stylesTheme.spacing(6, 3)} !important`,
                '& + .MuiDialogActions-root': {
                  marginTop: '-1px',
                  backgroundColor: stylesTheme.palette.background.default,
                  zIndex: 0,
                },
              },
              '& .MuiDialogActions-root': {
                border: `1px solid ${stylesTheme.palette[ownerState.color].main}`,
                borderWidth: '0px 1px 1px 1px',
                borderTop: 'none',
                padding: stylesTheme.spacing(0, 3, 6),
                justifyContent: 'center',
              },
            }),
          }),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
          '&.Mui-disabled': {
            color: '#757575',
            WebkitTextFillColor: '#757575',
          },
        },
        formControl: {
          top: 'inherit',
          left: 'inherit',
        },
        shrink: {
          backgroundColor: '#FFFFFF',
          padding: '0 0.5em',
          borderRadius: '3px',
        },
        noShorten: {
          textOverflow: 'unset',
          whiteSpace: 'unset',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: '2px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        selectMenu: {
          display: 'flex',
          flexWrap: 'wrap',
          whiteSpace: 'inherit',
        },
        outlined: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: { variant: 'left-border' },
          style: {
            borderLeft: '4px solid transparent',
            margin: stylesTheme.spacing(0.5),
            ':hover': {
              borderLeft: `4px solid ${stylesTheme.palette.secondary.main}`,
            },
          },
        },
        {
          props: { variant: 'left-border-primary' },
          style: {
            borderLeft: '4px solid transparent',
            margin: stylesTheme.spacing(0.5),
            ':hover': {
              borderLeft: `4px solid ${stylesTheme.palette.primary.main}`,
            },
          },
        },
        {
          props: { variant: 'right-border' },
          style: {
            borderRight: '4px solid transparent',
            margin: stylesTheme.spacing(0.5),
            ':hover': {
              borderRight: `4px solid ${stylesTheme.palette.secondary.main}`,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        ...plDataGrid.components.MuiDataGrid.defaultProps,
      },
      styleOverrides: {
        virtualScrollerContent: {
          height: 'unset !important',
          minHeight: '1.2em !important',
        },
        virtualScrollerRenderZone: {
          position: 'unset',
        },
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
          '& .MuiDataGrid-iconSeparator': { color: 'transparent' },
          '& .MuiDataGrid-columnHeader:focus': { outline: 'solid transparent 1px' },
          '& .MuiDataGrid-columnHeader:focus-within': { outline: 'solid transparent 1px' },
          '& .MuiDataGrid-cell:focus': { outline: 'solid transparent 1px' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'solid transparent 1px' },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+1)': {
            borderBottom: '4px solid #d66011',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+2)': {
            borderBottom: '4px solid #b61928',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+3)': {
            borderBottom: '4px solid #438da9',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+4)': {
            borderBottom: '4px solid #584991',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+5)': {
            borderBottom: '4px solid #f7a500',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+6)': { // should works, but...
            borderBottom: '4px solid #109c39',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-columnHeader:nth-of-type(7n+7)': {
            borderBottom: '4px solid #a6c4d6',
            backgroundColor: '#e1e3e4',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: 'transparent', // disable data grid rows highlighting
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'transparent !important', // disable data grid rows highlighting
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent !important', // disable data grid rows highlighting
          },
          '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
          },
          '& .MuiDataGrid-cell': {
            textAlign: 'left',
            maxHeight: 'inherit !important',
            wordBreak: 'break-all',
            whiteSpace: 'initial !important',
            lineHeight: '1.05em !important',
            display: 'flex !important',
            alignItems: 'center',
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
          },
          '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
          },
          /**
           * allow long table headers to wrap
           *
           * @see https://github.com/mui/mui-x/issues/417#issuecomment-998945392
           */
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: 'ellipsis',
            wordBreak: 'normal',
            whiteSpace: 'break-spaces',
            lineHeight: 1,
            fontWeight: 700,
            fontSize: '1rem',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            minHeight: '54px',
            padding: 0,
          },
          '& .MuiDataGrid-cellContent': {
            overflow: 'unset',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        letterSpacing: 0,
        variantMapping: {
          pageHeading: 'h2',
          dialogHeading: 'h1', // h1 because dialog it's overlay
          formHeading: 'h2',
        },
      },
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
        fontFamily: [
          'Montserrat-Light',
          'sans-serif',
        ].join(','),
        h1: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '2rem !important',
        },
        h2: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '1.75rem !important',
        },
        h3: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '1.5rem !important',
        },
        h4: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '1.25rem !important',
        },
        h5: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '1rem !important',
        },
        h6: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
          fontSize: '1rem !important',
        },
        subtitle1: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
        },
        subtitle2: {
          fontFamily: [
            'NovelPro-Regular',
            'serif',
          ].join(','),
        },
        dialogHeading: stylesTheme.typography.baseHeading,
        pageHeading: stylesTheme.typography.baseHeading,
        formHeading: stylesTheme.typography.baseHeading,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiChip-root': {
            background: '#FFFFFF',
            border: '1px solid #bdbdbd',
            borderRadius: 0,
          },
          '& .Mui-disabled fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.26) !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: [
            'Montserrat-Light',
            'sans-serif',
          ].join(','),
        },
      },
    },
    MuiDesktopTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDesktopDateTimePicker: {
      defaultProps: {
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
};
