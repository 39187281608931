import { TitledContainer } from '../../TitledContainer';
import { IndustryExpertList } from './IndustryExpert.list';

/**
 * Justification of experience of the industry expert candidate.
 *
 * @returns {IndustryExpert}
 */
export function IndustryExpert() {
  return (
    <TitledContainer
      title="Uzasadnienie wiedzy i umiejętności oraz doświadczenia kandydata na eksperta"
      key="industry-expert"
      variant="slim"
      textAlign="left"
    >
      <IndustryExpertList />
    </TitledContainer>
  );
}
