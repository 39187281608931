import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';

/**
 * Human Resources Internationalization Pdf element.
 *
 * @returns {HumanResourcesInternationalizationPdf}
 */
function HumanResourcesInternationalizationPdf() {
  return (
    <View style={printStyle.table}>
      <RowField name="human_resources_internationalization_description" />
    </View>
  );
}

export default HumanResourcesInternationalizationPdf;
