import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Research Agenda Rd Infrastructure Pdf element.
 *
 * @returns {ResearchAgendaRdInfrastructurePdf}
 */
function ResearchAgendaRdInfrastructurePdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="implementation_start_date" />
        <RowField name="implementation_end_date" />
        <CollectionWrapper name="main_innovative_research_solutions">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.area_name`}
                      value={item.area_name}
                      oldValue={previousRowData?.area_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.area_description`}
                      value={item.area_description}
                      oldValue={previousRowData?.area_description}
                    />
                    <RowField
                      name={`${collectionName}.${index}.rd_work_plan`}
                      value={item.rd_work_plan}
                      oldValue={previousRowData?.rd_work_plan}
                    />
                    <RowField
                      name={`${collectionName}.${index}.innovation_resulting_from_implementation_of_research_area_type`}
                      value={item.innovation_resulting_from_implementation_of_research_area_type}
                      oldValue={previousRowData?.innovation_resulting_from_implementation_of_research_area_type}
                      dictionaryName={DICTIONARIES.innovationResultingFromImplementationOfResearchAreaTypes.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.novelty_of_results_of_research_area`}
                      value={item.novelty_of_results_of_research_area}
                      oldValue={previousRowData?.novelty_of_results_of_research_area}
                      dictionaryName={DICTIONARIES.noveltyOfResultsOfResearchAreas.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.product_innovation_description`}
                      value={item.product_innovation_description}
                      oldValue={previousRowData?.product_innovation_description}
                    />
                    <RowField
                      name={`${collectionName}.${index}.graphic_attachment`}
                      value={item.graphic_attachment}
                      oldValue={previousRowData?.graphic_attachment}
                      dictionaryName="graphic_attachment"
                    />
                    <RowField
                      name={`${collectionName}.${index}.market_demand_analysis`}
                      value={item.market_demand_analysis}
                      oldValue={previousRowData?.market_demand_analysis}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        <RowField name="business_development_strategy_assumptions" />
        <RowField name="rd_works_carried_scope" />
        <RowField name="intellectual_property_rights" />
      </FieldSorter>
    </View>
  );
}

export default ResearchAgendaRdInfrastructurePdf;
