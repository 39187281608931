import { PROTEST_REVIEWS_STATUSES } from './statuses/protestReviewsStatuses';

export const BUTTON_APPEAR = {
  printPdf: [PROTEST_REVIEWS_STATUSES.sentForReview, PROTEST_REVIEWS_STATUSES.inReview],
  applicationPreview: [PROTEST_REVIEWS_STATUSES.sentForReview, PROTEST_REVIEWS_STATUSES.inReview],
  protestPreview: [PROTEST_REVIEWS_STATUSES.sentForReview, PROTEST_REVIEWS_STATUSES.inReview],
  acceptForFeedback: [PROTEST_REVIEWS_STATUSES.sentForReview],
  rejectReview: [PROTEST_REVIEWS_STATUSES.sentForReview],
  statement: [PROTEST_REVIEWS_STATUSES.inReview, PROTEST_REVIEWS_STATUSES.sentToParp],
  sheetPreview: [PROTEST_REVIEWS_STATUSES.inReview, PROTEST_REVIEWS_STATUSES.sentToParp],
  sheet: [PROTEST_REVIEWS_STATUSES.inReview],
  printoutReviewSheet: [PROTEST_REVIEWS_STATUSES.sentToParp],
};
