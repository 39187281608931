import {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import { format } from 'date-fns';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import {
  useExpertApplicationFormContext,
  useGlobalDialog,
} from '../../../Context';
import { theme } from '../../../styles/theme';
import { OtherDomainJustificationForm } from './OtherDomainJustification.form';
import { KisDomainJustificationForm } from './KisDomainJustification.form';

/**
 * Domains list of industry expert candidate.
 *
 * @returns {IndustryExpertList}
 */
export function IndustryExpertList() {
  const [kisDomains, setKisDomains] = useState([]);
  const [kisDomainAreas, setKisDomainAreas] = useState([]);
  const [otherDomains, setOtherDomains] = useState([]);
  const [oecdScienceDomains, setOecdScienceDomains] = useState([]);
  const [naceScienceDomains, setNaceScienceDomains] = useState([]);
  const {
    applicationFormData, isReadonly, attachmentsData,
  } = useExpertApplicationFormContext();
  const { render } = useGlobalDialog();

  useEffect(() => {
    /**
     * Loads kis domains from API.
     */
    const loadKisDomains = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(
        `${API_ROUTE.kisDomains}?id[]=${applicationFormData.kisDomains.join('&id[]=')}`
      );

      if (statusSuccess) {
        setKisDomains(payload);
      }
    };

    /**
     * Loads kis domain areas from API.
     */
    const loadKisDomainAreas = async () => {
      const today = format(new Date(), 'yyyy-MM-dd');
      let route = API_ROUTE.kisDomainAreas;
      switch (applicationFormData.status) {
      case 'editing':
        route = `${API_ROUTE.kisDomainAreas}?availableFrom[before]=${today}&availableTo[after]=${today}`;
        break;
      case 'correction':
        route = `${API_ROUTE.kisDomainAreas}?availableFrom[before]=${applicationFormData.submittedAt.substring(0, 10)}&availableTo[after]=${applicationFormData.submittedAt.substring(0, 10)}`;
        break;
      default:
        route = API_ROUTE.kisDomainAreas;
      }
      const {
        statusSuccess, payload,
      } = await request.get(route);

      if (statusSuccess) {
        setKisDomainAreas(payload);
      }
    };

    /**
     * Loads other domains from API.
     */
    const loadOtherDomains = async () => {
      if (applicationFormData?.otherDomains?.length > 0) {
        const {
          statusSuccess, payload,
        } = await request.get(
          `${API_ROUTE.otherDomains}?id[]=${applicationFormData.otherDomains.join('&id[]=')}`
        );

        if (statusSuccess) {
          setOtherDomains(payload);
        }
      }
    };

    /**
     * Loads oecd science domains from API.
     */
    const loadOecdScienceDomains = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.oecdScienceDomains}?order[subcategoryCode]=asc`);

      if (statusSuccess) {
        setOecdScienceDomains(payload);
      }
    };

    /**
     * Loads nace science domains from API.
     */
    const loadNaceScienceDomains = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.naceScienceDomains}?order[subsectionCode]=asc`);

      if (statusSuccess) {
        setNaceScienceDomains(payload);
      }
    };

    loadKisDomains();
    loadKisDomainAreas();
    loadOtherDomains();
    loadOecdScienceDomains();
    loadNaceScienceDomains();
  }, [applicationFormData]);

  return (
    <Grid container spacing={3} pt={4}>
      {kisDomains.length > 0 && (
        <Grid item xs={12}>
          <Typography backgroundColor={theme.palette.brandGray20.main}>
            Podstawowe dziedziny
          </Typography>
        </Grid>
      )}
      {kisDomains?.map((item) => (
        <Fragment key={item.id}>
          <Grid item xs={12} md={9}>
            <Typography color={theme.palette.brandGray80.main}>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="end">
            <Button
              id={uniqid()}
              variant="contained"
              color="secondary"
              onClick={() => {
                render(
                  <KisDomainJustificationForm
                    kisDomainAreas={kisDomainAreas.filter(({ kisDomain }) => kisDomain?.['@id'] === item?.['@id'])}
                    oecdScienceDomains={oecdScienceDomains}
                    naceScienceDomains={naceScienceDomains}
                    kisDomain={item}
                    applicationFormId={applicationFormData.id}
                    isReadonly={isReadonly}
                    attachmentsData={attachmentsData}
                  />,
                  'Uzasadnienie spełnienia kryteriów w odniesieniu do wskazanej dziedziny celem potwierdzenia '
                  + 'kompetencji eksperta do oceny w poszczególnych szczegółowych obszarach',
                );
              }}
            >
              Uzasadnij
            </Button>
          </Grid>
        </Fragment>
      ))}
      {otherDomains.length > 0 && (
        <Grid item xs={12}>
          <Typography backgroundColor={theme.palette.brandGray20.main}>
            Pozostałe dziedziny
          </Typography>
        </Grid>
      )}
      {otherDomains?.map((item) => (
        <Fragment key={item.id}>
          <Grid item xs={12} md={9}>
            <Typography color={theme.palette.brandGray80.main}>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="end">
            <Button
              id={uniqid()}
              variant="contained"
              color="secondary"
              onClick={() => {
                render(
                  <OtherDomainJustificationForm
                    applicationFormId={applicationFormData.id}
                    isReadonly={isReadonly}
                    otherDomain={item}
                    attachmentsData={attachmentsData}
                  />,
                  'Uzasadnienie spełnienia kryteriów w odniesieniu do wskazanej dziedziny '
                  + 'celem potwierdzenia kompetencji eksperta do oceny w poszczególnych szczegółowych obszarach'
                );
              }}
            >
              Uzasadnij
            </Button>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
