import {
  Route,
  Routes,
} from 'react-router-dom';
import {
  Grid,
  Fade,
} from '@mui/material';
import {
  NecessaryLinks,
  HelpBar,
} from '../Features';
import { Register } from '../Features/Register';
import {
  LoginMethod,
  AccountReminder,
  PasswordReminder,
} from '../Features/Login';
import { LoginByEmail } from '../Features/Login/LoginByEmail.form';
import NewsList from '../Features/News/NewsList/News.list';

/**
 * Login page component.
 *
 * @returns {Login}
 */
export function Login() {
  return (
    <Fade timeout={600} in>
      <div>
        <Grid container justifyContent="center" spacing={3} pt={3}>
          <Grid item xs={12} md={7} lg={6} xl={5}>
            <Routes>
              <Route
                key="login-method"
                path="/"
                element={(
                  <LoginMethod />
                )}
              />
              <Route
                key="login-by-email"
                path="login-email"
                element={(
                  <LoginByEmail />
                )}
              />
              <Route
                key="register"
                path="register"
                element={(
                  <Register />
                )}
              />
              <Route
                key="reminder-account"
                path="reminder-account"
                element={(
                  <AccountReminder />
                )}
              />
              <Route
                key="reminder-password"
                path="reminder-password"
                element={(
                  <PasswordReminder />
                )}
              />
            </Routes>
          </Grid>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <NewsList />
          </Grid>
        </Grid>
        <HelpBar />
        <NecessaryLinks />
      </div>
    </Fade>
  );
}
