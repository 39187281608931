import FieldWrapper from '../FieldWrapper';

/**
 * inEasternPoland validation component
 *
 * @returns {inEasternPoland}
 */
export default function inEasternPoland() {
  return (
    <FieldWrapper name="district_in_eastern_poland.value" label="Polska wchodnia" />
  );
}
