// eslint-disable-next-line import/no-cycle
import { request } from '../_services';

/**
 * Handle dragging action.
 *
 * @param {object} event - draggable event
 * @param {Array} items - array of items that can be dragged
 * @param {Function} setItems - items setter
 * @param {string} reorderUrl - API URL of reorder action
 */
export function handleDrag(
  event,
  items,
  setItems,
  reorderUrl
) {
  const {
    destination,
    source,
  } = event;
  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId
    && destination.index === source.index
  ) {
    return;
  }

  const newItems = reorder(
    items,
    event.source.index,
    event.destination.index
  );

  setItems(newItems);
  const json = newItems.map((item) => (item.id));
  request.post(reorderUrl, json);
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return Array.from(result);
};
