/**
 * Protest reviews statuses.
 *
 * @type {object}
 */
export const PROTEST_REVIEWS_STATUSES = {
  sentForReview: 'sent_for_review',
  inReview: 'in_review',
  rejected: 'rejected',
  sentToParp: 'sent_to_parp',
};

/**
 * Protest reviews statuses translations.
 *
 * @type {object}
 */
export const PROTEST_REVIEWS_STATUSES_TRANSLATIONS = {
  [PROTEST_REVIEWS_STATUSES.sentForReview]: 'Przekazane do opinii',
  [PROTEST_REVIEWS_STATUSES.inReview]: 'W opinii',
  [PROTEST_REVIEWS_STATUSES.rejected]: 'Odrzucona',
  [PROTEST_REVIEWS_STATUSES.sentToParp]: 'Przesłana do PARP',
};
