import { darken } from '@mui/material/styles';
import { theme } from '../../styles/theme';

export const styleClassesLoginMethod = {
  loginMethodHeader: {
    color: darken(theme.palette.primary.main, 0.25),
  },
  loginBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '350px',
    fontSize: '1.3em',
    borderWidth: '3px',
  },
  loginGovBtn: {
    width: '350px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: '#fff',
    borderWidth: '3px',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
  loginMethodPaper: {
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(20),
  },
  chooseLoginMethod: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontFamily: 'NovelPro-Regular',
    fontSize: '1.5rem',
  },
  loginWithPassBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '350px',
    fontSize: '1.5em',
    textTransform: 'inherit',
    color: theme.palette.text.main,
    borderWidth: '2px',
    lineHeight: '1em',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
  jumboBtn: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    height: '80px',
  },
  linkWithBtn: {
    display: 'block',
    textDecoration: 'none !important',
  },
};
