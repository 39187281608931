import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  FormControl,
  Grid,
} from '@mui/material';

import PropTypes from 'prop-types';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { request } from '../../../_services';
import {
  API_ROUTE,
  GROUP_NAMES,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useReloadListener } from '../../../Context';
import { theme } from '../../../styles/theme';

/**
 * Add element component.
 *
 * @param {object} props - root props
 * @param {string} props.templateId - application template ID
 * @param {Function} props.selectedElementHandler - created template ID will be passed to this function
 * @returns {AddElement}
 */
export function AddElement({
  templateId, selectedElementHandler,
}) {
  const [templateElementDefinitions, setTemplateElementDefinitions] = useState([]);
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'application-template-edit';

  const {
    control, handleSubmit, setError, setValue,
  } = useForm({
    defaultValues: {
      templateElementName: null,
    },
    mode: 'all',
  });

  useEffect(() => {
    /**
     * Loads API data.
     */
    const loadTemplateElementDefinitions = async () => {
      const { payload } = await request.get(API_ROUTE.templateElementDefinitions);

      setTemplateElementDefinitions(payload.sort((a, b) => a.title.localeCompare(b.title)));
    };

    loadTemplateElementDefinitions();
  }, []);

  /**
   * Handle submit.
   *
   * @param {object} values - payload form data
   */
  const handleAddElement = async (values) => {
    const {
      payload,
      violations,
    } = await request.post(
      API_ROUTE.addTemplateElement.replace(':templateId', templateId)
        .replace(':elementName', values.templateElementName),
      {
        groups: [GROUP_NAMES.main],
      }
    );

    if (violations) {
      violations.forEach(({
        _, message,
      }) => {
        setError('templateElementName', {
          type: 'manual',
          message,
        });
      });

      return;
    }

    reload(watcherName);
    selectedElementHandler(payload);
    setValue('templateElementName', null);
  };

  return (
    <form>
      <Grid container>
        <Grid item xs={10}>
          <FormControl
            variant="outlined"
            sx={{ paddingLeft: theme.spacing(2) }}
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Musisz wybrać element do dodania.',
                },
              }}
              name="templateElementName"
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                  name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  options={templateElementDefinitions}
                  optionsMapKeys={['name', 'title']}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  textFieldProps={{
                    error: !!error,
                    name,
                    label: 'Wybierz element',
                  }}
                  renderSelectAllButton={false}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            id="2r4Eb3m8i6k158b"
            sx={{
              backgroundColor: theme.palette.add.main,
              color: theme.palette.add.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.add.dark,
              },
              marginTop: theme.spacing(0.9),
              display: 'flex',
              marginLeft: theme.spacing(2),
            }}
            variant="contained"
            color="primary"
            onClick={handleSubmit(handleAddElement)}
          >
            Dodaj
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

AddElement.propTypes = {
  templateId: PropTypes.string.isRequired,
  selectedElementHandler: PropTypes.func.isRequired,
};
