import {
  Stack,
  Button,
  Box,
} from '@mui/material';
import uniqid from 'uniqid';
import {
  generatePath,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import {
  isBefore,
  parseISO,
  differenceInDays,
} from 'date-fns';
import { InfoOutlined } from '@mui/icons-material';
import {
  API_ROUTE,
  SUPPLEMENT_CALL_STATUSES,
  SUPPLEMENT_CALL_STATUSES_TRANSLATIONS,
  URL,
} from '../../../../_constants';
import { CustomDataGrid } from '../../../DataGrid/CustomDataGrid';
import { formatDateString } from '../../../../_helpers';
import ExpandableUuid from '../../../ExpandableUuid';
import PrintoutSupplementCall from '../../../Applicant/SupplementCall/PrintoutSupplementCall/PrintoutSupplementCall';

const conditionallyStyledDate = (value, daysToFinalDate) => (
  <>
    <p>{formatDateString(value)}</p>
    {daysToFinalDate < 3 && (
      <InfoOutlined sx={{
        color: (theme) => theme.palette.primary.main,
        ml: 'auto',
      }}
      />
    )}
  </>
);

/**
 * ApplicationCorrectionList component.
 *
 * @returns {ApplicationCorrectionList}
 */
export function ApplicationCorrectionList() {
  const { id: applicationId } = useParams();
  const { state: {
    applicationNumber, recruitmentId,
  } } = useLocation();

  const navigate = useNavigate();

  /**
   * Returns row actions.
   *
   * @param {object} row - table record data
   * @returns {Element[]}
   */
  const getRowActions = (row) => [
    <Stack
      direction="column"
      spacing={0.5}
      sx={{
        mt: (theme) => theme.spacing(1),
        mb: (theme) => theme.spacing(1),
      }}
    >
      {(isBefore(new Date(), parseISO(row.finalDate)) && row.status === SUPPLEMENT_CALL_STATUSES.sentToApplicant)
        && (
          <Box mb={1} width="100%">
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              id={uniqid()}
              fullWidth
              onClick={() => navigate(generatePath(
                URL.application.supplementCall,
                { id: row.id }
              ), { state: {
                isReadonly: false,
                recruitmentId,
                sentToApplicantDate: row.sentToApplicantDate,
              } })}
            >
              Edycja
            </Button>
          </Box>
        )}
      <Box mb={1} width="100%">
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          id={uniqid()}
          fullWidth
          onClick={() => navigate(generatePath(
            URL.application.supplementCall,
            { id: row.id }
          ), {
            state: {
              isReadonly: true,
              sentToApplicantDate: row.sentToApplicantDate,
            },
          })}
        >
          Podgląd
        </Button>
      </Box>
      <PrintoutSupplementCall
        applicationNumber={applicationNumber}
        supplementCallId={row.id}
        sentToApplicantDate={row.sentToApplicantDate}
        variant="button"
      />
    </Stack>,
  ];

  const columns = [
    {
      headerName: 'ID Wezwania',
      field: 'id',
      renderCell: (({ value }) => <ExpandableUuid uuid={value} wrap />),
    },
    {
      headerName: 'Data wezwania',
      field: 'sentToApplicantDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Status',
      field: 'status',
      valueFormatter: ({ value }) => SUPPLEMENT_CALL_STATUSES_TRANSLATIONS[value] || 'Nieznany status',
      flex: 0.5,
    },
    {
      headerName: 'Ostateczna data przesłania',
      field: 'finalDate',
      renderCell: (({ value }) => {
        const daysToFinalDate = differenceInDays(parseISO('2023-07-03T00:00:00+02:00'), new Date());

        return conditionallyStyledDate(value, daysToFinalDate);
      }),
      flex: 0.5,
    },
    {
      headerName: 'Data przesłania',
      field: 'sentByApplicantDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Akcja',
      field: 'actions',
      type: 'actions',
      filterable: false,
      getActions: ({ row }) => getRowActions(row),
      width: 210,
    },
  ];

  return (
    <CustomDataGrid
      columns={columns}
      apiSource={`${API_ROUTE.supplementCallsByApplicant}/application/${applicationId}`}
      name={`application-correction-${applicationId}-grid`}
    />
  );
}
