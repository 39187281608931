import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantBranchAdressInEasternPoland pdf element renderer.
 *
 * @returns {ApplicantBranchAdressInEasternPolandPdf}
 */
function ApplicantBranchAdressInEasternPolandPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();
  const cityValue = 'applicant_branch_adress_in_eastern_poland_city';
  const streetValue = 'applicant_branch_adress_in_eastern_poland_street';

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="applicant_branch_adress_in_eastern_poland_voivodeship"
          value={initialFormData?.[cityValue]?.voivodeship?.label}
          oldValue={previousInitialFormData?.[cityValue]?.voivodeship?.label}
        />
        <RowField
          name="applicant_branch_adress_in_eastern_poland_district"
          value={initialFormData?.[cityValue]?.district?.label}
          oldValue={previousInitialFormData?.[cityValue]?.district?.label}
        />
        <RowField
          name="applicant_branch_adress_in_eastern_poland_commune"
          value={initialFormData?.[cityValue]?.commune?.label}
          oldValue={previousInitialFormData?.[cityValue]?.commune?.label}
        />
        <RowField
          name="applicant_branch_adress_in_eastern_poland_city"
          value={initialFormData?.[cityValue]?.label}
          oldValue={previousInitialFormData?.[cityValue]?.label}
        />

        <RowField name="applicant_branch_adress_in_eastern_poland_postal_code" />
        <RowField
          name="applicant_branch_adress_in_eastern_poland_street"
          value={initialFormData?.[streetValue]?.label}
          oldValue={previousInitialFormData?.[streetValue]?.label}
        />
        <RowField name="applicant_branch_adress_in_eastern_poland_house_number" />
        <RowField name="applicant_branch_adress_in_eastern_poland_apartment_number" />
        <RowField name="applicant_branch_adress_in_eastern_poland_phone_number" />
      </FieldSorter>
    </View>
  );
}

export default ApplicantBranchAdressInEasternPolandPdf;
