import { API_ROUTE } from './apiRoutes';

export const DICTIONARIES = {
  projectAreas: {
    name: 'projectAreas',
    path: API_ROUTE.dziedzinyProjektu,
  },
  implementerTypes: {
    name: 'implementerTypes',
    path: API_ROUTE.implementerTypes,
  },
  propertyForms: {
    name: 'propertyForms',
    path: API_ROUTE.propertyForms,
  },
  applicantType: {
    name: 'applicantType',
    path: API_ROUTE.typyWnioskodawcy,
  },
  vatRecoveryPossibilities: {
    name: 'vatRecoveryPossibilities',
    path: API_ROUTE.vatRecoveryPossibilities,
  },
  companySize: {
    name: 'companySize',
    path: API_ROUTE.wielkosciPrzedsiebiorstwa,
  },
  predefinedTask: {
    name: 'predefined_task',
    path: API_ROUTE.predefinedTask,
  },
  taskType: {
    name: 'task_type',
    path: API_ROUTE.taskType,
  },
  costCategories: {
    name: 'cost_category',
    path: API_ROUTE.elementDictionaries.costCategories,
  },
  riskTypes: {
    name: 'risk_type',
    path: API_ROUTE.elementDictionaries.riskTypes,
  },
  technicalResourcesAndIntangibleAssets: {
    name: 'technical_resources_and_intangible_assets',
    path: API_ROUTE.elementDictionaries.technicalResourcesAndIntangibleAssets,
  },
  initialInvestmentTypes: {
    name: 'initial_investment_type',
    path: API_ROUTE.elementDictionaries.initialInvestmentTypes,
  },
  pkdCodes: {
    name: 'pkd_codes',
    path: API_ROUTE.elementDictionaries.pkdCodes,
  },
  solutionInnovationLevels: {
    name: 'solutionInnovationLevels',
    path: API_ROUTE.elementDictionaries.solutionInnovationLevels,
  },
  compentenceModuleEssenceScope: {
    name: 'competences_module_essence_scope',
    path: API_ROUTE.elementDictionaries.compentenceModuleEssenceScope,
  },
  compentenceModuleEssenceType: {
    name: 'competences_module_essence_support_type',
    path: API_ROUTE.elementDictionaries.compentenceModuleEssenceType,
  },
  compentenceModuleEssenceTargetGroup: {
    name: 'competences_module_essence_target_group',
    path: API_ROUTE.elementDictionaries.compentenceModuleEssenceTargetGroup,
  },
  modules: {
    name: 'module',
    path: API_ROUTE.modules,
  },
  modulesWithNaOption: {
    name: 'modules_with_na_option',
    path: API_ROUTE.modulesWithNaOption,
  },
  resultImplementationMethods: {
    name: 'sposob_wdrozenia_wynikow',
    path: API_ROUTE.elementDictionaries.resultImplementationMethods,
  },
  technicalExaminationPerformers: {
    name: 'kto_przeprowadzil_badanie_stanu_techniki',
    path: API_ROUTE.elementDictionaries.technicalExaminationPerformers,
  },
  investmentTypes: {
    name: 'investmentTypes',
    path: API_ROUTE.elementDictionaries.investmentTypes,
  },
  environmentalAidType: {
    name: 'environmental_aid_type',
    path: API_ROUTE.elementDictionaries.environmentalAidType,
  },
  workTypes: {
    name: 'rd_module_essence_work_type',
    path: API_ROUTE.elementDictionaries.workTypes,
  },
  innovationTypes: {
    name: 'rd_module_essence_innovation_type',
    path: API_ROUTE.elementDictionaries.innovationTypes,
  },
  implementationScopes: {
    name: 'implementation_scope',
    path: API_ROUTE.elementDictionaries.implementationScopes,
  },
  legalEntityTypes: {
    name: 'legal_entity_types',
    path: API_ROUTE.elementDictionaries.legalEntityTypes,
  },
  lumpSumTypes: {
    name: 'lump_sum_types',
    path: API_ROUTE.elementDictionaries.lumpSumTypes,
  },
  internationalizationModuleEssenceScopes: {
    name: 'internationalization_module_essence_scope',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceScopes,
  },
  internationalizationModuleEssenceEventTypes: {
    name: 'event_type',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceEventTypes,
  },
  internationalizationModuleEssenceTicketCategories: {
    name: 'ticket_category',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceTicketCategories,
  },
  internationalizationModuleEssenceTicketModes: {
    name: 'ticket_mode',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceTicketModes,
  },
  internationalizationModuleEssenceLawCategories: {
    name: 'protection_law_category',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceLawCategories,
  },
  internationalizationModuleEssenceModeOfActions: {
    name: 'mode_of_action',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceModeOfActions,
  },
  internationalizationModuleEssenceActionSubjects: {
    name: 'action_subject',
    path: API_ROUTE.elementDictionaries.internationalizationModuleEssenceActionSubjects,
  },
  innovationResultingFromImplementationOfResearchAreaTypes: {
    name: 'innovation_resulting_from_implementation_of_research_area_type',
    path: API_ROUTE.elementDictionaries.innovationResultingFromImplementationOfResearchAreaTypes,
  },
  noveltyOfResultsOfResearchAreas: {
    name: 'novelty_of_results_of_research_areas',
    path: API_ROUTE.elementDictionaries.noveltyOfResultsOfResearchAreas,
  },
  roleInProjects: {
    name: 'role_in_projects',
    path: API_ROUTE.elementDictionaries.roleInProjects,
  },
  cooperationStatuses: {
    name: 'cooperation_statuses',
    path: API_ROUTE.elementDictionaries.cooperationStatuses,
  },
  investmentTypeGreenings: {
    name: 'investment_type_greenings',
    path: API_ROUTE.elementDictionaries.investmentTypeGreenings,
  },
  education: {
    name: 'education',
    path: API_ROUTE.elementDictionaries.education,
  },
  innovationImplementationModuleEssenceWorkTypes: {
    name: 'innovation_implementation_module_essence_work_types',
    path: API_ROUTE.elementDictionaries.innovationImplementationModuleEssenceWorkTypes,
  },
  moduleImplementationPotentialDocumentTypes: {
    name: 'module_implementation_potential_document_types',
    path: API_ROUTE.elementDictionaries.moduleImplementationPotentialDocumentTypes,
  },
  ueHorizontalPoliciesProjectComplianceLegalActs: {
    name: 'ue_horizontal_policies_project_compliance_legal_acts',
    path: API_ROUTE.elementDictionaries.ueHorizontalPoliciesProjectComplianceLegalActs,
  },
  ueHorizontalPoliciesProjectComplianceIsR6Rules: {
    name: 'ue_horizontal_policies_project_compliance_is_r6_rules',
    path: API_ROUTE.elementDictionaries.ueHorizontalPoliciesProjectComplianceIsR6Rules,
  },
  ueHorizontalPoliciesProjectComplianceImpacts: {
    name: 'ue_horizontal_policies_project_compliance_impacts',
    path: API_ROUTE.elementDictionaries.ueHorizontalPoliciesProjectComplianceImpacts,
  },
  ueHorizontalPoliciesProjectComplianceR6Rules: {
    name: 'ue_horizontal_policies_project_compliance_r6_rules',
    path: API_ROUTE.elementDictionaries.ueHorizontalPoliciesProjectComplianceR6Rules,
  },
  projectCollaborationTypes: {
    name: 'project_collaboration_types',
    path: API_ROUTE.elementDictionaries.projectCollaborationTypes,
  },
  countries: {
    name: 'countries',
    path: API_ROUTE.elementDictionaries.countries,
  },
  interventionScopes: {
    name: 'intervention_scopes',
    path: API_ROUTE.elementDictionaries.interventionScopes,
  },
  activityTypes: {
    name: 'activity_types',
    path: API_ROUTE.elementDictionaries.activityTypes,
  },
  geoDictionariesCountries: {
    name: 'cst_countries',
    path: API_ROUTE.geoDictionaries.countries,
  },
  geoDictionariesVoivodeships: {
    name: 'cst_voivodeships',
    path: API_ROUTE.geoDictionaries.voivodeships,
  },
  measureUnits: {
    name: 'measureUnits',
    path: API_ROUTE.elementDictionaries.measureUnits,
  },
  lumpSumCostNames: {
    name: 'lump_sum_cost_names',
    path: API_ROUTE.elementDictionaries.lumpSumCostNames,
  },
  edihSevicesTypes: {
    name: 'edih_sevices_types',
    path: API_ROUTE.elementDictionaries.edihSevicesTypes,
  },
  digitalCostCategories: {
    name: 'digital_cost_categories',
    path: API_ROUTE.elementDictionaries.digitalCostCategories,
  },
  supportForms: {
    name: 'support_forms',
    path: API_ROUTE.elementDictionaries.supportForms,
  },
  linkedStrategies: {
    name: 'linked_strategies',
    path: API_ROUTE.elementDictionaries.linkedStrategies,
  },
  instrumentsImplementations: {
    name: 'instruments_implementations',
    path: API_ROUTE.elementDictionaries.instrumentsImplementations,
  },
  genderEqualityDimensions: {
    name: 'gender_equality_dimensions',
    path: API_ROUTE.elementDictionaries.genderEqualityDimensions,
  },
  projectTypes: {
    name: 'project_types',
    path: API_ROUTE.elementDictionaries.projectTypes,
  },
  kisDomainAreas: {
    name: 'kisDomainAreas',
    path: API_ROUTE.elementDictionaries.kisDomainAreas,
  },
  impact: {
    name: 'impact',
    path: API_ROUTE.elementDictionaries.impact,
  },
  projectCompatibility: {
    name: 'projectCompatibility',
    path: API_ROUTE.elementDictionaries.projectCompatibility,
  },
  cstLimitCategories: {
    name: 'cstLimitCategories',
    path: API_ROUTE.elementDictionaries.cstLimitCategories,
  },
  ecosystemPartnerType: {
    name: 'ecosystemPartnerType',
    path: API_ROUTE.elementDictionaries.ecosystemPartnerType,
  },
  innovationCenterType: {
    name: 'innovationCenterType',
    path: API_ROUTE.elementDictionaries.innovationCenterType,
  },
  risAreas: {
    name: 'risAreas',
    path: API_ROUTE.elementDictionaries.risAreas,
  },
  ecoDesignStages: {
    name: 'ecoDesignStages',
    path: API_ROUTE.elementDictionaries.ecoDesignStages,
  },
  greenSustainableEconomies: {
    name: 'greenSustainableEconomies',
    path: API_ROUTE.elementDictionaries.greenSustainableEconomies,
  },
  clusterTypes: {
    name: 'clusterTypes',
    path: API_ROUTE.elementDictionaries.clusterTypes,
  },
  projectInnovations: {
    name: 'projectInnovations',
    path: API_ROUTE.elementDictionaries.projectInnovations,
  },
  strategicAreas: {
    name: 'strategicAreas',
    path: API_ROUTE.elementDictionaries.strategicAreas,
  },
  serviceImplementationBenefits: {
    name: 'serviceImplementationBenefits',
    path: API_ROUTE.elementDictionaries.serviceImplementationBenefits,
  },
  eventServiceTypes: {
    name: 'eventServiceTypes',
    path: API_ROUTE.elementDictionaries.eventServiceTypes,
  },
  accelerationProgramConceptPath: {
    name: 'accelerationProgramConceptPath',
    path: API_ROUTE.elementDictionaries.accelerationProgramConceptPath,
  },
  projectFinancingSources: {
    name: 'projectFinancingSources',
    path: API_ROUTE.elementDictionaries.projectFinancingSources,
  },
  responsibilityAreas: {
    name: 'responsibilityAreas',
    path: API_ROUTE.elementDictionaries.responsibilityAreas,
  },
  mainParametersAndIndicators: {
    name: 'mainParametersAndIndicators',
    path: API_ROUTE.elementDictionaries.mainParametersAndIndicators,
  },
  supportScopes: {
    name: 'supportScopes',
    path: API_ROUTE.elementDictionaries.supportScopes,
  },
  projectComplexities: {
    name: 'projectComplexities',
    path: API_ROUTE.elementDictionaries.projectComplexities,
  },
  compatibilityWithIdeaNebInitiative: {
    name: 'compatibilityWithIdeaNebInitiative',
    path: API_ROUTE.elementDictionaries.compatibilityWithIdeaNebInitiative,
  },
  takeIntoAccountDoesNotTakeIntoAccount: {
    name: 'takeIntoAccountDoesNotTakeIntoAccount',
    path: API_ROUTE.elementDictionaries.takeIntoAccountDoesNotTakeIntoAccount,
  },
  projectImplementationInAreaWithSeparatedPaidParkingZone: {
    name: 'projectImplementationInAreaWithSeparatedPaidParkingZone',
    path: API_ROUTE.elementDictionaries.projectImplementationInAreaWithSeparatedPaidParkingZone,
  },
  sumpCompatibilities: {
    name: 'sumpCompatibilities',
    path: API_ROUTE.elementDictionaries.sumpCompatibilities,
  },
  projectImplementationRegions: {
    name: 'projectImplementationRegions',
    path: API_ROUTE.elementDictionaries.projectImplementationRegions,
  },
  innovationScope: {
    name: 'innovationScope',
    path: API_ROUTE.elementDictionaries.innovationScope,
  },
  confirmationDocuments: {
    name: 'confirmationDocuments',
    path: API_ROUTE.elementDictionaries.confirmationDocuments,
  },
  launchPlatforms: {
    name: 'launchPlatforms',
    path: API_ROUTE.elementDictionaries.launchPlatforms,
  },
  productInnovationImprovement: {
    name: 'productInnovationImprovement',
    path: API_ROUTE.elementDictionaries.productInnovationImprovement,
  },
  ueHorizontalPoliciesProjectSolutions: {
    name: 'ueHorizontalPoliciesProjectSolutions',
    path: API_ROUTE.elementDictionaries.ueHorizontalPoliciesProjectSolutions,
  },
  operationScopeGozTransformationSubjects: {
    name: 'operationScopeGozTransformationSubjects',
    path: API_ROUTE.elementDictionaries.operationScopeGozTransformationSubjects,
  },
  promotionalActivitiesEconomicSector: {
    name: 'promotionalActivitiesEconomicSector',
    path: API_ROUTE.elementDictionaries.promotionalActivitiesEconomicSector,
  },
  promotionalActivitiesType: {
    name: 'promotionalActivitiesType',
    path: API_ROUTE.elementDictionaries.promotionalActivitiesType,
  },
  promotionalActivitiesEconomicSectorsEvent: {
    name: 'promotionalActivitiesEconomicSectorsEvent',
    path: API_ROUTE.elementDictionaries.promotionalActivitiesEconomicSectorsEvent,
  },
  teamImplementingProgramConceptResponsibilityAreas: {
    name: 'teamImplementingProgramConceptResponsibilityAreas',
    path: API_ROUTE.elementDictionaries.teamImplementingProgramConceptResponsibilityAreas,
  },
  applicantGeneralInformationEntityTypes: {
    name: 'applicantGeneralInformationEntityTypes',
    path: API_ROUTE.elementDictionaries.applicantGeneralInformationEntityTypes,
  },
  reduceTurnoverCalculationType: {
    name: 'reduceTurnoverCalculationType',
    path: API_ROUTE.elementDictionaries.reduceTurnoverCalculationType,
  },
  productPlannedTypes: {
    name: 'productPlannedTypes',
    path: API_ROUTE.elementDictionaries.productPlannedTypes,
  },
  innovationLevels: {
    name: 'innovationLevels',
    path: API_ROUTE.elementDictionaries.innovationLevels,
  },
  startingPlatforms: {
    name: 'startingPlatforms',
    path: API_ROUTE.elementDictionaries.startingPlatforms,
  },
  ueProgramTypes: {
    name: 'ueProgramTypes',
    path: API_ROUTE.elementDictionaries.ueProgramTypes,
  },
  applicantUeProgramRoles: {
    name: 'applicantUeProgramRoles',
    path: API_ROUTE.elementDictionaries.applicantUeProgramRoles,
  },
  applicantPreparedSolutionIndustryAffiliation: {
    name: 'applicantPreparedSolutionIndustryAffiliation',
    path: API_ROUTE.elementDictionaries.applicantPreparedSolutionIndustryAffiliation,
  },
  applicantPreparedSolutionReadinessLevels: {
    name: 'applicantPreparedSolutionReadinessLevels',
    path: API_ROUTE.elementDictionaries.applicantPreparedSolutionReadinessLevels,
  },
  applicantPreparedSolutionSubjects: {
    name: 'applicantPreparedSolutionSubjects',
    path: API_ROUTE.elementDictionaries.applicantPreparedSolutionSubjects,
  },
  applicantPreparedSolutionInnovationLevel: {
    name: 'applicantPreparedSolutionInnovationLevel',
    path: API_ROUTE.elementDictionaries.applicantPreparedSolutionInnovationLevel,
  },
  startupApplicantInformationActivityFiscalYearsNumber: {
    name: 'startupApplicantInformationActivityFiscalYearsNumber',
    path: API_ROUTE.elementDictionaries.startupApplicantInformationActivityFiscalYearsNumber,
  },
  startupApplicantInformationCompetitionLevel: {
    name: 'startupApplicantInformationCompetitionLevel',
    path: API_ROUTE.elementDictionaries.startupApplicantInformationCompetitionLevel,
  },
  startupApplicantInformationHonorableMentionObject: {
    name: 'startupApplicantInformationHonorableMentionObject',
    path: API_ROUTE.elementDictionaries.startupApplicantInformationHonorableMentionObject,
  },
  ventureFeasibilityEconomicOrEnvironmentalEffect: {
    name: 'ventureFeasibilityEconomicOrEnvironmentalEffect',
    path: API_ROUTE.elementDictionaries.ventureFeasibilityEconomicOrEnvironmentalEffect,
  },
  gozTransformationImplementationActionPath: {
    name: 'gozTransformationImplementationActionPath',
    path: API_ROUTE.elementDictionaries.gozTransformationImplementationActionPath,
  },
  gozTransformationImplementationProductProcess: {
    name: 'gozTransformationImplementationProductProcess',
    path: API_ROUTE.elementDictionaries.gozTransformationImplementationProductProcess,
  },
  projectImplementationJustificationLevelInnovationImplementedSolution: {
    name: 'projectImplementationJustificationLevelInnovationImplementedSolution',
    path: API_ROUTE.elementDictionaries.projectImplementationJustificationLevelInnovationImplementedSolution,
  },
  scopeOfInnovation: {
    name: 'scopeOfInnovation',
    path: API_ROUTE.elementDictionaries.scopeOfInnovation,
  },
  projectGeneralInformationCompetitionCategory: {
    name: 'projectGeneralInformationCompetitionCategory',
    path: API_ROUTE.elementDictionaries.projectGeneralInformationCompetitionCategory,
  },
};
