import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { BOOLEAN_VALUES } from '../../../../_constants';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Rd Subimplementers Pdf element.
 *
 * @returns {RdSubimplementersPdf}
 */
function RdSubimplementersPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="has_subimplementers" />
        {initialFormData?.has_subimplementers === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="subimplementers">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.has_picked_subimplementer`}
                      value={item.has_picked_subimplementer}
                      oldValue={previousRowData?.has_picked_subimplementer}
                    />
                    {initialFormData?.subimplementers[index].has_picked_subimplementer === BOOLEAN_VALUES.FALSE && (
                      <RowField
                        name={`${collectionName}.${index}.subcontractor_obtaining_possibility`}
                        value={item.subcontractor_obtaining_possibility}
                        oldValue={previousRowData?.subcontractor_obtaining_possibility}
                        isSingleRow
                      />
                    )}
                    <RowField
                      name={`${collectionName}.${index}.is_subimplementer_critical`}
                      value={item.is_subimplementer_critical}
                      oldValue={previousRowData?.is_subimplementer_critical}
                    />
                    <RowField
                      name={`${collectionName}.${index}.has_agreement`}
                      value={item.has_agreement}
                      oldValue={previousRowData?.has_agreement}
                    />
                    <RowField
                      name={`${collectionName}.${index}.subimplementer_name`}
                      value={item.subimplementer_name}
                      oldValue={previousRowData?.subimplementer_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.subimplementer_nip`}
                      value={item.subimplementer_nip}
                      oldValue={previousRowData?.subimplementer_nip}
                    />
                    <RowField
                      name={`${collectionName}.${index}.scope_of_work`}
                      value={item.scope_of_work}
                      oldValue={previousRowData?.scope_of_work}
                    />
                    <RowField name={`${collectionName}.${index}.subimplementer_resources`} isSingleRow />
                    <RowField
                      name={`${collectionName}.${index}.resource_hr`}
                      value={item.resource_hr}
                      oldValue={previousRowData?.resource_hr}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_purpose_hr`}
                      value={item.resource_purpose_hr}
                      oldValue={previousRowData?.resource_purpose_hr}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_equipment`}
                      value={item.resource_equipment}
                      oldValue={previousRowData?.resource_equipment}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_purpose_equipment`}
                      value={item.resource_purpose_equipment}
                      oldValue={previousRowData?.resource_purpose_equipment}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_infrastracture`}
                      value={item.resource_infrastracture}
                      oldValue={previousRowData?.resource_infrastracture}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_purpose_infrastracture`}
                      value={item.resource_purpose_infrastracture}
                      oldValue={previousRowData?.resource_purpose_infrastracture}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_assets`}
                      value={item.resource_assets}
                      oldValue={previousRowData?.resource_assets}
                    />
                    <RowField
                      name={`${collectionName}.${index}.resource_purpose_assets`}
                      value={item.resource_purpose_assets}
                      oldValue={previousRowData?.resource_purpose_assets}
                    />
                    <RowField
                      name={`${collectionName}.${index}.subcontractor_name_organizational_unit`}
                      value={item.subcontractor_name_organizational_unit}
                      oldValue={previousRowData?.subcontractor_name_organizational_unit}
                      isSingleRow
                    />
                  </FieldSorter>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default RdSubimplementersPdf;
