import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ProjectFinancialResources pdf element.
 *
 * @returns {ProjectFinancialResourcesPdf}
 */
function ProjectFinancialResourcesPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Opis zasobów finansowych niezbędnych do realizacji projektu (posiadanych lub
        możliwych do pozyskania źródeł finansowania) oraz na zapewnienie płynności finansowej
        z uwzględnieniem dofinansowania w trakcie realizacji projektu */}
        <RowField
          name="project_financial_resources_project_implementation_necessary_financial_resources_description"
          isSingleRow
        />
        {/* W przypadku, gdy wnioskodawca będzie korzystał z zewnętrznych źródeł finansowania projektu
      należy podać rodzaj finansowania (kredyt bankowy, pożyczka, leasing) oraz numer i datę
      wystawienia dokumentu potwierdzającego finansowanie projektu */}
        <RowField name="project_financial_resources_type_of_financing" isSingleRow />
        {/* Plan finansowania projektu, w tym określenie etapów realizacji projektu, terminów
      tych etapów (kwartalnych) oraz kosztów dotyczących każdego z etapów */}
        <RowField name="project_financial_resources_financing_plan" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ProjectFinancialResourcesPdf;
