import {
  ADDITIONAL_MODULE_IDS,
  ADDITIONAL_MODULE_LABELS,
} from '../_constants';
import { getExternalIdsToCriteriaItem } from './assessmentSheet';

/**
 * Get transformed modules for printout assessment sheet
 *
 * @param {Array} modules - expert panel modules
 * @returns {Array}
 */
export const transformModules = (modules) => {
  const combinedModules = [];

  modules.forEach(({
    moduleId, moduleName,
  }) => {
    if (moduleId === null) {
      combinedModules.push(
        {
          moduleName,
          moduleId: ADDITIONAL_MODULE_IDS.ocenaFinansowa,
          criteriaType: ADDITIONAL_MODULE_IDS.ocenaFinansowa,
        },
        {
          moduleName: ADDITIONAL_MODULE_LABELS.modulowaFinansowa,
          moduleId: ADDITIONAL_MODULE_IDS.modulowaFinansowa,
          criteriaType: ADDITIONAL_MODULE_IDS.modulowaFinansowa,
        }
      );

      return;
    }

    combinedModules.push({
      moduleName,
      moduleId,
      criteriaType: ADDITIONAL_MODULE_IDS.kryteriaModulowe,
    });
  });

  combinedModules.push(
    {
      moduleName: ADDITIONAL_MODULE_LABELS.kryteriaOgolem,
      moduleId: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
      criteriaType: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
    },
    {
      moduleName: 'Kryteria rankingujące wspólne dla projektu',
      moduleId: ADDITIONAL_MODULE_IDS.kryteriaRankingujace,
      criteriaType: ADDITIONAL_MODULE_IDS.kryteriaRankingujace,
    }
  );

  return combinedModules;
};

/**
 * Get transformed printout assessment sheet data
 *
 * @param {Array} criteriaItems - criteria items
 * @param {Array} modules - expert panel modules
 * @returns {Array}
 */
export const getTransformedPrintoutAssessmentSheet = (
  criteriaItems,
  modules
) => transformModules(modules).map((module) => {
  const modulesId = modules.map(({ moduleId }) => moduleId);
  const mappedCriteriaItems = criteriaItems.map((criteriaItem) => ({
    ...criteriaItem,
    points: criteriaItem?.points?.toString(),
    moduleId: criteriaItem.criteriaItem.name.includes('(kryterium rankingujące)')
      ? ADDITIONAL_MODULE_IDS.kryteriaRankingujace
      : getExternalIdsToCriteriaItem(
        criteriaItem.criteriaItem.businessGroup,
        criteriaItem.criteriaItem.moduleId
      ),
  }));

  let items = mappedCriteriaItems.filter(({ moduleId }) => moduleId === module.moduleId);

  if (module.moduleId === ADDITIONAL_MODULE_IDS.modulowaFinansowa) {
    items = items.filter(({ criteriaItem }) => modulesId.includes(criteriaItem.moduleId));
  }

  return ({
    ...module,
    items,
  });
});
