import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useStylesDataGridTable } from '../../../../styles/dataGridTable';

/**
 * Expenses by task table
 *
 * @param {object} props - root props
 * @param {Array} props.data - project budget data
 * @param {string} props.tableName - table name
 * @returns {ExpensesByTaskTable}
 */
function ExpensesByTaskTable({
  data, tableName,
}) {
  const numberFormat = (value) => (
    <NumericFormat
      thousandSeparator=" "
      isNumericString
      value={value}
      disabled
      style={useStylesDataGridTable.numberField}
    />
  );

  return (
    <Box mb={4} sx={useStylesDataGridTable.tableWrapper}>
      <TableContainer component="paper">
        <Table sx={useStylesDataGridTable.table}>
          <TableHead>
            <TableRow sx={useStylesDataGridTable.titleTableHead}>
              <TableCell colSpan={5}>{tableName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nazwa zadania</TableCell>
              <TableCell>Wydatki ogółem</TableCell>
              <TableCell>Wydatki kwalifikowane</TableCell>
              <TableCell>Dofinansowanie</TableCell>
              <TableCell>% dofinansowania</TableCell>
            </TableRow>
          </TableHead>
          {data.map((item, index) => {
            const isLastRow = data.length - 1 === index;

            return (
              <TableBody>
                <TableRow sx={isLastRow ? 'groupSummary' : ''}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{numberFormat(item.total_expenses)}</TableCell>
                  <TableCell>{numberFormat(item.eligible_expenses)}</TableCell>
                  <TableCell>{numberFormat(item.co_financing)}</TableCell>
                  <TableCell>{item.co_financing_percent}</TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ExpensesByTaskTable;

ExpensesByTaskTable.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  tableName: PropTypes.string.isRequired,
};
