import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
} from '@mui/material';
import { request } from '../../_services';
import { ApiAutocomplete } from '../Autocomplete/ApiAutocomplete';
import { SaveCancelButtons } from '../SaveCancelButtons';
import {
  API_ROUTE,
  CHARS_LIMIT,
  REGEX,
} from '../../_constants';
import CharsCounter from '../CharsCounter';
import { ContainerLoader } from '../Application/Application/ContainerLoader';
import { ImprovedMaskedInput } from '../ImprovedMaskedInput';
import {
  setValuesForOtherGeographicalFields,
  townParser,
  iriToId,
  handleError,
} from '../../_helpers';
import CustomDesktopDatePicker from '../CustomDesktopDatePicker';
import { ConfirmDialog } from '../../Dialogs';
import { CustomAutocomplete } from '../CustomAutocomplete/CustomAutocomplete';
import { useGlobalContext } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  label: {
    position: 'relative',
    fontSize: 'inherit',
    color: 'inherit',
    whiteSpace: 'unset',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
};

/**
 * Organization address form
 *
 * @param {object} props - root props
 * @param {object} props.entityId - optional form data Id
 * @param {Function} props.closeHandler - function to invoke on close/cancel
 * @returns {OrganizationAddressForm}
 */
export function OrganizationAddressForm({
  entityId, closeHandler,
}) {
  const { notify } = useGlobalContext();
  const {
    control, handleSubmit, watch, reset, setError, setValue, getValues, formState: { isDirty },
  } = useForm({
    defaultValues: {
      applicantName: '',
      nip: '',
      applicantType: null,
      companySize: null,
      propertyForm: null,
      vatRecoveryPossibility: null,
      street: null,
      city: null,
      postalCode: '',
      houseNumber: '',
      apartmentNumber: '',
      email: '',
      phoneNumber: '',
      webPage: '',
      startDate: null,
      regon: '',
    },
    mode: 'all',
  });

  const [loaderWorkerCount, setLoaderWorkerCount] = useState(0);
  const [companySizes, setCompanySizes] = useState([]);
  const [vatRecoveryPossibilities, setVatRecoveryPossibilities] = useState([]);
  const [propertyForms, setPropertyForms] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const streetSelectRef = useRef();

  const loadApiCustomData = async (setterFunc, apiRoute) => {
    setLoaderWorkerCount((s) => s + 1);
    const { payload } = await request.get(apiRoute);
    setterFunc(payload);
    setLoaderWorkerCount((s) => s - 1);
  };
  const loadApiTownData = async (townId) => {
    setLoaderWorkerCount((s) => s + 1);
    const { payload } = await request.get(`${API_ROUTE.geoDictionaries.towns}/${townId}`);

    setValuesForOtherGeographicalFields(
      townParser(payload),
      setValue,
      ['country', 'commune', 'district', 'voivodeship'],
    );

    setLoaderWorkerCount((s) => s - 1);
  };

  const loadApiData = async (apiRoute) => {
    if (entityId === null) {
      return;
    }

    setLoaderWorkerCount((s) => s + 1);
    const { payload } = await request.get(apiRoute);
    reset(payload);
    setLoaderWorkerCount((s) => s - 1);
    if (payload.city) {
      loadApiTownData(iriToId(payload.city));
    }
  };

  useEffect(() => {
    loadApiData(`${API_ROUTE.organizationAddress}/${entityId}`);

    loadApiCustomData(
      setCompanySizes,
      `${API_ROUTE.wielkosciPrzedsiebiorstwa}`
    );
    loadApiCustomData(
      setPropertyForms,
      `${API_ROUTE.propertyForms}`
    );
    loadApiCustomData(
      setVatRecoveryPossibilities,
      `${API_ROUTE.vatRecoveryPossibilities}`
    );
  }, [entityId]);

  const submitExistingAddress = (payload) => (
    request.put(
      `${API_ROUTE.organizationAddress}/${payload.id}`,
      payload
    )
  );

  const submitNewAddress = (payload) => (
    request.post(
      API_ROUTE.organizationAddress,
      payload
    )
  );

  const submitHandler = async (data) => {
    const payload = {
      ...data,
      city: `/lsi/recruitments/api/cst-towns/${data.city?.value || iriToId(data.city)}`,
    };

    const {
      statusSuccess, violations = [],
    } = (entityId !== null)
      ? await submitExistingAddress(payload)
      : await submitNewAddress(payload);

    if (statusSuccess) {
      notify(entityId ? 'Dane organizacji zostały zmienione' : 'Dodano nową organizację', 'success');
      closeHandler();
    }

    handleError(setError, violations);
  };

  const createMsg = (name) => `Proszę uzupełnić pole "${name}"`;
  const renderSelect = (name, description, dataSet) => (
    <>
      <InputLabel
        htmlFor={name}
        required
        sx={classes.label}
      >
        {description}
      </InputLabel>

      <FormControl
        fullWidth
        spacing={3}
        variant="outlined"
      >
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg(description),
            },
          }}
          render={({
            field: {
              onChange, value,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                options={dataSet}
                optionsMapKeys={['@id', 'name']}
                textFieldProps={{
                  error: !!error,
                  name,
                  required: true,
                }}
              />
              {!!error && (
                <FormHelperText error>
                  {error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>

    </>
  );

  const renderDisabledTextField = (name, label) => (
    <>
      <InputLabel
        htmlFor={name}
        required
        sx={classes.label}
      >
        {label}
      </InputLabel>

      <TextField
        name={name}
        id={name}
        value={getValues(name)?.label || ''}
        disabled
        fullWidth
      />

    </>
  );

  const selectedTown = watch('city');

  if (loaderWorkerCount !== 0) {
    return <ContainerLoader />;
  }

  return (
    <>
      <InputLabel
        htmlFor="applicantName"
        required
        sx={classes.label}
      >
        Nazwa
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="applicantName"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Nazwa'),
            },
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={1000} />),
              }}
              inputProps={{ maxLength: 1000 }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="startDate" sx={classes.label} required>
        Data rozpoczęcia działalności zgodnie z dokumentem rejestrowym
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="startDate"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Data rozpoczęcia działalności zgodnie z dokumentem rejestrowym'),
            },
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <CustomDesktopDatePicker
              onChange={onChange}
              value={value}
              name={name}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </FormControl>

      <InputLabel
        htmlFor="nip"
        required
        sx={classes.label}
      >
        NIP
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="nip"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('NIP'),
            },
            pattern: REGEX.nip,
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={CHARS_LIMIT.NIP} />),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.NIP }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="regon" required sx={classes.label}>
        REGON
      </InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name="regon"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('REGON'),
            },
            pattern: REGEX.regon,
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={CHARS_LIMIT.REGON} />),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.REGON }}
            />
          )}
        />
      </FormControl>

      {renderSelect(
        'companySize',
        'Wielkość przedsiębiorstwa',
        companySizes
      )}

      {renderSelect(
        'propertyForm',
        'Forma własności',
        propertyForms
      )}

      {renderSelect(
        'vatRecoveryPossibility',
        'Możliwość odzyskania VAT',
        vatRecoveryPossibilities
      )}

      <InputLabel
        htmlFor="city"
        required
        sx={classes.label}
      >
        Miejscowość
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="city"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Miejscowość'),
            },
          }}
          render={({
            field: {
              name, value, onChange,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <ApiAutocomplete
                id={name}
                filterBy="name"
                initialValue={value}
                initialValueFilterBy="id"
                optionParser={townParser}
                changeHandler={(selectedValue) => {
                  streetSelectRef.current.clear();
                  onChange(selectedValue);
                  setValuesForOtherGeographicalFields(selectedValue, setValue);
                }}
                charLimitExclusionWords={['oś']}
                baseUrl={API_ROUTE.geoDictionaries.towns}
                staticFilters={{ itemsPerPage: '500' }}
              />
              {!!error && (
                <FormHelperText error>
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>

      {renderDisabledTextField(
        'voivodeship',
        'Województwo',
      )}

      {renderDisabledTextField(
        'district',
        'Gmina',
      )}

      {renderDisabledTextField(
        'commune',
        'Powiat',
      )}

      <InputLabel
        htmlFor="postalCode"
        required
        sx={classes.label}
      >
        Kod pocztowy
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="postalCode"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Kod pocztowy'),
            },
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              value={value}
              id={name}
              name={name}
              onChange={onChange}
              variant="outlined"
              InputProps={{
                inputComponent: ImprovedMaskedInput,
                endAdornment: (<CharsCounter valueLength={value?.length || 0} maxLength={6} />),
              }}
              inputProps={{
                mask: '00-000',
                maxLength: 6,
              }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="street" sx={classes.label}>
        Ulica
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="street"
          control={control}
          render={({
            field: {
              name, onChange, value,
            },
          }) => (
            <ApiAutocomplete
              id={name}
              filterBy="name"
              ref={streetSelectRef}
              staticFilters={{
                'town.id': selectedTown
                  ? selectedTown.value
                  : null,
              }}
              initialValue={value}
              initialValueFilterBy="id"
              optionParser={({
                '@id': id, name: label,
              }) => ({
                value: id,
                label,
              })}
              changeHandler={(selectedValue) => {
                onChange(selectedValue?.value || null);
              }}
              baseUrl={API_ROUTE.geoDictionaries.streets}
              disabled={!selectedTown}
              minCharsToRequest={2}
            />
          )}
        />
      </FormControl>

      <InputLabel
        htmlFor="houseNumber"
        required
        sx={classes.label}
      >
        Numer budynku
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="houseNumber"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Numer budynku'),
            },
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <CharsCounter
                    valueLength={value?.length}
                    maxLength={10}
                  />),
              }}
              inputProps={{ maxLength: 10 }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="apartmentNumber" sx={classes.label}>
        Numer lokalu
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="apartmentNumber"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <CharsCounter
                    valueLength={value?.length}
                    maxLength={10}
                  />),
              }}
              inputProps={{ maxLength: 10 }}
            />
          )}
        />
      </FormControl>

      <InputLabel
        htmlFor="email"
        required
        sx={classes.label}
      >
        Email
      </InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: createMsg('Email'),
            },
            pattern: REGEX.email,
          }}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <CharsCounter
                    valueLength={value?.length}
                    maxLength={750}
                  />),
              }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="phoneNumber" sx={classes.label}>
        Telefon
      </InputLabel>

      <FormControl variant="outlined" fullWidth>
        <Controller
          name="phoneNumber"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <CharsCounter
                    valueLength={value?.length}
                    maxLength={45}
                  />),
              }}
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="webPage" sx={classes.label}>
        Strona www
      </InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name="webPage"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              name={name}
              id={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <CharsCounter
                    valueLength={value?.length}
                    maxLength={750}
                  />),
              }}
            />
          )}
        />
      </FormControl>
      <Box mt={4}>
        <SaveCancelButtons
          cancelHandler={isDirty ? () => setConfirmDialogOpen(true) : closeHandler}
          cancelTitle="Zamknij"
          saveHandler={handleSubmit(submitHandler)}
        />
      </Box>

      <ConfirmDialog
        closeHandler={() => setConfirmDialogOpen(false)}
        open={confirmDialogOpen}
        confirmButtonHandler={() => {
          closeHandler();
          setConfirmDialogOpen(false);
        }}
        confirmButtonLabel="OK"
        cancelButtonColor="primary"
      >
        Formularz zawiera niezapisane dane. Czy chcesz zamknąć formularz i utracić dane?
      </ConfirmDialog>
    </>
  );
}

OrganizationAddressForm.propTypes = {
  entityId: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
};

OrganizationAddressForm.defaultProps = {
  entityId: null,
};
