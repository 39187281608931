import {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  FormControl,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useParams } from 'react-router-dom';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import {
  API_ROUTE,
  DATE,
  GROUPS,
} from '../../_constants';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';
import { request } from '../../_services';
import { CustomAutocomplete } from '../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * Logotypes configurator form component
 *
 * @param {object} props - root props
 * @param {object} props.initialData -form data
 * @param {Function} props.closeHandler - handle close form
 * @returns {LogotypesConfiguratorForm}
 */
export default function LogotypesConfiguratorForm({
  initialData, closeHandler,
}) {
  const { id: templateId } = useParams();
  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      logotypeName: initialData ? initialData.logotype?.id : null,
      groups: initialData ? initialData.groups : null,
      availableFrom: initialData ? initialData.availableFrom : null,
      availableTo: initialData ? initialData.availableTo : null,
    },
    mode: 'all',
  });

  const [loaded, setLoaded] = useState(false);
  const [logotypes, setLogotypes] = useState([]);

  const loadLogotypes = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(
      API_ROUTE.elementDictionaries.logotypes
    );

    if (statusSuccess) {
      setLogotypes(payload);
      setLoaded(true);
    }
  };

  useEffect(() => {
    loadLogotypes();
  }, []);

  /**
   * Send data to API
   *
   * @param {object} formData - form data
   * @returns {object}
   */
  const onSubmit = async (formData) => {
    const payload = {
      logotype: `/lsi/recruitments/api/logotypes/${formData.logotypeName}`,
      applicationTemplate: `/lsi/recruitments/api/application-templates/${templateId}`,
      groups: formData.groups,
      availableFrom: formData.availableFrom,
      availableTo: formData.availableTo,
    };

    const {
      statusSuccess,
    } = initialData ? await request.put(`${API_ROUTE.templateLogotypes}/${initialData.id}`, payload)
      : await request.post(API_ROUTE.templateLogotypes, payload);

    if (statusSuccess) {
      closeHandler();
    }
  };

  if (!loaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      <FormControl margin="normal" fullWidth>
        <Controller
          name="logotypeName"
          control={control}
          render={({
            field: {
              value, name, onChange,
            },
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              renderSelectAllButton={false}
              options={logotypes.map((logotype) => ({
                '@id': logotype.id,
                name: logotype.attachment.description,
              }))}
              textFieldProps={{
                label: 'Nazwa logotypu',
              }}
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <Controller
          name="groups"
          control={control}
          render={({
            field: {
              value, name, onChange,
            },
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              options={GROUPS.map((group) => ({
                '@id': group,
                name: group,
              }))}
              textFieldProps={{
                name,
                label: 'Krok',
              }}
              onChange={onChange}
              multiple
              renderSelectAllButton={false}
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <Controller
          name="availableFrom"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <DesktopDatePicker
              label="Data od"
              ampm={false}
              value={value}
              clearable
              slotProps={{
                textField: {
                  id: name,
                  variant: 'outlined',
                  helperText: error?.message,
                  error: !!error,
                },
              }}
              onChange={(newDate) => {
                onChange({
                  target: {
                    name,
                    value: new Date(newDate),
                  },
                });
              }}
              format="yyyy-MM-dd"
              mask="____-__-__"
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <Controller
          name="availableTo"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <DesktopDatePicker
              label="Data do"
              ampm={false}
              value={value}
              clearable
              slotProps={{
                textField: {
                  name,
                  id: name,
                  variant: 'outlined',
                  error: !!error,
                  helperText: error?.message,
                  placeholder: DATE.defaultDatePlaceholder,
                },
              }}
              onChange={(newDate) => {
                onChange({
                  target: {
                    name,
                    value: new Date(newDate),
                  },
                });
              }}
              format="yyyy-MM-dd"
            />
          )}
        />
      </FormControl>
      <Box mt={6}>
        <SaveCancelButtons
          variant="center"
          cancelHandler={closeHandler}
          cancelButtonId="w7A7I59i0nVJ7DP"
          saveHandler={handleSubmit(onSubmit)}
          saveButtonId="29LA4XH4Y92yB7q"
        />
      </Box>
    </>
  );
}

LogotypesConfiguratorForm.propTypes = {
  initialData: PropTypes.instanceOf(Object),
  closeHandler: PropTypes.func.isRequired,
};

LogotypesConfiguratorForm.defaultProps = {
  initialData: null,
};
