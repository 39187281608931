import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

export const parsePastedText = (event, onChange) => {
  const pastedText = event.clipboardData.getData('text');
  if (pastedText.indexOf(',') !== -1) {
    event.preventDefault();
    onChange(pastedText.replace(',', '.'));
  }
};

/**
 * Monetary field component.
 *
 * @see https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
 * @param {object} props - root props
 * @returns {MonetaryField} - component
 */
export const MonetaryField = forwardRef((props, ref) => {
  const {
    onChange, maxValue, ...other
  } = props;

  const getValue = (val) => {
    if (Number(val) > Number(maxValue)) {
      return Number(maxValue);
    }

    return val;
  };

  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: getValue(values.value),
          },
        });
      }}
      thousandSeparator=" "
      fixedDecimalScale
      isNumericString
      decimalScale={2}
      {...other}
    />
  );
});

MonetaryField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.number,
};

MonetaryField.defaultProps = {
  maxValue: 99999999999999,
};
