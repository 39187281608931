import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantRepresentative pdf element.
 *
 * @returns {ApplicantRepresentativePdf}
 */
function ApplicantRepresentativePdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Imię */}
        <RowField name="applicant_representative_first_name" isSingleRow />
        {/* Nazwisko */}
        <RowField name="applicant_representative_last_name" isSingleRow />
        {/* Telefon */}
        <RowField name="applicant_representative_phone_number" />
        {/* Adres e-mail */}
        <RowField name="applicant_representative_email" isSingleRow />
        {/* Stanowisko/Funkcja */}
        <RowField name="applicant_representative_position" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ApplicantRepresentativePdf;
