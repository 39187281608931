import {
  diffChars,
  diffWords,
  diffSentences,
} from 'diff';
import { Text } from '@react-pdf/renderer';
import { printStyle } from '../Features/PrintToPdf/print.style';
import { PDF_ROW_STATUS } from '../_constants';

const convertSingleTag = (singleTag) => {
  if (typeof singleTag === 'string') {
    return {
      tagName: null,
      content: singleTag,
    };
  }

  if (typeof singleTag.props.children === 'string') {
    return {
      tagName: singleTag.type,
      content: singleTag.props.children,
    };
  }

  if (Array.isArray(singleTag.props.children) || singleTag.props.children?.type === 'li') {
    if (singleTag.props.children?.type === 'li') {
      return {
        tagName: singleTag.type,
        content: [convertSingleTag(singleTag.props.children)],
      };
    }

    return {
      tagName: singleTag.type,
      content: singleTag.props.children.map((tag) => convertSingleTag(tag)),
    };
  }

  if (singleTag.type === 'a') {
    return {
      tagName: singleTag.type,
      href: singleTag.props.href,
      content: singleTag.props.children?.props?.children || null,
    };
  }

  return {
    tagName: singleTag.type,
    content: singleTag.props.children?.props?.children || null,
  };
};

/**
 * Convert HTML tags to PDF tags
 *
 * @param {Array} parsedHTML - html
 * @returns {Array}
 */
export const convertHtmlTagsToPdfTags = (parsedHTML) => parsedHTML.map((singleTag) => convertSingleTag(singleTag));

const DIFF_TYPES = {
  chars: diffChars,
  words: diffWords,
  sentences: diffSentences,
};

/**
 * Get changes in field
 *
 * @param {string} oldValue - old field value
 * @param {string} newValue - new field value
 * @param {string} diffType - diff type ( "chars" | "words" )
 * @returns {Node}
 */
export const getChangesInField = (oldValue, newValue, diffType = DIFF_TYPES.sentences) => {
  if (typeof oldValue !== 'string' || typeof newValue !== 'string') {
    return newValue;
  }

  const differences = diffType(oldValue, newValue);

  return differences.map((part) => {
    // eslint-disable-next-line no-nested-ternary
    const style = part.added ? printStyle.added : (part.removed ? printStyle.removed : {});

    return <Text style={style}>{part.value}</Text>;
  });
};

/**
 * Get index for previous row data
 *
 * @param {Array} data - current data
 * @param {string} searchedValue - searched value
 * @param {string} key - under which key the desired value is located
 * @returns {string | number}
 */
export const getIndexForPreviousRowData = (data = [], searchedValue = '', key = 'set_uuid') => data
  .findIndex((item) => item[key] === searchedValue);

/**
 * Get removed collection data
 *
 * @param {Array} currentData - current data
 * @param {Array} previousData - previous data
 * @param {string} key - under which key the desired value is located
 * @returns {Array}
 */
export const getRemovedRowData = (currentData = [], previousData = [], key = 'set_uuid') => previousData
  ?.filter((filteredItem) => !currentData.some((item) => filteredItem[key] === item[key])).map((item) => ({
    ...item,
    status: PDF_ROW_STATUS.removed,
  }));

/**
 * Get added row data
 *
 * @param {Array} currentData - current data
 * @param {Array} previousData - previous data
 * @param {string} key - under which key the desired value is located
 * @returns {Array}
 */
export const getAddedRowData = (currentData = [], previousData = [], key = '') => currentData.map((item) => {
  if (!previousData.some((searchedItem) => searchedItem[key] === item[key])) {
    return {
      ...item,
      status: PDF_ROW_STATUS.added,
    };
  }

  return {
    ...item,
    status: null,
  };
});

/**
 * Get additional styles for tables by status
 *
 * @param {string} status - row status
 * @returns {object}
 */
export const getAdditionalStylesByStatus = (status) => (status in printStyle ? {
  ...printStyle[status],
  padding: 10,
} : {});
