import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { FormField } from '../../../Application/Application/FormField';
import FieldConfig from '../../../Application/Application/FieldConfig/FieldConfig';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { useElementContext } from '../../../../Context';
import { MonetaryField } from '../../../MonetaryField';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../../_constants';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';
import CharsCounter from '../../../CharsCounter';
import { theme } from '../../../../styles/theme';

const classes = {
  container: {
    '& .MuiGrid-item': {
      paddingLeft: theme.spacing(3),
      paddingBottom: 0,
    },
  },
};
/**
 * Own indicator form component
 *
 * @param {object} props - root props
 * @param {number} props.index - row index
 * @param {string} props.indicatorName - indicator name
 * @param {Function} props.onRemove - remove indicator
 * @returns {OwnIndicatorForm}
 */
function OwnIndicatorForm({
  index, indicatorName, onRemove,
}) {
  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.measureUnits);
  const {
    isReadonly, id: templateElementId,
  } = useElementContext();

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <Grid sx={classes.container} container spacing={3} mb={3}>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.name`}>
        {({
          name, label,
        }) => (
          <Grid item xs={8}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.unit`}>
        {({
          name, label, title,
        }) => (
          <Grid item xs={4}>
            <Typography>
              {label || title}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, filterOptions, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  initialValue={value}
                  options={filterOptions(get(DICTIONARIES.measureUnits.name))}
                  error={error}
                  disabled={isReadonly}
                  isUpperFirst={false}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.base_year`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  views={['year']}
                  ariaLabel={label}
                  format="yyyy"
                  mask="____"
                  fullWidth
                  minDate="2022"
                  maxDate="2030"
                  placeholder="rrrr"
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.base_value_o`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                value, onChange, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_year`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  views={['year']}
                  ariaLabel={label}
                  format="yyyy"
                  mask="____"
                  fullWidth
                  minDate="2023"
                  maxDate="2035"
                  placeholder="rrrr"
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_value_o`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                value, onChange, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.measuring_method`}>
        {({
          name, label,
        }) => (
          <Grid item xs={12}>
            <Typography>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error, maxLength,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  type="number"
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  multiline
                  minRows={2}
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 500} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 500 }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>

      {!isReadonly && (
        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={2}>
          <Button
            id={uniqid()}
            onClick={() => onRemove(index)}
            variant="contained"
            color="primary"
          >
            Usuń
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default OwnIndicatorForm;

OwnIndicatorForm.propTypes = {
  index: PropTypes.number.isRequired,
  indicatorName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};
