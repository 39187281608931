import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Digital innovation pdf element.
 *
 * @returns {DigitalInnovationPdf}
 */
function DigitalInnovationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    application,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const isDigitalInnovationImplementation = initialFormData?.digital_innovation_is_implementation_digital_innovation
    === BOOLEAN_VALUES.TRUE;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Czy projekt dotyczy opracowania innowacji cyfrowej na poziomie kraju? */}
        <RowField name="digital_innovation_is_developing_digital_innovation" />
        {/* Czy projekt dotyczy wdrożenia innowacji cyfrowej na poziomie kraju? */}
        <RowField name="digital_innovation_is_implementation_digital_innovation" />
        {/* Moduł z wdrożeniem innowacji cyfrowej na poziomie kraju */}
        {isDigitalInnovationImplementation && (
          <RowField name="digital_innovation_implementation_digital_innovation_module" externalDictionary={modules} />
        )}
      </FieldSorter>
    </View>
  );
}

export default DigitalInnovationPdf;
