import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ProjectImplementationAreaPdf element.
 *
 * @returns {ProjectImplementationAreaPdf}
 */
function ProjectImplementationAreaPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    application,
    getDictionary,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="implemented_in_regions" />
        {initialFormData?.implemented_in_regions === 'region' && (
          <CollectionWrapper name="implementation_addresses">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.main_implementation_address`}
                        value={item.main_implementation_address}
                        oldValue={previousRowData?.main_implementation_address}
                      />
                      <RowField
                        name={`${collectionName}.${index}.voivodeship`}
                        value={item.voivodeship}
                        oldValue={previousRowData?.voivodeship}
                        dictionaryName={DICTIONARIES.geoDictionariesVoivodeships.name}
                        skipFilterDictionary
                      />
                      <RowField
                        name={`${collectionName}.${index}.district`}
                        value={getDictionary(`${collectionName}.${index}.district_dictionary`)?.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.commune`}
                        value={getDictionary(`${collectionName}.${index}.commune_dictionary`)?.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.city`}
                        value={getDictionary(`${collectionName}.${index}.city_dictionary`)?.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.subregion`}
                        value={item.subregion?.label}
                        oldValue={previousRowData?.subregion?.label}
                      />
                      <RowField
                        name={`${collectionName}.${index}.street`}
                        value={item.street?.label}
                        oldValue={previousRowData?.street?.label}
                      />
                      <RowField
                        name={`${collectionName}.${index}.house_number`}
                        value={item.house_number}
                        oldValue={previousRowData?.house_number}
                      />
                      <RowField
                        name={`${collectionName}.${index}.apartment_number`}
                        value={item.apartment_number}
                        oldValue={previousRowData?.apartment_number}
                      />
                      <RowField
                        name={`${collectionName}.${index}.postal_code`}
                        value={item.postal_code}
                        oldValue={previousRowData?.postal_code}
                      />
                      <RowField
                        name={`${collectionName}.${index}.legal_title_to_real_estate`}
                        value={item.legal_title_to_real_estate}
                        oldValue={previousRowData?.legal_title_to_real_estate}
                      />
                      <RowField
                        name={`${collectionName}.${index}.module_name`}
                        value={item.module_name}
                        oldValue={previousRowData?.module_name}
                        externalDictionary={modules}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default ProjectImplementationAreaPdf;
