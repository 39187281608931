import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import RowField from '../../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Private partnership pdf subfield of ApplicantGeneralInformation.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {PrivatePartnershipPdf}
 */
function PrivatePartnershipPdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.first_name`}
            value={item.first_name}
            oldValue={previousRowData?.first_name}
          />
          <RowField
            name={`${collectionName}.${index}.last_name`}
            value={item.last_name}
            oldValue={previousRowData?.last_name}
          />
          <RowField
            name={`${collectionName}.${index}.nip`}
            value={item.nip}
            oldValue={previousRowData?.nip}
          />
          <RowField
            name={`${collectionName}.${index}.country`}
            value={item.country?.label}
            oldValue={previousRowData?.country?.label}
          />
          <RowField
            name={`${collectionName}.${index}.city`}
            value={item.city?.label}
            oldValue={previousRowData?.city?.label}
          />
          <RowField
            name={`${collectionName}.${index}.commune`}
            value={item.commune?.label}
            oldValue={previousRowData?.commune?.label}
          />
          <RowField
            name={`${collectionName}.${index}.district`}
            value={item.district?.label}
            oldValue={previousRowData?.district?.label}
          />
          <RowField
            name={`${collectionName}.${index}.voivodeship`}
            value={item.voivodeship?.label}
            oldValue={previousRowData?.voivodeship?.label}
          />
          <RowField
            name={`${collectionName}.${index}.postal_code`}
            value={item.postal_code}
            oldValue={previousRowData?.postal_code}
          />
          <RowField
            name={`${collectionName}.${index}.street`}
            value={item.street?.label}
            oldValue={previousRowData?.street?.label}
          />
          <RowField
            name={`${collectionName}.${index}.house_number`}
            value={item.house_number}
            oldValue={previousRowData?.house_number}
          />
          <RowField
            name={`${collectionName}.${index}.apartment_number`}
            value={item.apartment_number}
            oldValue={previousRowData?.apartment_number}
          />
          <RowField
            name={`${collectionName}.${index}.email`}
            value={item.email}
            oldValue={previousRowData?.email}
          />
          <RowField
            name={`${collectionName}.${index}.phone_number`}
            value={item.phone_number}
            oldValue={previousRowData?.phone_number}
          />
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default PrivatePartnershipPdf;

PrivatePartnershipPdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

PrivatePartnershipPdf.defaultProps = {
  previousRowData: {},
};
