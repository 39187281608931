import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';
import { useGlobalDialog } from '../../../Context';
import { theme } from '../../../styles/theme';
import { formatDateString } from '../../../_helpers';
import { DATE } from '../../../_constants';

/**
 * Component will display news
 *
 * @param {object} props - Root props
 * @param {string} props.sx - button styles
 * @param {object} props.newsData - news data
 * @param {Node} props.children - children
 * @returns {NewsDialog}
 */
export default function NewsDialog({
  sx, children, newsData,
}) {
  const {
    closeAll, render,
  } = useGlobalDialog();

  return (
    <Button
      sx={sx}
      onClick={() => render(
        <>
          <Box p={3}>
            <Typography variant="body1" mt={2} fontSize="1em">
              {formatDateString(newsData.pubDate, DATE.defaultFormatWithoutTime)}
            </Typography>
            <Typography variant="dialogHeading" fontSize="1.2em" color={theme.palette.secondary.main} mt={2}>
              {newsData?.title}
            </Typography>
            <Typography variant="body1" mt={2} fontSize="1em">
              {newsData?.description ? HTMLReactParser(newsData?.description) : ''}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={5}>
            <Button
              id="DVhJTCyFXpdrZxG"
              onClick={closeAll}
            >
              Zamknij
            </Button>
          </Box>
        </>,
        null,
        {
          variant: 'contained',
          color: 'primary',
          maxWidth: 'lg',
        },
      )}
    >
      {children}
    </Button>
  );
}

NewsDialog.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.string,
  newsData: PropTypes.instanceOf(Object).isRequired,
};

NewsDialog.defaultProps = {
  sx: '',
};
