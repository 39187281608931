import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';
import SingleElement from '../../Components/SingleElement/SingleElement';

/**
 * Selected Modules Pdf element.
 *
 * @returns {SelectedModulesPdf}
 */
function SelectedModulesPdf() {
  const { application } = useApplicationToPdfContext();
  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  if (modules.length === 0) {
    return '';
  }

  return (
    <SingleElement title="Moduły realizowane w ramach projektu">
      <View style={printStyle.table}>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray" rowSpan={1}>
            Moduły obligatoryjne
          </PrintoutTableCell>
        </PrintoutTableRow>
        <PrintoutTableRow>
          <PrintoutTableCell rowSpan={1}>
            {application.obligatoryModules.map((module) => module.name).join(', ')}
          </PrintoutTableCell>
        </PrintoutTableRow>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray" rowSpan={1}>
            Moduły fakultatywne
          </PrintoutTableCell>
        </PrintoutTableRow>
        <PrintoutTableRow>
          <PrintoutTableCell rowSpan={1}>
            {application.facultativeModules.map((module) => module.name).join(', ')}
          </PrintoutTableCell>
        </PrintoutTableRow>
      </View>
    </SingleElement>
  );
}

export default SelectedModulesPdf;
