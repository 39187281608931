import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { CHARS_LIMIT } from '../../../_constants';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

const classes = {
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
  },
};

/**
 * Mailing address form for expert personal data.
 *
 * @param {object} props - root props
 * @param {object} props.countriesDictionary - dictionary for country input
 * @param {boolean} props.isReadonly - is read only
 * @returns {MailingAddressForm}
 */
export function MailingAddressForm({
  countriesDictionary, isReadonly,
}) {
  const {
    control, watch, setValue, getValues,
  } = useFormContext();

  const mailingCountry = watch('mailingAddress.country') || getValues('mailingAddress.country');
  const mailingSameAsResidenceAddress = watch('mailingSameAsResidenceAddress')
    || getValues('mailingSameAsResidenceAddress');
  const isPolandSelected = mailingCountry === 'PL';

  return (
    <>
      <Box textAlign="left">
        <Controller
          name="mailingSameAsResidenceAddress"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
          }) => (
            <FormControlLabel
              disabled={isReadonly}
              control={<Switch name={name} onChange={onChange} checked={value} color="secondary" />}
              label="Adres korespondencji taki sam jak adres zamieszkania"
              labelPlacement="start"
            />
          )}
        />
      </Box>
      {!mailingSameAsResidenceAddress && (
        <Grid container spacing={2} my={2} sx={classes.container}>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.country">
              Kraj
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Kraj"',
                  },
                }}
                name="mailingAddress.country"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <CustomAutocomplete
                    disabled={isReadonly}
                    id={name}
                    textFieldProps={{
                      error: !!error,
                      helperText: error?.message,
                      label: 'Kraj',
                    }}
                    initialValue={value}
                    onChange={(event) => {
                      setValue('mailingAddress.postalCode', '');
                      onChange(event);
                    }}
                    options={Object.entries(countriesDictionary).map(([id, label]) => ({
                      id,
                      label,
                    }))}
                    optionsMapKeys={['id', 'label']}
                    error={error}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.town">
              Miejscowość
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="mailingAddress.town"
                control={control}
                render={({
                  field: {
                    onChange, value = '', name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.postalCode">
              Kod pocztowy
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="mailingAddress.postalCode"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    value={value}
                    variant="outlined"
                    placeholder={isPolandSelected ? '__-___' : ''}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={isPolandSelected ? 6 : 10} />),
                      inputComponent: isPolandSelected ? ImprovedMaskedInput : undefined,
                    }}
                    inputProps={{
                      mask: '00-000',
                      maxLength: isPolandSelected ? 6 : 10,
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.street">
              Ulica
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="mailingAddress.street"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.houseNumber">
              Nr budynku
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="mailingAddress.houseNumber"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={10} />),
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="mailingAddress.apartmentNumber">
              Nr lokalu
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="mailingAddress.apartmentNumber"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={10} />),
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
}

MailingAddressForm.propTypes = {
  countriesDictionary: PropTypes.instanceOf(Object).isRequired,
  isReadonly: PropTypes.bool,
};

MailingAddressForm.defaultProps = {
  isReadonly: false,
};
