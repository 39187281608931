import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FieldSorter } from './FieldSorter';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../_constants';
import CharsCounter from '../../CharsCounter';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { MonetaryField } from '../../MonetaryField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * UE program subject eurogrant.
 *
 * @returns {UeProgramSubjectEurogrant}
 */
export function UeProgramSubjectEurogrant() {
  const { get } = useDictionaryLoader(
    DICTIONARIES.ueProgramTypes,
    DICTIONARIES.applicantUeProgramRoles,
    DICTIONARIES.geoDictionariesCountries,
  );
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    watch, reset, control, setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'ue_program_subject_eurogrant_members_data',
  });

  const config = fieldsConfig.ue_program_subject_eurogrant_members_data;
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const {
    ueProgramType, applicantUeProgramRole,
  } = ELEMENTS_DICTIONARIES_MAP;

  const ueProgramTypes = watch('ue_program_subject_eurogrant_ue_program_type');
  const isUeProgramTypeVisible = ueProgramTypes === ueProgramType.otherUeProgram.id;

  const ueProgramRoles = watch('ue_program_subject_eurogrant_applicant_ue_program_role');
  const isUeProgramRoleVisible = !Array.isArray(ueProgramRoles)
    && ueProgramRoles !== applicantUeProgramRole.independentApplicant.id;

  return (
    <FieldSorter>
      {/* Nazwa Programu UE, tj. programu Unii Europejskiej zarządzanego centralnie przez Komisję Europejską,
którego dotyczy projekt */}
      <FormField name="ue_program_subject_eurogrant_ue_program_type">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== ueProgramType.otherUeProgram.id) {
                reset((formValues) => ({
                  ...formValues,
                  ue_program_subject_eurogrant_other_ue_program_type: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.ueProgramTypes.name))}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {isUeProgramTypeVisible && (
        <FormField name="ue_program_subject_eurogrant_other_ue_program_type">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              multiline
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Opis konkursu Komisji Europejskiej/Instrumentu, w ramach którego złożony zostanie wniosek/aplikacja
      o Eurogrant */}
      <FormField name="ue_program_subject_eurogrant_eu_commission_competition_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Oficjalna strona internetowa konkursu Komisji Europejskiej/Instrumentu */}
      <FormField name="ue_program_subject_eurogrant_eu_commission_competition_official_website">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data złożenia wniosku/aplikacji o Eurogrant do Programu UE */}
      <FormField name="ue_program_subject_eurogrant_eurogrant_application_created_at">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            error={error}
          />
        )}
      </FormField>
      {/* Rola wnioskodawcy w projekcie z Programu UE: */}
      <FormField name="ue_program_subject_eurogrant_applicant_ue_program_role">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value === applicantUeProgramRole.independentApplicant.id) {
                reset((formValues) => ({
                  ...formValues,
                  ue_program_subject_eurogrant_members_data: FIELD_TYPES_MAP.collection,
                }));
              }
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.applicantUeProgramRoles.name))}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {isUeProgramRoleVisible && (
        <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
          {fields.map((field, index) => (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                'ue_program_subject_eurogrant_members_data',
                index,
              )}
              itemIndex={index}
              isReadonly={isReadonly}
              fieldsOrder={Object.keys(subfields)}
              buttonRemoveVisible={fields.length > minRowNumber}
              key={field.id}
              isIndexed
            >
              {/* Nazwa członka */}
              <FormField name={`ue_program_subject_eurogrant_members_data.${index}.name`}>
                {({
                  onChange, value, name, onBlur, error, maxLength,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Kraj siedziby członka */}
              <FormField name={`ue_program_subject_eurogrant_members_data.${index}.residence_country`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.geoDictionariesCountries.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/*  Numer Identyfikacji Podatkowej NIP lub VAT UE */}
              <FormField name={`ue_program_subject_eurogrant_members_data.${index}.nip`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Adres oficjalnej strony internetowej członka */}
              <FormField name={`ue_program_subject_eurogrant_members_data.${index}.official_website`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Czy będzie ponosił koszty w związku z przygotowaniem wniosku o dofinansowanie z Programu UE? */}
              <FormField name={`ue_program_subject_eurogrant_members_data.${index}.is_bear_to_prepare_application_expenses`}>
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
            </CollectionFieldContainer>
          ))}

          {!isReadonly && fields.length < maxRowNumber && (
            <Grid item xs={12}>
              <Button
                id="CNH6vSS2NYCstEG"
                variant="contained"
                color="secondary"
                onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
              >
                Dodaj
              </Button>
            </Grid>
          )}
        </LabeledFieldRow>
      )}

      {/* Czy w ramach przygotowania wniosku o dofinansowanie z Programu UE poniesione zostaną
      koszty opracowania analiz specjalistycznych? */}
      <FormField name="ue_program_subject_eurogrant_is_bear_to_prepare_application_operating_expenses">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  ue_program_subject_eurogrant_operating_expenses_justification: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(
        watch('ue_program_subject_eurogrant_is_bear_to_prepare_application_operating_expenses') === BOOLEAN_VALUES.TRUE
      ) && (
        <FormField name="ue_program_subject_eurogrant_operating_expenses_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              multiline
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Wnioskowana kwota dofinansowania w ramach Programu UE  */}
      <FormField name="ue_program_subject_eurogrant_funding_amount">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            defaultValue={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Opis zakresu przedmiotowego i założeń planowanego Eurograntu */}
      <FormField name="ue_program_subject_eurogrant_operating_assumptions_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
