import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ExportPotential pdf element.
 *
 * @returns {ExportPotentialPdf}
 */
function ExportPotentialPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const isProductBrandValue = initialFormData
    ?.export_potential_product_brand_awarded === BOOLEAN_VALUES.TRUE;

  const isRegularlyProductValue = initialFormData
    ?.export_potential_applicant_regularly_notified_products === BOOLEAN_VALUES.TRUE;

  const isPublicProcurementValue = initialFormData
    ?.export_potential_applicant_participated_in_international_public_procurement === BOOLEAN_VALUES.TRUE;

  const isProcessInnovationValue = initialFormData
    ?.export_potential_applicant_introduced_process_innovation === BOOLEAN_VALUES.TRUE;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Zasoby finansowe, techniczne i kadrowe do realizacji działań promocyjnych */}
        <RowField name="export_potential_resources" isSingleRow />
        {/* Marka produktowa została nagrodzona/wyróżniona w konkursie lub ujęta w rankingu branżowym? */}
        <RowField name="export_potential_product_brand_awarded" />
        {isProductBrandValue && (
          <RowField name="export_potential_product_brand_awarded_justification" isSingleRow />
        )}
        {/* Wnioskodawca zgłaszał regularnie produkty do udziału w wydarzeniu targowym */}
        <RowField name="export_potential_applicant_regularly_notified_products" />
        {isRegularlyProductValue && (
          <RowField name="export_potential_applicant_regularly_notified_products_justification" isSingleRow />
        )}
        {/* Wnioskodawca brał udział w międzynarodowych zamówieniach publicznych lub realizował te zamówienia */}
        <RowField name="export_potential_applicant_participated_in_international_public_procurement" />
        {isPublicProcurementValue && (
          <RowField
            name="export_potential_applicant_participated_in_international_public_procurement_justification"
            isSingleRow
          />
        )}
        {/* Wnioskodawca wprowadził w przedsiębiorstwie innowację procesową lub produktową */}
        <RowField name="export_potential_applicant_introduced_process_innovation" />
        {isProcessInnovationValue && (
          <RowField name="export_potential_applicant_introduced_process_innovation_justification" isSingleRow />
        )}
      </FieldSorter>
    </View>
  );
}

export default ExportPotentialPdf;
