export const CHARS_LIMIT = {
  PESEL: 11,
  NIP: 10,
  KRS: 10,
  REGON: 9,
  PHONE: 15,
  MEDIUM: 100,
  LONG: 255,
  STORY: 1024,
  EPIC: 2400,
  TIN: 50,
  SHORT: 25,
};
