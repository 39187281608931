/**
 * Protest unconsidered reasons.
 *
 * @type {object}
 */
export const PROTEST_UNCONSIDERED_REASONS = {
  withdrawnByApplicant: 'withdrawn_by_applicant',
  negative: 'negative',
  noFunds: 'no_funds',
};

export const PROTEST_UNCONSIDERED_REASONS_TRANSLATION = {
  [PROTEST_UNCONSIDERED_REASONS.withdrawnByApplicant]: 'Protest wycofany przez Wnioskodawcę',
  [PROTEST_UNCONSIDERED_REASONS.negative]: 'Negatywna ocena wymogów formalnych protestu',
  [PROTEST_UNCONSIDERED_REASONS.noFunds]: 'Brak środków',
};
