import { CircularProgress } from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { TitledContainer } from '../../TitledContainer';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';
import { PersonalDataForm } from './PersonalData.form';
import { useExpertPersonalDataContext } from '../../../Context';

/**
 * Expert personal data address form parts with residence&mailing addresses.
 *
 * @returns {PersonalData}
 */
export function PersonalData() {
  const [loaded, setLoaded] = useState(false);
  const [citizenship, setCitizenship] = useState(null);
  const [user, setUser] = useState(null);

  const {
    reloadData, closeHandler, isReadonly, expertId,
  } = useExpertPersonalDataContext();

  /**
   * Loads user data.
   */
  const loadUserData = async () => {
    const url = expertId ? `${API_ROUTE.users}/${expertId}` : API_ROUTE.usersMe;
    const {
      payload, statusSuccess,
    } = await request.get(url);

    if (statusSuccess) {
      setUser(payload);
    }
  };

  /**
   * Loads citizenship dictionary.
   */
  const loadCitizenship = async () => {
    const {
      statusSuccess, payload,
    } = await request.get(API_ROUTE.intl.citizenship);

    if (statusSuccess) {
      setCitizenship(payload?.data || {});
    }
  };

  useEffect(() => {
    loadUserData();
    loadCitizenship();
  }, []);

  useEffect(() => {
    if (citizenship && user) {
      setLoaded(true);
    }
  }, [user, citizenship]);

  if (!loaded) {
    return <CircularProgress size={50} color="secondary" aria-label="Trwa ładowanie słowników" />;
  }

  return (
    <TitledContainer title="Dane osobowe" key="personal-data" variant="slim" textAlign="left">
      <PersonalDataForm
        citizenshipDictionary={citizenship}
        user={user}
        reloadData={reloadData}
        closeHandler={closeHandler}
        isReadonly={isReadonly}
      />
    </TitledContainer>
  );
}
