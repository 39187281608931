import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';

/**
 * Foreign partners pdf element.
 *
 * @returns {ForeignPartnersPdf}
 */
function ForeignPartnersPdf() {
  return (
    <CollectionWrapper name="foreign_partners">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              {/* Nazwa zagranicznego partnera */}
              <RowField
                name={`${collectionName}.${index}.name`}
                value={item.name}
                oldValue={previousRowData?.name}
              />
              {/* Etykieta: Adres siedziby zagranicznego partnera */}
              <RowField
                name={`${collectionName}.${index}.head_office_address`}
                value={item.head_office_address}
                oldValue={previousRowData?.head_office_address}
                isSingleRow
              />
              {/* Kraj */}
              <RowField
                name={`${collectionName}.${index}.country`}
                value={item.country}
                oldValue={previousRowData?.country}
                dictionaryName={DICTIONARIES.geoDictionariesCountries.name}
              />
              {/* Ulica */}
              <RowField
                name={`${collectionName}.${index}.street`}
                value={item.street}
                oldValue={previousRowData?.street}
              />
              {/* Nr budynku */}
              <RowField
                name={`${collectionName}.${index}.house_number`}
                value={item.house_number}
                oldValue={previousRowData?.house_number}
              />
              {/* Nr lokalu */}
              <RowField
                name={`${collectionName}.${index}.apartment_number`}
                value={item.apartment_number}
                oldValue={previousRowData?.apartment_number}
              />
              {/* Kod pocztowy */}
              <RowField
                name={`${collectionName}.${index}.postal_code`}
                value={item.postal_code}
                oldValue={previousRowData?.postal_code}
              />
              {/* Poczta */}
              <RowField
                name={`${collectionName}.${index}.post_office`}
                value={item.post_office}
                oldValue={previousRowData?.post_office}
              />
              {/* Miejscowość */}
              <RowField
                name={`${collectionName}.${index}.city`}
                value={item.city}
                oldValue={previousRowData?.city}
              />
              {/* Telefon */}
              <RowField
                name={`${collectionName}.${index}.phone_number`}
                value={item.phone_number}
                oldValue={previousRowData?.phone_number}
              />
              {/* Adres e-mail */}
              <RowField
                name={`${collectionName}.${index}.email`}
                value={item.email}
                oldValue={previousRowData?.email}
              />
              {/* Adres strony WWW */}
              <RowField
                name={`${collectionName}.${index}.web_page`}
                value={item.web_page}
                oldValue={previousRowData?.web_page}
              />
              {/* Opis wcześniej nawiązanej relacji zagranicznego partnera z Wnioskodawcą */}
              <RowField
                name={`${collectionName}.${index}.relationship_description`}
                value={item.relationship_description}
                oldValue={previousRowData?.relationship_description}
                isSingleRow
              />
              {/* Opis działalności zagranicznego partnera w kontekście planowanej współpracy w ramach
          programu akceleracji (projektu) */}
              <RowField
                name={`${collectionName}.${index}.activities_description`}
                value={item.activities_description}
                oldValue={previousRowData?.activities_description}
                isSingleRow
              />
              {/* Informacje na temat przewidywanej formy i zakresu zaangażowania zagranicznego partnera
          w program akceleracyjny w konkretnych działaniach */}
              <RowField
                name={`${collectionName}.${index}.information_on_engagement`}
                value={item.information_on_engagement}
                oldValue={previousRowData?.information_on_engagement}
                isSingleRow
              />
              {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację o zaangażowaniu w projekt
           (dotyczy Wnioskodawców, którzy w części Koncepcja programu akceleracji wybrali ścieżkę „Go Global”)? */}
              <RowField
                name={`${collectionName}.${index}.accompanied_written_declaration`}
                value={item.accompanied_written_declaration}
                oldValue={previousRowData?.accompanied_written_declaration}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ForeignPartnersPdf;
