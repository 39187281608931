import {
  createContext,
  useContext,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { authService } from './authService';
import { sessionService } from './sessionService';
import { request } from '../_services';
import {
  API_ROUTE,
  ROLES,
} from '../_constants';

const ACTION_TYPES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  UPDATE: 'update',
};

const authReducer = (state, action) => {
  switch (action.type) {
  case ACTION_TYPES.LOGIN:
    return {
      ...state,
      ...(action.value || {}),
      authenticated: true,
    };
  case ACTION_TYPES.LOGOUT:
    return {
      authenticated: false,
      sessionEnded: action.payload.sessionEnded,
    };
  case ACTION_TYPES.UPDATE:
    return {
      ...state,
      ...(action.value || {}),
    };
  default:
    return state;
  }
};

/**
 * Pure auth context.
 */
export const AuthState = createContext({});

/**
 * Authentication provider.
 *
 * @param {object} props - root props
 * @param {Node|Node[]} props.children - children elements
 * @returns {AuthProvider}
 */
export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(
    authReducer,
    sessionService.getUserEntries(),
    undefined
  );

  /**
   * Api response with changed keys.
   *
   * @param {object} payload - payload data
   * @returns {object}
   */
  const getResponse = (payload) => ({
    ...payload,
    profileSwitching: payload.profile_switching,
    firstName: payload.first_name,
    lastName: payload.last_name,
    isAdmin: payload?.roles?.includes(ROLES.ROLE_ADMIN),
  });

  /**
   * Handles user logging in.
   * After successful response from authService, retrieved token will be decoded
   * and all data set to provider's state and session.
   * Response payload will be also forwarded to onSuccess callback function (if provided).
   *
   * @param {string} username - username
   * @param {string} password - password
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   */
  const handleLogin = async (username, password, onSuccess = () => {}, onFailure = () => {}) => {
    const dispatchDecoded = (payload, onSuccessCallback) => {
      sessionService.write(getResponse(payload));

      dispatch({
        type: ACTION_TYPES.LOGIN,
        value: getResponse(payload),
      });

      onSuccessCallback(payload);
    };

    await authService.handleLogin(
      username,
      password,
      (payload) => dispatchDecoded(payload, onSuccess),
      onFailure
    );
  };

  /**
   * Clears provider's state and session storage.
   *
   * @param {boolean} isLogoutOfAllSessions - log out of all sessions
   * @param {Function} onRequestDone - function invoked on request success or failure
   */
  const handleLogout = async (isLogoutOfAllSessions, onRequestDone = () => {}) => {
    await authService.handleLogout(
      isLogoutOfAllSessions,
      () => {
        dispatch({
          type: ACTION_TYPES.LOGOUT,
          payload: { sessionEnded: false },
        });
        sessionService.clear();
        onRequestDone();
      },
      onRequestDone,
    );
  };

  /**
   * Automatic logout at the end of the session time
   */
  const handleLogoutByEndOfSession = async () => {
    Promise.resolve().then(() => {
      dispatch({
        type: ACTION_TYPES.LOGOUT,
        payload: { sessionEnded: true },
      });
      sessionService.clear();
    });
  };

  /**
   * Update provider's state and session values.
   * ex. {profile: 'expert'} will change provider's state property `profile` to `expert`,
   * and write updated data to session.
   *
   * @param {object} values - keys&values to update
   */
  const handleUpdate = (values) => {
    dispatch({
      type: ACTION_TYPES.UPDATE,
      value: values,
    });

    sessionService.write(values);
  };

  /**
   * Handles user registration.
   * Depending on success, "payload" will be passed to onSuccess or onFailure function.
   *
   * @param {object} payload - request payload
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   * @returns {void}
   */
  const handleRegister = async (payload, onSuccess = () => {}, onFailure = () => {}) => {
    await authService.handleRegister(
      payload,
      onSuccess,
      onFailure
    );
  };

  /**
   * Handles user refresh session
   * Depending on success, "payload" will be passed to onSuccess or onFailure function.
   *
   * @param {Function} onFailure - function invoked on request failure
   * @returns {void}
   */
  const handleRefreshSession = async (onFailure = () => {}) => {
    const dispatchDecoded = (payload) => {
      handleUpdate({
        ...getResponse(payload),
        profileSwitching: payload.profile_switching,
        profile: state.profile,
      });
    };

    await authService.handleRefreshSession(
      (payload) => dispatchDecoded(payload),
      onFailure,
    );
  };

  /**
   * Handles user switch to other account.
   *
   * @param {string} username - username to switch
   * @returns {void}
   */
  const handleUserSwitch = async (username) => {
    const {
      payload, statusSuccess,
    } = await request.userSwitch(username);
    if (statusSuccess) {
      handleUpdate({
        switched: true,
        id: payload.id,
        username: payload.username,
        roles: payload.rolesNames,
        profile: payload.currentProfile,
        isAdmin: payload.rolesNames?.includes(ROLES.ROLE_ADMIN),
      });
    }
  };
  /**
   * Handles user switch back to main account.
   *
   * @returns {void}
   */
  const handleUserUndoSwitch = async () => {
    handleUpdate({ switched: false });
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.usersMe);

    if (statusSuccess) {
      handleUpdate({
        id: payload.id,
        username: payload.username,
        roles: payload.rolesNames,
        profile: payload.currentProfile,
        isAdmin: payload.rolesNames?.includes(ROLES.ROLE_ADMIN),
      });
    }
  };

  return (
    <AuthState.Provider value={
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      {
        ...state,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onLogoutByEndOfSession: handleLogoutByEndOfSession,
        onUpdate: handleUpdate,
        onRegister: handleRegister,
        onRefreshSession: handleRefreshSession,
        onUserSwitch: handleUserSwitch,
        onUserUndoSwitch: handleUserUndoSwitch,
      }
    }
    >
      {children}
    </AuthState.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Use auth context.
 *
 * @returns {object}
 */
export const useAuth = () => useContext(AuthState);
