import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { scopeOfStartupIncubation } from '../../../../_optionsCustomAutocomplete';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * LaunchPadIncubationProgramConceptDescription pdf element.
 *
 * @returns {LaunchPadIncubationProgramConceptDescriptionPdf}
 */
function LaunchPadIncubationProgramConceptDescriptionPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="concept_description_information_and_promotion_activities" />
        <RowField name="concept_description_recruitment_organization" />
        <RowField name="concept_description_active_and_effective_inclusion_activities" />
        <RowField name="concept_description_selection_method" />
        <RowField
          name="concept_description_startup_incubation_concept_scope"
          externalDictionary={scopeOfStartupIncubation.map(({
            id, label,
          }) => ({
            '@id': id,
            name: label,
          }))}
        />
        <RowField name="concept_description_organizing_incubation_rounds_method" />
        <RowField name="concept_description_preparing_ipi_method" />
        <RowField name="concept_description_cooperation_with_startups_method" />
        <RowField name="concept_description_activities_implementation" />
        <RowField name="concept_description_tools_and_progress_research_method" />
        <RowField name="concept_description_days_organization_method" />
        <RowField name="concept_description_expert_organization_method" />
      </FieldSorter>
    </View>
  );
}

export default LaunchPadIncubationProgramConceptDescriptionPdf;
