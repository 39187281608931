import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantExportActivities pdf element.
 *
 * @returns {ApplicantExportActivitiesPdf}
 */
function ApplicantExportActivitiesPdf() {
  const secondClosedFinancialYear = 'applicant_export_activities_second_closed_financial_year';
  const firstClosedFinancialYear = 'applicant_export_activities_first_closed_financial_year';

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* 1.Liczba zamkniętych lat obrachunkowych, w trakcie których wnioskodawca prowadził działalność eksportową */}
        <RowField name="applicant_export_activities_number_closed_years" />
        {/* 2.Opis dotychczasowego doświadczenia wnioskodawcy w prowadzeniu działalności eksportowej */}
        <RowField name="applicant_export_activities_applicant_previous_experience_description" isSingleRow />
        {/* 3.Etykieta: Drugi zamknięty rok obrachunkowy poprzedzający rok złożenia wniosku o dofinansowanie */}
        <RowField name={secondClosedFinancialYear} isSingleRow />
        {/* 4.Kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności gospodarczej */}
        <RowField name={`${secondClosedFinancialYear}_applicant_income_amount`} />
        {/* 5.w tym kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności eksportowej */}
        <RowField name={`${secondClosedFinancialYear}_applicant_income_amount_export_activities`} />
        {/* 6.Udział (%) przychodów Wnioskodawcy z prowadzenia działalności eksportowej w stosunku do
      sumy przychodów osiągniętych z prowadzenia działalności gospodarczej */}
        <RowField name={`${secondClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`} />
        {/* 7.w tym kwota przychodów z eksportu produktu/ów będących przedmiotem projektu */}
        <RowField
          name="applicant_export_activities_second_closed_financial_year_applicant_income_amount_from_products_export"
        />
        {/* 8.Udział (%) przychodów z eksportu produktu/ów będących przedmiotem projektu w stosunku do
      osiągniętej kwoty przychodów Wnioskodawcy z prowadzenia działalności eksportowej */}
        <RowField name={`${secondClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`} />
        {/* 9.Etykieta: Zamknięty rok obrachunkowy poprzedzający rok złożenia wniosku o dofinansowanie */}
        <RowField name="applicant_export_activities_first_closed_financial_year" isSingleRow />
        {/* 10.Kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności gospodarczej */}
        <RowField name="applicant_export_activities_first_closed_financial_year_applicant_income_amount" />

        {/* 11.w tym kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności eksportowej */}
        <RowField
          name="applicant_export_activities_first_closed_financial_year_applicant_income_amount_export_activities"
        />
        {/* 12.Udział (%) przychodów Wnioskodawcy z prowadzenia działalności eksportowej w stosunku do sumy
      przychodów osiągniętych z prowadzenia działalności gospodarczej */}
        <RowField name={`${firstClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`} />
        {/* 13.w tym kwota przychodów z eksportu produktu/ów będących przedmiotem projektu */}
        <RowField
          name="applicant_export_activities_first_closed_financial_year_applicant_income_amount_from_products_export"
        />
        {/* 14.Udział (%) przychodów z eksportu produktu/ów będących przedmiotem projektu w stosunku do
      osiągniętej kwoty przychodów Wnioskodawcy z prowadzenia działalności eksportowej */}
        <RowField name={`${firstClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`} />
        {/* Adres firmowej wizytówki na Portalu Promocji Eksportu */}
        <RowField name="applicant_export_activities_company_business_card_address" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ApplicantExportActivitiesPdf;
