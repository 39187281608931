import { TextField } from '@mui/material';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useElementContext } from '../../../Context';

/**
 * ClusterCoordinatorPotential element renderer.
 *
 * @returns {ClusterCoordinatorPotential}
 */
export function ClusterCoordinatorPotential() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Zasoby techniczne i kadrowe do realizacji projektu */}
      <FormField name="cluster_coordinator_potential_technical_and_human_resources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Źródła i sposób finansowania działalności (w szczególności projektu) */}
      <FormField name="cluster_coordinator_potential_sources_and_method_financing">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Kluczowe ryzyka związane z realizacją projektu */}
      <FormField name="cluster_coordinator_potential_key_risks">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Rozwój potencjału koordynatora klastra w ramach projektu */}
      <FormField name="cluster_coordinator_potential_capacity_development">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            disabled={isReadonly}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
