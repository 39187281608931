import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DATE,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { formatDateString } from '../../../../_helpers';

/**
 * PromotionalActivities Pdf element.
 *
 * @returns {PromotionalActivitiesPdf}
 */
function PromotionalActivitiesPdf() {
  const { promotionalActivitiesType } = ELEMENTS_DICTIONARIES_MAP;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Sektor gospodarki, którego dotyczy projekt  */}
        <RowField
          name="promotional_activities_economic_sectors"
          dictionaryName={DICTIONARIES.promotionalActivitiesEconomicSector.name}
        />
        {/* Tabela "Wybrane działania promocyjne, w tym wydarzenia targowe kluczowe dla
      wskazanego sektora gospodarki, w których wnioskodawca weźmie udział w roli wystawcy i/lub zwiedzającego" */}
        <CollectionWrapper name="promotional_activities_selected_promotional_activities">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    {/* Typ działania */}
                    <RowField
                      name={`${collectionName}.${index}.activities_type`}
                      value={item.activities_type}
                      oldValue={previousRowData?.activities_type}
                      dictionaryName={DICTIONARIES.promotionalActivitiesType.name}
                    />
                    {(item.activities_type === promotionalActivitiesType.exhibitorFairs.id) ? (
                      <RowField
                        name={`${collectionName}.${index}.trade_fair_event_name`}
                        value={item.trade_fair_event_name}
                        oldValue={previousRowData?.trade_fair_event_name}
                        dictionaryName={DICTIONARIES.promotionalActivitiesEconomicSectorsEvent.name}
                      />
                    ) : (
                      <RowField
                        name={`${collectionName}.${index}.trade_fair_event_activity_name`}
                        value={item.trade_fair_event_activity_name}
                        oldValue={previousRowData?.trade_fair_event_activity_name}
                        isSingleRow
                      />
                    )}
                    {/* Kraj wydarzenia targowego */}
                    <RowField
                      name={`${collectionName}.${index}.trade_fair_event_country`}
                      value={item.trade_fair_event_country}
                      oldValue={previousRowData?.trade_fair_event_country}
                      dictionaryName={DICTIONARIES.countries.name}
                    />
                    {/* Termin (rok) */}
                    <RowField
                      name={`${collectionName}.${index}.year_deadline`}
                      value={formatDateString(item?.year_deadline, DATE.onlyYear)}
                      oldValue={formatDateString(previousRowData?.year_deadline, DATE.onlyYear)}
                    />
                    {/* Adres oficjalnej strony internetowej wydarzenia branżowego (jeśli dotyczy) */}
                    <RowField
                      name={`${collectionName}.${index}.web_page`}
                      value={item.web_page}
                      oldValue={previousRowData?.web_page}
                    />
                    {/* Stoisko narodowe na wydarzeniu branżowym */}
                    <RowField
                      name={`${collectionName}.${index}.national_stand_at_industry_event`}
                      value={item.national_stand_at_industry_event}
                      oldValue={previousRowData?.national_stand_at_industry_event}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Rynki docelowe, na terenie których będą prowadzone działania promocyjne przewidziane w
      projekcie (kraj, region) */}
        <RowField name="promotional_activities_target_markets" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default PromotionalActivitiesPdf;
