import { Typography } from '@mui/material';
import { TitledContainer } from '../../TitledContainer';
import { LanguageSkillsForm } from './LanguageSkills.form';

/**
 * Expert language skills
 *
 * @returns {LanguageSkills}
 */
export function LanguageSkills() {
  return (
    <TitledContainer title="Znajomość języków" variant="slim" textAlign="left">
      <Typography>
        Deklaruję znajomość wskazanego języka na poziomie umożliwiającym realizację zadań:
        związanych z wyborem projektów do dofinansowania;
        wynikających z umowy o dofinansowanie projektu albo decyzji o dofinansowaniu projektu;
        związanych z procedurą odwoławczą.
      </Typography>
      <LanguageSkillsForm />
    </TitledContainer>
  );
}
