import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { CHARS_LIMIT } from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';

/**
 * ApplicantPotentialCompetition element renderer.
 *
 * @returns {ApplicantPotentialCompetition}
 */
export function ApplicantPotentialCompetition() {
  const {
    control, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'applicant_potential_competition',
  });

  const config = fieldsConfig.applicant_potential_competition;
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'applicant_potential_competition',
            index,
          )}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          buttonRemoveVisible={fields.length > minRowNumber}
          isIndexed
        >
          <FormField name={`applicant_potential_competition.${index}.name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                multiline
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`applicant_potential_competition.${index}.nip`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                variant="outlined"
                InputProps={{
                  inputComponent: ImprovedMaskedInput,
                  endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.NIP} />,
                }}
                inputProps={{
                  mask: '0000000000',
                  maxLength: maxLength ?? CHARS_LIMIT.NIP,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`applicant_potential_competition.${index}.description`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                multiline
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="f1ZbS3f3eEHywp7"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
