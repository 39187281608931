import { FormField } from '../../Application/Application/FormField';
import { useElementContext } from '../../../Context';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * IntensityOfPublicAidForRd component
 *
 * @returns {IntensityOfPublicAidForRd}
 */
export default function IntensityOfPublicAidForRd() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Zwiększenie intensywności pomocy publicznej na badania przemysłowe z uwagi na to że */}
      <FormField name="intensity_of_public_aid_for_rd_reason_mein_label" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Projekt zakłada efektywną współpracę (podwykonawstwo nie jest traktowane jako efektywna współpraca) między */}
      <FormField name="intensity_of_public_aid_for_rd_reason">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Wyniki całego projektu będą szeroko rozpowszechniane i w okresie do 3 lat od zakończenia projektu */}
      <FormField name="intensity_of_public_aid_for_rd_result_mein_label" contextHelpLabel>
        {() => null}
      </FormField>
      {/* a) zostaną zaprezentowane na co najmniej 3 konferencjach naukowych i technicznych, w tym co najmniej 1 o r */}
      <FormField name="intensity_of_public_aid_for_rd_result_mein_a">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* b)  zostaną opublikowane w co najmniej 2 czasopismach naukowych lub technicznych (ujętych w wykazie czasop */}
      <FormField name="intensity_of_public_aid_for_rd_result_mein_b">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* c) opublikowane w powszechnie dostępnych bazach danych, zapewniających swobodny dostęp do uzyskanych wyników
       badań, lub */}
      <FormField name="intensity_of_public_aid_for_rd_result_mein_c">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* d) zostaną w całości rozpowszechnione za pośrednictwem oprogramowania bezpłatnego lub oprogramowania z */}
      <FormField name="intensity_of_public_aid_for_rd_result_mein_d">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Zwiększenie intensywności pomocy publicznej na prace rozwojowe z uwagi na to że */}
      <FormField name="intensity_of_public_aid_for_rd_public_aid_label" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Projekt zakłada efektywną współpracę (podwykonawstwo nie jest traktowane jako efektywna współpraca) między */}
      <FormField name="intensity_of_public_aid_for_rd_public_aid">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Wyniki całego projektu będą szeroko rozpowszechniane i w okresie do 3 lat od zakończenia projektu: */}
      <FormField name="intensity_of_public_aid_for_rd_result_mnisw_label" contextHelpLabel>
        {() => null}
      </FormField>
      {/* a) zostaną zaprezentowane na co najmniej 3 konferencjach naukowych i technicznych, w tym co najmniej 1 o */}
      <FormField name="intensity_of_public_aid_for_rd_result_mnisw_a">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* b) zostaną opublikowane w co najmniej 2 czasopismach naukowych lub technicznych (ujętych w wykazie */}
      <FormField name="intensity_of_public_aid_for_rd_result_mnisw_b">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* c) opublikowane w powszechnie dostępnych bazach danych, zapewniających swobodny dostęp do uzyskanych
      wyników badań, lub */}
      <FormField name="intensity_of_public_aid_for_rd_result_mnisw_c">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* d) zostaną w całości rozpowszechnione za pośrednictwem oprogramowania bezpłatnego lub oprogramowania */}
      <FormField name="intensity_of_public_aid_for_rd_result_mnisw_d">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Premia za lokalizację (5%) */}
      <FormField name="intensity_of_public_aid_for_rd_5_percent_location_bonus">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Premia za lokalizację (15%) */}
      <FormField name="intensity_of_public_aid_for_rd_15_percent_location_bonus">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
