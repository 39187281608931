import PropTypes from 'prop-types';
import {
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import IndicatorRowPdf from './IndicatorRow.pdf';
import OwnIndicatorRowPdf from './OwnIndicatorRow.pdf';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getIndexForPreviousRowData,
  getRemovedRowData,
  getIndicatorTargetValueFromHrf,
} from '../../../../../_helpers';

const indicatorsTableStyle = StyleSheet.create({
  tableWrapper: {
    marginBottom: 20,
  },
});

/**
 * IndicatorsTablePdf element.
 *
 * @param {object} props - root props
 * @param {string} props.name - field name
 * @param {Array} props.indicatorsData - indicator data
 * @param {string} props.label - label
 * @returns {IndicatorsTablePdf}
 */
function IndicatorsTablePdf({
  name, indicatorsData, label,
}) {
  const {
    previousInitialFormData, withChanges, managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const previousIndicatorsData = previousInitialFormData[name] || [];

  const mappedIndicatorsData = () => {
    const combinedIndicatorsData = withChanges ? [
      ...getAddedRowData(indicatorsData, previousIndicatorsData, 'id'),
      ...getRemovedRowData(indicatorsData, previousIndicatorsData, 'id'),
    ] : indicatorsData;

    return combinedIndicatorsData.map((item) => {
      const existIndicator = indicatorsData.find(({ id }) => id === item.id);

      return ({
        ...item,
        target_value_o: item?.own
          ? item.target_value_o
          : getIndicatorTargetValueFromHrf(initialFormData, item, existIndicator),
      });
    });
  };

  return (
    <View style={indicatorsTableStyle.tableWrapper}>
      <View style={printStyle.table}>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray" rowSpan={1}>
            <Text style={printStyle.sectionTitle}>
              {label}
            </Text>
          </PrintoutTableCell>
        </PrintoutTableRow>
      </View>
      {mappedIndicatorsData().map((indicator, index) => {
        const previousIndicator = previousIndicatorsData[getIndexForPreviousRowData(
          indicatorsData,
          indicator.id,
          'id'
        )];

        const additionalStyles = indicator.status in printStyle ? {
          ...printStyle[indicator.status],
          padding: 10,
          marginBottom: 10,
          marginTop: 10,
        } : {};

        return (
          <View style={additionalStyles}>
            {indicator.own ? (
              <OwnIndicatorRowPdf
                key={indicator.id}
                name={name}
                index={index}
                indicator={indicator}
                previousIndicator={previousIndicator}
              />
            ) : (
              <IndicatorRowPdf
                key={indicator.id}
                name={name}
                index={index}
                indicator={indicator}
                previousIndicator={previousIndicator}
              />
            )}
          </View>
        );
      })}
      {indicatorsData.length === 0 && (
        <View style={printStyle.table}>
          <PrintoutTableRow>
            <PrintoutTableCell rowSpan={1}>
              Brak wskaźników.
            </PrintoutTableCell>
          </PrintoutTableRow>
        </View>
      )}
    </View>
  );
}

export default IndicatorsTablePdf;

IndicatorsTablePdf.propTypes = {
  name: PropTypes.string.isRequired,
  indicatorsData: PropTypes.arrayOf(Object).isRequired,
  label: PropTypes.string.isRequired,
};
