import {
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns';
import { formatDateString } from '../../../../_helpers';
import { REGEX } from '../../../../_constants';

const getRequiredValidation = (fieldName, rule) => ({
  required: {
    value: rule.enabled,
    message: rule?.options?.message || `Proszę uzupełnić pole "${fieldName}"`,
  },
});

const getDateAfterValidation = (fieldName, rule) => ({
  validate: {
    ifAfter: (dateValue) => isAfter(parseISO(rule?.options?.limit), dateValue)
      || `Data w polu "${fieldName}" nie może być późniejsza
      niż ${formatDateString(parseISO(rule?.options?.limit))}.`,
  },
});

const getDateBeforeValidation = (fieldName, rule) => ({
  validate: {
    ifBefore: (dateValue) => isBefore(parseISO(rule?.options?.limit), dateValue)
      || `Data w polu "${fieldName}" nie może być wcześniejsza
      niż ${formatDateString(parseISO(rule?.options?.limit))}.`,
  },
});

const getNipValidation = () => ({
  pattern: REGEX.nip,
});

const getMaxLengthValidation = (fieldName, rule) => ({
  maxLength: {
    value: rule?.options?.limit,
    message: `Maksymalna długość znaków w polu "${fieldName}" została przekroczona. 
    Maksymalna ilość znaków to ${rule?.options?.limit}.`,
  },
});

const getMinLengthValidation = (fieldName, rule) => ({
  minLength: {
    value: rule?.options?.limit,
    message: `Wpisana treść w polu ${fieldName} jest za krótka. Minimalna ilość znaków to ${rule?.options?.limit}.`,
  },
});

const getEmailValidation = () => ({
  pattern: REGEX.email,
});

const getMinValueValidation = (fieldName, rule) => ({
  min: {
    value: rule?.options?.limit,
    message: `Wartość w polu "${fieldName}" nie może być mniejsza niż ${rule?.options?.limit}`,
  },
});

const getMaxValueValidation = (fieldName, rule) => ({
  max: {
    value: rule?.options?.limit,
    message: `Wartość w polu "${fieldName}" nie może być większa niż ${rule?.options?.limit}`,
  },
});

const getRegexpMatch = (_, rule) => ({
  validate: {
    regexpMatch: (value) => (new RegExp(rule?.options?.pattern)).test(value) || rule?.options?.message,
  },
});

const getNoRegexpMatch = (_, rule) => ({
  pattern: {
    value: new RegExp(rule?.options?.pattern),
    message: rule?.options?.message,
  },
});

export const VALIDATION_TYPES = {
  required: getRequiredValidation,
  date_after: getDateAfterValidation,
  date_before: getDateBeforeValidation,
  max_length_auto: getMaxLengthValidation,
  nip: getNipValidation,
  min_length: getMinLengthValidation,
  max_length: getMaxLengthValidation,
  email: getEmailValidation,
  min_number: getMinValueValidation,
  max_number: getMaxValueValidation,
  regexp_match: getRegexpMatch,
  no_regexp_match: getNoRegexpMatch,
};
