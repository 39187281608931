import {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/**
 * Create form field based on type.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {string} props.width - sticky element width
 * @param {string} props.top - sticky element distance from the top of the window
 * @param {string} props.bottom - sticky element distance from the bottom of the window
 * @param {string} props.left - sticky element distance from the left side of the window
 * @param {string} props.right - sticky element distance from the right side of the window
 * @param {string} props.position - position of element
 * @param {number} props.zIndex - z-index of element
 * @returns {Sticky}
 */
export default function Sticky({
  children, width, top, bottom, left, right, position, zIndex,
}) {
  const [isSticky, setSticky] = useState(false);
  const [rectWidth, setRectWidth] = useState(0);
  const ref = useRef(null);

  const classes = useMemo(() => ({
    stickyWrapper: {
      position: 'relative',
    },
    stickyInner: {
      position,
      top,
      bottom,
      left,
      right,
      zIndex,
      width,
      maxWidth: width === 'inherit' ? rectWidth : 'inherit',
      minWidth: rectWidth,
    },
  }), [top, bottom, left, right, width, rectWidth, position]);

  const handleResize = () => {
    if (ref && ref.current && ref.current.getBoundingClientRect()) {
      setRectWidth(ref.current.getBoundingClientRect().width);
    }
  };

  const handleScroll = () => {
    if (ref?.current?.getBoundingClientRect()) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSticky === true) {
      setRectWidth(ref.current.getBoundingClientRect().width);
    }
  }, [isSticky]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', () => handleResize);
    };
  }, []);

  return (
    <Box ref={ref} sx={classes.stickyWrapper}>
      <Box sx={isSticky ? classes.stickyInner : ''}>
        {children}
      </Box>
    </Box>
  );
}

Sticky.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  position: PropTypes.string,
  zIndex: PropTypes.number,
};

Sticky.defaultProps = {
  width: 'inherit',
  top: 'none',
  bottom: 'none',
  left: 'none',
  right: 'none',
  position: 'fixed',
  zIndex: 10,
};
