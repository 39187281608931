import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getChangesInField,
  getIndexForPreviousRowData,
  getRemovedRowData,
  numberWithSeparator,
} from '../../../../../_helpers';

/**
 * ExpensesSummary Table Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.elementId - element id
 * @param {string} props.expensesSummaryTitle - title of expenses summary table
 * @returns {ExpensesSummaryTablePdf}
 */
function ExpensesSummaryTablePdf({
  elementId, expensesSummaryTitle,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const getExpensesSummaryByKey = (data, key) => data[key] ?? {};

  const currentExpensesSummaryData = getExpensesSummaryByKey(initialFormData, `hrf_element_summary-${elementId}`);
  const previousExpensesSummaryData = getExpensesSummaryByKey(previousInitialFormData, `hrf_element_summary-${elementId}`);

  const addedExpensesSummary = getAddedRowData(
    currentExpensesSummaryData.items,
    previousExpensesSummaryData.items,
    'id'
  ).map((categoryGroup) => {
    const previousGroupData = getIndexForPreviousRowData(
      previousExpensesSummaryData.items,
      categoryGroup.id,
      'id'
    ) !== -1
      ? previousExpensesSummaryData.items[
        getIndexForPreviousRowData(previousExpensesSummaryData.items, categoryGroup.id, 'id')
      ]
      : [];

    return ({
      ...categoryGroup,
      items: [
        ...getAddedRowData(categoryGroup.items, previousGroupData?.items || [], 'id'),
        ...getRemovedRowData(categoryGroup.items, previousGroupData?.items || [], 'id'),
      ],
    });
  });

  const removedExpensesSummary = getRemovedRowData(
    currentExpensesSummaryData.items,
    previousExpensesSummaryData.items,
    'id'
  );

  const getChangesInFieldWithMoneySeparator = (oldValue, newValue) => getChangesInField(
    numberWithSeparator(oldValue),
    numberWithSeparator(newValue)
  );

  const getValue = (oldValue, newValue) => (oldValue
    ? getChangesInFieldWithMoneySeparator(oldValue, newValue) : newValue);

  const expensesSummaryData = withChanges
    ? {
      ...currentExpensesSummaryData,
      items: [...addedExpensesSummary, ...removedExpensesSummary],
    }
    : currentExpensesSummaryData;

  if (Object.values(expensesSummaryData).length === 0 && !expensesSummaryData?.items) {
    return '';
  }

  return (
    <>
      <Text style={printStyle.sectionTitle}>
        {expensesSummaryTitle}
      </Text>
      <View style={printStyle.table}>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray">Grupa kategorii</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray">Kategoria Kosztu</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray">Wydatki ogółem</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray">Wydatki kwalifikowane</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray">Dofinansowanie</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray">% dofinansowania</PrintoutTableCell>
        </PrintoutTableRow>

        {expensesSummaryData.items.map((group) => {
          const previousGroupRow = getIndexForPreviousRowData(previousExpensesSummaryData.items, group.id, 'id') !== -1
            ? previousExpensesSummaryData.items[
              getIndexForPreviousRowData(previousExpensesSummaryData.items, group.id, 'id')
            ] : [];

          return (
            <>
              {group.items.map((expense, expenseIndex) => {
                const previousExpenseRow = getIndexForPreviousRowData(previousGroupRow.items, expense.id, 'id') !== -1
                  ? previousGroupRow.items[
                    getIndexForPreviousRowData(previousGroupRow.items, expense.id, 'id')
                  ] : [];

                return (
                  <PrintoutTableRow>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {expenseIndex === 0 ? group.name : ''}
                    </PrintoutTableCell>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {getChangesInField(previousExpenseRow?.name, expense.name)}
                    </PrintoutTableCell>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {getValue(previousExpenseRow?.total_expenses, expense.total_expenses)}
                    </PrintoutTableCell>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {getValue(
                        previousExpenseRow?.eligible_expenses,
                        expense.eligible_expenses
                      )}
                    </PrintoutTableCell>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {getValue(previousExpenseRow?.co_financing, expense.co_financing)}
                    </PrintoutTableCell>
                    <PrintoutTableCell bgColor={group?.status || expense?.status || 'white'}>
                      {getValue(
                        previousExpenseRow?.co_financing_percent,
                        expense.co_financing_percent
                      )}
                    </PrintoutTableCell>
                  </PrintoutTableRow>
                );
              })}
              <PrintoutTableRow>
                <PrintoutTableCell rowSpan={1} bgColor={group?.status || 'gray'}>
                  Suma ( dla każdej grupy )
                </PrintoutTableCell>
                <PrintoutTableCell bgColor={group?.status || 'white'}>
                  {getValue(previousGroupRow?.total_expenses, group.total_expenses)}
                </PrintoutTableCell>
                <PrintoutTableCell bgColor={group?.status || 'white'}>
                  {getValue(previousGroupRow?.eligible_expenses, group.eligible_expenses)}
                </PrintoutTableCell>
                <PrintoutTableCell bgColor={group?.status || 'white'}>
                  {getValue(previousGroupRow?.co_financing, group.co_financing)}
                </PrintoutTableCell>
                <PrintoutTableCell bgColor={group?.status || 'white'}>
                  {getValue(
                    previousGroupRow?.co_financing_percent,
                    group.co_financing_percent
                  )}
                </PrintoutTableCell>
              </PrintoutTableRow>
            </>
          );
        })}
        <PrintoutTableRow>
          <PrintoutTableCell rowSpan={1} bgColor="gray">Suma wydatków</PrintoutTableCell>
          <PrintoutTableCell>
            {getValue(
              previousExpensesSummaryData?.total_expenses,
              expensesSummaryData.total_expenses
            )}
          </PrintoutTableCell>
          <PrintoutTableCell>
            {getValue(
              previousExpensesSummaryData?.eligible_expenses,
              expensesSummaryData.eligible_expenses
            )}
          </PrintoutTableCell>
          <PrintoutTableCell>
            {getValue(
              previousExpensesSummaryData?.co_financing,
              expensesSummaryData.co_financing
            )}
          </PrintoutTableCell>
          <PrintoutTableCell>
            {getValue(
              previousExpensesSummaryData?.co_financing_percent,
              expensesSummaryData.co_financing_percent
            )}
          </PrintoutTableCell>
        </PrintoutTableRow>
      </View>
    </>
  );
}

export default ExpensesSummaryTablePdf;

ExpensesSummaryTablePdf.propTypes = {
  elementId: PropTypes.string.isRequired,
  expensesSummaryTitle: PropTypes.string.isRequired,
};
