import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../PrintToPdf/print.style';
import PrintoutTableCell from '../../../PrintToPdf/Components/Table/PrintoutTableCell';
import PrintoutTableRow from '../../../PrintToPdf/Components/Table/PrintoutTableRow';
import QuestionsList from './QuestionsList';

/**
 * Printout supplement call with modules.
 *
 * @param {object} props - root props
 * @param {Array} props.modules - modules
 * @returns {PrintoutSupplementCallWithModules}
 */
function PrintoutSupplementCallWithModules({
  modules,
}) {
  return (
    <>
      {modules.map(({
        moduleId, moduleName, evaluationItems,
      }) => (
        <View key={moduleId}>
          <Text style={printStyle.sectionTitle}>{`Moduł: ${moduleName}`}</Text>
          <View style={printStyle.table}>
            {evaluationItems.map(({
              evaluationItemId, evaluationItemName, questions,
            }) => (
              <>
                <PrintoutTableRow key={evaluationItemId}>
                  <PrintoutTableCell rowSpan={1} bgColor="dkGray" isTextBold>
                    <Text style={printStyle.subSectionTitle}>
                      {`Nazwa kryterium: ${evaluationItemName}`}
                    </Text>
                  </PrintoutTableCell>
                </PrintoutTableRow>
                <QuestionsList questions={questions} />
              </>
            ))}
          </View>
        </View>
      ))}
    </>
  );
}

export default PrintoutSupplementCallWithModules;

PrintoutSupplementCallWithModules.propTypes = {
  modules: PropTypes.arrayOf(Object).isRequired,
};
