import {
  useState,
  useEffect,
} from 'react';
import {
  Grid,
  Link,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { API_ROUTE } from '../_constants';
import { useAuth } from '../_security';
import { request } from '../_services';
import { ContainerLoader } from './Application/Application/ContainerLoader';
import { homeCardsStyleClasses } from '../styles/homeCards';
import { theme } from '../styles/theme';

const userGuideClasses = {
  card: {
    width: theme.spacing(62.5),
    height: theme.spacing(25),
    background: 'white',
    transition: 'box-shadow .2s',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(25),
    width: '100%',
    overflow: 'hidden',
    fontSize: '1.2rem',
    lineHeight: 1,
    color: '#757575',
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  link: {
    color: '#00749A',
    textAlign: 'right',
    fontSize: '0.7rem',
    padding: theme.spacing(0, 2, 1),
  },
};

/**
 * User guide cards.
 *
 * @returns {UserGuideCards}
 */
export function UserGuideCards() {
  const commonCardClasses = homeCardsStyleClasses;
  const { profile } = useAuth();
  const [cards, setCards] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    loadCards();
  }, []);

  const loadCards = async () => {
    const {
      payload, statusSuccess, statusCode,
    } = profile === null
      ? await request.get(`${API_ROUTE.publicHelpCenter}?order[ordinalNumber]=asc`)
      : await request.get(`${API_ROUTE.privateHelpCenter}?visible_no[]=${profile}&order[ordinalNumber]=asc`);

    setLoaded(true);
    if (!statusSuccess) {
      if (statusCode === StatusCodes.FORBIDDEN) {
        setErrorMessage('Brak dostępu do zasobu');
      }

      return;
    }
    setCards(payload);
  };

  if (!loaded) {
    return <ContainerLoader />;
  }

  return (
    <Grid container justifyContent="center" marginBottom={5}>
      <Grid item xs={12} xl={8}>
        <Divider sx={commonCardClasses.overHeading} />
        <Typography variant="pageHeading" sx={commonCardClasses.sectionHeading}>Centrum pomocy</Typography>
        <Grid
          container
          sx={commonCardClasses.cards}
          direction="row"
          justifyContent="space-evenly"
        >
          {errorMessage || cards.map((card) => (
            <Grid
              item
              xs={3}
              sx={{
                ...commonCardClasses.card,
                ...userGuideClasses.card,
              }}
            >
              <Link
                href={card.articleUrl}
                target="_blank"
                style={{ textDecoration: 'none' }}
                rel="noreferrer"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                >
                  <div
                    style={{
                      ...commonCardClasses.cardNameBlock,
                      ...userGuideClasses.titleBlock,
                    }}
                  >
                    {card.title}
                  </div>
                  <div
                    style={userGuideClasses.link}
                  >
                    <span>
                      Kliknij, żeby dowiedzieć się więcej...
                    </span>
                  </div>
                </Box>
              </Link>
            </Grid>
          ))}
          {!errorMessage && cards.length === 0 && 'Brak danych'}
        </Grid>
      </Grid>
    </Grid>
  );
}
