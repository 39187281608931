import PropTypes from 'prop-types';
import {
  createRef,
  useEffect,
  useMemo,
} from 'react';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Multiple select filter for MUI Data Grid.
 *
 * @param {object} props - root props
 * @param {object} props.columnData - grid column data
 * @param {Function} props.applyFilter - apply filter function
 * @param {number} props.cleanListener - on change this number, input value should be restored to default
 * @param {string} props.id - id forwarded to input component
 * @param {Array} props.initialValue - initial value
 * @returns {GridMultipleSelectFilter}
 */
export function GridMultipleSelectFilter({
  columnData, applyFilter, cleanListener, id, initialValue,
}) {
  const {
    field, valueOptions,
  } = columnData;
  const ref = useMemo(() => createRef(), []);

  useEffect(() => {
    if (cleanListener !== 0) {
      ref.current.clear();
    }
  }, [cleanListener, ref]);

  return (
    <CustomAutocomplete
      id={id}
      ref={ref}
      initialValue={initialValue}
      multiple
      textFieldProps={{ variant: 'standard' }}
      renderSelectAllButton={false}
      options={valueOptions}
      optionsMapKeys={['id', 'label']}
      onChange={(event) => {
        applyFilter(field, event?.target?.value);
      }}
    />
  );
}

GridMultipleSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  columnData: PropTypes.instanceOf(Object).isRequired,
  applyFilter: PropTypes.func.isRequired,
  cleanListener: PropTypes.number.isRequired,
  initialValue: PropTypes.arrayOf(Object),
};

GridMultipleSelectFilter.defaultProps = {
  initialValue: [],
};
