/**
 * Trim text to expected length and add 3 dots at end.
 *
 * @param {string} text - input text
 * @param {number} maxLength - max string length
 * @returns {string} trimed string
 */
export const trim = (text, maxLength = 50) => {
  if (text.length < maxLength) {
    return text;
  }

  return `${text.slice(0, maxLength)}...`;
};
