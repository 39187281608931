/**
 * Contract statuses.
 *
 * @type {object}
 */
export const CONTRACT_STATUSES = {
  preparation: 'preparation',
  sentToExpert: 'sent_to_expert',
  sentToParp: 'sent_to_parp',
  signed: 'signed',
  termination: 'termination',
};

/**
 * Contract statuses translations.
 *
 * @type {object}
 */
export const CONTRACT_STATUSES_TRANSLATIONS = {
  [CONTRACT_STATUSES.preparation]: 'w przygotowaniu',
  [CONTRACT_STATUSES.sentToExpert]: 'wysłana do eksperta',
  [CONTRACT_STATUSES.sentToParp]: 'wysłana do PARP',
  [CONTRACT_STATUSES.signed]: 'zawarta',
  [CONTRACT_STATUSES.termination]: 'rozwiązana',
};
