import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
  Box,
} from '@mui/material';
import {
  API_ROUTE,
  APPLICATION_CONTEXT_HELP_TEXT,
} from '../../../_constants';
import { request } from '../../../_services';
import CharsCounter from '../../CharsCounter';
import {
  useExpertApplicationFormContext,
  useGlobalContext,
} from '../../../Context';
import { ApplicationFormAttachmentsField } from './ApplicationFormAttachmentsField';
import InfoPopover from '../../InfoPopover/InfoPopover';
import { handleError } from '../../../_helpers';

/**
 * Financial expert data form for expert application
 *
 * @returns {FinancialExpertForm}
 */
export function FinancialExpertForm() {
  const {
    applicationFormData, isReadonly, reloadData,
  } = useExpertApplicationFormContext();
  const { notify } = useGlobalContext();

  const {
    control, handleSubmit, setError,
  } = useFormContext();

  const submitForm = async (formData) => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.applicationForms}/${applicationFormData.id}`, {
      financialJustification: formData.financialJustification,
    });

    if (statusSuccess) {
      reloadData();
      notify('Wprowadzono dane eksperta finansowego', 'success');
      window.scroll(0, 0);

      return;
    }

    handleError(setError, violations);
    notify('Wystąpił błąd podczas zapisu eksperta finansowego', 'error');
  };

  return (
    <>
      <InputLabel
        htmlFor="financialJustification.universityDegree"
        variant="noShorten"
        sx={{
          textAlign: 'left',
          fontSize: '1rem',
          mt: (t) => t.spacing(3),
        }}
      >
        Kandydat na eksperta posiada wykształcenie wyższe (co najmniej uzyskany tytuł
        mgr, mgr inż. lub równoważny w zakresie ekonomii lub finansów lub
        rachunkowości lub zarządzania)
      </InputLabel>
      <Box display="flex" mt={1}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="financialJustification.universityDegree"
            control={control}
            render={({
              field: {
                onChange, value, name,
                onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                placeholder="Uzasadnienie"
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                }}
                inputProps={{
                  maxLength: 10000,
                }}
                multiline
              />
            )}
          />
        </FormControl>
        <InfoPopover>
          {APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalent}
        </InfoPopover>
      </Box>

      <ApplicationFormAttachmentsField
        infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalentAttachments}
        fieldName="financialJustification.universityDegreeAttachments"
      />

      <InputLabel
        htmlFor="financialJustification.threeYearsOfExperience"
        variant="noShorten"
        sx={{
          textAlign: 'left',
          fontSize: '1rem',
          mt: (t) => t.spacing(3),
        }}
      >
        Kandydat na eksperta posiada co najmniej 3-letnie (w okresie ostatnich 5-lat) doświadczenie zawodowe
        (niezależnie od formy zatrudnienia) potwierdzające jego wiedzę w obszarze rachunkowości lub analizy
        finansowej przedsiębiorstw w zakresie pozwalającym na dokonywanie oceny opłacalności projektów innowacyjnych
        lub oceny kondycji finansowej
      </InputLabel>
      <Box display="flex" mt={1}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="financialJustification.threeYearsOfExperience"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                placeholder="Uzasadnienie"
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                }}
                inputProps={{
                  maxLength: 10000,
                }}
                multiline
              />
            )}
          />
        </FormControl>
        <InfoPopover>
          {APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperience}
        </InfoPopover>
      </Box>

      <ApplicationFormAttachmentsField
        infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperienceAttachments}
        fieldName="financialJustification.threeYearsOfExperienceAttachments"
      />

      {!isReadonly && (
        <Button
          id="0QLYoijmTUvg2uF"
          variant="contained"
          color="secondary"
          sx={{
            display: 'block',
            marginRight: 'auto',
            mt: (t) => t.spacing(5),
          }}
          onClick={handleSubmit(submitForm)}
        >
          Zapisz
        </Button>
      )}
    </>
  );
}
