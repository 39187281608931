import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';

/**
 * GozTransformationImplementation pdf element.
 *
 * @returns {GozTransformationImplementationPdf}
 */
function GozTransformationImplementationPdf() {
  return (
    <>
      {/* Tabela: Ścieżka działania ujęta w schemacie ReSOLVE */}
      <CollectionWrapper name="goz_transformation_implementation_action_path">
        {({
          collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
        }) => collectionData.map((item, index) => {
          const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

          return (
            <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
              <View style={printStyle.table}>
                <RowField
                  name={`${collectionName}.${index}.action_path`}
                  value={item.action_path}
                  oldValue={previousRowData?.action_path}
                  dictionaryName={DICTIONARIES.gozTransformationImplementationActionPath.name}
                />
                <RowField
                  name={`${collectionName}.${index}.justification_indicated_path`}
                  value={item.justification_indicated_path}
                  oldValue={previousRowData?.justification_indicated_path}
                  isSingleRow
                />
              </View>
            </SingleCollectionWrapper>
          );
        })}
      </CollectionWrapper>
      {/* Tabela: Procesy cyklu życia produktu (wyrobu/usługi) */}
      <CollectionWrapper name="goz_transformation_implementation_product_life_cycle_processes">
        {({
          collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
        }) => collectionData.map((item, index) => {
          const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

          return (
            <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
              <View style={printStyle.table}>
                <RowField
                  name={`${collectionName}.${index}.product_life_cycle_processes`}
                  value={item.product_life_cycle_processes}
                  oldValue={previousRowData?.product_life_cycle_processes}
                  dictionaryName={DICTIONARIES.gozTransformationImplementationProductProcess.name}
                />
                <RowField
                  name={`${collectionName}.${index}.justification_selected_process`}
                  value={item.justification_selected_process}
                  oldValue={previousRowData?.justification_selected_process}
                  isSingleRow
                />
              </View>
            </SingleCollectionWrapper>
          );
        })}
      </CollectionWrapper>
    </>
  );
}

export default GozTransformationImplementationPdf;
