import { API_ROUTE } from '../_constants';
// eslint-disable-next-line import/no-cycle
import { request } from '../_services';

export const getNewSystemMessages = async (loggedUserId, onSuccess, onlyNewMessages = false) => {
  const getSystemMessages = async (userMessages) => {
    const {
      statusSuccess, payload,
    } = await request.get(API_ROUTE.messagesByProfile);

    if (statusSuccess) {
      let mappedMessages = payload.map((item) => {
        const userMessage = userMessages.find(({ message }) => message === item['@id']);

        return ({
          ...item,
          readAt: userMessage?.readAt || null,
          approvedAt: userMessage?.approvedAt || null,
          userMessageId: userMessage?.id || null,
        });
      }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      if (onlyNewMessages) {
        mappedMessages = mappedMessages.filter(({ readAt }) => !readAt);
      }

      return onSuccess(mappedMessages);
    }

    return [];
  };

  const {
    statusSuccess, payload,
  } = await request.get(`${API_ROUTE.userMessages}?user.id=${loggedUserId}`);

  if (statusSuccess) {
    await getSystemMessages(payload);
  }
};
