import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { darken } from '@mui/material/styles';
import {
  API_ROUTE,
  CHARS_LIMIT,
  REGEX,
} from '../../_constants';
import { request } from '../../_services';
import CharsCounter from '../CharsCounter';
import { Captcha } from '../../_security';
import { useGlobalContext } from '../../Context';
import { handleError } from '../../_helpers';
import { theme } from '../../styles/theme';

const classes = {
  inputContainer: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-adornedEnd fieldset': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.brandGray.main,
    },
  },
  mainForm: {
    backgroundColor: '#f2f2f2',
    padding: theme.spacing(10),
  },
  boxTitle: {
    color: darken(theme.palette.primary.main, 0.25),
    fontSize: '1.5rem',
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
  },
  boxSubtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontSize: '1.5rem',
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  divider: {
    height: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  saveButton: {
    marginTop: theme.spacing(1),
    fontSize: '1.3em',
    textTransform: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentDivider: {
    margin: `${theme.spacing(1)} 43% ${theme.spacing(3)}`,
    height: '4px',
    backgroundColor: theme.palette.primary.main,
  },
};

/**
 * Contact form for unauthorized users.
 *
 * @param {object} props root props
 * @param {string} props.ariaLabelledBy - aria-labelledby value
 * @param {Function} props.onSuccess - function to be invoked on request success response
 * @returns {ContactForm}
 */
export function ContactForm({
  ariaLabelledBy, onSuccess,
}) {
  const { notify } = useGlobalContext();

  const {
    control, handleSubmit, formState: { isSubmitting }, setError,
  } = useForm({
    defaultValues: {
      fullName: '',
      email: '',
      phoneNumber: '',
      subject: '',
      description: '',
      termsOfConditions: false,
      reCaptcha: '',
    },
    mode: 'all',
  });

  /**
   * Handle submit.
   *
   * @param {object} formData - form data
   */
  const onSubmit = async (formData) => {
    const {
      statusSuccess,
      payload,
      violations,
    } = await request.post(
      API_ROUTE.publicIssues,
      {
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        subject: formData.subject,
        description: formData.description,
        reCaptcha: formData.reCaptcha,
      }
    );

    if (statusSuccess) {
      notify(payload.message, 'success');
      onSuccess();

      return;
    }

    handleError(setError, violations);

    if (!statusSuccess && payload && payload.message) {
      notify(payload.message, 'error');

      return;
    }

    notify('Nie udało się wysłać zgłoszenia', 'error');
  };

  return (
    <div aria-labelledby={ariaLabelledBy}>
      <Divider sx={classes.divider} />
      <Grow in>
        <Grid container sx={classes.content}>
          <Grid item xs={false} md={2} lg={3} />
          <Grid item xs={12} md={8} lg={6} sx={classes.mainForm}>
            <div style={classes.titleContainer}>
              <Typography
                variant="h2"
                component="h5"
                sx={classes.boxTitle}
              >
                Formularz kontaktowy
              </Typography>
              <span style={classes.boxSubtitle}>Napisz do nas</span>
            </div>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Imię i nazwisko"',
                  },
                }}
                name="fullName"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Imię i Nazwisko"
                    variant="outlined"
                    value={value}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      'aria-labelledby': 'fullName',
                      'aria-live': 'assertive',
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "E-mail"',
                  },
                  pattern: REGEX.email,
                }}
                name="email"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="E-mail"
                    variant="outlined"
                    value={value}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      'aria-labelledby': 'email',
                      'aria-live': 'assertive',
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Telefon"',
                  },
                }}
                name="phoneNumber"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Telefon"
                    variant="outlined"
                    value={value}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      'aria-labelledby': 'phoneNumber',
                      'aria-live': 'assertive',
                    }}
                  />
                )}
              />
            </FormControl>
            <Divider sx={classes.contentDivider} />
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Temat zgłoszenia"',
                  },
                }}
                name="subject"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Temat zgłoszenia"
                    variant="outlined"
                    value={value}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.LONG }}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      'aria-labelledby': 'subject',
                      'aria-live': 'assertive',
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Opis"',
                  },
                }}
                name="description"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Opis"
                    variant="outlined"
                    multiline
                    rows={5}
                    value={value}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.STORY} />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.STORY }}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={error?.message}
                    FormHelperTextProps={{
                      'aria-labelledby': 'description',
                      'aria-live': 'assertive',
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  validate: {
                    isTrue: (value) => value === true || 'Proszę zaakceptować regulamin.',
                  },
                }}
                name="termsOfConditions"
                control={control}
                render={({
                  field: {
                    onChange,
                    value,
                    name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          id={name}
                          name={name}
                          color="secondary"
                          onBlur={onBlur}
                        />
                      )}
                      label={(
                        <span>
                          Zapoznałem się z
                          {' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.parp.gov.pl/component/site/site/regulamin-ochrony-danych-osobowych"
                          >
                            Regulaminem ochrony danych osobowych
                          </a>
                        </span>
                      )}
                    />
                    {!!error && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę zaakceptować reCaptcha.',
                  },
                }}
                name="reCaptcha"
                control={control}
                render={({
                  field: {
                    onChange,
                    name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <>
                    <Captcha
                      id={name}
                      name={name}
                      onChange={onChange}
                    />
                    {!!error && (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </FormControl>
            <div style={classes.buttonWrapper}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={classes.saveButton}
                color="primary"
                onClick={handleSubmit(onSubmit)}
                id="zLa2yCvSkw5P4Og"
              >
                Wyślij
              </Button>
            </div>
          </Grid>
          <Grid item xs={false} md={2} lg={3} />
        </Grid>
      </Grow>
    </div>
  );
}

ContactForm.propTypes = {
  ariaLabelledBy: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

ContactForm.defaultProps = {
  onSuccess: () => {},
};
