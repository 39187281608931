import { URL } from './URL';
import { PROFILES } from './profiles';

export const breadCrumbsStructure = {
  [PROFILES.employee]: {
    [URL.recruitment.add]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Dodaj nabór',
      },
    ],
    [URL.recruitment.edit]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Edycja naboru',
      },
    ],
    [URL.recruitment.list]: [
      {
        path: null,
        name: 'Lista naborów',
      },
    ],
    [URL.recruitment.projectAppraisalCommission]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: URL.recruitment.edit,
        name: 'Edycja naboru',
      },
      {
        path: null,
        name: 'Lista KOP',
      },
    ],
    [URL.project.card]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: URL.recruitment.submittedApplications,
        name: 'Lista złożonych wniosków',
      },
      {
        path: null,
        name: 'Karta projektu',
      },
    ],
    [URL.recruitment.expertPanelsList]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Lista paneli',
      },
    ],
    [URL.recruitment.expertPanelApplicationsList]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: URL.recruitment.expertPanelsList,
        name: 'Lista paneli',
      },
      {
        path: null,
        name: 'Lista wniosków w panelu',
      },
    ],
    [URL.recruitment.submittedApplications]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Lista złożonych wniosków',
      },
    ],
    [URL.recruitment.submittedApplicationsByRecruitmentId]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: URL.recruitment.submittedApplications,
        name: 'Lista złożonych wniosków',
      },
      {
        path: null,
        name: 'Lista złożonych wniosków w wybranym naborze',
      },
    ],
    [URL.recruitment.applicationStatusManagement]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Zmiana statusów wniosków',
      },
    ],
    [URL.recruitment.applicationTemplate.edit]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: URL.recruitment.edit,
        name: 'Edycja naboru',
      },
      {
        path: null,
        name: 'Edycja wzoru wniosku',
      },
    ],
    [URL.recruitment.applicationTemplate.add]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Dodaj wzór wniosku',
      },
    ],
    [URL.recruitment.shareEvaluationSheet]: [
      {
        path: URL.recruitment.list,
        name: 'Lista naborów',
      },
      {
        path: null,
        name: 'Udostępnianie arkuszy ocen wnioskodawcy',
      },
    ],
  },
  [PROFILES.applicant]: {
    [URL.recruitment.ongoing]: [
      {
        path: null,
        name: 'Trwające nabory',
      },
    ],
  },
  [URL.applicationPrints]: [
    {
      path: URL.applicationPrints,
      name: 'Wydruki',
    },
  ],
};
