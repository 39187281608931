import {
  Button,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  useState,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../../../Context';
import { request } from '../../../../../_services';
import { useCustomSnackbar } from '../../../../../_hooks';
import { getImplementersAndApplicantName } from '../../../../../_helpers';
import {
  API_ROUTE,
  BOOLEAN_VALUES,
  EXPENSES_TYPE,
} from '../../../../../_constants';
import { ConfirmDialog } from '../../../../../Dialogs';
import ExpensesDialog from './Expenses.dialog';
import { useStylesDataGridTable } from '../../../../../styles/dataGridTable';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';

/**
 * Flat rate expenses table component
 *
 * @param {object} props - root props
 * @param {string} props.taskId - task id
 * @param {string} props.taskName - task name
 * @param {Array} props.expensesData - expenses data
 * @param {Function} props.reloadExpensesData - reload data handler
 * @param {string} props.taskType - task type
 * @param {boolean} props.indirectCosts - indirect costs
 * @param {Array} props.costCategoryForElement - costCategoryForElement data from api
 * @param {Array} props.lumpSumCostNames - props.lumpSumCostNames data from api
 * @returns {FlatRateExpensesTable}
 */
function FlatRateExpensesTable({
  taskId,
  expensesData,
  reloadExpensesData,
  taskName,
  taskType,
  indirectCosts,
  costCategoryForElement,
  lumpSumCostNames,
}) {
  const [removeDialog, setRemoveDialog] = useState(null);
  const [editDialog, setEditDialog] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const { id: applicationId } = useParams();
  const {
    getValues, control,
  } = useFormContext();
  const {
    id: elementId, isReadonly,
  } = useElementContext();

  const {
    remove,
  } = useFieldArray({
    control,
    name: `lump_sum_expenses-${elementId}`,
  });

  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const removeExpense = async (expenseId) => {
    setSubmitting(true);

    const expenseIndex = getValues(`lump_sum_expenses-${elementId}`)
      ?.findIndex(({ lump_sum_expense_id: lumpSumExpenseId }) => lumpSumExpenseId === expenseId);
    const { statusSuccess } = await request.delete(`${API_ROUTE.applicationExpenses
      .replace(':applicationId', applicationId)
      .replace(':elementId', elementId)}/lump-sum-expenses/${expenseId}`);

    setRemoveDialog(null);

    statusSuccess
      ? successNotification('Wydatek został usunięty.')
      : errorNotification('Nie udało się usunąć wydatku. Spróbuj ponownie.');
    if (!statusSuccess) {
      return;
    }

    remove(expenseIndex);
    reloadExpensesData(elementId, EXPENSES_TYPE.flatRateExpenses);
    setSubmitting(false);
  };

  /**
   * Returns row actions
   *
   * @param {object} props - root props
   * @param {object} props.row - element data
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => [
    <Button
      id={uniqid()}
      type="button"
      variant="contained"
      color="secondary"
      onClick={() => setEditDialog(
        <ExpensesDialog
          handleClose={() => setEditDialog(null)}
          isOpen
          taskName={taskName}
          taskType={taskType}
          indirectCosts={indirectCosts}
          itemIndex={expensesData.findIndex((expense) => expense.lump_sum_expense_id === row.lump_sum_expense_id)
            .toString()}
          reloadExpensesData={reloadExpensesData}
          expenseType={EXPENSES_TYPE.flatRateExpenses}
          taskId={row.task_id}
        />
      )}
    >
      {isReadonly ? 'Podgląd' : 'Edytuj'}
    </Button>,
    <>
      {!isReadonly && (
        <Button
          id={uniqid()}
          type="button"
          variant="outlined"
          onClick={() => {
            setRemoveDialog(
              <ConfirmDialog
                closeHandler={() => setRemoveDialog(null)}
                open
                confirmButtonHandler={() => removeExpense(row.lump_sum_expense_id)}
                cancelButtonColor="primary"
              >
                {`Czy na pewno chcesz usunąć wydatek "${row.cost_name_in_words}"?`}
              </ConfirmDialog>
            );
          }}
        >
          Usuń
        </Button>
      )}
    </>,
  ];

  const columns = [
    {
      field: 'cost_name_in_words',
      headerName: 'Nazwa kosztu',
      flex: 1.5,
      renderCell: ({ row }) => {
        if (row?.cost_name_in_words) {
          return row?.cost_name_in_words;
        }

        return lumpSumCostNames.find((item) => item['@id'] === row?.cost_name_from_dictionary)?.name || '';
      },
    },
    {
      field: 'cost_category',
      headerName: 'Kategoria kosztu',
      valueFormatter: ({ value }) => costCategoryForElement.find((item) => item['@id'] === value)?.name || '',
      flex: 0.8,
    },
    {
      field: 'total_expenses',
      headerName: 'Koszty ogółem',
      flex: 0.6,
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),
    },
    {
      field: 'eligible_expenses',
      headerName: 'Koszty kwalifikowane',
      flex: 0.6,
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),

    },
    {
      field: 'co_financing',
      headerName: 'Dofinansowanie',
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),
      flex: 0.7,
    },
    {
      field: 'co_financing_percent',
      headerName: '% Dofinansowania',
      flex: 0.8,
    },
    {
      field: 'implementer_id',
      headerName: 'Realizator',
      renderCell: ({ row }) => getImplementersAndApplicantName(getValues, applicationId)
        .find((item) => item['@id'] === row?.implementer_id)?.name || '',
      flex: 0.8,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      headerName: 'Akcje',
      width: 200,
      getActions: getRowActions,
    },
  ];

  const filteredExpensesByTaskId = expensesData.filter((field) => field.task_id === taskId);
  const hideImplementersColumn = getValues('has_implementers') !== BOOLEAN_VALUES.TRUE;
  const filteredColumns = columns.filter(({ field }) => !(hideImplementersColumn && field === 'implementer_id'));

  const memoizedDataGrid = useMemo(() => (
    <DataGrid
      columns={filteredColumns}
      rows={filteredExpensesByTaskId}
      rowCount={filteredExpensesByTaskId.length || 0}
      autoHeight
      hideFooterPagination
      hideFooter
      getRowId={(row) => row.lump_sum_expense_id}
      sx={useStylesDataGridTable.table}
    />
  ), [filteredExpensesByTaskId, costCategoryForElement]);

  if (filteredExpensesByTaskId.length > 0) {
    return (
      <>
        <Typography variant="pageHeading" textAlign="left">
          Wydatki ryczałtowe
        </Typography>
        {memoizedDataGrid}
        {isSubmitting ? <ContainerLoader /> : removeDialog}
        {editDialog}
      </>
    );
  }

  return '';
}

export default FlatRateExpensesTable;

FlatRateExpensesTable.propTypes = {
  taskId: PropTypes.string.isRequired,
  reloadExpensesData: PropTypes.func.isRequired,
  taskName: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  indirectCosts: PropTypes.bool.isRequired,
  expensesData: PropTypes.arrayOf(Object).isRequired,
  costCategoryForElement: PropTypes.arrayOf(Object).isRequired,
  lumpSumCostNames: PropTypes.arrayOf(Object).isRequired,
};
