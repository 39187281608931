import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * IncomeApplicant pdf element.
 *
 * @returns {ApplicantIncomePdf}
 */
function ApplicantIncomePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const getValue = (value) => {
    if (!isNaN(Number(value))) {
      return Number(value).toFixed(2);
    }

    return '0.00';
  };

  const incomeFromManufacturingOperations = initialFormData
    ?.applicant_income_percentage_sales_income_from_manufacturing_operations;
  const incomeFromServiceBusinessOperations = initialFormData
    ?.applicant_income_percentage_sales_income_from_service_business_operations;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="applicant_income_sales_income_from_last_closed_fiscal_year" />
        <RowField name="applicant_income_production_income_from_last_closed_fiscal_year" />
        <RowField name="applicant_income_service_activities_income_from_last_closed_fiscal_year" />
        <RowField
          name="applicant_income_percentage_sales_income_from_manufacturing_operations"
          value={getValue(incomeFromManufacturingOperations)}
        />
        <RowField
          name="applicant_income_percentage_sales_income_from_service_business_operations"
          value={getValue(incomeFromServiceBusinessOperations)}
        />
      </FieldSorter>
    </View>
  );
}

export default ApplicantIncomePdf;
