import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantPreparedSolution Pdf element.
 *
 * @returns {ApplicantPreparedSolutionPdf}
 */
function ApplicantPreparedSolutionPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="applicant_prepared_solution_name_in_polish" isSingleRow />
        <RowField name="applicant_prepared_solution_name_in_english" isSingleRow />
        <RowField name="applicant_prepared_solution_description_in_polish" isSingleRow />
        <RowField name="applicant_prepared_solution_description_in_english" isSingleRow />
        <RowField
          name="applicant_prepared_solution_industry_affiliation"
          dictionaryName={DICTIONARIES.applicantPreparedSolutionIndustryAffiliation.name}
        />
        <RowField
          name="applicant_prepared_solution_readiness_level"
          dictionaryName={DICTIONARIES.applicantPreparedSolutionReadinessLevels.name}
        />
        <RowField
          name="applicant_prepared_solution_subject"
          dictionaryName={DICTIONARIES.applicantPreparedSolutionSubjects.name}
        />
        <RowField name="applicant_prepared_solution_innovative_nature" isSingleRow />
        <RowField
          name="applicant_prepared_solution_innovation_level"
          dictionaryName={DICTIONARIES.applicantPreparedSolutionInnovationLevel.name}
        />
        <RowField name="applicant_prepared_solution_justification" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ApplicantPreparedSolutionPdf;
