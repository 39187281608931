import { FormControl } from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import FieldWrapper from '../FieldWrapper';
import CustomDesktopDatePicker from '../../../../CustomDesktopDatePicker';

/**
 * Date range validation component
 *
 * @param {object} props - root props
 * @param {number} props.index - validation index
 * @param {object} props.rule - rule data
 * @returns {DateRange}
 */
function DateRange({
  index, rule,
}) {
  const {
    control, watch,
  } = useFormContext();

  const isEnabled = watch(`rules.${index}.enabled`);

  const DATE_LABELS = {
    date_after: 'Maksymalna data <nie później niż>',
    date_before: 'Minimalna data <nie wcześniej niż>',
  };

  return (
    <FieldWrapper name={`rules.${index}.options.limit`} label={DATE_LABELS[rule.name]}>
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Controller
          name={`rules.${index}.options.limit`}
          rules={{
            required: {
              value: isEnabled,
              message: `Proszę uzupełnić pole "${DATE_LABELS[rule.name]}"`,
            },
          }}
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <CustomDesktopDatePicker
              onChange={onChange}
              value={value}
              name={name}
              error={!!error}
              helperText={error?.message}
              ariaLabel={DATE_LABELS[rule.name]}
            />
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
}

export default DateRange;

DateRange.propTypes = {
  index: PropTypes.number.isRequired,
  rule: PropTypes.objectOf(Object).isRequired,
};
