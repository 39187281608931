import { useNavigate } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { theme } from '../styles/theme';

/**
 * Component which allows to navigate back
 *
 * @param {object} props - root props
 * @returns {GoBack}
 */
export function GoBack(props) {
  const navigate = useNavigate();
  const {
    backHandler,
    destination,
    id,
  } = props;

  const navigateBackHandler = () => navigate(-1);

  return (
    <IconButton
      id={id}
      title={destination ? `Powrót do ${destination}` : 'Powrót'}
      onClick={backHandler ?? navigateBackHandler}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
      size="large"
    >
      <BackIcon
        sx={{
          color: theme.palette.error.main,
        }}
        aria-hidden="false"
      />
    </IconButton>
  );
}

GoBack.propTypes = {
  backHandler: PropTypes.func,
  destination: PropTypes.string,
  id: PropTypes.string,
};

GoBack.defaultProps = {
  backHandler: null,
  destination: '',
  id: 'goBackBtn',
};
