// eslint-disable-next-line import/no-cycle
import {
  SingleCollection,
  SingleElement,
  SingleField,
} from '../../Features/Application/Application/ApplicationActionListener/VerifyCorrectnessApplication';

export const ELEMENT_VIOLATION_BY_TYPE = {
  field: SingleField,
  collection: SingleCollection,
  element: SingleElement,
};
