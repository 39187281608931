import {
  Box,
  Button,
  Divider,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import {
  useExpertPersonalDataContext,
  useGlobalDialog,
  useExpertApplicationFormContext,
  useGlobalContext,
} from '../../../../Context';
import { TitledContainer } from '../../../TitledContainer';
import { EducationForm } from './Education.form';
import { EducationsList } from './Educations.list';
import { iriToId } from '../../../../_helpers';
import { API_ROUTE } from '../../../../_constants';
import { request } from '../../../../_services';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';

/**
 * Expert personal data education.
 *
 * @returns {Element[]}
 */
export function Education() {
  const [educationAttachmentsData, setEducationAttachmentsData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const {
    isReadonly, reloadData, selectionHandler, id, educationAttachments,
  } = useExpertPersonalDataContext();
  const {
    isApplicationFormStatusNotInEditionAndInCorrection, applicationFormData,
  } = useExpertApplicationFormContext();
  const { render } = useGlobalDialog();
  const { notify } = useGlobalContext();

  const loadAttachments = async () => {
    setLoaded(false);
    const educationAttachmentsIds = educationAttachments?.map(iriToId) || [];

    const url = isApplicationFormStatusNotInEditionAndInCorrection
      ? `${API_ROUTE.applicationForms}/${applicationFormData.id}/submitted-education-attachments?id[]=${educationAttachmentsIds
        .join('&id[]=')}&itemsPerPage=100`
      : `${API_ROUTE.expertPersonalData}/${id}/education-attachments?id[]=${educationAttachmentsIds
        .join('&id[]=')}&itemsPerPage=100`;
    const {
      payload, statusSuccess,
    } = await request.get(url);
    setLoaded(true);
    if (!statusSuccess) {
      notify('Wystąpił błąd podczas pobierania danych załączników.', 'error');

      return;
    }

    setEducationAttachmentsData(payload);
  };

  useEffect(() => {
    loadAttachments();
  }, [educationAttachments]);

  if (!loaded) {
    return <ContainerLoader />;
  }

  return (
    <TitledContainer
      title="Wykształcenie wyższe z tytułem zawodowym, minimum mgr, mgr inż. lub równoważny"
      key="education-container"
      variant="slim"
    >
      {!isReadonly && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            color="secondary"
            variant="contained"
            id="ycyLGPED14ReYG6"
            onClick={() => render(
              <EducationForm
                reloadData={reloadData}
                selectionHandler={selectionHandler}
                educationAttachmentsData={educationAttachmentsData}
                isReadonly={isReadonly}
              />,
              'Dodaj wykształcenie',
            )}
          >
            Dodaj wykształcenie
          </Button>
        </Box>
      )}
      <Divider sx={{ my: (t) => t.spacing(2.5) }} />
      <EducationsList educationAttachmentsData={educationAttachmentsData} />
    </TitledContainer>
  );
}
