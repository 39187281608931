import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

/**
 * Element container loader with circular progress.
 *
 * @param {object} props - root props
 * @param {string} props.title - title
 * @returns {ContainerLoader}
 */
export function ContainerLoader({ title }) {
  return (
    <>
      <Typography fontWeight="bold">{title}</Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 10,
        width: '100%',
      }}
      >
        <CircularProgress color="secondary" size={50} />
      </Box>
    </>
  );
}

ContainerLoader.propTypes = {
  title: PropTypes.string,
};

ContainerLoader.defaultProps = {
  title: '',
};
