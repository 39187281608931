import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import CheckIcon from '@mui/icons-material/Check';
import {
  DeleteOutline,
  FileDownloadOutlined,
} from '@mui/icons-material';
import { useState } from 'react';
import { FILE_STATE } from './fileState';
import { API_ROUTE } from '../../_constants';
import {
  iriToId,
  formatDateString,
  convertStorageUnit,
} from '../../_helpers';
import { request } from '../../_services';
import { ConfirmDialog } from '../../Dialogs';
import { FileIcon } from './FileIcon';
import { theme } from '../../styles/theme';

/**
 * File list component.
 *
 * @param {object} props - root props
 * @param {Array} props.files - files list
 * @param {Function} props.removeHandler - remove handler
 * @param {boolean} props.loading - is files still loading
 * @param {boolean} props.isReadonly - props provide value is read only
 * @param {string} props.apiUrl - API URL
 * @param {Node} props.fileActions - file actions
 * @param {Function} props.onDownload - on download
 * @param {Function} props.hideItemRemoveButtonCallback - callback to hide item remove button
 * @returns {FileList}
 */
export function FileList({
  files,
  removeHandler,
  loading,
  isReadonly,
  apiUrl,
  fileActions,
  onDownload,
  hideItemRemoveButtonCallback,
}) {
  const [elementToRemoveIndex, setElementToRemoveIndex] = useState(null);

  /**
   * Gets item subtitle.
   *
   * @param {number} size - file size
   * @param {string} status - file upload status
   * @param {string|null} error - file upload error message
   * @param {string} createdAt - created date
   * @returns {string}
   */
  const getSubtitle = (size, status, error, createdAt) => (
    <span>
      {`Wielkość: ${(convertStorageUnit.bytesToMegabytes(size)).toFixed(2)} MB`}
      <Typography
        variant="inherit"
        component="span"
        sx={{ display: 'block' }}
      >
        {createdAt ? `Data wgrania pliku: ${formatDateString(createdAt)}` : ''}
      </Typography>
      {status === FILE_STATE.pending && (
        <LinearProgress color="secondary" />
      )}
      {status === FILE_STATE.error && (
        <Typography
          variant="caption"
          color={theme.palette.error.main}
          display="block"
        >
          {error || 'Nie można zapisać tego pliku!'}
        </Typography>
      )}
    </span>
  );

  /**
   * Gets item title.
   *
   * @param {string} name - file name
   * @param {string} status - file upload status
   * @returns {HTMLElement}
   */
  const getTitle = (name, status) => (
    <span>
      {name}
      {status === FILE_STATE.uploaded && (
        <CheckIcon
          color="secondary"
          aria-label={`Plik ${name} został wgrany poprawnie`}
          aria-live="polite"
        />
      )}
    </span>
  );

  if (loading) {
    return <LinearProgress color="secondary" aria-live="polite" aria-label="Trwa ładowanie załączonych plików" />;
  }

  const download = async (iri) => {
    await request.download(`${apiUrl}/${iriToId(iri)}`);

    if (onDownload) {
      onDownload();
    }
  };

  const handleRemove = () => {
    removeHandler(elementToRemoveIndex);
    setElementToRemoveIndex(null);
  };

  return (
    <>
      <List>
        {files.map(({
          file: {
            name, size,
          }, status, error = null, description, extension, iri, createdAt,
        }, index) => (
          <ListItem key={uniqid()} sx={{ paddingRight: theme.spacing(15) }}>
            <ListItemAvatar>
              <FileIcon extension={extension} />
            </ListItemAvatar>
            <ListItemText
              primary={getTitle(description, status)}
              secondary={getSubtitle(size, status, error, createdAt)}
            />
            {status === FILE_STATE.uploaded && (
              <ListItemSecondaryAction sx={{ display: 'flex' }}>
                {!isReadonly && hideItemRemoveButtonCallback(status, createdAt) === false && (
                  <Tooltip title="Usuń">
                    <IconButton
                      id={uniqid()}
                      aria-label={`Usuń załącznik ${name}`}
                      color="primary"
                      onClick={() => setElementToRemoveIndex(index)}
                    >
                      <DeleteOutline fontSize="large" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Pobierz">
                  <IconButton
                    id={uniqid()}
                    aria-label={`Pobierz załącznik ${name}`}
                    color="secondary"
                    onClick={() => download(iri)}
                  >
                    <FileDownloadOutlined fontSize="large" />
                  </IconButton>
                </Tooltip>
                {fileActions && (
                  <Box display="flex" alignItems="center">
                    {fileActions}
                  </Box>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      <ConfirmDialog
        confirmButtonHandler={() => handleRemove()}
        closeHandler={() => setElementToRemoveIndex(null)}
        open={elementToRemoveIndex !== null}
        confirmButtonLabel="Tak"
        cancelButtonLabel="Nie"
        dialogTitle="Potwierdź usunięcie pliku"
        fullWidth
        cancelButtonColor="primary"
        maxWidth="sm"
      >
        <Typography textAlign="center" fontSize="large">
          Czy potwierdzasz usunięcie pliku?
        </Typography>
      </ConfirmDialog>
    </>
  );
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  removeHandler: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
  loading: PropTypes.bool,
  isReadonly: PropTypes.bool,
  apiUrl: PropTypes.string,
  fileActions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  onDownload: PropTypes.func,
  hideItemRemoveButtonCallback: PropTypes.func,
};

FileList.defaultProps = {
  files: [],
  removeHandler: null,
  loading: false,
  isReadonly: false,
  apiUrl: API_ROUTE.attachmentsRecruitments,
  fileActions: null,
  onDownload: null,
  hideItemRemoveButtonCallback: () => false,
};
