import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import FieldConfig from '../../Application/Application/FieldConfig/FieldConfig';
import ProjectBudgetTable from './ProjectBudget/ProjectBudget.table';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import ExpensesByTaskTable from './ProjectBudget/ExpensesByTask.table';
import ExpensesByImplementerTable from './ProjectBudget/ExpensesByImplementer.table';

/**
 * Project budget element
 *
 * @returns {ProjectBudget}
 */
function ProjectBudget() {
  const {
    getValues, setValue,
  } = useFormContext();
  const { id: applicationId } = useParams();
  const [isLoaded, setLoaded] = useState(false);
  const [isShowHelpText, setShowHelpText] = useState(true);

  const getProjectBudget = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.applicationProjectBudget.replace(':id', applicationId));

    setLoaded(true);
    if (statusSuccess) {
      Object.keys(payload).forEach((key) => {
        setValue(key, payload[key]);
        if (payload[key].length > 0) {
          setShowHelpText(false);
        }
      });
    }
  };

  useEffect(() => {
    getProjectBudget();
  }, []);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return isShowHelpText ? (
    <Typography m={3}>
      Budżet projektu zostanie wyświetlony po uzupełnieniu Harmonogramu rzeczowo - finansowego
    </Typography>
  ) : (
    <>
      <FieldConfig name="project_budget_by_module">
        {({
          title, label, name,
        }) => (
          <ProjectBudgetTable data={getValues(name)} tableName={label || title} fieldName={name} />
        )}
      </FieldConfig>
      <FieldConfig name="project_budget_by_aid_type">
        {({
          title, label, name,
        }) => (
          <ProjectBudgetTable data={getValues(name)} tableName={label || title} fieldName={name} />
        )}
      </FieldConfig>
      <FieldConfig name="project_budget_by_task">
        {({
          title, label, name,
        }) => (
          <ExpensesByTaskTable data={getValues(name) || []} tableName={label || title} />
        )}
      </FieldConfig>
      <FieldConfig name="project_budget_by_implementer">
        {({
          title, label, name,
        }) => (
          <ExpensesByImplementerTable data={getValues(name) || []} tableName={label || title} />
        )}
      </FieldConfig>
    </>
  );
}

export default ProjectBudget;
