import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import ImplementedProjectsPdf from './RdProjectTeam/ImplementedProjects.pdf';
import CommitmentPersonPdf from './RdProjectTeam/CommitmentPerson.pdf';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * RdProjectTeamPdf element.
 *
 * @returns {RdProjectTeamPdf}
 */
function RdProjectTeamPdf() {
  const {
    cooperationStatuses, roleInProjects,
  } = ELEMENTS_DICTIONARIES_MAP;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <CollectionWrapper name="rd_project_team_project_team">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];
            const isRoleConsortiumMemberNameVisible = item.role_cooperation_status
              === cooperationStatuses.consortiumEmployee.id
              || item.role_cooperation_status === cooperationStatuses.agreementSignedByConsortium.id;

            const isImplementedProjectsVisible = item.role_in_project
              === roleInProjects.rdManager.id || item.role_in_project === roleInProjects.rdModuleManager.id;

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.first_name`}
                      value={item.first_name}
                      oldValue={previousRowData?.first_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.last_name`}
                      value={item.last_name}
                      oldValue={previousRowData?.last_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.education`}
                      value={item.education}
                      oldValue={previousRowData?.education}
                      dictionaryName={DICTIONARIES.education.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.academic_degree`}
                      value={item.academic_degree}
                      oldValue={previousRowData?.academic_degree}
                    />
                    <RowField
                      name={`${collectionName}.${index}.role_in_project`}
                      value={item.role_in_project}
                      oldValue={previousRowData?.role_in_project}
                      dictionaryName={DICTIONARIES.roleInProjects.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.professional_experience`}
                      value={item.professional_experience}
                      oldValue={previousRowData?.professional_experience}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.role_and_responsibilities`}
                      value={item.role_and_responsibilities}
                      oldValue={previousRowData?.role_and_responsibilities}
                    />
                    <RowField
                      name={`${collectionName}.${index}.role_involvement_dimension`}
                      value={item.role_involvement_dimension}
                      oldValue={previousRowData?.role_involvement_dimension}
                    />
                    <RowField
                      name={`${collectionName}.${index}.role_cooperation_status`}
                      value={item.role_cooperation_status}
                      oldValue={previousRowData?.role_cooperation_status}
                      dictionaryName={DICTIONARIES.cooperationStatuses.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.team_member_experience`}
                      value={item.team_member_experience}
                      oldValue={previousRowData?.team_member_experience}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.experience_in_implementing_grant_project`}
                      value={item.experience_in_implementing_grant_project}
                      oldValue={previousRowData?.experience_in_implementing_grant_project}
                    />
                    <RowField
                      name={`${collectionName}.${index}.experience_in_implementing_project_identical_to_grant_project`}
                      value={item.experience_in_implementing_project_identical_to_grant_project}
                      oldValue={previousRowData?.experience_in_implementing_project_identical_to_grant_project}
                    />
                    <RowField
                      name={`${collectionName}.${index}.involvement_period_in_grant_project`}
                      value={item.involvement_period_in_grant_project}
                      oldValue={previousRowData?.involvement_period_in_grant_project}
                    />
                    <RowField
                      name={`${collectionName}.${index}.experience_description_in_grant_project_implementation`}
                      value={item.experience_description_in_grant_project_implementation}
                      oldValue={previousRowData?.experience_description_in_grant_project_implementation}
                    />
                    <RowField
                      name={`${collectionName}.${index}.position_in_project_management_team`}
                      value={item.position_in_project_management_team}
                      oldValue={previousRowData?.position_in_project_management_team}
                    />
                  </FieldSorter>
                </View>
                {isRoleConsortiumMemberNameVisible && (
                  <RowField
                    name={`${collectionName}.${index}.role_consortium_member_name`}
                    value={item.role_consortium_member_name}
                    oldValue={previousRowData?.role_consortium_member_name}
                  />
                )}
                {isImplementedProjectsVisible && (
                  <ImplementedProjectsPdf parentCollectionName={collectionName} index={index} />
                )}
                <CommitmentPersonPdf parentCollectionName={collectionName} index={index} />
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        <RowField name="rd_project_team_other_personnel" isSingleRow />
        <RowField name="rd_project_team_personnel_planned_to_be_involved" isSingleRow />
        <RowField name="rd_project_team_module_manage" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default RdProjectTeamPdf;
