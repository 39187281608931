import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getIndexForPreviousRowData,
  getRemovedRowData,
  getChangesInField,
} from '../../../../../_helpers';

/**
 * Project Budget Table Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.label - table label
 * @param {Array} props.data - table data
 * @param {string} props.fieldName - field name
 * @returns {ProjectBudgetTablePdf}
 */
function ProjectBudgetTablePdf({
  label, data, fieldName,
}) {
  const {
    previousInitialFormData, withChanges,
  } = useApplicationToPdfContext();

  const previousProjectBudgetData = previousInitialFormData[fieldName] || [];
  const projectBudgetTotal = data.pop();
  const previousProjectBudgetTotal = previousProjectBudgetData.pop();

  const projectBudgetData = withChanges ? [
    ...getAddedRowData(data, previousProjectBudgetData, 'name'),
    ...getRemovedRowData(data, previousProjectBudgetData, 'name'),
  ] : data;

  const FIRST_ROW_LABEL_BY_FIELD_NAME = {
    project_budget_by_module: 'Nazwa modułu',
    project_budget_by_aid_type: 'Rodzaj pomocy',
    project_budget_by_task: 'Nazwa zadania',
  };

  if (projectBudgetData.length === 0) {
    return '';
  }

  return (
    <View style={printStyle.sectionWrapperWithMargin}>
      <Text style={printStyle.sectionTitle}>
        {label}
      </Text>
      <View style={printStyle.table}>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray" rowSpan={5}>{FIRST_ROW_LABEL_BY_FIELD_NAME[fieldName]}</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray" rowSpan={5}>Wydatki ogółem</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray" rowSpan={5}>Wydatki kwalifikowane</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray" rowSpan={5}>Dofinansowanie</PrintoutTableCell>
          <PrintoutTableCell bgColor="gray" rowSpan={5}>% dofinansowania</PrintoutTableCell>
        </PrintoutTableRow>
        {projectBudgetData.map((item) => {
          const previousRowData = previousProjectBudgetData[getIndexForPreviousRowData(
            data,
            item.name,
            'name'
          )];

          return (
            <PrintoutTableRow key={item.name}>
              <PrintoutTableCell rowSpan={5} bgColor={item.status || 'white'}>
                {getChangesInField(previousRowData?.name, item.name)}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={5} bgColor={item.status || 'white'}>
                {getChangesInField(previousRowData?.total_expenses, item.total_expenses)}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={5} bgColor={item.status || 'white'}>
                {getChangesInField(previousRowData?.eligible_expenses, item.eligible_expenses)}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={5} bgColor={item.status || 'white'}>
                {getChangesInField(previousRowData?.co_financing, item.co_financing)}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={5} bgColor={item.status || 'white'}>
                {getChangesInField(previousRowData?.co_financing_percent, item.co_financing_percent)}
              </PrintoutTableCell>
            </PrintoutTableRow>
          );
        })}
        <PrintoutTableRow>
          <PrintoutTableCell rowSpan={5} bgColor="gray">
            {getChangesInField(previousProjectBudgetTotal?.name, projectBudgetTotal?.name)}
          </PrintoutTableCell>
          <PrintoutTableCell rowSpan={5} bgColor="gray">
            {getChangesInField(previousProjectBudgetTotal?.total_expenses, projectBudgetTotal?.total_expenses)}
          </PrintoutTableCell>
          <PrintoutTableCell rowSpan={5} bgColor="gray">
            {getChangesInField(previousProjectBudgetTotal?.eligible_expenses, projectBudgetTotal?.eligible_expenses)}
          </PrintoutTableCell>
          <PrintoutTableCell rowSpan={5} bgColor="gray">
            {getChangesInField(previousProjectBudgetTotal?.co_financing, projectBudgetTotal?.co_financing)}
          </PrintoutTableCell>
          <PrintoutTableCell rowSpan={5} bgColor="gray">
            {getChangesInField(
              previousProjectBudgetTotal?.co_financing_percent,
              projectBudgetTotal?.co_financing_percent
            )}
          </PrintoutTableCell>
        </PrintoutTableRow>
      </View>
    </View>
  );
}

export default ProjectBudgetTablePdf;

ProjectBudgetTablePdf.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  fieldName: PropTypes.string.isRequired,
};
