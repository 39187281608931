import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import { formatDateString } from '../../../../../_helpers';
import { useApplicationToPdfContext } from '../../../../../Context';
import { printStyle } from '../../../print.style';

/**
 * Own Indicator Row Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.indicator - indicator data
 * @param {string} props.name - main indicator name
 * @param {number} props.index - current indicator index
 * @param {object} props.previousIndicator - previous indicator
 * @returns {OwnIndicatorRowPdf}
 */
function OwnIndicatorRowPdf({
  indicator, name, index, previousIndicator,
}) {
  const {
    getDictionary,
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <PrintoutTableRow>
        <PrintoutTableCell bgColor="gray" rowSpan={1} isTextBold>
          {indicator.name}
        </PrintoutTableCell>
      </PrintoutTableRow>
      <RowField
        name={`${name}.${index}.unit`}
        value={indicator.unit}
        oldValue={previousIndicator?.unit}
        externalDictionary={getDictionary(DICTIONARIES.measureUnits.name)}
        skipFilterDictionary
      />
      <RowField
        name={`${name}.${index}.breakdown_by_gender`}
        value={indicator.breakdown_by_gender}
        oldValue={previousIndicator?.breakdown_by_gender}
      />
      <RowField
        name={`${name}.${index}.base_year`}
        value={formatDateString(indicator.base_year, 'yyyy')}
        oldValue={formatDateString(previousIndicator?.base_year, 'yyyy')}
      />
      <RowField
        name={`${name}.${index}.base_value_o`}
        value={indicator.base_value_o}
        oldValue={previousIndicator?.base_value_o}
      />
      <RowField
        name={`${name}.${index}.target_year`}
        value={formatDateString(indicator.target_year, 'yyyy')}
        oldValue={formatDateString(previousIndicator?.target_year, 'yyyy')}
      />
      <RowField
        name={`${name}.${index}.target_value_o`}
        value={indicator.target_value_o}
        oldValue={previousIndicator?.target_value_o}
      />
      <RowField
        name={`${name}.${index}.measuring_method`}
        value={indicator.measuring_method}
        oldValue={previousIndicator?.measuring_method}
        isSingleRow
      />
    </View>
  );
}

export default OwnIndicatorRowPdf;

OwnIndicatorRowPdf.propTypes = {
  indicator: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  previousIndicator: PropTypes.instanceOf(Object),
};

OwnIndicatorRowPdf.defaultProps = {
  previousIndicator: {},
};
