import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

/**
 * Simple ref forwards to IMaskInput.
 * Use this as `inputComponent` in `InputProps` in TextField.
 * Mask is configurable as `mask` in inputProps
 *
 * @see https://gitlab.parp.gov.pl/parp/lsi-frontend/-/wikis/Pole-tekstowe-z-mask%C4%85
 */
export const ImprovedMaskedInput = forwardRef((props, ref) => (
  <IMaskInput
    {...props}
    inputRef={ref}
  />
));
