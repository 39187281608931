import {
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import { request } from '../../../_services';
import { makeRecruitmentUrl } from '../../../_helpers';
import { CustomAutocomplete } from '../../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * ChoiceField field component
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - field data object
 * @param {string} props.fieldData.apiEndpoint -api endpoint
 * @param {string} props.fieldData.identifiedBy - identified by key
 * @param {object} props.fieldData.allChoices - allChoices options
 * @param {object} props.fieldData.choices - choices options
 * @returns {ChoiceField}
 */
export function ChoiceField({
  fieldData: {
    apiEndpoint,
    identifiedBy,
    allChoices = [],
    choices = [],
  },
}) {
  const [selectChoices, setSelectChoices] = useState([]);
  const [dictionaryLoadState, setDictionaryLoadState] = useState({
    requestDone: false,
    requestSuccess: false,
    loaded: false,
  });

  const { control } = useFormContext();

  const loadApiData = async (apiUrl) => {
    const {
      payload, statusSuccess,
    } = await request.get(`${apiUrl}?itemsPerPage=1000`);

    setDictionaryLoadState((prevState) => ({
      ...prevState,
      requestSuccess: statusSuccess,
      requestDone: true,
      loaded: payload.length !== 0,
    }));

    setSelectChoices(payload);
  };

  useEffect(() => {
    if (apiEndpoint === null) {
      const choicesData = allChoices.length > 0 ? allChoices : choices;
      const options = choicesData.map(({
        id, label: name,
      }) => ({
        id,
        name,
      }));
      setSelectChoices(options);

      return;
    }

    loadApiData(makeRecruitmentUrl(apiEndpoint));
  }, [apiEndpoint]);

  /**
   * Returns mapped choices.
   *
   * @returns {Array}
   */
  const mappedChoices = () => selectChoices.sort((a, b) => a.name.localeCompare(b.name)).map((option) => ({
    '@id': option[identifiedBy],
    name: option.name,
  }));

  return (
    <>
      <FormControl fullWidth margin="normal">
        <Controller
          name="choices"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                multiple
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                textFieldProps={{
                  label: 'Lista wartości do wyboru',
                  error: !!error,
                }}
                options={mappedChoices()}
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
              {apiEndpoint !== null && !dictionaryLoadState.loaded && dictionaryLoadState.requestDone && (
                <FormHelperText error>
                  Nie udało się załadować słownika dla tego pola.
                  {` Sprawdź czy endpoint API jest poprawny: ${apiEndpoint}`}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="minItems"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Minimalna liczba wybranych opcji"
              required
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="maxItems"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Maksymalna liczba wybranych opcji"
              required
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
    </>
  );
}

ChoiceField.propTypes = {
  fieldData: PropTypes.shape({
    apiEndpoint: PropTypes.string,
    identifiedBy: PropTypes.string.isRequired,
    allChoices: PropTypes.arrayOf(Object),
    choices: PropTypes.arrayOf(Object),
  }).isRequired,
};
