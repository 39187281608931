import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * AttachmentsPdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - elementData
 * @returns {AttachmentsPdf}
 */
function AttachmentsPdf({ elementData }) {
  const {
    getDictionary,
  } = useApplicationToPdfContext();

  const { elementDefinition: { fields } } = elementData;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {fields.map((field) => (
          <RowField
            key={field?.uuid}
            name={field.name}
            externalDictionary={getDictionary(field.name).map((attachment) => ({
              '@id': attachment['@id'],
              name: attachment.description,
            }))}
          />
        ))}
      </FieldSorter>
    </View>
  );
}

export default AttachmentsPdf;

AttachmentsPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
