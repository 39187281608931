import { REGEX } from '../../../_constants';

export const expertPersonalDataValidation = {
  tin: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "TIN"',
    },
  },
  identityDocumentType: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Rodzaj dokumentu tożsamości"',
    },
  },
  identityDocumentNumber: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Seria i numer dokumentu"',
    },
  },
  runningBusiness: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Czy ekspert usługi na rzecz PARP świadczył będzie i rozliczał w ramach '
        + 'prowadzonej działalności gospodarczej?"',
    },
  },
  nip: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "NIP"',
    },
    pattern: REGEX.nip,
  },
  citizenship: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Obywatelstwo"',
    },
  },
};
