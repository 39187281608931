import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';

/**
 * Implementers Partners Of Civil Partnership Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.fieldName - field name
 * @returns {ImplementersPartnersOfCivilPartnershipPdf}
 */
function ImplementersPartnersOfCivilPartnershipPdf({ fieldName }) {
  return (
    <CollectionWrapper name={fieldName}>
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.name`}
                value={item.name}
                oldValue={previousRowData?.name}
              />
              <RowField
                name={`${collectionName}.${index}.last_name`}
                value={item.last_name}
                oldValue={previousRowData?.last_name}
              />
              <RowField
                name={`${collectionName}.${index}.nip`}
                value={item.nip}
                oldValue={previousRowData?.nip}
              />
              <RowField
                name={`${collectionName}.${index}.voivodeship`}
                value={item.voivodeship?.label}
                oldValue={previousRowData?.voivodeship?.label}
              />
              <RowField
                name={`${collectionName}.${index}.district`}
                value={item.district?.label}
                oldValue={previousRowData?.district?.label}
              />
              <RowField
                name={`${collectionName}.${index}.commune`}
                value={item.commune?.label}
                oldValue={previousRowData?.commune?.label}
              />
              <RowField
                name={`${collectionName}.${index}.city`}
                value={item.city?.label}
                oldValue={previousRowData?.city?.label}
              />
              <RowField
                name={`${collectionName}.${index}.postal_code`}
                value={item.postal_code}
                oldValue={previousRowData?.postal_code}
              />
              <RowField
                name={`${collectionName}.${index}.street`}
                value={item.street?.label}
                oldValue={previousRowData?.street?.label}
              />
              <RowField
                name={`${collectionName}.${index}.house_number`}
                value={item.house_number}
                oldValue={previousRowData?.house_number}
              />
              <RowField
                name={`${collectionName}.${index}.apartment_number`}
                value={item.apartment_number}
                oldValue={previousRowData?.apartment_number}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ImplementersPartnersOfCivilPartnershipPdf;

ImplementersPartnersOfCivilPartnershipPdf.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
