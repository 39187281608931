import {
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  StatementAddDialog,
  StatementsListTable,
} from './index';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { useCustomSnackbar } from '../../../_hooks';
import { useReloadListener } from '../../../Context';

export const StatementsList = forwardRef(({
  templateElementId, currentStatementList,
}, ref) => {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'application-template-edit';

  const form = useForm({
    defaultValues: {
      statements: currentStatementList,
    },
    mode: 'all',
  });

  const {
    control, setValue, handleSubmit, watch, getValues,
  } = form;

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit(onSubmit),
  }));

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'statements',
  });

  const removeStatement = (index) => {
    remove(index);

    setValue('statements', fields
      .filter((_, filterIndex) => filterIndex !== index)
      .map((field, currentIndex) => ({
        ...field,
        ordinalNumber: currentIndex + 1,
      })));
  };

  const onSubmit = async () => {
    const { statusSuccess } = await request.put(
      `${API_ROUTE.templateElement}/${templateElementId}/statements`,
      getValues('statements'),
    );

    statusSuccess
      ? successNotification('Lista zastosowanych oświadczeń została zapisana.')
      : errorNotification('Wystąpił błąd podczas zapisu danych');
    if (statusSuccess) {
      reload(watcherName);
    }
  };

  return (
    <>
      <Box mt={5}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <StatementAddDialog
            append={append}
            fields={fields}
          />
        </Box>
        <Typography variant="pageHeading" textAlign="center">
          Lista zastosowanych oświadczeń
        </Typography>
      </Box>
      <FormProvider {...form}>
        <StatementsListTable
          removeStatement={removeStatement}
          statements={watch('statements')}
        />
      </FormProvider>
      {fields.length > 0 && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            type="button"
            id="BzKVyh9bHf2e3xC"
            onClick={() => setValue('statements', [])}
            color="primary"
            variant="contained"
          >
            Usuń wszystkie oświadczenia
          </Button>
        </Box>
      )}
    </>
  );
});

StatementsList.propTypes = {
  templateElementId: PropTypes.string.isRequired,
  currentStatementList: PropTypes.arrayOf(Object),
};

StatementsList.defaultProps = {
  currentStatementList: [],
};
