import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import {
  useEffect,
  useCallback,
} from 'react';
import { findIndex } from 'lodash';
import {
  CHARS_LIMIT,
  DICTIONARIES,
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import ContractorExperience from './ProjectImplementationJustification/ContractorExperience';
import DocumentsPreparationExperience from './ProjectImplementationJustification/DocumentsPreparationExperience';
import AuditsExperience from './ProjectImplementationJustification/AuditsExperience';

/**
 * ProjectImplementationJustification element renderer.
 *
 * @returns {ProjectImplementationJustification}
 */
export function ProjectImplementationJustification() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    watch, setValue, resetField, reset,
  } = useFormContext();
  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.projectAreas,
    DICTIONARIES.ecoDesignStages,
    DICTIONARIES.innovationScope,
    DICTIONARIES.operationScopeGozTransformationSubjects,
    DICTIONARIES.projectImplementationJustificationLevelInnovationImplementedSolution,
    DICTIONARIES.scopeOfInnovation,
  );
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'project_implementation_justification_eco_design_principles',
  });

  const {
    fields: projectImplementationFields, append: projectImplementationAppend, remove: projectImplementationRemove,
  } = useFieldArray({
    control,
    name: 'project_implementation_justification_innovations_description',
  });

  const {
    fields: operationScopeGozFields, append: operationScopeGozAppend, remove: operationScopeGozRemove,
  } = useFieldArray({
    control,
    name: 'project_implementation_justification_operation_scope_goz_transformation_subject',
  });

  const ecoDesignPrinciples = watch('project_implementation_justification_eco_design_principles');

  const filterConsumed = useCallback(
    (fieldIndex, options) => {
      const consumed = ecoDesignPrinciples
        .map(({ eco_design_stages: ecoDesignStages }, index) => ({
          iri: ecoDesignStages,
          index,
        }));

      const filterFn = ({ '@id': optionIri }) => {
        const consumedIndex = findIndex(consumed, { iri: optionIri });
        if (consumedIndex === -1) {
          return true;
        }

        return consumedIndex === fieldIndex;
      };

      return options.filter(filterFn);
    },
    [ecoDesignPrinciples],
  );

  const contractorExperienceConfig = fieldsConfig.project_implementation_justification_contractor_experience;

  const documentsPreparationExperienceConfig = fieldsConfig
    .project_implementation_justification_goz_documents_preparation_experience;

  const auditsExperienceConfig = fieldsConfig.project_implementation_justification_goz_audits_experience;

  const ecodesingPrinciplesConfig = fieldsConfig.project_implementation_justification_eco_design_principles;
  const subfields = ecodesingPrinciplesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const implementationInnovationsConfig = fieldsConfig.project_implementation_justification_innovations_description;
  const implementationInnovationssubfields = implementationInnovationsConfig?.fields || {};
  const implementationInnovationsfieldObject = getCollectionFieldObject(
    implementationInnovationssubfields,
    projectImplementationFields
  );

  const operationScopeGozConfig = fieldsConfig
    .project_implementation_justification_operation_scope_goz_transformation_subject;
  const operationScopeGozSubfields = operationScopeGozConfig?.fields || {};
  const aFieldObject = getCollectionFieldObject(operationScopeGozSubfields, operationScopeGozFields);

  const {
    maxRowNumber, minRowNumber,
  } = ecodesingPrinciplesConfig || {};

  const {
    maxRowNumber: maxRowNumberProject, minRowNumber: minRowNumberProject,
  } = implementationInnovationsConfig || {};

  const {
    maxRowNumber: aMaxRowNumber, minRowNumber: aMinRowNumber,
  } = operationScopeGozConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  useEffect(() => {
    if (projectImplementationFields.length === 0 && minRowNumberProject > 0) {
      for (let i = 1; i <= minRowNumberProject; i++) {
        append(getCollectionFieldObject(implementationInnovationssubfields, projectImplementationFields, i));
      }
    }
  }, [maxRowNumberProject, minRowNumberProject]);

  useEffect(() => {
    if (operationScopeGozFields.length === 0 && aMinRowNumber > 0) {
      for (let i = 1; i <= aMinRowNumber; i++) {
        operationScopeGozAppend(getCollectionFieldObject(operationScopeGozSubfields, operationScopeGozFields, i));
      }
    }
  }, [aMaxRowNumber, aMinRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* 1.Etykieta: Wykonawca audytu i strategii wzorniczej */}
      <FormField name="project_implementation_justification_audit_contractor" contextHelpLabel>
        {() => null}
      </FormField>
      {/* 2.Data rozpoczęcia przeprowadzenia audytu */}
      <FormField name="project_implementation_justification_audit_start_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 3.Data zakończenia przeprowadzenia audytu */}
      <FormField name="project_implementation_justification_audit_end_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 4.Nazwa wybranego wnioskodawcy */}
      <FormField name="project_implementation_justification_applicant_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 5.Potencjał wybranego wykonawcy */}
      <FormField name="project_implementation_justification_applicant_potential">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 6.NIP Wykonawcy */}
      <FormField name="project_implementation_justification_nip">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.NIP} />,
            }}
            inputProps={{
              mask: '0000000000',
              maxLength: maxLength ?? CHARS_LIMIT.NIP,
            }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 7.Adres siedziby wykonawcy */}
      <FormField name="project_implementation_justification_registered_address">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 8.Data podpisania umowy warunkowej z wykonawcą */}
      <FormField name="project_implementation_justificatio_date_signing_conditional_agreement">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 9.Dotychczasowa działalność wykonawcy */}
      <FormField name="project_implementation_justification_past_performance">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 10.Wyniki audytu wzorniczego */}
      <FormField name="project_implementation_justification_audit_result">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 11.Data rozpoczęcia sporządzenia/ zatwierdzenia strategii */}
      <FormField name="project_implementation_justification_strategy_drafting_start_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 12.Data zakończenia sporządzenia/ zatwierdzenia strategii */}
      <FormField name="project_implementation_justification_strategy_drafting_end_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 13.Opis wdrożenia działań wskazanych w strategii wzorniczej */}
      <FormField name="project_implementation_justification_implementation_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 14.Etykieta: Opis innowacji */}
      <FormField name="project_implementation_justification_innovation_description_label" contextHelpLabel>
        {() => null}
      </FormField>
      {/* 15.Etykieta: Innowacja produktowa */}
      <FormField name="project_implementation_justification_product_innovation" contextHelpLabel>
        {() => null}
      </FormField>
      {/* 16.Opis innowacji produktowej */}
      <FormField name="project_implementation_justification_product_innovation_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 17.Innowacja w procesie biznesowym */}
      <FormField name="project_implementation_justification_innovation_in_business_process">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'project_implementation_justification_description_innovation_in_business_process',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('project_implementation_justification_innovation_in_business_process') === BOOLEAN_VALUES.TRUE) && (
        <FormField name="project_implementation_justification_description_innovation_in_business_process">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* 18.Wpływ realizacji projektu na działalność i rozwój wnioskodawcy */}
      <FormField name="project_implementation_justification_implementation_project_impact_on_applicant_operations">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 19.Tabela: Zasady ekoprojektowania */}
      <LabeledFieldRow fieldConfig={ecodesingPrinciplesConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'project_implementation_justification_eco_design_principles',
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Etapy ekoprojektowania */}
            <FormField name={`project_implementation_justification_eco_design_principles.${index}.eco_design_stages`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                  }}
                  options={filterConsumed(index, filterOptions(get(DICTIONARIES.ecoDesignStages.name)))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Uzasadnienie */}
            <FormField name={`project_implementation_justification_eco_design_principles.${index}.justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="wtcVvr3E0laG5Ar"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>

      {/* 20.Etykieta: Wdrożenie nowego produktu nakierowanego na zapewnienie
      dostępności dla osób ze szczególnymi potrzebami */}
      <FormField name="project_implementation_justification_new_product_implementation" contextHelpLabel>
        {() => null}
      </FormField>
      {/* 21.Czy nowy produkt nakierowany jest na zapewnienie dostępności dla osób ze szczególnymi potrzebami */}
      <FormField name="project_implementation_justification_new_product_directed_at_ensuring_accessibility">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  project_implementation_justification_target_group_characteristics: FIELD_TYPES_MAP.text,
                  project_implementation_justification_new_features_description: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('project_implementation_justification_new_product_directed_at_ensuring_accessibility')
      === BOOLEAN_VALUES.TRUE) && (
        <>
          {/* Charakterystyka grupy docelowej */}
          <FormField name="project_implementation_justification_target_group_characteristics">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name="project_implementation_justification_new_features_description">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
      {/* Tabela: Opis wdrażania innowacji */}
      <LabeledFieldRow fieldConfig={implementationInnovationsConfig} contextHelpLabel fullWidth>
        {projectImplementationFields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              projectImplementationFields,
              minRowNumberProject,
              projectImplementationRemove,
              setValue,
              'project_implementation_justification_innovations_description',
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(implementationInnovationssubfields)}
            buttonRemoveVisible={projectImplementationFields.length > minRowNumberProject}
            key={field.id}
            isIndexed
          >
            {/* 1.Etykieta: Innowacje w procesie biznesowym (w procesie produkcji) */}
            <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_business_process_innovations`} contextHelpLabel>
              {() => null}
            </FormField>
            {/* 2.Zakres innowacji */}
            <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_innovation_scope`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.innovationScope.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* 3.Opis sposobu wprowadzenia innowacji w procesie biznesowym */}
            <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_business_process_innovation_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* 4.Czy wdrażana innowacja procesowa znosi bariery w świadczeniu pracy na danym stanowisku pracy
            w przedsiębiorstwie? */}
            <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_process_removes_barriers_to_work_provision`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={(event) => {
                    onChange(event);
                    if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                      resetField(
                        `project_implementation_justification_innovations_description.${index}.project_implementation_justification_workstation_adapted_to_disabled_people`,
                        { defaultValue: FIELD_TYPES_MAP.text }
                      );
                      resetField(
                        `project_implementation_justification_innovations_description.${index}.project_implementation_justification_improvements_removing_barriers_to_work_provision`,
                        { defaultValue: FIELD_TYPES_MAP.text }
                      );
                    }
                  }}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {(watch(`project_implementation_justification_innovations_description.${index}.project_implementation_justification_process_removes_barriers_to_work_provision`)
             === BOOLEAN_VALUES.TRUE)
              && (
                <>
                  {/* 5.Stanowisko pracy dostosowane do indywidulanych potrzeb osób z niepełnosprawnościami */}
                  <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_workstation_adapted_to_disabled_people`}>
                    {({
                      onChange, value, name, maxLength, onBlur, error,
                    }) => (
                      <TextField
                        multiline
                        id={name}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue={value}
                        variant="outlined"
                        InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                        inputProps={{ maxLength }}
                        disabled={isReadonly}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  </FormField>
                  {/* 6.Stanowisko pracy dostosowane do indywidulanych potrzeb osób z niepełnosprawnościami */}
                  <FormField name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_improvements_removing_barriers_to_work_provision`}>
                    {({
                      onChange, value, name, maxLength, onBlur, error,
                    }) => (
                      <TextField
                        multiline
                        id={name}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue={value}
                        variant="outlined"
                        InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                        inputProps={{ maxLength }}
                        disabled={isReadonly}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  </FormField>
                </>
              )}
            {/* 7.Zakres innowacji, w odniesieniu do etapu produkcji */}
            <FormField name={`project_implementation_justification_innovations_description.${index}.scope_of_innovation`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.scopeOfInnovation.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                  renderSelectAllButton={false}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && projectImplementationFields.length < maxRowNumberProject && (
          <Grid item xs={12}>
            <Button
              id="41YXpGrEdXfkgyM"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                projectImplementationFields,
                maxRowNumberProject,
                projectImplementationAppend,
                implementationInnovationsfieldObject
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Dotychczasowa działalność wnioskodawcy, obecne produkty (wyroby, usługi) i pozycja rynkowa wnioskodawcy */}
      <FormField name="project_implementation_justification_applicant_activities_and_market_position">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Etykieta: Zakres transformacji w kierunku GOZ */}
      <FormField name="project_implementation_justification_goz_transformation_scope" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Czy dokonano autoanalizy przez formularz na stronie internetowej PARP? */}
      <FormField name="project_implementation_justification_parp_website_auto_analysis">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Autoanaliza przedsiębiorstwa w zakresie potencjału GOZ */}
      <FormField name="project_implementation_justification_goz_potential_auto_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela Obszar działalności przedsiębiorstwa podlegający transformacji GOZ */}
      <LabeledFieldRow fieldConfig={operationScopeGozConfig} contextHelpLabel fullWidth>
        {operationScopeGozFields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              operationScopeGozFields,
              aMinRowNumber,
              operationScopeGozRemove,
              setValue,
              'project_implementation_justification_operation_scope_goz_transformation_subject',
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(operationScopeGozSubfields)}
            buttonRemoveVisible={operationScopeGozFields.length > aMinRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Obszar działalności przedsiębiorstwa podlegający transformacji GOZ */}
            <FormField name={`project_implementation_justification_operation_scope_goz_transformation_subject.${index}.operation_scope_goz_transformation_subject`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={filterOptions(get(DICTIONARIES.operationScopeGozTransformationSubjects.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Uzasadnienie wskazanego obszaru GOZ */}
            <FormField name={`project_implementation_justification_operation_scope_goz_transformation_subject.${index}.goz_scope_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && operationScopeGozFields.length < aMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="H4N98ztGRBxKRvL"
              variant="contained"
              color="secondary"
              onClick={() => addItem(operationScopeGozFields, aMaxRowNumber, operationScopeGozAppend, aFieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Etykieta: Wykonawca Modelu GOZ- transformacji */}
      <FormField name="project_implementation_justification_goz_transformation_model_contractor" contextHelpLabel>
        {() => null}
      </FormField>
      {/* REGON */}
      <FormField name="project_implementation_justification_regon">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.REGON} />,
            }}
            inputProps={{
              mask: '000000000',
              maxLength: maxLength ?? CHARS_LIMIT.REGON,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Numer w Krajowym Rejestrze Sądowym */}
      <FormField name="project_implementation_justification_krs_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.KRS} />,
            }}
            inputProps={{
              mask: '0000000000',
              maxLength: maxLength ?? CHARS_LIMIT.KRS,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela: Doświadczenie eksperckie wykonawcy */}
      <LabeledFieldRow fieldConfig={contractorExperienceConfig}>
        <ContractorExperience />
      </LabeledFieldRow>
      {/* Tabela: Doświadczenie w przygotowaniu dokumentów związanych z GOZ */}
      <LabeledFieldRow fieldConfig={documentsPreparationExperienceConfig}>
        <DocumentsPreparationExperience />
      </LabeledFieldRow>
      {/* Tabela:Doświadczenie w przeprowadzeniu audytów z zakresu GOZ */}
      <LabeledFieldRow fieldConfig={auditsExperienceConfig}>
        <AuditsExperience />
      </LabeledFieldRow>

      {/* Cel projektu */}
      <FormField name="project_implementation_justification_project_goal">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis realizacji projektu */}
      <FormField name="project_implementation_justification_description_implementation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres działalności, której dotyczy projekt */}
      <FormField name="project_implementation_justification_range_activities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Działania dotyczące wdrożenia GOZ wynikające z rekomendacji zawartych w modelu biznesowym
      GOZ-transformacji */}
      <FormField name="project_implementation_justification_implementation_goz">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Sposób wdrożenia w przedsiębiorstwie rekomendacji zawartych w w modelu biznesowym GOZ-transformacji */}
      <FormField name="project_implementation_justification_method_implementation_goz">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Innowacja w procesie biznesowym (innowacja procesowa)-GOZ */}
      <FormField name="project_implementation_justification_innovation_in_business_process_goz">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Poziom innowacyjności wdrażanego rozwiązania */}
      <FormField name="project_implementation_justification_level_innovation_implemented_solution">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES
              .projectImplementationJustificationLevelInnovationImplementedSolution.name))}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
            multiple
          />
        )}
      </FormField>
      {/* Uzasadnienie poziomu innowacyjności rozwiązania */}
      <FormField name="project_implementation_justification_level_innovation_solution">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wpływ realizacji projektu w zakresie wdrażanej innowacji na działalność i rozwój wnioskodawcy */}
      <FormField name="project_implementation_justification_impact_implementation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Symbioza przemysłowa (współpraca z innymi przedsiębiorstwami/ przedsiębiorcami) */}
      <FormField name="project_implementation_justification_label_industrial_symbiosis" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Czy projekt zakłada realizację działań w celu tworzenia symbiozy przemysłowej? */}
      <FormField name="project_implementation_justification_industrial_symbiosis">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'project_implementation_justification_description_implementation_processindustrial_symbiosis',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('project_implementation_justification_industrial_symbiosis') === BOOLEAN_VALUES.TRUE) && (
        <FormField name="project_implementation_justification_description_implementation_processindustrial_symbiosis">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Etykieta:Zielone zamówienia publiczne */}
      <FormField name="project_implementation_justification_label_green_public_procurement" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Czy w projekcie będą stosowane zielone zamówienia publiczne? */}
      <FormField name="project_implementation_justification_green_public_procurement">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'project_implementation_justification_description_implementation_process_green_public_procurement',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('project_implementation_justification_green_public_procurement') === BOOLEAN_VALUES.TRUE) && (
        <FormField
          name="project_implementation_justification_description_implementation_process_green_public_procurement"
        >
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
    </FieldSorter>
  );
}
