import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { DICTIONARIES } from '../../../_constants';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';
import {
  MonetaryField,
  parsePastedText,
} from '../../MonetaryField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';

/**
 * EdihServices element renderer.
 *
 * @returns {EdihServices}
 */
export function EdihServices() {
  const {
    control, setValue, getValues,
  } = useFormContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.edihSevicesTypes);

  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'edih_services',
  });

  const config = fieldsConfig.edih_services;
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const setTotalValue = () => {
    const totalValue = getValues().edih_services
      .reduce((total, item) => Number(item.average_aid_value) * (Number(item.services_number) || 1) + total, 0);
    setValue('edih_services_aid_total_value', totalValue.toString());
  };

  useEffect(() => {
    setTotalValue();
  }, [fields]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Tabela "Usługi EDIH dla MŚP" */}
      <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'edih_services',
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Nazwa usługi */}
            <FormField name={`edih_services.${index}.service_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Rodzaj usługi */}
            <FormField name={`edih_services.${index}.service_type`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.edihSevicesTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Średnia wartość pomocy de minimis dla MŚP w związku ze świadczeniem usługi */}
            <FormField name={`edih_services.${index}.average_aid_value`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    setTotalValue();
                  }}
                  defaultValue={value}
                  variant="outlined"
                  disabled={isReadonly}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
                    inputComponent: MonetaryField,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/*  Planowana liczba usług */}
            <FormField name={`edih_services.${index}.services_number`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    setTotalValue();
                  }}
                  defaultValue={value}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  type="number"
                />
              )}
            </FormField>
            {/* Opis usługi, sposobu jej świadczenia (zaangażowanych podmiotów, zasobów), mierzalnych celów i */}
            <FormField name={`edih_services.${index}.service_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="NnU7eoHFKiS1uKv"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Łączna wartość pomocy de minimis planowanej do udzielenia w związku ze świadczeniem usług dla MŚP */}
      <FormField name="edih_services_aid_total_value">
        {({
          value, name, error,
        }) => (
          <TextField
            id={name}
            name={name}
            value={value}
            disabled
            InputProps={{
              endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
              inputComponent: MonetaryField,
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
