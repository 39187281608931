import FieldWrapper from '../FieldWrapper';

/**
 * Required validation component
 *
 * @returns {Required}
 */
function Required() {
  return (
    <FieldWrapper name="required.value" label="Wymagalność pola" />
  );
}

export default Required;
