import {
  Grid,
  Link,
  List,
  ListItem,
  ListSubheader,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import { theme } from '../styles/theme';

const classes = {
  listTitle: {
    color: '#fff',
    backgroundColor: 'transparent',
    borderBottom: '2px solid #fff',
    marginLeft: theme.spacing(2),
    display: 'inline',
    fontFamily: '"Montserrat-Light",sans-serif',
  },
  listItemLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    color: '#FFF',
  },
};

/**
 * Internal ListItemLink component
 * Returns Link with a given text and href inside <li> element
 *
 * @param {object} props - root props
 * @param {string} props.linkLabel - text of the link
 * @param {string} props.linkHref - href of the link
 * @returns {ListItemLink}
 */
function ListItemLink({
  linkLabel, linkHref,
}) {
  return (
    <ListItem>
      <Link
        href={linkHref}
        target="_blank"
        rel="noopener"
        sx={classes.listItemLink}
      >
        {linkLabel}
      </Link>
    </ListItem>
  );
}

/**
 * NecessaryLinks component
 * Renders Navigation containing lists of links
 *
 * @returns {NecessaryLinks}
 */
export default function NecessaryLinks() {
  return (
    <Paper
      sx={{
        backgroundColor: '#b61928',
        color: '#fff',
        textAlign: 'left',
        width: 'calc(100% + 48px)',
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
      }}
      square
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          component="nav"
          aria-label="O Polskiej Agencji Rozwoju Przedsiębiorczości"
        >
          <List
            subheader={(
              <ListSubheader
                sx={classes.listTitle}
                disableGutters
              >
                O PARP
              </ListSubheader>
            )}
          >
            <ListItemLink
              linkLabel="Kim jesteśmy"
              linkHref="https://www.parp.gov.pl/component/parpabout/"
            />
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          component="nav"
          aria-label="Serwisy PARP"
        >
          <List
            subheader={(
              <ListSubheader
                sx={classes.listTitle}
                disableGutters
              >
                Serwisy PARP
              </ListSubheader>
            )}
          >
            <ListItemLink
              linkLabel="Nowoczesna gospodarka"
              linkHref="https://www.parp.gov.pl/component/site/site/nowa-perspektywa-finansowa-2021-2027#feng"
            />
            <ListItemLink
              linkLabel="Polska Wschodnia"
              linkHref="https://www.parp.gov.pl/component/site/site/nowa-perspektywa-finansowa-2021-2027#fepw"
            />
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          component="nav"
          aria-label="Polecane serwisy"
        >
          <List
            subheader={(
              <ListSubheader
                sx={classes.listTitle}
                disableGutters
              >
                Polecane serwisy
              </ListSubheader>
            )}
          >
            <ListItemLink
              linkLabel="Mapa dotacji"
              linkHref="https://mapadotacji.gov.pl/"
            />
            <ListItemLink
              linkLabel="Portal Funduszy Europejskich"
              linkHref="https://www.funduszeeuropejskie.gov.pl/"
            />
            <ListItemLink
              linkLabel="biznes.gov.pl"
              linkHref="https://www.biznes.gov.pl/pl"
            />
            <ListItemLink
              linkLabel="Polski Fundusz Rozwoju"
              linkHref="https://pfr.pl"
            />
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={2}
          component="nav"
          aria-label="Pomoc"
        >
          <List
            subheader={(
              <ListSubheader
                sx={classes.listTitle}
                disableGutters
              >
                Pomoc
              </ListSubheader>
            )}
          >
            <ListItemLink
              linkLabel="Infolinia PARP"
              linkHref="https://www.parp.gov.pl/component/site/site/kontakt-2"
            />
            <ListItemLink
              linkLabel="Rzecznik Funduszy Europejskich w PARP"
              /* eslint-disable-next-line max-len */
              linkHref="https://www.parp.gov.pl/component/site/site/rzecznik-funduszy-europejskich-w-polskiej-agencji-rozwoju-przedsiebiorczosci"
            />
            <ListItemLink
              linkLabel="Koordynator dostępności"
              linkHref="https://www.parp.gov.pl/component/site/site/koordynator_dostepnosci"
            />
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
}

ListItemLink.propTypes = {
  linkLabel: PropTypes.string.isRequired,
  linkHref: PropTypes.string,
};

ListItemLink.defaultProps = {
  linkHref: '#',
};
