import { TextField } from '@mui/material';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../../_constants';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import {
  setValuesForOtherGeographicalFields,
  townParser,
} from '../../../_helpers';

/**
 * ApplicantBranchAdressInEasternPoland element renderer.
 *
 * @returns {ApplicantBranchAdressInEasternPoland}
 */
export function ApplicantBranchAdressInEasternPoland() {
  const { isReadonly } = useElementContext();
  const streetSelectRef = useRef({
    clear: () => {},
  });

  const {
    watch, setValue,
  } = useFormContext();

  const selectedCity = watch('applicant_branch_adress_in_eastern_poland_city');

  return (
    <FieldSorter>
      {/* Województwo */}
      <FormField name="applicant_branch_adress_in_eastern_poland_voivodeship">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Powiat */}
      <FormField name="applicant_branch_adress_in_eastern_poland_district">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Gmina */}
      <FormField name="applicant_branch_adress_in_eastern_poland_commune">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Miejscowość */}
      <FormField name="applicant_branch_adress_in_eastern_poland_city">
        {({
          onChange, value, name, onBlur,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="name"
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={townParser}
            changeHandler={(selectedValue) => {
              streetSelectRef.current.clear();
              onChange(selectedValue);
              setValuesForOtherGeographicalFields(
                selectedValue,
                setValue,
                [
                  'applicant_branch_adress_in_eastern_poland_voivodeship',
                  'applicant_branch_adress_in_eastern_poland_district',
                  'applicant_branch_adress_in_eastern_poland_commune',
                ],
              );
            }}
            textFieldProps={{
              onBlur,
            }}
            charLimitExclusionWords={['oś']}
            baseUrl={API_ROUTE.geoDictionaries.towns}
            disabled={isReadonly}
            staticFilters={{ itemsPerPage: '500' }}
          />
        )}
      </FormField>
      {/* Kod pocztowy */}
      <FormField name="applicant_branch_adress_in_eastern_poland_postal_code">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            placeholder="__-___"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
            }}
            inputProps={{
              mask: '00-000',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Ulica */}
      <FormField name="applicant_branch_adress_in_eastern_poland_street">
        {({
          onChange, value, name, onBlur,
        }) => (
          <ApiAutocomplete
            id={name}
            disabled={!selectedCity?.value || isReadonly}
            ref={streetSelectRef}
            filterBy="name"
            staticFilters={{ 'town.id': selectedCity?.value }}
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={({
              id, name: label,
            }) => ({
              value: id,
              label,
            })}
            textFieldProps={{
              onBlur,
            }}
            changeHandler={onChange}
            baseUrl={API_ROUTE.geoDictionaries.streets}
            minCharsToRequest={2}
          />
        )}
      </FormField>
      {/* Nr budynku */}
      <FormField name="applicant_branch_adress_in_eastern_poland_house_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nr lokalu */}
      <FormField name="applicant_branch_adress_in_eastern_poland_apartment_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Telefon */}
      <FormField name="applicant_branch_adress_in_eastern_poland_phone_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.PHONE} />,
            }}
            inputProps={{ maxLength: maxLength ?? CHARS_LIMIT.PHONE }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
