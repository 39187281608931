/**
 * Print methods.
 *
 * @type {object}
 */
export const PRINT_METHODS = {
  client: 'client',
  server: 'server',
};

/**
 * Print methods translations.
 *
 * @type {object}
 */
export const PRINT_METHODS_TRANSLATIONS = {
  [PRINT_METHODS.client]: 'Użytkownik',
  [PRINT_METHODS.server]: 'Serwer',
};
