import { BlobProvider } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ApplicationToPdfContextProvider,
  useGlobalContext,
} from '../../../Context';
import PrintoutApplicationTemplate from './PrintoutApplication.template';
import PrintoutOpener from './PrintoutOpener';
import { useAuth } from '../../../_security';

/**
 * Printout application button
 *
 * @param {object} props - root props
 * @param {object} props.application - application data
 * @param {object} props.managedApplicationTemplate - managed application template
 * @param {Function} props.getDictionary - get dictionary
 * @param {boolean} props.isReadonly - is readonly
 * @param {object} props.previousInitialFormData - previous initial form data
 * @param {boolean} props.withChanges - show differences between old and new data
 * @param {Function} props.backdrop - backdrop
 * @param {Function} props.setApplicationData - set application data
 * @returns {PrintoutApplicationButton}
 */
function PrintoutApplicationButton({
  application,
  managedApplicationTemplate,
  getDictionary,
  isReadonly,
  previousInitialFormData,
  withChanges,
  backdrop,
  setApplicationData,
}) {
  const { id: applicationId } = useParams();
  const { notify } = useGlobalContext();
  const { profile } = useAuth();

  return (
    <BlobProvider document={(
      <ApplicationToPdfContextProvider
        value={{
          id: applicationId,
          application,
          managedApplicationTemplate,
          getDictionary,
          previousInitialFormData,
          withChanges,
          profile,
        }}
      >
        <PrintoutApplicationTemplate
          isReadonly={isReadonly}
          profile={profile}
        />
      </ApplicationToPdfContextProvider>
    )}
    >
      {({
        url, loading, error,
      }) => {
        if (!url && loading) {
          return (backdrop(!url && loading));
        }

        if (error && !loading) {
          notify(
            'Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.',
            'error'
          );
        }

        return (
          <PrintoutOpener
            url={url}
            backdrop={backdrop}
            resetData={() => setApplicationData({})}
          />
        );
      }}
    </BlobProvider>
  );
}

export default PrintoutApplicationButton;

PrintoutApplicationButton.propTypes = {
  getDictionary: PropTypes.func.isRequired,
  application: PropTypes.objectOf(Object).isRequired,
  managedApplicationTemplate: PropTypes.objectOf(Object).isRequired,
  isReadonly: PropTypes.bool.isRequired,
  previousInitialFormData: PropTypes.objectOf(Object).isRequired,
  withChanges: PropTypes.bool.isRequired,
  backdrop: PropTypes.func.isRequired,
  setApplicationData: PropTypes.func.isRequired,
};
