import { VALIDATION_TYPES } from './validationTypes';

/**
 * Get validations for field by rules.
 *
 * @see https://gitlab.parp.gov.pl/parp/lsi-frontend/-/wikis/Walidacje
 * @param {Array} rules - array with rules
 * @param {string} fieldLabel - field label
 * @returns {object}
 */
export const getValidationFieldByRules = (rules, fieldLabel) => rules
  .filter(({ name }) => name in VALIDATION_TYPES)
  .filter(({ enabled }) => enabled)
  .map((rule) => VALIDATION_TYPES[rule.name](fieldLabel, rule)).reduce((acc, currentValue) => {
    const currentKeyName = Object.keys(currentValue)[0] ?? '';

    if (currentKeyName && acc && currentKeyName in acc) {
      return ({
        ...acc,
        [currentKeyName]: {
          ...acc[currentKeyName],
          ...currentValue[currentKeyName],
        },
      });
    }

    return ({
      ...acc,
      ...currentValue,
    });
  }, {});
