import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * AccelerationProgramImplementationTeam Pdf element.
 *
 * @returns {AccelerationProgramImplementationTeamPdf}
 */
function AccelerationProgramImplementationTeamPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Doświadczenie i kompetencje 3 kluczowych członków zespołu realizującego program akceleracji */}
        <RowField name="acceleration_program_implementation_team_experience_and_competence_three_key_members" />
        {/* Doświadczenie i kompetencje 2 kluczowych ekspertów oceniających startupy biorących udział w naborze */}
        <RowField name="acceleration_program_implementation_team_experience_and_competence_two_key_experts" />
        {/* Tabela "Nazwa stanowiska, wymagane kompetencje oraz doświadczenie" */}
        <CollectionWrapper name="acceleration_program_implementation_team_organizational_structure">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.x}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.responsibility_area`}
                      value={item.responsibility_area}
                      oldValue={previousRowData?.responsibility_area}
                      dictionaryName={DICTIONARIES.responsibilityAreas.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.job_title_required_competencies_and_experience`}
                      value={item.job_title_required_competencies_and_experience}
                      oldValue={previousRowData?.job_title_required_competencies_and_experience}
                    />
                    <RowField
                      name={`${collectionName}.${index}.work_scope`}
                      value={item.work_scope}
                      oldValue={previousRowData?.work_scope}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default AccelerationProgramImplementationTeamPdf;
