import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../PrintToPdf/print.style';
import PrintoutTableRow from '../../../PrintToPdf/Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../PrintToPdf/Components/Table/PrintoutTableCell';
import QuestionsList from './QuestionsList';

/**
 * Printout supplement call without modules.
 *
 * @param {object} props - root props
 * @param {Array} props.evaluationSheetItems - evaluation sheet items
 * @returns {PrintoutSupplementCallWithoutModules}
 */
function PrintoutSupplementCallWithoutModules({ evaluationSheetItems }) {
  return (
    <View style={printStyle.table}>
      {evaluationSheetItems.map(({
        evaluationItemId, evaluationItemName, questions,
      }) => (
        <>
          <PrintoutTableRow key={evaluationItemId}>
            <PrintoutTableCell rowSpan={1} bgColor="dkGray" isTextBold>
              <Text style={printStyle.subSectionTitle}>
                {`Nazwa kryterium: ${evaluationItemName}`}
              </Text>
            </PrintoutTableCell>
          </PrintoutTableRow>
          <QuestionsList questions={questions} />
        </>
      ))}
    </View>
  );
}

export default PrintoutSupplementCallWithoutModules;

PrintoutSupplementCallWithoutModules.propTypes = {
  evaluationSheetItems: PropTypes.arrayOf(Object).isRequired,
};
