import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { URL } from '../../_constants';
import { theme } from '../../styles/theme';

/**
 * Breadcrumbs element.
 *
 * @param {object} props - root props
 * @param {Array} props.breadcrumbList - breadcrumbs list
 * @returns {Breadcrumbs}
 */
function Breadcrumbs({ breadcrumbList }) {
  const params = useParams();
  const paramKeys = Object.keys(params);
  const { pathname } = useLocation();

  const singleLabelStyle = {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.brandGray.main,
    fontSize: theme.typography.pxToRem(14),
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      textUnderlineOffset: theme.spacing(0.5),
    },
  };

  const getPath = (path) => {
    if (paramKeys.some((key) => path.includes(key))) {
      let pathWithParams = path;

      paramKeys.forEach((key) => {
        pathWithParams = pathWithParams.replace(`:${key}`, params[key]);
      });

      return pathWithParams;
    }

    return path;
  };

  if (breadcrumbList.length === 0 && pathname !== URL.home) {
    return '';
  }

  return (
    <Box display="flex" width="100%" flexWrap="wrap" justifyContent="flex-start" px={3} mb={4}>
      <MuiBreadcrumbs separator=">" sx={{ lineHeight: 1 }}>
        <MenuItem component={Link} to={URL.home} sx={singleLabelStyle}>
          <svg
            style={{ marginRight: '5px' }}
            aria-hidden="true"
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
            fill={theme.palette.brandGray.main}
          >
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </svg>
          Strona Główna
        </MenuItem>
        {breadcrumbList.map(({
          path, name,
        }) => (path ? (
          <MenuItem
            component={Link}
            to={getPath(path)}
            sx={singleLabelStyle}
            key={getPath(path)}
          >
            {name}
          </MenuItem>
        ) : (
          <Typography key={uniqid()} sx={singleLabelStyle}>
            {name}
          </Typography>
        )))}
      </MuiBreadcrumbs>
      <Divider sx={{
        mt: (t) => t.spacing(2),
        width: '100%',
      }}
      />
    </Box>
  );
}

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  breadcrumbList: PropTypes.arrayOf(Object),
};

Breadcrumbs.defaultProps = {
  breadcrumbList: [],
};
