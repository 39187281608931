import { API_ROUTE } from '../_constants';
// eslint-disable-next-line import/no-cycle
import { request } from '../_services';

const getProtestReviewItems = async (protestReviewItemsIds, onError) => {
  const protestReviewItemsToResolve = protestReviewItemsIds.map((protestReviewItemId) => request
    .get(`${API_ROUTE.protestReviewItems}/${protestReviewItemId}`));

  const responses = await Promise.all(protestReviewItemsToResolve);

  if (responses.some(({ statusSuccess }) => !statusSuccess)) {
    onError();

    return [];
  }

  return responses;
};

export const getReviewSheetData = async (
  protestReviewId,
  onSuccess,
  onError,
) => {
  const {
    statusSuccess, payload,
  } = await request.get(`${API_ROUTE.protestReviews}/${protestReviewId}`);
  const hasProtestReviewItems = payload.items.length !== 0;
  let protestReviewItems = hasProtestReviewItems ? [] : payload.criteriaItems;

  if (payload.items.length !== 0) {
    const responses = await getProtestReviewItems(payload.items.map(({ id }) => id), onError);

    protestReviewItems = responses.map(({ payload: protestReviewItem }) => {
      const criteriaItem = payload.criteriaItems.find(({ '@id': iri }) => iri === protestReviewItem.criteriaItem);

      return ({
        ...criteriaItem,
        ...protestReviewItem,
        exists: true,
      });
    });
  }

  if (!statusSuccess) {
    onError();

    return;
  }
  const nonExistCriteria = hasProtestReviewItems ? payload.criteriaItems
    .filter(({ '@id': id }) => !protestReviewItems.some(({ criteriaItem }) => criteriaItem === id))
    .map((item) => ({
      ...item,
      exists: false,
    })) : [];

  onSuccess({
    ...payload,
    criteriaItems: [
      ...protestReviewItems,
      ...nonExistCriteria,
    ],
  });
};
