import {
  REQUIREMENT_STATUSES,
  REQUIREMENT_STATUSES_TRANSLATIONS,
} from '../_constants';

/**
 * Requirement statuses values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const requirementStatusesOptions = [
  {
    id: REQUIREMENT_STATUSES.abandoned,
    label: REQUIREMENT_STATUSES_TRANSLATIONS.abandoned,
  },
  {
    id: REQUIREMENT_STATUSES.incomplete,
    label: REQUIREMENT_STATUSES_TRANSLATIONS.incomplete,
  },
  {
    id: REQUIREMENT_STATUSES.accepted,
    label: REQUIREMENT_STATUSES_TRANSLATIONS.accepted,
  },
];
