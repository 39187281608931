import {
  Button,
  Box,
} from '@mui/material';
import uniqid from 'uniqid';
import {
  API_ROUTE,
  USER_ISSUE_STATUSES,
} from '../../_constants';
import { IssuePreview } from './Preview';
import { formatDateString } from '../../_helpers';
import { MessageDialog } from '../../Dialogs';
import { CustomDataGrid } from '../DataGrid/CustomDataGrid';
import { useGlobalDialog } from '../../Context';

/**
 * IssuesList of issues
 *
 * @returns {IssuesList}
 */
export function IssuesList() {
  const { render } = useGlobalDialog();

  /**
   * Returns row actions.
   *
   * @param {object} props - root props
   * @param {object} props.row - element data
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => [
    <Button
      key={`dropdownItem-edit-${row.id}`}
      id={uniqid()}
      onClick={() => render(
        <MessageDialog okButtonLabel="Zamknij">
          <IssuePreview
            ariaLabelledBy="tempLabel"
            issueId={row.redmine_issue_id}
          />
        </MessageDialog>,
        'Podgląd zgłoszenia',
        { maxWidth: 'md' }
      )}
      variant="outlined"
      color="secondary"
      size="small"
    >
      Podgląd
    </Button>,
  ];

  const columns = [
    {
      field: 'redmine_issue_id',
      headerName: 'Identyfikator',
      valueFormatter: ({ value }) => value || 'Brak danych',
      flex: 0.75,
    },
    {
      field: 'application_number',
      headerName: 'Numer wniosku',
      flex: 2,
    },
    {
      field: 'subject',
      headerName: 'Temat',
      flex: 2,
    },
    {
      field: 'created_at',
      headerName: 'Data zgłoszenia',
      valueFormatter: ({ value }) => formatDateString(value),
      type: 'date',
      filterStrategy: 'after',
      flex: 2,
    },
    {
      field: 'estimated_end_at',
      headerName: 'Przewidywana data zakończenia',
      valueFormatter: ({ value }) => formatDateString(value),
      type: 'date',
      filterStrategy: 'before',
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueFormatter: ({ value }) => USER_ISSUE_STATUSES[value] ?? 'Nieznany status',
      type: 'singleSelect',
      valueOptions: Object.entries(USER_ISSUE_STATUSES).map(([value, label]) => ({
        value,
        label,
      })),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      headerName: 'Akcje',
      getActions: (params) => getRowActions(params),
      width: 120,
    },
  ];

  return (
    <Box id="7BN1qPSXGyd3aS3">
      <CustomDataGrid
        apiSource={API_ROUTE.issuesGet}
        columns={columns}
        additionalProps={{
          getRowId: (row) => row.redmine_issue_id.toString(),
        }}
        name="issues-grid"
      />
    </Box>
  );
}
