import { ACTION_TYPES } from '../_constants';

const initialState = {
  isAvailable: false,
  isRequestSend: false,
  expired: false,
  isDarkMode: false,
  fontSize: 14,
  globalNotification: {},
  forceReload: 0,
  isLock: false,
  isNewShowMessageDialog: false,
};

// eslint-disable-next-line default-param-last
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
  case ACTION_TYPES.TOGGLE_AVAILABILITY:
    return {
      ...state,
      isAvailable: !state.isAvailable,
    };
  case ACTION_TYPES.CHECK_AVAILABILITY:
    return {
      ...state,
      isAvailable: action.value,
    };
  case ACTION_TYPES.ON_REQUEST_SEND:
    return {
      ...state,
      isRequestSend: true,
    };
  case ACTION_TYPES.ON_REQUEST_NOT_SEND:
    return {
      ...state,
      isRequestSend: false,
    };
  case ACTION_TYPES.SET_IS_EXPIRED:
    return {
      ...state,
      user: action.value,
    };
  case ACTION_TYPES.DARK_MODE:
    return {
      ...state,
      isDarkMode: action.value,
    };
  case ACTION_TYPES.SET_FONT_SIZE:
    return {
      ...state,
      fontSize: action.value,
    };
  case ACTION_TYPES.SET_PROFILE:
    return {
      ...state,
      profile: action.value,
    };
  case ACTION_TYPES.GLOBAL_NOTIFICATION:
    return {
      ...state,
      globalNotification: {
        severity: action.severity ?? 'error',
        title: action.title ?? 'Wystąpił błąd',
        modal: action.modal ?? false,
      },
    };
  case ACTION_TYPES.CLEAR_GLOBAL_NOTIFICATION:
    return {
      ...state,
      globalNotification: {},
    };
  case ACTION_TYPES.FORCE_RELOAD:
    return {
      ...state,
      forceReload: Math.random(),
    };
  case ACTION_TYPES.SET_PAGE_LOCK:
    return {
      ...state,
      isLock: true,
      confirmMessage: action.message,
    };
  case ACTION_TYPES.SET_PAGE_UNLOCK:
    return {
      ...state,
      isLock: false,
      confirmMessage: '',
    };
  case ACTION_TYPES.SHOW_NEW_MESSAGE_DIALOG:
    return {
      ...state,
      isShowNewMessageDialog: true,
    };
  case ACTION_TYPES.NOT_SHOW_NEW_MESSAGE_DIALOG:
    return {
      ...state,
      isShowNewMessageDialog: false,
    };
  default:
    return state;
  }
};

export default rootReducer;
