import { REGEX } from '../../_constants';

export const registerValidation = {
  firstName: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Imię"',
    },
  },
  lastName: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Nazwisko"',
    },
  },
  pesel: {
    pattern: REGEX.pesel,
    minLength: {
      value: 11,
      message: 'PESEL musi się składać z 11 cyfr',
    },
    maxLength: {
      value: 11,
      message: 'PESEL musi się składać z 11 cyfr',
    },
  },
  email: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "E-mail"',
    },
    pattern: REGEX.email,
  },
  password: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Hasło"',
    },
    pattern: REGEX.password,
  },
  confirmedPassword: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Powtórz hasło"',
    },
  },
  termsConfirmation: {
    required: {
      value: true,
      message: 'Akceptacja regulaminu jest warunkiem rejestracji konta',
    },
  },
  reCaptcha: {
    required: {
      value: true,
      message: 'Proszę zaakceptować reCaptcha',
    },
  },
};
