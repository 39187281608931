import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';

/**
 * Human Resources Digitization Pdf element.
 *
 * @returns {HumanResourcesDigitizationPdf}
 */
function HumanResourcesDigitizationPdf() {
  return (
    <View style={printStyle.table}>
      <RowField name="human_resources_digitization_description_of_human_resources" />
    </View>
  );
}

export default HumanResourcesDigitizationPdf;
