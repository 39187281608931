import PropTypes from 'prop-types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  API_ROUTE,
  GROUPS,
  CHARS_LIMIT,
} from '../../../_constants';
import { request } from '../../../_services';
import { useCustomSnackbar } from '../../../_hooks';
import CharsCounter from '../../../Features/CharsCounter';
import { CustomAutocomplete } from '../../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * Statements element edit form
 *
 * @param {object} props - root props
 * @returns {StatementsElementEditForm}
 */

export const StatementsElementEditForm = forwardRef(({
  templateElement,
}, ref) => {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const [modules, setModules] = useState([]);

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      label: templateElement?.label || '',
      groups: templateElement?.groups || [],
      contextHelp: templateElement?.contextHelp || '',
      modules: templateElement?.modules.map((templateElementModule) => templateElementModule.id) || [],
    },
  });

  useImperativeHandle(ref, () => ({
    submitStatementElement: handleSubmit(onSubmit),
  }));

  const getModules = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.modules);

    if (statusSuccess) {
      setModules(payload);
    }
  };

  const onSubmit = async (values) => {
    const { statusSuccess } = await request.put(
      `${API_ROUTE.templateElement}/${templateElement.id}`,
      {
        ...values,
        modules: values.modules.map((moduleId) => `/lsi/recruitments/api/modules/${moduleId}`),
      }
    );

    statusSuccess
      ? successNotification('Główne ustawienia elementu zostały zapisane.')
      : errorNotification('Wystąpił błąd podczas zapisu danych');
  };

  useEffect(() => {
    getModules();
  }, []);

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="normal">
        <Controller
          name="label"
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Imię"',
            },
          }}
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Nazwa"
              required
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal">
        <Controller
          name="groups"
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Kroki"',
            },
          }}
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                multiple
                onChange={onChange}
                onBlur={onBlur}
                initialValue={value}
                textFieldProps={{
                  label: 'Kroki',
                  required: true,
                  error: !!error,
                }}
                options={GROUPS.map((group) => ({
                  '@id': group,
                  name: group,
                }))}
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal">
        <Controller
          name="modules"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                multiple
                onChange={onChange}
                onBlur={onBlur}
                initialValue={value}
                textFieldProps={{
                  label: 'Moduł',
                  error: !!error,
                }}
                options={modules}
                optionsMapKeys={['id', 'name']}
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal">
        <Controller
          name="contextHelp"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Pomoc kontekstowa"
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.STORY} onOutline />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.STORY }}
              multiline
            />
          )}
        />
      </FormControl>
    </>
  );
});

StatementsElementEditForm.propTypes = {
  templateElement: PropTypes.shape({
    label: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(Object).isRequired,
    contextHelp: PropTypes.string.isRequired,
    modules: PropTypes.arrayOf(Object).isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};
