/**
 * Requirement statuses.
 *
 * @type {object}
 */
export const REQUIREMENT_STATUSES = {
  abandoned: 'abandoned',
  incomplete: 'incomplete',
  accepted: 'accepted',
};

/**
 * Requirement statuses translations.
 *
 * @type {object}
 */
export const REQUIREMENT_STATUSES_TRANSLATIONS = {
  [REQUIREMENT_STATUSES.abandoned]: 'Protest pozostawiony bez rozpatrzenia',
  [REQUIREMENT_STATUSES.incomplete]: 'Do uzupełnienia',
  [REQUIREMENT_STATUSES.accepted]: 'Pozytywny',
};
