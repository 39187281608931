import {
  FormControl,
  Box,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { CustomAutocomplete } from '../CustomAutocomplete/CustomAutocomplete';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { useGlobalContext } from '../../Context';
import { handleError } from '../../_helpers';

/**
 * Form for add template of application
 *
 * @returns {AddTemplate}
 */
export function AddTemplate() {
  const { notify } = useGlobalContext();
  const navigate = useNavigate();

  const [implementationLevels, setImplementationLevels] = useState([]);

  const {
    control, handleSubmit, formState: { isSubmitting }, setError,
  } = useForm({
    defaultValues: {
      implementationLevel: null,
    },
    mode: 'all',
  });

  const submitAddTemplate = async (formData) => {
    const {
      payload, statusSuccess, violations = [],
    } = await request.post(
      API_ROUTE.applicationTemplates,
      formData,
    );

    const {
      id: applicationTemplateId, implementationLevel, ordinalNumber,
    } = payload;

    if (statusSuccess && applicationTemplateId) {
      const notificationMessage = `Utworzono wzór wniosku (ID: ${applicationTemplateId})
      o poziomie wdrażania ${implementationLevel.kod} (wersja ${ordinalNumber})`;

      navigate('/');

      setTimeout(() => {
        notify(notificationMessage, 'success');
      }, 100);

      return;
    }

    handleError(setError, violations);
  };

  useEffect(() => {
    /**
     * Load data from API.
     */
    async function fetchData() {
      const {
        payload, statusSuccess,
      } = await request.get(`${API_ROUTE.implementationLevels}?itemsPerPage=500`);

      if (statusSuccess) {
        setImplementationLevels(payload);
      }
    }
    fetchData();
  }, []);

  return (
    <form
      id="QrmApQLhSOPVDpe"
      data-testid="QrmApQLhSOPVDpe"
    >
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Poziom wdrażania"',
            },
          }}
          name="implementationLevel"
          control={control}
          render={({
            field: {
              onChange, value, name,
              onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              textFieldProps={{
                error: !!error,
                helperText: error?.message,
                required: true,
                label: 'Poziom wdrażania',
              }}
              options={implementationLevels}
              optionsMapKeys={['@id', 'kod']}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
      <Box sx={{ mt: (theme) => theme.spacing(2) }} display="flex" justifyContent="center">
        <SaveCancelButtons
          saveHandler={handleSubmit(submitAddTemplate)}
          saveButtonId="6TRAWj9jOc7ZfGM"
          saveDisabled={isSubmitting}
          cancelHandler={() => navigate('/')}
          cancelButtonId="iDoAOTPJoaRycZ3"
        />
      </Box>
    </form>
  );
}
