import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import {
  useEffect,
  useState,
} from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { darken } from '@mui/material/styles';
import { request } from '../../_services';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../_constants';
import { usePasswordRequirements } from '../../_hooks';
import { passwordChangeValidation } from './PasswordChangeConfirmation.validation';
import CharsCounter from '../CharsCounter';
import {
  getMessageError,
  handleError,
} from '../../_helpers';
import { useGlobalContext } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  passwordChangePaper: {
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(16),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  passwordChangeBtn: {
    marginTop: theme.spacing(4),
    fontSize: '1.3em',
    lineHeight: '3rem',
    textTransform: 'inherit',
    borderWidth: '3px',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
  visibility: {
    display: 'flex',
    order: '1',
    position: 'absolute',
    zIndex: 3,
    right: 0,
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit',
    },
  },
};

/**
 * Password change confirmation form
 *
 * @returns {PasswordChangeConfirmation}
 * */
export function PasswordChangeConfirmation() {
  const { notify } = useGlobalContext();
  const navigate = useNavigate();
  const { recoveryCode } = useParams();
  const [visibility, setVisibility] = useState({
    password: false,
    confirmedPassword: false,
  });
  const [isPasswordRequirementsOpen, setIsPasswordRequirementsOpen] = useState(false);

  const {
    control, handleSubmit, setError, formState, watch,
  } = useForm({
    defaultValues: {
      password: '',
      confirmedPassword: '',
    },
    mode: 'all',
  });

  const {
    errors, isValid, isDirty,
  } = formState;

  const {
    checkPassword,
    renderViolations,
  } = usePasswordRequirements();

  const checkToken = async () => {
    const {
      statusSuccess, violations,
    } = await request.post(
      `${API_ROUTE.validations}/otc`,
      {
        required: null,
        value: recoveryCode,
      },
      false
    );

    if (!statusSuccess) {
      navigate('/');

      setTimeout(() => {
        notify(getMessageError(violations[0]?.code), 'error');
      }, 100);
      window.scrollTo(0, 0);
    }
  };

  const handleSuccess = () => {
    navigate('/login');
    setTimeout(() => {
      notify('Twoje hasło zostało zmienione. Zaloguj się używając nowego hasła.', 'success');
    }, 100);
    window.scrollTo(0, 0);
  };

  const onSubmit = async (values) => {
    const requestObject = {
      passwordRecoveryCode: recoveryCode,
      plainPassword: values.password,
      repeatedPlainPassword: values.confirmedPassword,
    };

    const {
      statusSuccess,
      violations,
    } = await request.post(API_ROUTE.passwordRecovery, requestObject);

    if (violations) {
      handleError(setError, violations);
    }

    if (statusSuccess) {
      handleSuccess();
    }
  };

  const handleVisibility = (name) => {
    setVisibility((prevVisibility) => ({
      ...visibility,
      [name]: !prevVisibility[name],
    }));
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Paper variant="outlined" elevation={0} sx={classes.passwordChangePaper}>
      <Grid container>
        <Grid item xs={12} mt={2} mb={4} ml={1} />
        <Grid item lg={3} />
        <Grid item xs={12} lg={6}>
          <form style={{ padding: (t) => t.spacing(2) }} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h2" fontSize="2rem" mb={2}>Zmiana hasła</Typography>
            <FormControl fullWidth sx={{ mb: (t) => t.spacing(2) }}>
              <Controller
                rules={passwordChangeValidation.password}
                name="password"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Wpisz hasło"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <>
                          <CharsCounter
                            valueLength={value.length}
                            maxLength={CHARS_LIMIT.MEDIUM}
                            onOutline
                          />
                          <InputAdornment position="end" sx={classes.visibility}>
                            <IconButton
                              id="KYz4JfMDMbwuB4E"
                              aria-label="przełącz widoczność hasła"
                              data-testid="passwordVisibilityIcon1"
                              onClick={() => handleVisibility(name)}
                              size="large"
                            >
                              {visibility[name] ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.MEDIUM,
                      type: visibility[name] ? 'text' : 'password',
                    }}
                    onChange={(event) => {
                      onChange(event);
                      checkPassword(event.target.value);
                    }}
                    onBlur={(event) => {
                      onBlur(event);
                      setIsPasswordRequirementsOpen(false);
                    }}
                    required
                    onFocus={() => setIsPasswordRequirementsOpen(true)}
                  />
                )}
              />
              {(isPasswordRequirementsOpen || !!errors?.password) && renderViolations()}
            </FormControl>
            <FormControl fullWidth sx={{ mb: (t) => t.spacing(2) }}>
              <Controller
                rules={{
                  ...passwordChangeValidation.confirmedPassword,
                  validate: (value) => value === watch('password')
                    || 'Wpisz poprawne hasło. Hasło i powtórzone hasło nie są ze sobą zgodne.',
                }}
                name="confirmedPassword"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Powtórz hasło"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <>
                          <CharsCounter
                            valueLength={value.length}
                            maxLength={CHARS_LIMIT.MEDIUM}
                            onOutline
                          />
                          <InputAdornment position="end" sx={classes.visibility}>
                            <IconButton
                              id="37fAeUhOBQnLMUh"
                              aria-label="przełącz widoczność hasła"
                              data-testid="passwordVisibilityIcon2"
                              onClick={() => handleVisibility(name)}
                              size="large"
                            >
                              {visibility[name] ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.MEDIUM,
                      type: visibility[name] ? 'text' : 'password',
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                  />
                )}
              />
            </FormControl>
            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={classes.passwordChangeBtn}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Zmień hasło
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item lg={3} />
      </Grid>
    </Paper>
  );
}
