import {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

/**
 * Application context wrapper.
 * Contains all data about application and application template.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
const ApplicationContext = createContext({
  /**
   * Application identifier.
   */
  id: null,

  /**
   * Application object.
   *
   * @see /apidoc#operation/getApplicationItem
   */
  application: null,

  /**
   * ApplicationTemplate object.
   *
   * @see /apidoc#operation/getApplicationTemplateItem
   */
  applicationTemplate: null,

  /**
   * Application template and current application data combined.
   */
  managedApplicationTemplate: {},

  /**
   *  Setting the application to be displayed as read-only or editable
   */
  isReadonly: true,
});

/**
 * Application context hook consumer.
 *
 * @returns {object}
 */
export const useApplicationContext = () => useContext(ApplicationContext);

/**
 * Application context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ApplicationContextProvider({
  children, value,
}) {
  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};
