import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormField } from '../../Application/Application/FormField';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CharsCounter from '../../CharsCounter';
import FieldConfig from '../../Application/Application/FieldConfig/FieldConfig';
import { MODULE_IDS } from '../../../_constants';
import { getModules } from '../../../_helpers';
import { theme } from '../../../styles/theme';

/**
 * LinkBetweenModules element.
 *
 * @returns {LinkBetweenModules}
 * */
export default function LinkBetweenModules() {
  const { application } = useApplicationContext();
  const { isReadonly } = useElementContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const getRow = (name, moduleId) => (
    modules.some(({ id }) => id === moduleId)
    && getModules(name, null, modules).length > 1 && (
      <TableRow>
        <FieldConfig name={name}>
          {({ label: labelField }) => (
            <>
              <TableCell width="30%" sx={{ borderBottom: 'none' }} scope="row">
                <Typography textAlign="left" fontWeight="bold" fontSize="1rem" color={theme.palette.text.main} mb={2}>
                  {labelField}
                </Typography>
              </TableCell>
              <TableCell width="70%" sx={{ borderBottom: 'none' }} scope="row">
                <FormField name={name} labelled={false}>
                  {({
                    onChange, value, onBlur, error,
                  }) => (
                    <CustomAutocomplete
                      id={name}
                      initialValue={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      multiple
                      options={getModules(name, value, modules)}
                      renderSelectAllButton={false}
                      disabled={isReadonly}
                      error={error}
                    />
                  )}
                </FormField>
              </TableCell>
            </>
          )}
        </FieldConfig>
      </TableRow>
    )
  );

  return (
    <>
      {modules.length <= 1 ? (
        <Typography fontWeight="bold" mb={2}>
          Brak modułów do powiązania
        </Typography>
      )
        : (
          <TableContainer sx={{ mb: (t) => t.spacing(5) }}>
            <Table
              aria-label="Lista konfiguracji"
            >
              <TableHead>
                <TableRow>
                  <TableCell width="30%" scope="column">
                    <FieldConfig name="link_between_modules_name">
                      {({ label }) => (
                        <Typography fontWeight="bold">
                          {label}
                        </Typography>
                      )}
                    </FieldConfig>
                  </TableCell>
                  <TableCell width="70%" scope="column">
                    <FieldConfig name="link_between_modules_link_modules">
                      {({ label }) => (
                        <Typography fontWeight="bold">
                          {label}
                        </Typography>
                      )}
                    </FieldConfig>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getRow('link_between_modules_br', MODULE_IDS.br)}
                {getRow('link_between_modules_innovation_implementation', MODULE_IDS.innovationImplementation)}
                {getRow('link_between_modules_rd_infrastructure', MODULE_IDS.rdInfrastructure)}
                {getRow('link_between_modules_digitization', MODULE_IDS.digitization)}
                {getRow('link_between_modules_greening', MODULE_IDS.greening)}
                {getRow('link_between_modules_competences', MODULE_IDS.competences)}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <FieldConfig name="link_between_modules_explanation">
        {({ name }) => (
          <FormField name={name}>
            {({
              onChange, value, maxLength, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        )}
      </FieldConfig>
    </>
  );
}
