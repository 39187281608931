/**
 * Protest reviews statement acceptance statuses.
 *
 * @type {object}
 */
export const PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES = {
  accepted: 'accepted',
  corrections: 'corrections',
  rejected: 'rejected',
};

/**
 * Protest reviews statement acceptance statuses translations.
 *
 * @type {object}
 */
export const PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES_TRANSLATIONS = {
  [PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.accepted]: 'zaakceptowane',
  [PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.corrections]: 'do poprawy',
  [PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.rejected]: 'odrzucone',
};
