import {
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';
import { QuickProfileSwitching } from './Tools/QuickProfileSwitching';
import { useAuth } from '../../_security';
import { theme } from '../../styles/theme';
import { useCustomSnackbar } from '../../_hooks';

const classes = {
  mainMenu: {
    '& > .MuiMenu-paper': {
      padding: theme.spacing(1),
    },
  },
};

/**
 * Component rendered only in development environment.
 *
 * ####################################################################################
 * #                                                                                  #
 * # DO NOT MODIFY OR REMOVE CONDITION BELOW (process.env.NODE_ENV !== 'development') #
 * #                                                                                  #
 * ####################################################################################
 *
 * @returns {DevelopmentToolsMenu} button with menu items
 */
export function DevelopmentToolsMenu() {
  const {
    iat, exp,
  } = useAuth();

  const tokenTime = {
    expire: format(new Date(exp * 1000), 'yyyy-MM-dd HH:mm:ss'),
    created: format(new Date(iat * 1000), 'yyyy-MM-dd HH:mm:ss'),
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    successNotification,
  } = useCustomSnackbar();

  /**
   * It should depend on process.env.NODE_ENV but lsi-test is built as `prod`.
   */
  if (process.env.REACT_APP_ENV !== 'dev') {
    return '';
  }

  /**
   * Handle menu open.
   *
   * @param {Event} event onClick handler
   */
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle menu close.
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSentryReplays = () => {
    Sentry.addIntegration(
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      })
    );

    handleMenuClose();
    successNotification('Nagrywanie sesji zostało włączone.');
  };

  return (
    <>
      <BuildOutlinedIcon
        id="XrbmhLOyOoc21kp"
        aria-controls="Qy3zJcvQFBoVn1R"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{
          cursor: 'pointer',
          fill: theme.palette.error.dark,
          marginLeft: theme.spacing(1),
        }}
      />
      <Menu
        id="Qy3zJcvQFBoVn1R"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={classes.mainMenu}
      >
        <span style={{ fontWeight: 'bold' }}>Zmiana profilu</span>
        <Divider sx={{ mt: (t) => t.spacing(1) }} />
        <QuickProfileSwitching closeHandler={handleMenuClose} />
        <Divider />
        <MenuItem onClick={handleSentryReplays}>
          Nagraj sesję
        </MenuItem>
        <Divider />
        <span style={{ fontWeight: 'bold' }}>Token</span>
        <p>{`Utworzony: ${tokenTime.created}`}</p>
        <p>{`Wygasa: ${tokenTime.expire}`}</p>
      </Menu>
    </>
  );
}
