import {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

/**
 * Element context wrapper.
 * Contains all data about elements and its fields.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
export const ElementContext = createContext({
  /**
   * Template element identifier.
   */
  id: null,

  /**
   * Template element config
   */
  config: {
    /**
     * Internal function for current element render.
     *
     * @returns {Node|Element|HTMLElement}
     */
    Component: () => null,

    /**
     * Current element data such as title, contextHelp, fields,
     *
     * @see /apidoc#operation/getTemplateElementItem
     */
    element: {},
  },

  /**
   * Fields config for every available elements.
   * This data is shared between elements and fields.
   */
  fieldsConfig: {},

  /**
   * Fields config for current element.
   */
  currentElementFieldsConfig: {},

  /**
   * Returns field config data for the specified field.
   *
   * @param {string} fieldName - field name
   * @param {string} subfieldName - subfield name (optional)
   * @returns {object}
   */
  getFieldData: (fieldName, subfieldName) => ({
    name: fieldName,
    subfieldName,
  }),

  /**
   * Function for field render from current element.
   *
   * @param {string} fieldName - field name to render
   * @param {Function} callback - rendering callback, it contains all data from field config, and
   * current value, or onChange handler.
   * @param {boolean} useGrid - field will be rendered inside grid with title
   * @returns {object|null} - FormField object
   */
  // eslint-disable-next-line no-unused-vars
  render: (fieldName, callback, useGrid) => null,
});

/**
 * Element context hook consumer.
 *
 * @returns {ElementContext}
 */
export const useElementContext = () => useContext(ElementContext);

/**
 * Element context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ElementContextProvider({
  children, value,
}) {
  return (
    <ElementContext.Provider value={value}>
      {children}
    </ElementContext.Provider>
  );
}

ElementContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};
