import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';

/**
 * Technical Resources And Intangible Assets Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {TechnicalResourcesAndIntangibleAssetsPdf}
 */
function TechnicalResourcesAndIntangibleAssetsPdf({ elementData }) {
  const { id: elementId } = elementData;

  return (
    <CollectionWrapper name={`technical_resources_and_intangible_assets-${elementId}`}>
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.technical_resource_and_intangible_asset_type`}
                value={item.technical_resource_and_intangible_asset_type}
                oldValue={previousRowData?.technical_resource_and_intangible_asset_type}
                dictionaryName={DICTIONARIES.technicalResourcesAndIntangibleAssets.name}
              />
              <RowField
                name={`${collectionName}.${index}.technical_resource_and_intangible_asset_name`}
                value={item.technical_resource_and_intangible_asset_name}
                oldValue={previousRowData?.technical_resource_and_intangible_asset_name}
              />
              <RowField
                name={`${collectionName}.${index}.technical_resource_and_intangible_asset_purpose`}
                value={item.technical_resource_and_intangible_asset_purpose}
                oldValue={previousRowData?.technical_resource_and_intangible_asset_purpose}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default TechnicalResourcesAndIntangibleAssetsPdf;

TechnicalResourcesAndIntangibleAssetsPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
