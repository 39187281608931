import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * MailingAddressPdf element.
 *
 * @returns {MailingAddressPdf}
 */
function MailingAddressPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="mailingAddress_city"
          value={initialFormData?.mailingAddress_city?.label}
          oldValue={previousInitialFormData?.mailingAddress_city?.label}
        />
        <RowField
          name="mailingAddress_country"
          value={initialFormData?.mailingAddress_country?.label}
          oldValue={previousInitialFormData?.mailingAddress_country?.label}
        />
        <RowField
          name="mailingAddress_commune"
          value={initialFormData?.mailingAddress_commune?.label}
          oldValue={previousInitialFormData?.mailingAddress_commune?.label}
        />
        <RowField
          name="mailingAddress_district"
          value={initialFormData?.mailingAddress_district?.label}
          oldValue={previousInitialFormData?.mailingAddress_district?.label}
        />
        <RowField
          name="mailingAddress_voivodeship"
          value={initialFormData?.mailingAddress_voivodeship?.label}
          oldValue={previousInitialFormData?.mailingAddress_voivodeship?.label}
        />
        <RowField name="mailingAddress_postal_code" />
        <RowField
          name="mailingAddress_street"
          value={initialFormData?.mailingAddress_street?.label}
          oldValue={previousInitialFormData?.mailingAddress_street?.label}
        />
        <RowField name="mailingAddress_house_number" />
        <RowField name="mailingAddress_apartment_number" />
        <RowField name="mailingAddress_email" />
        <RowField name="mailingAddress_phone_number" />
        <RowField name="mailingAddress_epuap_box" />
      </FieldSorter>
    </View>
  );
}

export default MailingAddressPdf;
