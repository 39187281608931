export const ELEMENTS_DICTIONARIES_MAP = {
  innovationTypes: {
    innovationInTheBusinessProcess: {
      id: '/lsi/recruitments/api/innovation-types/88e22d22-05f3-498b-ba48-9989fc38107d',
      label: 'Innowacja w procesie biznesowym',
    },
    productInnovation: {
      id: '/lsi/recruitments/api/innovation-types/77ef4581-bbc8-48ac-8649-f82272573cb7',
      label: 'Innowacja produktowa',
    },
  },
  vatRecoveryPossibility: {
    yes: {
      id: '/lsi/recruitments/api/vat-recovery-possibilities/393d410f-96cb-4f18-9f77-81cc33fedc4d',
      label: 'Tak',
    },
    no: {
      id: '/lsi/recruitments/api/vat-recovery-possibilities/bd90829a-5509-4f29-a944-d5fe4de5c687',
      label: 'Nie',
    },
    partially: {
      id: '/lsi/recruitments/api/vat-recovery-possibilities/6043e630-b5ca-4fc6-a9b5-1a03eedf67d7',
      label: 'Częsciowo',
    },
  },
  initialInvestmentTypes: {
    investmentInTangibleFixedAssets: {
      id: '/lsi/recruitments/api/initial-investment-types/c405b29f-26c5-4c37-9a71-8a66e45d23ae',
      label: 'Inwestycja w rzeczowe aktywa trwałe lub wartości niematerialne i prawne związane z '
        + 'dywersyfikacją produkcji zakładu poprzez wprowadzenie produktów uprzednio nieprodukowanych w zakładzie',
    },
    investmentInTangibleFixedAssetsAndValues: {
      id: '/lsi/recruitments/api/initial-investment-types/d9ed2163-9eaf-4266-aaf0-5b2c59e5888c',
      label: 'Inwestycja w rzeczowe aktywa trwałe i wartości niematerialne i prawne związana z dywersyfikacją produkcji'
        + 'zakładu poprzez wprowadzenie produktów lub usług dotąd niewytwarzanych lub nieświadczonych przez ten zakład',
    },
  },
  internationalizationModuleEssenceScopes: {
    productsInternationalization: {
      id: '/lsi/recruitments/api/internationalization-module-essence-scopes/407e2047-4e9e-408b-ab6c-5fbb72b6473d',
      label: 'umiędzynarodowienie produktów',
    },
    propertyDefence: {
      id: '/lsi/recruitments/api/internationalization-module-essence-scopes/87626721-f470-4647-a9bf-1d6a2e498db8',
      label: 'obrona praw własności przemysłowej',
    },
    propertyProtection: {
      id: '/lsi/recruitments/api/internationalization-module-essence-scopes/cc8eaf58-ac8a-49de-9e80-7649092fb78c',
      label: 'uzyskanie ochrony praw własności przemysłowej',
    },
  },
  cooperationStatuses: {
    plannedPerson: {
      id: '/lsi/recruitments/api/cooperation-statuses/27be9c99-362b-4027-9fc1-6a5bfa9e1195',
      label: 'osoba planowana do zaangażowania',
    },
    consortiumEmployee: {
      id: '/lsi/recruitments/api/cooperation-statuses/9d61b219-765d-4085-8924-4e3b20cc354c',
      label: 'pracownik konsorcjanta',
    },
    agreementSignedByConsortium: {
      id: '/lsi/recruitments/api/cooperation-statuses/78eed84b-d0cb-4f34-8030-117e6d8ffc97',
      label: 'podpisana umowa warunkowa przez konsorcjanta',
    },
  },
  roleInProjects: {
    rdManager: {
      id: '/lsi/recruitments/api/role-in-projects/0b993a83-72a1-48c7-bb86-6512d09a5b63',
      label: 'Kierownik B+R',
    },
    rdModuleManager: {
      id: '/lsi/recruitments/api/role-in-projects/5f516780-50c3-4b75-a02f-38ed2912b70d',
      label: 'Kierownik zarządzający modułem B+R',
    },
    moduleManager: {
      id: '/lsi/recruitments/api/role-in-projects/905cba70-c726-4be8-a5f5-26c28bfb656d',
      label: 'Kierownik zarządzający modułem',
    },
  },
  ueHorizontalPoliciesProjectComplianceIsR6Rules: {
    project6R: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + '4f4f0c57-a180-48e9-b7ad-9a7f543251a7',
      label: 'Projekt będzie realizowany zgodnie z zasadami 6R',
    },
    projectPositiveInfluence: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + 'ffc0fdf9-33de-4608-85d8-6c98834d6e25',
      label: 'Projekt będzie miał pozytywny wpływ na inne aspekty środowiskowe (nie objęte zasadami 6R)',
    },
    withAtLeastTwoRulesFrom6R: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + '5b0f664a-6be4-4c02-a96e-11c8a5bea2d2',
      label: 'z co najmniej dwiema zasadami z 6R tj.odmów (refuse); ogranicz (reduce); używaj ponownie '
        + '(reuse); naprawiaj (recover); oddaj do recyklingu (recycle); zastanów się co możesz zrobić lepiej (rethink)',
    },
    withAtLeastOneRuleFrom6R: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + '5d664290-4483-4111-bc05-8f70ba4ceab0',
      label: 'z co najmniej jedną zasadą z 6R',
    },
    atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + 'c1580156-2fb2-4ede-a9de-68515c6765c4',
      label: 'co najmniej jedną zasadą z 6R i zgodnie z pozytywnym wpływem na inne aspekty środowiskowe '
        + 'w ramach projektu (niż zasady 6R)',
    },
    positiveImpactOnOtherEnvironmentalAspectsOfProject: {
      id: '/lsi/recruitments/api/ue-horizontal-policies-project-compliance-is-r6-rules/'
        + '814cc037-d9ee-44be-9e52-ea1173c5cbd3',
      label: 'pozytywnym wpływem na inne aspekty środowiskowe w ramach projektu (niż zasady 6R)',
    },
  },
  projectCollaborationTypes: {
    research: {
      id: '/lsi/recruitments/api/project-collaboration-types/1430470c-15ee-4445-a3a4-b17e0d9be76a',
      label: 'Wnioskodawca przewiduje współpracę z organizacją badawczą',
    },
    nonGovernmental: {
      id: '/lsi/recruitments/api/project-collaboration-types/39c2cfdb-f62d-4cdb-a438-2e47bacf2ecd',
      label: 'Wnioskodawca przewiduje współpracę z organizacją pozarządową',
    },
    notApplicable: {
      id: '/lsi/recruitments/api/project-collaboration-types/f2b1a4d2-ebcb-40a8-bb2c-5bd1eab5c471',
      label: 'Nie dotyczy',
    },
  },
  promotionalActivitiesType: {
    awayMission: {
      id: '/lsi/recruitments/api/promotional-activities-types/364a5cf8-b5af-4a68-b304-5e813910367b',
      label: 'misja wyjazdowa',
    },
    exhibitorFairs: {
      id: '/lsi/recruitments/api/promotional-activities-types/6eb56ac9-bf57-4cfc-a9c1-ec14eb580ab8',
      label: 'targi w roli wystawcy',
    },
    promotionalCampaign: {
      id: '/lsi/recruitments/api/promotional-activities-types/adeb9323-aa0c-46cf-bbca-f35045f322fe',
      label: 'kampania promocyjna',
    },
  },
  ueProgramType: {
    otherUeProgram: {
      id: '/lsi/recruitments/api/ue-program-types/d2fe6bf6-0ce7-446a-b624-9c47e680c710',
      label: 'Inny program',
    },
  },
  applicantUeProgramRole: {
    independentApplicant: {
      id: '/lsi/recruitments/api/applicant-ue-program-roles/f2b3dd21-d735-4645-8e1d-a5b3c08f0df3',
      label: 'SAMODZIELNIE APLIKUJĄCY',
    },
  },
};
