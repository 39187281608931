import { Link } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

/**
 * PARP info icon.
 *
 * @param {object} props - root props
 * @param {string} props.webpageUrl - webpage url
 * @returns {InfoIcon}
 */
export default function InfoIcon({ webpageUrl }) {
  return (
    <Link href={webpageUrl} target="_blank" rel="noopener">
      <InfoOutlined
        aria-label="ikona informacyjna"
        sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(1),
          top: 'calc(50% - 12px)',
          width: theme.spacing(3),
          color: theme.palette.secondary.main,
        })}
      />
    </Link>
  );
}
InfoIcon.propTypes = {
  webpageUrl: PropTypes.string,
};
InfoIcon.defaultProps = {
  webpageUrl: 'https://www.parp.gov.pl/',
};
