import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Regional investment aid digitization pdf element.
 *
 * @returns {RegionalInvestmentAidDigitizationPdf}
 */
function RegionalInvestmentAidDigitizationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const { initialInvestmentTypes } = ELEMENTS_DICTIONARIES_MAP;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="regional_investment_aid_digitization_is_regional" />
        {initialFormData?.regional_investment_aid_digitization_is_regional === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="regional_investment_aid_digitization_investment_aid">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.applicant_name`}
                      value={item.applicant_name}
                      oldValue={previousRowData?.applicant_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.initial_investment_type`}
                      dictionaryName={DICTIONARIES.initialInvestmentTypes.name}
                      value={item.initial_investment_type}
                      oldValue={previousRowData?.initial_investment_type}
                    />
                    <RowField
                      name={`${collectionName}.${index}.reason`}
                      value={item.reason}
                      oldValue={previousRowData?.reason}
                    />
                    {item.initial_investment_type === initialInvestmentTypes.investmentInTangibleFixedAssets.id && (
                      <>
                        <RowField
                          name={`${collectionName}.${index}.reused_assets`}
                          value={item.reused_assets}
                          oldValue={previousRowData?.reused_assets}
                        />
                        <RowField
                          name={`${collectionName}.${index}.reused_assets_book_value`}
                          value={item.reused_assets_book_value}
                          oldValue={previousRowData?.reused_assets_book_value}
                        />
                      </>
                    )}
                  </FieldSorter>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default RegionalInvestmentAidDigitizationPdf;
