import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { DICTIONARIES } from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';

/**
 * ForeignPartners element.
 *
 * @returns {ForeignPartners}
 */
export function ForeignPartners() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.geoDictionariesCountries,
  );

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'foreign_partners',
  });

  const foreignPartnersConfig = fieldsConfig.foreign_partners;
  const subfields = foreignPartnersConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = foreignPartnersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <LabeledFieldRow fieldConfig={foreignPartnersConfig} contextHelpLabel fullWidth>
      {fields.map((item, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'foreign_partners',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={item.id}
          isIndexed
        >
          {/* Nazwa zagranicznego partnera */}
          <FormField name={`foreign_partners.${index}.name`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Etykieta: Adres siedziby zagranicznego partnera */}
          <FormField name={`foreign_partners.${index}.head_office_address`} contextHelpLabel>
            {() => null}
          </FormField>
          {/* Kraj */}
          <FormField name={`foreign_partners.${index}.country`}>
            {({
              name, onChange, value, error, onBlur, filterOptions,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.geoDictionariesCountries.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Ulica */}
          <FormField name={`foreign_partners.${index}.street`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Nr budynku */}
          <FormField name={`foreign_partners.${index}.house_number`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Nr lokalu */}
          <FormField name={`foreign_partners.${index}.apartment_number`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Kod pocztowy */}
          <FormField name={`foreign_partners.${index}.postal_code`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Poczta */}
          <FormField name={`foreign_partners.${index}.post_office`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Miejscowość */}
          <FormField name={`foreign_partners.${index}.city`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Telefon */}
          <FormField name={`foreign_partners.${index}.phone_number`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Adres e-mail */}
          <FormField name={`foreign_partners.${index}.email`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Adres strony WWW */}
          <FormField name={`foreign_partners.${index}.web_page`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Opis wcześniej nawiązanej relacji zagranicznego partnera z Wnioskodawcą */}
          <FormField name={`foreign_partners.${index}.relationship_description`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                multiline
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Opis działalności zagranicznego partnera w kontekście planowanej współpracy w ramach
          programu akceleracji (projektu) */}
          <FormField name={`foreign_partners.${index}.activities_description`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Informacje na temat przewidywanej formy i zakresu zaangażowania zagranicznego partnera
          w program akceleracyjny w konkretnych działaniach */}
          <FormField name={`foreign_partners.${index}.information_on_engagement`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                multiline
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację o zaangażowaniu w projekt
           (dotyczy Wnioskodawców, którzy w części Koncepcja programu akceleracji wybrali ścieżkę „Go Global”)? */}
          <FormField name={`foreign_partners.${index}.accompanied_written_declaration`}>
            {({
              name, onChange, value, error, onBlur, allChoices,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}

      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="IWigCtQSzZmlLbT"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
