import {
  useMemo,
  useState,
  Fragment,
} from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Logo } from '../index';
import { getMenuStructure } from '../../_helpers';
import { useAuth } from '../../_security';
import { theme } from '../../styles/theme';

/**
 * Mobile navigation component.
 *
 * @returns {MobileNavigation}
 */
function MobileNavigation() {
  const {
    profile, authenticated,
  } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const [openSubMenuIds, setOpenSubMenuIds] = useState([]);

  const menuStructure = useMemo(() => getMenuStructure(profile), [profile, authenticated]);

  const renderMenuItem = ({
    title, id, children, url,
  }, parentId = null, nestedLevel = 1) => {
    const combinedOpenIdMenu = parentId ? `${parentId}-${id}` : id;

    if (url) {
      return (
        <Link
          to={url}
          style={{
            textDecoration: 'none',
            color: theme.palette.text.primary,
          }}
          onClick={() => setOpen(false)}
          key={id}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        </Link>
      );
    }

    const isClickedPositionOpen = openSubMenuIds.join('-') === combinedOpenIdMenu;

    return (
      <Fragment key={id}>
        <ListItem
          disablePadding
          key={id}
          sx={{
            borderBottom: openSubMenuIds.includes(id) ? `2px solid ${theme.palette.secondary.main}` : 'none',
          }}
          title={title}
        >
          <ListItemButton onClick={() => setOpenSubMenuIds(isClickedPositionOpen ? [] : combinedOpenIdMenu.split('-'))}>
            <ListItemText primary={title} />
          </ListItemButton>
        </ListItem>
        {children && (
          <Collapse in={openSubMenuIds.includes(id)} timeout="auto" unmountOnExit>
            <List sx={{ pl: theme.spacing(nestedLevel) }}>
              {children.map((childMenuItem) => renderMenuItem(childMenuItem, combinedOpenIdMenu, nestedLevel + 1))}
            </List>
          </Collapse>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <IconButton title="Otwórz mobilne menu" onClick={() => setOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer open={isOpen} onClose={() => setOpen(false)}>
        <Box width="250px" role="presentation">
          <Box p={2}>
            <Logo />
          </Box>
          <Divider />
          <List>
            {menuStructure.map((menuItem) => renderMenuItem(menuItem))}
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default MobileNavigation;
