import PropTypes from 'prop-types';
import {
  TextField,
  IconButton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import uniqid from 'uniqid';
import { copyToClipboard } from '../_helpers';
import { ImprovedMaskedInput } from './ImprovedMaskedInput';
import { useCustomSnackbar } from '../_hooks';
import CharsCounter from './CharsCounter';

/**
 * IbanField component
 *
 * @param {number} props - root props
 * @param {Function} props.onChange - callback fired when the value is changed
 * @param {Function} props.onBlur - function passed to TextField
 * @param {string} props.name - field name
 * @param {string|number} props.value - field value
 * @param {object} props.error - field errors
 * @param {string} props.successCopyNotificationText - success copy notification msg
 * @param {string} props.errorCopyNotificationText - error copy notification msg
 * @param {boolean} props.disabled - disabled prop passed to TextField
 * @param {string} props.label - label of field
 * @returns {IbanField}
 */
export function IbanField({
  onChange,
  value,
  name,
  onBlur,
  error,
  successCopyNotificationText,
  errorCopyNotificationText,
  disabled,
  label,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      error={!!error}
      helperText={error?.message}
      placeholder="__ ____ ____ ____ ____ ____ ____"
      onChange={onChange}
      onBlur={onBlur}
      variant="outlined"
      value={value}
      fullWidth
      InputProps={{
        inputComponent: ImprovedMaskedInput,
        endAdornment: (
          <>
            {!disabled && (
              <CharsCounter valueLength={value ? value.toString().replace(/\s/g, '').length : 0} maxLength={26} />
            )}
            <IconButton
              aria-label="Skopiuj IBAN do schowka"
              size="small"
              onClick={async () => {
                if (value.replace('PL', '').replace(/\s/g, '').length === 26) {
                  await copyToClipboard(value.replace('PL', '').replace(/\s/g, ''));
                  successNotification(successCopyNotificationText);

                  return;
                }
                errorNotification(errorCopyNotificationText);
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </>
        ),
      }}
      inputProps={{
        maxLength: 32, // 26 digits of IBAN number + 6 spaces
        mask: '00 0000 0000 0000 0000 0000 0000',
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      disabled={disabled}
    />
  );
}

IbanField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.objectOf(Object),
  successCopyNotificationText: PropTypes.string,
  errorCopyNotificationText: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

IbanField.defaultProps = {
  name: uniqid(),
  value: null,
  onChange: () => {},
  onBlur: () => {},
  error: null,
  successCopyNotificationText: 'Skopiowano numer IBAN do schowka',
  errorCopyNotificationText: 'Skopiowany numer jest niepoprawny',
  disabled: false,
  label: null,
};
