import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ProjectImplementationJustification Pdf element.
 *
 * @returns {ProjectImplementationJustificationPdf}
 */
function ProjectImplementationJustificationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();
  const fieldName = 'project_implementation_justification_improvements_removing_barriers_to_work_provision';

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* 1.Etykieta: Wykonawca audytu i strategii wzorniczej */}
        <RowField name="project_implementation_justification_audit_contractor" isSingleRow />
        {/* 2.Data rozpoczęcia przeprowadzenia audytu */}
        <RowField name="project_implementation_justification_audit_start_date" />
        {/* 3.Data zakończenia przeprowadzenia audytu */}
        <RowField name="project_implementation_justification_audit_end_date" />
        {/* 4.Nazwa wybranego wnioskodawcy */}
        <RowField name="project_implementation_justification_applicant_name" />
        {/* 5.Potencjał wybranego wykonawcy */}
        <RowField name="project_implementation_justification_applicant_potential" />
        {/* 6.NIP */}
        <RowField name="project_implementation_justification_nip" />
        {/* 7.Adres siedziby wykonawcy */}
        <RowField name="project_implementation_justification_registered_address" />
        {/* 8.Data podpisania umowy warunkowej z wykonawcą */}
        <RowField name="project_implementation_justificatio_date_signing_conditional_agreement" />
        {/* 9.Dotychczasowa działalność wykonawcy */}
        <RowField name="project_implementation_justification_past_performance" />
        {/* 10.Wyniki audytu wzorniczego */}
        <RowField name="project_implementation_justification_audit_result" />
        {/* 11.Data rozpoczęcia sporządzenia/ zatwierdzenia strategii */}
        <RowField name="project_implementation_justification_strategy_drafting_start_date" />
        {/* 12.Data zakończenia sporządzenia/ zatwierdzenia strategii */}
        <RowField name="project_implementation_justification_strategy_drafting_end_date" />
        {/* 13.Opis wdrożenia działań wskazanych w strategii wzorniczej */}
        <RowField name="project_implementation_justification_implementation_description" />
        {/* 14.Etykieta: Opis innowacji */}
        <RowField name="project_implementation_justification_innovation_description" isSingleRow />
        {/* 15.Etykieta: Innowacja produktowa */}
        <RowField name="project_implementation_justification_product_innovation" isSingleRow />
        {/* 16.Opis innowacji produktowej */}
        <RowField name="project_implementation_justification_product_innovation_description" />
        {/* 17.Innowacja w procesie biznesowym */}
        <RowField name="project_implementation_justification_innovation_in_business_process" />
        {initialFormData?.project_implementation_justification_innovation_in_business_process
        === BOOLEAN_VALUES.TRUE && (
          <RowField
            name="project_implementation_justification_description_innovation_in_business_process"
          />
        )}
        {/* 18.Wpływ realizacji projektu na działalność i rozwój wnioskodawcy */}
        <RowField name="project_implementation_justification_implementation_project_impact_on_applicant_operations" />

        {/* 19.Tabela: Zasady ekoprojektowania */}
        <CollectionWrapper name="project_implementation_justification_eco_design_principles">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.eco_design_stages`}
                      value={item.eco_design_stages}
                      oldValue={previousRowData?.eco_design_stages}
                      dictionaryName={DICTIONARIES.ecoDesignStages.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.justification`}
                      value={item.justification}
                      oldValue={previousRowData?.justification}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* 20.Etykieta: Wdrożenie nowego produktu nakierowanego na zapewnienie
      dostępności dla osób ze szczególnymi potrzebami */}
        <RowField name="project_implementation_justification_new_product_implementation" isSingleRow />
        {/* 21.Czy nowy produkt nakierowany jest na zapewnienie dostępności dla osób ze szczególnymi potrzebami */}
        <RowField name="project_implementation_justification_new_product_directed_at_ensuring_accessibility" />
        {initialFormData?.project_implementation_justification_new_product_directed_at_ensuring_accessibility
        === BOOLEAN_VALUES.TRUE && (
          <>
            {/* Charakterystyka grupy docelowej */}
            <RowField name="project_implementation_justification_target_group_characteristics" />
            {/* Opis innowacji w procesie biznesowym */}
            <RowField name="project_implementation_justification_new_features_description" />
          </>
        )}

        {/* Opis wdrażanych innowacji */}
        <CollectionWrapper name="project_implementation_justification_innovations_description">
          {({ collectionData }) => collectionData.map((item, index) => (
            <SingleCollectionWrapper key={item.set_uuid} index={index}>
              <View style={printStyle.table}>
                <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                  <RowField
                    name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_business_process_innovations`}
                    isSingleRow
                  />
                  <RowField
                    name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_innovation_scope`}
                    value={item.project_implementation_justification_innovation_scope}
                    dictionaryName={DICTIONARIES.innovationScope.name}
                  />
                  <RowField
                    name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_business_process_innovation_description`}
                    value={item.project_implementation_justification_business_process_innovation_description}
                    isSingleRow
                  />
                  <RowField
                    name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_process_removes_barriers_to_work_provision`}
                    value={item.project_implementation_justification_process_removes_barriers_to_work_provision}
                  />
                  {initialFormData?.project_implementation_justification_innovations_description[index]
                    .project_implementation_justification_process_removes_barriers_to_work_provision
                  === BOOLEAN_VALUES.TRUE && (
                    <>
                      <RowField
                        name={`project_implementation_justification_innovations_description.${index}.project_implementation_justification_workstation_adapted_to_disabled_people`}
                        value={item.project_implementation_justification_workstation_adapted_to_disabled_people}
                      />
                      <RowField
                        name={`project_implementation_justification_innovations_description.${index}.${fieldName}`}
                        value={item[fieldName]}
                      />
                    </>
                  )}
                  <RowField
                    name={`project_implementation_justification_innovations_description.${index}.scope_of_innovation`}
                    value={item.scope_of_innovation}
                    dictionaryName={DICTIONARIES.scopeOfInnovation.name}
                  />
                </FieldSorter>
              </View>
            </SingleCollectionWrapper>
          ))}
        </CollectionWrapper>
        {/* Dotychczasowa działalność wnioskodawcy, obecne produkty (wyroby, usługi) i pozycja rynkowa wnioskodawcy */}
        <RowField name="project_implementation_justification_applicant_activities_and_market_position" isSingleRow />

        {/* Etykieta: Zakres transformacji w kierunku GOZ */}
        <RowField name="project_implementation_justification_goz_transformation_scope" isSingleRow />
        {/* Czy dokonano autoanalizy przez formularz na stronie internetowej PARP? */}
        <RowField name="project_implementation_justification_parp_website_auto_analysis" />
        {/* Autoanaliza przedsiębiorstwa w zakresie potencjału GOZ */}
        <RowField name="project_implementation_justification_goz_potential_auto_analysis" isSingleRow />
        {/* Tabela: Obszar działalności przedsiębiorstwa podlegający transformacji GOZ */}
        <CollectionWrapper name="project_implementation_justification_operation_scope_goz_transformation_subject">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.operation_scope_goz_transformation_subject`}
                      value={item.operation_scope_goz_transformation_subject}
                      oldValue={previousRowData?.operation_scope_goz_transformation_subject}
                      dictionaryName={DICTIONARIES.operationScopeGozTransformationSubjects.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_scope_justification`}
                      oldValue={previousRowData?.goz_scope_justification}
                      value={item.goz_scope_justification}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Wykonawca Modelu GOZ- transformacji */}
        <RowField name="project_implementation_justification_goz_transformation_model_contractor" isSingleRow />
        {/* REGON */}
        <RowField name="project_implementation_justification_regon" />
        {/* Numer w Krajowym Rejestrze Sądowym */}
        <RowField name="project_implementation_justification_krs_number" />
        {/* Tabela: Doświadczenie eksperckie wykonawcy */}
        <CollectionWrapper name="project_implementation_justification_contractor_experience">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.first_name`}
                      value={item.first_name}
                      oldValue={previousRowData?.first_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.last_name`}
                      value={item.last_name}
                      oldValue={previousRowData?.last_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.education`}
                      value={item.education}
                      oldValue={previousRowData?.education}
                      dictionaryName={DICTIONARIES.education.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.scientific_title`}
                      value={item.scientific_title}
                      oldValue={previousRowData?.scientific_title}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.position`}
                      value={item.position}
                      oldValue={previousRowData?.position}
                      isSingleRow
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Tabela: Doświadczenie w przygotowaniu dokumentów związanych z GOZ */}
        <CollectionWrapper name="project_implementation_justification_goz_documents_preparation_experience">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.company_name`}
                      value={item.company_name}
                      oldValue={previousRowData?.company_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.document_name`}
                      value={item.document_name}
                      oldValue={previousRowData?.document_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.preparation_date`}
                      value={item.preparation_date}
                      oldValue={previousRowData?.preparation_date}
                    />
                    <RowField
                      name={`${collectionName}.${index}.document_scope`}
                      value={item.document_scope}
                      oldValue={previousRowData?.document_scope}
                      isSingleRow
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Tabela: Doświadczenie w przeprowadzeniu audytów z zakresu GOZ */}
        <CollectionWrapper name="project_implementation_justification_goz_audits_experience">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_company_name`}
                      value={item.goz_audit_company_name}
                      oldValue={previousRowData?.goz_audit_company_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_start_date`}
                      value={item.goz_audit_start_date}
                      oldValue={previousRowData?.goz_audit_start_date}
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_end_date`}
                      value={item.goz_audit_end_date}
                      oldValue={previousRowData?.goz_audit_end_date}
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_company_industry`}
                      value={item.goz_audit_company_industry}
                      oldValue={previousRowData?.goz_audit_company_industry}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_scope`}
                      value={item.goz_audit_scope}
                      oldValue={previousRowData?.goz_audit_scope}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_results`}
                      value={item.goz_audit_results}
                      oldValue={previousRowData?.goz_audit_results}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.goz_audit_results_implementation_experience`}
                      value={item.goz_audit_results_implementation_experience}
                      oldValue={previousRowData?.goz_audit_results_implementation_experience}
                      isSingleRow
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>

        {/* Cel projektu */}
        <RowField name="project_implementation_justification_project_goal" isSingleRow />
        {/* Opis realizacji projektu */}
        <RowField name="project_implementation_justification_description_implementation" isSingleRow />
        {/* Zakres działalności, której dotyczy projekt */}
        <RowField name="project_implementation_justification_range_activities" isSingleRow />
        {/* Działania dotyczące wdrożenia GOZ wynikające z rekomendacji zawartych w modelu biznesowym
      GOZ-transformacji */}
        <RowField name="project_implementation_justification_implementation_goz" isSingleRow />
        {/* Sposób wdrożenia w przedsiębiorstwie rekomendacji zawartych w w modelu biznesowym GOZ-transformacji */}
        <RowField name="project_implementation_justification_method_implementation_goz" isSingleRow />
        {/* Innowacja w procesie biznesowym (innowacja procesowa)-GOZ */}
        <RowField name="project_implementation_justification_innovation_in_business_process_goz" />
        {/* Poziom innowacyjności wdrażanego rozwiązania */}
        <RowField
          name="project_implementation_justification_level_innovation_implemented_solution"
          dictionaryName={DICTIONARIES.projectImplementationJustificationLevelInnovationImplementedSolution.name}
        />
        {/* Uzasadnienie poziomu innowacyjności rozwiązania */}
        <RowField name="project_implementation_justification_level_innovation_solution" isSingleRow />
        {/* Wpływ realizacji projektu w zakresie wdrażanej innowacji na działalność i rozwój wnioskodawcy */}
        <RowField name="project_implementation_justification_impact_implementation" isSingleRow />
        {/* Etykieta: Symbioza przemysłowa (współpraca z innymi przedsiębiorstwami/ przedsiębiorcami) */}
        <RowField name="project_implementation_justification_label_industrial_symbiosis" isSingleRow />
        {/* Czy projekt zakłada realizację działań w celu tworzenia symbiozy przemysłowej? */}
        <RowField name="project_implementation_justification_industrial_symbiosis" />
        {initialFormData?.project_implementation_justification_industrial_symbiosis === BOOLEAN_VALUES.TRUE && (
          <RowField
            name="project_implementation_justification_description_implementation_processindustrial_symbiosis"
            isSingleRow
          />
        )}
        {/* Etykieta:Zielone zamówienia publiczne */}
        <RowField name="project_implementation_justification_label_green_public_procurement" isSingleRow />
        {/* Czy w projekcie będą stosowane zielone zamówienia publiczne? */}
        <RowField name="project_implementation_justification_green_public_procurement" />
        {initialFormData?.project_implementation_justification_green_public_procurement === BOOLEAN_VALUES.TRUE && (
          <RowField
            name="project_implementation_justification_description_implementation_process_green_public_procurement"
            isSingleRow
          />
        )}
      </FieldSorter>
    </View>
  );
}

export default ProjectImplementationJustificationPdf;
