import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useStylesDataGridTable } from '../../../../../styles/dataGridTable';

/**
 * Expenses summary table component
 *
 * @param {object} props - root props
 * @param {object} props.data - expense element data
 * @returns {ExpensesSummaryTable}
 */
function ExpensesSummaryTable({ data }) {
  const numberFormat = (value) => (
    <NumericFormat
      thousandSeparator=" "
      isNumericString
      value={value}
      disabled
      style={useStylesDataGridTable.numberField}
    />
  );

  return (
    <TableContainer component="paper">
      <Table sx={useStylesDataGridTable.table}>
        <TableHead>
          <TableRow>
            <TableCell>Grupa kategorii</TableCell>
            <TableCell>Kategoria Kosztu</TableCell>
            <TableCell>Wydatki ogółem</TableCell>
            <TableCell>Wydatki kwalifikowane</TableCell>
            <TableCell>Dofinansowanie</TableCell>
            <TableCell>% dofinansowania</TableCell>
          </TableRow>
        </TableHead>
        {data.items.map((group) => (
          <TableBody>
            {group.items.map((expense, expenseIndex) => (
              <TableRow>
                {expenseIndex === 0 && (
                  <TableCell rowSpan={group.items.length}>{group.name}</TableCell>
                )}
                <TableCell>{expense.name}</TableCell>
                <TableCell>{numberFormat(expense.total_expenses)}</TableCell>
                <TableCell>{numberFormat(expense.eligible_expenses)}</TableCell>
                <TableCell>{numberFormat(expense.co_financing)}</TableCell>
                <TableCell>{expense.co_financing_percent}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>Suma ( dla każdej grupy )</TableCell>
              <TableCell>{numberFormat(group.total_expenses)}</TableCell>
              <TableCell>{numberFormat(group.eligible_expenses)}</TableCell>
              <TableCell>{numberFormat(group.co_financing)}</TableCell>
              <TableCell>{group.co_financing_percent}</TableCell>
            </TableRow>
          </TableBody>
        ))}

        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>Suma wydatków</TableCell>
            <TableCell>{numberFormat(data.total_expenses)}</TableCell>
            <TableCell>{numberFormat(data.eligible_expenses)}</TableCell>
            <TableCell>{numberFormat(data.co_financing)}</TableCell>
            <TableCell>{data.co_financing_percent}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ExpensesSummaryTable;

ExpensesSummaryTable.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
