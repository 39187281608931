import {
  Button,
  Collapse,
  TableCell,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useFormContext } from 'react-hook-form';
import IndicatorForm from './Indicator.form';
import OwnIndicatorForm from './OwnIndicator.form';
import { DICTIONARIES } from '../../../../_constants';
import { useElementContext } from '../../../../Context';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';

/**
 * Indicator row component
 *
 * @param {object} props - root props
 * @param {object} props.row - row data
 * @param {string} props.currentRow - current row id
 * @param {Function} props.handleOpenRow - row open handler
 * @param {number} props.index - row index
 * @param {string} props.indicatorName - indicator name
 * @param {Function} props.onRemove - remove indicator
 * @returns {IndicatorRow}
 */
function IndicatorRow({
  row, handleOpenRow, currentRow, index, indicatorName, onRemove,
}) {
  const { watch } = useFormContext();
  const { id: templateElementId } = useElementContext();

  const {
    get,
  } = useDictionaryLoader(DICTIONARIES.measureUnits);

  const isOwnIndicator = watch(`${indicatorName}-${templateElementId}.${index}.own`);
  const unitLabel = get(DICTIONARIES.measureUnits.name)
    .find((unit) => unit['@id'] === watch(`${indicatorName}-${templateElementId}.${index}.unit`))?.name || '';

  return (
    <>
      <TableRow>
        <TableCell sx={{ border: 0 }}>
          {isOwnIndicator
            ? watch(`${indicatorName}-${templateElementId}.${index}.name`) || 'Brak nazwy wskaźnika' : row.name}
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {isOwnIndicator ? unitLabel : row.unit}
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {isOwnIndicator
            ? watch(`${indicatorName}-${templateElementId}.${index}.target_value_o`) || 0 : row.target_value_o}
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          <Button
            id={uniqid()}
            type="button"
            variant="outlined"
            color="secondary"
            onClick={() => handleOpenRow(row.id)}
          >
            Pokaż
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={4}
        >
          <Collapse in={currentRow === row.id} timeout="auto" unmountOnExit>
            {isOwnIndicator ? (
              <OwnIndicatorForm indicatorName={indicatorName} index={index} onRemove={onRemove} />
            ) : (
              <IndicatorForm indicatorName={indicatorName} index={index} />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default IndicatorRow;

IndicatorRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  currentRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  handleOpenRow: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  indicatorName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

IndicatorRow.defaultProps = {
  currentRow: null,
};
