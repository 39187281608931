import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import InfoPopover from '../../InfoPopover/InfoPopover';
import { theme } from '../../../styles/theme';

const classes = {
  label: {
    fontWeight: theme?.typography?.fontWeightBold,
    backgroundColor: theme?.palette?.brandGray10?.main,
    padding: theme?.typography?.baseHeading?.marginTop,
    fontSize: '1.1em',
  },
  regularRow: {
    fontWeight: theme?.typography?.fontWeightRegular,
  },
};

/**
 * Represents field with label.
 * It also renders help button with text tooltip (only if field config contains `help` key).
 *
 * @param {object} props - root props
 * @param {object} props.fieldConfig - field config from application template
 * @param {Element|Element[]|Node|Node[]} props.children - any children
 * @param {boolean} props.hide - content will not be rendered
 * @param {object} props.labelContainerProps - label container props
 * @param {boolean} props.contextHelpLabel - shows context help next to label
 * @param {string} props.extraLabel - show extra label for field
 * @returns {LabeledFieldRow}
 */
export function LabeledFieldRow({
  fieldConfig, children, hide, labelContainerProps, contextHelpLabel, extraLabel,
}) {
  if (!fieldConfig || hide) {
    return '';
  }

  const {
    name, label, title, help, type,
  } = fieldConfig;

  const labelClassName = (type === 'label' || type === 'collection') && !labelContainerProps.fontWeight
    ? classes.label
    : classes.regularRow;

  return (
    <Grid item xs={12}>
      {extraLabel && (
        <Typography
          fontSize={16}
          textAlign="left"
          fontWeight="bold"
          mb={1.5}
        >
          {extraLabel}
        </Typography>
      )}
      <Box
        sx={labelClassName}
        display="flex"
        alignItems="center"
        {...labelContainerProps}
      >
        <InputLabel
          htmlFor={name}
          sx={{
            position: 'relative',
            fontSize: 'inherit',
            color: 'inherit',
            whiteSpace: 'unset',
            display: 'inline-block',
            textAlign: 'left',
            ...labelClassName,
          }}
        >
          {label || title}
        </InputLabel>
        {help && contextHelpLabel && <InfoPopover noBorder>{help}</InfoPopover>}
      </Box>
      <Box display="flex" mt={0.5}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          {children}
        </FormControl>
        {help && !contextHelpLabel && <InfoPopover>{help}</InfoPopover>}
      </Box>
    </Grid>
  );
}

LabeledFieldRow.propTypes = {
  fieldConfig: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(Object),
    PropTypes.arrayOf(Object),
    PropTypes.arrayOf(Node),
  ]).isRequired,
  hide: PropTypes.bool,
  fullWidth: PropTypes.bool,
  labelContainerProps: PropTypes.instanceOf(Object),
  contextHelpLabel: PropTypes.bool,
  extraLabel: PropTypes.string,
};

LabeledFieldRow.defaultProps = {
  hide: false,
  fullWidth: false,
  labelContainerProps: {},
  contextHelpLabel: false,
  extraLabel: '',
};
