import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import RowField from '../../Components/RowField/RowField';
import RowConfig from '../../Components/RowConfig/RowConfig';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';
import { getModules } from '../../../../_helpers';
import { MODULE_IDS } from '../../../../_constants';

/**
 * LinkBetweenModulesPdf element.
 *
 * @returns {LinkBetweenModulesPdf}
 */
function LinkBetweenModulesPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    application,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const getRow = (name, moduleId) => (
    modules.some(({ id }) => id === moduleId)
    && getModules(name, null, modules).length > 1 && (
      <RowField
        name={name}
        externalDictionary={[
          ...getModules(name, initialFormData[name], modules),
          ...getModules(name, previousInitialFormData[name], modules),
        ]}
        skipFilterDictionary
      />
    )
  );

  return (
    <View style={printStyle.table}>
      <PrintoutTableRow>
        <RowConfig name="link_between_modules_name">
          {({ label }) => (
            <PrintoutTableCell rowSpan={2} bgColor="gray" isTextBold>
              {label}
            </PrintoutTableCell>
          )}
        </RowConfig>
        <RowConfig name="link_between_modules_link_modules">
          {({ label }) => (
            <PrintoutTableCell rowSpan={2} bgColor="gray" isTextBold>
              {label}
            </PrintoutTableCell>
          )}
        </RowConfig>
      </PrintoutTableRow>
      {getRow('link_between_modules_br', MODULE_IDS.br)}
      {getRow('link_between_modules_innovation_implementation', MODULE_IDS.innovationImplementation)}
      {getRow('link_between_modules_rd_infrastructure', MODULE_IDS.rdInfrastructure)}
      {getRow('link_between_modules_digitization', MODULE_IDS.digitization)}
      {getRow('link_between_modules_greening', MODULE_IDS.greening)}
      {getRow('link_between_modules_competences', MODULE_IDS.competences)}
      <RowField name="link_between_modules_explanation" isSingleRow />
    </View>
  );
}

export default LinkBetweenModulesPdf;
