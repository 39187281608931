import {
  useCallback,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ACTION_TYPES } from '../../_constants';
import { Alert } from '../Alert';
import { useGlobalContext } from '../../Context';

/**
 * Notifications component based on context dispatch.
 *
 * Message timeout can be configured as env variable.
 *
 * @returns {GlobalNotification}
 */
export function GlobalNotification() {
  const {
    root: { globalNotification }, handleAction,
  } = useGlobalContext();
  const globalNotificationTimeout = parseInt(process.env.REACT_APP_GLOBAL_NOTIFICATION_TIMEOUT, 10);
  const location = useLocation();

  const clearGlobalNotification = useCallback(() => {
    handleAction({ type: ACTION_TYPES.CLEAR_GLOBAL_NOTIFICATION });
  }, []);

  useEffect(() => {
    if (globalNotificationTimeout > 0) {
      setTimeout(() => {
        if (globalNotification && globalNotification.title) {
          clearGlobalNotification();
        }
      }, globalNotificationTimeout);
    }
  }, [clearGlobalNotification, globalNotification, globalNotificationTimeout]);

  useEffect(() => {
    clearGlobalNotification();
  }, [location.pathname]);

  return (
    <>
      {globalNotification && globalNotification.title && (
        <Alert
          fullWidth
          severity={globalNotification.severity}
          closeHandler={clearGlobalNotification}
          modal={globalNotification.modal}
        >
          {globalNotification.title}
        </Alert>
      )}
    </>
  );
}
