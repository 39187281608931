export const GROUP_NAMES = {
  main: 'main',
  krok1: 'krok_1',
  krok2: 'krok_2',
};

export const GROUPS = [
  GROUP_NAMES.main,
  GROUP_NAMES.krok1,
  GROUP_NAMES.krok2,
];

export const DIALOG_TYPES = {
  APPLICATION_MENU: 'APPLICATION_MENU',
  MODULES: 'MODULES',
  STEPS: 'STEPS',
  LOGOTYPES: 'LOGOTYPES',
  OPERATORS: 'OPERATORS',
  HRF_VALIDATIONS: 'HRF_VALIDATIONS',
};

export const ACTION_BUTTONS = [
  {
    id: 'x7FGt2W7A0J9qpR',
    label: 'Konfigurator menu wniosku',
    dialogType: DIALOG_TYPES.APPLICATION_MENU,
  },
  {
    id: 'fuDYOGkmW1eshV5',
    label: 'Konfigurator modułów',
    dialogType: DIALOG_TYPES.MODULES,
  },
  {
    id: 'G9oYYeoYNUG7vMO',
    label: 'Konfigurator kroków',
    dialogType: DIALOG_TYPES.STEPS,
  },
  {
    id: 'Kf83q1blfScJuRx',
    label: 'Konfigurator logotypów',
    dialogType: DIALOG_TYPES.LOGOTYPES,
  },
  {
    id: 'k4Gg90hNgLlGO57',
    label: 'Konfigurator operatorów',
    dialogType: DIALOG_TYPES.OPERATORS,
  },
  {
    id: 'mkHsh5WTE586uli',
    label: 'Konfigurator walidacji',
    dialogType: DIALOG_TYPES.HRF_VALIDATIONS,
  },
];

export const FIELD_TYPES = {
  limitedChoice: 'limited_choice',
  choice: 'choice',
  date: 'date',
  attachments: 'attachments',
};
