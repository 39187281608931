import {
  Font,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  red,
  green,
} from '@mui/material/colors';
import { theme } from '../../styles/theme';
import montserratLight from '../../fonts/Montserrat-Light.ttf';
import montserratRegular from '../../fonts/Montserrat-Regular.ttf';

// Register Font
Font.register({
  family: 'Montserrat-Light',
  src: montserratLight,
});

Font.register({
  family: 'Montserrat-Regular',
  src: montserratRegular,
});
export const printStyle = StyleSheet.create({
  body: {
    padding: 50,
    fontSize: 12,
    lineHeight: 1.2,
    fontFamily: 'Montserrat-Light',
  },
  header: {
    paddingBottom: 20,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 15,
    fontFamily: 'Montserrat-Regular',
  },
  sectionTitle: {
    fontSize: 14,
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 10,
    fontFamily: 'Montserrat-Regular',
  },
  subSectionTitle: {
    fontSize: 12,
    textAlign: 'left',
    marginTop: 10,
    paddingLeft: 5,
    fontFamily: 'Montserrat-Regular',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: '50px',
    textAlign: 'right',
    color: theme.palette.text.main,
  },
  applicationNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: '50px',
    right: 0,
    textAlign: 'left',
    color: theme.palette.text.main,
  },
  table: {
    marginTop: 10,
    marginBottom: 15,
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: theme.palette.brandGray10.contrastText,
  },
  sectionWrapperWithMargin: {
    marginBottom: 20,
  },
  listItem: {
    width: '100%',
    marginBottom: 5,
  },
  listItemText: {
    fontSize: 8,
    color: theme.palette.brandGray10.contrastText,
  },
  listItemTextTitle: {
    fontSize: 10,
    color: theme.palette.brandGray.main,
  },
  listWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
    paddingVertical: 7,
    paddingHorizontal: 5,
    borderStyle: 'solid',
    borderColor: theme.palette.brandGray10.contrastText,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 10,
  },
  watermarkWrapper: {
    position: 'absolute',
    fontSize: 60,
    top: '50%',
    left: 0,
    right: 0,
    transform: 'rotate(-45deg)',
    transformOriginY: '-50%',
    textTransform: 'uppercase',
    opacity: 0.5,
    textAlign: 'center',
    color: theme.palette.brandGray60.main,
  },
  added: {
    backgroundColor: green[200],
    color: theme.palette.success.dark,
  },
  removed: {
    backgroundColor: red[200],
    color: theme.palette.error.dark,
  },
});
