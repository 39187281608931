import {
  EXPERT_ROLES,
  EXPERT_ROLES_TRANSLATIONS,
} from '../_constants';

/**
 * expert roles values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const expertRolesOptions = [
  {
    id: EXPERT_ROLES.leadingExpert,
    label: EXPERT_ROLES_TRANSLATIONS.leadingExpert,
  },
  {
    id: EXPERT_ROLES.expert,
    label: EXPERT_ROLES_TRANSLATIONS.expert,
  },
  {
    id: EXPERT_ROLES.financialExpert,
    label: EXPERT_ROLES_TRANSLATIONS.financialExpert,
  },
];
