import {
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ReactComponent as Faq } from '../images/chat.svg';
import { ReactComponent as Email } from '../images/mail.svg';
import { ContactForm } from './Contact';
import { UserGuideCards } from './UserGuideCards';
import { theme } from '../styles/theme';

const classes = {
  helpBarWrapper: {
    width: 'calc(100% + 48px)',
    marginTop: theme.spacing(10),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    backgroundColor: '#f2f2f2',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
  },
  icon: {
    marginRight: theme.spacing(3),
    fontSize: '3rem',
    width: theme.spacing(7.5),
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  helpType: {
    display: 'inline-block',
    fontSize: '1rem',
    verticalAlign: 'top',
    paddingTop: theme.spacing(1),
  },
  helpBarHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    fontSize: '1.8rem',
  },
};

/**
 * Helpbar component.
 *
 * @returns {HelpBar}
 */
export default function HelpBar() {
  const [contactFormOpen, setContactFormOpened] = useState(false);
  const [userGuideOpen, setUserGuideOpen] = useState(false);

  return (
    <>
      <Paper
        id="DkXgghH8Zkk3203"
        sx={classes.helpBarWrapper}
        component="aside"
      >
        <Typography variant="h4" sx={classes.helpBarHeader}>Masz problem z logowaniem?</Typography>
        <Link
          id="WHJjMWsrJHLnTeI"
          href="#userGuideContainer"
          onClick={() => {
            setContactFormOpened(false);
            setUserGuideOpen(!userGuideOpen);
          }}
          color="inherit"
          component="button"
          sx={{ mr: () => theme.spacing(8) }}
          aria-controls="userGuideContainer"
          aria-expanded={userGuideOpen}
        >
          <Faq style={classes.icon} aria-hidden="true" />
          <span style={classes.helpType}>
            Sprawdź Centrum pomocy
          </span>
        </Link>
        <Link
          id="NdH7Z5Xnh8YgVl4"
          href="#contactFormContainer"
          onClick={() => {
            setUserGuideOpen(false);
            setContactFormOpened(!contactFormOpen);
          }}
          color="inherit"
          aria-controls="contactFormContainer"
          aria-expanded={contactFormOpen}
          component="button"
        >
          <Email style={classes.icon} aria-hidden="true" />
          <span style={classes.helpType}>Napisz do nas</span>
        </Link>
      </Paper>
      {contactFormOpen && (
        <ContactForm
          ariaLabelledBy="contactFormLink"
          onSuccess={() => {
            setContactFormOpened(false);
            window.scroll(0, 0);
          }}
        />
      )}
      {userGuideOpen && (
        <UserGuideCards />
      )}
    </>
  );
}
