import {
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

/**
 * CollectionField field component
 *
 * @returns {CollectionField}
 */
export function CollectionField() {
  const { control } = useFormContext();

  return (
    <>
      <FormControl fullWidth margin="normal">
        <Controller
          name="minRowNumber"
          control={control}
          render={({
            field: {
              value, name, onChange,
            },
            fieldState: {
              error,
            },
          }) => (
            <NumericFormat
              name={name}
              onValueChange={(values) => {
                onChange(values.floatValue);
              }}
              defaultValue={value}
              allowNegative={false}
              label="Minimalna liczba kolekcji"
              error={!!error}
              helperText={error?.message}
              customInput={TextField}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="maxRowNumber"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <NumericFormat
              name={name}
              onValueChange={(values) => {
                onChange(values.floatValue);
              }}
              defaultValue={value}
              allowNegative={false}
              label="Maksymalna liczba kolekcji"
              error={!!error}
              helperText={error?.message}
              customInput={TextField}
            />
          )}
        />
      </FormControl>
    </>
  );
}
