import PropTypes from 'prop-types';
import { Fragment } from 'react';
import PrintoutTableRow from '../../../PrintToPdf/Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../PrintToPdf/Components/Table/PrintoutTableCell';

/**
 * QuestionsList element.
 *
 * @param {object} props - root props
 * @param {Array} props.questions - questions
 * @returns {QuestionsList}
 */
function QuestionsList({ questions }) {
  return questions.map(({
    question, answer, id: questionId,
  }) => (
    <Fragment key={questionId}>
      <PrintoutTableRow>
        <PrintoutTableCell bgColor="gray" isTextBold rowSpan={1}>
          {`Pytanie: ${question}`}
        </PrintoutTableCell>
      </PrintoutTableRow>
      <PrintoutTableRow>
        <PrintoutTableCell rowSpan={1}>
          {`Odpowiedź: ${answer?.answer || 'Odpowiedź na pytanie nie została udzielona.'}`}
        </PrintoutTableCell>
      </PrintoutTableRow>
    </Fragment>
  ));
}

export default QuestionsList;

QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(Object).isRequired,
};
