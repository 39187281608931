import {
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../print.style';
import { PDF_ROW_STATUS } from '../../../../_constants';

const singleElementStyle = StyleSheet.create({
  elementWrapper: {
    marginBottom: 20,
  },
});

/**
 * Pdf single collection wrapper
 *
 * @param {object} props - root props
 * @param {number} props.index - set index
 * @param {Node} props.children - children component
 * @param {string} props.rowStatus - row status
 * @returns {SingleCollectionWrapper}
 */
function SingleCollectionWrapper({
  index, children, rowStatus,
}) {
  const additionalStyles = rowStatus in printStyle ? {
    ...printStyle[rowStatus],
    padding: 10,
  } : {};

  return (
    <View style={{
      ...singleElementStyle.elementWrapper,
      ...additionalStyles,
    }}
    >
      <Text style={printStyle.sectionTitle}>
        {index + 1}
      </Text>
      {children}
    </View>
  );
}

export default SingleCollectionWrapper;

SingleCollectionWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  rowStatus: PropTypes.oneOf([PDF_ROW_STATUS.added, PDF_ROW_STATUS.removed, null]),
};

SingleCollectionWrapper.defaultProps = {
  rowStatus: null,
};
