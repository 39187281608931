import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';

/**
 * ApplicantExperienceInImplementationOfGrantProjects pdf element.
 *
 * @returns {ApplicantExperienceInImplementationOfGrantProjects}
 */
function ApplicantExperienceInImplementationOfGrantProjects() {
  return (
    <CollectionWrapper name="applicant_experience_in_implementation_of_grant_projects">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.name`}
                value={item.name}
                oldValue={previousRowData?.name}
              />
              <RowField
                name={`${collectionName}.${index}.title`}
                value={item.title}
                oldValue={previousRowData?.title}
              />
              <RowField
                name={`${collectionName}.${index}.budget`}
                value={item.budget}
                oldValue={previousRowData?.budget}
              />
              <RowField
                name={`${collectionName}.${index}.implementation_start_date`}
                value={item.implementation_start_date}
                oldValue={previousRowData?.implementation_start_date}
              />
              <RowField
                name={`${collectionName}.${index}.implementation_end_date`}
                value={item.implementation_end_date}
                oldValue={previousRowData?.implementation_end_date}
              />
              <RowField
                name={`${collectionName}.${index}.subject_and_purpose`}
                value={item.subject_and_purpose}
                oldValue={previousRowData?.subject_and_purpose}
              />
              <RowField
                name={`${collectionName}.${index}.final_recipients`}
                value={item.final_recipients}
                oldValue={previousRowData?.final_recipients}
              />
              <RowField
                name={`${collectionName}.${index}.scope_of_financial_or_non_financial_support`}
                value={item.scope_of_financial_or_non_financial_support}
                oldValue={previousRowData?.scope_of_financial_or_non_financial_support}
              />
              <RowField
                name={`${collectionName}.${index}.entities_involved_in_the_implementation_of_the_project`}
                value={item.entities_involved_in_the_implementation_of_the_project}
                oldValue={previousRowData?.entities_involved_in_the_implementation_of_the_project}
              />
              <RowField
                name={`${collectionName}.${index}.tasks_and_role_of_the_applicant`}
                value={item.tasks_and_role_of_the_applicant}
                oldValue={previousRowData?.tasks_and_role_of_the_applicant}
              />
              <RowField
                name={`${collectionName}.${index}.implementation_effects`}
                value={item.implementation_effects}
                oldValue={previousRowData?.implementation_effects}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ApplicantExperienceInImplementationOfGrantProjects;
