import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import NewsDialog from './News.dialog';
import { newsStyleClasses } from './News.list.style';
import { theme } from '../../../styles/theme';
import { formatDateString } from '../../../_helpers';
import { DATE } from '../../../_constants';

/**
 * SingleNews element.
 *
 * @param {object} props - root props
 * @param {object} props.newsData - single news data
 * @returns {SingleNews}
 */
function SingleNews({ newsData }) {
  const classes = newsStyleClasses;

  const NewsItem = styled('li')({
    listStyle: 'none',
    borderLeft: '2px solid #c9c7c7',
    paddingBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  });

  return (
    <NewsItem>
      <div style={classes.titleDot} />
      <NewsDialog newsData={newsData} sx={classes.itemTitle}>
        {formatDateString(newsData.pubDate, DATE.defaultFormatWithoutTime)}
      </NewsDialog>
      <NewsDialog newsData={newsData} sx={classes.itemContent}>
        {newsData.title}
      </NewsDialog>
    </NewsItem>
  );
}

export default SingleNews;

SingleNews.propTypes = {
  newsData: PropTypes.instanceOf(Object).isRequired,
};
