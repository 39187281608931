import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../../Context';
import { DICTIONARIES } from '../../../../../_constants';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * InternationalizationModuleEssencePropertyDefencePdf element.
 *
 * @returns {InternationalizationModuleEssencePropertyDefencePdf}
 */
function InternationalizationModuleEssencePropertyDefencePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  // eslint-disable-next-line max-len
  const otherModeOfActionId = '/lsi/recruitments/api/internationalization-module-essence-mode-of-actions/1e91d033-ef91-460d-87ce-06be88b7afc5';

  return (
    <CollectionWrapper name="internationalization_module_essence_property_defence">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.applicant_name`}
                  value={item.applicant_name}
                  oldValue={previousRowData?.applicant_name}
                  externalDictionary={combinedApplicantName}
                />
                <RowField name={`${collectionName}.${index}.protection_law_data`} isSingleRow />
                <RowField
                  name={`${collectionName}.${index}.protection_law_title`}
                  value={item.protection_law_title}
                  oldValue={previousRowData?.protection_law_title}
                />
                <RowField
                  name={`${collectionName}.${index}.protection_law_category`}
                  value={item.protection_law_category}
                  oldValue={previousRowData?.protection_law_category}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceLawCategories.name}
                />
                <RowField
                  name={`${collectionName}.${index}.decision_date`}
                  value={item.decision_date}
                  oldValue={previousRowData?.decision_date}
                />
                <RowField
                  name={`${collectionName}.${index}.protection_law_number`}
                  value={item.protection_law_number}
                  oldValue={previousRowData?.protection_law_number}
                />
                <RowField
                  name={`${collectionName}.${index}.mode_of_action`}
                  value={item.mode_of_action}
                  oldValue={previousRowData?.mode_of_action}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceModeOfActions.name}
                />
                {item.mode_of_action === otherModeOfActionId && (
                  <RowField
                    name={`${collectionName}.${index}.other_ticket_mode`}
                    value={item.other_ticket_mode}
                    oldValue={previousRowData?.other_ticket_mode}
                  />
                )}
                <RowField
                  name={`${collectionName}.${index}.subject_of_action`}
                  value={item.subject_of_action}
                  oldValue={previousRowData?.subject_of_action}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceActionSubjects.name}
                />
                <RowField
                  name={`${collectionName}.${index}.action_justification`}
                  value={item.action_justification}
                  oldValue={previousRowData?.action_justification}
                />
                <RowField
                  name={`${collectionName}.${index}.action_parties`}
                  value={item.action_parties}
                  oldValue={previousRowData?.action_parties}
                />
                <RowField
                  name={`${collectionName}.${index}.department_action`}
                  value={item.department_action}
                  oldValue={previousRowData?.department_action}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default InternationalizationModuleEssencePropertyDefencePdf;
