import PropTypes from 'prop-types';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import PrintoutApplicationButton from './PrintoutApplicationButton';
import {
  API_ROUTE,
  DICTIONARIES,
} from '../../../_constants';
import { iriToId } from '../../../_helpers';

/**
 * Printout application dictionaries
 *
 * @param {object} props - root props
 * @param {object} props.application - application data
 * @param {object} props.managedApplicationTemplate - managed application template
 * @param {string} props.applicationId - application id
 * @param {Function} props.setApplicationData - set application data
 * @param {boolean} props.isReadonly - is readonly
 * @param {object} props.previousInitialFormData - previous initial form data
 * @param {boolean} props.withChanges - show differences between old and new data
 * @param {Function} props.backdrop - backdrop
 * @returns {PrintoutApplicationDictionaries}
 */
function PrintoutApplicationDictionaries({
  application,
  managedApplicationTemplate,
  applicationId,
  setApplicationData,
  isReadonly,
  previousInitialFormData,
  withChanges,
  backdrop,
}) {
  const additionalDictionaries = [];

  const {
    allDictionariesList,
    elementsConfig,
    initialFormData,
  } = managedApplicationTemplate;

  if (
    Object.values(elementsConfig)
      .some(({ elementData }) => elementData.elementDefinition.name === 'project_implementation_area')
  ) {
    initialFormData?.implementation_addresses?.forEach((implementationAddress, index) => {
      if (implementationAddress.district) {
        additionalDictionaries.push({
          name: `implementation_addresses.${index}.district_dictionary`,
          path: `${API_ROUTE.geoDictionaries.districts}/${iriToId(implementationAddress.district)}`,
        });
      }

      if (implementationAddress.commune) {
        additionalDictionaries.push({
          name: `implementation_addresses.${index}.commune_dictionary`,
          path: `${API_ROUTE.geoDictionaries.communes}/${iriToId(implementationAddress.commune)}`,
        });
      }

      if (implementationAddress.city) {
        additionalDictionaries.push({
          name: `implementation_addresses.${index}.city_dictionary`,
          path: `${API_ROUTE.geoDictionaries.towns}/${iriToId(implementationAddress.city)}`,
        });
      }
    });
  }

  if (
    Object.values(elementsConfig)
      .some(({ elementData }) => elementData.elementDefinition['@type'] === 'Attachments')
  ) {
    const { elementData: attachmentData } = Object.values(elementsConfig)
      .find(({ elementData }) => elementData.elementDefinition['@type'] === 'Attachments');

    const attachmentFields = attachmentData.elementDefinition.fields;

    attachmentFields.forEach(({ name }) => {
      const attachments = initialFormData[name] || [];
      const requestQuery = `id[]=${attachments
        .filter((element) => !!element)
        .map((iri) => iriToId(iri)).join('&id[]=')}`;

      additionalDictionaries.push({
        name,
        path: `${API_ROUTE.applications}/${applicationId}/attachments?itemsPerPage=100&${requestQuery}`,
      });
    });
  }

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    ...allDictionariesList,
    ...additionalDictionaries,
    {
      name: 'graphic_attachment',
      path: API_ROUTE.elementDictionaries.rdInfrastructureAttachments.replace(':id', applicationId),
    },
    DICTIONARIES.measureUnits,
    DICTIONARIES.pkdCodes,
    DICTIONARIES.interventionScopes,
    DICTIONARIES.geoDictionariesCountries,
  );

  if (!isLoaded) {
    return (backdrop(!isLoaded));
  }

  return (
    <PrintoutApplicationButton
      application={application}
      managedApplicationTemplate={managedApplicationTemplate}
      getDictionary={get}
      setApplicationData={setApplicationData}
      isReadonly={isReadonly}
      previousInitialFormData={previousInitialFormData}
      withChanges={withChanges}
      backdrop={backdrop}
    />
  );
}

export default PrintoutApplicationDictionaries;

PrintoutApplicationDictionaries.propTypes = {
  application: PropTypes.objectOf(Object).isRequired,
  managedApplicationTemplate: PropTypes.objectOf(Object).isRequired,
  applicationId: PropTypes.string.isRequired,
  setApplicationData: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  previousInitialFormData: PropTypes.objectOf(Object).isRequired,
  withChanges: PropTypes.bool.isRequired,
  backdrop: PropTypes.func.isRequired,
};
