import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../../Context';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { DICTIONARIES } from '../../../../_constants';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../../_helpers';

/**
 * Promotional events in which the applicant will participate component
 *
 * @returns {InternationalizationModuleEssencePromotion}
 */
export default function InternationalizationModuleEssencePromotion() {
  const {
    control, watch,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { get } = useDictionaryLoader(DICTIONARIES.countries);

  const {
    fields, append, remove, setValue,
  } = useFieldArray({
    control,
    name: 'internationalization_module_essence_promotion_events',
  });

  const internationalizationModuleEssencePromotionEventsConfig = fieldsConfig
    .internationalization_module_essence_promotion_events;
  const subfields = internationalizationModuleEssencePromotionEventsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = internationalizationModuleEssencePromotionEventsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const productInternationalizationValue = watch('internationalization_module_essence_product_internationalization')
    || [];

  return (
    <>
      {/* Tabela "Wydarzenia promujące, w których uczestniczył będzie wnioskodawca" */}
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'internationalization_module_essence_promotion_events',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed
        >
          {/* Rodzaj wydarzenia */}
          <FormField name={`internationalization_module_essence_promotion_events.${index}.event_type`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceEventTypes.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Nazwa wydarzenia */}
          <FormField name={`internationalization_module_essence_promotion_events.${index}.event_name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Kraj wydarzenia */}
          <FormField name={`internationalization_module_essence_promotion_events.${index}.event_country`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.countries.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Termin */}
          <FormField name={`internationalization_module_essence_promotion_events.${index}.event_date`}>
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                minDate="01-01-2023"
                maxDate="01-01-2030"
                views={['year']}
                format="yyyy"
                placeholder="rrrr"
                mask="____"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Nazwa promowanego produktu/ grupy produktów */}
          <FormField name={`internationalization_module_essence_promotion_events.${index}.product_name`}>
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                multiple
                renderSelectAllButton={false}
                id={name}
                initialValue={value}
                onChange={onChange}
                optionsMapKeys={['set_uuid', 'product_name']}
                options={productInternationalizationValue.filter((item) => item.product_name.length > 0)}
                onBlur={onBlur}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="0CQ6xgmdb0C4Fx8"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
