import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * ApplicantProductBrand Pdf element.
 *
 * @returns {ApplicantProductBrandPdf}
 */
function ApplicantProductBrandPdf() {
  const {
    getDictionary,
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Nazwa marki produktowej, której dotyczy projekt */}
        <RowField name="applicant_product_brand_name" isSingleRow />
        {/* Od kiedy marka produktowa znajduje się w ofercie wnioskodawcy */}
        <RowField name="applicant_product_brand_since_when_is_in_portfolio" isSingleRow />
        {/* Informacje potwierdzające prawo wnioskodawcy do dysponowania marką produktową, której dotyczy projekt  */}
        <RowField name="applicant_product_brand_information_confirming_applicant_right" isSingleRow />
        {/* Tabela: Produkty będące przedmiotem promocji */}
        <CollectionWrapper name="applicant_product_brand_products_subject_promotion">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.x}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    {/* Nazwa produktu będącego przedmiotem promocji */}
                    <RowField
                      name={`${collectionName}.${index}.product_name`}
                      value={item.product_name}
                      oldValue={previousRowData?.product_name}
                      isSingleRow
                    />
                    {/* Numer kodu PKD działalności, której dotyczy produkt będący przedmiotem promocji */}
                    <RowField
                      name={`${collectionName}.${index}.pkd`}
                      value={item.pkd}
                      oldValue={previousRowData?.pkd}
                      externalDictionary={getDictionary(DICTIONARIES.pkdCodes.name).map((pkdCode) => ({
                        '@id': pkdCode['@id'],
                        name: `${pkdCode.code} ${pkdCode.name}`,
                      }))}
                    />
                    {/* Opis produktu będącego przedmiotem promocji */}
                    <RowField
                      name={`${collectionName}.${index}.product_description`}
                      value={item.product_description}
                      oldValue={previousRowData?.product_description}
                      isSingleRow
                    />
                    {/* Opis założeń w zakresie wprowadzenia produktu pod wskazaną marką produktową na wybranych rynkach
             docelowych */}
                    <RowField
                      name={`${collectionName}.${index}.assumptions_description`}
                      value={item.assumptions_description}
                      oldValue={previousRowData?.assumptions_description}
                      isSingleRow
                    />
                    {/* Potencjał eksportowy wnioskodawcy - potencjalne zainteresowanie produktem na rynkach
                    zagranicznych, w tym rynkach docelowy */}
                    <RowField
                      name={`${collectionName}.${index}.applicant_export_potential`}
                      value={item.applicant_export_potential}
                      oldValue={previousRowData?.applicant_export_potential}
                      isSingleRow
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default ApplicantProductBrandPdf;
