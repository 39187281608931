import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';

/**
 * InnovationSocialSignificance element renderer.
 *
 * @returns {InnovationSocialSignificance}
 */
export default function InnovationSocialSignificance() {
  const { isReadonly } = useElementContext();
  const {
    watch, reset,
  } = useFormContext();
  const { application } = useApplicationContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <FieldSorter>
      {/* Czy główne przeznaczenie innowacji ma pozytywne znaczenie społeczne */}
      <FormField name="innovation_social_significance_is_positive">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  innovation_social_significance_module_where_innovation_occurs: FIELD_TYPES_MAP.limited_choice,
                  innovation_social_significance_description: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {watch('innovation_social_significance_is_positive') === BOOLEAN_VALUES.TRUE && (
        <>
          {/* Moduł, w którym występuje innowacja */}
          <FormField name="innovation_social_significance_module_where_innovation_occurs">
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                multiple
                options={filterOptions(modules)}
                disabled={isReadonly}
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
          {/* Uzasadnienie */}
          <FormField name="innovation_social_significance_description">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
    </FieldSorter>
  );
}
