import { TextField } from '@mui/material';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';
import { useElementContext } from '../../../Context';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * R&D Infrastructure Module Essence element.
 *
 * @returns {RdInfrastructureModuleEssence}
 */
export function RdInfrastructureModuleEssence() {
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.investmentTypes,
  );
  const {
    isReadonly,
  } = useElementContext();

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <FormField name="rd_infrastructure_module_essence_module_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="rd_infrastructure_module_essence_investment_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.investmentTypes.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      <FormField name="rd_infrastructure_module_essence_investment_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

    </FieldSorter>
  );
}
