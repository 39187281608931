/**
 * Transitions between statuses.
 *
 * @type {object}
 * @property {string} reject - change status from 'review' to 'rejected'
 * @property {string} accept - change status from 'review' to 'accepted'
 * @property {string} cancel - change status from 'accepted' to 'cancelled'
 */
export const APPLICATION_WITHDRAWAL_STATUS_TRANSITIONS = {
  reject: 'reject',
  accept: 'accept',
  cancel: 'cancel',
};
