import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * IntensityOfPublicAidForRdPdf element.
 *
 * @returns {IntensityOfPublicAidForRdPdf}
 */
function IntensityOfPublicAidForRdPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="intensity_of_public_aid_for_rd_reason_mein_label" isSingleRow />
        <RowField name="intensity_of_public_aid_for_rd_reason" />
        <RowField name="intensity_of_public_aid_for_rd_result_mein_label" isSingleRow />
        <RowField name="intensity_of_public_aid_for_rd_result_mein_a" />
        <RowField name="intensity_of_public_aid_for_rd_result_mein_b" />
        <RowField name="intensity_of_public_aid_for_rd_result_mein_c" />
        <RowField name="intensity_of_public_aid_for_rd_result_mein_d" />
        <RowField name="intensity_of_public_aid_for_rd_public_aid_label" isSingleRow />
        <RowField name="intensity_of_public_aid_for_rd_public_aid" />
        <RowField name="intensity_of_public_aid_for_rd_result_mnisw_label" isSingleRow />
        <RowField name="intensity_of_public_aid_for_rd_result_mnisw_a" />
        <RowField name="intensity_of_public_aid_for_rd_result_mnisw_b" />
        <RowField name="intensity_of_public_aid_for_rd_result_mnisw_c" />
        <RowField name="intensity_of_public_aid_for_rd_result_mnisw_d" />
        <RowField name="intensity_of_public_aid_for_rd_5_percent_location_bonus" />
        <RowField name="intensity_of_public_aid_for_rd_15_percent_location_bonus" />
      </FieldSorter>
    </View>
  );
}

export default IntensityOfPublicAidForRdPdf;
