import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';

/**
 * Risks Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {RisksPdf}
 */
function RisksPdf({ elementData }) {
  const { id: elementId } = elementData;

  return (
    <CollectionWrapper name={`risks-${elementId}`}>
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.risk_name`}
                value={item.risk_name}
                oldValue={previousRowData?.risk_name}
              />
              <RowField
                name={`${collectionName}.${index}.risk_type`}
                value={item.risk_type}
                oldValue={previousRowData?.risk_type}
                dictionaryName={DICTIONARIES.riskTypes.name}
              />
              <RowField
                name={`${collectionName}.${index}.risk_description`}
                value={item.risk_description}
                oldValue={previousRowData?.risk_description}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.risk_prevention`}
                value={item.risk_prevention}
                oldValue={previousRowData?.risk_prevention}
                isSingleRow
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default RisksPdf;

RisksPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
