import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * RdSubimplementers element renderer.
 *
 * @returns {RdSubimplementers}
 */
export function RdSubimplementers() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    control, watch, setValue, resetField,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'subimplementers',
  });

  const hasSubimplementersValue = watch('has_subimplementers');

  const subimplementersConfig = fieldsConfig.subimplementers;
  const subfields = subimplementersConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber, minRowNumber,
  } = subimplementersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, hasSubimplementersValue]);

  return (
    <FieldSorter>
      {/* Wnioskodawca planuje zaangażowanie podwykonawcy/ podwykonawców badań przemysłowych/ prac rozwojowych */}
      <FormField name="has_subimplementers">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField('subimplementers', { defaultValue: FIELD_TYPES_MAP.collection });
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Tabela "Informacje o podwykonawcach" */}
      <LabeledFieldRow
        hide={hasSubimplementersValue !== BOOLEAN_VALUES.TRUE}
        fieldConfig={subimplementersConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            key={item.id}
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'subimplementers',
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            <FormField name={`subimplementers.${index}.has_picked_subimplementer`}>
              {({
                name, onChange, value, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {watch(`subimplementers.${index}.has_picked_subimplementer`) === BOOLEAN_VALUES.FALSE && (
              <FormField name={`subimplementers.${index}.subcontractor_obtaining_possibility`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            )}
            <FormField name={`subimplementers.${index}.is_subimplementer_critical`}>
              {({
                name, onChange, value, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.has_agreement`}>
              {({
                name, onChange, value, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.subimplementer_name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.subimplementer_nip`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.scope_of_work`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.subcontractor_work_necessary_for_project_objective`}>
              {({
                name, onChange, value, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <Grid
              name="subimplementer_resources"
              item
              xs={12}
            >
              <Typography>
                {subfields.subimplementer_resources?.label}
              </Typography>
            </Grid>
            <FormField name={`subimplementers.${index}.resource_hr`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_purpose_hr`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_equipment`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_purpose_equipment`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_infrastracture`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_purpose_infrastracture`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_assets`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.resource_purpose_assets`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`subimplementers.${index}.subcontractor_name_organizational_unit`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="nI0nF5UD0rAhlRw"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
