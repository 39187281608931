import {
  Font,
  StyleSheet,
} from '@react-pdf/renderer';
import montserratLight from '../../fonts/Montserrat-Light.ttf';
import montserratRegular from '../../fonts/Montserrat-Regular.ttf';
import { theme } from '../../styles/theme';

// Register Font
Font.register({
  family: 'Montserrat-Light',
  src: montserratLight,
});

Font.register({
  family: 'Montserrat-Regular',
  src: montserratRegular,
});
export const htmlTagsStyle = StyleSheet.create({
  h1: {
    fontSize: 18,
    textAlign: 'left',
    marginBottom: 10,
    fontFamily: 'Montserrat-Regular',
  },
  h2: {
    fontSize: 16,
    textAlign: 'left',
    marginBottom: 10,
    fontFamily: 'Montserrat-Regular',
  },
  h3: {
    fontSize: 14,
    textAlign: 'left',
    marginBottom: 10,
    fontFamily: 'Montserrat-Regular',
  },
  p: {
    fontSize: 12,
    color: theme.palette.brandGray10.contrastText,
    lineHeight: 1.3,
    fontFamily: 'Montserrat-Light',
    marginBottom: 20,
    display: 'table',
    width: 'auto',
  },
  strong: {
    fontSize: 12,
    color: theme.palette.brandGray10.contrastText,
    lineHeight: 1.3,
    fontFamily: 'Montserrat-Regular',
  },
  blockquote: {
    fontSize: 12,
    color: theme.palette.brandGray10.contrastText,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    paddingLeft: 20,
    paddingVertical: 10,
    backgroundColor: theme.palette.brandGray20.main,
    marginBottom: 20,
  },
  list: {
    marginBottom: 20,
  },
  liStyles: {
    marginBottom: 10,
    width: '100%',
    boxSizing: 'border-box',
  },
  regularFont: {
    fontFamily: 'Montserrat-Regular',
  },
  lightFont: {
    fontFamily: 'Montserrat-Light',
  },
});
