import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import { DATE } from '../../../../_constants';
import { formatDateString } from '../../../../_helpers';

/**
 * KpiOtherThanProductOrResultIndicatorsPdf element.
 *
 * @returns {KpiOtherThanProductOrResultIndicatorsPdf}
 */
function KpiOtherThanProductOrResultIndicatorsPdf() {
  return (
    <CollectionWrapper name="kpi_other_than_product_or_result_indicators">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.kpi_name`}
                value={item.kpi_name}
                oldValue={previousRowData?.kpi_name}
              />
              <RowField
                name={`${collectionName}.${index}.kpi_description`}
                value={item.kpi_description}
                oldValue={previousRowData?.kpi_description}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.year_of_achievement_of_the_target_value`}
                value={formatDateString(item?.year_of_achievement_of_the_target_value, DATE.onlyYear)}
                oldValue={formatDateString(
                  previousRowData?.year_of_achievement_of_the_target_value,
                  DATE.onlyYear
                )}
              />
              <RowField
                name={`${collectionName}.${index}.description_of_how_to_verify_the_kpi_achievement`}
                value={item.description_of_how_to_verify_the_kpi_achievement}
                oldValue={previousRowData?.description_of_how_to_verify_the_kpi_achievement}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default KpiOtherThanProductOrResultIndicatorsPdf;
