import PropTypes from 'prop-types';
import { theme } from '../styles/theme';

/**
 * AsteriskedText component.
 *
 * @param {object} props root props
 * @param {string} props.textContent text content
 * @param {string} props.asteriskColor default asterisk color
 * @param {boolean} props.printSymbol print asterisk before text
 * @returns {AsteriskedText} text with asterisk at beginning
 */
export function AsteriskedText({
  textContent, asteriskColor, printSymbol,
}) {
  const asteriskStyle = {
    color: asteriskColor,
  };

  return (
    <span>
      {printSymbol && (
        <span style={asteriskStyle}>
          *
        </span>
      )}
      {textContent}
    </span>
  );
}

AsteriskedText.propTypes = {
  textContent: PropTypes.string.isRequired,
  asteriskColor: PropTypes.string,
  printSymbol: PropTypes.bool,
};
AsteriskedText.defaultProps = {
  asteriskColor: theme.palette.primary.dark,
  printSymbol: true,
};
