import {
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
  applicationReducer,
  rootReducer,
} from '../_reducers';
import { THUNK_FUNC } from '../_helpers';

export const initialState = {
  root: {
    isAvailable: false,
    isRequestSend: false,
    expired: false,
    isDarkMode: false,
    fontSize: 14,
    globalNotification: {},
    forceReload: 0,
    isLock: false,
    isShowNewMessageDialog: false,
  },
  applicationData: {
    dictionaries: {},
    dictionariesLoadQueue: 0,
    loadingState: {},
    openedMenuTab: {
      menuId: undefined,
      title: undefined,
      element: '',
    },
    actions: {
      save: 0,
      isOpenVerifyCorrectness: false,
      print: 0,
      submit: 0,
    },
    isCorrection: false,
    previousPath: null,
  },
};

/**
 * Global context wrapper.
 * Contains global data API.
 *
 * @type {React.Context}
 */
const GlobalContext = createContext(initialState);

export const useGlobalContext = () => useContext(GlobalContext);

const combineReducers = (slices) => (state, action) => Object.keys(slices).reduce((acc, prop) => ({
  ...acc,
  [prop.replace('Reducer', '')]: slices[prop](acc[prop], action),
}), state);

/**
 * Assessment sheet provider.
 *
 * @param {object} props - root props
 * @param {Node|Node[]} props.children - children elements
 * @param {object} props.defaultState -default state
 * @returns {object} - AssessmentSheetProvider object
 */
export function GlobalProvider({
  children,
  defaultState,
}) {
  const [state, dispatch] = useReducer(
    combineReducers({
      root: rootReducer,
      applicationData: applicationReducer,
    }),
    defaultState,
    undefined
  );

  const store = useMemo(() => state, [state]);

  /**
   * Handle global notification
   *
   * @param {string} message - notification message
   * @param {string} severity - notification type
   * @param {boolean} modal - display in modal
   * @returns {object} - global context message
   */
  const notify = (message, severity, modal = false) => dispatch({
    ...THUNK_FUNC.notification(message, severity),
    modal,
  });

  return (
    <GlobalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...store,
        dispatch,
        notify,
        handleAction: dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.instanceOf(Object),
};

GlobalProvider.defaultProps = {
  defaultState: initialState,
};
