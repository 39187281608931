import {
  useState,
  useEffect,
} from 'react';
import {
  Grid,
  Link,
  Divider,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import uniqid from 'uniqid';
import { API_ROUTE } from '../_constants';
import { useAuth } from '../_security';
import { request } from '../_services';
import { sanitizeAndRenderHtml } from '../_helpers';
import { ContainerLoader } from './Application/Application/ContainerLoader';
import { theme } from '../styles/theme';

const classes = {
  overHeading: {
    borderColor: theme.palette.alert.info.main,
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.125),
    marginBottom: theme.spacing(0.5),
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    fontSize: '1rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
};

/**
 * User guide items.
 *
 * @returns {UserGuideItems}
 */
export function UserGuideItems() {
  const { profile } = useAuth();
  const [cards, setCards] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    loadCards();
  }, []);

  const loadCards = async () => {
    const {
      payload, statusSuccess, statusCode,
    } = profile === null
      ? await request.get(`${API_ROUTE.publicHelpCenter}?order[ordinalNumber]=asc`)
      : await request.get(`${API_ROUTE.privateHelpCenter}?visible_no[]=${profile}&order[ordinalNumber]=asc`);

    setLoaded(true);
    if (!statusSuccess) {
      if (statusCode === StatusCodes.FORBIDDEN) {
        setErrorMessage('Brak dostępu do zasobu');
      }

      return;
    }
    setCards(payload);
  };

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!loaded) {
    return <ContainerLoader />;
  }

  return errorMessage || (
    <Grid container justifyContent="center">
      <Grid item xs={12} xl={8}>
        <Divider sx={classes.overHeading} />
        <Typography variant="pageHeading" py={2} backgroundColor="#f7f7f7">Centrum pomocy</Typography>

        {cards.map((card) => {
          const description = card?.justification?.length > 0 && sanitizeAndRenderHtml(card.justification);

          return (
            <Accordion expanded={expanded === card.id} onChange={handleChange(card.id)}>
              <AccordionSummary
                expandIcon={card?.justification && <ExpandMoreIcon sx={{ color: theme.palette.info.main }} />}
                aria-controls={card.id}
                id={uniqid()}
              >
                {card?.justification ? (
                  <Typography my={2}>{card.title}</Typography>
                ) : (
                  <Link
                    href={card.articleUrl}
                    target="_blank"
                    sx={classes.link}
                    rel="noreferrer"
                  >
                    {card.title}
                  </Link>
                )}
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#0c759214' }}>
                <Typography textAlign="start">{description}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </Grid>
  );
}
