/**
 * Evaluation types.
 *
 * @type {object}
 */
export const EVALUATION_TYPES = {
  evaluation_type_experts_panel: 'evaluation_type_experts_panel',
  evaluation_type_merit: 'evaluation_type_merit',
};

/**
 * Evaluation types translations.
 *
 * @type {object}
 */
export const EVALUATION_TYPES_TRANSLATIONS = {
  [EVALUATION_TYPES.evaluation_type_experts_panel]: 'Panel ekspertów',
  [EVALUATION_TYPES.evaluation_type_merit]: 'Ocena merytoryczna',
};
