import {
  Link as MUILink,
  Stack,
  Divider,
} from '@mui/material';
import { theme } from '../../styles/theme';

const classes = {
  footerLinks: {
    justifyContent: 'flex-end',
    fontSize: '0.925rem',
    lineHeight: '1rem',
    textAlign: 'center',
    '& .MuiLink-root': {
      textDecoration: 'none',
    },
    '&>:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(0),
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
};

/**
 * Fragment with of LSI Footer.
 *
 * @returns {Links}
 */
export function Links() {
  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      spacing={2}
      sx={classes.footerLinks}
      divider={<Divider orientation="vertical" flexItem sx={{ borderColor: theme.palette.brandGray.main }} />}
    >
      <MUILink
        href="https://www.parp.gov.pl/component/site/site/regulamin-ochrony-danych-osobowych"
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        Ochrona danych osobowych
      </MUILink>
      <MUILink
        href="https://www.parp.gov.pl/component/site/site/polityka-prywatnosci"
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        Polityka prywatności
      </MUILink>
      <MUILink
        href="https://www.parp.gov.pl/component/site/site/deklaracja-dostepnosci-LSI-nowe"
        target="_blank"
        color="inherit"
        rel="noopener"
      >
        Deklaracja&nbsp;dostępności
      </MUILink>
    </Stack>
  );
}
