import {
  Tab,
  Tabs,
} from '@mui/material';
import PropTypes from 'prop-types';
import { theme } from '../styles/theme';

const classes = {
  menuItem: {
    textTransform: 'none',
    textAlign: 'left',
    '& > span': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  tabsContainer: {
    border: `1px solid ${theme.palette.brandGray60.main}`,
    textAlign: 'left',
    '& > div > div > .Mui-selected': {
      backgroundColor: theme.palette.brandGray20.main,
    },
    '& > div > div > button': {
      maxWidth: '100%',
    },
    padding: '5px 0px 5px',
  },
};

/**
 * Vertically oriented tab menu.
 *
 * @param {object} props root props
 * @param {Function} props.selectionHandler selected tab index will be passed to this function
 * @param {Array} props.menuItems menu tabs items (see shape in prop types)
 * @param {string} props.ariaLabel tab container aria label
 * @param {string} props.ariaPrefix tab item aria-controls prefix
 * @param {string} props.initialSelectedIndex index which be initially selected
 * @param {boolean} props.disableMenu menu options will be disabled
 * @returns {VerticalTabs}
 */
export function VerticalTabs({
  menuItems,
  selectionHandler,
  ariaLabel,
  ariaPrefix,
  initialSelectedIndex,
  disableMenu,
}) {
  return (
    <Tabs
      sx={classes.tabsContainer}
      orientation="vertical"
      variant="standard"
      textColor="inherit"
      indicatorColor="secondary"
      value={initialSelectedIndex}
      onChange={(_, newValue) => {
        selectionHandler(newValue);
      }}
      aria-label={ariaLabel}
      TabScrollButtonProps={{
        sx: classes.menuItem,
      }}
    >
      {menuItems.map(({
        title, id,
      }) => (
        <Tab
          disabled={disableMenu}
          id={id}
          key={`tab-${id}`}
          label={title}
          aria-controls={`${ariaPrefix}-${id}`}
          sx={{
            ...classes.menuItem,
            alignItems: 'start',
          }}
        />
      ))}
    </Tabs>
  );
}

VerticalTabs.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
  })),
  selectionHandler: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  ariaPrefix: PropTypes.string,
  initialSelectedIndex: PropTypes.number,
  disableMenu: PropTypes.bool,
};

VerticalTabs.defaultProps = {
  menuItems: [],
  ariaLabel: 'Tabs box',
  ariaPrefix: 'tab',
  initialSelectedIndex: 0,
  disableMenu: false,
};
