import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import {
  useState,
  useMemo,
} from 'react';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../../../Context';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  BOOLEAN_VALUES,
  EXPENSES_TYPE,
} from '../../../../../_constants';
import { useCustomSnackbar } from '../../../../../_hooks';
import { ConfirmDialog } from '../../../../../Dialogs';
import ExpensesDialog from './Expenses.dialog';
import { useStylesDataGridTable } from '../../../../../styles/dataGridTable';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';
import { getImplementersAndApplicantName } from '../../../../../_helpers';

/**
 * Actual expenses table component
 *
 * @param {object} props - root props
 * @param {string} props.taskId - task id
 * @param {string} props.taskName - task name
 * @param {Array} props.expensesData - expenses data
 * @param {Function} props.reloadExpensesData - reload expenses data handler
 * @param {string} props.taskType - task type id
 * @param {boolean} props.indirectCosts - is indirect costs
 * @param {Array} props.costCategoryForElement - costCategoryForElement data from api
 * @returns {ActualExpensesTable}
 */
function ActualExpensesTable({
  taskId, reloadExpensesData, taskName, taskType, indirectCosts, expensesData, costCategoryForElement,
}) {
  const [removeDialog, setRemoveDialog] = useState(null);
  const [editDialog, setEditDialog] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const { id: applicationId } = useParams();
  const {
    control, getValues,
  } = useFormContext();

  const {
    id: elementId, isReadonly,
  } = useElementContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const {
    remove,
  } = useFieldArray({
    control,
    name: `actual_expenses-${elementId}`,
  });

  const removeExpense = async (expenseId) => {
    setSubmitting(true);

    const expenseIndex = getValues(`actual_expenses-${elementId}`)
      ?.findIndex(({ actual_expense_id: actualExpenseId }) => actualExpenseId === expenseId);
    const { statusSuccess } = await request.delete(`${API_ROUTE.applicationExpenses
      .replace(':applicationId', applicationId)
      .replace(':elementId', elementId)}/actual-expenses/${expenseId}`);

    setRemoveDialog(null);

    statusSuccess
      ? successNotification('Wydatek został usunięty.')
      : errorNotification('Nie udało się usunąć wydatku. Spróbuj ponownie.');
    if (!statusSuccess) {
      return;
    }

    remove(expenseIndex);
    reloadExpensesData(elementId, EXPENSES_TYPE.actualExpenses);
    setSubmitting(false);
  };

  const getLabelForCostCategory = (value) => costCategoryForElement
    .find((category) => category['@id'] === value)?.name || '';

  const ROW_TYPE_DESCRIPTION = 'ROW_TYPE_DESCRIPTION';

  const getTransformedData = () => {
    const transformedExpenses = [];

    const expensesDataByTask = expensesData.filter((field) => field.task_id === taskId);
    expensesDataByTask.forEach((expense) => {
      transformedExpenses.push(expense);

      if (expense?.description_and_justification) {
        transformedExpenses.push({
          id: uniqid(),
          type: ROW_TYPE_DESCRIPTION,
          label: 'Opis wydatku',
          desc: expense?.description_and_justification,
        });
      }
    });

    return transformedExpenses;
  };

  /**
   * Returns row actions
   *
   * @param {object} props - root props
   * @param {object} props.row - element data
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => [
    <Button
      id={uniqid()}
      type="button"
      variant="contained"
      color="secondary"
      onClick={() => setEditDialog(
        <ExpensesDialog
          handleClose={() => setEditDialog(null)}
          isOpen
          taskName={taskName}
          taskType={taskType}
          indirectCosts={indirectCosts}
          itemIndex={expensesData.findIndex((expense) => expense.actual_expense_id === row.actual_expense_id)
            .toString()}
          reloadExpensesData={reloadExpensesData}
          expenseType={EXPENSES_TYPE.actualExpenses}
          taskId={row.task_id}
        />
      )}
    >
      {isReadonly ? 'Podgląd' : 'Edytuj'}
    </Button>,
    <>
      {!isReadonly && (
        <Button
          id={uniqid()}
          type="button"
          variant="outlined"
          onClick={() => {
            setRemoveDialog(
              <ConfirmDialog
                closeHandler={() => setRemoveDialog(null)}
                open
                confirmButtonHandler={() => removeExpense(row.actual_expense_id)}
                cancelButtonColor="primary"
                dialogTitle="Usuń wydatek"
                confirmButtonLabel="TAK"
                cancelButtonLabel="NIE"
              >
                {`Czy na pewno chcesz usunąć wydatek "${row.name}"?`}
              </ConfirmDialog>
            );
          }}
        >
          Usuń
        </Button>
      )}
    </>,
  ];

  const columns = [
    {
      field: 'name',
      headerName: 'Nazwa kosztu',
      flex: 1.5,
      colSpan: ({ row }) => {
        if (row.type === ROW_TYPE_DESCRIPTION) {
          return 8;
        }

        return undefined;
      },
      renderCell: ({
        value, row,
      }) => {
        if (row.type === ROW_TYPE_DESCRIPTION) {
          return (
            <Box py={2}>
              <Typography fontWeight="bold">
                {row.label}
              </Typography>
              <Typography component="p">
                {row.desc}
              </Typography>
            </Box>
          );
        }

        return value;
      },
    },
    {
      field: 'cost_category',
      headerName: 'Kategoria kosztu',
      valueFormatter: ({ value }) => getLabelForCostCategory(value),
      flex: 0.8,
    },
    {
      field: 'total_expenses',
      headerName: 'Koszty ogółem',
      flex: 0.6,
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),
    },
    {
      field: 'eligible_expenses',
      headerName: 'Koszty kwalifikowane',
      flex: 0.6,
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),
    },
    {
      field: 'co_financing',
      headerName: 'Dofinansowanie',
      flex: 0.6,
      renderCell: ({
        value,
      }) => (
        <NumericFormat
          thousandSeparator=" "
          isNumericString
          value={value}
          disabled
          style={useStylesDataGridTable.numberField}
        />
      ),
    },
    {
      field: 'co_financing_percent',
      headerName: '% Dofinansowania',
      flex: 0.8,
    },
    {
      field: 'implementer_id',
      headerName: 'Realizator',
      renderCell: ({ row }) => getImplementersAndApplicantName(getValues, applicationId)
        .find((item) => item['@id'] === row?.implementer_id)?.name || '',
      flex: 0.8,
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      headerName: 'Akcje',
      width: 200,
      getActions: getRowActions,
    },
  ];

  const hideImplementersColumn = getValues('has_implementers') !== BOOLEAN_VALUES.TRUE;
  const filteredColumns = columns.filter(({ field }) => !(hideImplementersColumn && field === 'implementer_id'));

  const memoizedDataGrid = useMemo(() => (
    <DataGrid
      columns={filteredColumns}
      rows={getTransformedData()}
      rowCount={getTransformedData().length || 0}
      autoHeight
      hideFooterPagination
      hideFooter
      getRowId={(row) => row?.actual_expense_id || row?.id}
      sx={useStylesDataGridTable.table}
    />
  ), [expensesData, costCategoryForElement]);

  if (getTransformedData().length > 0) {
    return (
      <>
        <Typography variant="pageHeading" textAlign="left">
          Wydatki rzeczywiste
        </Typography>
        {memoizedDataGrid}
        {isSubmitting ? <ContainerLoader /> : removeDialog}
        {editDialog}
      </>
    );
  }

  return '';
}

export default ActualExpensesTable;

ActualExpensesTable.propTypes = {
  taskId: PropTypes.string.isRequired,
  reloadExpensesData: PropTypes.func.isRequired,
  taskName: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired,
  indirectCosts: PropTypes.bool.isRequired,
  expensesData: PropTypes.arrayOf(Object).isRequired,
  costCategoryForElement: PropTypes.arrayOf(Object).isRequired,
};
