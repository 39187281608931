import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDictionaryContext } from './DictionaryProvider';

/**
 * @typedef {object} useDictionaryLoader
 * @property {Function} get - gets dictionary data by name
 * @property {object} dictionaries - dictionaries map
 * @property {boolean} isLoaded - is queued dictionaries loading completed
 */

/**
 * @typedef {object} DictionaryLoadModel
 * @property {string} name - dictionary name
 * @property {string} path - dictionary API endpoint (probably from API_ROUTE)
 */

/**
 * Hook for dictionaries.
 * It is recommended to retrieve dictionaries using the `get` function,
 * because it always will return array even on fetch failure.
 *
 * @param {DictionaryLoadModel[]} toLoad - dictionaries to load
 * @throws {Error} if component that using hook is not wrapped in DictionaryProvider
 * @returns {useDictionaryLoader}
 */
export function useDictionaryLoader(...toLoad) {
  const {
    dictionaries, load, get, isLoaded,
  } = useDictionaryContext();

  if (typeof load !== 'function') {
    throw new Error('This hook can be used only inside DictionaryProvider');
  }

  /**
   * Trigger loader on hook initialization.
   *
   * @throws {Error} if object schema does not match
   */
  useEffect(() => {
    toLoad.forEach(({
      name, path,
    }) => {
      if (!name || !path) {
        throw new Error('You need to provide `name` and `path` to load dictionary');
      }
    });

    load(toLoad);
  }, []);

  return {
    isLoaded,
    dictionaries,
    get,
  };
}

useDictionaryLoader.propTypes = {
  toLoad: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })),
};

useDictionaryLoader.defaultProps = {
  toLoad: [],
};
