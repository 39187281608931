import * as Elements from './Elements';

/**
 * Contains all available elements mapped.
 * Remember that when you add a new element, it must be registered in this constant.
 * This map tells the application what item to render based on data from the API.
 */
const MAPPING = {
  general_program_information: Elements.GeneralProgramInformationPdf,
  project_general_information: Elements.ProjectGeneralInformationPdf,
  applicant_other_applications: Elements.ApplicantOtherApplicationsPdf,
  regional_investment_aid_digitization: Elements.RegionalInvestmentAidDigitizationPdf,
  regional_investment_aid_rd_infrastructure: Elements.RegionalInvestmentAidInfrastructurePdf,
  contact_persons: Elements.ContactPersonsPdf,
  project_classification: Elements.ProjectClassificationPdf,
  applicant_general_information: Elements.ApplicantGeneralInformationPdf,
  applicant_potential_competition: Elements.ApplicantPotentialCompetitionPdf,
  br_implementation_potential: Elements.BrImplementationPotentialPdf,
  competences_module_essence: Elements.CompetencesModuleEssencePdf,
  digital_innovation: Elements.DigitalInnovationPdf,
  digitization_module_essence: Elements.DigitizationModuleEssencePdf,
  ecoinnovations: Elements.EcoInnovationsPdf,
  edih_organization: Elements.EdihOrganizationPdf,
  greening_module_essence: Elements.GreeningModuleEssencePdf,
  rd_module_essence: Elements.RdModuleEssencePdf,
  greening_environmental_protection_aid: Elements.GreeningEnvironmentalProtectionAidPdf,
  human_resources_digitization: Elements.HumanResourcesDigitizationPdf,
  human_resources_greening: Elements.HumanResourcesGreeningPdf,
  human_resources_innovation_implementation: Elements.HumanResourcesInnovationImplementationPdf,
  human_resources_internationalization: Elements.HumanResourcesInternationalizationPdf,
  human_resources_potential_rd_infrastructure: Elements.HumanResourcesPotentialRdInfrastructurePdf,
  implementers: Elements.ImplementersPdf,
  intensity_of_public_aid_for_rd: Elements.IntensityOfPublicAidForRdPdf,
  innovation_social_significance: Elements.InnovationSocialSignificancePdf,
  kpi_other_than_product_or_result_indicators: Elements.KpiOtherThanProductOrResultIndicatorsPdf,
  innovation_implementation_module_essence: Elements.InnovationImplementationModuleEssencePdf,
  internationalization_module_essence: Elements.InternationalizationModuleEssencePdf,
  mailing_address: Elements.MailingAddressPdf,
  market_demand_and_rd_profitability: Elements.MarketDemandAndRdProfitabilityPdf,
  market_transformation: Elements.MarketTransformationPdf,
  rd_module_classification: Elements.ModuleClassificationPdf,
  rd_infrastructure_module_classification: Elements.ModuleClassificationPdf,
  innovation_implementation_module: Elements.ModuleClassificationPdf,
  internationalization_module_classification: Elements.ModuleClassificationPdf,
  competences_module_classification: Elements.ModuleClassificationPdf,
  digitization_module_classification: Elements.ModuleClassificationPdf,
  greening_module_classification: Elements.ModuleClassificationPdf,
  module_implementation_potential: Elements.ModuleImplementationPotentialPdf,
  launch_pad_incubation_program_concept_description: Elements.LaunchPadIncubationProgramConceptDescriptionPdf,
  incubation_taking_into_account_industry_specialization: Elements.IncubationTakingIntoAccountIndustrySpecializationPdf,
  services_implementation: Elements.ServicesImplementationPdf,
  national_key_cluster_participation: Elements.NationalKeyClusterParticipationPdf,
  project_collaboration: Elements.ProjectCollaborationPdf,
  project_implementation_area: Elements.ProjectImplementationAreaPdf,
  rd_infrastructure_module_essence: Elements.RdInfrastructureModuleEssencePdf,
  rd_project_team: Elements.RdProjectTeamPdf,
  edih_services: Elements.EdihServicesPdf,
  applicant_experience_in_implementation_of_grant_projects: Elements.ApplicantExperienceInImplementationOfGrantProjects,
  statements: Elements.StatementsPdf,
  risks: Elements.RisksPdf,
  technical_resources_and_intangible_assets: Elements.TechnicalResourcesAndIntangibleAssetsPdf,
  br_subcontractors: Elements.RdSubimplementersPdf,
  regional_investment_aid_innovation_implementation: Elements.RegionalInvestmentAidInnovationImplementationPdf,
  regional_investment_aid_greening: Elements.RegionalInvestmentAidGreeningPdf,
  link_between_modules: Elements.LinkBetweenModulesPdf,
  research_agenda_rd_infrastructure: Elements.ResearchAgendaRdInfrastructurePdf,
  ue_horizontal_policies_project_compliance: Elements.UeHorizontalPoliciesProjectCompliancePdf,
  attachments: Elements.AttachmentsPdf,
  hrf: Elements.MaterialAndFinancialSchedulePdf,
  indicators: Elements.IndicatorsPdf,
  project_budget: Elements.ProjectBudgetPdf,
  funding_sources: Elements.FundingSourcesPdf,
  applicant_and_project_partners_resources: Elements.ApplicantAndProjectPartnersResourcesPdf,
  applicant_branch_adress_in_eastern_poland: Elements.ApplicantBranchAdressInEasternPolandPdf,
  applicant_potential: Elements.ApplicantPotentialPdf,
  project_implementation_justification: Elements.ProjectImplementationJustificationPdf,
  ecosystem_partners: Elements.EcosystemPartnersPdf,
  applicant_income: Elements.ApplicantIncomePdf,
  pkw_eligibility: Elements.PkwEligibilityPdf,
  cluster_data: Elements.ClusterDataPdf,
  cluster_coordinator_service_offer: Elements.ClusterCoordinatorServiceOffer,
  cluster_coordinator_potential: Elements.ClusterCoordinatorPotentialPdf,
  applicant_experience_in_implementation_acceleration_programs:
  Elements.ApplicantExperienceInImplementationAccelerationProgramsPdf,
  cluster_internationalization: Elements.ClusterInternationalizationPdf,
  project_detailed_information: Elements.ProjectDetailedInformationPdf,
  acceleration_program_concept: Elements.AccelerationProgramConceptPdf,
  foreign_partners: Elements.ForeignPartnersPdf,
  acceleration_program_implementation_team: Elements.AccelerationProgramImplementationTeamPdf,
  grant_project_management_team: Elements.GrantProjectManagementTeamPdf,
  result_of_costs_and_benefits_analysis: Elements.ResultOfCostsAndBenefitsAnalysisPdf,
  project_implementation_concept: Elements.ProjectImplementationConceptPdf,
  applicant_experience: Elements.ApplicantExperiencePdf,
  innovation_description: Elements.InnovationDescriptionPdf,
  business_model_description: Elements.BusinessModelDescriptionPdf,
  business_partners: Elements.BusinessPartnersPdf,
  export_potential: Elements.ExportPotentialPdf,
  applicant_innovation: Elements.ApplicantInnovationPdf,
  applicant_export_activities: Elements.ApplicantExportActivitiesPdf,
  applicant_product_brand: Elements.ApplicantProductBrandPdf,
  support_so_far_received: Elements.SupportSoFarReceivedPdf,
  promotional_activities: Elements.PromotionalActivitiesPdf,
  applicant_experience_in_innovative_business_ideas_development: Elements
    .ApplicantExperienceInInnovativeBusinessIdeasDevelopmentPdf,
  innovator_lab_program_concept: Elements.InnovatorLabProgramConceptPdf,
  team_implementing_program_concept: Elements.TeamImplementingProgramConceptPdf,
  project_sme_assumptions: Elements.ProjectSmeAssumptionsPdf,
  reduce_turnover: Elements.ReduceTurnoverPdf,
  business_idea_information: Elements.BusinessIdeaInformationPdf,
  originator: Elements.OriginatorPdf,
  venture_implementation_justification: Elements.VentureImplementationJustificationPdf,
  venture_feasibility: Elements.VentureFeasibilityPdf,
  ue_program_subject_eurogrant: Elements.UeProgramSubjectEurograntPdf,
  business_model_elements: Elements.BusinessModelElementsPdf,
  applicant_prepared_solution: Elements.ApplicantPreparedSolutionPdf,
  startup_applicant_information: Elements.StartupApplicantInformationPdf,
  goz_transformation_implementation: Elements.GozTransformationImplementationPdf,
  project_financial_resources: Elements.ProjectFinancialResourcesPdf,
  applicant_representative: Elements.ApplicantRepresentativePdf,
};

/**
 * Gets element from mapping or if not exists, returns UnhandledElement.
 *
 * @param {string} name - element name
 * @returns {Node | string} - element from mapping or UnhandledElement
 */
export const getPdfElement = (name) => MAPPING[name] ?? null;
