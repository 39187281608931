import {
  Button,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import uniqid from 'uniqid';
import { Draggable } from 'react-beautiful-dnd';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';

/**
 * Single Additional Option element.
 *
 * @param {object} props - root props
 * @param {Function} props.deleteOption - delete option
 * @param {number} props.index - current option index
 * @param {string} props.optionDataKey - option data key
 * @returns {SingleAdditionalOption}
 */
function SingleAdditionalOption({
  deleteOption, index, optionDataKey,
}) {
  const { control } = useFormContext();

  const validation = {
    label: {
      required: {
        value: true,
        message: 'Pole "Treść opcji" musi być uzupełnione.',
      },
    },
  };

  return (
    <Draggable draggableId={`drag-${optionDataKey}`} index={index} key={index}>
      {(provided) => (
        <TableRow
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          index={index}
        >
          <TableCell>
            <FormControl fullWidth spacing={3} variant="outlined">
              <Controller
                name={`options.${index}.label`}
                control={control}
                rules={validation.label}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                    label="Treść opcji"
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell>
            <Button
              id={uniqid()}
              variant="contained"
              color="primary"
              aria-label="Usuń punkt z oświadczeń"
              onClick={() => deleteOption(index)}
              fullWidth
            >
              Usuń
            </Button>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
}

export default SingleAdditionalOption;

SingleAdditionalOption.propTypes = {
  optionDataKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  deleteOption: PropTypes.func.isRequired,
};
