import { Draggable } from 'react-beautiful-dnd';
import {
  Badge,
  Button,
  Checkbox,
  TableCell,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { theme } from '../../styles/theme';

const classes = {
  slBadge: {
    '& .MuiBadge-badge': {
      border: '1px solid #000000',
      right: theme.spacing(-3),
      background: '#fff',
      color: '#000000',
    },
  },
};
/**
 * Draggable field row
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - field data object
 * @param {number} props.index - index
 * @param {Function} props.selectedFieldHandler - selected field handler
 * @param {Function} props.printableCheckHandler - printable check handler
 * @returns {FieldRow}
 */
export function FieldRow({
  fieldData, index, selectedFieldHandler, printableCheckHandler,
}) {
  const {
    uuid,
    printable,
    operatedBySl,
    name,
    label,
    title,
  } = fieldData;

  return (
    <Draggable draggableId={uuid} index={index} key={index}>
      {(provided) => (
        <TableRow
          key={uuid}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          index={index}
        >
          <TableCell>
            <Checkbox
              checked={printable}
              onChange={(event) => printableCheckHandler(event, fieldData)}
            />
          </TableCell>
          <TableCell>
            <Badge
              sx={classes.slBadge}
              badgeContent="CST"
              invisible={!operatedBySl}
            >
              {title}
            </Badge>
          </TableCell>
          <TableCell>
            {label ?? name}
          </TableCell>
          <TableCell>
            <Button
              id={uniqid()}
              color="secondary"
              variant="contained"
              onClick={() => selectedFieldHandler(fieldData)}
            >
              Edytuj
            </Button>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
}

FieldRow.propTypes = {
  fieldData: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    printable: PropTypes.bool.isRequired,
    operatedBySl: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  selectedFieldHandler: PropTypes.func.isRequired,
  printableCheckHandler: PropTypes.func.isRequired,
};
