import {
  TableCell,
  TableRow,
} from '@mui/material';

/**
 * Renders table row with "Brak wierszy do wyświetlenia" message.
 *
 * @returns {TableRow} row with empty dataset message
 */
export function EmptyDatasetRow() {
  return (
    <TableRow>
      <TableCell colSpan={99} align="center">Brak wierszy do wyświetlenia</TableCell>
    </TableRow>
  );
}
