import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  CHARS_LIMIT,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * ProjectCollaboration element renderer.
 *
 * @returns {ProjectCollaboration}
 */
export function ProjectCollaboration() {
  const {
    control, setValue, watch, resetField,
  } = useFormContext();

  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { projectCollaborationTypes: {
    research, nonGovernmental, notApplicable,
  } } = ELEMENTS_DICTIONARIES_MAP;

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.projectCollaborationTypes);

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'cooperating_entity',
  });

  const collaborationTypeValues = watch('project_collaboration_project_collaboration_type');

  /**
   * Creates options depending on whether an option "Nie dotyczy" has been selected
   * for the "CustomAutocomplete" component.
   *
   * @param {Function} filterOptions - function to filter options by selected in config recruitment
   * @param {Array} value - input value
   * @returns {Array}
   */
  const getOptionsByValue = (filterOptions, value) => {
    const projectCollaborationTypes = get(DICTIONARIES.projectCollaborationTypes.name);
    const selectedOptions = filterOptions(projectCollaborationTypes);

    if (value?.includes(notApplicable.id)) {
      return selectedOptions.filter(({ '@id': id }) => id === notApplicable.id);
    }
    if (value?.length > 0) {
      return selectedOptions.filter(({ '@id': id }) => id !== notApplicable.id);
    }

    return selectedOptions;
  };

  const config = fieldsConfig.cooperating_entity;
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);
  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <FormField name="project_collaboration_project_collaboration_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            multiple
            renderSelectAllButton={false}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (!event.target.value?.includes(research.id)
                && !event.target.value?.includes(nonGovernmental.id)) {
                resetField('cooperating_entity', { defaultValue: FIELD_TYPES_MAP.collection });
              }
            }}
            onBlur={onBlur}
            options={getOptionsByValue(filterOptions, value)}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <LabeledFieldRow
        hide={!collaborationTypeValues?.includes(research.id) && !collaborationTypeValues?.includes(nonGovernmental.id)}
        fieldConfig={config}
        contextHelpLabel
        fullWidth
      >
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'cooperating_entity',
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            <FormField name={`cooperating_entity.${index}.name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`cooperating_entity.${index}.nip`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.NIP} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? CHARS_LIMIT.NIP,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`cooperating_entity.${index}.krs`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.KRS} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? CHARS_LIMIT.KRS,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`cooperating_entity.${index}.description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`cooperating_entity.${index}.effects_and_benefits`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="FsNSEzsGxKf22ww"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
