export const innerCollectionStyles = {
  backgroundColor: '#fff !important',
  marginLeft: (theme) => `${theme.spacing(10)} !important`,
  width: (theme) => `calc(100% - ${theme.spacing(10)}) !important`,
};

export const innerCollectionWrapperStyles = {
  padding: (theme) => theme.spacing(2),
  border: '1px solid #e1e3e4',
  // eslint-disable-next-line max-len
  boxShadow: 'box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
};
