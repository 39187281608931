import {
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import FieldWrapper from '../FieldWrapper';

/**
 * String length validation
 *
 * @param {object} props - root props
 * @param {number} props.index - validation index
 * @param {object} props.rule - rule data
 * @returns {StringLength}
 */
function StringLength({
  index, rule,
}) {
  const {
    control, watch,
  } = useFormContext();

  const STRING_LENGTH_LABELS = {
    min_length: 'Minimalna liczba znaków',
    max_length: 'Maksymalna liczba znaków',
  };

  const isEnabled = watch(`rules.${index}.enabled`);

  return (
    <FieldWrapper name={`rules.${index}.options.limit`} label={STRING_LENGTH_LABELS[rule.name]}>
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Controller
          name={`rules.${index}.options.limit`}
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
          }) => (
            <TextField
              type="number"
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              disabled={!isEnabled}
            />
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
}

export default StringLength;

StringLength.propTypes = {
  index: PropTypes.number.isRequired,
  rule: PropTypes.instanceOf(Object).isRequired,
};
