import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import { useState } from 'react';
import { ElementRow } from './ElementRow';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';

/**
 * Elements list component.
 *
 * @param {object} props - root props
 * @param {Array} props.elements - collection of template elements
 * @param {Function} props.reloadHandler - reload application template from API
 * @param {Function} props.selectedElementHandler - template element selection handler
 * @param {Array} props.elementsOrder - elements order (template elements identifiers)
 * @param {string} props.templateId - current application template identifier
 * @returns {ElementsList}
 */
export function ElementsList({
  elements,
  reloadHandler,
  selectedElementHandler,
  elementsOrder,
  templateId,
}) {
  const [loading, setLoading] = useState(false);

  /**
   * Handle dragging action.
   *
   * @param {object} event - draggable event
   */
  const handleDrag = async (event) => {
    const {
      destination,
      source,
    } = event;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
    && destination.index === source.index) {
      return;
    }

    const templateElements = Array.from(elements);
    const [removed] = templateElements.splice(event.source.index, 1);
    templateElements.splice(event.destination.index, 0, removed);

    setLoading(true);
    await request.put(
      `${API_ROUTE.applicationTemplates}/${templateId}`,
      {
        elementsOrder: templateElements.map(({ id }) => id),
      }
    );

    await reloadHandler(() => setLoading(false));
  };

  const removeElementHandler = async (elementUuid) => {
    setLoading(true);
    const { statusSuccess } = await request.delete(
      `${API_ROUTE.templateElements}/${elementUuid}`
    );

    if (statusSuccess) {
      reloadHandler(() => setLoading(false));
    }
  };

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Typography variant="pageHeading" mt={5}>
        Zastosowane elementy
      </Typography>
      <TableContainer>
        <Table aria-label="Lista elementow">
          <TableHead>
            <TableRow>
              <TableCell scope="column">
                <Typography component="span" fontWeight="bold">
                  Element
                </Typography>
              </TableCell>
              <TableCell scope="column">
                <Typography component="span" fontWeight="bold">
                  Nazwa
                </Typography>
              </TableCell>
              <TableCell scope="column">
                <Typography component="span" fontWeight="bold">
                  Wybrane moduły
                </Typography>
              </TableCell>
              <TableCell scope="column">
                <Typography component="span" fontWeight="bold">
                  Grupa
                </Typography>
              </TableCell>
              <TableCell scope="column" align="center">
                <Typography component="span" fontWeight="bold">
                  Akcje
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <TableBody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {elements
                    .sort(({ id: idA }, { id: idB }) => elementsOrder.indexOf(idA) - elementsOrder.indexOf(idB))
                    .map((templateElement, index) => (
                      <ElementRow
                        templateElementData={templateElement}
                        index={index}
                        removeElementHandler={removeElementHandler}
                        selectedElementHandler={selectedElementHandler}
                        key={templateElement.id}
                      />
                    ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
}

ElementsList.propTypes = {
  elements: PropTypes.arrayOf(Object).isRequired,
  reloadHandler: PropTypes.func.isRequired,
  selectedElementHandler: PropTypes.func.isRequired,
  elementsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateId: PropTypes.string.isRequired,
};
