import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StatementsElementEditForm } from './StatementsElementEdit.form';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { SaveCancelButtons } from '../../../Features/SaveCancelButtons';
import { StatementsList } from './StatementsList';

/**
 * Dialog box for editing statements.
 *
 * @param {object} props - root props
 * @param {string} props.templateElementId - ID of edited template element
 * @param {Function} props.closeHandler - close button handler
 * @returns {StatementsElementEditDialog}
 */
export function StatementsElementEditDialog({
  templateElementId, closeHandler,
}) {
  const statementsElementEditFormRef = useRef();
  const statementsListRef = useRef();
  const isOpen = Boolean(templateElementId);
  const [requestProgress, setRequestProgress] = useState(true);
  const [templateElement, setTemplateElement] = useState(null);

  const loadTemplateElement = useCallback(async () => {
    const { payload } = await request.get(`${API_ROUTE.templateElement}/${templateElementId}`);

    setTemplateElement(payload);
    setRequestProgress(false);
  }, [templateElementId]);

  useEffect(() => {
    loadTemplateElement();
  }, [loadTemplateElement]);

  const onSubmit = async () => {
    statementsElementEditFormRef.current.submitStatementElement();
    statementsListRef.current.handleSubmit();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      maxWidth="xl"
      fullWidth
      variant="outlined"
      color="secondary"
    >
      <DialogTitle component="div">
        <Typography variant="dialogHeading">
          {templateElement?.elementDefinition?.title || 'Oświadczenia'}
        </Typography>
      </DialogTitle>
      {requestProgress && (
        <LinearProgress color="secondary" />
      )}
      {!requestProgress && (
        <>
          <DialogContent>
            {templateElement.id && (
              <>
                <StatementsElementEditForm ref={statementsElementEditFormRef} templateElement={templateElement} />
                <Box my={5}>
                  <Divider />
                </Box>
                <StatementsList
                  ref={statementsListRef}
                  templateElementId={templateElement.id}
                  currentStatementList={templateElement?.elementDefinition?.fields}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <SaveCancelButtons
              saveHandler={onSubmit}
              saveButtonId="S7tOBIpJ9MMxD94"
              cancelHandler={closeHandler}
              cancelButtonId="gjII6dVJhlAj3lj"
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

StatementsElementEditDialog.propTypes = {
  templateElementId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
