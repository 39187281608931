import { TextField } from '@mui/material';
import { FormField } from '../../Application/Application/FormField';
import { useElementContext } from '../../../Context';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * BrImplementationPotential component
 *
 * @returns {BrImplementationPotential}
 */
function BrImplementationPotential() {
  const {
    isReadonly,
  } = useElementContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.resultImplementationMethods,
    DICTIONARIES.technicalExaminationPerformers,
  );

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <FormField name="results_implementation_way">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            multiple
            options={filterOptions(get(DICTIONARIES.resultImplementationMethods.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      <FormField name="results_implementation_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="estimated_implementation_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            views={['month', 'year']}
            ariaLabel={label}
            format="yyyy-MM"
            mask="____-__"
            placeholder="rrrr-mm"
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="implementation_as_part_of_innovations_module">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <FormField name="intellectual_property_rights_label" contextHelpLabel>
        {() => null}
      </FormField>
      <FormField name="barriers_absence_demonstration">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="technical_condition_examination_label" contextHelpLabel>
        {() => null}
      </FormField>
      <FormField name="examination_date_technology_condition">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="examination_technology_condition_conducted_by">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.technicalExaminationPerformers.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <FormField name="used_databases">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="subject_classification_way">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="used_keywords">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="results_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="protection_subject">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="law_regulations_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}

export default BrImplementationPotential;
