import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Eco Innovations Pdf element.
 *
 * @returns {EcoInnovationsPdf}
 */
function EcoInnovationsPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    application,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];
  const isEcoInnovationImplementation = initialFormData?.ecoinnovations_country_level_implementation
    === BOOLEAN_VALUES.TRUE;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Czy projekt dotyczy opracowania ekoinnowacji na poziomie kraju */}
        <RowField name="ecoinnovations_country_level_development" />
        {/* Czy projekt dotyczy wdrożenia ekoinnowacji na poziomie kraju */}
        <RowField name="ecoinnovations_country_level_implementation" />
        {/* Moduł z wdrożeniem ekoinnowacji na poziomie kraju */}
        {isEcoInnovationImplementation && (
          <RowField name="ecoinnovations_modules" externalDictionary={modules} />
        )}
      </FieldSorter>
    </View>
  );
}

export default EcoInnovationsPdf;
