import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import { getOwnIndicators } from '../../../../../_helpers';
import { useApplicationToPdfContext } from '../../../../../Context';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Ue HorizontalPolicies Project Compliance 6RRule Compatibility Pdf element.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf}
 */
function UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      elementsConfig,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();
  const combinedIndicatorsDictionary = [
    ...getOwnIndicators(
      elementsConfig,
      initialFormData,
      item.indicators,
    ),
    ...getOwnIndicators(
      elementsConfig,
      previousInitialFormData,
      previousRowData?.indicators,
    ),
  ];

  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.6r_rule`}
            value={item['6r_rule']}
            oldValue={previousRowData?.['6r_rule']}
            dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectComplianceR6Rules.name}
          />
          <RowField
            name={`${collectionName}.${index}.implementation_description`}
            value={item.implementation_description}
            oldValue={previousRowData?.implementation_description}
            isSingleRow
          />
          <RowField
            name={`${collectionName}.${index}.indicators`}
            value={item.indicators}
            oldValue={previousRowData?.indicators}
            externalDictionary={combinedIndicatorsDictionary.map((indicator) => ({
              '@id': indicator.id,
              name: indicator.name,
            }))}
          />
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf;

UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf.defaultProps = {
  previousRowData: {},
};
