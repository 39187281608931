import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { MonetaryField } from '../../MonetaryField';

/**
 * SupportSoFarReceived element.
 *
 * @returns {SupportSoFarReceived}
 */
export function SupportSoFarReceived() {
  const { isReadonly } = useElementContext();

  return (
    <>
      {/* Pomoc na udział MSP w targach w roku bieżącym [EUR] */}
      <FormField name="support_so_far_received_participation_aid_sme">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            id={name}
            name={name}
            variant="outlined"
            defaultValue={value}
            error={!!error}
            helperText={error?.message}
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            disabled={isReadonly}
          />
        )}
      </FormField>
    </>
  );
}
