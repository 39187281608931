import { useState } from 'react';
import {
  FormHelperText,
  List,
  ListItem,
  ListSubheader,
} from '@mui/material';
import { theme } from '../styles/theme';

const classes = {
  passwordRequirementsList: {
    marginTop: theme.spacing(1),
    border: '1px ridge #f44336',
    paddingBottom: 0,
    '& #passwordRequirementsListSubheader': {
      backgroundColor: 'rgba(255,255,255, 0.5)',
    },
    '& li': {
      backgroundColor: 'rgba(255,255,255, 0.5)',
    },
  },
};

/**
 * Hook for password validation with requirements list.
 *
 * @returns {{
 *     violations: {
 *       isLongEnough: boolean,
 *       hasUppercase: boolean,
 *       hasDigit: boolean,
 *       hasLowercase: boolean,
 *       hasSpecialCharacter: boolean
 *     },
 *     checkPassword: Function,
 *     renderViolations: Function
 * }} Hook data and functions
 */
export function usePasswordRequirements() {
  const [violations, setViolations] = useState({
    isLongEnough: false,
    hasUppercase: false,
    hasDigit: false,
    hasLowercase: false,
    hasSpecialCharacter: false,
  });
  const specialCharacters = 'Akceptowalne znaki specjalne: \n ~!@#$%^&*()_+-=[]\'{}|;:`",.<>/\\?';

  /**
   * Check if password met requirements.
   *
   * @param {string} password password to test
   */
  const checkPassword = (password) => {
    const regexp = (pattern) => pattern.test(password);
    setViolations(() => ({
      isLongEnough: regexp(/^.{16,}/),
      hasUppercase: regexp(/\p{Lu}+/u),
      hasDigit: regexp(/\d+/),
      hasLowercase: regexp(/\p{Ll}+/u),
      hasSpecialCharacter: regexp(/[$&+,:;=?@#|'<>.^*()%!{}[\]_~"`\-\\/]+/),
    }));
  };

  /**
   * Returns check result.
   *
   * @returns {boolean} password validity
   */
  const isValid = () => !Object.values(violations).includes(false);

  /**
   * Renders violation list.
   *
   * @returns {object} JSX Element violation list
   */
  const renderViolations = () => (
    <FormHelperText
      component="div"
      sx={{
        margin: 0,
        backgroundColor: '#f2f2f2',
      }}
    >
      {violations && !isValid() && (
        <List
          id="zAz2P04DHjpnHZP"
          sx={classes.passwordRequirementsList}
          subheader={(
            <ListSubheader component="div" id="RTeb5LIqH2zJ6MK">
              Wymagania dotyczące hasła:
            </ListSubheader>
          )}
        >
          {violations && !violations.isLongEnough && (
            <ListItem>Hasło musi zawierać nie mniej niż 16 znaków;</ListItem>
          )}
          {violations && !violations.hasDigit && (
            <ListItem>Hasło musi zawierać nie mniej niż 1 cyfrę;</ListItem>
          )}
          {violations && !violations.hasUppercase && (
            <ListItem>Hasło musi zawierać nie mniej niż 1 dużą literę;</ListItem>
          )}
          {violations && !violations.hasLowercase && (
            <ListItem>Hasło musi zawierać nie mniej niż 1 małą literę;</ListItem>
          )}
          {violations && !violations.hasSpecialCharacter && (
            <ListItem>
              {`Hasło musi zawierać nie mniej niż 1 znak specjalny. ${specialCharacters}`}
            </ListItem>
          )}
        </List>
      )}
    </FormHelperText>
  );

  return {
    violations,
    checkPassword,
    renderViolations,
  };
}
