import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import {
  API_ROUTE,
  DICTIONARIES,
} from '../../../_constants';
import { useElementContext } from '../../../Context';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  iriToId,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { request } from '../../../_services';

/**
 * Research agenda - R&D infrastructure component
 *
 * @returns {ResearchAgendaRdInfrastructure}
 */
function ResearchAgendaRdInfrastructure() {
  const [attachments, setAttachments] = useState([]);
  const {
    control, setValue, getValues,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const { id: applicationId } = useParams();

  const applicationData = getValues();

  const attachmentsIri = Object.keys(applicationData)
    .flatMap((item) => (item.includes('attachments_') ? applicationData[item] : []));
  const mappedAttachments = attachmentsIri.map(iriToId);

  useEffect(() => {
    const loadApiData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationAttachments.replace(':id', applicationId)}?id[]=${mappedAttachments.join('&id[]=')}`
      );

      if (statusSuccess) {
        setAttachments(payload);
      }
    };

    if (attachmentsIri.length > 0) {
      loadApiData();
    }
  }, [mappedAttachments]);

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.innovationResultingFromImplementationOfResearchAreaTypes,
    DICTIONARIES.noveltyOfResultsOfResearchAreas,
  );

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'main_innovative_research_solutions',
  });

  const mainInnovativeResearchSolutionsConfig = fieldsConfig.main_innovative_research_solutions;
  const subfields = mainInnovativeResearchSolutionsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = mainInnovativeResearchSolutionsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  const noOptionsText = 'Brak dodanych załączników. W celu uzupełnienia pola proszę o załączenie pliku '
    + ' w sekcji "Załączniki"';

  return (
    <FieldSorter>
      {/* Data rozpoczęcia realizacji prac B+R w ramach agendy badawczej */}
      <FormField name="implementation_start_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data zakończenia realizacji prac B+R w ramach agendy badawczej */}
      <FormField name="implementation_end_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Główne innowacyjne obszary badawcze" */}
      <LabeledFieldRow fieldConfig={mainInnovativeResearchSolutionsConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'main_innovative_research_solutions',
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            isIndexed
          >
            {/* Nazwa i krótki opis procesu badawczego */}
            <FormField name={`main_innovative_research_solutions.${index}.area_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Opis obszaru badawczego */}
            <FormField name={`main_innovative_research_solutions.${index}.area_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Indykatywny (orientacyjny) plan prac badawczo – rozwojowych, obejmujący okres trwałości projektu */}
            <FormField name={`main_innovative_research_solutions.${index}.rd_work_plan`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Rodzaj innowacji będących rezultatem realizacji obszaru badawczego */}
            <FormField
              // eslint-disable-next-line max-len
              name={`main_innovative_research_solutions.${index}.innovation_resulting_from_implementation_of_research_area_type`}
            >
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(
                    get(DICTIONARIES.innovationResultingFromImplementationOfResearchAreaTypes.name)
                  )}
                  disabled={isReadonly}
                  multiple
                  renderSelectAllButton={false}
                  error={error}
                />
              )}
            </FormField>
            {/* Nowość rezultatów obszaru badawczego */}
            <FormField name={`main_innovative_research_solutions.${index}.novelty_of_results_of_research_area`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.noveltyOfResultsOfResearchAreas.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis innowacji produktowej/ innowacji w procesie biznesowym */}
            <FormField name={`main_innovative_research_solutions.${index}.product_innovation_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Dodatkowy załącznik graficzny */}
            <FormField name={`main_innovative_research_solutions.${index}.graphic_attachment`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={attachments}
                  optionsMapKeys={['@id', 'description']}
                  multiple
                  renderSelectAllButton={false}
                  noOptionsText={noOptionsText}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Analiza zapotrzebowania rynkowego na produkty/ usługi,
            procesy wytworzone w oparciu o wyniki prac B+R */}
            <FormField name={`main_innovative_research_solutions.${index}.market_demand_analysis`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="c5Omed55XnkdiH1"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Założenia strategii rozwoju działalności przedsiębiorstw w oparciu o B+R */}
      <FormField name="business_development_strategy_assumptions">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres prac B+R realizowanych na zlecenie w przypadku, gdy dodatkowo są przewidziane przez wnioskodawcę */}
      <FormField name="rd_works_carried_scope">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Prawa własności intelektualnej związane z agendą badawczą */}
      <FormField name="intellectual_property_rights">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}

export default ResearchAgendaRdInfrastructure;
