import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { getImplementersAndApplicantNameForPdf } from '../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Regional Investment Aid Greening Pdf element.
 *
 * @returns {RegionalInvestmentAidGreeningPdf}
 */
function RegionalInvestmentAidGreeningPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const { initialInvestmentTypes } = ELEMENTS_DICTIONARIES_MAP;

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="regional_investment_aid_greening_is_regional" />
        {initialFormData?.regional_investment_aid_greening_is_regional === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField
              name="regional_investment_aid_greening__innovation_implementation_is_regional__description"
              isSingleRow
            />
            <CollectionWrapper name="regional_investment_aid_greening_investment_aid">
              {({
                collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
              }) => collectionData.map((item, index) => {
                const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

                return (
                  <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.applicant_name`}
                        value={item.applicant_name}
                        oldValue={previousRowData?.applicant_name}
                        externalDictionary={combinedApplicantName}
                      />
                      <RowField
                        name={`${collectionName}.${index}.initial_investment_type`}
                        value={item.initial_investment_type}
                        oldValue={previousRowData?.initial_investment_type}
                        dictionaryName={DICTIONARIES.initialInvestmentTypes.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.reason`}
                        value={item.reason}
                        oldValue={previousRowData?.reason}
                      />
                      {item.initial_investment_type === initialInvestmentTypes.investmentInTangibleFixedAssets.id && (
                        <>
                          <RowField
                            name={`${collectionName}.${index}.reused_assets`}
                            value={item.reused_assets}
                            oldValue={previousRowData?.reused_assets}
                          />
                          <RowField
                            name={`${collectionName}.${index}.reused_assets_book_value`}
                            value={item.reused_assets_book_value}
                            oldValue={previousRowData?.reused_assets_book_value}
                          />
                        </>
                      )}
                    </FieldSorter>
                  </SingleCollectionWrapper>
                );
              })}
            </CollectionWrapper>
          </>
        )}
        <RowField name="regional_investment_aid_greening_is_environmental_protection" />
        {initialFormData?.regional_investment_aid_greening_is_environmental_protection === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="regional_investment_aid_greening_environmental_protection_aid_type">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.applicant_name`}
                        value={item.applicant_name}
                        oldValue={previousRowData?.applicant_name}
                        externalDictionary={combinedApplicantName}
                      />
                      <RowField
                        name={`${collectionName}.${index}.investment_type_greening`}
                        value={item.investment_type_greening}
                        oldValue={previousRowData?.investment_type_greening}
                        dictionaryName={DICTIONARIES.investmentTypeGreenings.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.conditions_description`}
                        value={item.conditions_description}
                        oldValue={previousRowData?.conditions_description}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default RegionalInvestmentAidGreeningPdf;
