import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import {
  useGlobalContext,
  useReloadListener,
} from '../../../Context';

/**
 * Hrf validation configurator save button.
 *
 * @param {object} props - root props
 * @param {string} props.applicationTemplateId - application template id
 * @returns {HrfValidationConfiguratorSaveButton}
 */
export function HrfValidationConfiguratorSaveButton({ applicationTemplateId }) {
  const { notify } = useGlobalContext();
  const { handleSubmit } = useFormContext();
  const { reload } = useReloadListener();
  const watcherName = 'hrf-validation-configurator';

  const onSubmit = async (values) => {
    const { statusSuccess } = await request.put(API_ROUTE.projectBudgetLimits
      .replace(':applicationTemplateId', applicationTemplateId), values);

    if (statusSuccess) {
      reload(watcherName);
      notify('Walidacje dla harmonogramu rzeczowo-finansowego zostały zapisane.', 'success');

      return;
    }

    notify('Nie udało się zapisać walidacji dla harmonogramu rzeczowo-finansowego.', 'error');
  };

  return (
    <Button
      id="BE1mCD6kjLgAoOI"
      color="secondary"
      variant="contained"
      onClick={handleSubmit(onSubmit)}
    >
      Zapisz
    </Button>
  );
}

HrfValidationConfiguratorSaveButton.propTypes = {
  applicationTemplateId: PropTypes.string.isRequired,
};
