import {
  useEffect,
  useState,
} from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  API_ROUTE,
  DICTIONARIES,
} from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { request } from '../../../_services';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Digitization Module Essence element.
 *
 * @returns {DigitizationModuleEssence}
 */
export function DigitizationModuleEssence() {
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.solutionInnovationLevels,
  );
  const {
    fieldsConfig,
    isReadonly,
  } = useElementContext();
  const {
    getValues,
  } = useFormContext();
  const { id: applicationId } = useApplicationContext();

  const [attachments, setAttachments] = useState([]);
  const [attachmentIris, setAttachmentIris] = useState([]);

  useEffect(() => {
    loadModules();
  }, []);

  useEffect(() => {
    loadApplicationAttachments();
  }, [attachmentIris]);

  /**
   * Loads Attachments from API.
   */
  const loadApplicationAttachments = async () => {
    const {
      payload,
    } = await request.get(`${API_ROUTE.applicationAttachments.replace(':id', applicationId)}`);

    setAttachments(
      payload.filter((attachment) => attachmentIris.includes(attachment['@id']))
    );
  };

  /**
   * Load module names.
   */
  const loadModules = async () => {
    const {
      payload,
    } = await request.get(`${API_ROUTE.modules}`);

    const currentModule = payload.find((module) => module.name === 'Cyfryzacja')?.['@id'];

    /**
   * Filtered iris of attachments element for digitization module.
   * Contains only `attachments` type fields.
   */
    setAttachmentIris(
      Object
        .values(fieldsConfig)
        .filter((fieldConfig) => fieldConfig.type === 'attachments')
        .filter((fieldConfig) => fieldConfig.modules.includes(currentModule))
        .reduce((prev, field) => (
          prev.concat(getValues(field.name))
        ), [])
    );
  };

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <FormField name="digitization_module_essence_module_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="digitization_module_essence_features_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      <FormField name="digitization_module_essence_attachments">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={attachments}
            multiple
            optionsMapKeys={['@id', 'description']}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>

      <FormField name="digitization_module_essence_solution_innovation_level">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            multiple
            options={filterOptions(get(DICTIONARIES.solutionInnovationLevels.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>

      <FormField name="digitization_module_essence_innovation_level_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
