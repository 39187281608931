import {
  EXPERT_ROLES,
  MODULES_ORDER,
} from '../_constants';

const getModuleOrder = (moduleId) => MODULES_ORDER[moduleId ? `/lsi/recruitments/api/modules/${moduleId}` : null];
const getMappedModules = (modules, criteriaSet) => {
  const getEvaluatorCount = (id) => criteriaSet?.modules?.find(({ moduleId }) => moduleId === id)?.evaluatorCount;

  const mappedModules = modules?.map((item) => ({
    ...item,
    evaluatorCount: getEvaluatorCount(item.moduleId),
    experts: item.experts.map((expert) => ({
      ...expert,
      externalExpert: expert.externalExpert ? expert.externalExpert['@id'] : null,
      evaluationFee: expert.evaluationFee.toString(),
    })),
  }))?.sort((
    { moduleId: moduleIdA },
    { moduleId: moduleIdB }
  ) => getModuleOrder(moduleIdA) - getModuleOrder(moduleIdB))
    || [];

  mappedModules.forEach(({
    experts, moduleId,
  }, index) => {
    const refusedExpert = experts.filter(({ refused }) => refused);
    for (let i = experts.length; i < refusedExpert.length + getEvaluatorCount(moduleId); i++) {
      mappedModules[index].experts.push({
        externalExpert: null,
        role: getEvaluatorCount(moduleId) === 1 ? EXPERT_ROLES.leadingExpert : null,
        evaluationFee: '0',
        acceptanceDate: null,
        refusalDate: null,
        newExpert: true,
      });
    }
  });

  return mappedModules;
};

const mappedEvaluationCommitteesExternalExperts = (evaluationCommitteesData) => evaluationCommitteesData
  ?.externalExperts?.map(({
    user: {
      fullName,
    }, '@id': id,
  }) => ({
    id,
    name: fullName,
  })) || [];

const defaultValues = (initialPanelData) => ({
  remarks: initialPanelData?.remarks || '',
  chairman: initialPanelData?.chairman ? initialPanelData?.chairman['@id'] : null,
  viceChairman: initialPanelData?.viceChairman ? initialPanelData?.viceChairman['@id'] : null,
  secretary: initialPanelData?.secretary ? initialPanelData?.secretary['@id'] : null,
  viceSecretary: initialPanelData?.viceSecretary ? initialPanelData?.viceSecretary['@id'] : null,
  kisDomainAreas: initialPanelData?.kisDomainAreas || [],
  finalDate: initialPanelData?.finalDate || null,
  applications: initialPanelData?.applications?.length !== 0 ? {
    ...initialPanelData?.applications?.[0],
    applicant: initialPanelData?.applications?.[0]?.applicant?.['@id'],
  } : null,
  leadingExpert: initialPanelData?.leadingExpert?.externalExpert?.['@id'] || null,
  leadingExpertIri: initialPanelData?.leadingExpert?.['@id'],
  modules: expertPanelsHelper.getMappedModules(initialPanelData?.modules) || [],
  started: initialPanelData?.started || false,
  isCreateMode: Object.values(initialPanelData).length === 0,
});

export const expertPanelsHelper = {
  getMappedModules,
  mappedEvaluationCommitteesExternalExperts,
  defaultValues,
};

export const isEmployeeAssignedToExpertPanel = (panelData, loggedUserId) => (
  [
    panelData?.chairman?.user?.id,
    panelData?.viceChairman?.user?.id,
    panelData?.secretary?.user?.id,
    panelData?.viceSecretary?.user?.id,
  ].includes(loggedUserId)
);
