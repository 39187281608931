import PropTypes from 'prop-types';
import {
  Controller,
  FormProvider,
  useForm,
} from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import { HrfValidationConfiguratorSaveButton } from './HrfValidationConfiguratorSaveButton';
import { useGlobalDialog } from '../../../Context';
import {
  MonetaryField,
  parsePastedText,
} from '../../../Features/MonetaryField';

/**
 * Hrf validation configurator form.
 *
 * @param {object} props - root props
 * @param {object} props.projectBudgetLimitsData - project budget limits data
 * @param {string} props.applicationTemplateId - application template id
 * @returns {HrfValidationConfiguratorForm}
 */
export function HrfValidationConfiguratorForm({
  projectBudgetLimitsData, applicationTemplateId,
}) {
  const { closeAll } = useGlobalDialog();
  const form = useForm({
    defaultValues: {
      minTotalExpenses: projectBudgetLimitsData.minTotalExpenses,
      maxTotalExpenses: projectBudgetLimitsData.maxTotalExpenses,
      minEligibleExpenses: projectBudgetLimitsData.minEligibleExpenses,
      maxEligibleExpenses: projectBudgetLimitsData.maxEligibleExpenses,
      minCoFinancingValue: projectBudgetLimitsData.minCoFinancingValue,
      maxCoFinancingValue: projectBudgetLimitsData.maxCoFinancingValue,
      minCoFinancingPercent: projectBudgetLimitsData.minCoFinancingPercent,
      maxCoFinancingPercent: projectBudgetLimitsData.maxCoFinancingPercent,
    },
    mode: 'all',
  });

  const {
    control,
  } = form;

  return (
    <FormProvider {...form}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="minTotalExpenses">
            Minimalna wartość wydatków ogółem
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Minimalna wartość wydatków ogółem"',
                },
              }}
              name="minTotalExpenses"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Minimalna wartość wydatków ogółem"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="maxTotalExpenses">
            Maksymalna wartość wydatków ogółem
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Maksymalna wartość wydatków ogółem"',
                },
              }}
              name="maxTotalExpenses"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Maksymalna wartość wydatków ogółem"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="minEligibleExpenses">
            Minimalna wartość wydatków kwalifikowanych
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Minimalna wartość wydatków kwalifikowanych"',
                },
              }}
              name="minEligibleExpenses"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Minimalna wartość wydatków kwalifikowanych"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="maxEligibleExpenses">
            Maksymalna wartość wydatków kwalifikowanych
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Maksymalna wartość wydatków kwalifikowanych"',
                },
              }}
              name="maxEligibleExpenses"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Maksymalna wartość wydatków kwalifikowanych"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="minCoFinancingValue">
            Minimalna wartość dofinansowania
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Minimalna wartość dofinansowania"',
                },
              }}
              name="minCoFinancingValue"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Minimalna wartość dofinansowania"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="maxCoFinancingValue">
            Maksymalna wartość dofinansowania
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Maksymalna wartość dofinansowania"',
                },
              }}
              name="maxCoFinancingValue"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Maksymalna wartość dofinansowania"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="minCoFinancingPercent">
            Minimalny % dofinansowania
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Minimalny % dofinansowania"',
                },
              }}
              name="minCoFinancingPercent"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Minimalny % dofinansowania"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} alignItems="center" display="flex">
          <InputLabel htmlFor="maxCoFinancingPercent">
            Maksymalny % dofinansowania
          </InputLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl fullWidth>
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Maksymalny % dofinansowania"',
                },
              }}
              name="maxCoFinancingPercent"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  label="Maksymalny % dofinansowania"
                  onBlur={onBlur}
                  required
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  inputProps={{
                    allowNegative: false,
                    fixedDecimalScale: true,
                    decimalScale: 2,
                  }}
                  onPaste={(event) => parsePastedText(event, onChange)}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" gap={3} mt={5}>
        <HrfValidationConfiguratorSaveButton applicationTemplateId={applicationTemplateId} />
        <Button
          id="9Blh1lNRa3s2joL"
          color="primary"
          variant="outlined"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      </Box>
    </FormProvider>
  );
}

HrfValidationConfiguratorForm.propTypes = {
  projectBudgetLimitsData: PropTypes.shape({
    minTotalExpenses: PropTypes.number.isRequired,
    maxTotalExpenses: PropTypes.number.isRequired,
    minEligibleExpenses: PropTypes.number.isRequired,
    maxEligibleExpenses: PropTypes.number.isRequired,
    minCoFinancingValue: PropTypes.number.isRequired,
    maxCoFinancingValue: PropTypes.number.isRequired,
    minCoFinancingPercent: PropTypes.number.isRequired,
    maxCoFinancingPercent: PropTypes.number.isRequired,
  }).isRequired,
  applicationTemplateId: PropTypes.string.isRequired,
};
