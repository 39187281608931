import PropTypes from 'prop-types';
import RowConfig from '../../Components/RowConfig/RowConfig';
import IndicatorsTablePdf from './Indicators/IndicatorsTable.pdf';

/**
 * IndicatorsPdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {IndicatorsPdf}
 */
function IndicatorsPdf({ elementData }) {
  const { id: elementId } = elementData;

  return (
    <>
      <RowConfig name={`information_indicators-${elementId}`}>
        {({
          name, fieldValue, label,
        }) => (
          <IndicatorsTablePdf name={name} indicatorsData={fieldValue || []} label={label} />
        )}
      </RowConfig>
      <RowConfig name={`product_indicators-${elementId}`}>
        {({
          name, fieldValue, label,
        }) => (
          <IndicatorsTablePdf name={name} indicatorsData={fieldValue || []} label={label} />
        )}
      </RowConfig>
      <RowConfig name={`result_indicators-${elementId}`}>
        {({
          name, fieldValue, label,
        }) => (
          <IndicatorsTablePdf name={name} indicatorsData={fieldValue || []} label={label} />
        )}
      </RowConfig>
    </>
  );
}

export default IndicatorsPdf;

IndicatorsPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
