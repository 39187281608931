import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { TitledContainer } from '../../TitledContainer';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';
import { ResidenceAddressForm } from './ResidenceAddress.form';
import { MailingAddressForm } from './MailingAddress.form';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import {
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../Context';

/**
 * Expert personal data address form parts with residence&mailing addresses.
 *
 * @returns {Address}
 */
export function Address() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [countries, setCountries] = useState({});
  const {
    address, reloadData, closeHandler, isReadonly,
  } = useExpertPersonalDataContext();
  const { notify } = useGlobalContext();

  /**
   * Handle form submit request.
   *
   * @param {object} formData - form data to send
   */
  const submitForm = async (formData) => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertAddress}/${address.id}`, formData);

    if (statusSuccess) {
      notify('Wprowadzono dane adresowe', 'success');
      reloadData();
      window.scrollTo(0, 0);

      return;
    }

    notify('Wystąpił błąd podczas zapisu danych adresowych', 'error');
    window.scrollTo(0, 0);
  };

  const form = useForm({
    defaultValues: {
      residenceAddress: {
        country: address?.residenceAddress?.country || 'PL',
        town: address?.residenceAddress?.town || '',
        postalCode: address?.residenceAddress?.postalCode || '',
        street: address?.residenceAddress?.street || '',
        houseNumber: address?.residenceAddress?.houseNumber || '',
        apartmentNumber: address?.residenceAddress?.apartmentNumber || '',
      },
      mailingAddress: {
        country: address?.mailingAddress?.country || 'PL',
        town: address?.mailingAddress?.town || '',
        postalCode: address?.mailingAddress?.postalCode || '',
        street: address?.mailingAddress?.street || '',
        houseNumber: address?.mailingAddress?.houseNumber || '',
        apartmentNumber: address?.mailingAddress?.apartmentNumber || '',
      },
      mailingSameAsResidenceAddress: address.mailingSameAsResidenceAddress || false,
    },
    mode: 'all',
    shouldUnregister: true,
  });

  useEffect(() => {
    /**
     * Loads countries dictionary.
     */
    const loadCountries = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(API_ROUTE.intl.countries);

      if (statusSuccess) {
        setCountries(payload?.data || {});
      }

      setLoaded(true);
    };

    loadCountries();
  }, []);

  if (!loaded) {
    return <CircularProgress size={50} color="secondary" aria-label="Trwa ładowanie słowników" />;
  }

  return (
    <FormProvider {...form}>
      <TitledContainer title="Adres zamieszkania" key="residence-address" variant="slim" textAlign="left">
        <ResidenceAddressForm countriesDictionary={countries} isReadonly={isReadonly} />
      </TitledContainer>
      <TitledContainer title="Adres do korespondencji" key="mailing-address" variant="slim" textAlign="left">
        <MailingAddressForm countriesDictionary={countries} isReadonly={isReadonly} />
      </TitledContainer>
      {!isReadonly && (
        <SaveCancelButtons
          saveButtonId="w6gdzLNqm35Ldlb"
          cancelButtonId="GjywqgT5svoLdS1"
          cancelHandler={closeHandler ? () => closeHandler() : () => { navigate('/'); }}
          saveHandler={form.handleSubmit(submitForm)}
        />
      )}
    </FormProvider>
  );
}
