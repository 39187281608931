import {
  AccordionDetails,
  Button,
  List,
  ListItem,
} from '@mui/material';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import {
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  ACTION_TYPES,
  ROLES,
} from '../../_constants';
import {
  getMenuStructure,
  groupStructureByUrl,
} from '../../_helpers';
import SubAccordionItem from './SubAccordionItem';
import { useAuth } from '../../_security';
import { useGlobalContext } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  mainNavBar: {
    boxShadow: 'none',
  },
  screenPlaceholder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9000,
  },
  mainNav: {
    display: 'flex',
    flexDirection: 'row',
    paddingInlineStart: 0,
    paddingTop: theme.spacing(0),
    paddingBottom: 0,
    marginBlockStart: 0,
    fontSize: '1.2em',
    textTransform: 'uppercase',
  },
  mainNavOpened: {
    zIndex: 9001,
  },
  mainNavItem: {
    position: 'relative',
    width: 'inherit',
    padding: theme.spacing(0),
    listStyle: 'none',
    borderBottomStyle: '3px solid transparent',
    transition: 'border-bottom-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&.MuiListItem-root.Mui-selected': {
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
      backgroundColor: 'inherit',
      transition: 'border-bottom-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  mainNavItemLink: {
    display: 'inline-flex',
    padding: theme.spacing(1),
    color: '#484c4e',
    fontWeight: '700',
    fontSize: '0.875rem',
    lineHeight: '1.5em',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      color: theme.palette.primary.main,
    },
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    minWidth: '290px',
    zIndex: 5,
    marginTop: theme.spacing(3),
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
    listStyle: 'none',
    backgroundColor: '#ffffff',
    paddingInlineStart: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: 'rgba(0,0,0,0.2) 0px 3px 3px -2px, rgb(0,0,0,0.14) 0px 3px 4px 0px, rgb(0,0,0,0.12) 0px 1px 8px 0px',
  },
  dropdownItem: {
    backgroundColor: '#ffffff',
    listStyle: 'none',
    textAlign: 'left',
    width: '100%',
    borderWidth: 0,
    '&:hover:not(.MuiExpanded)': {
      backgroundColor: '#eaeaea',
    },
  },
  dropdownItemLink: {
    display: 'inline-block',
    width: '100%',
    padding: theme.spacing(0, 2, 1),
    fontFamily: '"Montserrat-Light", sans-serif',
    lineHeight: '1.5em',
    fontSize: '0.875em',
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
};

/**
 * A component that allows users to navigate through the application
 *
 * @returns {Navigation}
 */
export function Navigation() {
  const {
    profile, authenticated, roles,
  } = useAuth();
  const {
    handleAction, root: {
      isLock, confirmMessage,
    },
  } = useGlobalContext();
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [openedMenu, setOpenedMenu] = useState('');

  const hasAccess = (access) => roles?.some((item) => {
    if (item.includes(ROLES.ROLE_SECRETARY)) {
      return access?.includes(ROLES.ROLE_SECRETARY);
    }

    if (item.includes(ROLES.ROLE_MANAGEMENT_INSTITUTION)) {
      return access?.includes(ROLES.ROLE_MANAGEMENT_INSTITUTION);
    }

    return access?.includes(item);
  });

  const linkClickHandler = (selectedNav, event) => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (isLock && !confirm(confirmMessage)) {
      return event.preventDefault();
    }

    setOpenedMenu('');
    handleAction({ type: ACTION_TYPES.FORCE_RELOAD });
    setSelectedNavItem(selectedNav);

    return handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  };

  const menuStructure = useMemo(() => getMenuStructure(profile), [profile, authenticated]);
  const menuMatchedUrl = useMemo(() => groupStructureByUrl(menuStructure), [menuStructure]);

  const location = useLocation();

  const renderMenuItems = (childrenData) => {
    const menuItems = childrenData.map(({
      children, title, url, id, access,
    }) => {
      if (children) {
        return (
          <SubAccordionItem id={id} key={id} title={title} linkClickHandler={linkClickHandler}>
            <AccordionDetails sx={{ p: 0 }}>
              <ul
                style={{
                  width: '100%',
                  listStyle: 'none',
                  paddingLeft: (t) => t.spacing(1),
                }}
                role="menu"
              >
                {/* eslint-disable-next-line react/prop-types */}
                {children.map(({
                  url: submenuItemUrl, title: submenuItemTitle, id: submenuItemId,
                }) => (
                  <li style={classes.dropdownItem} key={submenuItemId}>
                    <Link
                      to={submenuItemUrl}
                      style={classes.dropdownItemLink}
                      onClick={(event) => linkClickHandler(title, event)}
                      id={submenuItemId}
                      role="menuitem"
                    >
                      {submenuItemTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </SubAccordionItem>
        );
      }

      return hasAccess(access) && (
        <li style={classes.dropdownItem} key={id}>
          <Link
            to={url}
            style={classes.dropdownItemLink}
            onClick={(event) => linkClickHandler(title, event)}
            id={id}
          >
            {title}
          </Link>
        </li>
      );
    });

    const hasChildren = childrenData.map((itemObj) => Object.keys(itemObj).includes('children'));

    if (hasChildren.includes(true)) {
      return (
        <div style={classes.dropdown}>
          { menuItems }
        </div>
      );
    }

    return (
      <ul style={classes.dropdown}>
        { menuItems }
      </ul>
    );
  };

  useEffect(() => {
    const routeMatch = menuMatchedUrl[window.location.pathname];

    if (routeMatch) {
      setSelectedNavItem(routeMatch);

      return;
    }

    setSelectedNavItem('');
  }, [menuMatchedUrl, location]);

  const renderMenuForProfile = () => renderMenu(menuStructure);
  const openMenu = (menuName) => {
    if (menuName === openedMenu) {
      setOpenedMenu('');

      return;
    }

    setOpenedMenu(menuName);
  };

  const renderMenu = (menuRoot = []) => menuRoot.map(({
    children, title, url, id, access,
  }) => (
    <ListItem
      key={title}
      sx={classes.mainNavItem}
      selected={selectedNavItem === title}
    >
      {url && hasAccess(access) && (
        <ListItem
          component={Link}
          to={url}
          sx={classes.mainNavItemLink}
          onClick={(event) => linkClickHandler(title, event)}
          id={id}
        >
          {title}
        </ListItem>
      )}
      {!url && hasAccess(access) && (
        <Button
          onClick={() => openMenu(title)}
          sx={classes.mainNavItemLink}
          id={id}
          aria-expanded={openedMenu === title}
          onFocus={() => setOpenedMenu('')}
        >
          {title}
        </Button>
      )}
      {openedMenu === title && renderMenuItems(children, title)}
    </ListItem>
  ));

  return (
    <nav style={classes.mainNavBar}>
      {openedMenu && (
        <div
          style={classes.screenPlaceholder}
          onClick={() => setOpenedMenu('')}
          aria-hidden="true"
        />
      )}
      {authenticated && (
        <List sx={openedMenu ? {
          ...classes.mainNav,
          ...classes.mainNavOpened,
        } : classes.mainNav}
        >
          {renderMenuForProfile(profile)}
        </List>
      )}
    </nav>
  );
}
