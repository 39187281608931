import {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

/**
 * Expert application form context wrapper.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
const ExpertApplicationFormContext = createContext({
  /**
   * Application form data.
   *
   */
  applicationFormData: {},
  /**
   * Is application form status not inEdition and inCorrection.
   *
   */
  isApplicationFormStatusNotInEditionAndInCorrection: false,
  /**
   * Is read only mode.
   *
   */
  isReadonly: false,
  /**
   * Attachments data.
   *
   */
  attachmentsData: [],

  /**
   * Function to reload data from API.
   *
   */
  reloadData: () => {},
});

/**
 * Application context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ExpertApplicationFormContextProvider({
  children, value,
}) {
  return (
    <ExpertApplicationFormContext.Provider value={value}>
      {children}
    </ExpertApplicationFormContext.Provider>
  );
}

ExpertApplicationFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

/**
 * ExpertApplicationFormContext hook consumer.
 *
 * @returns {object}
 */
export const useExpertApplicationFormContext = () => useContext(ExpertApplicationFormContext);
