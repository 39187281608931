import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { PASSWORD_CHANGE_ERRORS } from '../_constants';

export const getMessageError = (code) => {
  if (code in PASSWORD_CHANGE_ERRORS) {
    return (
      <Box>
        {PASSWORD_CHANGE_ERRORS[code]}
        {' '}
        &quot;
        <Link
          style={{ color: (t) => t.palette.alert.error.main }}
          to="/auth/reminder-password"
        >
          Nie pamiętasz hasła?
        </Link>
        &quot;.
      </Box>
    );
  }

  return 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.';
};
