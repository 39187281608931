export const getValuesForOtherGeographicalFields = (town) => ({
  country: {
    value: town?.commune?.district?.voivodeship?.country?.id,
    label: town?.commune?.district?.voivodeship?.country?.name,
  },
  commune: {
    value: town?.commune?.id,
    label: town?.commune?.name,
  },
  district: {
    value: town?.commune?.district?.id,
    label: town?.commune?.district?.name,
  },
  voivodeship: {
    value: town?.commune?.district?.voivodeship?.id,
    label: town?.commune?.district?.voivodeship?.name,
  },
  subregion: {
    value: town?.commune?.district?.subregion,
    label: town?.commune?.district?.subregion,
  },
});
export const townParser = (town) => {
  const communeName = town?.commune?.name || '';
  const districtName = town?.commune?.district?.name || '';
  const voivodeshipName = town?.commune?.district?.voivodeship?.name || '';
  const countryName = town?.commune?.district?.voivodeship?.country?.name || '';

  return {
    value: town.id,
    label: `${town.name} (woj. ${voivodeshipName}, pow. ${districtName}, gm. ${communeName}, ${countryName})`,
    ...getValuesForOtherGeographicalFields(town),
  };
};

export const setValuesForOtherGeographicalFields = (
  town,
  setValue,
  mapKeys = ['country', 'commune', 'district', 'voivodeship', 'street']
) => {
  mapKeys.forEach((key) => {
    if (key.includes('street')) {
      setValue(key, null);

      return;
    }

    if (key.includes('country')) {
      setValue(key, town?.country || null);

      return;
    }
    if (key.includes('commune')) {
      setValue(key, town?.commune || null);

      return;
    }
    if (key.includes('district')) {
      setValue(key, town?.district || null);

      return;
    }

    if (key.includes('subregion')) {
      setValue(key, town?.subregion || null);

      return;
    }
    setValue(key, town?.voivodeship || null);
  });
};
