import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { DICTIONARIES } from '../../../_constants';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * ApplicantPreparedSolution element.
 *
 * @returns {ApplicantPreparedSolution}
 */
export function ApplicantPreparedSolution() {
  const { isReadonly } = useElementContext();
  const { get } = useDictionaryLoader(
    DICTIONARIES.applicantPreparedSolutionIndustryAffiliation,
    DICTIONARIES.applicantPreparedSolutionReadinessLevels,
    DICTIONARIES.applicantPreparedSolutionSubjects,
    DICTIONARIES.applicantPreparedSolutionInnovationLevel,
  );

  return (
    <FieldSorter>
      {/* Nazwa rozwiązania (produktu/ usługi/ technologii) w języku polskim */}
      <FormField name="applicant_prepared_solution_name_in_polish">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nazwa rozwiązania (produktu/ usługi/ technologii) w języku angielskim */}
      <FormField name="applicant_prepared_solution_name_in_english">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis rozwiązania w języku polskim */}
      <FormField name="applicant_prepared_solution_description_in_polish">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis rozwiązania w języku angielskim */}
      <FormField name="applicant_prepared_solution_description_in_english">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przynależność rozwiązania do branży */}
      <FormField name="applicant_prepared_solution_industry_affiliation">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            renderSelectAllButton={false}
            options={filterOptions(get(DICTIONARIES.applicantPreparedSolutionIndustryAffiliation.name))}
            disabled={isReadonly}
            error={error}
            multiple
          />
        )}
      </FormField>
      {/* Poziom gotowości rozwiązania */}
      <FormField name="applicant_prepared_solution_readiness_level">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            renderSelectAllButton={false}
            options={filterOptions(get(DICTIONARIES.applicantPreparedSolutionReadinessLevels.name))}
            disabled={isReadonly}
            error={error}
            multiple
          />
        )}
      </FormField>
      {/* Czego dotyczy rozwiązanie */}
      <FormField name="applicant_prepared_solution_subject">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            renderSelectAllButton={false}
            options={filterOptions(get(DICTIONARIES.applicantPreparedSolutionSubjects.name))}
            disabled={isReadonly}
            error={error}
            multiple
          />
        )}
      </FormField>
      {/* Na czym polega innowacyjność rozwiązania względem na tle rozwiązań oferowanych
      przez konkurencyjne podmioty */}
      <FormField name="applicant_prepared_solution_innovative_nature">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Poziom innowacyjności */}
      <FormField name="applicant_prepared_solution_innovation_level">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.applicantPreparedSolutionInnovationLevel.name))}
            renderSelectAllButton={false}
            disabled={isReadonly}
            error={error}
            multiple
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="applicant_prepared_solution_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
