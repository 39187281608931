import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';
import MenuTabsForm from './MenuTabs.form';
import { elementHelper } from '../../_helpers';
import { useGlobalContext } from '../../Context';

/**
 * Menu tabs dialog component.
 *
 * @param {object} props - root props
 * @param {string} props.applicationTemplateId - application template ID
 * @param {Function} props.closeHandler - dialog close handler
 * @param {Array} props.elements - tab elements
 * @returns {MenuTabsDialog}
 */
export function MenuTabsDialog({
  applicationTemplateId,
  closeHandler,
  elements,
}) {
  const navigate = useNavigate();
  const { notify } = useGlobalContext();

  const [menuTabs, setMenuTabs] = useState([]);
  const [templateMenuTabOptions, setTemplateMenuTabOptions] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const elementLabel = (element) => {
    let label = element.label !== '' ? element.label : `[${element.elementDefinition.title}]`;
    if (elementHelper.hasSingleModule(element.elementDefinition['@type']) && element.modules.length > 0) {
      label += ` (${element.modules[0].name})`;
    }

    return label;
  };

  const elementsDictionary = elements.map((element) => ({
    id: element.id,
    value: element['@id'],
    label: elementLabel(element),
  }));

  useEffect(() => {
    /**
     * Loads data from API.
     */
    async function fetchMenuTabs() {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationTemplates}/${applicationTemplateId}/menu-tabs`
      );

      if (!statusSuccess) {
        navigate('/');
        setTimeout(() => {
          notify(`Nie udało się pobrać zakładek menu dla wniosku o identyfikatorze "${applicationTemplateId}".`, 'error');
        }, 100);

        return;
      }

      setMenuTabs(payload);
      setLoaded(true);
    }

    /**
     * Loads data from API.
     */
    async function fetchTemplateMenuTabOptions() {
      const { payload } = await request.get(API_ROUTE.menuTabsDictionary);
      setTemplateMenuTabOptions(payload.sort((a, b) => a.name.localeCompare(b.name)));
    }

    fetchTemplateMenuTabOptions();
    fetchMenuTabs();
  }, []);

  return (
    <>
      {!isLoaded ? <ContainerLoader /> : (
        <MenuTabsForm
          menuTabs={menuTabs}
          elementsDictionary={elementsDictionary}
          templateMenuTabOptions={templateMenuTabOptions}
          closeHandler={closeHandler}
          applicationTemplateId={applicationTemplateId}
        />
      )}
    </>
  );
}

MenuTabsDialog.propTypes = {
  applicationTemplateId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};
