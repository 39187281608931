const recruitmentsApi = process.env.REACT_APP_RECRUITMENTS_API_URL;

/**
 * Adds scheme and domain to endpoint.
 *
 * Ex. `/api/modules` -> `https://API/api/modules`
 *
 * @param {string} endpoint - api endpoint
 * @returns {string} - recruitment URL
 */
export const makeRecruitmentUrl = (endpoint) => `${recruitmentsApi}${endpoint}`;

/**
 * Gets ID from IRI.
 *
 * @param {string} iri - iri
 * @returns {string}
 */
export const iriToId = (iri) => (iri && typeof iri === 'string' ? iri.split('/').pop() : '');
