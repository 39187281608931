import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import {
  isBefore,
  isValid,
  parseISO,
} from 'date-fns';
import { useElementContext } from '../../../../Context';
import { API_ROUTE } from '../../../../_constants';
import { FormField } from '../../../Application/Application/FormField';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';
import { SaveCancelButtons } from '../../../SaveCancelButtons';
import { request } from '../../../../_services';
import { useCustomSnackbar } from '../../../../_hooks';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import CharsCounter from '../../../CharsCounter';

/**
 * Material and financial schedule dialog
 *
 * @param {object} props - root props
 * @param {boolean} props.isOpen - dialog is open
 * @param {Function} props.closeModal - dialog close handler
 * @param {Function} props.reloadData - reload data from API
 * @param {string} props.itemIndex - task index
 * @param {boolean} props.isIntermediateTask - isIntermediateTask
 * @returns {MaterialAndFinancialScheduleDialog}
 */
function MaterialAndFinancialScheduleDialog({
  isOpen, closeModal, reloadData, itemIndex, isIntermediateTask,
}) {
  const [isSubmitting, setSubmitting] = useState(false);
  const { id: applicationId } = useParams();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const {
    id: elementId, isReadonly,
  } = useElementContext();
  const {
    watch, setValue, control, getValues, handleSubmit, trigger,
  } = useFormContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    {
      name: 'taskTypeForElement',
      path: `${API_ROUTE.taskType}?templateElement.id=${elementId}`,
    },
    {
      name: 'predefinedTaskForElement',
      path: `${API_ROUTE.predefinedTask}?templateElement.id=${elementId}`,
    }
  );

  const {
    fields, remove,
  } = useFieldArray({
    control,
    name: `tasks-${elementId}`,
  });

  const index = itemIndex || fields.length - 1;

  const intermediateTask = watch(`tasks-${elementId}.${index}.indirect_costs`);
  const currentState = fields[index];

  const taskId = watch(`tasks-${elementId}.${index}`).task_id || '';

  /**
   * Filter field available options.
   *
   * @param {string|number} optionId - option id to filter by
   * @returns {boolean}
   */
  const filterOptions = (optionId) => !fields
    .some((task) => task.predefined_task === optionId && task.task_id !== taskId);

  const onSubmit = async () => {
    setSubmitting(true);
    const payload = watch(`tasks-${elementId}.${index}`);
    delete payload.id;

    const {
      statusSuccess, payload: response,
    } = taskId ? await request
      .put(`${API_ROUTE.applicationTasks
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/${taskId}`, {
        ...payload,
        milestone_label: '',
      })
      : await request
        .post(API_ROUTE.applicationTasks
          .replace(':applicationId', applicationId)
          .replace(':elementId', elementId), {
          ...payload,
          milestone_label: '',
        });

    statusSuccess
      ? successNotification(`Zadanie zostało ${taskId ? 'edytowane' : 'dodane'}.`)
      : errorNotification(`Nie udało się ${taskId ? 'edytować' : 'dodać'} zadania. Spróbuj ponownie.`);

    if (!statusSuccess) {
      closeModalAndRemoveItem();

      return;
    }

    if (!taskId) {
      setValue(`tasks-${elementId}.${index}`, response);
    }

    reloadData(elementId);
    closeModal();
  };

  const closeModalAndRemoveItem = () => {
    closeModal();

    if (!itemIndex) {
      remove(index);

      return;
    }

    setValue(`tasks-${elementId}.${index}`, currentState);
  };

  const lumpSumExpenses = getValues(`lump_sum_expenses-${elementId}`);
  const actualExpenses = getValues(`actual_expenses-${elementId}`);

  const taskHasExpenses = taskId
    ? !(lumpSumExpenses?.filter((expense) => expense.task_id === taskId)?.length === 0
      && actualExpenses?.filter((expense) => expense.task_id === taskId)?.length === 0)
    : false;

  const indirectCostLabel = 'Koszty pośrednie';
  const getFilteredTaskByIndirectCosts = () => get('taskTypeForElement')
    .filter(({ name }) => (intermediateTask ? name === indirectCostLabel : name !== indirectCostLabel));

  useEffect(() => {
    filterOptions();
  }, [watch(`tasks-${elementId}`)]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModalAndRemoveItem}
      maxWidth="lg"
      fullWidth
      variant="outlined"
      color="secondary"
    >
      <DialogTitle component="div">
        <Typography variant="dialogHeading">
          {`${taskId ? 'Edytuj' : 'Dodaj'} zadanie`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!isLoaded ? <ContainerLoader /> : (
          <Grid
            container
            spacing={2}
          >
            <FormField name={`tasks-${elementId}.${index}.name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={intermediateTask || isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {!isIntermediateTask && (
              <FormField name={`tasks-${elementId}.${index}.indirect_costs`}>
                {({
                  name, value, onChange,
                }) => (
                  <Checkbox
                    id={name}
                    name={name}
                    color="primary"
                    onChange={(event) => {
                      onChange(event);
                      setValue(`tasks-${elementId}.${index}.name`, event.target.checked ? 'Koszty pośrednie' : '');
                    }}
                    checked={value === true}
                    disabled={isReadonly || taskHasExpenses}
                  />
                )}
              </FormField>
            )}
            <FormField name={`tasks-${elementId}.${index}.predefined_task`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={get('predefinedTaskForElement').filter(({ '@id': id }) => filterOptions(id))}
                  disabled={isReadonly || taskHasExpenses}
                  error={error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.data_rozpoczecia_zadania`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={(event) => {
                    onChange(event);
                    trigger(`tasks-${elementId}.${index}.data_zakonczenia_zadania`);
                  }}
                  onBlur={(event) => {
                    onBlur(event);
                    if (value) {
                      const date = new Date(value);
                      date.setHours(13);

                      setValue(name, date);
                    }
                  }}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField
              name={`tasks-${elementId}.${index}.data_zakonczenia_zadania`}
              rules={{
                validate: {
                  isBeforeDate: (value) => isBefore(
                    isValid(watch(`tasks-${elementId}.${index}.data_rozpoczecia_zadania`))
                      ? watch(`tasks-${elementId}.${index}.data_rozpoczecia_zadania`)
                      : parseISO(watch(`tasks-${elementId}.${index}.data_rozpoczecia_zadania`)),
                    isValid(value) ? value : parseISO(value)
                  ) || !isValid(value)
                    || 'Wartość wprowadzona w polu "Data zakończenia zadania" nie może być wczesniejsza, '
                    + 'niż wartość wprowadzona w polu "Data rozpoczęcia zadania"',
                },
              }}
            >
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  onBlur={(event) => {
                    onBlur(event);
                    if (value) {
                      const date = new Date(value);
                      date.setHours(19);

                      setValue(name, date);
                    }
                  }}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.task_type`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getFilteredTaskByIndirectCosts()}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.opis_i_uzasadnienie`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  minRows={4}
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.planned_works_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  minRows={4}
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 3000} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 3000 }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.milestone_label`} contextHelpLabel>
              {() => null}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.milestone_name`}>
              {({
                name, onChange, value, maxLength, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 200} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 200 }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.milestone_params`}>
              {({
                name, onChange, value, maxLength, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 500} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 500 }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.milestone_achievement_verification`}>
              {({
                name, onChange, value, maxLength, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 500} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 500 }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`tasks-${elementId}.${index}.milestone_fail_impact`}>
              {({
                name, onChange, value, maxLength, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 500} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 500 }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <Grid item xs={12} display="flex" justifyContent="center">
              <SaveCancelButtons
                cancelHandler={closeModalAndRemoveItem}
                cancelButtonId="JDhBiqNB99v6nnc"
                saveHandler={handleSubmit(onSubmit)}
                saveButtonId="8Ooe944x8XK2Sox"
                saveDisabled={isSubmitting || isReadonly}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default MaterialAndFinancialScheduleDialog;

MaterialAndFinancialScheduleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  itemIndex: PropTypes.string,
  isIntermediateTask: PropTypes.bool.isRequired,
};

MaterialAndFinancialScheduleDialog.defaultProps = {
  itemIndex: '',
};
