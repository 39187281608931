import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';

/**
 * Market transformation element.
 *
 * @returns {MarketTransformation}
 */
export function MarketTransformation() {
  const { isReadonly } = useElementContext();
  const {
    watch, reset,
  } = useFormContext();
  const { application } = useApplicationContext();
  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <FieldSorter>
      {/* 1. Czy występująca w projekcie innowacja ma potencjał do transformacji
      istniejącego rynku docelowego produktów (wyrobów lub usług) */}
      <FormField name="market_transformation_is_potential_to_transform_the_existing_target_market">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  market_transformation_potential_to_transform_the_existing_target_market_justification_module:
                  FIELD_TYPES_MAP.choice,
                  market_transformation_potential_to_transform_the_existing_target_market_justification:
                  FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('market_transformation_is_potential_to_transform_the_existing_target_market') === BOOLEAN_VALUES.TRUE)
        && (
          <>
            {/* 2. Moduł, w którym występuje innowacja */}
            <FormField
              name="market_transformation_potential_to_transform_the_existing_target_market_justification_module"
            >
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  multiple
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={modules}
                  disabled={isReadonly}
                  renderSelectAllButton={false}
                  error={error}
                />
              )}
            </FormField>

            {/* 3. Uzasadnienie */}
            <FormField
              name="market_transformation_potential_to_transform_the_existing_target_market_justification"
              sx={{ border: '2px solid blue' }}
            >
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </>
        )}
      {/* 4. Czy występująca w projekcie innowacja ma potencjał do wykreowania nowego rynku */}
      <FormField name="market_transformation_is_potential_to_create_a_new_market">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  market_transformation_potential_to_create_a_new_market_module: FIELD_TYPES_MAP.choice,
                  market_transformation_potential_to_create_a_new_market_justification: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('market_transformation_is_potential_to_create_a_new_market') === BOOLEAN_VALUES.TRUE) && (
        <>
          {/* 5. Moduł, w którym występuje innowacja */}
          <FormField name="market_transformation_potential_to_create_a_new_market_module">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                multiple
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={modules}
                disabled={isReadonly}
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
          {/* 6. Nazwa pola: Uzasadnienie */}
          <FormField name="market_transformation_potential_to_create_a_new_market_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
    </FieldSorter>
  );
}
