/**
 * Get list of years for indicators on basis of the provided project start or end year and range
 *
 * @param {string} projectDate - project start or end date
 * @param {Array} range - date range from configurator
 * @returns {Array}
 */
const getListOfYearsForIndicators = (projectDate, range) => {
  if (projectDate && range) {
    const projectYear = new Date(projectDate).getFullYear();

    return range.map((item) => ({
      value: Number(item),
      label: Number(projectYear) + Number(item),
    }));
  }

  return [];
};

export const IndicatorsHelper = {
  getListOfYearsForIndicators,
};
