import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * ApplicantExperienceInImplementationOfGrantProjects element renderer.
 *
 * @returns {ApplicantExperienceInImplementationOfGrantProjects}
 */
export default function ApplicantExperienceInImplementationOfGrantProjects() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();

  const collectionFieldName = 'applicant_experience_in_implementation_of_grant_projects';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });
  const applicantExperienceConfig = fieldsConfig[collectionFieldName];
  const subfields = applicantExperienceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = applicantExperienceConfig || {};
  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <LabeledFieldRow fieldConfig={applicantExperienceConfig} contextHelpLabel fullWidth>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          key={field.id}
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            collectionFieldName,
            index,
          )}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          buttonRemoveVisible={fields.length > minRowNumber}
          isIndexed
        >
          {/* Nazwa podmiotu odpowiedzialnego za realizację projektu wraz z adresem, NIP  */}
          <FormField name={`${collectionFieldName}.${index}.name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Tytuł projektu  */}
          <FormField name={`${collectionFieldName}.${index}.title`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Budżet projektu (PLN) i źródło finansowania   */}
          <FormField name={`${collectionFieldName}.${index}.budget`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Okres realizacji projektu od */}
          <FormField name={`${collectionFieldName}.${index}.implementation_start_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Okres realizacji projektu do */}
          <FormField name={`${collectionFieldName}.${index}.implementation_end_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Przedmiot i cel projektu oraz przebieg jego realizacji  */}
          <FormField name={`${collectionFieldName}.${index}.subject_and_purpose`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Odbiorcy ostateczni projektu   */}
          <FormField name={`${collectionFieldName}.${index}.final_recipients`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Zakres wsparcia finansowego lub niefinansowego w formie usług dla odbiorców ostatecznych   */}
          <FormField name={`${collectionFieldName}.${index}.scope_of_financial_or_non_financial_support`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Podmioty zaangażowane w realizację projektu ze wskazaniem ich zadań i roli w realizacji projektu  */}
          <FormField name={`${collectionFieldName}.${index}.entities_involved_in_the_implementation_of_the_project`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Zadania i rola wnioskodawcy w projekcie */}
          <FormField name={`${collectionFieldName}.${index}.tasks_and_role_of_the_applicant`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Efekty realizacji projektu */}
          <FormField name={`${collectionFieldName}.${index}.implementation_effects`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="9ybOSog33XZrYEc"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
