import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { ClusterMembersNumber } from './PkwEligibility/ClusterMembersNumber';
import { MonetaryField } from '../../MonetaryField';

/**
 * PkwEligibility element renderer.
 *
 * @returns {PkwEligibility}
 */
export default function PkwEligibility() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    watch, reset,
  } = useFormContext();
  const membersConfig = fieldsConfig.pkw_eligibility_number_cluster_members_by_voivodeships;
  const pkwEligibilityFieldsConfig = Object.values(fieldsConfig).filter(({ name }) => name.includes('pkw_eligibility')
    && name !== 'pkw_eligibility_does_it_apply');

  const defaulFieldsConfig = {};
  pkwEligibilityFieldsConfig.forEach(({
    name, type,
  }) => {
    defaulFieldsConfig[name] = FIELD_TYPES_MAP[type];
  });

  return (
    <FieldSorter>
      <FormField name="pkw_eligibility_does_it_apply">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  ...defaulFieldsConfig,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {watch('pkw_eligibility_does_it_apply') === BOOLEAN_VALUES.TRUE && (
        <>
          {/* 1.Koordynator posiada osobowość prawną */}
          <FormField name="pkw_eligibility_coordinator_has_legal_personality">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 2.Uzasadnienie */}
          <FormField name="pkw_eligibility_coordinator_has_legal_personality_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 3.Data rozpoczęcia działalności klastra */}
          <FormField name="pkw_eligibility_cluster_start_date">
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                onChange={onChange}
                value={value}
                name={name}
                disabled={isReadonly}
                ariaLabel={label}
                onBlur={onBlur}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 4.Uzasadnienie */}
          <FormField name="pkw_eligibility_cluster_start_date_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 5.Klaster posiada aktualny dokument strategii/planu rozwoju */}
          <FormField name="pkw_eligibility_cluster_has_current_strategy_document">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 6.Uzasadnienie */}
          <FormField name="pkw_eligibility_cluster_has_current_strategy_document_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 7.Ponadregionalny charakter działalności klastra (%) */}
          <FormField name="pkw_eligibility_cluster_activities_supra_regional_nature">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 8.Tabela: „Liczba członków klastra w podziale na województwa” */}
          <LabeledFieldRow fieldConfig={membersConfig} contextHelpLabel fullWidth>
            <ClusterMembersNumber />
          </LabeledFieldRow>
          {/* 9.Struktura klastra: min. 35 członków, z czego przynajmniej 2/3 liczby członków
          powinny stanowić przedsiębiorstwa – dane obliczone zgodnie z regułą dotyczącą powiązań i partnerstw */}
          <FormField name="pkw_eligibility_cluster_structure">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 10.Uzasadnienie */}
          <FormField name="pkw_eligibility_cluster_structure_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 11. Udział członków będących w klastrze powyżej 1 roku (%) */}
          <FormField name="pkw_eligibility_share_members_who_have_been_in_cluster_for_more_than_1_year">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 12.Uzasadnienie */}
          <FormField name="pkw_eligibility_share_members_who_have_been_in_cluster_for_more_than_1_year_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 13.Środki pochodzące ze składek członkowskich (udział w przychodach koordynatora klastra)
          przeznaczone na działalność klastra (%) */}
          <FormField name="pkw_eligibility_funds_from_membership_fees">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 14.Uzasadnienie */}
          <FormField name="pkw_eligibility_funds_from_membership_fees_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 15.Etykieta: Zatrudnienie w przedsiębiorstwach klastra */}
          <FormField name="pkw_eligibility_employment_in_enterprises" contextHelpLabel>
            {() => null}
          </FormField>
          {/* 16.Stan na koniec ostatniego zakończonego roku obrotowego zatrudnionych zgodnie z przepisami
           kodeksu pracy (w przeliczeniu na pełne etaty), na podstawie umów o charakterze cywilnoprawnym,
           kontraktów menadżerskich, czy na zasadach B2B */}
          <FormField name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code_and_others">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 17.Stan na koniec ostatniego zakończonego roku obrotowego zatrudnionych zgodnie z przepisami
           kodeksu pracy (w przeliczeniu na pełne etaty) */}
          <FormField name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 18.Uzasadnienie */}
          <FormField name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 19.Koordynator klastra ma na stronie ofertę usługową dla członków klastra */}
          <FormField name="pkw_eligibility_cluster_coordinator_has_service_offering_on_website">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 20.Uzasadnienie */}
          <FormField name="pkw_eligibility_cluster_coordinator_has_service_offering_on_website_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 21.Koordynator klastra udostępnia członkom infrastrukturę (badawczą, demonstracyjną
           lub informatyczną) */}
          <FormField name="pkw_eligibility_cluster_coordinator_provides_members_with_infrastructure">
            {({
              onChange, value, name, allChoices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={allChoices}
                error={error}
                disabled={isReadonly}
              />
            )}
          </FormField>
          {/* 22.Uzasadnienie */}
          <FormField name="pkw_eligibility_cluster_coordinator_provides_members_with_infrastructure_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 23.Liczba wspólnych projektów (z ustalonym harmonogramem działań i budżetem) realizowanych w ostatnim
          roku przed złożeniem wniosku, w których brał udział koordynator klastra z min. 2 członkami klastra lub min.3
          członkami klastra, z czego co najmniej 1 był przedsiębiorcą */}
          <FormField name="pkw_eligibility_joint_projects_number">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <NumericFormat
                id={name}
                name={name}
                onValueChange={(values) => onChange({
                  target: {
                    name,
                    value: values.floatValue,
                  },
                })}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                customInput={TextField}
                allowNegative={false}
                decimalScale={0}
              />
            )}
          </FormField>
          {/* 24.Uzasadnienie */}
          <FormField name="pkw_eligibility_joint_projects_number_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 25.Przedsiębiorstwa wchodzące w skład klastra, które wprowadziły innowację produktową lub procesową
          powiązaną z procesem produkcji, w ostatnim zakończonym roku obrotowym (%) */}
          <FormField name="pkw_eligibility_enterprises_in_cluster_that_have_introduced_product_innovation">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 26.Uzasadnienie */}
          <FormField
            name="pkw_eligibility_enterprises_in_cluster_that_have_introduced_product_innovation_justification"
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 27.Przedsiębiorstwa wchodzące w skład klastra prowadzące działalność B+R w ostatnim zakończonym roku
          obrotowym (%) */}
          <FormField name="pkw_eligibility_enterprises_in_cluster_engaged_in_rd_activities">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 28.Uzasadnienie */}
          <FormField name="pkw_eligibility_enterprises_in_cluster_engaged_in_rd_activities_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 29. Wspólny udział koordynatora klastra razem z członkami w wydarzeniach branżowych/ targach (również
            międzynarodowych) w ostatnim roku przed złożeniem wniosku (liczba wydarzeń – szt.) */}
          <FormField name="pkw_eligibility_joint_participation_in_industry_events">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 30.Uzasadnienie */}
          <FormField name="pkw_eligibility_joint_participation_in_industry_events_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 31.Przedsiębiorstwa wchodzące w skład klastra prowadzące działalność eksportową w ostatnim zakończonym
          roku obrotowym (%) */}
          <FormField name="pkw_eligibility_enterprises_in_cluster_engaged_in_export_activities">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{ inputComponent: MonetaryField }}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* 32.Uzasadnienie */}
          <FormField name="pkw_eligibility_enterprises_in_cluster_engaged_in_export_activities_justification">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
    </FieldSorter>
  );
}
