/**
 * Panel Types values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const panelTypesValues = [
  {
    id: 'module',
    label: 'Modułowy',
  },
  {
    id: 'with_expert',
    label: 'Z ekspertem wiodącym',
  },
  {
    id: 'without_expert',
    label: 'Bez eksperta wiodącego',
  },
  {
    id: 'module_first_stage',
    label: 'Modułowy 1 etap',
  },
];
