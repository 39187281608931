export const EXPENSES_TYPE = {
  actualExpenses: 'actualExpenses',
  flatRateExpenses: 'flatRateExpenses',
};

export const EXPENSES_TYPE_LIST = [
  {
    name: EXPENSES_TYPE.actualExpenses,
    label: 'Wydatki rzeczywiste',
  },
  {
    name: EXPENSES_TYPE.flatRateExpenses,
    label: 'Koszty uproszczone',
  },
];

export const FLAT_RATE_EXPENSES_TYPE = [
  {
    name: 'unitRate',
    label: 'Stawka jednostkowa',
  },
  {
    name: 'flatRate',
    label: 'Stawka ryczałtowa',
  },
  {
    name: 'lumpSum',
    label: 'Kwota ryczałtowa',
  },
];
