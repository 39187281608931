import {
  IconButton,
  Toolbar as MUIToolbar,
  Typography,
  Badge,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import {
  ACTION_TYPES,
  URL,
} from '../../_constants';
import { getNewSystemMessages } from '../../_helpers';
import { ReactComponent as SettingsIcon } from '../../images/settings-light.svg';
import { ReactComponent as TurnOffIcon } from '../../images/turn-off-light.svg';
import { DevelopmentToolsMenu } from '../Development/DevelopmentToolsMenu';
import { useAuth } from '../../_security';
import {
  useGlobalContext,
  useGlobalDialog,
  useReloadListener,
} from '../../Context';
import { NewSystemMessagesDialog } from '../SystemMessage/NewSystemMessages.dialog';
import LogoutConfirmationForm from '../Logout/LogoutConfirmation.form';
import { theme } from '../../styles/theme';

const classes = {
  userToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '2.5vw',
  },
  toolbarIcon: {
    width: theme.spacing(3),
    fill: theme.palette.brandGray.main,
    cursor: 'pointer',
  },
  turnOff: {
    fill: theme.palette.secondary.main,
  },
  emailIcon: {
    fill: theme.palette.brandGray.main,
  },
  emailIconError: {
    fill: theme.palette.primary.main,
  },
};

/**
 * User's toolbar
 *
 * @returns {Toolbar}
 */
export function Toolbar() {
  const {
    authenticated, username, id: loggedUserId,
  } = useAuth();
  const navigate = useNavigate();
  const {
    handleAction, root: {
      isLock, confirmMessage, isShowNewMessageDialog,
    },
  } = useGlobalContext();
  const [messages, setMessages] = useState([]);
  const {
    reload, watch,
  } = useReloadListener();
  const watcherName = 'system-messages';
  const { render } = useGlobalDialog();

  const linkClickHandler = (event) => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (isLock && !confirm(confirmMessage)) {
      return event.preventDefault();
    }

    navigate(URL.users.account.settings);

    return handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  };

  useEffect(() => {
    if (authenticated) {
      getNewSystemMessages(loggedUserId, (data) => {
        setMessages(data);
        if (data.length > 0 && isShowNewMessageDialog) {
          render(
            <NewSystemMessagesDialog messages={data} />,
            'Komunikaty',
            { maxWidth: 'lg' }
          );
        }

        handleAction({ type: ACTION_TYPES.NOT_SHOW_NEW_MESSAGE_DIALOG });
      }, true);
    }
  }, [authenticated, watch(watcherName), isShowNewMessageDialog]);

  useEffect(() => {
    const timer = setInterval(() => reload(watcherName), 60000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ minHeight: theme.spacing(4) }}>
      {authenticated && (
        <MUIToolbar style={classes.userToolbar}>
          <Typography variant="body1" mr={4} color={theme.palette.brandGray.main}>
            {username}
          </Typography>
          <IconButton
            id="RqIXhK2pZMHf586"
            size="small"
            onClick={() => navigate(URL.systemMessagesByProfile)}
            sx={{ mr: (t) => t.spacing(1) }}
          >
            <Badge badgeContent={messages.length} color="primary">
              <MailOutlineIcon style={messages.length === 0 ? classes.emailIcon : classes.emailIconError} />
            </Badge>
          </IconButton>
          <IconButton
            id="gLJT4s6IGC2JPEq"
            onClick={linkClickHandler}
            size="large"
          >
            <SettingsIcon id="4pRzKxu6k7JZCui" style={classes.toolbarIcon} />
          </IconButton>
          <IconButton
            id="gAsFRARywA5AHFK"
            onClick={() => render(
              <LogoutConfirmationForm />,
              'Potwierdź wylogowanie',
              { maxWidth: 'xs' }
            )}
            size="large"
          >
            <TurnOffIcon
              id="c7k0rc6OkVKN29V"
              style={{
                ...classes.toolbarIcon,
                ...classes.turnOff,
              }}
            />
          </IconButton>
          <DevelopmentToolsMenu />
        </MUIToolbar>
      )}
    </div>
  );
}
