/**
 * Handle newlines (for example in PDF printouts)
 *
 * @param {string | undefined} textToHandle - the text we want to process
 * @returns {string}
 */
export const handleNewlines = (textToHandle) => {
  if (typeof textToHandle === 'string') {
    const resultText = textToHandle ? textToHandle.replace(/\\n/g, '\n').normalize() : null;

    return resultText;
  }

  return textToHandle; // text is an object so return it raw
};
