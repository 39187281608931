import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  useApplicationToPdfContext,
  useElementContext,
} from '../../../../Context';
import {
  formatDateString,
  getFieldConfig,
  getChangesInField,
  iriToId,
} from '../../../../_helpers';
import PrintoutTableRow from '../Table/PrintoutTableRow';
import PrintoutTableCell from '../Table/PrintoutTableCell';
import {
  BOOLEAN_VALUES,
  DATE,
  FIELD_TYPES,
} from '../../../../_constants';

/**
 * RowField config
 *
 * @param {object} props - root props
 * @param {string} props.name - field name
 * @param {boolean} props.isSingleRow - is single row
 * @param {any} props.value - external field value
 * @param {string} props.dictionaryName - dictionary name
 * @param {Array} props.externalDictionary - external dictionary list
 * @param {boolean} props.skipFilterDictionary - skip filtering dictionaries
 * @param {string} props.label - custom label
 * @param {string} props.formatDate - format date
 * @param {string} props.oldValue - old field value
 * @returns {RowField}
 */
function RowField({
  name, isSingleRow, value, dictionaryName, externalDictionary, skipFilterDictionary, label, formatDate, oldValue,
}) {
  const {
    managedApplicationTemplate: {
      fieldsConfig,
      initialFormData,
    },
    previousInitialFormData,
    getDictionary,
    withChanges,
  } = useApplicationToPdfContext();

  const { id: templateElementId } = useElementContext();

  /**
   * Field config based on field name.
   * Note that field can be nested. Dot separated notation is used to retrieve nested field.
   * Field also can be indexed (for collection fields) like 'field.0.name` is
   * equivalent of `field.name`.
   */
  const fieldConfig = useMemo(() => getFieldConfig(fieldsConfig, name), [name]);

  if (!fieldConfig) {
    return '';
  }

  const fieldValue = value || initialFormData[name] || fieldConfig?.defaultValue;
  const oldFieldValue = oldValue || previousInitialFormData[name] || fieldConfig?.defaultValue;

  const filterOptions = (allChoices) => {
    if (
      fieldConfig?.type === FIELD_TYPES.choice
      && !fieldConfig?.apiEndpoint
      && !allChoices
      && !skipFilterDictionary
    ) {
      return fieldConfig.choices;
    }

    if (fieldConfig?.type === FIELD_TYPES.limitedChoice && !skipFilterDictionary) {
      return allChoices.filter((choice) => (fieldConfig.choices.length === 0
        ? true
        : fieldConfig.choices.includes(choice.id || (choice['@id'] && iriToId(choice['@id'])))));
    }

    return allChoices || [];
  };

  const getDictionaryList = (dictionaryList) => {
    if (dictionaryName) {
      return filterOptions(getDictionary(`${dictionaryName}-${templateElementId}`));
    }

    if (dictionaryList.length !== 0) {
      return filterOptions(externalDictionary);
    }

    return filterOptions(fieldConfig?.allChoices).map((choice) => ({
      '@id': choice.id,
      name: choice.label,
    }));
  };

  const multipleDictionaryToString = (dictionaries, dictionaryList) => dictionaries?.map((item) => dictionaryList
    ?.find(({ '@id': dictionaryId }) => dictionaryId === item)?.name)
    ?.join(', ') || '';

  const findSingleDictionaryName = (dictionaryList, dictionaryIri) => dictionaryList
    .find(({ '@id': dictionaryId }) => dictionaryId === dictionaryIri)
    ?.name || dictionaryIri;

  const getFieldValue = () => {
    if (
      (fieldConfig.type === FIELD_TYPES.choice
        || fieldConfig.type === FIELD_TYPES.limitedChoice
        || fieldConfig.type === FIELD_TYPES.attachments
        || externalDictionary.length !== 0
      )
      && (dictionaryName || externalDictionary.length !== 0)
    ) {
      const dictionaryList = getDictionaryList(externalDictionary);

      if (Array.isArray(fieldValue)) {
        const fieldValuesToString = multipleDictionaryToString(fieldValue || [], dictionaryList);
        const oldFieldValuesToString = multipleDictionaryToString(Array.isArray(oldFieldValue)
          ? oldFieldValue : [], dictionaryList);

        return withChanges ? getChangesInField(oldFieldValuesToString, fieldValuesToString) : fieldValuesToString;
      }

      const singleDictionaryValue = findSingleDictionaryName(dictionaryList, fieldValue);
      const oldSingleDictionaryValue = findSingleDictionaryName(dictionaryList, oldFieldValue);

      return withChanges ? getChangesInField(oldSingleDictionaryValue, singleDictionaryValue) : singleDictionaryValue;
    }

    if (fieldConfig.type === FIELD_TYPES.date && fieldValue) {
      const dateFieldValue = formatDateString(fieldValue, formatDate);
      const oldDateFieldValue = formatDateString(oldFieldValue, formatDate);

      return withChanges ? getChangesInField(oldDateFieldValue, dateFieldValue) : dateFieldValue;
    }

    if (typeof fieldValue === 'boolean') {
      const boolFieldValue = fieldValue ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;
      const boolOldFieldValue = oldFieldValue ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;

      return withChanges ? getChangesInField(boolOldFieldValue, boolFieldValue) : boolFieldValue || '';
    }

    return withChanges ? getChangesInField(oldFieldValue, fieldValue) : fieldValue || '';
  };

  if (isSingleRow) {
    return (
      <>
        <PrintoutTableRow>
          <PrintoutTableCell bgColor="gray" rowSpan={1}>
            {label || fieldConfig?.label || fieldConfig?.title}
          </PrintoutTableCell>
        </PrintoutTableRow>
        {getFieldValue() && (
          <PrintoutTableRow>
            <PrintoutTableCell rowSpan={1}>
              {getFieldValue()}
            </PrintoutTableCell>
          </PrintoutTableRow>
        )}
      </>
    );
  }

  return (
    <PrintoutTableRow>
      <PrintoutTableCell bgColor="gray">
        {label || fieldConfig?.label || fieldConfig?.title}
      </PrintoutTableCell>
      <PrintoutTableCell>
        {getFieldValue()}
      </PrintoutTableCell>
    </PrintoutTableRow>
  );
}

export default RowField;

RowField.propTypes = {
  name: PropTypes.string.isRequired,
  isSingleRow: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  dictionaryName: PropTypes.string,
  externalDictionary: PropTypes.arrayOf(Object),
  skipFilterDictionary: PropTypes.bool,
  label: PropTypes.string,
  formatDate: PropTypes.string,
  oldValue: PropTypes.string,
};

RowField.defaultProps = {
  isSingleRow: false,
  value: null,
  dictionaryName: '',
  externalDictionary: [],
  skipFilterDictionary: false,
  label: '',
  formatDate: DATE.defaultFormatWithoutTime,
  oldValue: '',
};
