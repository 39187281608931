import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * TeamImplementingProgramConcept Pdf element.
 *
 * @returns {TeamImplementingProgramConceptPdf}
 */
function TeamImplementingProgramConceptPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Struktura organizacyjna zespołu realizującego koncepcję programu */}
        <RowField name="team_implementing_program_concept_organizational_structure_team" isSingleRow />
        {/* Tabela "Doświadczenie i kompetencje kluczowych członków zespołu realizującego koncepcję
       programu, w tym ekspertów oceniających pomysły biznesowe" */}
        <CollectionWrapper name="team_implementing_program_concept_experience">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            const isBusinessVenturesDevelopment = item.innovative_business_ventures_development === BOOLEAN_VALUES.TRUE;
            const isCreatingNewTechnology = item.creating_new_technology === BOOLEAN_VALUES.TRUE;
            const isInvestmentProcessesRelations = item.investment_processes_relations === BOOLEAN_VALUES.TRUE;

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.x}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    {/* Imię i nazwisko */}
                    <RowField
                      name={`${collectionName}.${index}.first_and_last_name`}
                      value={item.first_and_last_name}
                      oldValue={previousRowData?.first_and_last_name}
                    />
                    {/* Wykształcenie */}
                    <RowField
                      name={`${collectionName}.${index}.education`}
                      value={item.education}
                      oldValue={previousRowData?.education}
                    />
                    {/* Nazwa stanowiska/ funkcji */}
                    <RowField
                      name={`${collectionName}.${index}.job_title`}
                      value={item.job_title}
                      oldValue={previousRowData?.job_title}
                    />

                    {/* Opis doświadczenia związanego z pełnioną funkcją w projekcie */}
                    <RowField
                      name={`${collectionName}.${index}.experience_description`}
                      value={item.experience_description}
                      oldValue={previousRowData?.experience_description}
                      isSingleRow
                    />
                    {/* Etykieta:Doświadczenie w zakresie */}
                    <RowField name={`${collectionName}.${index}.field_experience`} isSingleRow />
                    {/* Rozwoju innowacyjnych przedsięwzięć biznesowych */}
                    <RowField
                      name={`${collectionName}.${index}.innovative_business_ventures_development`}
                      value={item.innovative_business_ventures_development}
                      oldValue={previousRowData?.innovative_business_ventures_development}
                    />
                    {/* Przykłady przedsięwzięć/ działań potwierdzających wskazane doświadczenie  */}
                    {isBusinessVenturesDevelopment && (
                      <RowField
                        name={`${collectionName}.${index}.innovative_business_ventures_development_activities_examples`}
                        value={item.innovative_business_ventures_development_activities_examples}
                        oldValue={previousRowData?.innovative_business_ventures_development_activities_examples}
                        isSingleRow
                      />
                    )}
                    {/* Tworzeniu lub komercjalizacji nowych technologii */}
                    <RowField
                      name={`${collectionName}.${index}.creating_new_technology`}
                      value={item.creating_new_technology}
                      oldValue={previousRowData?.creating_new_technology}
                    />
                    {/* Przykłady przedsięwzięć/ działań potwierdzających wykazane doświadczenie */}
                    {isCreatingNewTechnology && (
                      <RowField
                        name={`${collectionName}.${index}.creating_new_technology_activities_examples`}
                        value={item.creating_new_technology_activities_examples}
                        oldValue={previousRowData?.creating_new_technology_activities_examples}
                        isSingleRow
                      />
                    )}
                    {/* Relacji procesów inwestycyjnych venture capital */}
                    <RowField
                      name={`${collectionName}.${index}.investment_processes_relations`}
                      value={item.investment_processes_relations}
                      oldValue={previousRowData?.investment_processes_relations}
                    />
                    {/* Przykłady przedsięwzięć/ działań potwierdzających wykazane doświadczenie */}
                    {isInvestmentProcessesRelations && (
                      <RowField
                        name={`${collectionName}.${index}.investment_processes_relations_activities_examples`}
                        value={item.investment_processes_relations_activities_examples}
                        oldValue={previousRowData?.investment_processes_relations_activities_examples}
                        isSingleRow
                      />
                    )}
                    {/* Zakres zadań w projekcie */}
                    <RowField
                      name={`${collectionName}.${index}.project_tasks_scope`}
                      value={item.project_tasks_scope}
                      oldValue={previousRowData?.project_tasks_scope}
                      isSingleRow
                    />
                    {/* Obszar odpowiedzialności */}
                    <RowField
                      name={`${collectionName}.${index}.responsibility_areas`}
                      value={item.responsibility_areas}
                      oldValue={previousRowData?.responsibility_areas}
                      dictionaryName={DICTIONARIES.teamImplementingProgramConceptResponsibilityAreas.name}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default TeamImplementingProgramConceptPdf;
