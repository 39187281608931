import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../../Context';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../../_helpers';
import { DICTIONARIES } from '../../../../_constants';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';

/**
 * UeHorizontalPoliciesProjectComplianceEnvironmentalProtection element renderer.
 *
 * @returns {UeHorizontalPoliciesProjectComplianceEnvironmentalProtection}
 */
export function UeHorizontalPoliciesProjectComplianceEnvironmentalProtection() {
  const {
    control, setValue,
  } = useFormContext();

  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'ue_horizontal_policies_project_compliance_environment_protection',
  });

  const environmentalProtectionConfig = fieldsConfig.ue_horizontal_policies_project_compliance_environment_protection;
  const subfields = environmentalProtectionConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = environmentalProtectionConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const { get } = useDictionaryLoader(DICTIONARIES.ueHorizontalPoliciesProjectComplianceLegalActs);

  return (
    <>
      {/* Tabela "Produkty/ usługi w projekcie" */}
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'ue_horizontal_policies_project_compliance_environment_protection',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed
        >
          {/* Akt prawny */}
          <FormField name={`ue_horizontal_policies_project_compliance_environment_protection.${index}.legal_act`}>
            {({
              onChange, value, name, choices, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={get(DICTIONARIES.ueHorizontalPoliciesProjectComplianceLegalActs.name)
                  .filter((legalAct) => choices.includes(legalAct.id))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Uzasadnienie */}
          <FormField name={`ue_horizontal_policies_project_compliance_environment_protection.${index}.justification`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="Js8TWMOLYUxK7hK"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
