import {
  Box,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useGlobalDialog } from '../../Context';

/**
 * Message dialog to prompt the user
 *
 * @param {object} props - root props
 * @param {Node} props.children - children
 * @param {string} props.okButtonLabel - ok button label
 * @param {string} props.okButtonColor - ok button color
 * @returns {MessageDialog}
 */
export function MessageDialog({
  children, okButtonLabel, okButtonColor,
}) {
  const { closeAll } = useGlobalDialog();

  return (
    <>
      {children}
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          id="VkO1ecRPphFKyeY"
          variant="outlined"
          onClick={closeAll}
          color={okButtonColor}
        >
          {okButtonLabel}
        </Button>
      </Box>
    </>
  );
}

MessageDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  okButtonLabel: PropTypes.string,
  okButtonColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']),
};

MessageDialog.defaultProps = {
  okButtonLabel: 'OK',
  okButtonColor: 'secondary',
  children: null,
};
