import { useState } from 'react';
import { LinearProgress } from '@mui/material';
import { GridFilterToolbar } from './GridFilterToolbar';
import { useQueryFilters } from './useQueryFilters';

/**
 * CustomDataGrid state and props helper.
 * Part of CustomDataGrid, ONLY FOR INTERNAL USE.
 *
 * @param {boolean} disableFilterToolbar - toolbar filter will be disabled
 * @param {Array} defaultSortModel - default sort model
 * @param {object} defaultColumnVisibilityModel - default column visibility model
 * @param {string} name - key name in which the parameters will be stored
 * @returns {object}
 */
export const useDataGrid = (disableFilterToolbar, defaultSortModel, defaultColumnVisibilityModel, name) => {
  const {
    filterModel,
    sortModel,
    paginationModel,
    setQueryFilters,
  } = useQueryFilters(name, defaultSortModel);

  /**
   * Column visibility model.
   */
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(defaultColumnVisibilityModel);

  const dataGridProps = {
    pagination: true,
    paginationModel: {
      page: paginationModel.page,
      pageSize: paginationModel.itemsPerPage,
    },
    paginationMode: 'server',
    pageSizeOptions: [5, 10, 20],
    onPaginationModelChange: (pagination) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        paginationModel: {
          page: pagination.page,
          itemsPerPage: pagination.pageSize,
        },
      }));
    },
    sortingMode: 'server',
    sortModel,
    onSortModelChange: (newModel) => setQueryFilters((prevState) => ({
      ...prevState,
      sortModel: newModel,
    })),
    columnVisibilityModel,
    onColumnVisibilityModelChange: (visibilityModel) => setColumnVisibilityModel(visibilityModel),
    editMode: 'row',
    componentsProps: {
      pagination: {
        showFirstButton: true,
        showLastButton: true,
      },
    },
  };

  if (!disableFilterToolbar) {
    dataGridProps.components = {
      Toolbar: GridFilterToolbar,
      LoadingOverlay: LinearProgress,
    };
    dataGridProps.componentsProps = {
      ...dataGridProps.componentsProps,
      toolbar: {
        setFilterModel: (values) => setQueryFilters((prevState) => ({
          ...prevState,
          filterModel: values,
        })),
        filterModel,
      },
      loadingOverlay: {
        color: 'secondary',
      },
    };
  }

  return {
    paginationModel: dataGridProps.paginationModel,
    sortModel,
    filterModel,
    dataGridProps,
    columnVisibilityModel,
  };
};
