import {
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import { useState } from 'react';
import FieldWrapper from '../FieldWrapper';
import {
  MonetaryField,
  parsePastedText,
} from '../../../../MonetaryField';

/**
 * Number value validation
 *
 * @param {object} props - root props
 * @param {number} props.index - validation index
 * @param {object} props.rule - rule data
 * @returns {NumberValue}
 */
function NumberValue({
  index, rule,
}) {
  const [fixedDecimalScale, setFixedDecimalScale] = useState(true);
  const {
    control, watch,
  } = useFormContext();

  const NUMBER_VALUE_LABELS = {
    min_number: 'Minimalna wartość dla pola',
    max_number: 'Maksymalna wartość dla pola',
  };

  const isEnabled = watch(`rules.${index}.enabled`);

  return (
    <FieldWrapper name={`rules.${index}.options.limit`} label={NUMBER_VALUE_LABELS[rule.name]}>
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Controller
          name={`rules.${index}.options.limit`}
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              disabled={!isEnabled}
              onBlur={(event) => {
                setFixedDecimalScale(true);
                onBlur(event);
              }}
              onFocus={() => setFixedDecimalScale(false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                allowedDecimalSeparators: ['.', ','],
                fixedDecimalScale,
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
            />
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
}

export default NumberValue;

NumberValue.propTypes = {
  index: PropTypes.number.isRequired,
  rule: PropTypes.instanceOf(Object).isRequired,
};
