import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementersAndApplicantName,
} from '../../../_helpers';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  SubstantiveResourcesIncubationProgram,
} from './ApplicantAndProjectPartnersResources/SubstantiveResourcesIncubationProgram';
import CharsCounter from '../../CharsCounter';

/**
 * ApplicantAndProjectPartnersResources application element.
 *
 * @returns {ApplicantAndProjectPartnersResources}
 */
export function ApplicantAndProjectPartnersResources() {
  const { id: applicationId } = useParams();
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue, getValues,
  } = useFormContext();

  const collectionFieldName = 'applicant_and_project_partners_resources';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const applicantResourcesConfig = fieldsConfig[collectionFieldName];
  const applicantResourcesSubfields = applicantResourcesConfig?.fields || {};
  const applicantResourcesFieldObject = getCollectionFieldObject(applicantResourcesSubfields, fields);
  const {
    maxRowNumber, minRowNumber,
  } = applicantResourcesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(applicantResourcesSubfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);
  const substantiveResourcesSubfields = applicantResourcesConfig?.fields
    .substantive_resources_for_the_incubation_program;

  return (
    <>
      {/* Tabela "Zasoby wnioskodawcy i partnerów projektowych" */}
      <LabeledFieldRow
        fieldConfig={applicantResourcesConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionFieldName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(applicantResourcesSubfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Nazwa podmiotu */}
            <FormField name={`${collectionFieldName}.${index}.entity_name`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementersAndApplicantName(getValues, applicationId)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Tabela: Zasoby merytoryczne do programu inkubacji */}
            <LabeledFieldRow
              fieldConfig={substantiveResourcesSubfields}
              contextHelpLabel
            >
              <SubstantiveResourcesIncubationProgram
                collectionName={`${collectionFieldName}.${index}.substantive_resources_for_the_incubation_program`}
              />
            </LabeledFieldRow>
            {/* Zasoby techniczne do programu inkubacji */}
            <FormField name={`${collectionFieldName}.${index}.technical_resources`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Etykieta: Doświadczenie w realizacji usług dla startupów w ramach prowadzonej działalności na
            rzecz inkubacji przedsiębiorstw */}
            <FormField name={`${collectionFieldName}.${index}.experience_in_service_delivery`} contextHelpLabel>
              {() => null}
            </FormField>
            {/* Inicjatywa, w ramach której świadczone były usługi oraz okres realizacji usług */}
            <FormField name={`${collectionFieldName}.${index}.initiative`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Przedmiot i zakres realizowanych usług */}
            <FormField name={`${collectionFieldName}.${index}.subject_and_scope`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Przebieg współpracy z odbiorcami usług podczas ich realizacji z opisem odbiorców usług */}
            <FormField name={`${collectionFieldName}.${index}.cooperation_course`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Efekty zrealizowanych usług */}
            <FormField name={`${collectionFieldName}.${index}.services_effect`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Zakres zadań w ramach partnerstwa */}
            <FormField name={`${collectionFieldName}.${index}.tasks_scope`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="tQnNkdHtYMe3Fl7"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                applicantResourcesFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </>
  );
}
