import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DATE,
  DICTIONARIES,
} from '../../../../../_constants';
import RowField from '../../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../../Context';
import { formatDateString } from '../../../../../_helpers';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * InternationalizationModuleEssencePromotionPdf element.
 *
 * @returns {InternationalizationModuleEssencePromotionPdf}
 */
function InternationalizationModuleEssencePromotionPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();
  const productInternationalizationValue = initialFormData
    ?.internationalization_module_essence_product_internationalization;

  return (
    <CollectionWrapper name="internationalization_module_essence_promotion_events">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.event_type`}
                  value={item.event_type}
                  oldValue={previousRowData?.event_type}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceEventTypes.name}
                />
                <RowField
                  name={`${collectionName}.${index}.event_name`}
                  value={item.event_name}
                  oldValue={previousRowData?.event_name}
                />
                <RowField
                  name={`${collectionName}.${index}.event_country`}
                  value={item.event_country}
                  oldValue={previousRowData?.event_country}
                  dictionaryName={DICTIONARIES.countries.name}
                />
                <RowField
                  name={`${collectionName}.${index}.event_date`}
                  value={formatDateString(item?.event_date, DATE.onlyYear)}
                  oldValue={formatDateString(previousRowData?.event_date, DATE.onlyYear)}
                />
                <RowField
                  name={`${collectionName}.${index}.product_name`}
                  value={item.product_name}
                  oldValue={previousRowData?.product_name}
                  externalDictionary={productInternationalizationValue.map((singleItem) => ({
                    '@id': singleItem.set_uuid,
                    name: singleItem.product_name,
                  }))}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default InternationalizationModuleEssencePromotionPdf;
