import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  useEffect,
  useState,
} from 'react';
import { useElementContext } from '../../../../Context';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../../_helpers';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../../_constants';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';
import {
  MonetaryField,
  parsePastedText,
} from '../../../MonetaryField';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';

/**
 * Works type component
 *
 * @returns {WorksType}
 */
function WorksType() {
  const [fixedDecimalScaleForWorkValue, setFixedDecimalScaleForWorkValue] = useState(true);
  const {
    control, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.innovationImplementationModuleEssenceWorkTypes);

  const collectionFieldName = 'innovation_implementation_module_essence_work_types';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const workTypesConfig = fieldsConfig[collectionFieldName];
  const subfields = workTypesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = workTypesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            collectionFieldName,
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          isIndexed
          key={field.id}
        >
          {/* Rodzaj prac */}
          <FormField name={`${collectionFieldName}.${index}.work_types`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.innovationImplementationModuleEssenceWorkTypes.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Zakres prac badawczo rozwojowych */}
          <FormField name={`${collectionFieldName}.${index}.rd_work_scope`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Wartość prac */}
          <FormField name={`${collectionFieldName}.${index}.work_value`}>
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                disabled={isReadonly}
                onBlur={(event) => {
                  setFixedDecimalScaleForWorkValue(true);
                  onBlur(event);
                }}
                onFocus={() => setFixedDecimalScaleForWorkValue(false)}
                InputProps={{
                  inputComponent: MonetaryField,
                }}
                inputProps={{
                  allowNegative: false,
                  fixedDecimalScale: fixedDecimalScaleForWorkValue,
                  allowedDecimalSeparators: ['.', ','],
                }}
                onPaste={(event) => parsePastedText(event, onChange)}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Sposób uwzględnienia w aktywach firmy */}
          <FormField name={`${collectionFieldName}.${index}.method_for_assets_inclusion`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="JSd8f4KsDYtYbZX"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}

export default WorksType;
