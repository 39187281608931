import {
  useEffect,
  useState,
} from 'react';
import { TitledContainer } from '../../TitledContainer';
import { AdditionalActivityForm } from './AdditionalActivity.form';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import {
  useExpertPersonalDataContext,
  useExpertApplicationFormContext,
} from '../../../Context';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';

/**
 * Expert additional activity
 *
 * @returns {AdditionalActivity}
 */
export function AdditionalActivity() {
  const [additionalActivity, setAdditionalActivity] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { expertAdditionalActivity } = useExpertPersonalDataContext();
  const { isApplicationFormStatusNotInEditionAndInCorrection } = useExpertApplicationFormContext();

  useEffect(() => {
    /**
     * Loads expert additional activities.
     */
    const loadAdditionalActivity = async () => {
      if (isApplicationFormStatusNotInEditionAndInCorrection) {
        setAdditionalActivity(expertAdditionalActivity);
        setIsLoaded(true);

        return;
      }
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.additionalActivity}/${expertAdditionalActivity.id}`);

      if (statusSuccess) {
        setAdditionalActivity(payload);
        setIsLoaded(true);
      }
    };

    loadAdditionalActivity();
  }, []);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <TitledContainer title="Dodatkowa działalność eksperta" key="additional-activity" variant="slim" textAlign="left">
      <AdditionalActivityForm additionalActivity={additionalActivity} />
    </TitledContainer>
  );
}
