/**
 * Consider migration to https://github.com/convert-units/convert-units
 */
export const convertStorageUnit = {
  /**
   * Converts megabytes to bytes.
   *
   * @param {number} megabytes - input value
   * @returns {number}
   */
  megabytesToBytes: (megabytes) => Number(megabytes) * 1000000,

  /**
   * Converts bytes to megabytes.
   *
   * @param {number} bytes - input value
   * @returns {number}
   */
  bytesToMegabytes: (bytes) => Number(bytes) / 1000000,
};

export const convertMoneyUnit = {
  amountToSmallestUnit: (amount) => Number(amount) * 100,
  smallestUnitToAmount: (smallestUnit) => Number(smallestUnit) / 100,
};
