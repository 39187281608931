/**
 * Expert roles.
 *
 * @type {object}
 */
export const EXPERT_ROLES = {
  expert: 'expert',
  leadingExpert: 'leading_expert',
  financialExpert: 'financial_expert',
};

/**
 * Expert roles translations.
 *
 * @type {object}
 */
export const EXPERT_ROLES_TRANSLATIONS = {
  expert: 'Ekspert',
  leadingExpert: 'Ekspert wiodący',
  financialExpert: 'Ekspert finansowy',
};
