import PropTypes from 'prop-types';
import { ChoiceField } from './ChoiceField';
import { TextField } from './TextField';
import { AttachmentsField } from './AttachmentsField';
import { CollectionField } from './CollectionField';

/**
 * Create form field based on type.
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - field data
 * @returns {DynamicFieldForm}
 */
export function DynamicFieldForm({ fieldData }) {
  const fieldTypes = {
    limited_choice: ChoiceField,
    choice: ChoiceField,
    text: TextField,
    email: TextField,
    attachments: AttachmentsField,
    collection: CollectionField,
  };
  if (!(fieldData.type in fieldTypes)) {
    return '';
  }

  const FieldComponent = fieldTypes[fieldData.type];

  return <FieldComponent fieldData={fieldData} />;
}

DynamicFieldForm.propTypes = {
  fieldData: PropTypes.instanceOf(Object).isRequired,
};
