import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import {
  useNavigate,
  useLocation,
  useParams,
  generatePath,
} from 'react-router-dom';
import {
  useState,
  useEffect,
} from 'react';
import {
  Fade,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import {
  API_ROUTE,
  URL,
  SPECIALIZATIONS_TYPES,
  EXPERT_APPLICATION_FORM_STATUSES,
} from '../../_constants';
import { request } from '../../_services';
import { VerticalTabs } from '../../Features/VerticalTabs';
import {
  ExpertApplicationFormDataAttachment,
  ChoiceSpecialization,
  IndustryExpert,
  FinancialExpert,
  StatementsApplication,
  ApplicationValidationDialog,
} from '../../Features/Expert';
import PersonalData from '../../Features/Expert/ApplicationForm/PersonalData';
import Sticky from '../../Features/Sticky/Sticky';
import { ConfirmDialog } from '../../Dialogs';
import { useAuth } from '../../_security';
import {
  ExpertApplicationFormContextProvider,
  useGlobalContext,
  useGlobalDialog,
  useReloadListener,
} from '../../Context';
import {
  handleError,
  iriToId,
} from '../../_helpers';
import ApplicationFormCorrectionForm from '../../Features/Expert/ApplicationFormCard/ApplicationFormCorrection.form';

/**
 * Data to application page
 *
 * @param {object} props - root props
 * @param {boolean} props.createMode - send POST request to create resource immediately instead of fetching
 * @param {boolean} props.isReadonly - is read only
 * @returns {ExpertApplicationForm}
 */
export default function ExpertApplicationForm({
  createMode, isReadonly,
}) {
  const location = useLocation();
  /**
   * Expert application form object ID from URL.
   *
   * Note that "new" as value will send POST request and then redirect to edit page.
   */
  const { id: applicationFormId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [hashTriggered, setHashTriggered] = useState(false);
  const [applicationFormData, setApplicationFormData] = useState(null);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [expertApplicationCreated, setExpertApplicationCreated] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [latestApplicationFormCorrection, setLatestApplicationFormCorrection] = useState(null);
  const { id: userId } = useAuth();
  const { notify } = useGlobalContext();
  const {
    reload, watch: customWatch,
  } = useReloadListener();
  const watcherName = 'expert-application-form';
  const { render } = useGlobalDialog();

  const form = useForm({
    defaultValues: {
      specializations: [],
      kisDomains: [],
      otherDomains: [],
      financialJustification: {
        universityDegree: '',
        threeYearsOfExperience: '',
        universityDegreeAttachments: [],
        threeYearsOfExperienceAttachments: [],
      },
      attachments: [],
      applicationStatementAttachments: [],
      trainingCertificatesCbaAttachments: [],
    },
    mode: 'all',
  });

  const {
    resetField, reset, setError, getValues, formState: { isSubmitting },
  } = form;

  const hasValues = applicationFormData?.kisDomains?.length > 0 || applicationFormData?.otherDomains?.length > 0;
  const isApplicationFormStatusNotInEditionAndInCorrection = applicationFormData?.status
        && applicationFormData.status !== EXPERT_APPLICATION_FORM_STATUSES.inEdition
        && applicationFormData.status !== EXPERT_APPLICATION_FORM_STATUSES.inCorrection;

  useEffect(() => {
    const loadAttachments = async (path, fieldName) => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.financialJustifications}/${path}`);

      if (statusSuccess) {
        reset((formValues) => ({
          ...formValues,
          [fieldName]: payload.map(({ '@id': id }) => id),
        }));
      }
    };
    /**
     * Creates ApplicationFormData resource.
     */
    const createExpertApplication = async () => {
      if (applicationFormData) {
        return;
      }

      const {
        statusSuccess, payload,
      } = await request.post(API_ROUTE.applicationForms, {
        ownedBy: `/lsi/auth/api/users/${userId}`,
      });

      if (!statusSuccess) {
        navigate(URL.applicationForm.list);
        setTimeout(() => {
          notify(
            'Nie można utworzyć nowego wniosku, do momentu zakończenia oceny poprzedniego wniosku.',
            'error'
          );
        }, 100);

        return;
      }

      navigate(generatePath(
        URL.applicationForm.edit,
        {
          id: payload.id,
        }
      ));
      setExpertApplicationCreated(true);
    };

    /**
     * Loads ApplicationFormData resource from API.
     */
    const loadApplicationFormData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(`${API_ROUTE.applicationForms}/${applicationFormId}`);

      if (!statusSuccess) {
        navigate(URL.applicationForms);
        setTimeout(() => {
          notify('Wystąpił błąd w trakcie tworzenia zasobu.', 'error');
        }, 100);

        return;
      }

      setApplicationFormData(payload);

      if (payload?.financialJustification?.id) {
        loadAttachments(`${payload.financialJustification.id}/university-degree-attachments`, 'financialJustification.universityDegreeAttachments');
        loadAttachments(`${payload.financialJustification.id}/three-years-of-experience-attachments`, 'financialJustification.threeYearsOfExperienceAttachments');
      }

      reset({
        specializations: payload?.specializations || [],
        kisDomains: payload?.kisDomains || [],
        otherDomains: payload?.otherDomains || [],
        financialJustification: {
          universityDegree: payload?.financialJustification?.universityDegree || '',
          threeYearsOfExperience: payload?.financialJustification?.threeYearsOfExperience || '',
        },
        attachments: payload?.attachments || [],
        applicationStatementAttachments: payload?.applicationStatementAttachments || [],
        trainingCertificatesCbaAttachments: payload?.trainingCertificatesCbaAttachments || [],
      });
      setLoaded(true);
    };

    if (createMode) {
      createExpertApplication();

      return;
    }
    loadApplicationFormData();
  }, [expertApplicationCreated, customWatch(watcherName)]);

  const loadAttachments = async () => {
    if (applicationFormData?.id) {
      const requestQuery = `?itemsPerPage=100&id[]=${getValues('attachments')
        .map((iri) => iriToId(iri)).join('&id[]=')}`;

      const {
        payload, statusSuccess,
      } = await request.get(`${API_ROUTE.applicationForms}/${applicationFormData.id}/attachments${requestQuery}`);

      if (statusSuccess) {
        setAttachmentsData(payload);
      }
    }
  };

  const loadApplicationFormCorrection = async () => {
    if (applicationFormId && applicationFormData?.applicationFormCorrection) {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationForms}/${applicationFormId}/application-form-correction`
      );

      if (statusSuccess) {
        setLatestApplicationFormCorrection(payload);
      }
    }
  };

  useEffect(() => {
    loadAttachments();
    loadApplicationFormCorrection();
  }, [applicationFormData?.id, customWatch(watcherName)]);

  useEffect(() => {
    if (!applicationFormData?.specializations?.includes(SPECIALIZATIONS_TYPES.financialExpert)) {
      resetField('financialJustification');
    }
  }, [applicationFormData?.specializations]);

  const tabsConfig = [
    {
      title: 'Dane osobowe',
      id: 'B5X42NMgjySeCz3',
      component: <PersonalData
        closeHandler={() => {
          setValue(1);
        }}
        isReadonly={isReadonly}
      />,
      accessRight: true,
      isVisible: true,
    },
    {
      title: 'Wybór specjalizacji i dziedzin',
      id: '7zyWQZAxeYy9yRs',
      component: <ChoiceSpecialization />,
      accessRight: true,
      isVisible: true,
    },
    {
      title: 'Ekspert branżowy',
      id: '6bNviXQvW7LFwaF',
      component: <IndustryExpert />,
      accessRight: true,
      isVisible: applicationFormData?.specializations?.includes(SPECIALIZATIONS_TYPES.industryExpert)
        && hasValues,
    },
    {
      title: 'Ekspert finansowy',
      id: 'Q1q0BXcXbBKAsSc',
      component: <FinancialExpert />,
      accessRight: true,
      isVisible: applicationFormData?.specializations?.includes(SPECIALIZATIONS_TYPES.financialExpert) || false,
    },
    {
      title: 'Oświadczenia',
      id: 'JNEQBKPzsVjdDpT',
      component: <StatementsApplication />,
      accessRight: true,
      isVisible: true,
    },
    {
      title: 'Załączniki do wniosku',
      id: '6wnnVdNSjK7ZzcA',
      component: <ExpertApplicationFormDataAttachment />,
      accessRight: true,
      isVisible: true,
    },
  ].filter(({
    accessRight, isVisible,
  }) => accessRight && isVisible);

  if (location.hash && !hashTriggered) {
    tabsConfig.forEach(({ id }, index) => {
      if (`#${id}` === location.hash) {
        setHashTriggered(true);
        setValue(index);
      }
    });
  }

  /**
   * Render selected component.
   *
   * @returns {Element|string} component if user has access, otherwise empty string
   */
  const getComponent = () => {
    if (!tabsConfig[value]) {
      return '';
    }

    return tabsConfig[value].component;
  };

  const submitApplication = async () => {
    const {
      statusSuccess, violations, payload,
    } = await request.put(`${API_ROUTE.applicationForms}/${applicationFormData?.id}/manage`, {
      transition: 'submit',
    });

    setDialogOpened(false);
    if (!statusSuccess) {
      render(
        <ApplicationValidationDialog applicationFormDataId={applicationFormData.id} />,
        'Sprawdź poprawność',
      );
      handleError(setError, violations);

      return;
    }

    navigate('/expert-application-forms');
    setTimeout(() => {
      notify(
        `Poprawnie złożono wniosek o wpis na listę ekspertów. Numer wniosku ${payload?.applicationNumber}.`,
        'success',
      );
    }, 100);
  };

  if (!loaded) {
    return <CircularProgress size={50} color="secondary" />;
  }

  return (
    <ExpertApplicationFormContextProvider value={{
      applicationFormData,
      isReadonly,
      attachmentsData,
      reloadData: () => reload(watcherName),
      isApplicationFormStatusNotInEditionAndInCorrection,
    }}
    >
      <FormProvider {...form}>
        {!isReadonly && (
          <Sticky width="100%" top="0" left="0">
            <Box
              display="flex"
              justifyContent="start"
              boxShadow="0 0 10px rgb(0 0 0 / 10%)"
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
              mb={3}
            >
              <Typography style={visuallyHidden}>
                Podejmij akcję na wniosku
              </Typography>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  id="8iVJldZae1dTGe5"
                  onClick={() => render(
                    <ApplicationValidationDialog applicationFormDataId={applicationFormData.id} />,
                    'Sprawdź poprawność',
                  )}
                  sx={{
                    margin: (t) => t.spacing(1),
                    minWidth: '150px',
                  }}
                >
                  Sprawdź poprawność
                </Button>
                {[
                  EXPERT_APPLICATION_FORM_STATUSES.inEdition,
                  EXPERT_APPLICATION_FORM_STATUSES.inCorrection,
                ].includes(applicationFormData?.status) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    id="CLB0wO2YIC9OXdk"
                    onClick={() => setDialogOpened(true)}
                    sx={{
                      margin: (t) => t.spacing(1),
                      minWidth: '150px',
                    }}
                  >
                    Złóż wniosek
                  </Button>
                )}
              </div>
            </Box>
          </Sticky>
        )}
        {latestApplicationFormCorrection && (
          <Grid container>
            <Grid item xs={4} />
            <Grid item xs={6} mb={3}>
              <Typography fontWeight="bold" fontSize="large" mb={1}>
                Komisja Kwalifikacyjna przesłała wezwanie do poprawy/uzupełnienia Twojego wniosku
                złożonego w ramach naboru ekspertów PARP. Zapoznaj się ze szczegółami wezwania.
              </Typography>
              <Button
                id="jIlgrYmYnmVLDmJ"
                variant="contained"
                color="secondary"
                onClick={() => render(
                  <ApplicationFormCorrectionForm
                    isReadonly
                    initialData={latestApplicationFormCorrection}
                    applicationFormId={applicationFormId}
                  />,
                  'Wezwanie do uzupełnień',
                )}
              >
                Szczegóły
              </Button>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        )}
        <Fade timeout={600} in>
          <Grid container spacing={3} direction="row">
            <Grid item lg={3} md={3} xs={12}>
              <VerticalTabs
                ariaLabel="Opcje dotyczące danych do wniosku aplikacyjnego"
                ariaPrefix="settings-tab"
                selectionHandler={setValue}
                initialSelectedIndex={value}
                menuItems={tabsConfig}
                disableMenu={isSubmitting}
              />
            </Grid>
            <Grid item lg={9} md={9} xs={12}>
              {getComponent()}
            </Grid>
            <ConfirmDialog
              open={dialogOpened}
              dialogId="XoWk8fGSaYbFHu5"
              dialogTitle="Potwierdź złożenie wniosku"
              confirmButtonLabel="Złóż wniosek"
              closeHandler={() => setDialogOpened(false)}
              confirmButtonHandler={submitApplication}
            >
              <Typography textAlign="center" fontSize="large">
                Czy potwierdzasz złożenie wniosku?
              </Typography>
            </ConfirmDialog>
          </Grid>
        </Fade>
      </FormProvider>
    </ExpertApplicationFormContextProvider>
  );
}

ExpertApplicationForm.propTypes = {
  createMode: PropTypes.bool,
  isReadonly: PropTypes.bool,
};

ExpertApplicationForm.defaultProps = {
  createMode: false,
  isReadonly: false,
};
