import {
  Box,
  Button,
  Divider,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import uniqid from 'uniqid';
import { useMemo } from 'react';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import SingleAdditionalOption from './SingleAdditionalOption';

/**
 * Single statement additional options form
 *
 * @returns {SingleStatementAdditionalOptionsForm}
 */
export function SingleStatementAdditionalOptionsForm() {
  const {
    watch, control, clearErrors, setValue,
  } = useFormContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'options',
  });

  const validation = {
    minChosenOptions: {
      required: {
        value: true,
        message: 'Pole "Minimalna liczba opcji do wyboru" jest wymagane.',
      },
    },
    maxChosenOptions: {
      required: {
        value: true,
        message: 'Pole "Maksymalna liczba opcji do wyboru" jest wymagane.',
      },
      min: {
        value: parseInt(watch('minChosenOptions'), 10),
        message: 'Wartość pola "Maksymalna liczba opcji do wyboru" nie może być mniejsza'
          + 'od wartości pola "Minimalna liczba opcji do wyboru"',
      },
    },
  };

  /**
   * Adds item to contact_persons collection.
   */
  const addItem = () => {
    append({
      key: `option-tmp-${uniqid()}`,
      label: '',
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Handle dragging action.
   *
   * @param {object} event - draggable event
   */
  const handleDrag = (event) => {
    clearErrors();
    const {
      destination,
      source,
    } = event;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      fields,
      event.source.index,
      event.destination.index
    );

    setValue('options', items);
  };

  const DraggableFields = () => useMemo(() => fields.map((optionData: Object, index: number) => (
    <SingleAdditionalOption
      index={index}
      key={`option-row-${optionData.key}`}
      deleteOption={remove}
      optionDataKey={optionData.key}
    />
  )), []);

  return (
    <>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          rules={{
            required: validation.minChosenOptions.required,
          }}
          name="minChosenOptions"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              error={!!error}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              required
              label="Minimalna liczba opcji do wyboru"
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          rules={{
            required: validation.maxChosenOptions.required,
            min: validation.maxChosenOptions.min,
          }}
          name="maxChosenOptions"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              error={!!error}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              required
              label="Maksymalna liczba opcji do wyboru"
            />
          )}
        />
      </FormControl>
      <Box my={5}>
        <Divider />
      </Box>
      <Typography textAlign="center" variant="pageHeading">
        Opcje oświadczenia
      </Typography>
      <Box display="flex" justifyContent="flex-end" mt={-8}>
        <Button
          id="g876st7M16CwOd8"
          variant="contained"
          color="success"
          aria-label="Dodaj opcję"
          onClick={addItem}
        >
          Dodaj
        </Button>
      </Box>
      <TableContainer>
        <Table aria-label="Opcje oświadczenia">
          <TableHead>
            <TableRow>
              <TableCell scope="column">Nazwa opcji</TableCell>
              <TableCell scope="column" width={200}>Akcje</TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId="option-list-droppable">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  <DraggableFields />
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
}
