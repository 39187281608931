import {
  Box,
  FormControl,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { SaveCancelButtons } from '../../../Features/SaveCancelButtons';
import { useGlobalDialog } from '../../../Context';

/**
 * StatementAddForm element.
 *
 * @param {object} props - root props
 * @param {Function} props.append - append statement
 * @param {Array} props.fields - statement list
 * @returns {StatementAddForm}
 */
function StatementAddForm({
  append, fields,
}) {
  const [statementLabel, setStatementLabel] = useState('');
  const {
    closeAll,
  } = useGlobalDialog();

  const addStatement = () => {
    append({
      label: statementLabel,
      validFrom: null,
      validTo: null,
      ordinalNumber: fields.length + 1,
      note: '',
      minChosenOptions: 0,
      maxChosenOptions: 0,
      hasAdditionalOptions: false,
      options: [],
      printable: true,
    });
    closeAll();
  };

  return (
    <>
      <FormControl fullWidth variant="outlined" margin="normal">
        <TextField
          name="statementLabel"
          onChange={(event) => setStatementLabel(event.target.value)}
          value={statementLabel}
          variant="outlined"
          label="Nazwa oświadczenia"
          required
        />
      </FormControl>
      <Box mt={5} display="flex" width="100%" justifyContent="center">
        <SaveCancelButtons
          saveHandler={addStatement}
          saveButtonId="tFI8CD0jY15Iz04"
          cancelHandler={closeAll}
          cancelButtonId="6ua3D8Y2hrpJ0gU"
        />
      </Box>
    </>
  );
}

export default StatementAddForm;

StatementAddForm.propTypes = {
  append: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
};
