/**
 * Delivery methods values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const deliveryMethodsOptions = [
  {
    id: 'paper',
    label: 'W formie papierowej',
  },
  {
    id: 'digital',
    label: 'W formie elektronicznej',
  },
];
