import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  formatPkdCodesFieldValue,
} from '../../../_helpers';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import {
  BOOLEAN_VALUES,
  CHARS_LIMIT,
  DICTIONARIES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';

/**
 * EcosystemPartners element renderer.
 *
 * @returns {EcosystemPartners}
 */
export default function EcosystemPartners() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    watch, control, setValue, resetField,
  } = useFormContext();

  const {
    REGON, KRS, NIP,
  } = CHARS_LIMIT;

  const {
    append, remove, fields,
  } = useFieldArray({
    control,
    name: 'ecosystem_partners_ecosystem_partners',
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.legalEntityTypes,
    DICTIONARIES.propertyForms,
    DICTIONARIES.companySize,
    DICTIONARIES.pkdCodes,
    DICTIONARIES.ecosystemPartnerType,
  );
  const ecosystemPartnersConfig = fieldsConfig.ecosystem_partners_ecosystem_partners;
  const ecosystemPartnersSubfields = ecosystemPartnersConfig?.fields || {};
  const investmentAidFieldObject = getCollectionFieldObject(ecosystemPartnersSubfields, fields);

  const {
    maxRowNumber, minRowNumber,
  } = ecosystemPartnersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(ecosystemPartnersSubfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Czy wnioskodawca przewiduje udział partnerów ekosystemowych w realizacji projektu?  */}
      <FormField name="ecosystem_partners_is_ecosystem_partners">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'ecosystem_partners_ecosystem_partners',
                  { defaultValue: FIELD_TYPES_MAP.collection }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      <LabeledFieldRow
        hide={watch('ecosystem_partners_is_ecosystem_partners') !== BOOLEAN_VALUES.TRUE}
        fieldConfig={ecosystemPartnersConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'ecosystem_partners_ecosystem_partners',
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(ecosystemPartnersSubfields)}
            key={item.id}
            isIndexed
          >
            {/* Rodzaj partnera ekosystemowego */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.partner_type`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.ecosystemPartnerType.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Nazwa */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.name`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Data rozpoczęcia działalności zgodnie z dokumentem rejestrowym */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.start_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Forma prawna */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.legal_form`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.legalEntityTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Forma własności  */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.property_form`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.propertyForms.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Wielkość przedsiębiorstwa  */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.company_size`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.companySize.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* NIP */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.nip`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  disabled={isReadonly}
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? NIP} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? NIP,
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* REGON */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.regon`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  disabled={isReadonly}
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? REGON} />,
                  }}
                  inputProps={{
                    mask: '000000000',
                    maxLength: maxLength ?? REGON,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Numer w Krajowym Rejestrze Sądowym */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.krs`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  disabled={isReadonly}
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? KRS} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? KRS,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Numer kodu PKD przeważającej działalności */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.pkd`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={formatPkdCodesFieldValue(filterOptions(get(DICTIONARIES.pkdCodes.name)))}
                  optionsMapKeys={['id', 'label']}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis działalności partnera ekosystemowego */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.activities_description`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Zakres oferty (zasoby i potencjał) partnera ekosystemowego  */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.offer_scope`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Opis zrealizowanego przedsięwzięcia wnioskodawcy lub partnera projektowego z
              partnerem ekosystemowym wraz z okresem realizacji   */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.implemented_project_description`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Doświadczenie partnera ekosystemowego we współpracy ze startupami wraz z okresem współpracy   */}
            <FormField name={`ecosystem_partners_ecosystem_partners.${index}.partner_experience`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="arGGxN1DnOJ2ayO"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                investmentAidFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
