import {
  PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES,
  PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES_TRANSLATIONS,
} from '../_constants';
/**
 * Protest review statement acceptance statuses values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const protestReviewStatementAcceptanceStatusesOptions = [
  {
    id: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.accepted,
    label: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES_TRANSLATIONS.accepted,
  },
  {
    id: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.corrections,
    label: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES_TRANSLATIONS.corrections,
  },
  {
    id: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES.rejected,
    label: PROTEST_REVIEWS_STATEMENT_ACCEPTANCE_STATUSES_TRANSLATIONS.rejected,
  },
];
