import {
  useEffect,
  useState,
} from 'react';
import { sessionService } from '../../_security';

/**
 * Data grid query parameter helper. Used to store filters, sorting and pagination in session storage.
 *
 * @param {string} name - key name in which the parameters will be stored
 * @param {Array} defaultSortModel - default sort model
 * @returns {object}
 */
export const useQueryFilters = (name, defaultSortModel) => {
  const [queryFilters, setQueryFilters] = useState({
    filterModel: sessionService.get(name)?.filterModel || {},
    sortModel: sessionService.get(name)?.sortModel || defaultSortModel,
    paginationModel: sessionService.get(name)?.paginationModel || {
      page: 0,
      itemsPerPage: 20,
    },
  });

  useEffect(() => {
    if (name) {
      sessionStorage.setItem(name, JSON.stringify(queryFilters));
    }
  }, [queryFilters]);

  return {
    filterModel: queryFilters.filterModel,
    sortModel: queryFilters.sortModel,
    paginationModel: queryFilters.paginationModel,
    setQueryFilters,
  };
};
