import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Button,
  Typography,
  FormControl,
  TextField,
  Tooltip,
} from '@mui/material';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { ACTION_TYPES } from '../../_constants';
import { GoBack } from '../GoBack';
import { LoginByEmailStyleClasses } from './LoginByEmail.style';
import { Alert } from '../Alert';
import { useAuth } from '../../_security';
import { useGlobalContext } from '../../Context';

export const INVALID_CREDENTIALS_MESSAGE = 'Invalid credentials.';
export const UNACTIVATED_ACCOUNT_MESSAGE = 'Account activation required.';

/**
 * Login with email component.
 *
 * @returns {LoginByEmail}
 */
export function LoginByEmail() {
  const classes = LoginByEmailStyleClasses;
  const { onLogin } = useAuth();
  const { handleAction } = useGlobalContext();
  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'all',
  });
  const [state, setState] = useState({
    submitted: false,
    error: null,
  });
  const [showPassword, setShowPassword] = useState(false);

  const invalidCredentials = 'Nieprawidłowe dane logowania. Poprawne dane logowania uzyskasz korzystając z opcji '
    + '"Nie pamiętasz loginu?"/"Nie pamiętasz hasła?".';
  const activationRequired = 'Twoje konto nie zostało jeszcze aktywowane. Proszę aktywować konto klikając w link '
    + 'w wiadomości e-mail otrzymanej z systemu podczas rejestracji konta.';

  /**
   * Function forwards credentials to `onLogin` and handles success/failure response.
   *
   * @param {object} props - root props
   * @param {string} props.username - username
   * @param {string} props.password - password
   */
  const handleLogin = ({
    username, password,
  }) => {
    setState((s) => ({
      ...s,
      submitted: true,
    }));

    /**
     * Sets error message to state and unlock form by setting submitted to false.
     *
     * @param {object} payload - response payload
     */
    const handleFailure = (payload) => {
      setState((s) => ({
        ...s,
        submitted: false,
        error: payload?.message === UNACTIVATED_ACCOUNT_MESSAGE
          ? activationRequired
          : invalidCredentials,
      }));
    };

    onLogin(
      username,
      password,
      () => handleAction({ type: ACTION_TYPES.SHOW_NEW_MESSAGE_DIALOG }),
      handleFailure
    );
  };

  return (
    <Paper variant="outlined" elevation={0} sx={classes.loginByEmailPaper}>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} mt={2} mb={4} ml={1}>
          <GoBack
            destination="strony głównej"
            id="TqwCsbsI1hd0op9"
          />
        </Grid>
        <Grid item xs={12} lg={8} xl={6}>
          <Typography variant="h2" sx={classes.loginByEmailHeader}>Logowanie</Typography>
          <form onSubmit={handleSubmit(handleLogin)} noValidate>
            <FormControl fullWidth>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Wpisz login"',
                  },
                  validate: (val) => !val.includes('@')
                    || 'Adres e-mail nie jest loginem do konta. '
                    + 'Login do konta znajduje się w e-mailu dot. rejestracji konta.',
                }}
                name="username"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={state.submitted}
                    required
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    label="Wpisz login"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <Link to="/auth/reminder-account" style={classes.helperLink}>
              Nie pamiętasz loginu?
            </Link>
            <FormControl fullWidth>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Wpisz hasło"',
                  },
                }}
                name="password"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={state.submitted}
                    required
                    error={!!error}
                    helperText={error?.message}
                    label="Wpisz hasło"
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    type="password"
                    variant="outlined"
                    inputProps={{
                      type: showPassword ? 'text' : 'password',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={showPassword ? 'Ukryj hasło' : 'Pokaż hasło'}
                            aria-label={showPassword ? 'Ukryj hasło' : 'Pokaż hasło'}
                          >
                            <IconButton
                              id="zoSdsbmgJt1Lu6j"
                              aria-label="przełącz widoczność hasła"
                              data-testid="passwordVisibilityIcon"
                              onClick={() => setShowPassword((s) => (!s))}
                              size="large"
                            >
                              {showPassword ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <Link to="/auth/reminder-password" style={classes.helperLink}>
              Nie pamiętasz hasła?
            </Link>
            <div>
              {state.error && (
                <Alert severity="error">
                  {state.error}
                </Alert>
              )}
            </div>
            <Button
              disabled={state.submitted}
              id="t0T385S3GIiJwsd"
              sx={classes.loginByEmailBtn}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              Zaloguj
            </Button>
            <Typography variant="h5" component="h3">Nie masz jeszcze konta?</Typography>
            <Link to="/auth/register" style={classes.linkWithBtn}>
              <Button
                id="SEC0ev6PJrYNLdq"
                variant="contained"
                sx={classes.registerBtn}
                size="large"
                fullWidth
              >
                Zarejestruj konto
              </Button>
            </Link>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
