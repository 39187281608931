/**
 * Modules ids.
 *
 * @type {object}
 */
export const MODULE_IDS = {
  br: '91e674cd-e26a-49a2-82f1-f58cff06693b',
  rdInfrastructure: '368ab2fa-a3b3-4058-a31c-6f27ddd04fa3',
  innovationImplementation: 'eb0a4538-56e6-474a-8acf-472bae644560',
  internationalization: 'f154569e-aa30-4795-bae6-548b93ca95dc',
  competences: 'a3594985-2292-4e15-93d0-9b92d87c53b7',
  digitization: 'b6f1f805-e150-4d25-8951-4835fb55b8b7',
  greening: '7a90bbf6-de6a-470f-bad4-f6010284fcd8',
};

/**
 * Module iris.
 *
 * @type {object}
 */
export const MODULE_IRIS = {
  br: `/lsi/recruitments/api/modules/${MODULE_IDS.br}`,
  rdInfrastructure: `/lsi/recruitments/api/modules/${MODULE_IDS.rdInfrastructure}`,
  innovationImplementation: `/lsi/recruitments/api/modules/${MODULE_IDS.innovationImplementation}`,
  internationalization: `/lsi/recruitments/api/modules/${MODULE_IDS.internationalization}`,
  competences: `/lsi/recruitments/api/modules/${MODULE_IDS.competences}`,
  digitization: `/lsi/recruitments/api/modules/${MODULE_IDS.digitization}`,
  greening: `/lsi/recruitments/api/modules/${MODULE_IDS.greening}`,
};

/**
 * Modules numeration.
 *
 * @type {object}
 */
export const MODULES_ORDER = {
  [MODULE_IRIS.br]: 1,
  [MODULE_IRIS.innovationImplementation]: 2,
  [MODULE_IRIS.rdInfrastructure]: 3,
  [MODULE_IRIS.digitization]: 4,
  [MODULE_IRIS.greening]: 5,
  [MODULE_IRIS.competences]: 6,
  [MODULE_IRIS.internationalization]: 7,
  [null]: 8,
};
