import { TitledContainer } from '../../TitledContainer';
import { FinancialExpertForm } from './FinancialExpert.form';

/**
 * Financial data of expert
 *
 * @returns {FinancialExpert}
 */
export function FinancialExpert() {
  return (
    <TitledContainer
      title="Uzasadnienie spełnienia kryteriów obligatoryjnych"
      key="financial-expert"
      variant="slim"
      textAlign="left"
    >
      <FinancialExpertForm />
    </TitledContainer>
  );
}
