/**
 * Specialization types.
 *
 * @type {object}
 */
export const SPECIALIZATIONS_TYPES = {
  financialExpert: 'financial_expert',
  industryExpert: 'industry_expert',
};

/**
 * Specialization statuses translations.
 *
 * @type {object}
 */
export const SPECIALIZATIONS_TYPES_TRANSLATIONS = {
  financialExpert: 'Ekspert finansowy',
  industryExpert: 'Ekspert branżowy',
};

export const getAllSpecializationStatuses = () => [
  {
    name: SPECIALIZATIONS_TYPES.financialExpert,
    label: SPECIALIZATIONS_TYPES_TRANSLATIONS.financialExpert,
  },
  {
    name: SPECIALIZATIONS_TYPES.industryExpert,
    label: SPECIALIZATIONS_TYPES_TRANSLATIONS.industryExpert,
  },
];
