import {
  Divider,
  Grid,
  useMediaQuery,
} from '@mui/material';
import {
  Logo,
  LogoUE,
} from '../index';
import { Navigation } from '../Navigation';
import { Toolbar } from '../Toolbar';
import { GlobalNotification } from './GlobalNotification';
import SessionBar from '../SessionBar/SessionBar';
import { useAuth } from '../../_security';
import { SwitchedUser } from './SwitchedUser';
import MobileNavigation from '../Navigation/MobileNavigation';

/**
 * Application header component.
 *
 * @returns {Header}
 */
export function Header() {
  const {
    authenticated,
  } = useAuth();
  const isBreakpointUpLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <Grid component="header" px={3} container spacing={0} pt={0} pb={2} minHeight="15vh">
      {authenticated && (
        <Grid item xs={12}>
          <SessionBar />
          <SwitchedUser />
        </Grid>
      )}
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
      <Grid item xs={10} lg={2} display="flex" alignItems="center">
        <Logo />
      </Grid>
      {isBreakpointUpLg && (
        <Grid item lg={9} display="flex" alignItems="center">
          <Navigation />
        </Grid>
      )}
      <Grid item xs={2} lg={1} display="flex" gap={3} alignItems="center" justifyContent="flex-end">
        {!isBreakpointUpLg && (<MobileNavigation />)}
        <LogoUE />
      </Grid>
      <Divider sx={{ width: '100%' }} />
      <GlobalNotification />
    </Grid>
  );
}
