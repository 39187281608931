import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../../Context';

/**
 * Commitment Person Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.parentCollectionName - parent collection name
 * @param {number} props.index - current parent index
 * @returns {CommitmentPersonPdf}
 */
function CommitmentPersonPdf({
  parentCollectionName, index,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      fieldsConfig,
    },
  } = useApplicationToPdfContext();

  const commitmentPersonConfig = fieldsConfig?.[parentCollectionName]?.fields?.commitment_person;
  const commitmentPersonData = initialFormData?.[parentCollectionName]?.[index]?.commitment_person || [];

  return (
    <CollectionWrapper
      name={`${parentCollectionName}.${index}.commitment_person`}
      externalFieldConfig={commitmentPersonConfig}
      externalData={commitmentPersonData}
    >
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, childIndex) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={childIndex} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${childIndex}.title`}
                value={item.title}
                oldValue={previousRowData?.title}
              />
              <RowField
                name={`${collectionName}.${childIndex}.institution`}
                value={item.institution}
                oldValue={previousRowData?.institution}
              />
              <RowField
                name={`${collectionName}.${childIndex}.role`}
                value={item.role}
                oldValue={previousRowData?.role}
              />
              <RowField
                name={`${collectionName}.${childIndex}.involvement_dimension`}
                value={item.involvement_dimension}
                oldValue={previousRowData?.involvement_dimension}
              />
              <RowField
                name={`${collectionName}.${childIndex}.involvement_start_date`}
                value={item.involvement_start_date}
                oldValue={previousRowData?.involvement_start_date}
              />
              <RowField
                name={`${collectionName}.${childIndex}.involvement_end_date`}
                value={item.involvement_end_date}
                oldValue={previousRowData?.involvement_end_date}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default CommitmentPersonPdf;

CommitmentPersonPdf.propTypes = {
  parentCollectionName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
