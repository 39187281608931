import {
  MenuItem,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';

/**
 * Select filter for MUI Data Grid.
 *
 * @param {object} props - root props
 * @param {object} props.columnData - grid column data
 * @param {Function} props.applyFilter - apply filter function
 * @param {number} props.cleanListener - on change this number, input value should be restored to default
 * @param {string} props.id - id forwarded to input component
 * @param {string} props.initialValue - initial value
 * @returns {GridSingleSelectFilter}
 */
export function GridSingleSelectFilter({
  columnData, applyFilter, cleanListener, id, initialValue,
}) {
  const {
    field, valueOptions,
  } = columnData;
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    if (cleanListener !== 0) {
      setSelectedValue('');
    }
  }, [cleanListener]);

  /**
   * Handle input change.
   *
   * @param {Event} props - root props
   * @param {object} props.target - Event target
   * @param {string} props.target.value - input value
   */
  const handleChange = ({ target: { value } }) => {
    setSelectedValue(value);
    applyFilter(field, value);
  };

  return (
    <Select
      displayEmpty
      variant="standard"
      value={selectedValue}
      onChange={handleChange}
      inputProps={{ id }}
      sx={{
        width: '90%',
      }}
    >
      <MenuItem key="empty-option" value="">&nbsp;</MenuItem>
      {valueOptions.map(({
        value, label,
      }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
    </Select>
  );
}

GridSingleSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  columnData: PropTypes.instanceOf(Object).isRequired,
  applyFilter: PropTypes.func.isRequired,
  cleanListener: PropTypes.number.isRequired,
  initialValue: PropTypes.string,
};

GridSingleSelectFilter.defaultProps = {
  initialValue: '',
};
