import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantPotential pdf element.
 *
 * @returns {ApplicantPotentialPdf}
 */
function ApplicantPotentialPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Opis posiadanych lub możliwych do pozyskania źródeł finansowania */}
        <RowField name="applicant_potential_financing_sources_description" />

        {/* Opis posiadanych lub możliwych do pozyskania zasobów ludzkich */}
        <RowField name="applicant_potential_human_resources_description" isSingleRow />

        {/* Opis posiadanych lub możliwych do pozyskania infrastruktury technicznej, informatycznej i lokalowej */}
        <RowField name="applicant_potential_technical_owned_resources_description" isSingleRow />

        {/* Opis posiadanych lub możliwych do pozyskania innych zidentyfikowanych zasobów o
       kluczowym znaczeniu dla wykonalności projektu */}
        <RowField name="applicant_potential_held_resources_description" isSingleRow />

        {/* Wykazanie zdolności wnioskodawcy do utrzymania efektów projektu */}
        <RowField name="applicant_potential_demonstration_applicant_ability" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ApplicantPotentialPdf;
