import {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Controller,
  useForm,
  useFieldArray,
  FormProvider,
} from 'react-hook-form';
import uniqid from 'uniqid';
import { theme } from '../../../styles/theme';
import CharsCounter from '../../CharsCounter';
import { ConfirmDialog } from '../../../Dialogs';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { ApplicationFormAttachmentsField } from './ApplicationFormAttachmentsField';
import InfoPopover from '../../InfoPopover/InfoPopover';
import {
  API_ROUTE,
  APPLICATION_CONTEXT_HELP_TEXT,
} from '../../../_constants';
import { request } from '../../../_services';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  useGlobalContext,
  useGlobalDialog,
} from '../../../Context';
import { handleError } from '../../../_helpers';

const classes = {
  label: {
    color: theme.palette.text.brand,
    fontSize: '1rem',
    marginTop: theme.spacing(3),
  },
};

/**
 * Reason KIS form of the industry expert candidate.
 *
 * @param {object} props - root props
 * @param {Array} props.kisDomainAreas - kis domain areas dictionary
 * @param {Array} props.oecdScienceDomains - oecd science domains dictionary
 * @param {Array} props.naceScienceDomains - nace science domains dictionary
 * @param {object} props.kisDomain - related kis domain object
 * @param {string} props.applicationFormId - application form id
 * @param {boolean} props.isReadonly - is readonly
 * @param {object} props.attachmentsData - attachments data
 * @returns {KisDomainJustificationForm}
 */
export function KisDomainJustificationForm({
  kisDomainAreas,
  oecdScienceDomains,
  naceScienceDomains,
  kisDomain,
  applicationFormId,
  isReadonly,
  attachmentsData,
}) {
  const [domainJustificationsCreated, setDomainJustificationsCreated] = useState(false);
  const [domainJustificationId, setDomainJustificationId] = useState(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [visibleNotification, setVisibleNotification] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { notify } = useGlobalContext();
  const { closeAll } = useGlobalDialog();

  const form = useForm({
    defaultValues: {
      kisDomainAreas: [],
      keywordsBanks: [],
      oecdScienceDomains: [],
      naceScienceDomains: [],
      holdsMasterDegreeOrEquivalent: '',
      holdsMasterDegreeOrEquivalentAttachments: [],
      threeYearsOfExperience: '',
      threeYearsOfExperienceAttachments: [],
      doctoralDegreeInDomain: '',
      doctoralDegreeInDomainAttachments: [],
      scientificPublications: '',
      memberOfCrucialResearchStaff: '',
      memberOfCrucialResearchStaffAttachments: [],
      patentsAsInventor: '',
      patentsAsInventorAttachments: [],
      implementationParticipant: '',
      implementationParticipantAttachments: [],
      qualifiedAsExpert: '',
      qualifiedAsExpertAttachments: [],
    },
    mode: 'all',
  });

  const {
    control, formState: { isDirty }, handleSubmit, setError, reset, getValues, watch, setValue,
  } = form;

  useEffect(() => {
    const loadAttachments = async (path, fieldName) => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.domainJustifications}/${path}`);

      if (statusSuccess) {
        setValue(fieldName, payload.map(({ '@id': id }) => id));
      }
    };

    /**
     * Load DomainJustificationsData resource from API.
     */
    const domainJustificationsData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationDomainJustifications
          .replace(':applicationFormId', applicationFormId)}?kisDomain=${kisDomain['@id']}`
      );

      if (!statusSuccess) {
        return;
      }

      const domainJustificationData = payload[0];
      setDomainJustificationId(domainJustificationData?.id);

      if (domainJustificationData?.id) {
        loadAttachments(`${domainJustificationData.id}/holds-master-degree-or-equivalent-attachments`, 'holdsMasterDegreeOrEquivalentAttachments');
        loadAttachments(`${domainJustificationData.id}/three-years-of-experience-attachments`, 'threeYearsOfExperienceAttachments');
        loadAttachments(`${domainJustificationData.id}/doctoral-degree-in-domain-attachments`, 'doctoralDegreeInDomainAttachments');
        loadAttachments(`${domainJustificationData.id}/member-of-crucial-research-staff-attachments`, 'memberOfCrucialResearchStaffAttachments');
        loadAttachments(`${domainJustificationData.id}/implementation-participant-attachments`, 'implementationParticipantAttachments');
        loadAttachments(`${domainJustificationData.id}/qualified-as-expert-attachments`, 'qualifiedAsExpertAttachments');
        loadAttachments(`${domainJustificationData.id}/patents-as-inventor-attachments`, 'patentsAsInventorAttachments');
      }

      if (!domainJustificationData) {
        setLoaded(true);

        return;
      }

      const kisDomainAreasIri = domainJustificationData?.keywordsBanks?.map((item) => item?.kisDomainArea);
      const chosenKisDomainAreas = [];
      kisDomainAreas?.forEach(({
        '@id': id, name,
      }) => {
        if (kisDomainAreasIri?.includes(id)) {
          chosenKisDomainAreas.push({
            id,
            label: name,
          });
        }
      });

      const newKeywordsBanksValues = chosenKisDomainAreas?.map(({
        label, id,
      }) => {
        const obj = domainJustificationData?.keywordsBanks
          .filter(({ kisDomainArea }) => kisDomainArea === id)
          .map(({
            kisDomainArea, keywords,
          }) => ({
            label,
            kisDomainArea,
            keywords,
          }))[0];

        return obj;
      });

      reset({
        kisDomainAreas: chosenKisDomainAreas.map(({ id }) => id),
        keywordsBanks: newKeywordsBanksValues,
        oecdScienceDomains: domainJustificationData.oecdScienceDomains.map(({ '@id': id }) => id),
        naceScienceDomains: domainJustificationData.naceScienceDomains.map(({ '@id': id }) => id),
        holdsMasterDegreeOrEquivalent: domainJustificationData.holdsMasterDegreeOrEquivalent,
        threeYearsOfExperience: domainJustificationData.threeYearsOfExperience,
        doctoralDegreeInDomain: domainJustificationData.doctoralDegreeInDomain,
        scientificPublications: domainJustificationData.scientificPublications,
        memberOfCrucialResearchStaff: domainJustificationData.memberOfCrucialResearchStaff,
        patentsAsInventor: domainJustificationData.patentsAsInventor,
        implementationParticipant: domainJustificationData.implementationParticipant,
        qualifiedAsExpert: domainJustificationData.qualifiedAsExpert,
      });

      setDomainJustificationsCreated(true);
      setLoaded(true);
    };

    domainJustificationsData();
  }, [applicationFormId]);

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'keywordsBanks',
  });

  const addItem = () => {
    const kisDomainAreasFieldValue = getValues('kisDomainAreas');
    const keywordsBanksValue = getValues('keywordsBanks');

    /**
     * Elements from kisDomainAreasFieldValue are filtered to prevent duplicates.
     */
    if (kisDomainAreasFieldValue.length === keywordsBanksValue.length) {
      return;
    }
    const elementsToAppend = kisDomainAreasFieldValue
      .filter((id) => !keywordsBanksValue.some(({ kisDomainArea }) => kisDomainArea === id))
      .map((kisDomainArea) => ({
        kisDomainArea,
        label: kisDomainAreas.find(({ '@id': kisDomainAreaId }) => kisDomainAreaId === kisDomainArea)?.name,
        keywords: [],
      }));
    append(elementsToAppend);
  };

  const submitForm = async (formData) => {
    const requestFunc = domainJustificationsCreated
      ? request.put(`${API_ROUTE.domainJustifications}/${domainJustificationId}`, formData)
      : request.post(`${API_ROUTE.domainJustifications}`, {
        ...formData,
        applicationForm: `/lsi/auth/api/application-forms/${applicationFormId}`,
        oecdScienceDomains: formData.oecdScienceDomains,
        naceScienceDomains: formData.naceScienceDomains,
        kisDomain: kisDomain['@id'],
      });
    const {
      statusSuccess, violations = [],
    } = await requestFunc;

    window.scroll(0, 0);

    if (statusSuccess) {
      notify('Dane zostały wprowadzone', 'success');
      closeAll();

      return;
    }

    handleError(setError, violations);
    setVisibleNotification(true);
  };

  if (!loaded) {
    return <CircularProgress size={50} color="secondary" />;
  }

  return (
    <FormProvider {...form}>
      <Box mb={2}>
        <Typography fontWeight={700}>
          {`Dotyczy dziedziny: ${kisDomain.name}`}
        </Typography>
        <InputLabel htmlFor="kisDomainAreas" sx={classes.label}>
          Szczegółowe obszary KIS w ramach których ekspert deklaruje kompetencje do oceny
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
            sx={{ mb: (t) => t.spacing(2) }}
          >
            <Controller
              name="kisDomainAreas"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  textFieldProps={{
                    error: !!error,
                    required: true,
                  }}
                  initialValue={value}
                  onBlur={onBlur}
                  multiple
                  renderSelectAllButton={false}
                  onChange={onChange}
                  options={kisDomainAreas}
                  error={error}
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.kisDomainAreas}
          </InfoPopover>
        </Box>
        {!isReadonly && (
          <Button
            id="YgTRZ4AWQr2Kxcc"
            variant="contained"
            color="secondary"
            onClick={addItem}
            sx={{ mb: (t) => t.spacing(2) }}
          >
            Dodaj obszar KIS
          </Button>
        )}
        {fields.map((item, index) => (
          <Grid item key={item.id} xs={10}>
            <Grid item display="flex" alignItems="center" mb={2}>
              <Grid item xs={3} mr={1}>
                <InputLabel
                  htmlFor={item.label}
                  variant="noShorten"
                  sx={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(1),
                    color: theme.palette.text.brand,
                    fontSize: '1rem',
                  }}
                >
                  {item.label}
                </InputLabel>
              </Grid>
              <Grid item xs={9}>
                <Box display="flex" mt={3} mb={1}>
                  <InputLabel
                    htmlFor={`keywordsBanks.${index}.keywords`}
                    variant="noShorten"
                    sx={{
                      color: theme.palette.text.brand,
                      fontSize: '1rem',
                    }}
                  >
                    Słowa kluczowe
                  </InputLabel>
                  <InfoPopover noBorder position="left">
                    {APPLICATION_CONTEXT_HELP_TEXT.keywordsBanks}
                  </InfoPopover>
                </Box>
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <Controller
                    rules={{
                      validate: {
                        atLeastFive: (keywordsBag) => keywordsBag?.length >= 5
                          || 'Dodaj przynajmniej 5 słów kluczowych. Kolejne słowa kluczowe'
                          + ' proszę oddzielać poprzez naciśnięcie przycisku ENTER.',
                      },
                      required: {
                        value: true,
                        message: 'Dodaj przynajmniej 5 słów kluczowych. Kolejne słowa kluczowe'
                          + ' proszę oddzielać poprzez naciśnięcie przycisku ENTER.',
                      },
                    }}
                    name={`keywordsBanks.${index}.keywords`}
                    control={control}
                    render={({
                      field: {
                        onChange, name, onBlur, value,
                      },
                      fieldState: {
                        error,
                      },
                    }) => (
                      <Autocomplete
                        id={name}
                        name={name}
                        multiple
                        options={[]}
                        freeSolo
                        onChange={(_, newValue) => {
                          onChange({ target: {
                            name,
                            value: newValue,
                          } });
                        }}
                        defaultValue={value}
                        initialValue={value}
                        onBlur={onBlur}
                        renderInput={(params) => (
                          <TextField
                            error={!!error}
                            helperText={error?.message || 'Proszę uzupełnić słowa kluczowe dla każdego obszaru KIS.'
                              + ' Kolejne słowa kluczowe proszę oddzielać poprzez naciśnięcie przycisku ENTER'}
                            variant="standard"
                            color="secondary"
                            defaultValue={value}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                {!isReadonly && (
                  <Button
                    id={uniqid()}
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon color="primary" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
        <InputLabel htmlFor="oecdScienceDomains" sx={classes.label}>
          Dziedzina nauki według klasyfikacji OECD
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="oecdScienceDomains"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  textFieldProps={{
                    error: !!error,
                    required: true,
                  }}
                  initialValue={value}
                  onBlur={onBlur}
                  multiple
                  renderSelectAllButton={false}
                  onChange={onChange}
                  options={oecdScienceDomains?.map(({
                    '@id': id, subcategory, subcategoryCode,
                  }) => ({
                    '@id': id,
                    name: `${subcategoryCode} ${subcategory}`,
                  }))}
                  error={error}
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.oecdScienceDomains}
          </InfoPopover>
        </Box>
        <InputLabel htmlFor="naceScienceDomains" sx={classes.label}>
          Dziedzina gospodarki według klasyfikacji NACE
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="naceScienceDomains"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  textFieldProps={{
                    error: !!error,
                    required: true,
                  }}
                  initialValue={value}
                  onBlur={onBlur}
                  multiple
                  renderSelectAllButton={false}
                  onChange={onChange}
                  options={naceScienceDomains
                    .map(({
                      '@id': id, subsection, subsectionCode,
                    }) => ({
                      '@id': id,
                      name: `${subsectionCode} ${subsection}`,
                    }))}
                  error={error}
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.naceScienceDomains}
          </InfoPopover>
        </Box>
        <InputLabel htmlFor="holdsMasterDegreeOrEquivalent" sx={classes.label}>
          Kandydat na eksperta posiada co najmniej wykształcenie wyższe, tytuł magistra lub równoważny
          w dziedzinie do oceny której aplikuje.
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="holdsMasterDegreeOrEquivalent"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalent}
          </InfoPopover>
        </Box>

        <ApplicationFormAttachmentsField
          infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalentAttachments}
          labelColor={theme.palette.text.brand}
          fieldName="holdsMasterDegreeOrEquivalentAttachments"
          attachmentsData={attachmentsData}
        />

        <Typography fontSize="1rem" fontWeight={600} mt={4}>
          Kandydat na eksperta posiada wiedzę i udokumentowane doświadczenie zawodowe,
          w dziedzinie do oceny której aplikuje:
        </Typography>
        <InputLabel htmlFor="threeYearsOfExperience" variant="noShorten" sx={classes.label}>
          Potwierdzenie przynajmniej trzyletniego doświadczenia zawodowego
          (niezależnie od formy zatrudnienia) w okresie ostatnich pięciu lat na stanowisku
          związanym z badaniami, rozwojem lub wdrożeniem
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="threeYearsOfExperience"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperience}
          </InfoPopover>
        </Box>

        <ApplicationFormAttachmentsField
          infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperienceAttachments}
          labelColor={theme.palette.text.brand}
          fieldName="threeYearsOfExperienceAttachments"
          attachmentsData={attachmentsData}
        />

        <Typography fontSize="1rem" fontWeight={600} mt={4}>
          Udokumentowanie przez kandydata na eksperta co najmniej dwóch z poniższych form aktywności
          zawodowej lub naukowej:
        </Typography>
        <InputLabel htmlFor="doctoralDegreeInDomain" variant="noShorten" sx={classes.label}>
          Uzyskanie co najmniej stopnia naukowego doktora w danej dziedzinie
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="doctoralDegreeInDomain"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.doctoralDegreeInDomain}
          </InfoPopover>
        </Box>

        {watch('doctoralDegreeInDomain') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.doctoralDegreeInDomainAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="doctoralDegreeInDomainAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <InputLabel htmlFor="scientificPublications" variant="noShorten" sx={classes.label}>
          Publikacje naukowe (indeksowane w Web of Science, Scopus lub
          ERIH PLUS - minimum jedna publikacja w ciągu ostatnich 3 lat)
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="scientificPublications"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.scientificPublications}
          </InfoPopover>
        </Box>

        <InputLabel htmlFor="memberOfCrucialResearchStaff" variant="noShorten" sx={classes.label}>
          Realizacja projektów B+R, jako co najmniej członek kluczowego personelu badawczego
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="memberOfCrucialResearchStaff"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.memberOfCrucialResearchStaff}
          </InfoPopover>
        </Box>

        {watch('memberOfCrucialResearchStaff') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.memberOfCrucialResearchStaffAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="memberOfCrucialResearchStaffAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <InputLabel htmlFor="patentsAsInventor" variant="noShorten" sx={classes.label}>
          Uzyskanie patentów jako zgłaszający lub twórca
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="patentsAsInventor"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.patentsAsInventor}
          </InfoPopover>
        </Box>

        <ApplicationFormAttachmentsField
          infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.patentsAsInventorAttachments}
          labelColor={theme.palette.text.brand}
          fieldName="patentsAsInventorAttachments"
          attachmentsData={attachmentsData}
        />

        <InputLabel htmlFor="implementationParticipant" variant="noShorten" sx={classes.label}>
          Udział we wdrożeniu i komercjalizacji wyników prac B+R dotyczących innowacyjnych produktów lub procesów
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="implementationParticipant"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.implementationParticipant}
          </InfoPopover>
        </Box>

        {watch('implementationParticipant') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.implementationParticipantAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="implementationParticipantAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <InputLabel htmlFor="qualifiedAsExpert" variant="noShorten" sx={classes.label}>
          Kandydat na eksperta posiada uprawnienia rzeczoznawcy lub jest wpisany na listę biegłych sądowych
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="qualifiedAsExpert"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.qualifiedAsExpert}
          </InfoPopover>
        </Box>

        <ApplicationFormAttachmentsField
          infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.qualifiedAsExpertAttachments}
          labelColor={theme.palette.text.brand}
          fieldName="qualifiedAsExpertAttachments"
          attachmentsData={attachmentsData}
        />

        {!isReadonly && (
          <Box mt={3}>
            <SaveCancelButtons
              saveButtonId="zvairA5eXhYP9dg"
              cancelButtonId="H5PHey9TKusNwRP"
              cancelHandler={isDirty ? () => setDialogOpened(true) : closeAll}
              saveHandler={handleSubmit(submitForm)}
            />
          </Box>
        )}
        {visibleNotification && (
          <Typography color="primary" align="left" aria-live="assertive" mt={3}>
            Wystąpił błąd podczas zapisu uzasadnienia branżowego
          </Typography>
        )}

        <ConfirmDialog
          open={dialogOpened}
          dialogId="8zD6QbOmuw9YgTq"
          dialogTitle="Formularz zawiera niezapisane dane"
          confirmButtonLabel="ok"
          closeHandler={() => setDialogOpened(false)}
          confirmButtonHandler={closeAll}
        >
          <Typography textAlign="center" fontSize="large">
            Formularz zawiera niezapisane dane. Czy chcesz zamknąć formularz i utracić dane?
          </Typography>
        </ConfirmDialog>
      </Box>
    </FormProvider>
  );
}

KisDomainJustificationForm.propTypes = {
  kisDomainAreas: PropTypes.arrayOf(Object).isRequired,
  oecdScienceDomains: PropTypes.arrayOf(Object).isRequired,
  naceScienceDomains: PropTypes.arrayOf(Object).isRequired,
  kisDomain: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  applicationFormId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  attachmentsData: PropTypes.instanceOf(Object),
};

KisDomainJustificationForm.defaultProps = {
  kisDomain: {},
  attachmentsData: null,
};
