import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import { useApplicationToPdfContext } from '../../../../../Context';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Ue Horizontal Policies Project Compliance Products Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {UeHorizontalPoliciesProjectComplianceProductsPdf}
 */
function UeHorizontalPoliciesProjectComplianceProductsPdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  const {
    application,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.name`}
            value={item.name}
            oldValue={previousRowData?.name}
          />
          <RowField
            name={`${collectionName}.${index}.impact`}
            value={item.impact}
            oldValue={previousRowData?.impact}
            dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectComplianceImpacts.name}
          />
          <RowField
            name={`${collectionName}.${index}.justification`}
            value={item.justification}
            oldValue={previousRowData?.justification}
            isSingleRow
          />
          <RowField
            name={`${collectionName}.${index}.module`}
            value={item.module}
            oldValue={previousRowData?.module}
            externalDictionary={modules}
          />
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default UeHorizontalPoliciesProjectComplianceProductsPdf;

UeHorizontalPoliciesProjectComplianceProductsPdf.propTypes = {
  collectionName: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

UeHorizontalPoliciesProjectComplianceProductsPdf.defaultProps = {
  previousRowData: {},
};
