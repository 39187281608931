import PropTypes from 'prop-types';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { useCustomSnackbar } from '../../_hooks';
import { FieldRow } from './FieldRow';

/**
 * List of applied fields
 *
 * @param {object} props - root props
 * @param {string} props.templateElementId - template element id
 * @param {object} props.fieldsData - fields data
 * @param {Function} props.reloadTemplateElement - reload handler
 * @param {Function} props.selectedFieldHandler - selected field handler
 * @param {Function} props.fieldsOrderSetter - fields order setter
 * @param {string} props.listTitle - title for list
 * @returns {FieldsList}
 */
export function FieldsList({
  templateElementId,
  fieldsData,
  reloadTemplateElement,
  selectedFieldHandler,
  fieldsOrderSetter,
  listTitle,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const [requestProgress, setRequestProgress] = useState(false);
  const [fieldsList, setFieldsList] = useState(fieldsData);

  useEffect(() => {
    setFieldsList(fieldsData);
  }, [fieldsData]);

  const makeFieldUpdateUrl = (fieldUuid) => API_ROUTE.editElementField
    .replace(':templateElementId', templateElementId)
    .replace(':fieldUuid', fieldUuid);

  const handleFieldPrintableCheck = async (event, fieldData) => {
    setRequestProgress(true);
    const payload = {
      properties: {},
    };

    const { checked } = event.target;

    payload.properties.printable = checked;

    const {
      uuid, title,
    } = fieldData;
    const { statusSuccess } = await request.put(
      makeFieldUpdateUrl(uuid),
      payload
    );

    if (statusSuccess) {
      // it does not refresh itself in <CollectionField>
      fieldData.printable = checked; // eslint-disable-line no-param-reassign
      reloadTemplateElement();
      setRequestProgress(false);
      checked
        ? successNotification(`Pole "${title}" zostało ustawione jako widoczne`)
        : errorNotification(`Pole "${title}" zostało ustawione jako niewidoczne`);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Handle dragging action.
   *
   * @param {object} event - draggable event
   */
  const handleDrag = (event) => {
    const {
      destination, source,
    } = event;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) {
      return;
    }

    const items = reorder(
      fieldsList,
      event.source.index,
      event.destination.index
    );

    setFieldsList(items);
  };

  useEffect(() => {
    fieldsOrderSetter(
      fieldsList.map((fieldData) => fieldData.uuid)
    );
  }, [fieldsList, fieldsOrderSetter]);

  const DraggableFields = () => useMemo(() => fieldsList.map((fieldData: Object, index: number) => (
    <FieldRow
      fieldData={fieldData}
      index={index}
      key={`field-row-${fieldData.uuid}`}
      selectedFieldHandler={selectedFieldHandler}
      printableCheckHandler={handleFieldPrintableCheck}
    />
  )), []);

  return (
    <TableContainer>
      <Typography variant="pageHeading" textAlign="center">
        {listTitle}
      </Typography>
      {requestProgress && (
        <LinearProgress color="secondary" />
      )}
      <Table aria-label="Lista zastosowanych pól">
        <TableHead>
          <TableRow>
            <TableCell scope="column">
              <Typography fontWeight="bold">
                Wyświetlaj
              </Typography>
            </TableCell>
            <TableCell scope="column">
              <Typography fontWeight="bold">
                Nazwa pola
              </Typography>
            </TableCell>
            <TableCell scope="column">
              <Typography fontWeight="bold">
                Etykieta pola
              </Typography>
            </TableCell>
            <TableCell scope="column">
              <Typography fontWeight="bold">
                Akcje
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="fields-list-droppable">
            {(provided) => (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <DraggableFields />
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}

FieldsList.propTypes = {
  templateElementId: PropTypes.string.isRequired,
  reloadTemplateElement: PropTypes.func.isRequired,
  fieldsData: PropTypes.arrayOf(Object).isRequired,
  selectedFieldHandler: PropTypes.func.isRequired,
  fieldsOrderSetter: PropTypes.func.isRequired,
  listTitle: PropTypes.string,
};

FieldsList.defaultProps = {
  listTitle: 'Lista zastosowanych pól',
};
