import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';
import { useApplicationToPdfContext } from '../../../../Context';
import RowConfig from '../../Components/RowConfig/RowConfig';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Funding Sources Pdf element.
 *
 * @returns {FundingSourcesPdf}
 */
function FundingSourcesPdf() {
  const {
    managedApplicationTemplate: {
      fieldsConfig,
    },
  } = useApplicationToPdfContext();

  const totalExpenses = fieldsConfig.funding_sources_total_expenses;
  const eligibleExpenses = fieldsConfig.funding_sources_eligible_expenses;

  const columnCount = [
    'label',
    fieldsConfig.funding_sources_total_expenses,
    fieldsConfig.funding_sources_eligible_expenses,
  ].filter((field) => field).length;

  const fieldsNames = [
    'funding_sources_co_financing_',
    'funding_sources_own_contribution_',
    'funding_sources_public_budget_',
    'funding_sources_local_government_budget_',
    'funding_sources_public_other_',
    'funding_sources_private_',
    'funding_sources_own_funds_',
    'funding_sources_credit_',
    'funding_sources_loan_',
  ];

  const labels = {
    funding_sources_co_financing_: 'Dofinansowanie',
    funding_sources_own_contribution_: 'Razem wkład własny',
    funding_sources_public_budget_: 'Budżet państwa',
    funding_sources_local_government_budget_: 'Budżet jednostek samorządu terytorialnego',
    funding_sources_public_other_: 'Inne publiczne',
    funding_sources_private_: 'Prywatne, w tym',
    funding_sources_own_funds_: 'Środki własne',
    funding_sources_credit_: 'Kredyt',
    funding_sources_loan_: 'Pożyczka',
  };

  return (
    <View style={printStyle.table}>
      <PrintoutTableRow>
        <PrintoutTableCell rowSpan={columnCount} bgColor="gray" />
        {totalExpenses && (
          <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
            {totalExpenses.label}
          </PrintoutTableCell>
        )}
        {eligibleExpenses && (
          <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
            {eligibleExpenses.label}
          </PrintoutTableCell>
        )}
      </PrintoutTableRow>
      {fieldsNames.map((fieldName) => {
        const isVisibleRow = (fieldsConfig[`${fieldName}total`] || fieldsConfig[`${fieldName}eligible`]);

        if (!isVisibleRow) {
          return '';
        }

        return (
          <PrintoutTableRow key={fieldName}>
            <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
              {labels[fieldName]}
            </PrintoutTableCell>
            <RowConfig name={`${fieldName}total`}>
              {({ fieldValue }) => (
                <PrintoutTableCell rowSpan={columnCount}>
                  {fieldValue}
                </PrintoutTableCell>
              )}
            </RowConfig>
            <RowConfig name={`${fieldName}eligible`}>
              {({ fieldValue }) => (
                <PrintoutTableCell rowSpan={columnCount}>
                  {fieldValue}
                </PrintoutTableCell>
              )}
            </RowConfig>
          </PrintoutTableRow>
        );
      })}

      <RowConfig name="funding_sources_others">
        {({
          fieldValue, label,
        }) => (
          <>
            {fieldValue?.length !== 0 && (
              <PrintoutTableRow>
                <PrintoutTableCell bgColor="gray" rowSpan={1}>
                  {label}
                </PrintoutTableCell>
              </PrintoutTableRow>
            )}
            <CollectionWrapper name="funding_sources_others">
              {({
                collectionData, collectionName, collectionLabel, previousCollectionData, getIndexForPreviousRowData,
              }) => collectionData.map((item, index) => {
                const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

                return (
                  <SingleCollectionWrapper
                    key={item.set_uuid}
                    index={index}
                    label={collectionLabel}
                  >
                    <View style={printStyle.table}>
                      <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                        <RowField
                          name={`${collectionName}.${index}.name`}
                          value={item.name}
                          oldValue={previousRowData?.name}
                        />
                        <RowField
                          name={`${collectionName}.${index}.total`}
                          value={item.total}
                          oldValue={previousRowData?.total}
                        />
                        <RowField
                          name={`${collectionName}.${index}.eligible`}
                          value={item.eligible}
                          oldValue={previousRowData?.eligible}
                        />
                      </FieldSorter>
                    </View>
                  </SingleCollectionWrapper>
                );
              })}
            </CollectionWrapper>
          </>
        )}
      </RowConfig>

      <PrintoutTableRow>
        <RowConfig name="funding_sources_sum_total">
          {({ label }) => (
            <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
              {label}
            </PrintoutTableCell>
          )}
        </RowConfig>
        <RowConfig name="funding_sources_sum_total">
          {({ fieldValue }) => (
            <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
              {fieldValue}
            </PrintoutTableCell>
          )}
        </RowConfig>
        <RowConfig name="funding_sources_sum_eligible">
          {({ fieldValue }) => (
            <PrintoutTableCell rowSpan={columnCount} bgColor="gray">
              {fieldValue}
            </PrintoutTableCell>
          )}
        </RowConfig>
      </PrintoutTableRow>
      <RowField name="funding_sources_applicant_contribution_provision_justification" isSingleRow />
    </View>
  );
}

export default FundingSourcesPdf;
