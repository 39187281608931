import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import ActualExpensesForm from './ActualExpenses.form';
import FlatRateExpensesForm from './FlatRateExpenses.form';
import {
  API_ROUTE,
  EXPENSES_TYPE,
  EXPENSES_TYPE_LIST,
} from '../../../../../_constants';
import { Alert } from '../../../../Alert';
import { useElementContext } from '../../../../../Context';
import { iriToId } from '../../../../../_helpers';
import { useDictionaryLoader } from '../../../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';
import { CustomAutocomplete } from '../../../../CustomAutocomplete/CustomAutocomplete';
import { theme } from '../../../../../styles/theme';

/**
 * Expenses dialog
 *
 * @param {object} props - root props
 * @param {Function} props.handleClose - handle close dialog
 * @param {boolean} props.isOpen - dialog is open
 * @param {string} props.taskName - task name
 * @param {string} props.itemIndex - expenses index
 * @param {string | null} props.expenseType - type expense
 * @param {string} props.taskId - task id
 * @param {boolean} props.indirectCosts - is indirect costs
 * @param {string} props.taskType - task type name
 * @param {Function} props.reloadExpensesData - reload optional expenses data from API
 * @param {number} props.expensesDataLength - expenses data length
 * @returns {ExpensesDialog}
 */
function ExpensesDialog({
  handleClose,
  isOpen,
  taskName,
  itemIndex,
  expenseType,
  taskId,
  indirectCosts,
  taskType,
  reloadExpensesData,
  expensesDataLength,
}) {
  const [expensesTypeValue, setExpensesTypeValue] = useState(expenseType);

  const { control } = useFormContext();
  const {
    id: elementId, currentElementFieldsConfig, fieldsConfig, config: { elementData },
  } = useElementContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    {
      name: 'predefinedTaskForElement',
      path: `${API_ROUTE.predefinedTask}?templateElement.id=${elementId}`,
    }
  );

  const materialAndFinancialScheduleConfig = fieldsConfig[`tasks-${elementId}`];
  const subfields = materialAndFinancialScheduleConfig?.fields || {};

  useEffect(() => {
    get('predefinedTaskForElement');
  }, []);

  const predefinedTaskData = subfields?.predefined_task
    ? get('predefinedTaskForElement').find((singleTask) => singleTask.name === taskName)
    : null;

  const isPredefinedTaskAllowActualExpenses = predefinedTaskData ? predefinedTaskData.allowingActualExpenses : true;
  const isPredefinedTaskAllowLumpSumExpenses = predefinedTaskData ? predefinedTaskData.allowingLumpSumExpenses : true;
  const isIndirectCosts = (predefinedTaskData && predefinedTaskData?.indirectCosts) || indirectCosts;

  const isModuleRd = elementData.modules.some((module) => iriToId(module) === '91e674cd-e26a-49a2-82f1-f58cff06693b');

  const actualExpensesFields = useFieldArray({
    control,
    name: `actual_expenses-${elementId}`,
  });

  const lumpSumExpensesFields = useFieldArray({
    control,
    name: `lump_sum_expenses-${elementId}`,
  });

  const actualExpensesConfig = fieldsConfig[`actual_expenses-${elementId}`];
  const actualExpensesSubfields = actualExpensesConfig?.fields || {};
  const actualExpensesFieldObject = actualExpensesSubfields
    ? Object.keys(actualExpensesSubfields).reduce((prev, key) => ({
      ...prev,
      [key]: '',
    }), {})
    : {};

  const lumpSumExpensesConfig = fieldsConfig[`actual_expenses-${elementId}`];
  const lumpSumExpensesSubfields = lumpSumExpensesConfig?.fields || {};
  const lumpSumExpensesFieldObject = lumpSumExpensesSubfields
    ? Object.keys(lumpSumExpensesSubfields).reduce((prev, key) => ({
      ...prev,
      [key]: '',
    }), {})
    : {};

  const isActualExpenses = expensesTypeValue === EXPENSES_TYPE.actualExpenses;
  const isFlatRateExpenses = expensesTypeValue === EXPENSES_TYPE.flatRateExpenses;
  const index = itemIndex || (isActualExpenses
    ? actualExpensesFields.fields.length - 1
    : lumpSumExpensesFields.fields.length - 1);

  const handleAddItem = () => {
    if (isActualExpenses) {
      actualExpensesFieldObject.task_id = taskId;
      actualExpensesFields.append(actualExpensesFieldObject);
    }
    if (isFlatRateExpenses) {
      lumpSumExpensesFieldObject.task_id = taskId;
      lumpSumExpensesFields.append(lumpSumExpensesFieldObject);
    }
  };

  const actualExpensesExists = !!currentElementFieldsConfig?.find(
    (element) => element.name === 'actual_expenses'
  )?.name;

  const lumpSumExpensesExists = !!currentElementFieldsConfig?.find(
    (element) => element.name === 'lump_sum_expenses'
  )?.name;

  // eslint-disable-next-line array-callback-return,consistent-return
  const filterExpensesType = () => EXPENSES_TYPE_LIST.filter((type) => {
    if (
      actualExpensesExists
      && type.name === EXPENSES_TYPE.actualExpenses
      && !isIndirectCosts
      && isPredefinedTaskAllowActualExpenses
    ) {
      return type;
    }

    if (
      lumpSumExpensesExists
      && type.name === EXPENSES_TYPE.flatRateExpenses
      && !(isModuleRd && !isIndirectCosts)
      && isPredefinedTaskAllowLumpSumExpenses
    ) {
      return type;
    }
  });

  const handleExpensesType = (selectedValue) => {
    setExpensesTypeValue((prevState) => {
      if (prevState === EXPENSES_TYPE.actualExpenses) {
        actualExpensesFields.remove(index);
      }

      if (prevState === EXPENSES_TYPE.flatRateExpenses) {
        lumpSumExpensesFields.remove(index);
      }

      return selectedValue || '';
    });
  };

  const closeModalAndRemoveItem = (removeExpenses = false) => {
    handleClose();

    if (!itemIndex && removeExpenses) {
      if (isActualExpenses) {
        actualExpensesFields.remove(index);
      }

      if (isFlatRateExpenses) {
        lumpSumExpensesFields.remove(index);
      }
    }
  };

  useEffect(() => {
    if ((isActualExpenses || isFlatRateExpenses) && !expenseType) {
      handleAddItem();
    }
  }, [expensesTypeValue]);

  const getDialogContent = () => (
    <Grid container spacing={0.5} display={isLoaded ? 'block' : 'none'} alignItems="center">
      <Grid item xs={12}>
        <Typography>
          Zadanie:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="status" sx={{ margin: 0 }}>
          {taskName}
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          htmlFor="aNJl5GdoPTwp6xl"
          sx={{
            color: theme.palette.text.main,
            fontSize: '1rem',
          }}
        >
          Rodzaj wydatków:
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <CustomAutocomplete
            id="aNJl5GdoPTwp6xl"
            options={filterExpensesType()}
            optionsMapKeys={['name', 'label']}
            initialValue={expensesTypeValue}
            onChange={(event) => handleExpensesType(event.target.value)}
            disabled={expenseType}
          />
        </FormControl>
      </Grid>
      {isActualExpenses && (
        <ActualExpensesForm
          handleClose={closeModalAndRemoveItem}
          itemIndex={index.toString()}
          reloadData={reloadExpensesData}
          taskType={taskType}
          indirectCosts={isIndirectCosts}
          taskId={taskId}
        />
      )}
      {isFlatRateExpenses && (
        <FlatRateExpensesForm
          handleClose={closeModalAndRemoveItem}
          itemIndex={index.toString()}
          reloadData={reloadExpensesData}
          taskType={taskType}
          indirectCosts={isIndirectCosts}
          taskId={taskId}
        />
      )}
    </Grid>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeModalAndRemoveItem(true)}
      fullWidth
      maxWidth="md"
      variant="outlined"
      color="secondary"
    >
      <DialogTitle component="div">
        <Typography variant="dialogHeading">
          {expenseType ? 'Edytuj wydatek' : 'Dodaj wydatek'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!expenseType && expensesDataLength >= 100 && (
          <Typography textAlign="center">
            Osiągnięto maksymalny limit (100) dodanych wydatków w ramach zadania.
          </Typography>
        )}
        {!expenseType
          ? (expensesDataLength < 100 && !isLoaded && <ContainerLoader />)
          : (!isLoaded && <ContainerLoader />)}
        {!expenseType
          ? (expensesDataLength < 100 && getDialogContent())
          : getDialogContent()}
      </DialogContent>
    </Dialog>
  );
}

export default ExpensesDialog;

ExpensesDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  taskName: PropTypes.string.isRequired,
  itemIndex: PropTypes.string,
  expenseType: PropTypes.oneOf(['actualExpenses', 'flatRateExpenses', null]),
  taskId: PropTypes.string,
  indirectCosts: PropTypes.bool,
  taskType: PropTypes.string,
  reloadExpensesData: PropTypes.func,
  expensesDataLength: PropTypes.number.isRequired,
};

ExpensesDialog.defaultProps = {
  itemIndex: '',
  expenseType: null,
  taskId: '',
  taskType: '',
  indirectCosts: false,
  reloadExpensesData: () => { },
};
