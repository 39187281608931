import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import GreeningEnvironmentalProtectionAidTypePdf
  from './GreeningEnvironmentalProtectionAid/GreeningEnvironmentalProtectionAidType.pdf';
import { useApplicationToPdfContext } from '../../../../Context';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * GreeningEnvironmentalProtectionAidPdf element.
 *
 * @returns {GreeningEnvironmentalProtectionAidPdf}
 */
function GreeningEnvironmentalProtectionAidPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const greeningEnvironmentalProtectionAidEnvironmentalProtectionValue = initialFormData
    ?.greening_environmental_protection_aid_environmental_protection;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="greening_environmental_protection_aid_environmental_protection" />
        {greeningEnvironmentalProtectionAidEnvironmentalProtectionValue === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="greening_environmental_protection_aid_aid_type">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <GreeningEnvironmentalProtectionAidTypePdf
                  collectionName={collectionName}
                  item={item}
                  index={index}
                  previousRowData={previousRowData}
                  collectionData={collectionData}
                />
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default GreeningEnvironmentalProtectionAidPdf;
