import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import React from 'react';
import {
  PROFILES,
  ROLES,
  URL,
} from '../../_constants';
import { useAuth } from '../../_security';

/**
 * Conditional force redirect to setttings page.
 *
 * Users who never changed their profile (first time login) will be redirected to the settings page.
 * Employees will not be redirected.
 *
 * @returns {NeedToAcceptRedirect} redirect component
 */
export function NeedToAcceptRedirect() {
  const {
    authenticated, profile, profileSwitching, roles,
  } = useAuth();
  const location = useLocation();

  if (
    authenticated
    && !roles.includes(ROLES.ROLE_EMPLOYEE)
    && profileSwitching.length === 0
    && profile !== PROFILES.employee
    && location.pathname !== URL.users.account.settings
  ) {
    return (
      <Navigate to={{
        pathname: URL.users.account.settings,
        hash: '#SjsoVqEQS4rG7Nl',
        state: { from: '/' },
      }}
      />
    );
  }

  return '';
}
