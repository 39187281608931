import {
  useFormContext,
  Controller,
} from 'react-hook-form';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { TitledContainer } from '../../TitledContainer';
import { AttachmentsBox } from '../../FileUpload';
import {
  API_ROUTE,
  ALLOWED_EXTENSIONS,
} from '../../../_constants';
import { request } from '../../../_services';
import {
  useExpertApplicationFormContext,
  useGlobalContext,
} from '../../../Context';

/**
 * Expert attachments
 *
 * @returns {AttachmentForm}
 */
export function AttachmentForm() {
  const {
    control, getValues,
  } = useFormContext();
  const {
    applicationFormData, isReadonly, reloadData,
  } = useExpertApplicationFormContext();
  const { notify } = useGlobalContext();

  const onSubmit = async () => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.applicationForms}/${applicationFormData.id}`, {
      attachments: getValues('attachments'),
      applicationStatementAttachments: getValues('applicationStatementAttachments'),
      trainingCertificatesCbaAttachments: getValues('trainingCertificatesCbaAttachments'),
    });

    window.scroll(0, 0);
    if (statusSuccess) {
      reloadData();
      notify('Lista załączników została zmieniona.', 'success');

      return;
    }

    notify('Wystąpił błąd podczas dodawania załączników', 'error');
  };

  useEffect(() => () => {
    if (!isReadonly) {
      onSubmit();
    }
  }, []);

  return [
    <TitledContainer title="Załączniki do wniosku" key="expert-attachments" variant="slim" textAlign="left">
      <Controller
        control={control}
        name="attachments"
        render={({
          field: {
            value, name, onChange,
          },
        }) => (
          <AttachmentsBox
            id={name}
            maxAttachments={100}
            maxSize={15000000}
            allowedExtensions={ALLOWED_EXTENSIONS}
            uploadedFilesCallback={(uploadedFiles) => {
              onChange({
                target: {
                  name,
                  value: uploadedFiles.map(({ iri }) => iri),
                },
              });
            }}
            existingFiles={value}
            fetchUrl={`${API_ROUTE.applicationForms}/${applicationFormData.id}/attachments?itemsPerPage=100`}
            isReadonly={isReadonly}
            apiUrl={API_ROUTE.attachmentsAuth}
          />
        )}
      />
    </TitledContainer>,
    <TitledContainer
      title="Oświadczenia do wniosku aplikacyjnego"
      key="expert-statements-attachment"
      variant="slim"
      textAlign="left"
    >
      <Controller
        control={control}
        name="applicationStatementAttachments"
        render={({
          field: {
            value, name, onChange,
          },
        }) => (
          <AttachmentsBox
            id={name}
            maxAttachments={3}
            maxSize={15000000}
            allowedExtensions={ALLOWED_EXTENSIONS}
            uploadedFilesCallback={(uploadedFiles) => {
              onChange({
                target: {
                  name,
                  value: uploadedFiles.map(({ iri }) => iri),
                },
              });
            }}
            existingFiles={value}
            fetchUrl={`${API_ROUTE.applicationForms}/${applicationFormData.id}/application-statement-attachments`}
            isReadonly={isReadonly}
            apiUrl={API_ROUTE.attachmentsAuth}
          />
        )}
      />
    </TitledContainer>,
    <TitledContainer
      title="Certyfikaty ukończenia szkoleń CBA: Korupcja w administracji
        publicznej, Korupcja w biznesie, Przeciwdziałanie korupcji"
      key="training-certificates-cba-attachments"
      variant="slim"
      textAlign="left"
    >
      <Controller
        control={control}
        name="trainingCertificatesCbaAttachments"
        render={({
          field: {
            value, name, onChange,
          },
        }) => (
          <AttachmentsBox
            id={name}
            maxAttachments={3}
            maxSize={15000000}
            allowedExtensions={ALLOWED_EXTENSIONS}
            uploadedFilesCallback={(uploadedFiles) => {
              onChange({
                target: {
                  name,
                  value: uploadedFiles.map(({ iri }) => iri),
                },
              });
            }}
            existingFiles={value}
            fetchUrl={`${API_ROUTE.applicationForms}/${applicationFormData.id}/training-certificates-cba-attachments`}
            isReadonly={isReadonly}
            apiUrl={API_ROUTE.attachmentsAuth}
          />
        )}
      />
    </TitledContainer>,
    <>
      {!isReadonly && (
        <Button
          id="7GaRCygCmUrabaG"
          sx={{
            marginRight: 'auto',
            display: 'block',
          }}
          variant="contained"
          color="secondary"
          onClick={onSubmit}
        >
          Zapisz
        </Button>
      )}
    </>,
  ];
}
