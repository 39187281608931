import RowConfig from '../../Components/RowConfig/RowConfig';
import ProjectBudgetTablePdf from './ProjectBudget/ProjectBudgetTable.pdf';
import ProjectBudgetByImplementerTablePdf from './ProjectBudget/ProjectBudgetByImplementerTable.pdf';

/**
 * ProjectBudgetPdf element.
 *
 * @returns {ProjectBudgetPdf}
 */
function ProjectBudgetPdf() {
  return (
    <>
      <RowConfig name="project_budget_by_module">
        {({
          fieldValue, title, label, name,
        }) => (
          <ProjectBudgetTablePdf label={label || title} data={fieldValue || []} fieldName={name} />
        )}
      </RowConfig>
      <RowConfig name="project_budget_by_aid_type">
        {({
          fieldValue, title, label, name,
        }) => (
          <ProjectBudgetTablePdf label={label || title} data={fieldValue || []} fieldName={name} />
        )}
      </RowConfig>
      <RowConfig name="project_budget_by_task">
        {({
          fieldValue, title, label, name,
        }) => (
          <ProjectBudgetTablePdf label={label || title} data={fieldValue || []} fieldName={name} />
        )}
      </RowConfig>
      <RowConfig name="project_budget_by_implementer">
        {({
          fieldValue, title, label, name,
        }) => (
          <ProjectBudgetByImplementerTablePdf label={label || title} data={fieldValue || []} fieldName={name} />
        )}
      </RowConfig>
    </>
  );
}

export default ProjectBudgetPdf;
