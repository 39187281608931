import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import ImplementersPartnersOfCivilPartnershipPdf from './Implementers/ImplementersPartnersOfCivilPartnership.pdf';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * ImplementersPdf element.
 *
 * @returns {ImplementersPdf}
 */
function ImplementersPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    }, getDictionary,
  } = useApplicationToPdfContext();

  const { vatRecoveryPossibility } = ELEMENTS_DICTIONARIES_MAP;

  const hasImplementers = initialFormData?.has_implementers === BOOLEAN_VALUES.TRUE;

  return (
    <>
      <View style={printStyle.table}>
        <RowField name="has_implementers" />
      </View>
      {hasImplementers && (
        <CollectionWrapper name="implementers">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <RowField
                    name={`${collectionName}.${index}.name`}
                    value={item.name}
                    oldValue={previousRowData?.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.legal_form`}
                    value={item.legal_form}
                    oldValue={previousRowData?.legal_form}
                    dictionaryName={DICTIONARIES.legalEntityTypes.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.implementer_type`}
                    value={item.implementer_type}
                    oldValue={previousRowData?.implementer_type}
                    dictionaryName={DICTIONARIES.implementerTypes.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.email`}
                    value={item.email}
                    oldValue={previousRowData?.email}
                  />
                  <RowField
                    name={`${collectionName}.${index}.phone_number`}
                    value={item.phone_number}
                    oldValue={previousRowData?.phone_number}
                  />
                  <RowField
                    name={`${collectionName}.${index}.city`}
                    value={item.city?.label}
                    oldValue={previousRowData?.city?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.company_size`}
                    value={item.company_size}
                    oldValue={previousRowData?.company_size}
                    dictionaryName={DICTIONARIES.companySize.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.property_form`}
                    value={item.property_form}
                    oldValue={previousRowData?.property_form}
                    dictionaryName={DICTIONARIES.propertyForms.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.vat_recovery_possibility`}
                    value={item.vat_recovery_possibility}
                    oldValue={previousRowData?.vat_recovery_possibility}
                    dictionaryName={DICTIONARIES.vatRecoveryPossibilities.name}
                  />
                  {(item.vat_recovery_possibility === vatRecoveryPossibility.partially.id
                  || item.vat_recovery_possibility === vatRecoveryPossibility.no.id) && (
                    <RowField
                      name={`${collectionName}.${index}.implementers_justification_of_the_inability_to_recover_vat`}
                      value={item.implementers_justification_of_the_inability_to_recover_vat}
                      oldValue={previousRowData?.implementers_justification_of_the_inability_to_recover_vat}
                    />
                  )}
                  <RowField
                    name={`${collectionName}.${index}.country`}
                    value={item.country?.label}
                    oldValue={previousRowData?.country?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.voivodeship`}
                    value={item.voivodeship?.label}
                    oldValue={previousRowData?.voivodeship?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.district`}
                    value={item.district?.label}
                    oldValue={previousRowData?.district?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.commune`}
                    value={item.commune?.label}
                    oldValue={previousRowData?.commune?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.street`}
                    value={item.street?.label}
                    oldValue={previousRowData?.street?.label}
                  />
                  <RowField
                    name={`${collectionName}.${index}.house_number`}
                    value={item.house_number}
                    oldValue={previousRowData?.house_number}
                  />
                  <RowField
                    name={`${collectionName}.${index}.apartment_number`}
                    value={item.apartment_number}
                    oldValue={previousRowData?.apartment_number}
                  />
                  <RowField
                    name={`${collectionName}.${index}.postal_code`}
                    value={item.postal_code}
                    oldValue={previousRowData?.postal_code}
                  />
                  <RowField
                    name={`${collectionName}.${index}.web_page`}
                    value={item.web_page}
                    oldValue={previousRowData?.web_page}
                  />
                  <RowField
                    name={`${collectionName}.${index}.nip`}
                    value={item.nip}
                    oldValue={previousRowData?.nip}
                  />
                  <RowField
                    name={`${collectionName}.${index}.regon`}
                    value={item.regon}
                    oldValue={previousRowData?.regon}
                  />
                  <RowField
                    name={`${collectionName}.${index}.krs_number`}
                    value={item.krs_number}
                    oldValue={previousRowData?.krs_number}
                  />
                  <RowField
                    name={`${collectionName}.${index}.pesel`}
                    value={item.pesel}
                    oldValue={previousRowData?.pesel}
                  />
                  <RowField
                    name={`${collectionName}.${index}.foreign_number`}
                    value={item.foreign_number}
                    oldValue={previousRowData?.foreign_number}
                  />
                  <RowField
                    name={`${collectionName}.${index}.implementers_date_of_signing_the_agreement`}
                    value={item.implementers_date_of_signing_the_agreement}
                    oldValue={previousRowData?.implementers_date_of_signing_the_agreement}
                  />
                  <ImplementersPartnersOfCivilPartnershipPdf
                    fieldName={`${collectionName}.${index}.implementers_partners_of_a_civil_partnership`}
                  />
                  <RowField
                    name={`${collectionName}.${index}.project_partner_characteristics`}
                    value={item.project_partner_characteristics}
                    oldValue={previousRowData?.project_partner_characteristics}
                    isSingleRow
                  />
                  <RowField
                    name={`${collectionName}.${index}.innovation_center_type`}
                    value={item.innovation_center_type}
                    oldValue={previousRowData?.innovation_center_type}
                    dictionaryName={DICTIONARIES.innovationCenterType.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.justification_for_meeting_definition`}
                    value={item.justification_for_meeting_definition}
                    oldValue={previousRowData?.justification_for_meeting_definition}
                    isSingleRow
                  />
                  <RowField
                    name={`${collectionName}.${index}.partner_selection_method`}
                    value={item.partner_selection_method}
                    oldValue={previousRowData?.partner_selection_method}
                    isSingleRow
                  />
                  <RowField
                    name={`${collectionName}.${index}.partner_business_profile`}
                    value={item.partner_business_profile}
                    oldValue={previousRowData?.partner_business_profile}
                  />
                  <RowField
                    name={`${collectionName}.${index}.start_date`}
                    value={item.start_date}
                    oldValue={previousRowData?.start_date}
                  />
                  <RowField
                    name={`${collectionName}.${index}.pkd`}
                    value={item.pkd}
                    oldValue={previousRowData?.pkd}
                    externalDictionary={getDictionary(DICTIONARIES.pkdCodes.name).map((pkdCode) => ({
                      '@id': pkdCode['@id'],
                      name: `${pkdCode.code} ${pkdCode.name}`,
                    }))}
                  />
                  <RowField
                    name={`${collectionName}.${index}.implementers_role_of_the entity_in_the_implementation_of_edih_services`}
                    value={item['implementers_role_of_the entity_in_the_implementation_of_edih_services']}
                  />

                  {/* Opis wcześniej nawiązanej relacji krajowego partnera z Wnioskodawcą */}
                  <RowField
                    name={`${collectionName}.${index}.description_relationship_national_partner_with_applicant`}
                    value={item.description_relationship_national_partner_with_applicant}
                    oldValue={previousRowData?.description_relationship_national_partner_with_applicant}
                  />
                  {/* Źródła finansowania przedsięwzięcia zrealizowanego przez krajowego partnera z
               wspólnie z Wnioskodawcą */}
                  <RowField
                    name={`${collectionName}.${index}.project_financing_source`}
                    value={item.project_financing_source}
                    oldValue={previousRowData?.project_financing_source}
                    dictionaryName={DICTIONARIES.projectFinancingSources.name}
                  />
                  {/* Opis działalności krajowego partnera w kontekście planowanej współpracy w ramach programu
              akceleracji */}
                  <RowField
                    name={`${collectionName}.${index}.description_national_partner_activities`}
                    value={item.description_national_partner_activities}
                    oldValue={previousRowData?.description_national_partner_activities}
                    isSingleRow
                  />
                  {/* Informacje na temat przewidywanej formy i zakresu zaangażowania krajowego partnera w program
              akceleracyjny w konkretnych działaniach */}
                  <RowField
                    name={`${collectionName}.${index}.information_on_involvement_national_partner`}
                    value={item.information_on_involvement_national_partner}
                    oldValue={previousRowData?.information_on_involvement_national_partner}
                  />
                  {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację od partnera zawierającą
              oświadczenie o gotowości współfinansowania kosztów operacyjnych akceleratora nie podlegających
              dofinansowaniu w projekcie? */}
                  <RowField
                    name={`${collectionName}.${index}.statement_on_willingness_co_finance_operating_costs`}
                    value={item.statement_on_willingness_co_finance_operating_costs}
                    oldValue={previousRowData?.statement_on_willingness_co_finance_operating_costs}
                  />
                  {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację krajowego partnera o zaangażowaniu
              w projekt i możliwości walidacji rozwiązań startupów (dotyczy partnera, który został wskazany jako
              Odbiorca Technologii w ścieżce Akceleracja branżowa startupów z partnerem biznesowym lub publicznym
              (B2B/B2A)? */}
                  <RowField
                    name={`${collectionName}.${index}.commitment_to_project_and_ability_to_validate_startup_solutions`}
                    value={item.commitment_to_project_and_ability_to_validate_startup_solutions}
                    oldValue={previousRowData?.commitment_to_project_and_ability_to_validate_startup_solutions}
                  />
                  {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację krajowego partnera o zaangażowaniu w
              projekt i możliwości weryfikacji gotowości inwestycyjnej startupów (dotyczy partnera, który został
              wskazany jako Inwestor w ścieżce Akceleracja startupów z funduszem VC (inwestorska)? */}
                  <RowField
                    name={`${collectionName}.${index}.commitment_to_project_and_opportunities_to_verify_investment_readiness_startups`}
                    value={item.commitment_to_project_and_opportunities_to_verify_investment_readiness_startups}
                    oldValue={previousRowData
                      ?.commitment_to_project_and_opportunities_to_verify_investment_readiness_startups}
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      )}
    </>
  );
}

export default ImplementersPdf;
