import {
  PROTEST_UNCONSIDERED_REASONS,
  PROTEST_UNCONSIDERED_REASONS_TRANSLATION,
} from '../_constants';
/**
 * Protest protest unconsidered reasons values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const protestUnconsideredReasonsOptions = [
  {
    id: PROTEST_UNCONSIDERED_REASONS.withdrawnByApplicant,
    label: PROTEST_UNCONSIDERED_REASONS_TRANSLATION.withdrawn_by_applicant,
  },
  {
    id: PROTEST_UNCONSIDERED_REASONS.negative,
    label: PROTEST_UNCONSIDERED_REASONS_TRANSLATION.negative,
  },
  {
    id: PROTEST_UNCONSIDERED_REASONS.noFunds,
    label: PROTEST_UNCONSIDERED_REASONS_TRANSLATION.no_funds,
  },
];
