import {
  Button,
  Divider,
  Box,
} from '@mui/material';
import {
  useExpertPersonalDataContext,
  useGlobalDialog,
} from '../../../Context';
import { TitledContainer } from '../../TitledContainer';
import { CareersList } from './Careers.list';
import { CareerForm } from './Career.form';

/**
 * Expert personal data career.
 *
 * @returns {Career}
 */
export function Career() {
  const {
    reloadData, isReadonly,
  } = useExpertPersonalDataContext();
  const { render } = useGlobalDialog();

  return (
    <TitledContainer
      title="Przebieg pracy zawodowej"
      key="career-container"
      variant="slim"
    >
      {!isReadonly && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            color="secondary"
            variant="contained"
            id="XwUgSv5xuyVKZaP"
            onClick={() => render(
              <CareerForm
                reloadData={reloadData}
                isReadonly={isReadonly}
              />,
              'Dodaj miejsce pracy',
            )}
          >
            Dodaj miejsce pracy
          </Button>
        </Box>
      )}
      <Divider sx={{ my: (theme) => theme.spacing(2) }} />
      <CareersList />
    </TitledContainer>
  );
}
