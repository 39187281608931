import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  useEffect,
  useState,
} from 'react';
import { FormField } from '../../Application/Application/FormField';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  getCollectionFieldObject,
  iriToId,
  addItem,
  removeItem,
  getOwnIndicators,
} from '../../../_helpers';
import { request } from '../../../_services';
import {
  API_ROUTE,
  DICTIONARIES,
  MODULE_IRIS,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * RdModuleEssence component
 *
 * @returns {RdModuleEssence}
 */
function RdModuleEssence() {
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isAttachmentsLoaded, setAttachmentsLoaded] = useState(false);
  const { id: applicationId } = useApplicationContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    control, getValues, watch, setValue, resetField,
  } = useFormContext();
  const {
    managedApplicationTemplate: {
      elementsConfig,
    },
  } = useApplicationContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.workTypes, DICTIONARIES.innovationTypes);

  const { innovationTypes } = ELEMENTS_DICTIONARIES_MAP;

  const collectionFieldName = 'rd_module_essence_novelty_of_results';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const rdModuleEssenceNoveltyResultsConfig = fieldsConfig[collectionFieldName];
  const subfields = rdModuleEssenceNoveltyResultsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const attachmentFieldsName = Object
    .values(fieldsConfig)
    .filter((fieldConfig) => fieldConfig.type === 'attachments')
    .map((attachment) => attachment.name);

  const getAttachments = async () => {
    const combinedAttachments = [];

    attachmentFieldsName.forEach((attachmentFieldName) => {
      if (getValues(attachmentFieldName)) {
        combinedAttachments.push(...getValues(attachmentFieldName));
      }
    });

    const requestQuery = `?id[]=${combinedAttachments
      .filter((element) => !!element)
      .map((iri) => iriToId(iri)).join('&id[]=')}`;

    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.applications}/${applicationId}/attachments${requestQuery}`);

    if (statusSuccess) {
      setAttachmentsData(payload.sort((a, b) => a.description.localeCompare(b.description)));
    }
    setAttachmentsLoaded(true);
  };

  const {
    maxRowNumber,
    minRowNumber,
  } = rdModuleEssenceNoveltyResultsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const noOptionsText = 'Przed wypełnieniem pola, dodaj w elemencie „Wskaźniki dot. modułu B+R” wskaźniki własne';

  useEffect(() => {
    getAttachments();
  }, []);

  if (!isAttachmentsLoaded && !isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <FormField name="rd_module_essence_work_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.workTypes.name))}
            disabled={isReadonly}
            multiple
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_module_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_rd_issue">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_rd_issue_attachments">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={attachmentsData}
            optionsMapKeys={['@id', 'description']}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_reaserch_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_reaserch_method_attachments">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={attachmentsData}
            optionsMapKeys={['@id', 'description']}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <FormField name="rd_module_essence_innovation_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            multiple
            renderSelectAllButton={false}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.innovationTypes.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <LabeledFieldRow fieldConfig={rdModuleEssenceNoveltyResultsConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionFieldName,
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            <FormField name={`${collectionFieldName}.${index}.innovation_type`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={(event) => {
                    onChange(event);
                    if (event.target.value !== innovationTypes.productInnovation.id) {
                      resetField(`${collectionFieldName}.${index}.product_feature_for_customers`, { defaultValue: FIELD_TYPES_MAP.text });
                    }
                  }}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.innovationTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.product_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.is_product_implemented`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.novelty_level`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis innowacji produktowej / innowacji w procesie biznesowym */}
            <FormField name={`${collectionFieldName}.${index}.product_innovation_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Wskaźniki dotyczące cech/ funkcjonalności innowacji produktowej/ innowacji w procesie biznesowym */}
            <FormField name={`${collectionFieldName}.${index}.indicators_in_the_business_process`}>
              {({
                onChange, value, name, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  options={getOwnIndicators(elementsConfig, getValues, value, MODULE_IRIS.br)}
                  disabled={isReadonly}
                  optionsMapKeys={['id', 'name']}
                  multiple
                  noOptionsText={getOwnIndicators(elementsConfig, getValues, value, MODULE_IRIS.br)
                    .length === 0
                    ? noOptionsText
                    : 'Brak opcji'}
                  renderSelectAllButton={false}
                  error={error}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.product_innovation_feature`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.benefit`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            <FormField name={`${collectionFieldName}.${index}.technical_parameters`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {watch(`${collectionFieldName}.${index}.innovation_type`) === innovationTypes.productInnovation.id && (
              <FormField name={`${collectionFieldName}.${index}.product_feature_for_customers`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            )}
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="2OFu72r5PL3dAkn"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
              variant="contained"
              color="secondary"
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}

export default RdModuleEssence;
