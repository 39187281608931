import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * AccelerationProgramConcept Pdf element.
 *
 * @returns {AccelerationProgramConceptPdf}
 */
function AccelerationProgramConceptPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="acceleration_program_concept_paths"
          dictionaryName={DICTIONARIES.accelerationProgramConceptPath.name}
        />
        <RowField name="acceleration_program_concept_structure" isSingleRow />
        <RowField name="acceleration_program_concept_recruitment" isSingleRow />
        <RowField name="acceleration_program_concept_evaluation" isSingleRow />
        <RowField name="acceleration_program_concept_soft_landing" isSingleRow />
        <RowField name="acceleration_program_concept_acceleration" isSingleRow />
        <RowField name="acceleration_program_concept_cooperation_principles_with_recipients" isSingleRow />
        <RowField name="acceleration_program_concept_cooperation_principles_with_investors" isSingleRow />
        <RowField name="acceleration_program_concept_accelerated_startup_promotion_system" isSingleRow />
        <RowField name="acceleration_program_concept_assumed_results" isSingleRow />
        <RowField name="acceleration_program_concept_information_confirming_effectiveness" isSingleRow />
        <RowField name="acceleration_program_concept_identified_risks" isSingleRow />
        <RowField name="acceleration_program_concept_postacceleration" isSingleRow />
        <RowField name="acceleration_program_concept_industry_specialties_description" isSingleRow />
        <RowField name="acceleration_program_concept_industry_specialty_i" isSingleRow />
        <RowField name="acceleration_program_concept_industry_specialty_ii" isSingleRow />
        <RowField name="acceleration_program_concept_industry_specialty_iii" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default AccelerationProgramConceptPdf;
