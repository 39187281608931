import {
  Button,
  TextField,
  Box,
} from '@mui/material';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import {
  API_ROUTE,
  CHARS_LIMIT,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { PrivatePartnership } from './PrivatePartnership';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  setValuesForOtherGeographicalFields,
  townParser,
  getPropertyFormWhetherLegalForm,
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';
import { request } from '../../../_services';
import { MonetaryField } from '../../MonetaryField';

/**
 * Project general information element renderer.
 *
 * @returns {ApplicantGeneralInformation}
 */
export function ApplicantGeneralInformation() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const streetSelectRef = useRef({
    clear: () => {},
  });

  const {
    watch, setValue, resetField,
  } = useFormContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.applicantType,
    DICTIONARIES.propertyForms,
    DICTIONARIES.companySize,
    DICTIONARIES.vatRecoveryPossibilities,
    DICTIONARIES.geoDictionariesCountries,
    DICTIONARIES.legalEntityTypes,
    DICTIONARIES.pkdCodes,
    DICTIONARIES.innovationCenterType,
    DICTIONARIES.applicantGeneralInformationEntityTypes,
    {
      name: 'organizationAddress',
      path: API_ROUTE.organizationAddress,
    }
  );

  const {
    MEDIUM, NIP, REGON, PHONE, KRS,
  } = CHARS_LIMIT;

  const propertyForms = get(DICTIONARIES.propertyForms.name);
  const organizationAddress = get('organizationAddress');

  const legalFormValue = watch('legal_form');
  const organizationAddressValue = watch('organization_address');

  const noOptionsText = 'Brak dodanych moich organizacji. W celu uzupełnienia pola proszę dodać organizację'
    + ' w sekcji Lista moich organizacji.';

  const pasteOrganizationAddress = async () => {
    if (!organizationAddressValue) {
      return;
    }

    const {
      statusSuccess, payload,
    } = await request.get(`${API_ROUTE.organizationAddress}/${iriToId(organizationAddressValue)}`);

    if (statusSuccess) {
      const camelToUnderscore = (key) => {
        const result = key.replace(/([A-Z])/g, ' $1');

        return result.split(' ').join('_').toLowerCase();
      };

      Object.keys(payload).forEach((item) => {
        if (fieldsConfig[camelToUnderscore(item)]) {
          resetField(camelToUnderscore(item), { defaultValue: payload[item] });
        }
      });
    }
  };

  const selectedCity = watch('city');
  const partnersConfig = fieldsConfig.partnership_partners;

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  const { vatRecoveryPossibility } = ELEMENTS_DICTIONARIES_MAP;

  const vatRecoveryPossibilityValue = watch('vat_recovery_possibility');
  const isVatRecoveryJustificationVisible = vatRecoveryPossibilityValue === vatRecoveryPossibility.no.id
    || vatRecoveryPossibilityValue === vatRecoveryPossibility.partially.id;

  return (
    <FieldSorter>
      <FormField name="organization_address">
        {({
          name, onChange, onBlur, error,
        }) => (
          <Box>
            {/*  Lista moich organizacji */}
            <CustomAutocomplete
              id={name}
              onChange={onChange}
              onBlur={onBlur}
              options={organizationAddress.map(({
                '@id': id,
                applicationName,
                nip,
              }) => ({
                id,
                name: `${applicationName} / ${nip}`,
              }))}
              optionsMapKeys={['id', 'name']}
              disabled={isReadonly}
              error={error}
              textFieldProps={{
                label: 'Wybierz z Listy moich organizacji',
              }}
              noOptionsText={noOptionsText}
            />
            <Button
              sx={{
                mt: (theme) => theme.spacing(2),
                display: 'block',
                ml: 'auto',
              }}
              id="R8NvRqhbD9G9fIA"
              color="success"
              variant="contained"
              aria-label="Dodaj moduł"
              onClick={pasteOrganizationAddress}
            >
              Kopiuj
            </Button>
          </Box>
        )}
      </FormField>

      <FormField name="applicant_name">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data rozpoczęcia działalności zgodnie z dokumentem rejestrowym */}
      <FormField name="start_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Forma prawna */}
      <FormField name="legal_form">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.legalEntityTypes.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Typ wnioskodawcy */}
      <FormField name="applicant_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            multiple
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.applicantType.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Wielkość przedsiębiorstwa */}
      <FormField name="company_size">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.companySize.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Forma wlasności */}
      <FormField name="property_form">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={getPropertyFormWhetherLegalForm(filterOptions, legalFormValue, propertyForms)}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Numer w Krajowym Rejestrze Sądowym */}
      <FormField name="krs_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? KRS} />,
            }}
            inputProps={{
              mask: '0000000000',
              maxLength: maxLength ?? KRS,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Numer kodu PKD działalności wnioskodawcy */}
      <FormField name="pkd">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={formatPkdCodesFieldValue(filterOptions(get(DICTIONARIES.pkdCodes.name)))}
            optionsMapKeys={['id', 'label']}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Możliwość odzyskania VAT */}
      <FormField name="vat_recovery_possibility">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== vatRecoveryPossibility.partially.id
                && event.target.value !== vatRecoveryPossibility.no.id) {
                resetField('vat_recovery_possibility_justification', { defaultValue: FIELD_TYPES_MAP.text });
              }
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.vatRecoveryPossibilities.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      {isVatRecoveryJustificationVisible && (
        // Uzasadnienie braku możliwości odzyskania VAT
        <FormField name="vat_recovery_possibility_justification">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              value={value}
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              disabled={isReadonly}
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      {/* Kraj */}
      <FormField name="country">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Miejscowość */}
      <FormField name="city">
        {({
          onChange, value, name, onBlur,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="name"
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={townParser}
            changeHandler={(selectedValue) => {
              streetSelectRef.current.clear();
              onChange(selectedValue);
              setValuesForOtherGeographicalFields(selectedValue, setValue);
            }}
            textFieldProps={{
              onBlur,
            }}
            charLimitExclusionWords={['oś']}
            baseUrl={API_ROUTE.geoDictionaries.towns}
            disabled={isReadonly}
            staticFilters={{ itemsPerPage: '500' }}
          />
        )}
      </FormField>
      {/* Gmina */}
      <FormField name="commune">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Powiat */}
      <FormField name="district">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Województwo */}
      <FormField name="voivodeship">
        {({ value }) => (
          <TextField value={value?.label || ''} disabled />
        )}
      </FormField>
      {/* Kod pocztowy */}
      <FormField name="postal_code">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            placeholder="__-___"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
            }}
            inputProps={{
              mask: '00-000',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Ulica */}
      <FormField name="street">
        {({
          onChange, value, name, onBlur,
        }) => (
          <ApiAutocomplete
            id={name}
            disabled={!selectedCity?.value || isReadonly}
            ref={streetSelectRef}
            filterBy="name"
            staticFilters={{ 'town.id': selectedCity?.value }}
            initialValue={value?.value}
            initialValueFilterBy="id"
            optionParser={({
              id, name: label,
            }) => ({
              value: id,
              label,
            })}
            textFieldProps={{
              onBlur,
            }}
            changeHandler={onChange}
            baseUrl={API_ROUTE.geoDictionaries.streets}
            minCharsToRequest={2}
          />
        )}
      </FormField>
      {/* Nr budynku */}
      <FormField name="house_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nr lokalu */}
      <FormField name="apartment_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Email */}
      <FormField name="email">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Telefon */}
      <FormField name="phone_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? PHONE} /> }}
            inputProps={{ maxLength: maxLength ?? PHONE }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Strona www */}
      <FormField name="web_page">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Skrzynka e-doręczenia */}
      <FormField name="delivery_box">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? MEDIUM} /> }}
            inputProps={{ maxLength: maxLength ?? MEDIUM }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <LabeledFieldRow fieldConfig={partnersConfig} contextHelpLabel>
        <PrivatePartnership getDictionary={get} />
      </LabeledFieldRow>
      {/* NIP */}
      <FormField name="nip">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? NIP} />,
            }}
            inputProps={{
              mask: '0000000000',
              maxLength: maxLength ?? NIP,
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* REGON */}
      <FormField name="regon">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? REGON} />,
            }}
            inputProps={{
              mask: '000000000',
              maxLength: maxLength ?? REGON,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Rodzaj ośrodka innowacji */}
      <FormField name="innovation_center_type">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            multiple
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.innovationCenterType.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie spełniania definicji ośrodka innowacji */}
      <FormField name="justification_for_meeting_innovation_center_definition">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Adres siedziby wnioskodawcy */}
      <FormField name="applicant_headquarters_address" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Etykieta: Adres oddziału wnioskodawcy na terenie Polski Wschodniej lub
      miejsce wykonywania działalności gospodarczej na terenie Polski Wschodniej */}
      <FormField name="applicant_district_in_eastern_poland" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Data rejestracji działalności w Polsce Wschodniej */}
      <FormField name="business_registration_date_in_eastern_poland">
        {({
          name, onChange, value, error, onBlur, label,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/*  Rodzaj podmiotu działającego na rzecz innowacyjności */}
      <FormField name="applicant_general_information_entity_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.applicantGeneralInformationEntityTypes.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie spełaniania definicji podmiotu działającego na rzecz innowcyjności */}
      <FormField name="applicant_general_information_entity_types_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Numer kodu PKD z sektora HoReCa, turystyka, kultura */}
      <FormField name="horeca_sector_pkd_codes">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            multiple
            renderSelectAllButton={false}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={formatPkdCodesFieldValue(filterOptions(get(DICTIONARIES.pkdCodes.name)))}
            optionsMapKeys={['id', 'label']}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Ostatni zamknięty rok obrotowy */}
      <FormField name="applicant_general_information_last_closed_fiscal_year">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wartość przychodów ze sprzedaży w ostatnim zamkniętym roku obrotowym */}
      <FormField name="applicant_general_information_revenues_value_in_the_last_closed_fiscal_year">
        {({
          name, onChange, value, error, onBlur,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
