import {
  View,
  Text,
} from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';

/**
 * UeProgramSubjectEurogrant Pdf element.
 *
 * @returns {UeProgramSubjectEurograntPdf}
 */
function UeProgramSubjectEurograntPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    }, getDictionary,
  } = useApplicationToPdfContext();

  const {
    ueProgramType, applicantUeProgramRole,
  } = ELEMENTS_DICTIONARIES_MAP;

  const ueProgramTypes = initialFormData?.ue_program_subject_eurogrant_ue_program_type;
  const ueProgramRoles = initialFormData?.ue_program_subject_eurogrant_applicant_ue_program_role;
  const isBearToPrepareApplicationOperatingExpenses = initialFormData
    .ue_program_subject_eurogrant_is_bear_to_prepare_application_operating_expenses;

  const countriesDictionary = getDictionary(DICTIONARIES.geoDictionariesCountries.name);

  return (
    <View style={printStyle.table}>
      {/* Nazwa Programu UE, tj. programu Unii Europejskiej zarządzanego centralnie przez Komisję Europejską,
którego dotyczy projekt */}
      <RowField
        name="ue_program_subject_eurogrant_ue_program_type"
        dictionaryName={DICTIONARIES.ueProgramTypes.name}
      />
      {(ueProgramTypes === ueProgramType.otherUeProgram.id) && (
        <RowField name="ue_program_subject_eurogrant_other_ue_program_type" />
      )}
      {/* Opis konkursu Komisji Europejskiej/Instrumentu, w ramach którego złożony zostanie wniosek/aplikacja
      o Eurogrant */}
      <RowField name="ue_program_subject_eurogrant_eu_commission_competition_description" isSingleRow />
      {/* Oficjalna strona internetowa konkursu Komisji Europejskiej/Instrumentu */}
      <RowField name="ue_program_subject_eurogrant_eu_commission_competition_official_website" isSingleRow />
      {/* Data złożenia wniosku/aplikacji o Eurogrant do Programu UE */}
      <RowField name="ue_program_subject_eurogrant_eurogrant_application_created_at" />
      {/* Rola wnioskodawcy w projekcie z Programu UE: */}
      <RowField
        name="ue_program_subject_eurogrant_applicant_ue_program_role"
        dictionaryName={DICTIONARIES.applicantUeProgramRoles.name}
      />
      {(ueProgramRoles !== applicantUeProgramRole.independentApplicant.id) && (
        <Text style={printStyle.sectionTitle}>
          W przypadku ubiegania się o dofinansowanie z Programu UE w ramach konsorcjum lub grupy, należy podać
          dane wszystkich aktualnie znanych członków wchodzących w skład konsorcjum/grupy (1, 2, 3, …)
        </Text>
      )}
      {(ueProgramRoles !== applicantUeProgramRole.independentApplicant.id) && (
        <View style={printStyle.table}>
          <PrintoutTableRow>
            <PrintoutTableCell rowSpan={8}>Lp.</PrintoutTableCell>
            <PrintoutTableCell rowSpan={3}>Nazwa członka</PrintoutTableCell>
            <PrintoutTableCell rowSpan={3}>Kraj siedziby członka</PrintoutTableCell>
            <PrintoutTableCell rowSpan={3}>Numer Identyfikacji Podatkowej NIP lub VAT UE</PrintoutTableCell>
            <PrintoutTableCell rowSpan={3}>Adres oficjalnej strony internetowej członka</PrintoutTableCell>
            <PrintoutTableCell rowSpan={3}>
              Czy będzie ponosił koszty w związku z przygotowaniem wniosku o dofinansowanie z Programu UE?
            </PrintoutTableCell>
          </PrintoutTableRow>

          {(initialFormData.ue_program_subject_eurogrant_members_data).map((item, index) => {
            const countryForPdf = countriesDictionary
              .find((country) => country['@id'] === item.residence_country)?.name;

            return (
              <PrintoutTableRow key={item.set_uuid} mb={4}>
                <PrintoutTableCell rowSpan={8}>{index + 1}</PrintoutTableCell>
                <PrintoutTableCell rowSpan={3}>{item.name}</PrintoutTableCell>
                <PrintoutTableCell rowSpan={3}>{countryForPdf}</PrintoutTableCell>
                <PrintoutTableCell rowSpan={3}>{item.nip}</PrintoutTableCell>
                <PrintoutTableCell rowSpan={3}>{item.official_website}</PrintoutTableCell>
                <PrintoutTableCell rowSpan={3}>
                  {item.is_bear_to_prepare_application_expenses}
                </PrintoutTableCell>
              </PrintoutTableRow>
            );
          })}
        </View>
      )}
      {/* Czy w ramach przygotowania wniosku o dofinansowanie z Programu UE poniesione zostaną
      koszty opracowania analiz specjalistycznych? */}
      <RowField name="ue_program_subject_eurogrant_is_bear_to_prepare_application_operating_expenses" />
      {isBearToPrepareApplicationOperatingExpenses === BOOLEAN_VALUES.TRUE && (
        <RowField name="ue_program_subject_eurogrant_operating_expenses_justification" />
      )}
      {/* Wnioskowana kwota dofinansowania w ramach Programu UE  */}
      <RowField name="ue_program_subject_eurogrant_funding_amount" />
      {/* Opis zakresu przedmiotowego i założeń planowanego Eurograntu */}
      <RowField name="ue_program_subject_eurogrant_operating_assumptions_description" />
    </View>
  );
}

export default UeProgramSubjectEurograntPdf;
