import { TitledContainer } from '../../TitledContainer';
import { ChoiceSpecializationForm } from './ChoiceSpecialization.form';

/**
 * Selection of the specialization of the expert.
 *
 * @returns {ChoiceSpecialization}
 */
export function ChoiceSpecialization() {
  return (
    <TitledContainer
      title="Wybór specjalizacji i dziedzin"
      key="choice-specialization"
      variant="slim"
      textAlign="left"
    >
      <ChoiceSpecializationForm />
    </TitledContainer>
  );
}
