import FieldWrapper from '../FieldWrapper';

/**
 * NotBeforeSubmissionDate validation component
 *
 * @returns {NotBeforeSubmissionDate}
 */
function NotBeforeSubmissionDate() {
  return (
    <FieldWrapper
      name="required.value"
      label="Data rozpoczęcia realizacji projektu"
      sx={{
        fontWeight: 'bold',
        fontSize: '1rem',
      }}
    />
  );
}

export default NotBeforeSubmissionDate;
