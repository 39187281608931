import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import RowField from '../../Components/RowField/RowField';

/**
 * Module Implementation Potential Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {ModuleImplementationPotentialPdf}
 */
function ModuleImplementationPotentialPdf({ elementData }) {
  const { id: elementId } = elementData;

  return (
    <CollectionWrapper name={`module_implementation_potentials-${elementId}`}>
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.module_implementation_potential_document_type`}
                value={item.module_implementation_potential_document_type}
                oldValue={previousRowData?.module_implementation_potential_document_type}
                dictionaryName={DICTIONARIES.moduleImplementationPotentialDocumentTypes.name}
              />
              <RowField
                name={`${collectionName}.${index}.module_implementation_document_description`}
                value={item.module_implementation_document_description}
                oldValue={previousRowData?.module_implementation_document_description}
              />
              <RowField
                name={`${collectionName}.${index}.module_implementation_document_issued_at`}
                value={item.module_implementation_document_issued_at}
                oldValue={previousRowData?.module_implementation_document_issued_at}
              />
              <RowField
                name={`${collectionName}.${index}.module_implementation_document_planned_issue_at`}
                value={item.module_implementation_document_planned_issue_at}
                oldValue={previousRowData?.module_implementation_document_planned_issue_at}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ModuleImplementationPotentialPdf;

ModuleImplementationPotentialPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
