import {
  View,
  Text,
} from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getChangesInField,
  getIndexForPreviousRowData,
  getRemovedRowData,
} from '../../../../../_helpers';

/**
 * ClusterMembersNumber pdf subfield of PkwEligibility.
 *
 * @returns {ClusterMembersNumberPdf}
 */
function ClusterMembersNumberPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const numberClusterMembers = initialFormData.pkw_eligibility_number_cluster_members_by_voivodeships;
  const previousNumberClusterMembers = previousInitialFormData?.pkw_eligibility_number_cluster_members_by_voivodeships;

  const numberClusterMembersData = withChanges ? [
    ...getAddedRowData(numberClusterMembers, previousNumberClusterMembers, 'set_uuid'),
    ...getRemovedRowData(numberClusterMembers, previousNumberClusterMembers, 'set_uuid'),
  ] : numberClusterMembers;

  return (
    <>
      <Text style={printStyle.sectionTitle}>
        Liczba członków klastra w podziale na województwa
      </Text>
      <View style={printStyle.table}>
        <PrintoutTableRow>
          <PrintoutTableCell rowSpan={3}>Województwo</PrintoutTableCell>
          <PrintoutTableCell rowSpan={3}>
            Liczba członków klastra z siedzibą/miejscem zamieszkania w województwie
          </PrintoutTableCell>
          <PrintoutTableCell rowSpan={3}>Udział procentowy</PrintoutTableCell>
        </PrintoutTableRow>
        {numberClusterMembersData.map((item) => {
          const rowsCount = [
            item?.voivodeship,
            item?.cluster_members_based_in_voivodeship,
            item?.percentage_share,
          ].filter((field) => field).length;

          const previousRowData = withChanges ? previousNumberClusterMembers[
            getIndexForPreviousRowData(previousNumberClusterMembers, item.set_uuid, 'set_uuid')
          ] : {};

          return (
            <PrintoutTableRow key={item.voivodeship}>
              <PrintoutTableCell rowSpan={rowsCount}>
                {getChangesInField(previousRowData?.voivodeship, item.voivodeship)}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={rowsCount}>
                {getChangesInField(
                  previousRowData?.cluster_members_based_in_voivodeship,
                  item.cluster_members_based_in_voivodeship
                )}
              </PrintoutTableCell>
              <PrintoutTableCell rowSpan={rowsCount}>
                {getChangesInField(previousRowData?.percentage_share, item.percentage_share)}
              </PrintoutTableCell>
            </PrintoutTableRow>
          );
        })}
      </View>
    </>
  );
}
export default ClusterMembersNumberPdf;
