import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * BusinessModelElements pdf element.
 *
 * @returns {BusinessModelElementsPdf}
 */
function BusinessModelElementsPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Potencjalni odbiorcy rozwiązania oferowanego przez Wnioskodawcę */}
        <RowField name="business_model_elements_potential_recipients" isSingleRow />
        {/* Problemy jakie mają potencjalni klienci Wnioskodawcys */}
        <RowField name="business_model_elements_potential_customers_problems" isSingleRow />
        {/* Wskazanie czy i jak rozwiązania opracowane przez Wnioskodawcę te problemy rozwiązuje */}
        <RowField name="business_model_elements_problem_solving_description" isSingleRow />
        {/* Wskazanie potencjalnych kanałów dystrybucji rozwiązania */}
        <RowField name="business_model_elements_potential_distribution_channels" isSingleRow />
        {/*  Wskazanie potencjalnych źródeł przychodu Wnioskodawcy z komercjalizacji rozwiązania */}
        <RowField name="business_model_elements_applicant_income_potential_sources" isSingleRow />
        {/* Wskazanie potencjalnych kierunków zagranicznej komercjalizacji rozwiązania */}
        <RowField name="business_model_elements_foreign_commercialization_potential_directions" isSingleRow />
        {/* Wskazanie czy i w jakim zakresie Rozwiązanie może mieć pozytywny wpływ na osiągniecie celów
      jednej lub więcej polityk horyzontalnych Unii Europejskiej w szczególności: w zakresie zasady
      równości kobiet i mężczyzn, zasady równości szans i niedyskryminacji, w tym dostępności dla osób
       z niepełnosprawnościami, zasady zrównoważonego rozwoju */}
        <RowField name="business_model_elements_positive_impact_on_achieving_goals" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default BusinessModelElementsPdf;
