import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Digitization Module Essence Pdf element.
 *
 * @returns {DigitizationModuleEssencePdf}
 */
function DigitizationModuleEssencePdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="digitization_module_essence_module_purpose" />
        <RowField name="digitization_module_essence_features_description" />
        {/* @TODO - need to check how attachments are returned  */}
        <RowField name="digitization_module_essence_attachments" />
        <RowField
          name="digitization_module_essence_solution_innovation_level"
          dictionaryName={DICTIONARIES.solutionInnovationLevels.name}
        />
        <RowField name="digitization_module_essence_innovation_level_justification" />
      </FieldSorter>
    </View>
  );
}

export default DigitizationModuleEssencePdf;
