import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Box,
} from '@mui/material';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AddElement } from './AddElement';
import { ElementsList } from './ElementsList';
import {
  API_ROUTE,
  ACTION_BUTTONS,
  DIALOG_TYPES,
} from '../../../_constants';
import { request } from '../../../_services';
import ExpandableText from '../../ExpandableText';
import {
  ElementEditDialog,
  StatementsElementEditDialog,
} from '../../../Dialogs';
import { MenuTabsDialog } from '../../../Dialogs/ApplicationTemplate/MenuTabs.dialog';
import { ModulesSelectionDialog } from '../../../Dialogs/ApplicationTemplate/ModulesSelectionDialog';
import { StepConfiguratorDialog } from '../../../Dialogs/ApplicationTemplate/StepConfiguratorDialog';
import { LogotypesConfiguratorDialog } from '../../../Dialogs/ApplicationTemplate/LogotypesConfigurator.dialog';
import { OperatorsConfiguratorDialog } from '../../../Dialogs/ApplicationTemplate/OperatorsConfigurator.dialog';
import {
  useGlobalContext,
  useGlobalDialog,
  useReloadListener,
} from '../../../Context';
import { theme } from '../../../styles/theme';
import {
  HrfValidationConfigurator,
} from '../../../Dialogs/ApplicationTemplate/HrfValidationConfigurator/HrfValidationConfigurator';

const classes = {
  headerButton: {
    backgroundColor: '#a6c4d6',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#879fad',
    },
    marginRight: theme.spacing(0.6),
  },
  dataInfoWrapper: {
    '& > div': {
      border: '1px solid #000',
      padding: theme.spacing(1.5),
      marginRight: theme.spacing(2),
    },
  },
  label: {
    backgroundColor: '#FFF',
    fontSize: '10px',
    display: 'flex',
    position: 'relative',
    top: theme.spacing(2),
    left: '10px',
    justifyContent: 'left',
    width: 'fit-content',
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
};

/**
 * Application template edit
 *
 * @returns {Edit|string}
 */
export function Edit() {
  const { applicationTemplateId } = useParams();
  const navigate = useNavigate();
  const { notify } = useGlobalContext();
  const {
    render, closeAll,
  } = useGlobalDialog();

  const DIALOGS_COMPONENT = {
    [DIALOG_TYPES.APPLICATION_MENU]: {
      component: MenuTabsDialog,
      title: 'Konfigurator menu wniosku',
    },
    [DIALOG_TYPES.MODULES]: {
      component: ModulesSelectionDialog,
      title: 'Konfigurator modułów',
    },
    [DIALOG_TYPES.STEPS]: {
      component: StepConfiguratorDialog,
      title: 'Konfigurator kroków',
    },
    [DIALOG_TYPES.LOGOTYPES]: {
      component: LogotypesConfiguratorDialog,
      title: 'Lista logotypów / Dodawanie logotypu',
    },
    [DIALOG_TYPES.OPERATORS]: {
      component: OperatorsConfiguratorDialog,
      title: 'Konfigurator operatorów',
    },
    [DIALOG_TYPES.HRF_VALIDATIONS]: {
      component: HrfValidationConfigurator,
      title: 'Konfigurator walidacji',
    },
  };

  const [applicationTemplateData, setApplicationTemplateData] = useState({
    id: '',
    elements: [],
  });
  const [selectedElement, setSelectedElement] = useState(null);
  const {
    watch,
  } = useReloadListener();
  const watcherName = 'application-template-edit';

  /**
   * Loads ApplicationTemplate from API.
   *
   * @param {Function} onSuccessCallback - optional success callback
   */
  const loadApplicationTemplate = async (onSuccessCallback = () => {}) => {
    const {
      payload,
      statusSuccess,
    } = await request.get(`${API_ROUTE.applicationTemplates}/${applicationTemplateId}`);

    setApplicationTemplateData(payload);

    if (!statusSuccess) {
      navigate('/');
      notify(`Wzór wniosku o identyfikatorze "${applicationTemplateId}" nie został odnaleziony.`, 'error');

      return;
    }

    onSuccessCallback();
  };

  useEffect(() => {
    loadApplicationTemplate();
  }, [applicationTemplateData.id, applicationTemplateId, watch(watcherName)]);

  if (!applicationTemplateData.id) {
    return '';
  }

  return (
    <>
      <Box p={2} display="flex" sx={classes.dataInfoWrapper}>
        <span>
          <span style={classes.label}>GUID</span>
          <Box
            p={1.5}
            mr={2}
            display="flex"
            sx={{
              minWidth: '150px',
              border: '1px solid #000',
            }}
          >
            <ExpandableText shortText="Pokaż GUID" fullText={applicationTemplateData.id} />
          </Box>
        </span>
        <span>
          <span style={classes.label}>Poziom Wdrażania</span>
          <Box
            p={1.5}
            mr={2}
            display="flex"
            sx={{
              minWidth: '150px',
              border: '1px solid #000',
            }}
          >
            {applicationTemplateData.implementationLevel ? applicationTemplateData.implementationLevel.kod : ''}
          </Box>
        </span>
        <span>
          <span style={classes.label}>Wersja</span>
          <Box
            p={1.5}
            mr={2}
            display="flex"
            sx={{
              minWidth: '150px',
              border: '1px solid #000',
            }}
          >
            {applicationTemplateData.ordinalNumber}
          </Box>
        </span>
      </Box>
      <Box display="flex" p={2}>
        {ACTION_BUTTONS.map(({
          id: actionButtonId, label, dialogType, isDisabled,
        }) => {
          const DialogComponent = DIALOGS_COMPONENT[dialogType]?.component;

          return (
            <Button
              key={actionButtonId}
              id={actionButtonId}
              sx={classes.headerButton}
              onClick={() => render(
                <DialogComponent
                  applicationTemplateId={applicationTemplateData.id}
                  elements={applicationTemplateData.elements}
                  closeHandler={closeAll}
                />,
                DIALOGS_COMPONENT[dialogType].title,
              )}
              disabled={isDisabled}
            >
              {label}
            </Button>
          );
        })}
      </Box>

      <AddElement
        templateId={applicationTemplateData.id}
        selectedElementHandler={setSelectedElement}
      />
      <ElementsList
        templateId={applicationTemplateData.id}
        elementsOrder={applicationTemplateData.elementsOrder || []}
        elements={applicationTemplateData.elements}
        reloadHandler={loadApplicationTemplate}
        selectedElementHandler={setSelectedElement}
      />
      {selectedElement && (
        <>
          {selectedElement?.elementDefinition['@type'] === 'Statements' ? (
            <StatementsElementEditDialog
              templateElementId={selectedElement.id}
              closeHandler={() => setSelectedElement(null)}
            />
          ) : (
            <ElementEditDialog
              templateElementId={selectedElement.id}
              closeHandler={() => setSelectedElement(null)}
            />
          )}
        </>
      )}
    </>
  );
}
