import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Edih Services Pdf element.
 *
 * @returns {EdihServicesPdf}
 */
function EdihServicesPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <CollectionWrapper name="edih_services">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.service_name`}
                      value={item.service_name}
                      oldValue={previousRowData?.service_name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.service_type`}
                      value={item.service_type}
                      oldValue={previousRowData?.service_type}
                      dictionaryName={DICTIONARIES.edihSevicesTypes.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.average_aid_value`}
                      value={item.average_aid_value}
                      oldValue={previousRowData?.average_aid_value}
                    />
                    <RowField
                      name={`${collectionName}.${index}.services_number`}
                      value={item.services_number}
                      oldValue={previousRowData?.services_number}
                    />
                    <RowField
                      name={`${collectionName}.${index}.service_description`}
                      value={item.service_description}
                      oldValue={previousRowData?.service_description}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        <RowField name="edih_services_aid_total_value" />
      </FieldSorter>
    </View>
  );
}

export default EdihServicesPdf;
