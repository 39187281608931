export const PROFILES = {
  applicant: 'applicant', // wnioskodawca
  employee: 'employee', // pracownik
  expert: 'expert', // ekspert
  operator: 'operator', // operator
  managementInstitution: 'management_institution', // instytucja zarządzająca
};

export const SIMPLE_TRANSLATION = {
  [PROFILES.applicant]: 'Wnioskodawca',
  [PROFILES.employee]: 'Pracownik',
  [PROFILES.expert]: 'Ekspert',
  [PROFILES.operator]: 'Operator',
  [PROFILES.managementInstitution]: 'Instytucja Zarządzająca',
};

export const PROFILES_TRANSLATION = {
  [PROFILES.applicant]: 'Profil wnioskodawcy',
  [PROFILES.employee]: 'Profil pracownika',
  [PROFILES.expert]: 'Profil eksperta',
  [PROFILES.operator]: 'Profil operatora',
  [PROFILES.managementInstitution]: 'Profil Instytucji Zarządzającej',
};

export const PROFILES_LIST = [
  {
    value: PROFILES.expert,
    text: SIMPLE_TRANSLATION[PROFILES.expert],
  },
  {
    value: PROFILES.employee,
    text: SIMPLE_TRANSLATION[PROFILES.employee],
  },
  {
    value: PROFILES.applicant,
    text: SIMPLE_TRANSLATION[PROFILES.applicant],
  },
  {
    value: PROFILES.operator,
    text: SIMPLE_TRANSLATION[PROFILES.operator],
  },
  {
    value: PROFILES.managementInstitution,
    text: SIMPLE_TRANSLATION[PROFILES.managementInstitution],
  },
];
