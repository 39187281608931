import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAuth } from '../_security';
import { request } from '../_services';
import {
  ADDITIONAL_MODULE_IDS,
  ADDITIONAL_MODULE_LABELS,
  API_ROUTE,
  EXPERT_PANEL_ROLES,
  MODULE_STATUSES,
  EVALUATION_SHEET_STATUS,
  EXPERT_PANEL_TYPES,
  ACTION_TYPES,
} from '../_constants';
import {
  getExpertRole,
  getExternalIdsToCriteriaItem,
  getModuleStatus,
  iriToId,
} from '../_helpers';
import { useGlobalContext } from './Global.context';
import { useReloadListener } from './ReloadListener.context';
import { ContainerLoader } from '../Features/Application/Application/ContainerLoader';
import { usePageLock } from '../_hooks';

/**
 * ExpertPersonalDataContext context wrapper.
 * Contains expert personal data from API.
 *
 * @type {React.Context}
 */
const AssessmentSheetContext = createContext({});

export const useAssessmentSheetContext = () => useContext(AssessmentSheetContext);

/**
 * Assessment sheet provider.
 *
 * @param {object} props - root props
 * @param {Node|Node[]} props.children - children elements
 * @param {boolean} props.isReadonly - is read only
 * @returns {AssessmentSheetProvider}
 */
export function AssessmentSheetProvider({
  children, isReadonly,
}) {
  const location = useLocation();
  const { id: evaluationSheetId } = useParams();
  const {
    id: loggedUserId, isAdmin,
  } = useAuth();
  const navigate = useNavigate();
  const {
    notify, handleAction,
  } = useGlobalContext();
  const [isLoaded, setLoaded] = useState(false);
  const [showFilteredModulesByExpert, setShowFilteredModulesByExpert] = useState(true);
  const [sessionData, setSessionData] = useState({});
  const [selectedModule, setSelectedModule] = useState(null);
  const [isExpandedAllItems, setExpandedAllItems] = useState(false);
  const {
    reload, watch,
  } = useReloadListener();
  const watcherName = 'assessment-sheet';

  const [store, setStore] = useState({
    expertPanelIri: '',
    modules: [],
    expertList: [],
    isLeadingExpert: false,
    panelType: '',
    evaluationItems: [],
    hasAcceptedMinOneModule: false,
    isFinancialExpert: false,
    status: '',
    currentExpertRole: null,
    application: {},
    panelNumber: null,
    isDirty: false,
    financialCosts: {
      project: {},
      modules: [],
    },
  });

  /**
   * Triggers listener to reload data from API.
   */
  const reloadData = () => {
    setLoaded(false);
    reload(watcherName);
  };

  const setDirty = (value) => setStore((prevState) => ({
    ...prevState,
    isDirty: value,
  }));

  // eslint-disable-next-line default-param-last
  const closeSession = async (goToPreviousPage = true, handleCloseModal) => {
    if (store.currentExpertRole !== EXPERT_PANEL_ROLES.employeeOrAdmin && !isReadonly) {
      await request.put(`${API_ROUTE.evaluationSessions}/${sessionData.id}`, {
        evaluationSheet: `/lsi/auth/api/evaluation-sheets/${evaluationSheetId}`,
        closed: true,
      });
    }

    if (goToPreviousPage) {
      navigate(-1);
    }

    if (handleCloseModal) {
      handleCloseModal(false);
    }
  };

  const setEvaluationSession = async () => {
    const {
      statusSuccess, payload,
    } = await request.post(API_ROUTE.evaluationSessions, {
      evaluationSheet: `/lsi/auth/api/evaluation-sheets/${evaluationSheetId}`,
      user: `/lsi/auth/api/users/${loggedUserId}`,
    });

    if (!statusSuccess) {
      navigate(-1);
      setTimeout(() => {
        notify(payload?.detail || 'Arkusz oceny jest obecnie edytowany przez innego eksperta.', 'error');
      }, 100);

      return;
    }

    setSessionData(payload);
    setLoaded(true);
  };

  const getEvaluationSheet = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}`);

    if (statusSuccess) {
      setStore((prevState) => ({
        ...prevState,
        evaluationItems: payload.items.map((evaluationSheetItem) => ({
          ...evaluationSheetItem,
          moduleId: getExternalIdsToCriteriaItem(
            evaluationSheetItem.criteriaItem.businessGroup,
            evaluationSheetItem.criteriaItem.moduleId,
            evaluationSheetItem.criteriaItem.conclusive,
          ),
        })),
        status: payload.status,
        application: payload.application,
      }));

      fetchExpertPanel(iriToId(payload.expertPanel));

      return;
    }

    navigate(-1);
    setTimeout(() => {
      notify('Wystąpił błąd podczas wczytywania arkusza ocen. Spróbuj ponownie.', 'error');
    }, 100);
  };

  const getAdditionalValuesForModules = (
    modules,
    hasAcceptedMinOneModule,
    _isLeadingExpert,
    isFinancialExpert,
    isChairmanOrViceChairman
  ) => {
    const combinedModules = [];
    modules.forEach((module) => {
      const currentExpert = module.experts
        .find((expert) => iriToId(expert.externalExpert.user['@id']) === loggedUserId);
      const hasAccess = !!currentExpert;
      const currentExpertRole = (isChairmanOrViceChairman || isAdmin)
        ? EXPERT_PANEL_ROLES.employeeOrAdmin
        : getExpertRole(currentExpert, isFinancialExpert);
      const additionalData = {
        currentExpertRole,
        // eslint-disable-next-line no-nested-ternary
        moduleStatus: hasAccess
          ? getModuleStatus(currentExpert)
          : ((isChairmanOrViceChairman || isAdmin) ? MODULE_STATUSES.accepted : MODULE_STATUSES.readonly),
      };

      if (module.moduleId === null) {
        combinedModules.push(
          {
            ...module,
            moduleId: ADDITIONAL_MODULE_IDS.ocenaFinansowa,
            ...additionalData,
          },
          {
            moduleName: ADDITIONAL_MODULE_LABELS.modulowaFinansowa,
            moduleId: ADDITIONAL_MODULE_IDS.modulowaFinansowa,
            ...additionalData,
            currentExpertRole: additionalData.currentExpertRole,
            moduleStatus:
              additionalData.moduleStatus === MODULE_STATUSES.waiting
                ? MODULE_STATUSES.waitingForFinancial
                : additionalData.moduleStatus,
            '@id': null,
            experts: [{
              externalExpert: {
                user: {
                  '@id': `/lsi/auth/api/users/${loggedUserId}`,
                },
              },
            }],
          }
        );

        return;
      }

      combinedModules.push({
        ...module,
        ...additionalData,
      });
    });

    const additionalDataForModules = {
      experts: [{
        externalExpert: {
          user: {
            '@id': `/lsi/auth/api/users/${loggedUserId}`,
          },
        },
      }],
      currentExpertRole: (isChairmanOrViceChairman || isAdmin)
        ? EXPERT_PANEL_ROLES.employeeOrAdmin
        : getExpertRole({ role: EXPERT_PANEL_ROLES.expert }, isFinancialExpert),
      // eslint-disable-next-line no-nested-ternary
      moduleStatus: hasAcceptedMinOneModule
        ? MODULE_STATUSES.accepted
        : ((isChairmanOrViceChairman || isAdmin) ? MODULE_STATUSES.accepted : MODULE_STATUSES.readonly),
    };
    combinedModules.push(
      {
        moduleId: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
        moduleName: ADDITIONAL_MODULE_LABELS.kryteriaOgolem,
        ...additionalDataForModules,
      },
      {
        moduleId: ADDITIONAL_MODULE_IDS.kryteriaRankingujace,
        moduleName: ADDITIONAL_MODULE_LABELS.kryteriaRankingujace,
        ...additionalDataForModules,
      }
    );

    return combinedModules;
  };

  const fetchApplicationLeadingExpert = async (expertPanelId) => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.expertPanelLeadingExperts.replace(':id', expertPanelId));

    if (!statusSuccess) {
      navigate(-1);

      return;
    }

    // eslint-disable-next-line consistent-return
    return payload;
  };

  const getCurrentExpertData = (expert) => {
    if (expert.externalExpert) {
      return iriToId(expert.externalExpert.user['@id']) === loggedUserId;
    }

    return iriToId(expert.parpExpert.user['@id']) === loggedUserId;
  };
  const fetchExpertPanel = async (expertPanelId) => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertPanels}/${expertPanelId}`);

    if (!statusSuccess) {
      navigate(-1);

      return;
    }

    const applicationLeadingExpert = payload.criteriaSet.panelType === EXPERT_PANEL_TYPES.withExpert
      ? null : await fetchApplicationLeadingExpert(iriToId(payload['@id']));

    const evaluationSheet = payload.evaluationSheets.find(({ id }) => id === evaluationSheetId);
    const application = payload.criteriaSet.panelType === EXPERT_PANEL_TYPES.module
      ? payload.applications[0]
      : payload.applications?.find(
        ({ applicationId }) => applicationId === evaluationSheet.application.applicationId
      ) || {};

    const expertList = payload.criteriaSet.panelType === EXPERT_PANEL_TYPES.module
      ? payload.modules.map(({ experts }) => experts).flat()
      : application.experts;
    const chairmanId = iriToId(payload.chairman.user['@id'] || '');
    const viceChairmanId = iriToId(payload.viceChairman?.user?.['@id'] || '');
    const isChairmanOrViceChairman = chairmanId === loggedUserId || viceChairmanId === loggedUserId;
    const financialModule = payload.modules.find(({ moduleId }) => moduleId === null) || null;
    const isFinancialExpert = financialModule
      ? financialModule.experts.some(
        ({ externalExpert }) => iriToId(externalExpert.user['@id']) === loggedUserId
      )
      : false;
    const expertRole = isFinancialExpert ? EXPERT_PANEL_ROLES.financial : EXPERT_PANEL_ROLES.expert;
    const hasAcceptedMinOneModule = expertList
      .filter((expert) => iriToId(expert?.externalExpert?.user['@id']) === loggedUserId)
      .filter(({ accepted }) => accepted).length !== 0;
    const isLeadingExpert = loggedUserId === iriToId(
      applicationLeadingExpert?.leadingExpert?.externalExpert?.user['@id'] || ''
    );

    const modules = getAdditionalValuesForModules(
      payload.modules,
      hasAcceptedMinOneModule,
      isLeadingExpert,
      isFinancialExpert,
      isChairmanOrViceChairman
    );
    const isModuleExpert = modules.filter((module) => (
      module.moduleStatus !== MODULE_STATUSES.readonly
      && module.moduleId !== ADDITIONAL_MODULE_IDS.modulowaFinansowa
      && module.moduleId !== ADDITIONAL_MODULE_IDS.kryteriaOgolem
      && module.moduleId !== ADDITIONAL_MODULE_IDS.ocenaFinansowa
    )).length !== 0;

    const currentExpert = expertList.find(getCurrentExpertData);

    setStore((prevState) => ({
      ...prevState,
      modules,
      expertList,
      isLeadingExpert,
      panelType: payload.criteriaSet.panelType,
      expertPanelIri: payload['@id'],
      expertRole,
      hasAcceptedMinOneModule,
      isFinancialExpert,
      isModuleExpert,
      applicationNumber: application.applicationNumber,
      currentExpertRole: (isChairmanOrViceChairman || isAdmin)
        ? EXPERT_PANEL_ROLES.employeeOrAdmin
        : getExpertRole(currentExpert || {}, isFinancialExpert),
      panelNumber: payload.number,
      recruitmentId: payload.recruitmentId,
    }));

    if ((isChairmanOrViceChairman || isAdmin || isReadonly) || Object.values(sessionData).length !== 0) {
      setLoaded(true);
    }
  };

  const getAmountTables = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/amount-tables`);

    if (!statusSuccess) {
      navigate(-1);

      return;
    }

    if (payload.length !== 0) {
      setStore((prevState) => ({
        ...prevState,
        financialCosts: {
          project: payload?.project || {},
          modules: payload?.modules?.['hydra:member'] || [],
        },
      }));
    }
  };

  useEffect(() => {
    if (
      store.currentExpertRole
      && store.currentExpertRole !== EXPERT_PANEL_ROLES.employeeOrAdmin
      && !isReadonly && Object.values(sessionData).length === 0
    ) {
      setEvaluationSession();
    }
    if (store.currentExpertRole === EXPERT_PANEL_ROLES.employeeOrAdmin || isReadonly) {
      setShowFilteredModulesByExpert(false);
    }
  }, [store.currentExpertRole]);

  useEffect(() => {
    getEvaluationSheet();
  }, [watch(watcherName)]);

  useEffect(() => {
    if (
      store.currentExpertRole
      && ((
        store.status === EVALUATION_SHEET_STATUS.edition
          && store.currentExpertRole === EXPERT_PANEL_ROLES.readonly
      )
      || (
        store.status === EVALUATION_SHEET_STATUS.sentToParp
          && store.currentExpertRole !== EXPERT_PANEL_ROLES.employeeOrAdmin
      ))
      && !isReadonly) {
      navigate(-1);
    }
    if (
      store.currentExpertRole
      && store.currentExpertRole !== EXPERT_PANEL_ROLES.employeeOrAdmin
      && store.panelType === EXPERT_PANEL_TYPES.withExpert
      && !isReadonly
    ) {
      const currentExpert = store.expertList.find(getCurrentExpertData);

      if (currentExpert?.refused || (!currentExpert?.refused && !currentExpert?.accepted)) {
        navigate(-1);
      }
    }
  }, [store.status, store.currentExpertRole, store.expertList, store.panelType]);

  useEffect(() => {
    if (store.panelType) {
      getAmountTables();
    }
  }, [store.panelType]);

  const handleShowFilteredModulesByExpert = (value = null) => {
    setShowFilteredModulesByExpert((prevState) => {
      if (typeof value === 'boolean') {
        return value;
      }

      return !prevState;
    });
  };

  const handleExpandAllItems = () => {
    setExpandedAllItems((prevState) => !prevState);
  };

  useEffect(() => () => {
    if (store.currentExpertRole !== EXPERT_PANEL_ROLES.employeeOrAdmin && Object.values(sessionData).length !== 0) {
      closeSession(false);
    }
    handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  }, [location, sessionData]);

  usePageLock(store.isDirty);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AssessmentSheetContext.Provider value={{
      isLoaded,
      showFilteredModulesByExpert,
      handleShowFilteredModulesByExpert,
      reloadData,
      sessionData,
      store,
      selectedModule,
      setSelectedModule,
      isReadonly,
      closeSession,
      setDirty,
      isExpandedAllItems,
      handleExpandAllItems,
    }}
    >
      {children}
    </AssessmentSheetContext.Provider>
  );
}

AssessmentSheetProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isReadonly: PropTypes.bool,
};

AssessmentSheetProvider.defaultProps = {
  isReadonly: true,
};
