import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import StepConfiguratorForm from './StepConfigurator.form';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';

/**
 * StepConfiguratorDialog component.
 *
 * @param {object} props root props
 * @param {Function} props.closeHandler dialog close handler
 * @returns {StepConfiguratorDialog} steps configuration dialog
 */
export function StepConfiguratorDialog({
  closeHandler,
}) {
  const form = useForm({
    defaultValues: {
      formName: '',
      instruction: '',
      steps: [
        {
          step: '',
        },
        {
          step: '',
        },
      ],
    },
  });

  const { handleSubmit } = form;

  return (
    <>
      <FormProvider {...form}>
        <StepConfiguratorForm />
      </FormProvider>
      <Box mt={5} display="flex" justifyContent="center" width="100%">
        <SaveCancelButtons
          cancelHandler={closeHandler}
          cancelButtonId="UF33m93cAki1izs"
          saveHandler={handleSubmit}
          saveButtonId="eFeF9B69u2XrjQ3"
        />
      </Box>
    </>
  );
}

StepConfiguratorDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};
