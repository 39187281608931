import {
  Button,
  FormControl,
  TableCell,
  TableRow,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import uniqid from 'uniqid';
import { useState } from 'react';
import localErrors from 'lodash/_SetCache';
import { ConfirmDialog } from '../../../Dialogs';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';
import { useCustomSnackbar } from '../../../_hooks';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Menu tab row component.
 *
 * @param {object} props - root props
 * @param {number} props.index - element index
 * @param {Array} props.elementsDictionary - elements dictionary
 * @param {Function} props.removeItem - remove item
 * @param {object} props.menuTabData - menu tab data
 * @returns {MenuTabRow}
 */
export function MenuTabRow({
  index,
  elementsDictionary,
  removeItem,
  menuTabData,
}) {
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const { successNotification } = useCustomSnackbar();

  const {
    control,
  } = useFormContext();

  const removeMenuTab = async () => {
    if (menuTabData.id.substring(0, 3) !== 'new') {
      const { statusSuccess } = await request.delete(`${API_ROUTE.menuTabs}/${menuTabData.id}`);

      if (!statusSuccess) {
        localErrors.push({
          key: `errorMenuTab${menuTabData.id}`,
          message: `Nie udało się usunąć zakładki ${menuTabData.templateMenuTab.name}.`,
        });

        return;
      }
    }

    successNotification(`Usunięto pozycję menu - ${menuTabData.templateMenuTab.name}`);

    removeItem(index);

    setConfirmationDialog(false);
  };

  return (
    <Draggable draggableId={menuTabData.id} index={index} key={index}>
      {(provided) => (
        <TableRow
          key={menuTabData.id}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          index={index}
        >
          <TableCell>
            {menuTabData.templateMenuTab.name}
          </TableCell>
          <TableCell>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name={`menuTabs.${index}.templateElements`}
                control={control}
                render={({
                  field: {
                    onChange,
                    value,
                    name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <CustomAutocomplete
                    id={name}
                    multiple
                    options={elementsDictionary.sort((a, b) => a.label.localeCompare(b.label)).map(({
                      id, label, '@id': elementDictionaryIri,
                    }) => ({
                      id,
                      label,
                      '@id': elementDictionaryIri,
                    }))}
                    optionsMapKeys={['id', 'label']}
                    initialValue={value.map((item) => item.id)}
                    renderSelectAllButton={false}
                    onChange={(event) => {
                      onChange(elementsDictionary.filter((element) => event.target.value.includes(element.id)));
                    }}
                    textFieldProps={{
                      error: !!error,
                      name,
                      label: 'Dodaj element',
                    }}
                    error={error}
                  />
                )}
              />
            </FormControl>

          </TableCell>
          <TableCell>
            <Button
              id={uniqid()}
              onClick={() => setConfirmationDialog(true)}
              variant="contained"
              color="primary"
              aria-label={`Usuń menu '${menuTabData.templateMenuTab.name}'`}
            >
              Usuń
            </Button>
            <ConfirmDialog
              closeHandler={() => setConfirmationDialog(false)}
              open={confirmationDialog}
              confirmButtonHandler={removeMenuTab}
              dialogTitle="Usuń pozycję menu"
              confirmButtonLabel="Tak"
              cancelButtonColor="primary"
              cancelButtonLabel="Nie"
            >
              {`Czy chcesz usunąć pozycję menu - ${menuTabData.templateMenuTab.name}`}
            </ConfirmDialog>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
}

MenuTabRow.propTypes = {
  index: PropTypes.number.isRequired,
  elementsDictionary: PropTypes.arrayOf(Object).isRequired,
  removeItem: PropTypes.func.isRequired,
  menuTabData: PropTypes.instanceOf(Object).isRequired,
};
