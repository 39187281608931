import { format } from 'date-fns';
import { pl } from 'date-fns/locale/pl';
import { DATE } from '../_constants';

/**
 * Converts date string to properly formatted date.
 *
 * @param {string|null} dateString - date string or empty
 * @param {string} dateFormat - returned format of the date
 * @param {string} placeholder - placeholder
 * @returns {string} - formatted date string
 */
export const formatDateString = (
  dateString,
  dateFormat = DATE.defaultFormat,
  placeholder = '',
) => (dateString
  ? format(new Date(dateString), dateFormat, { locale: pl }) : placeholder);

/**
 * Converts date string to Date object.
 *
 * @param {string|null} dateString - input date
 * @returns {Date|null} - date or null
 */
export const dateFromString = (dateString) => {
  if (dateString === null || !dateString) {
    return null;
  }

  return new Date(dateString);
};
