import { useFormContext } from 'react-hook-form';
import {
  Box,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { calculateCoFinancingPercent } from './helperFunctions';
import { useElementContext } from '../../../../../Context';
import { SaveCancelButtons } from '../../../../SaveCancelButtons';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';
import { useDictionaryLoader } from '../../../../DictionaryProvider/useDictionaryLoader';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  BOOLEAN_VALUES,
  DICTIONARIES,
  EXPENSES_TYPE,
} from '../../../../../_constants';
import { FieldSorter } from '../../FieldSorter';
import CharsCounter from '../../../../CharsCounter';
import { FormField } from '../../../../Application/Application/FormField';
import { Alert } from '../../../../Alert';
import {
  MonetaryField,
  parsePastedText,
} from '../../../../MonetaryField';
import { CustomAutocomplete } from '../../../../CustomAutocomplete/CustomAutocomplete';
import { getImplementersAndApplicantName } from '../../../../../_helpers';
import { useCustomSnackbar } from '../../../../../_hooks';

/**
 * Actual expenses form
 *
 * @param {object} props - root props
 * @param {string} props.itemIndex - expenses index
 * @param {Function} props.handleClose - handle close dialog
 * @param {Function} props.reloadData - reload data from API
 * @param {boolean} props.indirectCosts - is indirect costs
 * @param {string} props.taskType - task type name
 * @param {string} props.taskId - task id
 * @returns {ActualExpensesForm}
 */
function ActualExpensesForm({
  itemIndex, handleClose, reloadData, indirectCosts, taskType, taskId,
}) {
  const { id: applicationId } = useParams();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const {
    setValue, watch, getValues, handleSubmit,
  } = useFormContext();
  const {
    id: elementId, fieldsConfig, isReadonly,
  } = useElementContext();
  const expenseId = watch(`actual_expenses-${elementId}.${itemIndex}`)?.actual_expense_id || '';
  const [isSubmitting, setSubmitting] = useState(false);
  const [fixedDecimalScale, setFixedDecimalScale] = useState({
    [`actual_expenses-${elementId}.${itemIndex}.total_expenses`]: true,
    [`actual_expenses-${elementId}.${itemIndex}.eligible_expenses`]: true,
    [`actual_expenses-${elementId}.${itemIndex}.co_financing`]: true,
    [`actual_expenses-${elementId}.${itemIndex}.vat`]: true,
  });

  const actualExpensesConfig = fieldsConfig[`actual_expenses-${elementId}`];
  const subfields = actualExpensesConfig.fields || {};

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    {
      name: `costCategoryActualExpenses-${elementId}-${taskId}`,
      // eslint-disable-next-line max-len
      path: `${API_ROUTE.elementDictionaries.costCategories}?templateElement.id=${elementId}&displayInIndirectTasks=${BOOLEAN_VALUES[indirectCosts.toString().toUpperCase()]}${taskType ? `&taskTypes.name=${taskType}` : ''}&itemsPerPage=100`,
    },
    DICTIONARIES.digitalCostCategories,
    DICTIONARIES.cstLimitCategories,
  );

  const setCalculatedCoFinancingPercent = () => {
    const coFinancingValue = getValues(`actual_expenses-${elementId}.${itemIndex}.co_financing`);
    const eligibleExpensesValue = getValues(`actual_expenses-${elementId}.${itemIndex}.eligible_expenses`);

    setValue(
      `actual_expenses-${elementId}.${itemIndex}.co_financing_percent`,
      calculateCoFinancingPercent(coFinancingValue, eligibleExpensesValue)
    );
  };

  const onSubmit = async (formData) => {
    setSubmitting(true);
    const actualExpensesValues = formData[`actual_expenses-${elementId}`][itemIndex];
    const payload = {
      ...actualExpensesValues,
      optimal_number_sme_ventures_be_supported: actualExpensesValues
        ?.optimal_number_sme_ventures_be_supported?.toString() || '',
      vat: actualExpensesValues?.vat?.toString() || '',
      description_and_justification: actualExpensesValues?.description_and_justification?.replace(/[\r\n]/gm, '\n'),
      estimation_methods: actualExpensesValues?.estimation_methods?.replace(/[\r\n]/gm, '\n'),
      name: actualExpensesValues?.name?.replace(/[\r\n]/gm, '\n'),

    };
    delete payload.id;

    const {
      payload: response, statusSuccess,
    } = expenseId
      ? await request.put(`${API_ROUTE.applicationExpenses
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/actual-expenses/${expenseId}`, payload)
      : await request.post(`${API_ROUTE.applicationExpenses.replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/actual-expenses`, payload);

    statusSuccess
      ? successNotification(`Wydatek został ${expenseId ? 'edytowany' : 'dodany'}.`)
      : errorNotification(`Nie udało się ${expenseId ? 'edytować' : 'dodać'} wydatku. Spróbuj ponownie.`);
    if (!statusSuccess) {
      handleClose(true);

      return;
    }

    setValue(`actual_expenses-${elementId}.${itemIndex}`, response);
    reloadData(elementId, EXPENSES_TYPE.actualExpenses);
    handleClose();
  };

  const handleFixedDecimalScale = (fieldName, value) => {
    setFixedDecimalScale((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const filteredCostCategory = get(`costCategoryActualExpenses-${elementId}-${taskId}`)
    .filter((category) => category.lumpSumType === null);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      <FieldSorter fieldsOrder={Object.keys(subfields)}>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.name`}>
          {({
            name, onChange, value, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              onChange={onChange}
              onBlur={onBlur}
              required
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              error={!!error}
              helperText={error?.message}
              disabled={isReadonly}
              multiline
              minRows={2}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.cost_category`}>
          {({
            name, onChange, value, filterOptions, onBlur, error,
          }) => (
            <CustomAutocomplete
              id={name}
              options={filterOptions(filteredCostCategory)}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              error={error}
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.total_expenses`}>
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              error={!!error}
              helperText={error?.message}
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField
          name={`actual_expenses-${elementId}.${itemIndex}.eligible_expenses`}
          rules={{
            validate: {
              isLessThan: (
                value,
                formData,
              ) => Number(value) === Number(formData[`actual_expenses-${elementId}`][itemIndex].total_expenses)
                || Number(value) < Number(formData[`actual_expenses-${elementId}`][itemIndex].total_expenses)
                || 'Wartość w polu "Wydatki kwalifikowalne" nie może być większa niż wartość w polu "Wydatki ogółem"',
            },
          }}
        >
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                setCalculatedCoFinancingPercent();
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField
          name={`actual_expenses-${elementId}.${itemIndex}.co_financing`}
          rules={{
            validate: {
              isLessThan: (
                value,
                formData,
              ) => Number(value) === Number(formData[`actual_expenses-${elementId}`][itemIndex].eligible_expenses)
                || Number(value) < Number(formData[`actual_expenses-${elementId}`][itemIndex].eligible_expenses)
            || 'Wartość w polu "Dofinansowanie" nie może być większa niż wartość w polu "Wydatki kwalifikowalne"',
            },
          }}
        >
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                setCalculatedCoFinancingPercent();
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.co_financing_percent`}>
          {({
            name,
          }) => (
            <Alert
              severity="status"
              sx={{
                minHeight: '50px',
                margin: 0,
              }}
            >
              {watch(name)}
            </Alert>
          )}
        </FormField>
        {getValues('has_implementers') === BOOLEAN_VALUES.TRUE && (
          <FormField name={`actual_expenses-${elementId}.${itemIndex}.implementer_id`}>
            {({
              name, onChange, value, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={getImplementersAndApplicantName(getValues, applicationId)}
                error={error}
              />
            )}
          </FormField>
        )}
        <FormField
          name={`actual_expenses-${elementId}.${itemIndex}.vat`}
          rules={{
            validate: {
              isLessThan: (
                value,
                formData,
              ) => Number(value) === Number(formData[`actual_expenses-${elementId}`][itemIndex].eligible_expenses)
                || Number(value) < Number(formData[`actual_expenses-${elementId}`][itemIndex].eligible_expenses)
            || 'Wartość w polu "w tym VAT" nie może być większa niż wartość w polu "Wydatki kwalifikowalne"',
            },
          }}

        >
          {({
            name, onChange, value, onBlur, error,
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              variant="outlined"
              value={value}
              onChange={onChange}
              onBlur={(event) => {
                handleFixedDecimalScale(name, true);
                onBlur(event);
              }}
              onFocus={() => handleFixedDecimalScale(name, false)}
              InputProps={{
                inputComponent: MonetaryField,
              }}
              inputProps={{
                allowNegative: false,
                fixedDecimalScale: fixedDecimalScale[name],
                allowedDecimalSeparators: ['.', ','],
              }}
              onPaste={(event) => parsePastedText(event, onChange)}
              required
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.subcontracts`}>
          {({
            onChange, value, name, allChoices, error,
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              options={allChoices}
              error={error}
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.description_and_justification`}>
          {({
            name, onChange, value, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              onChange={onChange}
              onBlur={onBlur}
              required
              multiline
              minRows={2}
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              error={!!error}
              helperText={error?.message}
              disabled={isReadonly}
            />
          )}
        </FormField>
        <FormField
          name={`actual_expenses-${elementId}.${itemIndex}.subcontracting`}
          rules={{
            required: {
              value: true,
              message: 'To pole nie może być puste.',
            },
          }}
        >
          {({
            name, onChange, value, allChoices, error,
          }) => (
            <CustomAutocomplete
              id={name}
              options={allChoices}
              initialValue={value}
              onChange={onChange}
              error={error}
              disabled={isReadonly}
              required
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.estimation_methods`}>
          {({
            name, onChange, value, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              onChange={onChange}
              onBlur={onBlur}
              required
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              error={!!error}
              helperText={error?.message}
              disabled={isReadonly}
              multiline
              minRows={2}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.digital_cost_category_id`}>
          {({
            name, onChange, value, onBlur, error, filterOptions,
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              options={filterOptions(get(DICTIONARIES.digitalCostCategories.name))}
              error={error}
            />
          )}
        </FormField>
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.spending_on_accessibility`}>
          {({
            name, onChange, value, onBlur, error, filterOptions,
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              options={filterOptions(get(DICTIONARIES.cstLimitCategories.name))}
              error={error}
            />
          )}
        </FormField>
        {/* Optymalna liczba przedsięwzięć MŚP do objęcia wsparciem */}
        <FormField name={`actual_expenses-${elementId}.${itemIndex}.optimal_number_sme_ventures_be_supported`}>
          {({
            onChange, value, name, onBlur, error,
          }) => (
            <NumericFormat
              id={name}
              name={name}
              onValueChange={(values) => onChange({
                target: {
                  name,
                  value: values.floatValue,
                },
              })}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              error={!!error}
              helperText={error?.message}
              customInput={TextField}
              allowNegative={false}
              decimalScale={0}
            />
          )}
        </FormField>
      </FieldSorter>
      <Box display="flex" justifyContent="center" mt={2} alignItems="center" sx={{ width: '100%' }}>
        <SaveCancelButtons
          cancelHandler={() => handleClose(true)}
          cancelButtonId="iGCy9YRAdcLnn5o"
          saveHandler={handleSubmit(onSubmit)}
          saveButtonId="Cr2LDc5YaWDF2XT"
          saveDisabled={isSubmitting || isReadonly}
        />
      </Box>
    </>
  );
}

export default ActualExpensesForm;

ActualExpensesForm.propTypes = {
  itemIndex: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  indirectCosts: PropTypes.bool.isRequired,
  taskType: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
};
