export const languageSkillsFormValidation = {
  english: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Angielski"',
    },
  },
  german: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Niemiecki"',
    },
  },
  french: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Francuski"',
    },
  },
  polish: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Polski"',
    },
  },
};
