import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useElementContext } from '../../../Context';
import {
  CHARS_LIMIT,
  DICTIONARIES,
} from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getClusterMembersAndApplicantName,
} from '../../../_helpers';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * Cluster internationalization application element.
 *
 * @returns {ClusterInternationalization}
 */
export function ClusterInternationalization() {
  const { id: applicationId } = useParams();
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue, getValues,
  } = useFormContext();

  const clusterMembers = 'cluster_members';
  const {
    fields: clusterMemersFields, append: clusterMembersAppend, remove: clusterMembersRemove,
  } = useFieldArray({
    control,
    name: clusterMembers,
  });
  const collectionName = 'internationalisation_events_services';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.countries,
    DICTIONARIES.eventServiceTypes,
    DICTIONARIES.companySize,
  );

  const clusterMembersConfig = fieldsConfig[clusterMembers];
  const clusterMembersSubfields = clusterMembersConfig?.fields || {};
  const clusterMembersFieldObject = getCollectionFieldObject(clusterMembersSubfields, clusterMemersFields);

  const eventsServicesConfig = fieldsConfig[collectionName];
  const subfields = eventsServicesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber: clusterMembersMaxRowNumber,
    minRowNumber: clusterMembersMinRowNumber,
  } = clusterMembersConfig || {};

  const {
    maxRowNumber,
    minRowNumber,
  } = eventsServicesConfig || {};

  useEffect(() => {
    if (clusterMemersFields.length === 0 && clusterMembersMinRowNumber > 0) {
      for (let i = 1; i <= clusterMembersMinRowNumber; i++) {
        clusterMembersAppend(getCollectionFieldObject(clusterMembersSubfields, clusterMemersFields, i));
      }
    }
  }, [clusterMembersMaxRowNumber, clusterMembersMinRowNumber]);

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {/* Tabela: Członkowie klastra biorący udział w procesie internacjonalizacji */}
      <LabeledFieldRow fieldConfig={clusterMembersConfig} contextHelpLabel fullWidth>
        {clusterMemersFields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              clusterMemersFields,
              clusterMembersMinRowNumber,
              clusterMembersRemove,
              setValue,
              clusterMembers,
              index,
            )}
            buttonRemoveVisible={clusterMemersFields.length > clusterMembersMinRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(clusterMembersSubfields)}
            key={item.id}
            isIndexed
          >
            {/* Nazwa członka klastra */}
            <FormField name={`${clusterMembers}.${index}.name`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* NIP */}
            <FormField name={`${clusterMembers}.${index}.nip`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  value={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ImprovedMaskedInput,
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? CHARS_LIMIT.NIP} />,
                  }}
                  inputProps={{
                    mask: '0000000000',
                    maxLength: maxLength ?? CHARS_LIMIT.NIP,
                  }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Wielkość przedsiębiorstwa */}
            <FormField name={`${clusterMembers}.${index}.enterprise_size`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.companySize.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Dotychczasowe rynki zagraniczne sprzedaży produktów przeznaczonych do internacjonalizacji */}
            <FormField name={`${clusterMembers}.${index}.existing_markets`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  renderSelectAllButton={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.countries.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Rynki, których dotyczą działania z zakresu internacjonalizacji */}
            <FormField name={`${clusterMembers}.${index}.markets_affected_by_internationalization_activities`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={filterOptions(get(DICTIONARIES.countries.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Produkty podlegające internacjonalizacji */}
            <FormField name={`${clusterMembers}.${index}.internationalized_products`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Potencjał organizacyjny i finansowy przedsiębiorcy do internacjonalizacji */}
            <FormField name={`${clusterMembers}.${index}.organizational_and_financial_potential`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Opis i uzasadnienie zaplanowanych usług w zakresie internacjonalizacji  na rzecz przedsiębiorcy */}
            <FormField name={`${clusterMembers}.${index}.planned_services_description_and_justification`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && clusterMemersFields.length < clusterMembersMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="HDehMyT1qEad09h"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                clusterMemersFields,
                clusterMembersMaxRowNumber,
                clusterMembersAppend,
                clusterMembersFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>

      {/* Tabela: Wydarzenia/ usługi z zakresu internacjonalizacji */}
      <LabeledFieldRow
        fieldConfig={eventsServicesConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={item.id}
            isIndexed
          >
            {/* Nazwa wydarzenia/ usługi */}
            <FormField name={`${collectionName}.${index}.event_name`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Rodzaj wydarzenia/usługi */}
            <FormField name={`${collectionName}.${index}.event_service_types`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.eventServiceTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Planowana data rozpoczęcia */}
            <FormField name={`${collectionName}.${index}.start_date`}>
              {({
                name, onChange, value, error, onBlur, label,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Planowana data zakończenia */}
            <FormField name={`${collectionName}.${index}.end_date`}>
              {({
                name, onChange, value, label, error, onBlur,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Lokalizacja (państwo) */}
            <FormField name={`${collectionName}.${index}.country`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={filterOptions(get(DICTIONARIES.countries.name))}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Członkowie klastra biorący udział w wydarzeniu */}
            <FormField name={`${collectionName}.${index}.cluster_members_participating_in_event`}>
              {({
                name, onChange, value, error, onBlur,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={getClusterMembersAndApplicantName(getValues, applicationId)}
                  disabled={isReadonly}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Opis sposobu organizacji, w tym szczegółowego zakresu usługi, wraz z uzasadnieniem */}
            <FormField name={`${collectionName}.${index}.description_how_to_organize`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="o8Z2KU9uMMaEJxR"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </>
  );
}
