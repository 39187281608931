import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { request } from '../_services';
import {
  ADDITIONAL_MODULE_IDS,
  ADDITIONAL_MODULE_LABELS,
  API_ROUTE,
  URL,
} from '../_constants';
import {
  getExternalIdsToCriteriaItem,
  iriToId,
} from '../_helpers';
import { useGlobalContext } from './Global.context';
import { ContainerLoader } from '../Features/Application/Application/ContainerLoader';
import { useReloadListener } from './ReloadListener.context';

/**
 * Protest form context wrapper.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
const ProtestFormContext = createContext({
  /**
   * Application identifier.
   */
  applicationId: '',
  /**
   * Recruitment identifier.
   */
  recruitmentId: '',
  /**
   * Is read only mode.
   *
   */
  isReadonly: false,
  /**
   * Has application modules.
   *
   */
  hasApplicationModules: false,
  /**
   * CriteriaSetModules data.
   *
   */
  criteriaSetModules: [],
  /**
   * Criteria items data.
   *
   */
  criteriaItems: [],
  /**
   * Protest data.
   *
   */
  protestData: {},
  /**
   * Function to update protest data.
   *
   */
  setProtestData: () => {},
  /**
   * Protest evaluation data.
   *
   */
  protestEvaluationData: {},
  /**
   * Function to update protest evaluation data.
   *
   */
  setProtestEvaluationData: () => {},
  /**
   * Function to reload data from API.
   *
   */
  reloadData: () => {},
  /**
   * Function to close modal.
   *
   */
  closeHandler: () => {},
});

/**
 * Protest form context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ProtestFormContextProvider({
  children, value,
}) {
  const [applicationData, setApplicationData] = useState(null);
  const { id: applicationId } = useParams();
  const [criteriaSetModules, setCriteriaSetModules] = useState([]);
  const [criteriaItems, setCriteriaItems] = useState([]);
  const [protestData, setProtestData] = useState(value.initialData || null);
  const [protestEvaluationData, setProtestEvaluationData] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const { notify } = useGlobalContext();
  const navigate = useNavigate();
  const protestEvaluationId = useMemo(() => protestData?.protestEvaluation
    && iriToId(protestData.protestEvaluation), [protestData]);
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'protests-list';

  const hasApplicationModules = applicationData?.facultativeModules?.length > 0
    || applicationData?.obligatoryModules?.length > 0;

  const loadApplicationData = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.applications}/${applicationId}`);
    if (!statusSuccess) {
      navigate(URL.recruitment.submittedApplications);
      setTimeout(() => {
        notify('Nie udało się wczytać danych wniosku.', 'error');
      }, 100);

      return;
    }
    setApplicationData(payload);
  };
  const loadExpertCriteriaSets = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertCriteriaSets}?recruitmentId=${applicationData?.recruitment?.id}`);

    if (statusSuccess) {
      const selectedModulesIds = [
        ...applicationData.facultativeModules,
        ...applicationData.obligatoryModules,
      ].map(({ id }) => id);

      const evaluationItems = payload[0]?.items
        ?.filter(({ moduleId }) => selectedModulesIds.includes(moduleId) || moduleId === null || moduleId === '')
        ?.map((evaluationSheetItem) => ({
          ...evaluationSheetItem,
          moduleId: getExternalIdsToCriteriaItem(
            evaluationSheetItem.businessGroup,
            evaluationSheetItem.moduleId,
          ),
        }));

      const combinedModules = [];

      payload[0]?.modules
        ?.filter(({ moduleId }) => selectedModulesIds.includes(moduleId) || moduleId === null || moduleId === '')
        ?.forEach((module) => {
          if (module.moduleId === null) {
            combinedModules.push(
              {
                ...module,
                moduleId: ADDITIONAL_MODULE_IDS.ocenaFinansowa,
              },
              {
                moduleName: ADDITIONAL_MODULE_LABELS.modulowaFinansowa,
                moduleId: ADDITIONAL_MODULE_IDS.modulowaFinansowa,
              }
            );

            return;
          }

          combinedModules.push({
            ...module,
          });
        });

      combinedModules.push({
        moduleId: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
        moduleName: ADDITIONAL_MODULE_LABELS.kryteriaOgolem,
      });

      setCriteriaSetModules(combinedModules);

      if (hasApplicationModules) {
        setCriteriaItems(evaluationItems.map(({
          moduleId, '@id': criteriaItemId, name,
        }) => {
          const module = combinedModules.find((item) => item.moduleId === moduleId)?.moduleName;

          return ({
            id: criteriaItemId,
            label: module ? `${module}: ${name}` : `Kryteria ogólne: ${name}`,
          });
        }).sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB)));

        if (!protestData.protestEvaluation) {
          setLoaded(true);
        }

        return;
      }

      setCriteriaItems(evaluationItems?.map(({
        '@id': criteriaItemId, name: label,
      }) => ({
        id: criteriaItemId,
        label,
      })));

      if (!protestData.protestEvaluation) {
        setLoaded(true);
      }

      return;
    }

    notify('Nie udało się wczytać wymaganych danych. Spróbuj ponownie.', 'error');
    navigate(-1);
  };
  const loadProtestEvaluationData = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.protestEvaluations}/${protestEvaluationId}`);

    if (statusSuccess) {
      setProtestEvaluationData({
        ...payload,
        requirements: payload.requirements
          .sort((
            { verifiedAt: verifiedAtA },
            { verifiedAt: verifiedAtB }
          ) => new Date(verifiedAtA) - new Date(verifiedAtB)),
      });

      setLoaded(true);

      return;
    }

    notify('Nie udało się wczytać wymaganych danych. Spróbuj ponownie.', 'error');
    navigate(-1);
  };

  useEffect(() => {
    loadApplicationData();
  }, []);

  useEffect(() => {
    if (applicationData) {
      loadExpertCriteriaSets();
    }
  }, [applicationData]);

  useEffect(() => {
    if (protestData?.protestEvaluation) {
      loadProtestEvaluationData();
    }
  }, [protestData]);

  const store = useMemo(() => ({
    closeHandler: () => navigate(-1),
    isReadonly: value.isReadonly,
    reloadData: () => reload(watcherName),
    applicationId,
    recruitmentId: applicationData?.recruitment?.id,
    criteriaSetModules,
    criteriaItems,
    protestData,
    setProtestData,
    protestEvaluationData,
    setProtestEvaluationData,
    hasApplicationModules,
  }), [applicationData, criteriaItems, criteriaSetModules, protestData, protestEvaluationData]);

  return (
    <ProtestFormContext.Provider value={store}>
      {!isLoaded || !applicationData ? <ContainerLoader /> : children}
    </ProtestFormContext.Provider>
  );
}

ProtestFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

/**
 * ProtestFormContext hook consumer.
 *
 * @returns {object}
 */
export const useProtestFormContext = () => useContext(ProtestFormContext);
