import PropTypes from 'prop-types';
import {
  Link,
  Text,
  View,
} from '@react-pdf/renderer';
import { htmlTagsStyle } from '../../htmlTags.style';

/**
 * HtmlTag element.
 *
 * @param {object} props - root props
 * @param {object} props.tagData - tag data
 * @returns {HtmlTag}
 */
function HtmlTag({ tagData }) {
  const TAG_NAMES = {
    a: <Link src={tagData.href}>{tagData?.content}</Link>,
    li: (
      <View style={htmlTagsStyle.liStyles}>
        <Text>
          {`• ${tagData.content}`}
        </Text>
      </View>
    ),
  };
  const getContent = () => (Array.isArray(tagData.content)
    ? tagData.content.map((childTagData) => <HtmlTag tagData={childTagData} />)
    : (
      <>
        {tagData.tagName in TAG_NAMES ? TAG_NAMES[tagData.tagName] : tagData.content}
      </>
    ));
  const getListWrapper = () => (
    <View style={htmlTagsStyle.list}>
      {getContent()}
    </View>
  );

  if (tagData.tagName === 'ul' || tagData.tagName === 'ol') {
    return getListWrapper();
  }

  return (
    <Text style={tagData.tagName in htmlTagsStyle ? htmlTagsStyle[tagData.tagName] : {}}>
      {getContent()}
    </Text>
  );
}

export default HtmlTag;

HtmlTag.propTypes = {
  tagData: PropTypes.objectOf(Object).isRequired,
};
