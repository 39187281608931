import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useElementContext } from '../../../Context';
import { FieldSorter } from './FieldSorter';
import { FormField } from '../../Application/Application/FormField';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import CharsCounter from '../../CharsCounter';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * NationalKeyClusterParticipation element renderer.
 *
 * @returns {NationalKeyClusterParticipation}
 */
export function NationalKeyClusterParticipation() {
  const {
    isReadonly,
  } = useElementContext();
  const {
    watch, reset,
  } = useFormContext();

  return (
    <FieldSorter>
      {/* Liczba zamkniętych lat obrotowych trwających co najmniej 12 miesięcy kalendarzowych */}
      <FormField name="national_key_cluster_participation_number_closed_years">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <NumericFormat
            id={name}
            name={name}
            onValueChange={(values) => onChange({
              target: {
                name,
                value: values.floatValue,
              },
            })}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
          />
        )}
      </FormField>
      {/* Wnioskodawca jest członkiem klastra posiadającego status Krajowego Klastra Kluczowego
         (co najmniej 6 miesięcy) */}
      <FormField name="national_key_cluster_participation_member">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  national_key_cluster_participation_name: FIELD_TYPES_MAP.text,
                  national_key_cluster_participation_date: FIELD_TYPES_MAP.date,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('national_key_cluster_participation_member') === BOOLEAN_VALUES.TRUE) && (
        <>
          {/* Nazwa Krajowego Klastra Kluczowego, którego członkiem jest wnioskodawca */}
          <FormField name="national_key_cluster_participation_name">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                multiline
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Data wstąpienia do Krajowego Klastra Kluczowego */}
          <FormField name="national_key_cluster_participation_date">
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                ariaLabel={label}
                error={error}
              />
            )}
          </FormField>
        </>
      )}
    </FieldSorter>
  );
}
