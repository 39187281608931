export const getTransformedIndicatorsByModules = (indicatorsElements, modules) => modules.map((module) => ({
  ...module,
  templateElementId: indicatorsElements
    .find(({ modules: elementModules }) => (elementModules.includes(module.moduleId)
      || (elementModules.length === 0 && module.moduleId === 'wskazniki_ogolne_dla_projektu')))?.id,
  elementDefinition: indicatorsElements
    .find(({ modules: elementModules }) => (elementModules.includes(module.moduleId)
      || (elementModules.length === 0 && module.moduleId === 'wskazniki_ogolne_dla_projektu')))?.elementDefinition,
})).filter(({ templateElementId }) => templateElementId);

export const getIndicatorsToResolve = (indicatorsElement) => indicatorsElement
  .map(({ templateElementId }) => templateElementId);
