const mapImplementers = (implementer) => ({
  '@id': implementer.set_uuid,
  name: implementer.name,
});

export const getImplementers = (getValues) => getValues('implementers')?.map(mapImplementers) || [];

export const getImplementersAndApplicantName = (getValues, applicationId) => {
  const applicantData = [{
    '@id': `/lsi/recruitments/api/applications/${applicationId}`,
    name: getValues('applicant_name') || '',
  }];

  return [...getImplementers(getValues), ...applicantData].filter((applicant) => applicant.name !== ''
    && applicant.name?.length > 0);
};

export const getImplementersAndApplicantNameForPdf = (applicantName, implementers, applicationId) => [
  {
    '@id': `/lsi/recruitments/api/applications/${applicationId}`,
    name: applicantName || '',
  },
  ...implementers.map(mapImplementers),
];
