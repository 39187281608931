import { useEffect } from 'react';
import { ACTION_TYPES } from '../_constants';
import { useGlobalContext } from '../Context';

/**
 * Use page lock hook.
 *
 * @param {boolean} isDirty - is dirty
 * @param {string | null} confirmMessage - confirm message
 */
export function usePageLock(isDirty, confirmMessage) {
  const message = confirmMessage || 'Formularz zawiera niezapisane dane. Czy chcesz zamknąć formularz i utracić dane?';
  const { handleAction } = useGlobalContext();

  const handlePageLock = () => {
    if (isDirty) {
      handleAction({
        type: ACTION_TYPES.SET_PAGE_LOCK,
        message,
      });

      return;
    }
    handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  };

  useEffect(() => {
    handlePageLock();

    if (isDirty) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);
}
