import {
  Controller,
  useForm,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { useState } from 'react';
import { isValid } from 'date-fns';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { request } from '../../../_services';
import {
  API_ROUTE,
  CHARS_LIMIT,
  BOOLEAN_VALUES,
} from '../../../_constants';
import { Alert } from '../../Alert';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { handleError } from '../../../_helpers';
import { useGlobalDialog } from '../../../Context';

/**
 * Expert career entry edit/create form.
 *
 * @param {object} props - root props
 * @param {object|null} props.existingData - existing data to edit
 * @param {Function} props.reloadData - function invoked on form submit success
 * @param {boolean} props.isReadonly - is read only
 * @returns {CareerForm}
 */
export function CareerForm({
  existingData,
  reloadData,
  isReadonly,
}) {
  /**
   * Prevents submit button from multiple click.
   */
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const { closeAll } = useGlobalDialog();

  const {
    control, handleSubmit, setError, watch,
  } = useForm({
    defaultValues: {
      employerName: existingData?.employerName || '',
      employerPolish: existingData?.employerPolish || BOOLEAN_VALUES.TRUE,
      employerNip: existingData?.employerNip || '',
      address: existingData?.address || '',
      position: existingData?.position || '',
      positionDescription: existingData?.positionDescription || '',
      employedFrom: existingData?.employedFrom ? new Date(existingData.employedFrom) : null,
      employedTo: existingData?.employedTo ? new Date(existingData.employedTo) : null,
      employmentType: existingData?.employmentType || '',
    },
    mode: 'all',
  });

  /**
   * Send request to API for resource create.
   *
   * @param {object} formData - form data
   */
  const createHandler = async (formData) => {
    setSaveTriggered(true);

    const {
      statusSuccess, violations = [],
    } = await request.post(
      API_ROUTE.expertCareers,
      formData,
    );

    if (statusSuccess) {
      reloadData();
      closeAll();

      return;
    }

    handleError(setError, violations);

    setSaveError(true);
    setSaveTriggered(false);
  };

  /**
   * Send request to API for resource edit.
   *
   * @param {object} formData - form data
   */
  const editHandler = async (formData) => {
    const {
      statusSuccess, violations = [],
    } = await request.put(
      `${API_ROUTE.expertCareers}/${existingData.id}`,
      formData,
    );

    if (statusSuccess) {
      reloadData();
      closeAll();

      return;
    }

    handleError(setError, violations);

    setSaveError(true);
  };

  const isEmployerPolish = watch('employerPolish').toUpperCase() === BOOLEAN_VALUES.TRUE;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputLabel htmlFor="employerName">
            Nazwa pracodawcy
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Nazwa pracodawcy"',
                },
              }}
              name="employerName"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/${CHARS_LIMIT.MEDIUM}`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="employerPolish">
            Czy przedsiębiorstwo polskie?
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="employerPolish"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <>
                  <RadioGroup
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    row
                  >
                    <FormControlLabel
                      disabled={isReadonly}
                      value={BOOLEAN_VALUES.TRUE}
                      control={<Radio color="secondary" />}
                      label="Tak"
                    />
                    <FormControlLabel
                      disabled={isReadonly}
                      value={BOOLEAN_VALUES.FALSE}
                      control={<Radio color="secondary" />}
                      label="Nie"
                    />
                  </RadioGroup>
                  <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <InputLabel htmlFor="employerNip">
            {isEmployerPolish ? 'NIP pracodawcy' : 'Numer identyfikacji podatkowej'}
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: isEmployerPolish
                    ? 'Proszę uzupełnić pole "NIP pracodawcy"'
                    : 'Proszę uzupełnić pole "Numer identyfikacji podatkowej"',
                },
                pattern: isEmployerPolish
                  ? {
                    value: /^\d{10}$/,
                    message: 'Nieprawidłowa wartość w polu "NIP pracodawcy"',
                  } : null,
              }}
              name="employerNip"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/${isEmployerPolish ? CHARS_LIMIT.NIP : CHARS_LIMIT.MEDIUM}`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: isEmployerPolish ? CHARS_LIMIT.NIP : CHARS_LIMIT.MEDIUM }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="address">
            Adres pracodawcy
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Adres"',
                },
              }}
              name="address"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/250`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 250 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="position">
            Stanowisko
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Stanowisko"',
                },
              }}
              name="position"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/${CHARS_LIMIT.MEDIUM}`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="positionDescription" sx={{ whiteSpace: 'unset' }}>
            Opis stanowiska / Doświadczenie / Kompetencje zawodowe
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Opis stanowiska/Doświadczenie/Kompetencje zawodowe"',
                },
              }}
              name="positionDescription"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/10000`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 10000 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="employedFrom">
            Data zatrudnienia od
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                validate: {
                  isValidDate: (dateValue) => isValid(dateValue) || 'Nieprawidłowa wartość',
                },
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Data zatrudnienia od"',
                },
              }}
              name="employedFrom"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  error={!!error}
                  helperText={error?.message}
                  ariaLabel="Data zatrudnienia od"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="employedTo">
            Data zatrudnienia do
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                validate: {
                  isValidDate: (dateValue) => (!dateValue || isValid(dateValue)) || 'Nieprawidłowa wartość',
                },
              }}
              name="employedTo"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  error={!!error}
                  helperText={error?.message || 'Pozostaw pole puste jeżeli zatrudnienie nadal trwa.'}
                  ariaLabel="Data zatrudnienia do"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="employmentType" sx={{ whiteSpace: 'unset' }}>
            Forma zatrudnienia
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Forma zatrudnienia"',
                },
              }}
              name="employmentType"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${value.length}/100`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 100 }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      {saveError && (
        <Alert severity="error">Wystąpił nieoczekiwany błąd zapisu.</Alert>
      )}
      {!isReadonly && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: (theme) => theme.spacing(5),
          marginBottom: (theme) => theme.spacing(2),
        }}
        >
          <SaveCancelButtons
            saveButtonId="UektN4FaEtaEt5F"
            cancelButtonId="19NQfmNe7u5AKFW"
            cancelHandler={closeAll}
            saveHandler={handleSubmit(existingData === null ? createHandler : editHandler)}
            saveDisabled={saveTriggered}
          />
        </Box>
      )}
    </>
  );
}

CareerForm.propTypes = {
  existingData: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.oneOf([null]),
  ]),
  reloadData: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool,
};

CareerForm.defaultProps = {
  existingData: null,
  isReadonly: false,
};
