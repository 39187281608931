/* eslint-disable */
export const REVIEW_EXPERT_STATEMENT_TEMPLATE = {
  firstList: [
    {
      pointDecor: 'I.',
      label: 'Na podstawie art. 85 ust. 2 ustawy z dnia 28 kwietnia 2022 r. o zasadach realizacji zadań finansowanych ze środków europejskich w perspektywie finansowej 2021-2027 (Dz. U. z 2022 r. poz. 1079) i stosowanego odpowiednio art. 24 § 1 i 2 ustawy z dnia 14 czerwca 1960 r. - Kodeks postępowania administracyjnego (Dz. U. z 2024 r. poz. 572), oświadczam, że nie zachodzi żadna z okoliczności powodujących wyłączenie mnie z udziału w postępowaniu odwoławczym, w szczególności, że: ',
      children: [
        {
          pointDecor: '1)',
          label: 'nie jestem wnioskodawcą[1] i nie pozostaję z wnioskodawcą w takim stosunku prawnym, że wynik postępowania odwoławczego może mieć wpływ na moje prawa lub obowiązki, w tym:',
          children: [
            { label: 'nie jestem wspólnikiem, udziałowcem lub akcjonariuszem wnioskodawcy,', pointDecor: 'a)' },
            { label: 'nie jestem członkiem organów zarządzających lub nadzorczych wnioskodawcy,', pointDecor: 'b)' },
            { label: 'nie jestem związany stosunkiem pracy lub stosunkiem cywilnoprawnym z wnioskodawcą,', pointDecor: 'c)' },
            { label: 'nie pełnię jakiejkolwiek roli w ww. projekcie,', pointDecor: 'd)' },
            { label: 'nie jestem podwykonawcą[2] ww. projektu;[3]', pointDecor: 'e)' }
          ],
        },
        {
          pointDecor: '2)',
          label: 'nie pozostaję w związku małżeńskim, w stosunku pokrewieństwa lub powinowactwa do drugiego stopnia z wnioskodawcą lub z członkami organów zarządzających lub organów nadzorczych wnioskodawcy;',
        },
        {
          pointDecor: '3)',
          label: 'nie jestem związany/a z wnioskodawcą lub z członkami organów zarządzających lub organów nadzorczych wnioskodawcy z tytułu przysposobienia, kurateli lub opieki;',
        },
        {
          pointDecor: '4)',
          label: 'nie jestem przedstawicielem wnioskodawcy lub nie pozostaję w związku małżeńskim, w stosunku pokrewieństwa lub powinowactwa do drugiego stopnia z przedstawicielem wnioskodawcy ani nie jestem związany/a z przedstawicielem wnioskodawcy z tytułu przysposobienia, kurateli lub opieki;',
        },
        {
          pointDecor: '5)',
          label: 'nie pozostaję z wnioskodawcą w stosunku nadrzędności służbowej;',
        },
        {
          pointDecor: '6)',
          label: 'nie wszczęto przeciwko mnie postępowania karnego w sprawie wyboru ww. projektu do dofinansowania.',
        },
      ],
    },
  ],
  annotation: [
    {
      pointDecor: '[1]',
      label: 'Przez wnioskodawcę należy rozumieć podmiot, który złożył wniosek o dofinansowanie projektu.',
      isSmall: true,
    },
    {
      pointDecor: '[2]',
      label: 'Ekspert pozostający w stosunku pracy lub stosunku cywilnoprawnych z podwykonawcą będącym uczelnią, instytutem badawczym lub Polską Akademią Nauk, nie może brać udziału w postępowaniu odwoławczym dotyczącym wniosku o dofinansowanie, w którym ta jednostka jest podwykonawcą. Stosuje się jeżeli w projekcie występują podwykonawcy.',
      isSmall: true,
    },
    {
      pointDecor: '[3]',
      label: 'Stosuje się jeżeli w projekcie występują podwykonawcy.',
      isSmall: true,
    }
  ],
  cautions: {
    label: 'POUCZENIE: Oświadczenie w Części I jest składane pod rygorem odpowiedzialności karnej za składanie fałszywych oświadczeń zgodnie z art. 85 ust. 2 ustawy z dnia 28 kwietnia 2022 r. o zasadach realizacji zadań finansowanych ze środków europejskich w perspektywie finansowej 2021-2027 (Dz. U. z 2022 r. poz. 1079) w zw. z art. 233 § 6 ustawy z dnia 6 czerwca 1997 r. – Kodeks karny (Dz. U. z 2022 r. poz. 1138, z późn. zm.)*.',
    criminalCode: [
      {
        label: '*Art. 233 Kodeksu karnego',
        isBolded: true,
        children: [
          {
            pointDecor: '§ 1.',
            label: 'Kto, składając zeznanie mające służyć za dowód w postępowaniu sądowym lub w innym postępowaniu prowadzonym na podstawie ustawy, zeznaje nieprawdę lub zataja prawdę, podlega karze pozbawienia wolności od 6 miesięcy do lat 8.',
          },
          {
            pointDecor: '§ 1a.',
            label: 'Jeżeli sprawca czynu określonego w § 1 zeznaje nieprawdę lub zataja prawdę z obawy przed odpowiedzialnością karną grożącą jemu samemu lub jego najbliższym, podlega karze pozbawienia wolności od 3 miesięcy do lat 5.',
          },
          {
            pointDecor: '§ 2.',
            label: 'Warunkiem odpowiedzialności jest, aby przyjmujący zeznanie, działając w zakresie swoich uprawnień, uprzedził zeznającego o odpowiedzialności karnej za fałszywe zeznanie lub odebrał od niego przyrzeczenie.',
          },
          {
            pointDecor: '§ 3.',
            label: 'Nie podlega karze za czyn określony w § 1a, kto składa fałszywe zeznanie, nie wiedząc o prawie odmowy zeznania lub odpowiedzi na pytania.',
          },
          {
            pointDecor: '§ 4.',
            label: 'Kto, jako biegły, rzeczoznawca lub tłumacz, przedstawia fałszywą opinię, ekspertyzę lub tłumaczenie mające służyć za dowód w postępowaniu określonym w § 1, podlega karze pozbawienia wolności od roku do lat 10.',
          },
          {
            pointDecor: '§ 4a.',
            label: 'Jeżeli sprawca czynu określonego w § 4 działa nieumyślnie, narażając na istotną szkodę interes publiczny, podlega karze pozbawienia wolności do lat 3.',
          },
          {
            pointDecor: '§ 5.',
            label: 'Sąd może zastosować nadzwyczajne złagodzenie kary, a nawet odstąpić od jej wymierzenia, jeżeli:',
            children : [
              {
                pointDecor: '1)',
                label: 'fałszywe zeznanie, opinia, ekspertyza lub tłumaczenie dotyczy okoliczności nie mogących mieć wpływu na rozstrzygnięcie sprawy,',
              },
              {
                pointDecor: '2)',
                label: 'sprawca dobrowolnie sprostuje fałszywe zeznanie, opinię, ekspertyzę lub tłumaczenie, zanim nastąpi, chociażby nieprawomocne, rozstrzygnięcie sprawy.',
              }
            ],
          },
          {
            pointDecor: '§ 6.',
            label: 'Przepisy § 1–3 oraz 5 stosuje się odpowiednio do osoby, która składa fałszywe oświadczenie, jeżeli przepis ustawy przewiduje możliwość odebrania oświadczenia pod rygorem odpowiedzialności karnej.',
          },
        ],
      },
    ],
    firstList: [
      {
        pointDecor: 'II.',
        label: 'Oświadczam, że:',
        isBolded: true,
        children: [
          {
            pointDecor: '1)',
            label: 'nie pozostaję z żadnym z podwykonawców wskazanych we wniosku o dofinansowanie projektu, którego protest jest rozpatrywany, w takim stosunku prawnym, że wynik rozpatrzenia protestu może mieć wpływ na moje prawa lub obowiązki, w tym nie jestem:',
            children: [
              {
                pointDecor: 'a)',
                label: 'wspólnikiem, udziałowcem lub akcjonariuszem żadnego z podwykonawców,',
              },
              {
                pointDecor: 'b)',
                label: 'członkiem organów zarządzających lub nadzorczych żadnego z podwykonawców;[4]',
              },
            ],
          },
          {
            pointDecor: '2)',
            label: 'nie jestem związany stosunkiem pracy lub stosunkiem cywilnoprawnym z żadnym z podwykonawców;[5]',
          },
          {
            pointDecor: '3)',
            label: 'nie pozostaję w związku małżeńskim albo w stosunku pokrewieństwa lub powinowactwa w linii prostej, pokrewieństwa lub powinowactwa w linii bocznej do drugiego stopnia z jakąkolwiek osobą pełniącą jakąkolwiek rolę w projekcie, którego protest jest rozpatrywany, w szczególności kierownikiem projektu lub członkiem zespołu mającego realizować projekt;',
          },
          {
            pointDecor: '4)',
            label: 'nie brałem/łam udziału w przygotowaniu wniosku o dofinansowanie, którego protest jest rozpatrywany;',
          },
          {
            pointDecor: '5)',
            label: 'w okresie trzech lat przed dniem złożenia oświadczenia:',
            children: [
              {
                pointDecor: 'a)',
                label: 'nie byłem/łam wspólnikiem wnioskodawcy, nie posiadałem/łam co najmniej 10% udziałów lub akcji wnioskodawcy,',
              },
              {
                pointDecor: 'b)',
                label: 'nie byłem/łam związany/a stosunkiem pracy lub stosunkiem cywilnoprawnym z wnioskodawcą,',
              },
              {
                pointDecor: 'c)',
                label: 'nie byłem/łam członkiem organów zarządzających lub nadzorczych wnioskodawcy, lub jakiegokolwiek podmiotu biorącego udział w przygotowaniu wniosku o dofinansowanie, którego protest jest rozpatrywany,',
              },
              {
                pointDecor: 'd)',
                label: 'nie świadczyłem/am jakichkolwiek usług na rzecz wnioskodawcy jako pracownik lub zleceniobiorca innego podmiotu lub na podstawie innego stosunku prawnego z innym podmiotem, z wyłączeniem usług realizowanych w ramach STEP i Innovation Coach;',
              },
            ],
          },
          {
            pointDecor: '6)',
            label: 'nie łączą mnie oraz w okresie trzech lat przed dniem złożenia oświadczenia nie łączyły mnie inne relacje z wnioskodawcą, które skutkowałyby powstaniem konfliktu interesów[6].',
          },
        ],
      },
    ],
    annotation: [
      {
        pointDecor: '[4]',
        label: 'Stosuje się jeżeli w projekcie występują podwykonawcy.',
        isSmall: true,
      },
      {
        pointDecor: '[5]',
        label: 'Stosuje się jeżeli w projekcie występują podwykonawcy.',
        isSmall: true,
      },
      {
        pointDecor: '[6]',
        label: 'Konflikt interesów istnieje wówczas, gdy bezstronne i obiektywne pełnienie funkcji podmiotu upoważnionego do działań finansowych lub innej osoby, o których mowa w art. 61 ust. 1, jest zagrożone z uwagi na względy rodzinne, emocjonalne, sympatie polityczne lub związki z jakimkolwiek krajem, interes gospodarczy lub jakiekolwiek inne bezpośrednie lub pośrednie interesy osobiste (art. 61 ust. 3 rozporządzenia Parlamentu Europejskiego i Rady (UE, Euratom) 2018/1046 z dnia 18 lipca 2018 r. w sprawie zasad finansowych mających zastosowanie do budżetu ogólnego Unii, zmieniające rozporządzenia (UE) nr 1296/2013, (UE) nr 1301/2013, (UE) nr 1303/2013, (UE) nr 1304/2013, (UE) nr 1309/2013, (UE) nr 1316/2013, (UE) nr 223/2014 i (UE) nr 283/2014 oraz decyzję nr 541/2014/UE, a także uchylające rozporządzenie (UE, Euratom) nr 966/2012) (Dz. Urz. UE L Nr 193 z 30.07.2018, str. 1).',
        isSmall: true,
      }
    ],
    secondList: [
      {
        pointDecor: 'III.',
        label: 'Oświadczam, że:',
        isBolded: true,
        children: [
          {
            pointDecor: '1)',
            label: 'nie biorę udziału oraz w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie brałem/łam udziału w przygotowaniu wniosków o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '2)',
            label: 'nie jestem i w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie byłem/łam członkiem organów zarządzających lub nadzorczych podmiotu przygotowującego wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '3)',
            label: 'nie jestem i w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie byłem/łam wspólnikiem podmiotu przygotowującego wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '4)',
            label: 'nie posiadam i w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie posiadałem/łam co najmniej 10% udziałów lub akcji podmiotu przygotowującego wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '5)',
            label: 'nie jestem i w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie byłem/łam związany/a stosunkiem pracy z podmiotem przygotowującym wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '6)',
            label: 'nie łączy mnie i w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia nie łączył mnie stosunek cywilnoprawny z podmiotem przygotowującym wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '7)',
            label: 'nie pozostaję w związku małżeńskim, w stosunku pokrewieństwa lub powinowactwa do drugiego stopnia z osobą, która przygotowuje lub w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia przygotowywała wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '8)',
            label: 'nie jestem związany/a z tytułu przysposobienia, kurateli lub opieki z osobą, która przygotowuje lub w okresie roku poprzedzającego dzień złożenia niniejszego oświadczenia przygotowywała wnioski o dofinansowanie projektów na zasadach komercyjnych w ramach instrumentów wdrażanych przez PARP w krajowych programach na lata 2021-2027;',
          },
          {
            pointDecor: '9)',
            label: 'zobowiązuję się do nie podejmowania jakiejkolwiek działalności zarobkowej związanej z realizacją projektu, który opiniuję w ramach procedury odwoławczej - w ciągu 12 miesięcy od dnia publikacji na stronie internetowej listy wniosków, które uzyskały dofinansowanie w naborze.',
          },
          {
            pointDecor: '10)',
            label: 'nie brałem/łam udziału w ocenie wniosku o dofinansowanie projektu, którego protest jest rozpatrywany.',
          },
        ],
      },
    ]
  },
  secondList: [
    {
      pointDecor: 'IV.',
      label: 'Oświadczam, że:',
      isBolded: true,
      children: [
        {
          pointDecor: '1)',
          label: 'będę wypełniać moje obowiązki w sposób uczciwy, rzetelny i sprawiedliwy, zgodnie z posiadaną wiedzą;',
        },
        {
          pointDecor: '2)',
          label: 'nie będę zatrzymywać kopii jakichkolwiek pisemnych lub elektronicznych informacji i dokumentów związanych z rozpatrzeniem protestu;',
        },
        {
          pointDecor: '3)',
          label: 'zobowiązuję się do zachowania w tajemnicy wszystkich informacji i dokumentów ujawnionych mi lub wytworzonych przeze mnie w trakcie lub jako rezultat procedury odwoławczej oraz zobowiązuje się wykorzystywać je wyłącznie dla celów związanych z rozpatrzeniem protestu i nie ujawniać ich podmiotom, a także osobom trzecim, z zastrzeżeniem ewentualnego składania zeznań lub wyjaśnień w postępowaniach administracyjnych i sądowych prowadzonych w związku z projektem, jednakże w takim przypadku zobowiązuje się poinformować PARP o fakcie składania zeznań lub wyjaśnień.',
        },
      ],
    }
  ],
  notes: {
    firstList:[
      {
        pointDecor: 'a)',
        label: 'względy rodzinne i względy emocjonalne (pozytywne i negatywne):',
        children: [
          {
            pointDecor: 'i.',
            label: 'nie jest wymagana więź emocjonalna między członkami rodziny, aby bezstronność danej osoby była zagrożona z uwagi na względy rodzinne;',
          },
          {
            pointDecor: 'ii.',
            label: 'względy rodzinne obejmują: najbliższą rodzinę, tj. co najmniej poniższe relacje, w tym relacje powstałe w wyniku adopcji: małżonek (w tym partner, z którym dana osoba pozostaje w (nie)zarejestrowanym wolnym związku), dzieci i rodzice, (pra)dziadkowie i (pra)wnuki, (przyrodni) bracia i siostry (w tym z rodzin mieszanych), wujowie i ciotki, siostrzenice lub bratanice oraz siostrzeńcy lub bratankowie, kuzyni pierwszego stopnia, teściowie, synowe i zięciowie, szwagier lub szwagierka, przybrani rodzice, pasierbowie i pasierbice, oraz dalszą rodzinę;',
          },
          {
            pointDecor: 'iii.',
            label: 'osobista przyjaźń (np. także relacje rodzic chrzestny/chrześniak) lub osobista wrogość może prowadzić do sytuacji, w której dana osoba, pozostając w takiej relacji, narazi na szwank swoją bezstronność i obiektywizm;',
          },
          {
            pointDecor: 'iv.',
            label: 'każdy, kto na stałe mieszka w gospodarstwie domowym danej osoby, znajduje się co najmniej w sytuacji, która obiektywnie może być postrzegana jako konflikt interesów.',
          },
        ],
      },
      {
        pointDecor: 'b)',
        label: 'sympatie polityczne, np. związki z partiami politycznymi, zaangażowanie w działalność organizacji politycznych (nawet jeśli nie jest ono wynagradzane);',
      },
      {
        pointDecor: 'c)',
        label: 'związki z jakimkolwiek krajem, ale jedynie takie, które mogłyby zagrażać bezstronności i obiektywizmowi danej osoby;',
      },
      {
        pointDecor: 'd)',
        label: 'interes gospodarczy;',
      },
      {
        pointDecor: 'e)',
        label: 'jakiekolwiek inne bezpośrednie lub pośrednie interesy osobiste, np. dana osoba uzyskuje jakieś prezenty (np. bony, vouchery) lub gościnność od danego podmiotu, awans na wyższe stanowisko w strukturze organizacyjnej, interesy o charakterze nieekonomicznym, konkurujące obowiązki lojalności między jednym podmiotem, wobec którego dana osoba jest zobowiązana, a inną osobą lub podmiotem, wobec którego dana osoba jest zobowiązana.',
      },
    ],
    secondList: [
      {
        pointDecor: '1)',
        label: 'może mieć bezpośredni interes osobisty (rodzinny) w przyznaniu środków finansowych UE na projekt przedstawiony przez przedsiębiorstwo swojego małżonka/partnera (i w związku z tym, aby można było zaradzić zaistniałej sytuacji, musiałby zadeklarować konflikt interesów i powstrzymać się od udziału w podejmowaniu decyzji związanych z tym projektem); ',
      },
      {
        pointDecor: '2)',
        label: 'może mieć pośredni interes osobisty (rodzinny) w przyznaniu środków finansowych UE przedsiębiorstwu, które obiecało stworzyć nową fabrykę, dla której przedsiębiorstwo małżonka/partnera będzie najprawdopodobniej najważniejszym podwykonawcą (i w związku z tym wymagałoby to od niego wstrzymania się od decyzji związanych z procedurą udzielania zamówienia) lub jest właścicielem gruntu, który przedsiębiorstwo będzie musiało kupić, aby wybudować fabrykę. ',
      }
    ],
    thirdList: [
      {
        pointDecor: '1)',
        label: 'Osoba ta (lub jej partner) jednocześnie prowadzi działalność doradczą, na rzecz przedsiębiorstwa doradczego lub osoby trzeciej świadczącej usługi na rzecz przedsiębiorstwa doradczego, w zakresie składania wniosków o dofinansowanie ze środków UE. ',
      },
      {
        pointDecor: '2)',
        label: 'Osoba ta (lub członek jej najbliższej rodziny) jest bezpośrednio lub pośrednio właścicielem przedsiębiorstwa ubiegającego się o dofinansowanie ze środków UE. ',
      },
      {
        pointDecor: '3)',
        label: 'Osoba ta przyjaźni się z kierownictwem/właścicielami przedsiębiorstwa ubiegającego się o dofinansowanie ze środków UE. ',
      },
      {
        pointDecor: '4)',
        label: 'Dana osoba kandyduje (jako członek partii politycznej) na urząd publiczny, a jej partia polityczna ma powiązania biznesowe z konkretnym wnioskodawcą ubiegającym się o dofinansowanie ze środków UE. ',
      },
      {
        pointDecor: '5)',
        label: 'Przed odejściem ze stanowiska w służbie publicznej dana osoba negocjuje swoje przyszłe zatrudnienie w przedsiębiorstwie ubiegającym się o dofinansowanie ze środków UE (25) (lub w przedsiębiorstwie powiązanym, partnerskim lub innym przedsiębiorstwie, którego struktura własnościowa pokrywa się ze strukturą własnościową przedsiębiorstwa ubiegającego się o dofinansowanie ze środków UE).',
      },
      {
        pointDecor: '6)',
        label: 'Osoba ta pracowała ostatnio na stanowisku kierowniczym w przedsiębiorstwie ubiegającym się o dofinansowanie ze środków UE i była odpowiedzialna za ten konkretny sektor przedsiębiorstwa, który obecnie ubiega się o dofinansowanie.',
      },
      {
        pointDecor: '7)',
        label: 'Jeżeli dana osoba mieszka w gminie, która ubiega się o dofinansowanie infrastruktury, nie powinno to być koniecznie i obiektywnie postrzegane jako powodujące konflikt interesów. Im większa grupa, do której należy dana osoba i która skorzysta na danym działaniu – w tym przypadku mieszkańcy danej gminy – tym ryzyko konfliktu interesów jest na ogół mniejsze. Ocena poszczególnych przypadków jest jednak nadal konieczna, jeżeli dana osoba miałaby na przykład odnieść szczególną korzyść ze środka finansowania, np. gdyby infrastruktura publiczna zwiększyła wartość nieruchomości w jej okolicy, mogłoby dojść do powstania konfliktu interesów.',
      },
    ],
  },
};
