import {
  PROFILES_LIST,
  PROFILES_TRANSLATION,
} from './profiles';

/**
 * Help center list.
 *
 * @type {Array}
 */
export const HELPCENTER_LIST = [
  ...PROFILES_LIST,
  {
    value: 'landing_page',
    text: 'Strona startowa',
  },
];

/**
 * Help center list.
 *
 * @type {object}
 */
export const HELPCENTER_VISIBLE_ON = {
  ...PROFILES_TRANSLATION,
  landing_page: 'Strona startowa',
};
