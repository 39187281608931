import React, {
  useEffect,
  useState,
} from 'react';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';
import { useAuth } from '../../_security';
import Timer from './Timer';
import Sticky from '../Sticky/Sticky';
import {
  useGlobalContext,
  useGlobalDialog,
} from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  sessionBarContainer: {
    width: '100%',
    backgroundColor: '#b52033',
    borderBottom: '4px solid #3c3c3c',
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
    },
  },
  alarmIcon: {
    fontSize: '2rem',
    color: theme.palette.brandGray.contrastText,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  description: {
    fontSize: '14px',
    fontFamily: 'Montserrat-Light,sans-serif',
    color: theme.palette.brandGray.contrastText,
    margin: '0 15px',
  },
};

/**
 * Renders session bar component with timer.
 *
 * @returns {SessionBar}
 */
export default function SessionBar() {
  const { notify } = useGlobalContext();
  const { closeAll } = useGlobalDialog();
  const {
    exp, onLogoutByEndOfSession, onRefreshSession,
  } = useAuth();
  const isBreakpointUpMd = useMediaQuery((t) => t.breakpoints.up('md'));

  const [secondsToSessionEnd, setSecondsToSessionEnd] = useState(exp - Math.floor(new Date().getTime() / 1000));

  useEffect(() => {
    const timer = secondsToSessionEnd > 0
      && setInterval(() => setSecondsToSessionEnd(exp - Math.floor(new Date().getTime() / 1000)), 1000);

    if (secondsToSessionEnd <= 0) {
      onLogoutByEndOfSession();
      closeAll();
    }

    return () => clearInterval(timer);
  }, [secondsToSessionEnd]);

  const handleFailure = () => {
    notify('Nie udało się przedłużyć sesji.', 'error');
  };

  return (
    <Box sx={{
      zIndex: (t) => t.zIndex.snackbar,
      position: 'relative',
    }}
    >
      {secondsToSessionEnd <= 900 && (
        <Sticky bottom="0">
          <div style={classes.sessionBarContainer}>
            <Grid container spacing={0}>
              <Grid item md={1} lg={2} />
              <Grid
                item
                xs={9}
                md={7}
                lg={6}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AccessAlarmRoundedIcon sx={classes.alarmIcon} />
                {isBreakpointUpMd && (
                  <>
                    <Typography
                      align="left"
                      variant="string"
                      sx={classes.description}
                      style={{ minWidth: '80px' }}
                    >
                      Po upływie
                    </Typography>
                    <Timer time={secondsToSessionEnd} />

                    <Typography align="left" variant="string" sx={classes.description}>
                      {/* eslint-disable-next-line max-len */}
                      zostaniesz wylogowany automatycznie przez system. Wszystkie niezapisane przez Ciebie dane zostaną utracone. W celu przedłużenia sesji kliknij w przycisk &quot;Przedłuż sesję&quot;.
                    </Typography>
                  </>
                )}
                {!isBreakpointUpMd && (
                  <Typography align="left" variant="string" sx={classes.description}>
                    {/* eslint-disable-next-line max-len */}
                    {`Po upływie ${format(secondsToSessionEnd * 1000, 'mm:ss')} zostaniesz wylogowany automatycznie przez system. Wszystkie niezapisane przez Ciebie dane zostaną ustracone. W celu przedłużenia sesji kliknij w przycisk "Przedłuż sesję".`}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                lg={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  sx={{ cursor: 'pointer' }}
                  color="secondary"
                  variant="contained"
                  onClick={() => onRefreshSession(handleFailure)}
                >
                  Przedłuż sesję
                </Button>
              </Grid>
              <Grid item md={1} lg={2} />
            </Grid>
          </div>
        </Sticky>
      )}
    </Box>
  );
}
