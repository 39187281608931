import { Grid } from '@mui/material';
import { ContactInfo } from './FooterContactInfo';
import { Logos } from './FooterLogos';
import { Links } from './FooterLinks';
import { SiteInfo } from './FooterSiteInfo';
import { theme } from '../../styles/theme';

const classes = {
  root: {
    marginTop: theme.spacing(10.5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& #footerB': {
      textAlign: 'right',
      paddingRight: theme.spacing(4),
    },
  },
};

/**
 * Footer component.
 *
 * @returns {Footer}
 */
export default function Footer() {
  return (
    <footer style={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ContactInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <Links />
        </Grid>
        <Grid item xs={12} md={9} alignItems="center" display="flex">
          <Logos />
        </Grid>
        <Grid item xs={12} md={3} alignItems="center" display="flex">
          <SiteInfo />
        </Grid>
      </Grid>
    </footer>
  );
}
