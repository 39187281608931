import { URL } from './URL';
import { ROLES } from './roles';
import { PROFILES } from './profiles';

export const MENU_STRUCTURE = {
  [PROFILES.applicant]: [
    {
      title: 'Trwające nabory',
      url: URL.recruitment.ongoing,
      id: 'wYUaxeCS2SdCkMv',
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER, ROLES.ROLE_OPERATOR],
    },
    {
      title: 'Moje wnioski',
      url: URL.application.myApplications,
      id: 'NG4slbI3JP9vZh9',
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
    },
    {
      title: 'Lista zadań',
      url: URL.application.todoList,
      id: 'G03e4fHq2h7XjIc',
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
    },
  ],
  [PROFILES.expert]: [
    {
      title: 'Ocena wniosków',
      id: '20GQN6bIrpawd5z',
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
      children: [
        {
          title: 'Ocena merytoryczna',
          url: URL.substantiveEvaluation,
          id: 'fcBhggQP8e6500e',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Panele ekspertów',
          url: URL.expert.expertPanels,
          id: 'IYGBW5IQFR9iC19',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Opinie',
          url: URL.reviews,
          id: 'atI57avnANoghGg',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Oświadczenia KOP',
          url: URL.expert.kopStatements,
          id: 'NDCGbSeb0LtdAXt',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
      ],
    },
    {
      title: 'Dane osobowe',
      id: 'vnLbeiaBSkJjuHX',
      url: URL.expertApplicationFormData,
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
    },
    {
      title: 'Wnioski aplikacyjne',
      id: 'A5GMHlMIwgu1XoN',
      url: URL.applicationForm.list,
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
    },
    {
      title: 'Rozliczanie ocen',
      id: 'rJNQ6cFDRlpiNgu',
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
      children: [
        {
          title: 'Lista ocenionych wniosków',
          url: URL.listOfRatedApplications,
          id: 'ffH2pfIyRtSBE25',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Protokoły odbioru zlecenia',
          url: URL.listOfOrderAcceptanceProtocols,
          id: 'bumEYegG3XDlvhN',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Ocena pracy eksperta',
          url: URL.expert.workEvaluation,
          id: 'qJP0GI8msoH1ove',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Złóż rekomendację płatności',
          url: URL.submitRecommendationPayment,
          id: '84sznMwOavJwa09',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Oświadczenia',
          url: URL.declarations,
          id: 'aFEhiEtmVdE3zTo',
          access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
        },
        {
          title: 'Ocena pracy eksperta',
          url: URL.workEvaluation,
          id: 'ljUFDqD53mHm8Lj',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Dane do przelewów',
          url: URL.expert.transferDetails,
          id: 'pSeOAmLIMNmgjj2',
          access: [ROLES.ROLE_EXPERT, ROLES.ROLE_USER, ROLES.ROLE_ADMIN],
        },
        {
          title: 'Formularz PIT',
          url: URL.expert.taxReturn,
          id: 'KoxXEXWREPQtkwU',
          access: [ROLES.ROLE_EXPERT, ROLES.ROLE_USER, ROLES.ROLE_ADMIN],
        },
      ],
    },
    {
      title: 'Umowa',
      id: 'b23oXDKzVJXLZ8x',
      url: URL.expertContract,
      access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER],
    },
  ],
  [PROFILES.employee]: [
    {
      title: 'Nabory',
      id: '9lOmZ2eApeurLKt',
      access: [ROLES.ROLE_EMPLOYEE],
      children: [
        {
          title: 'Lista naborów',
          url: URL.recruitment.list,
          id: 'LeYli9eL4OGbQyi',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Lista złożonych wniosków',
          url: URL.recruitment.submittedApplications,
          id: 'pgSZeiIxpxcRqrP',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Wyszukiwanie wniosków wg NIP',
          url: URL.applicationSearch,
          id: 'Kk8SC6Teok4FZNg',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Zmiana statusów wniosków',
          url: URL.recruitment.applicationStatusManagement,
          id: 'XA3EdoRbFaSVhfc',
          access: [ROLES.ROLE_ADMIN, ROLES.ROLE_SECRETARY],
        },
        {
          title: 'Udostępnianie arkuszy ocen',
          url: URL.recruitment.shareEvaluationSheet,
          id: 'HCb9j8EpfOW9ofX',
          access: [ROLES.ROLE_ADMIN, ROLES.ROLE_SECRETARY],
        },
      ],
    },
    {
      title: 'Ocena',
      id: 'n3BhQCD0WPYRt4q',
      access: [ROLES.ROLE_EMPLOYEE],
      children: [
        {
          title: 'Ocena merytoryczna',
          url: URL.substantialEvaluation,
          id: 'G8bKRYwGlwZmFyd',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Ocena panelowa',
          url: URL.expert.expertPanels,
          id: 'eOgdTSGGYr7o1rK',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Opinie',
          url: URL.reviews,
          id: 'he72xOrtUFyYMe9',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Oświadczenia KOP',
          url: URL.expert.kopStatements,
          id: 'rdwhGnoMxK5p6Ra',
          access: [ROLES.ROLE_EMPLOYEE],
        },
      ],
    },
    {
      title: 'Eksperci',
      id: 'zZDa38KsIegCluJ',
      access: [ROLES.ROLE_EMPLOYEE],
      children: [
        {
          title: 'Lista ekspertów',
          url: URL.expertsList,
          id: 'R3mrPG7POYkGanB',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Lista wniosków aplikacyjnych',
          url: URL.applicationsListExpert,
          id: 'kPmyaJCRvP6XOuD',
          access: [ROLES.ROLE_ADMIN, ROLES.ROLE_EXPERT_PREVIEW, ROLES.ROLE_EXPERT_EDIT],
        },
      ],
    },
    {
      title: 'Podmioty wykluczone',
      id: 'nLICdW2Ru9b4VUO',
      access: [ROLES.ROLE_EMPLOYEE],
      children: [
        {
          title: 'Wykaz podmiotów wykluczonych',
          url: URL.excludedEntitiesList,
          id: 'doQimTKD8axwZNR',
          access: [ROLES.ROLE_EMPLOYEE],
        },
        {
          title: 'Wykaz sygnałów ostrzegawczych',
          url: URL.redFlagsList,
          id: 'VlWtIcJ00x91x62',
          access: [ROLES.ROLE_EMPLOYEE],
        },
      ],
    },
    {
      title: 'Raporty',
      url: URL.reports.main,
      id: 'Xyksd3REiJFd36T',
      access: [ROLES.ROLE_EMPLOYEE],
    },
    {
      title: 'Użytkownicy',
      access: [ROLES.ROLE_ADMIN],
      id: 'kTxpNdkwzd8NHPJ',
      children: [
        {
          title: 'Lista użytkowników',
          url: URL.usersList,
          id: 'Kwgt15H2vtAOdeO',
          access: [ROLES.ROLE_ADMIN],
        },
        {
          title: 'Dodaj użytkownika',
          url: URL.userAdd,
          id: 'ZhmdqdXNL8urOGO',
          access: [ROLES.ROLE_ADMIN],
        },
        {
          title: 'Import uprawnień (AKD)',
          url: URL.permissionsImport,
          id: 'XPcUKYvJUGk0crG',
          access: [ROLES.ROLE_ADMIN],
        },
        {
          title: 'Uprawnienia RPW',
          url: URL.excludedEntitiesRegistry,
          id: 'o0D04gKpA3AAiwc',
          access: [ROLES.ROLE_ADMIN],
        },
      ],
    },
    {
      title: 'Administracja',
      id: 'T2BgIjaRKeBnpXl',
      access: [ROLES.ROLE_ADMIN],
      children: [
        {
          title: 'Nabory',
          id: 'aNaePNDfQYcL0pY',
          children: [
            {
              title: 'Dodaj nabór',
              url: URL.recruitment.add,
              id: '2wr7jCPiFY5daOW',
            },
            {
              title: 'Dodaj wzór wniosku',
              url: URL.recruitment.applicationTemplate.add,
              id: 'LnKv19a2uvnITAi',
            },
            {
              title: 'Wybór naborów',
              url: URL.recruitment.selection,
              id: 'CJ7Y6Hcjge68veV',
            },
            {
              title: 'Lista wniosków',
              url: URL.applications.list,
              id: 'lKKO3kfcxQ57Yk7',
            },
          ],
        },
        {
          title: 'System',
          id: 'YzqFiXUyAiCdarz',
          children: [
            {
              title: 'Dodaj aktualność RSS',
              url: URL.newsList,
              id: '9EH8it6FwoSRW1T',
            },
            {
              title: 'Komunikaty systemowe',
              url: URL.systemMessages,
              id: 'zKNCVYvuJDOXJvr',
            },
            {
              title: 'Słowniki LSI',
              url: URL.lsiDictionaries,
              id: '6vxkGbqdAVMk1YT',
            },
            {
              title: 'Słowniki CST',
              url: URL.cstDictionaries,
              id: 'XDpsWiETstOWgvo',
            },
            {
              title: 'Konfigurator oświadczeń użytkownika',
              url: URL.config.statements,
              id: 'qkPO5sMi7CAxkVd',
            },
            {
              title: 'Konfigurator centrum pomocy',
              url: URL.helpCenter,
              id: 'MU4jCIRizurOKVn',
            },
            {
              title: 'Klauzula informacyjna',
              url: URL.informationClause.list,
              id: 'xT5L45UMZdN9IRc',
            },
          ],
        },
        {
          title: 'Moduł wymiany danych z CST',
          url: URL.exchangeDataWithCst,
          id: '6hJaatL4KuY9JEM',
          access: [ROLES.ROLE_ADMIN],
        },
      ],
    },
  ],
  [PROFILES.operator]: [
    {
      title: 'Wnioski',
      url: URL.applications.submittedApplicationsWithOperator,
      id: 'ahQzjrDcAGW55e5',
      access: [ROLES.ROLE_OPERATOR],
    },
  ],
  [PROFILES.managementInstitution]: [
    {
      title: 'Nabory',
      url: URL.recruitment.list,
      id: 'Is4th2wMZiDcfv3',
      access: [ROLES.ROLE_MANAGEMENT_INSTITUTION],
    },
  ],
};

export const COMMON_STRUCTURE = [
  {
    title: 'Wydruki',
    id: 'JOXPIDsJLFB3hQe',
    access: [],
    url: URL.applicationPrints,
  },
  {
    title: 'Pomoc',
    id: 'oROl38GeIMizlA2',
    access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER, ROLES.ROLE_OPERATOR, ROLES.ROLE_MANAGEMENT_INSTITUTION],
    children: [
      {
        title: 'Centrum pomocy',
        id: 'WseOrPxGH0jn5tU',
        url: URL.userGuide,
        access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER, ROLES.ROLE_OPERATOR, ROLES.ROLE_MANAGEMENT_INSTITUTION],
      },
      {
        title: 'Formularz kontaktowy',
        id: 'gQ7mOyVegv4SW7Z',
        url: URL.contactForm,
        access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER, ROLES.ROLE_OPERATOR, ROLES.ROLE_MANAGEMENT_INSTITUTION],
      },
      {
        title: 'Moje zgłoszenia',
        id: 'FRuK6B96RhkSc6T',
        url: URL.userIssues,
        access: [ROLES.ROLE_EMPLOYEE, ROLES.ROLE_USER, ROLES.ROLE_OPERATOR, ROLES.ROLE_MANAGEMENT_INSTITUTION],
      },
    ],
  },
];
