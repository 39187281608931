/**
 * Application context help text.
 *
 * @type {object}
 */
export const APPLICATION_CONTEXT_HELP_TEXT = {
  specializations: 'Możesz wybrać jedną lub obie specjalizacje. Ekspert branżowy aplikuje o'
    + ' wpis na wykaz ekspertów w ramach dziedzin KIS i/lub pozostałych dziedzin. Ekspert finansowy'
    + ' aplikuje o wpis na wykaz ekspertów w ramach dziedziny Analiza finansowa.',
  kisDomains: 'Wybierz dowolną liczbę dziedzin KIS, w ramach których posiadasz wymaganą wiedzę,'
    + ' umiejętności i doświadczenie.',
  otherDomains: 'Wybierz dowolną liczbę pozostałych dziedzin, w ramach których posiadasz wymaganą wiedzę,'
    + ' umiejętności i doświadczenie.',
  financialDomains: 'Pole uzupełniane automatycznie po wyborze specjalizacji Ekspert finansowy.',
  kisDomainAreas: 'Wybierz dowolną liczbę szczegółowych obszarów KIS, w ramach których posiadasz wymaganą wiedzę,'
    + ' umiejętności i doświadczenie. Po wybraniu szczegółowych obszarów KIS naciśnij przycisk "Dodaj obszar KIS"',
  keywords: 'Dodaj słowa kluczowe dla każdej dziedziny',
  keywordsBanks: 'Dodaj słowa kluczowe dla każdego wybranego szczegółowego obszaru KIS. Niedodanie słów kluczowych'
    + ' dla danego szczegółowego KIS obszaru spowoduje brak możliwości dodania tego obszaru.',
  oecdScienceDomains: 'Wybierz dowolną liczbę dziedzin OECD, które pomocniczo określą posiadaną wiedzę,'
    + ' umiejętności oraz doświadczenie.',
  naceScienceDomains: 'Wybierz dowolną liczbę dziedzin NACE, które pomocniczo określą posiadaną wiedzę,'
    + ' umiejętności oraz doświadczenie.',
  holdsMasterDegreeOrEquivalent: 'Podaj nazwę uczelni, nazwę wydziału, kierunek/specjalizację, rok ukończenia uczelni,'
    + ' uzyskany tytuł/stopień naukowy.',
  holdsMasterDegreeOrEquivalentAttachments: (
    <>
      Dokumentami potwierdzającymi są:
      <br />
      &nbsp;- dyplom potwierdzający uzyskanie tytułu magistra lub równoważnego,
      <br />
      &nbsp;- zaświadczenie z uczelni potwierdzające uzyskanie tytułu magistra lub równoważnego,
      <br />
      &nbsp;- zaświadczenie wydane po przeprowadzeniu postępowania nostryfikacyjnego.
    </>
  ),
  threeYearsOfExperience: 'Podaj nazwę pracodawcy, stanowisko, zakres obowiązków, okres zatrudnienia.',
  threeYearsOfExperienceAttachments: (
    <>
      Dokumentami potwierdzającymi są:
      <br />
      &nbsp;- zaświadczenie o zatrudnieniu,
      <br />
      &nbsp;- świadectwa pracy,
      <br />
      &nbsp;- umowy cywilnoprawne,
      <br />
      &nbsp;- referencje/rekomendacje.
    </>
  ),
  doctoralDegreeInDomain: 'Podaj nazwę uczelni, nazwę wydziału, dziedzinę, dyscyplinę, rok nadania,'
    + ' uzyskany stopień naukowy.',
  doctoralDegreeInDomainAttachments: (
    <>
      Dokumentami potwierdzającymi są:
      <br />
      &nbsp;- dyplom potwierdzający uzyskanie stopnia naukowego doktora lub wyższego,
      <br />
      &nbsp;- zaświadczenie z uczelni potwierdzające uzyskanie stopnia naukowego doktora lub
      <br />
      &nbsp;&nbsp;&nbsp;wyższego.
    </>
  ),
  scientificPublications: 'Wymień publikacje naukowe z ostatnich 3 lat i podaj źródła weryfikacji tych publikacji.',
  memberOfCrucialResearchStaff: 'Wymień tytuły projektów B+R, wskaż podmiot realizujący projekty, okres ich'
    + ' realizacji oraz swoją rolę, zakres wykonywanych prac lub zasady udziału w realizacji wymienionych projektów.',
  memberOfCrucialResearchStaffAttachments: 'Dokumentami potwierdzającymi są zaświadczenia lub rekomendacje wydawane'
    + ' przez instytucje/podmioty, z którymi kandydat na eksperta współpracował.',
  patentsAsInventor: 'Wymień patenty uzyskane jako zgłaszający lub twórca oraz opisz ich powiązanie z dziedziną'
    + ' w ramach której ubiegasz się o wpis. Wskaż linki do baz danych patentowych potwierdzające uzyskanie patentów.',
  patentsAsInventorAttachments: 'Jeśli nie wskazałeś linków do baz danych patentowych dodaj dokumenty potwierdzające'
    + ' uzyskanie patentów.Dokumentami potwierdzającymi są dokumenty patentowe.',
  implementationParticipant: 'Opisz swoją rolę, zakres wykonywanych prac lub zasady udziału we wdrożeniu i'
    + ' komercjalizacji wyników prac B+R dotyczących innowacyjnych produktów lub procesów.',
  implementationParticipantAttachments: 'Dokumentami potwierdzającymi są zaświadczenia lub rekomendacje wydawane przez'
    + ' instytucje/podmioty, z którymi kandydat na eksperta współpracował.',
  qualifiedAsExpert: 'Wskaż rodzaj wpisu (rzeczoznawca/biegły sądowy) oraz dziedzinę i okres wpisu (jeśli dotyczy).'
    + ' Wskaż linki do bazy/wykazu potwierdzające posiadanie czynnych uprawnień rzeczoznawcy lub wpis na listę'
    + ' biegłych sądowych.',
  qualifiedAsExpertAttachments: (
    <>
      Jeśli nie wskazałeś linków do bazy/wykazu dodaj dokumenty potwierdzające posiadanie czynnych uprawnień
      rzeczoznawcy lub wpis na listę biegłych sądowych. Dokumentami potwierdzającymi są:
      <br />
      &nbsp;- świadectwa, certyfikaty, zaświadczenia potwierdzające czynne uprawnienia rzeczoznawcy,
      <br />
      &nbsp;- dokumenty potwierdzające wpis na listę biegłych sądowych.
    </>
  ),
  euProjectsParticipant: 'Wskaż tytuły projektów, nazwę beneficjenta, okres realizacji projektów, nazwy działań/'
    + 'poddziałań i programów z jakich finansowane były projekty, swoją rolę, zakres wykonywanych prac lub zasady'
    + ' udziału w realizacji wymienionych projektów.',
  experienceInEvaluating: 'Wymień w ramach jakich instrumentów (działanie/poddziałanie nazwa programu) posiadasz'
    + ' doświadczenie w zakresie oceny wniosków, wskaż liczbę ocenionych wniosków.',
};
