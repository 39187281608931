import PropTypes from 'prop-types';
import {
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  formatDateString,
  sanitizeAndRenderHtml,
} from '../../_helpers';
import { SaveCancelButtons } from '../SaveCancelButtons';
import ExpandableText from '../ExpandableText';
import { useGlobalDialog } from '../../Context';
import { DATE } from '../../_constants';

/**
 * NewSystemMessagesDialog
 *
 * @param {object} props - root props
 * @param {object} props.messages - messages data
 * @returns {NewSystemMessagesDialog}
 */
export function NewSystemMessagesDialog({
  messages,
}) {
  const navigate = useNavigate();
  const { closeAll } = useGlobalDialog();

  return (
    <>
      {messages.map((message) => (
        <>
          <Typography fontWeight={message.readAt ? 'normal' : 'bold'} fontSize="0.8rem">
            {formatDateString(message.created_at, DATE.defaultFormatWithoutTime)}
          </Typography>
          <Typography fontWeight={message.readAt ? 'normal' : 'bold'}>
            {message.title}
          </Typography>
          {message.contents?.length > 200
            ? (
              <ExpandableText
                variant="html"
                shortText={sanitizeAndRenderHtml(`${message.contents.slice(0, 200)}...`)}
                fullText={sanitizeAndRenderHtml(message.contents)}
              />
            )
            : <Box mb={3}>{sanitizeAndRenderHtml(message.contents)}</Box>}
        </>
      ))}
      <Box display="flex" justifyContent="center" mt={5}>
        <SaveCancelButtons
          cancelHandler={closeAll}
          cancelButtonId="V9nH3u7XhQ93cdl"
          saveHandler={() => {
            closeAll();
            navigate('/system-messages-by-profile');
          }}
          saveTitle="Zobacz komunikaty"
          cancelTitle="Zamknij"
          saveButtonId="YxkqhH54tggTbic"
        />
      </Box>
    </>
  );
}

NewSystemMessagesDialog.propTypes = {
  messages: PropTypes.arrayOf(Object).isRequired,
};
