import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';

/**
 * Contact persons application pdf element.
 *
 * @returns {ClusterCoordinatorServiceOfferPdf}
 */
function ClusterCoordinatorServiceOfferPdf() {
  return (
    <CollectionWrapper name="cluster_coordinator_service_offer">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.service_name`}
                value={item.service_name}
                oldValue={previousRowData?.service_name}
              />
              <RowField
                name={`${collectionName}.${index}.project_innovations`}
                value={item.project_innovations}
                oldValue={previousRowData?.project_innovations}
                dictionaryName={DICTIONARIES.projectInnovations.name}
              />
              <RowField
                name={`${collectionName}.${index}.service_description`}
                value={item.service_description}
                oldValue={previousRowData?.service_description}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.strategic_areas`}
                value={item.strategic_areas}
                oldValue={previousRowData?.strategic_areas}
                dictionaryName={DICTIONARIES.strategicAreas.name}
              />
              <RowField
                name={`${collectionName}.${index}.selection_justification`}
                value={item.selection_justification}
                oldValue={previousRowData?.selection_justification}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.service_implementation_benefits`}
                value={item.service_implementation_benefits}
                oldValue={previousRowData?.service_implementation_benefits}
                dictionaryName={DICTIONARIES.serviceImplementationBenefits.name}
              />
              <RowField
                name={`${collectionName}.${index}.benefits_justification`}
                value={item.benefits_justification}
                oldValue={previousRowData?.benefits_justification}
                isSingleRow
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ClusterCoordinatorServiceOfferPdf;
