import {
  Controller,
  FormProvider,
  useForm,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { DATE } from '../../../_constants';
import { SaveCancelButtons } from '../../../Features/SaveCancelButtons';
import { SingleStatementAdditionalOptionsForm } from './SingleStatementAdditionalOptions.form';

/**
 * Single statement form
 *
 * @param {object} props - root props
 * @param {object} props.singleStatement - single statement data
 * @param {Function} props.handleValue - handle value
 * @param {number} props.index - current index
 * @param {Function} props.handleClose - handle modal close
 * @returns {SingleStatementForm}
 */
export function SingleStatementForm({
  singleStatement, handleValue, index, handleClose,
}) {
  const form = useForm({
    defaultValues: {
      label: singleStatement?.label || '',
      validFrom: singleStatement?.validFrom ? new Date(singleStatement?.validFrom) : null,
      validTo: singleStatement?.validTo ? new Date(singleStatement?.validTo) : null,
      ordinalNumber: singleStatement?.ordinalNumber || '',
      note: singleStatement?.note || '',
      minChosenOptions: singleStatement?.minChosenOptions || 0,
      maxChosenOptions: singleStatement?.maxChosenOptions || 0,
      hasAdditionalOptions: singleStatement?.hasAdditionalOptions ? 'true' : 'false',
      options: singleStatement?.options || [],
      printable: singleStatement?.printable || true,
    },
    mode: 'all',
  });

  const {
    control, watch, handleSubmit,
  } = form;

  const watchHasAdditionalOptions = watch('hasAdditionalOptions') === 'true';

  const onSubmit = (values) => {
    handleValue(`statements.${index}`, {
      ...values,
      minChosenOptions: Number(values.minChosenOptions) || 0,
      maxChosenOptions: Number(values.maxChosenOptions) || 0,
      hasAdditionalOptions: values.hasAdditionalOptions === 'true',
    });
    handleClose();
  };

  return (
    <FormProvider {...form}>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          name="label"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              error={!!error}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              required
              multiline
              rows={10}
              label="Treść oświadczenia"
              inputProps={{ maxLength: 2000 }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          name="validFrom"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <DesktopDateTimePicker
              label="Data wyświetlania od"
              ampm={false}
              value={value}
              okText="Ustaw"
              cancelText="Anuluj"
              clearable
              clearText="Wyczyść"
              slotProps={{
                textField: {
                  id: name,
                  variant: 'outlined',
                  helperText: error?.message,
                  error: !!error,
                },
              }}
              onChange={(newDate) => {
                onChange({
                  target: {
                    name,
                    value: newDate,
                  },
                });
              }}
              format="yyyy-MM-dd HH:mm"
              mask="____-__-__ __:__"
              name={name}
              error={!!error}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          name="validTo"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <DesktopDateTimePicker
              label="Data wyświetlania do"
              ampm={false}
              value={value}
              clearable
              slotProps={{
                textField: {
                  name,
                  id: name,
                  variant: 'outlined',
                  error: !!error,
                  helperText: error?.message,
                  placeholder: DATE.defaultDatePlaceholder,
                },
              }}
              onChange={(newDate) => {
                onChange({
                  target: {
                    name,
                    value: newDate,
                  },
                });
              }}
              format="yyyy-MM-dd HH:mm"
              name={name}
              error={!!error}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          name="note"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              id={name}
              name={name}
              variant="outlined"
              defaultValue={value}
              error={!!error}
              helperText={error?.message}
              onChange={onChange}
              onBlur={onBlur}
              multiline
              rows={3}
              label="Przypis"
            />
          )}
        />
      </FormControl>
      <InputLabel htmlFor="hasAdditionalOptions">
        Dodatkowe opcje wyboru
      </InputLabel>
      <FormControl fullWidth margin="normal" variant="outlined">
        <Controller
          name="hasAdditionalOptions"
          control={control}
          render={({
            field: {
              onChange,
              value,
              name,
            },
          }) => (
            <RadioGroup
              name={name}
              value={value}
              onChange={onChange}
              id={name}
            >
              <FormControlLabel value="true" control={<Radio color="secondary" />} label="Tak" />
              <FormControlLabel value="false" control={<Radio color="secondary" />} label="Nie" />
            </RadioGroup>
          )}
        />
      </FormControl>
      {watchHasAdditionalOptions && (
        <SingleStatementAdditionalOptionsForm />
      )}
      <Box display="flex" justifyContent="center" gap={2} mt={3}>
        <SaveCancelButtons
          saveHandler={handleSubmit(onSubmit)}
          saveButtonId="ZM10aLF7zEU6dMi"
          cancelHandler={handleClose}
          cancelButtonId="4xPSiBYSS7dzX8q"
        />
      </Box>
    </FormProvider>
  );
}

SingleStatementForm.propTypes = {
  singleStatement: PropTypes.objectOf(Object).isRequired,
  handleValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
