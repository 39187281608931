import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  Box,
  Button,
} from '@mui/material';
import { useEffect } from 'react';
import { TitledContainer } from '../../TitledContainer';
import { AttachmentsBox } from '../../FileUpload';
import {
  API_ROUTE,
  ALLOWED_EXTENSIONS,
} from '../../../_constants';
import {
  useExpertPersonalDataContext,
  useExpertApplicationFormContext,
  useGlobalContext,
} from '../../../Context';
import { request } from '../../../_services';

/**
 * Personal data attachments
 *
 * @returns {PersonalDataAttachments}
 */
export function PersonalDataAttachments() {
  const { notify } = useGlobalContext();

  const {
    educationAttachments, attachments, id, hasAttachmentsProperty, isReadonly, reloadData,
  } = useExpertPersonalDataContext();

  const {
    applicationFormData, isApplicationFormStatusNotInEditionAndInCorrection,
  } = useExpertApplicationFormContext();

  const isSubmittedData = isApplicationFormStatusNotInEditionAndInCorrection;
  const educationAttachmentsUrl = isSubmittedData
    ? `${API_ROUTE.applicationForms}/${applicationFormData.id}/submitted-education-attachments`
    : `${API_ROUTE.expertPersonalData}/${id}/education-attachments`;

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      educationAttachments,
      attachments,
    },
    mode: 'all',
  });

  const onSubmit = async (values) => {
    const {
      statusSuccess,
    } = await request
      .put(`${API_ROUTE.expertPersonalData}/${id}`, values);

    window.scroll(0, 0);
    if (statusSuccess) {
      reloadData();
      notify('Lista załączników została zmieniona.', 'success');

      return;
    }

    notify('Wystąpił błąd podczas dodawania załączników', 'error');
  };

  useEffect(() => () => {
    if (!isReadonly) {
      handleSubmit(onSubmit)();
    }
  }, []);

  return (
    <>
      <TitledContainer
        title="Załączniki dot. wykształcenia"
        key="expert-attachments-education"
        variant="slim"
        textAlign="left"
      >
        <Controller
          control={control}
          name="educationAttachments"
          render={({
            field: {
              value, name, onChange,
            },
          }) => (
            <AttachmentsBox
              id={name}
              maxSize={15000000}
              maxAttachments={100}
              allowedExtensions={ALLOWED_EXTENSIONS}
              uploadedFilesCallback={(uploadedFiles) => {
                onChange({
                  target: {
                    name,
                    value: uploadedFiles.map(({ iri }) => iri),
                  },
                });
              }}
              existingFiles={value}
              fetchUrl={educationAttachmentsUrl}
              isReadonly={isReadonly}
              apiUrl={API_ROUTE.attachmentsAuth}
            />
          )}
        />
      </TitledContainer>

      {hasAttachmentsProperty && !applicationFormData.id && (
        <TitledContainer title="Załączniki do umowy" key="expert-attachments" textAlign="left">
          <Controller
            control={control}
            name="attachments"
            render={({
              field: {
                value, name, onChange,
              },
            }) => (
              <AttachmentsBox
                id={name}
                maxSize={15000000}
                maxAttachments={100}
                allowedExtensions={ALLOWED_EXTENSIONS}
                uploadedFilesCallback={(uploadedFiles) => {
                  onChange({
                    target: {
                      name,
                      value: uploadedFiles.map(({ iri }) => iri),
                    },
                  });
                }}
                existingFiles={value}
                fetchUrl={`${API_ROUTE.expertPersonalData}/${id}/attachments`}
                isReadonly={isReadonly}
                apiUrl={API_ROUTE.attachmentsAuth}
              />
            )}
          />
        </TitledContainer>
      )}
      {!isReadonly && (
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Button variant="outlined" color="secondary" id="Orta6zYq4F83LP8" onClick={handleSubmit(onSubmit)}>
            Zapisz
          </Button>
        </Box>
      )}
    </>
  );
}
