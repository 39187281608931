import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import {
  DICTIONARIES,
  MODULE_IRIS,
} from '../../../../../_constants';
import { useApplicationToPdfContext } from '../../../../../Context';
import { getOwnIndicators } from '../../../../../_helpers';

/**
 * Innovation Implementation Pdf element.
 *
 * @returns {InnovationImplementationPdf}
 */
function InnovationImplementationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
      elementsConfig,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();

  return (
    <CollectionWrapper name="innovation_implementation_module_essence_rd_result_novelty">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];
        const combinedIndicatorsInBusinessProcessDictionary = [
          ...getOwnIndicators(
            elementsConfig,
            initialFormData,
            item.indicators_in_the_business_process,
            MODULE_IRIS.innovationImplementation
          ),
          ...getOwnIndicators(
            elementsConfig,
            previousInitialFormData,
            previousRowData?.indicators_in_the_business_process,
            MODULE_IRIS.innovationImplementation
          ),
        ];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.rd_result_novelty`}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.innovation_type`}
                value={item.innovation_type}
                oldValue={previousRowData?.innovation_type}
                dictionaryName={DICTIONARIES.innovationTypes.name}
              />
              <RowField
                name={`${collectionName}.${index}.product_name`}
                value={item.product_name}
                oldValue={previousRowData?.product_name}
              />
              <RowField
                name={`${collectionName}.${index}.innovation_implementation_module_essence_work_types`}
                value={item.innovation_implementation_module_essence_work_types}
                oldValue={previousRowData?.innovation_implementation_module_essence_work_types}
              />
              <RowField
                name={`${collectionName}.${index}.novelty_level`}
                value={item.novelty_level}
                oldValue={previousRowData?.novelty_level}
              />
              <RowField
                name={`${collectionName}.${index}.product_innovation_description`}
                value={item.product_innovation_description}
                oldValue={previousRowData?.product_innovation_description}
              />
              <RowField
                name={`${collectionName}.${index}.indicators_in_the_business_process`}
                value={item.indicators_in_the_business_process}
                oldValue={previousRowData?.indicators_in_the_business_process}
                externalDictionary={combinedIndicatorsInBusinessProcessDictionary.map((indicator) => ({
                  '@id': indicator.id,
                  name: indicator.name,
                }))}
              />

              <RowField
                name={`${collectionName}.${index}.product_functionality`}
                value={item.product_functionality}
                oldValue={previousRowData?.product_functionality}
              />
              <RowField
                name={`${collectionName}.${index}.advantage`}
                value={item.advantage}
                oldValue={previousRowData?.advantage}
              />
              <RowField
                name={`${collectionName}.${index}.technical_parameters`}
                value={item.technical_parameters}
                oldValue={previousRowData?.technical_parameters}
              />
              <RowField
                name={`${collectionName}.${index}.product_feature_emphasis_for_recipients`}
                value={item.product_feature_emphasis_for_recipients}
                oldValue={previousRowData?.product_feature_emphasis_for_recipients}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default InnovationImplementationPdf;
