import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Alert } from '../../../Alert';
import { FormField } from '../../../Application/Application/FormField';
import FieldConfig from '../../../Application/Application/FieldConfig/FieldConfig';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { IndicatorsHelper } from '../../../../_helpers/applicationElement';
import { getFieldConfig } from '../../../../_helpers';
import { useElementContext } from '../../../../Context';
import CharsCounter from '../../../CharsCounter';
import { theme } from '../../../../styles/theme';

const classes = {
  container: {
    '& .MuiGrid-item': {
      paddingLeft: theme.spacing(3),
      paddingBottom: 0,
    },
  },
};

/**
 * Indicators form component
 *
 * @param {object} props - root props
 * @param {number} props.index - row index
 * @param {string} props.indicatorName - indicator name
 * @returns {IndicatorForm}
 */
function IndicatorForm({
  index, indicatorName,
}) {
  const {
    getValues, watch,
  } = useFormContext();

  const {
    fieldsConfig, id: templateElementId, isReadonly,
  } = useElementContext();
  const isThirdColumnPresent = getFieldConfig(
    fieldsConfig,
    `${indicatorName}-${templateElementId}.${index}.breakdown_by_gender`
  );

  const createFieldValueO = (currentFieldConfig, fieldNamePrefix) => {
    const {
      name, value, error, onChange, onBlur,
    } = currentFieldConfig;
    const lockedValue = getValues(`${indicatorName}-${templateElementId}.${index}.${fieldNamePrefix}ValueLocked`);
    const integerValue = getValues(`${indicatorName}-${templateElementId}.${index}.${fieldNamePrefix}ValueInteger`);

    return (
      <TextField
        id={name}
        name={name}
        variant="outlined"
        defaultValue={value}
        error={!!error}
        helperText={error?.message}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        InputProps={{
          inputComponent: NumericFormat,
        }}
        inputProps={{
          allowNegative: false,
          thousandSeparator: false,
          fixedDecimalScale: integerValue,
          isNumericString: true,
          decimalScale: integerValue ? 0 : 2,
          allowedDecimalSeparators: ['.', ','],
        }}
        disabled={lockedValue || isReadonly}
      />
    );
  };

  const projectDateStart = getValues('project_start_date');
  const projectDateEnd = getValues('project_finish_date');

  return (
    <Grid sx={classes.container} container spacing={3} mb={3}>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.name`}>
        {({
          name, label, title,
        }) => (
          <Grid item xs={isThirdColumnPresent ? 4 : 6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label || title}
            </Typography>
            <Alert severity="status" sx={{ my: 0 }}>
              {getValues(name)}
            </Alert>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.unit`}>
        {({
          name, label, title,
        }) => (
          <Grid item xs={isThirdColumnPresent ? 4 : 6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label || title}
            </Typography>
            <Alert severity="status" sx={{ my: 0 }}>
              {getValues(name)}
            </Alert>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.breakdown_by_gender`}>
        {({
          name, label, title,
        }) => (
          <Grid item xs={4} display="flex" alignItems="center">
            <Box>
              <FormControlLabel
                control={(
                  <Checkbox
                    id={name}
                    name={name}
                    color="secondary"
                    checked={getValues(name)}
                    disabled
                  />
                )}
                label={label || title}
                labelPlacement="start"
              />
            </Box>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.base_year`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  initialValue={value}
                  options={
                    IndicatorsHelper.getListOfYearsForIndicators(
                      projectDateStart,
                      getValues(`${indicatorName}-${templateElementId}.${index}.base_year_range`)
                    )
                  }
                  noOptionsText={projectDateStart
                    ? 'Brak opcji'
                    : 'Uzupełnij pole - "Data rozpoczęcia realizacji projektu"'}
                  disabled={getValues(`${indicatorName}-${templateElementId}.${index}.base_year_locked`) || isReadonly}
                  optionsMapKeys={['value', 'label']}
                  error={error}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.base_value_o`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {(props) => (
                <>
                  {createFieldValueO(props, 'base')}
                </>
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_year`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  initialValue={value}
                  options={
                    IndicatorsHelper.getListOfYearsForIndicators(
                      projectDateEnd,
                      getValues(`${indicatorName}-${templateElementId}.${index}.target_year_range`)
                    )
                  }
                  noOptionsText={projectDateEnd
                    ? 'Brak opcji'
                    : 'Uzupełnij pole - "Data zakończenia realizacji projektu"'}
                  disabled={getValues(`${indicatorName}-${templateElementId}.${index}.target_year_locked`)
                    || isReadonly}
                  optionsMapKeys={['value', 'label']}
                  error={error}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_value_o`}>
        {({
          name, label,
        }) => (
          <Grid item xs={6}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {(props) => (
                <>
                  {createFieldValueO(props, 'target')}
                </>
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
      {watch(`${indicatorName}-${templateElementId}.${index}.breakdown_by_gender`) && (
        <>
          <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_value_k`}>
            {({
              name, label,
            }) => (
              <Grid item xs={6}>
                <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
                  {label}
                </Typography>
                <FormField name={name} labelled={false}>
                  {({
                    onChange, value, onBlur, error,
                  }) => (
                    <TextField
                      id={name}
                      name={name}
                      type="number"
                      variant="outlined"
                      defaultValue={value}
                      error={!!error}
                      helperText={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      fullWidth
                      disabled={isReadonly}
                    />
                  )}
                </FormField>
              </Grid>
            )}
          </FieldConfig>
          <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.target_value_m`}>
            {({
              name, label,
            }) => (
              <Grid item xs={6}>
                <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
                  {label}
                </Typography>
                <FormField name={name} labelled={false}>
                  {({
                    onChange, value, onBlur, error,
                  }) => (
                    <TextField
                      id={name}
                      name={name}
                      type="number"
                      variant="outlined"
                      defaultValue={value}
                      error={!!error}
                      helperText={error?.message}
                      onChange={onChange}
                      onBlur={onBlur}
                      fullWidth
                      disabled={isReadonly}
                    />
                  )}
                </FormField>
              </Grid>
            )}
          </FieldConfig>
        </>
      )}

      <FieldConfig name={`${indicatorName}-${templateElementId}.${index}.measuring_method`}>
        {({
          name, label,
        }) => (
          <Grid item xs={12}>
            <Typography color={theme.palette.brandGray80.main} fontSize={14} mb={1}>
              {label}
            </Typography>
            <FormField name={name} labelled={false}>
              {({
                onChange, value, onBlur, error, maxLength,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  type="number"
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  multiline
                  minRows={2}
                  InputProps={{
                    endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 500} />,
                  }}
                  inputProps={{ maxLength: maxLength ?? 500 }}
                  disabled={getValues(`${indicatorName}-${templateElementId}.${index}.measurementMethodLocked`)
                    || isReadonly}
                />
              )}
            </FormField>
          </Grid>
        )}
      </FieldConfig>
    </Grid>
  );
}

export default IndicatorForm;

IndicatorForm.propTypes = {
  index: PropTypes.number.isRequired,
  indicatorName: PropTypes.string.isRequired,
};
