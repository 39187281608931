import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useGlobalDialog } from '../../../Context';
import StatementAddForm from './StatementAdd.form';

/**
 * Statement add dialog
 *
 * @param {object} props - root props
 * @param {Function} props.append - add statement
 * @param {Array} props.fields - current statement list
 * @returns {StatementAddDialog}
 */
export function StatementAddDialog({
  append, fields,
}) {
  const {
    render,
  } = useGlobalDialog();

  return (
    <Button
      type="button"
      id="Ii40IKXB82TzvLP"
      onClick={() => render(
        <StatementAddForm fields={fields} append={append} />,
        'Dodaj oświadczenie'
      )}
      color="secondary"
      variant="outlined"
    >
      Dodaj oświadczenie
    </Button>
  );
}

StatementAddDialog.propTypes = {
  append: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
};
