import {
  COMMON_STRUCTURE,
  MENU_STRUCTURE,
} from '../_constants';

/**
 * Gets menu structure by profile.
 *
 * @param {string} profile - profile name
 * @returns {object} - structure
 */
export const getMenuStructure = (profile) => ([
  ...(MENU_STRUCTURE[profile] || []),
  ...COMMON_STRUCTURE,
]);

/**
 * Returns all menu items as flat object.
 *
 * ex. {
 *   '/some-url': 'Root menu item name',
 *   ...
 * }
 *
 * @param {Array} structure - structure array
 * @returns {object} - grouped structure
 */
export const groupStructureByUrl = (structure = []) => {
  let rootTitle = '';

  const callback = (arrayRoot, firstLoop) => {
    const {
      url, title, children = [],
    } = arrayRoot;
    if (firstLoop) {
      rootTitle = title;
    }

    if (!url) {
      return children.map((element) => callback(element, false)).flat();
    }

    return {
      url,
      title: rootTitle,
    };
  };

  return structure
    .map((menuItem) => callback(menuItem, true))
    .flat()
    .reduce((obj, item) => Object.assign(obj, { [item.url]: item.title }), {});
};
