import {
  useEffect,
  useState,
} from 'react';
import {
  Fade,
  Grid,
} from '@mui/material';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { TitledContainer } from '../../Features/TitledContainer';
import { request } from '../../_services';
import {
  API_ROUTE,
  URL,
} from '../../_constants';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';
import { ApplicationWithdrawalForm } from '../../Features/Application/Application/ApplicationWithdrawal.form';
import { useGlobalContext } from '../../Context';

/**
 * Application withdrawal dashboard.
 *
 * @returns {ApplicationWithdrawal}
 */
export default function ApplicationWithdrawal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { notify } = useGlobalContext();
  const [applicationData, setApplicationData] = useState(null);
  const [hasActiveWithdrawal, setHasActiveWithdrawal] = useState(false);

  useEffect(() => {
    const loadApiData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(`${API_ROUTE.applications}/${id}`);

      if (!statusSuccess) {
        notify(
          `Nie udało się załadować wniosku o identyfikatorze: ${id}`,
          'error'
        );

        navigate(URL.application.myApplications);

        return;
      }

      setApplicationData(payload);
    };

    const checkIfHasActiveWithdrawal = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationHasActiveWithdrawal.replace(':id', id)}`
      );

      if (!statusSuccess) {
        navigate(URL.application.myApplications);
        setTimeout(() => {
          notify('Wystąpił błąd podczas sprawdzania, czy zawnioskowano o wycofanie. Spróbuj ponownie.', 'error');
        }, 100);

        return;
      }

      setHasActiveWithdrawal(payload.active_withdrawal_exists);
    };

    checkIfHasActiveWithdrawal();
    loadApiData();
  }, [id]);

  if (!applicationData) {
    return <ContainerLoader />;
  }

  return (
    <Fade timeout={600} in>
      <Grid container component="main" justifyContent="center">
        <Grid item xs={10} md={8} lg={8}>
          <TitledContainer title="Wycofanie wniosku o dofinansowanie" variant="slim">
            <ApplicationWithdrawalForm
              applicationData={applicationData}
              hasActiveWithdrawal={hasActiveWithdrawal}
            />
          </TitledContainer>
        </Grid>
      </Grid>
    </Fade>
  );
}
