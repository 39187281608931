import {
  useFieldArray,
  useFormContext,
  Controller,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  TextField,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { DICTIONARIES } from '../../../../_constants';
import { Alert } from '../../../Alert';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';

/**
 * ClusterMembersNumber subfield of PkwEligibility.
 *
 * @returns {ClusterMembersNumber}
 */
export function ClusterMembersNumber() {
  const {
    control, setValue, getValues,
  } = useFormContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.geoDictionariesVoivodeships,
  );

  const voivodeships = get(DICTIONARIES.geoDictionariesVoivodeships?.name);

  const {
    fields, append,
  } = useFieldArray({
    control,
    name: 'pkw_eligibility_number_cluster_members_by_voivodeships',
  });

  useEffect(() => {
    if (voivodeships.length > 0 && fields.length === 0) {
      voivodeships.forEach(({ name }) => {
        append({
          name,
          cluster_members_based_in_voivodeship: null,
          percentage_share: null,
        });
      });
    }
  }, [voivodeships]);

  const countPercentageClusterMembersNumber = () => {
    const numberClusterMembersValues = getValues('pkw_eligibility_number_cluster_members_by_voivodeships');
    const totalClusterMembers = numberClusterMembersValues
      .reduce((total, item) => Number(item.cluster_members_based_in_voivodeship || 0) + total, 0);
    numberClusterMembersValues.forEach((item, i) => {
      if (item.cluster_members_based_in_voivodeship === null) {
        return;
      }
      if (item.cluster_members_based_in_voivodeship?.toString() === '0') {
        setValue(`pkw_eligibility_number_cluster_members_by_voivodeships.${i}.percentage_share`, '0.00');
        setValue(`pkw_eligibility_number_cluster_members_by_voivodeships.${i}.cluster_members_based_in_voivodeship`, '0');

        return;
      }
      const totalValue = item.cluster_members_based_in_voivodeship / totalClusterMembers * 100;
      setValue(`pkw_eligibility_number_cluster_members_by_voivodeships.${i}.percentage_share`, totalValue % 2 ? totalValue.toFixed(2) : totalValue);
    });
  };

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="30%" scope="column" sx={{ fontWeight: 700 }}>Województwo</TableCell>
            <TableCell
              width="30%"
              scope="column"
              sx={{ fontWeight: 700 }}
            >
              Liczba członków klastra z siedzibą/miejscem zamieszkania w województwie
            </TableCell>
            <TableCell width="30%" scope="column" sx={{ fontWeight: 700 }}>Udział procentowy</TableCell>
          </TableRow>
        </TableHead>
        {fields.map((item, index) => (
          <TableBody key={item.name}>
            <TableRow>
              <TableCell>
                {item.name}
              </TableCell>
              <TableCell>
                <FormControl variant="outlined" fullWidth>
                  <Controller
                    name={`pkw_eligibility_number_cluster_members_by_voivodeships.${index}.cluster_members_based_in_voivodeship`}
                    control={control}
                    render={({
                      field: {
                        value, name, onChange, onBlur,
                      },
                      fieldState: {
                        error,
                      },
                    }) => (
                      <NumericFormat
                        id={name}
                        name={name}
                        onValueChange={(values) => onChange({
                          target: {
                            name,
                            value: values.floatValue,
                          },
                        })}
                        onBlur={(event) => {
                          onBlur(event);
                          countPercentageClusterMembersNumber(index);
                        }}
                        defaultValue={value}
                        variant="outlined"
                        error={!!error}
                        helperText={error?.message}
                        customInput={TextField}
                        allowNegative={false}
                        decimalScale={0}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl variant="outlined" fullWidth>
                  <Controller
                    name={`pkw_eligibility_number_cluster_members_by_voivodeships.${index}.percentage_share`}
                    control={control}
                    render={({ field: { value } }) => (
                      <Alert severity="status">
                        {value}
                      </Alert>
                    )}
                  />
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}
