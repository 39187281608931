import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Venture implementation justification
 *
 * @returns {VentureImplementationJustification}
 */
export function VentureImplementationJustification() {
  const { isReadonly } = useElementContext();
  const { get } = useDictionaryLoader(
    DICTIONARIES.solutionInnovationLevels,
  );

  return (
    <FieldSorter>
      {/* Cel przedsięwzięcia  */}
      <FormField name="venture_implementation_justification_venture_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis realizacji przedsięwzięcia  */}
      <FormField name="venture_implementation_justification_venture_implementation_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* W jaki sposób przedsięwzięcie przyczyni się do realizacji celu inwestycji A.2.2.1 KPO,
      jakim jest ułatwienie transformacji przedsiębiorstw w kierunku gospodarki o obiegu zamkniętym (GOZ)
      poprzez wsparcie projektów wpisujących się w regulacje unijne (CEAP 2020) i krajowe („Mapa drogowa
      transformacji w kierunku gospodarki obiegu zamkniętego”)?  */}
      <FormField name="venture_implementation_justification_achieving_purpose_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis bezpośredniego związku przedsięwzięcia z gospodarką o obiegu zamkniętym    */}
      <FormField name="venture_implementation_justification_venture_and_economy_connection_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis sposobu wprowadzenia innowacji, w tym innowacji środowiskowej ( jeśli dotyczy)    */}
      <FormField name="venture_implementation_justification_implementation_innovation_method_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Poziom innowacyjności rozwiązania */}
      <FormField name="venture_implementation_justification_solution_innovation_level">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.solutionInnovationLevels.name))}
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Uzasadnienie poziomu innowacyjności rozwiązania */}
      <FormField name="venture_implementation_justification_innovation_level_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis sposobu wprowadzenia technologii środowiskowych związanej z gospodarką o obiegu
      zamkniętym ( jeśli dotyczy) */}
      <FormField name="venture_implementation_justification_environmental_technologies_methods_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
