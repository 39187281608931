import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Edih Organization Pdf element.
 *
 * @returns {EdihOrganizationPdf}
 */
function EdihOrganizationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="edih_organization_name" />
        <RowField name="edih_organization_coordinator_potential" />
        <RowField name="edih_organization_partners_potential" />
      </FieldSorter>
    </View>
  );
}

export default EdihOrganizationPdf;
