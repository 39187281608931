import PropTypes from 'prop-types';
import {
  Button,
  Popover,
  Typography,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { theme } from '../../styles/theme';

const classes = {
  button: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    borderLeft: '0 !important',
    backgroundColor: `${theme.palette.background.default} !important`,
    maxHeight: '56px',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },
  },
  buttonNoBorder: {
    color: theme.palette.secondary.main,
    padding: 0,
    border: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      border: 0,
    },
  },
  paper: {
    boxShadow: 'none',
    maxWidth: '600px',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(-2),
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
    backgroundColor: '#F7F7F7',
    borderRadius: '5px',
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    borderLeft: '1px solid #D7D7D7',
    borderBottom: '1px solid #D7D7D7',
    borderRight: '1px solid #D7D7D7',
  },
  content: {
    display: 'block',
    maxHeight: '200px',
    overflow: 'auto',
  },
  arrowRight: {
    position: 'absolute',
    right: '-2px',
    top: '-15px',
    width: '0',
    height: '0',
    borderWidth: '20px 0 0 20px',
    borderColor: 'transparent transparent transparent #F7F7F7',
    borderStyle: 'solid',
    filter: `drop-shadow(-2px -5px 0px ${theme.palette.secondary.main})`,
  },
  arrowLeft: {
    position: 'absolute',
    left: '-2px',
    top: '-15px',
    width: '0',
    height: '0',
    borderWidth: '0 0 20px 20px',
    borderColor: 'transparent transparent #F7F7F7 transparent',
    borderStyle: 'solid',
    filter: `drop-shadow(2px -5px 0px ${theme.palette.secondary.main})`,
  },
};

/**
 * Renders info popover component after click button.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {boolean} props.noBorder - button without border
 * @param {string} props.position - position of popover
 * @returns {InfoPopover}
 */
function InfoPopover({
  children, noBorder, position,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonClass = noBorder ? classes.buttonNoBorder : classes.button;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box display="inline-flex" justifyContent="flex-end">
      <Button
        variant="outlined"
        aria-label="Pomoc kontekstowa"
        onClick={handleClick}
        sx={buttonClass}
      >
        <InfoOutlined />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: position,
        }}
        PaperProps={{
          sx: classes.paper,
        }}
      >
        <div style={classes.wrapper}>
          <div style={position === 'left' ? classes.arrowLeft : classes.arrowRight} />
          <Typography variant="caption" py={2} px={3} sx={classes.content}>
            {children}
          </Typography>
        </div>
      </Popover>
    </Box>
  );
}

InfoPopover.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
};

InfoPopover.defaultProps = {
  noBorder: false,
  position: 'right',
};

export default InfoPopover;
