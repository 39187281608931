import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useElementContext } from '../../../../Context';
import { FormField } from '../../../Application/Application/FormField';
import CharsCounter from '../../../CharsCounter';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../../DictionaryProvider/useDictionaryLoader';
import {
  DATE,
  DICTIONARIES,
} from '../../../../_constants';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementersAndApplicantName,
} from '../../../../_helpers';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import FieldConfig from '../../../Application/Application/FieldConfig/FieldConfig';

/**
 * Defense of industrial property rights outside Poland component
 *
 * @returns {InternationalizationModuleEssencePropertyDefence}
 */
export default function InternationalizationModuleEssencePropertyDefence() {
  const { id: applicationId } = useParams();
  const {
    control, watch, getValues, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { get } = useDictionaryLoader(
    DICTIONARIES.internationalizationModuleEssenceLawCategories,
    DICTIONARIES.internationalizationModuleEssenceModeOfActions,
    DICTIONARIES.internationalizationModuleEssenceActionSubjects,
  );

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'internationalization_module_essence_property_defence',
  });

  const internationalizationModuleEssencePropertyDefenceConfig = fieldsConfig
    .internationalization_module_essence_property_defence;
  const subfields = internationalizationModuleEssencePropertyDefenceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = internationalizationModuleEssencePropertyDefenceConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  // eslint-disable-next-line max-len
  const otherModeOfActionId = '/lsi/recruitments/api/internationalization-module-essence-mode-of-actions/1e91d033-ef91-460d-87ce-06be88b7afc5';

  return (
    <>
      {fields.map((field, index) => (
        // Tabela \"Obrona praw własności przemysłowej poza Polską\"
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'contact_persons',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed={!subfields.ticket_number}
        >
          {/* Naruszone prawo własności numer */}
          <FieldConfig
            name={`internationalization_module_essence_property_defence.${index}.ticket_number`}
          >
            {({
              label, title,
            }) => (
              <Grid item xs={12}>
                <Typography mb={1.5} textAlign="left" fontSize={16} fontWeight={700}>
                  {`${label} ${index + 1}` || `${title} ${index + 1}`}
                </Typography>
              </Grid>
            )}
          </FieldConfig>

          {/* Nazwa wnioskodawcy lub konsorcjanta ubiegającego się o ochronę */}
          <FormField
            name={`internationalization_module_essence_property_defence.${index}.applicant_name`}
          >
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={getImplementersAndApplicantName(getValues, applicationId)}
                multiple
                renderSelectAllButton={false}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Dane dotyczące prawa ochronnego będącego przedmiotem działania planowanego do podjęcia w ramach */}
          <FieldConfig
            name={`internationalization_module_essence_property_defence.${index}.protection_law_data`}
          >
            {({
              label, title,
            }) => (
              <Grid item xs={12}>
                <Typography mb={1.5} textAlign="left" fontSize={16} fontWeight={700}>
                  {label || title}
                </Typography>
              </Grid>
            )}
          </FieldConfig>
          {/* Tytuł/nazwa prawa ochronnego */}
          <FormField
            name={`internationalization_module_essence_property_defence.${index}.protection_law_title`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Kategoria prawa ochronnego, co do którego podjęte zostaną działania w ramach obrony */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.protection_law_category`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceLawCategories.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Data wydania decyzji o udzieleniu patentu/prawa ochronnego/prawa z rejestracji */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.decision_date`}>
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <DesktopDatePicker
                ampm={false}
                value={value}
                clearable
                slotProps={{
                  textField: {
                    name,
                    id: name,
                    variant: 'outlined',
                    error: !!error,
                    onBlur,
                    helperText: error?.message,
                    placeholder: DATE.defaultDatePlaceholder,
                    color: 'secondary',
                  },
                }}
                disabled={isReadonly}
                onChange={(newDate) => {
                  onChange({
                    target: {
                      name,
                      value: newDate,
                    },
                  });
                }}
                format="yyyy-MM-dd"
              />
            )}
          </FormField>
          {/* Numer patentu/prawa z rejestracji/ prawa ochronnego */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.protection_law_number`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Tryb działań podejmowanych w ramach obrony */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.mode_of_action`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceModeOfActions.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {watch(`internationalization_module_essence_property_defence.${index}.mode_of_action`) === otherModeOfActionId
            && (
              // Inny tryb zgłoszenia
              <FormField name={`internationalization_module_essence_property_defence.${index}.other_ticket_mode`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            )}
          {/* Postępowanie dotyczy */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.subject_of_action`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceActionSubjects.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          {/* Uzasadnienie podjęcia planowanego działania w ramach obrony */}
          <FormField
            name={`internationalization_module_essence_property_defence.${index}.action_justification`}
          >
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Strona/y postępowania */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.action_parties`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Organ, przed którym prowadzone będzie postępowanie */}
          <FormField name={`internationalization_module_essence_property_defence.${index}.department_action`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="72escC9j2z8fgiS"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
