import {
  Link as MUILink,
  Typography,
} from '@mui/material';
import { VersionDialog } from '../../Dialogs';
import { useGlobalDialog } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  siteInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  copyright: {
    fontSize: '1rem',
    letterSpacing: '0.6px',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
  },
  appVersion: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'flex-start',
    },
    '&.MuiLink-root': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

/**
 * Site information fragment of LSI Footer.
 *
 * @returns {SiteInfo}
 */
export function SiteInfo() {
  const { render } = useGlobalDialog();

  return (
    <div style={classes.siteInfo}>
      <MUILink
        onClick={() => render(
          <VersionDialog />,
          'LSI - wersje aplikacji',
          {
            maxWidth: 'xs',
          }
        )}
        color="inherit"
        id="eK1DGnPU5oagZjh"
        component="button"
        sx={classes.appVersion}
      >
        LSI - wersja aplikacji
      </MUILink>
      <Typography
        variant="body"
        component="p"
        sx={classes.copyright}
      >
          &nbsp;©PARP&nbsp;
        {new Date().getFullYear()}
      </Typography>
    </div>
  );
}
