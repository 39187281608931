import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import CharsCounter from '../../CharsCounter';
import { FormField } from '../../Application/Application/FormField';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Risks component
 *
 * @returns {Risks}
 */
function Risks() {
  const {
    fieldsConfig, isReadonly, id: elementId,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: `risks-${elementId}`,
  });

  const risksConfig = fieldsConfig[`risks-${elementId}`];
  const subfields = risksConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = risksConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);
  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.riskTypes);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <LabeledFieldRow fieldConfig={risksConfig} contextHelpLabel fullWidth>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          key={field.id}
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            `risks-${elementId}`,
            index,
          )}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          buttonRemoveVisible={fields.length > minRowNumber}
          isIndexed
        >
          <FormField name={`risks-${elementId}.${index}.risk_name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 200} /> }}
                inputProps={{ maxLength: maxLength ?? 200 }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`risks-${elementId}.${index}.risk_type`}>
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.riskTypes.name))}
                disabled={isReadonly}
                error={error}
              />
            )}
          </FormField>
          <FormField name={`risks-${elementId}.${index}.risk_description`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 1000} /> }}
                inputProps={{ maxLength: maxLength ?? 1000 }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          <FormField name={`risks-${elementId}.${index}.risk_prevention`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? 2000} /> }}
                inputProps={{ maxLength: maxLength ?? 2000 }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="a1w4DM0jSydrW57"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}

export default Risks;
