import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { App } from './App';
import { AuthProvider } from './_security';
import { GlobalProvider } from './Context';

if (process.env.REACT_APP_SENTRY_DSN.length > 0) {
  /**
   * @see https://docs.sentry.io/platforms/javascript/guides/react/
   */
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
  });
}

ReactDOM.render(
  <StrictMode>
    <GlobalProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </GlobalProvider>
  </StrictMode>,
  document.getElementById('root')
);
