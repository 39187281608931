import PropTypes from 'prop-types';
import {
  Text,
  View,
} from '@react-pdf/renderer';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import RowConfig from '../../../Components/RowConfig/RowConfig';
import { printStyle } from '../../../print.style';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import ActualExpensesTablePdf from './ActualExpensesTable.pdf';
import { useApplicationToPdfContext } from '../../../../../Context';
import FlatRateExpensesTablePdf from './FlatRateExpensesTable.pdf';
import {
  getIndexForPreviousRowData,
  getAdditionalStylesByStatus,
} from '../../../../../_helpers';

/**
 * MaterialAndFinancialScheduleTablePdf element.
 *
 * @param {object} props - root props
 * @param {object} props.taskData - task object
 * @param {number} props.index - index
 * @param {string} props.elementId - element id
 * @returns {MaterialAndFinancialScheduleTablePdf}
 */
function MaterialAndFinancialScheduleTablePdf({
  taskData, index, elementId,
}) {
  const {
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const previousTasks = previousInitialFormData[`tasks-${elementId}`] ?? [];
  const previousTask = previousTasks[getIndexForPreviousRowData(previousTasks, taskData.task_id, 'task_id')];
  const additionalStyles = getAdditionalStylesByStatus(taskData.status);

  return (
    <>
      <Text style={printStyle.sectionTitle}>Zadanie</Text>
      <View style={{
        ...printStyle.table,
        ...additionalStyles,
      }}
      >
        <PrintoutTableRow>
          <RowConfig name={`tasks-${elementId}.${index}.name`}>
            {({
              label,
            }) => (
              <PrintoutTableCell rowSpan={3} bgColor="gray">
                {label}
              </PrintoutTableCell>
            )}
          </RowConfig>
          <RowConfig name={`tasks-${elementId}.${index}.data_rozpoczecia_zadania`}>
            {({
              label,
            }) => (
              <PrintoutTableCell rowSpan={3} bgColor="gray">
                {label}
              </PrintoutTableCell>
            )}
          </RowConfig>
          <RowConfig name={`tasks-${elementId}.${index}.data_zakonczenia_zadania`}>
            {({
              label,
            }) => (
              <PrintoutTableCell rowSpan={3} bgColor="gray">
                {label}
              </PrintoutTableCell>
            )}
          </RowConfig>
        </PrintoutTableRow>
        <PrintoutTableRow>
          <RowConfig
            name={`tasks-${elementId}.${index}.name`}
            value={taskData.name}
            oldValue={previousTask?.name}
          >
            {({ fieldValue }) => (
              <PrintoutTableCell rowSpan={3}>
                {fieldValue}
              </PrintoutTableCell>
            )}
          </RowConfig>
          <RowConfig
            name={`tasks-${elementId}.${index}.data_rozpoczecia_zadania`}
            value={taskData.data_rozpoczecia_zadania}
            oldValue={previousTask?.data_rozpoczecia_zadania}
          >
            {({ fieldValue }) => (
              <PrintoutTableCell rowSpan={3}>
                {fieldValue}
              </PrintoutTableCell>
            )}
          </RowConfig>
          <RowConfig
            name={`tasks-${elementId}.${index}.data_zakonczenia_zadania`}
            value={taskData.data_zakonczenia_zadania}
            oldValue={previousTask?.data_zakonczenia_zadania}
          >
            {({ fieldValue }) => (
              <PrintoutTableCell rowSpan={3}>
                {fieldValue}
              </PrintoutTableCell>
            )}
          </RowConfig>
        </PrintoutTableRow>
        <RowField
          name={`tasks-${elementId}.${index}.task_type`}
          value={taskData.task_type}
          oldValue={previousTask?.task_type}
          dictionaryName={DICTIONARIES.taskType.name}
        />
        <RowField
          name={`tasks-${elementId}.${index}.opis_i_uzasadnienie`}
          value={taskData.opis_i_uzasadnienie}
          oldValue={previousTask?.opis_i_uzasadnienie}
          isSingleRow
        />
        <RowField
          name={`tasks-${elementId}.${index}.planned_works_description`}
          value={taskData.planned_works_description}
          oldValue={previousTask?.planned_works_description}
          isSingleRow
        />
        <RowField
          name={`tasks-${elementId}.${index}.milestone_name`}
          value={taskData.milestone_name}
          oldValue={previousTask?.milestone_name}
          isSingleRow
        />
        <RowField
          name={`tasks-${elementId}.${index}.milestone_params`}
          value={taskData.milestone_params}
          oldValue={previousTask?.milestone_params}
          isSingleRow
        />
        <RowField
          name={`tasks-${elementId}.${index}.milestone_achievement_verification`}
          value={taskData.milestone_achievement_verification}
          oldValue={previousTask?.milestone_achievement_verification}
          isSingleRow
        />
        <RowField
          name={`tasks-${elementId}.${index}.milestone_fail_impact`}
          value={taskData.milestone_fail_impact}
          oldValue={previousTask?.milestone_fail_impact}
          isSingleRow
        />
      </View>
      <ActualExpensesTablePdf additionalStyles={additionalStyles} taskId={taskData.task_id} elementId={elementId} />
      <FlatRateExpensesTablePdf additionalStyles={additionalStyles} taskId={taskData.task_id} elementId={elementId} />
    </>
  );
}

export default MaterialAndFinancialScheduleTablePdf;

MaterialAndFinancialScheduleTablePdf.propTypes = {
  taskData: PropTypes.objectOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  elementId: PropTypes.string.isRequired,
};
