import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Applicant other applications pdf element.
 *
 * @returns {ApplicantOtherApplicationsPdf}
 */
function ApplicantOtherApplicationsPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="applicant_other_applications_is_submitted_again" />
        {initialFormData?.applicant_other_applications_is_submitted_again === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField name="applicant_other_applications_application_number" />
            <RowField name="applicant_other_applications_title" isSingleRow />
            <RowField name="applicant_other_applications_description" isSingleRow />
          </>
        )}
        <RowField
          name="applicant_other_applications_starting_platforms"
          dictionaryName={DICTIONARIES.startingPlatforms.name}
        />
      </FieldSorter>
    </View>
  );
}

export default ApplicantOtherApplicationsPdf;
