import PropTypes from 'prop-types';
import {
  useCallback,
  useMemo,
} from 'react';
import {
  useApplicationToPdfContext,
  useElementContext,
} from '../../../../Context';
import {
  formatDateString,
  getFieldConfig,
  getChangesInField,
} from '../../../../_helpers';
import {
  DATE,
  FIELD_TYPES,
} from '../../../../_constants';

/**
 * Row config
 *
 * @param {object} props - root props
 * @param {string} props.name - field name
 * @param {any} props.value - external field value
 * @param {string} props.dictionaryName - dictionary name
 * @param {Array} props.externalDictionary - external dictionary list
 * @param {Node} props.children - children element
 * @param {boolean} props.skipFilterDictionary - skip filtering dictionaries
 * @param {string} props.formatDate - format date
 * @param {string} props.oldValue - old field value
 * @returns {RowConfig}
 */
function RowConfig({
  name, value, dictionaryName, externalDictionary, children, skipFilterDictionary, formatDate, oldValue,
}) {
  const {
    managedApplicationTemplate: {
      fieldsConfig,
      initialFormData,
    },
    getDictionary,
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const { id: templateElementId } = useElementContext();

  /**
   * Field config based on field name.
   * Note that field can be nested. Dot separated notation is used to retrieve nested field.
   * Field also can be indexed (for collection fields) like 'field.0.name` is
   * equivalent of `field.name`.
   */
  const fieldConfig = useMemo(() => getFieldConfig(fieldsConfig, name), [name]);

  const ConfigWrapper = useCallback((callback) => (
    callback({
      name,
      fieldValue: getFieldValue(),
      label: fieldConfig?.label || fieldConfig?.title,
    })
  ));

  if (!fieldConfig || !children) {
    return '';
  }

  const fieldValue = value || initialFormData[name] || fieldConfig?.defaultValue;
  const oldFieldValue = oldValue || previousInitialFormData[name] || fieldConfig?.defaultValue;

  const filterOptions = (allChoices) => {
    if (
      fieldConfig?.type === FIELD_TYPES.choice
      && !fieldConfig?.apiEndpoint
      && !allChoices
      && !skipFilterDictionary
    ) {
      return fieldConfig.choices;
    }

    if (fieldConfig?.type === FIELD_TYPES.limitedChoice && !skipFilterDictionary) {
      return allChoices.filter((choice) => (fieldConfig.choices.length === 0
        ? true
        : fieldConfig.choices.includes(choice.id)));
    }

    return allChoices || [];
  };

  const getDictionaryList = (dictionaryList) => {
    if (dictionaryName) {
      return filterOptions(getDictionary(`${dictionaryName}-${templateElementId}`));
    }

    if (dictionaryList.length !== 0) {
      return filterOptions(externalDictionary);
    }

    return filterOptions(fieldConfig?.allChoices).map((choice) => ({
      '@id': choice.id,
      name: choice.label,
    }));
  };

  const multipleDictionaryToString = (dictionaries, dictionaryList) => dictionaries?.map((item) => dictionaryList
    .find(({ '@id': dictionaryId }) => dictionaryId === item)?.name)
    .join(', ');

  const findSingleDictionaryName = (dictionaryList, dictionaryIri) => dictionaryList
    .find(({ '@id': dictionaryId }) => dictionaryId === dictionaryIri)
    ?.name || dictionaryIri;

  const getFieldValue = () => {
    if (
      (fieldConfig.type === FIELD_TYPES.choice
        || fieldConfig.type === FIELD_TYPES.limitedChoice
        || fieldConfig.type === FIELD_TYPES.attachments)
      && (dictionaryName || externalDictionary.length !== 0)
    ) {
      const dictionaryList = getDictionaryList(externalDictionary);

      if (Array.isArray(fieldValue)) {
        const fieldValuesToString = multipleDictionaryToString(fieldValue, dictionaryList);
        const oldFieldValuesToString = multipleDictionaryToString(oldFieldValue, dictionaryList);

        return withChanges ? getChangesInField(oldFieldValuesToString, fieldValuesToString) : fieldValuesToString;
      }

      const singleDictionaryValue = findSingleDictionaryName(dictionaryList, fieldValue);
      const oldSingleDictionaryValue = findSingleDictionaryName(dictionaryList, oldFieldValue);

      return withChanges ? getChangesInField(oldSingleDictionaryValue, singleDictionaryValue) : singleDictionaryValue;
    }

    if (fieldConfig.type === FIELD_TYPES.date && fieldValue) {
      const dateFieldValue = formatDateString(fieldValue, formatDate);
      const oldDateFieldValue = formatDateString(oldFieldValue, formatDate);

      return withChanges ? getChangesInField(oldDateFieldValue, dateFieldValue) : dateFieldValue;
    }

    return withChanges ? getChangesInField(oldFieldValue, fieldValue) : fieldValue || '';
  };

  return (
    <>
      {ConfigWrapper(children)}
    </>
  );
}

export default RowConfig;

RowConfig.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  dictionaryName: PropTypes.string,
  externalDictionary: PropTypes.arrayOf(Object),
  skipFilterDictionary: PropTypes.bool,
  formatDate: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
    PropTypes.func,
  ]),
  oldValue: PropTypes.string,
};

RowConfig.defaultProps = {
  value: null,
  dictionaryName: '',
  externalDictionary: [],
  children: null,
  skipFilterDictionary: false,
  formatDate: DATE.defaultFormatWithoutTime,
  oldValue: '',
};
