import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import ImplementedProjects from './RdProjectTeam/ImplementedProjects';
import CommitmentPerson from './RdProjectTeam/CommitmentPerson';

/**
 * RdProjectTeam element renderer.
 *
 * @returns {RdProjectTeam}
 */
export function RdProjectTeam() {
  const {
    control, watch, setValue, resetField,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const collectionFieldName = 'rd_project_team_project_team';
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const commitmentPersonConfig = fieldsConfig?.[collectionFieldName]?.fields?.commitment_person;
  const implementedProjectsConfig = fieldsConfig?.[collectionFieldName]?.fields?.implemented_projects;

  const projectTeamConfig = fieldsConfig?.[collectionFieldName];
  const projectTeamSubfields = projectTeamConfig?.fields || {};
  const projectTeamFieldObject = getCollectionFieldObject(projectTeamSubfields, fields);
  const {
    maxRowNumber,
    minRowNumber,
  } = projectTeamConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(projectTeamSubfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.roleInProjects,
    DICTIONARIES.education,
    DICTIONARIES.cooperationStatuses,
  );

  const {
    roleInProjects, cooperationStatuses,
  } = ELEMENTS_DICTIONARIES_MAP;

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Tabela: Zespół projektowy */}
      <LabeledFieldRow fieldConfig={projectTeamConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => {
          const cooperationStatusValue = watch(`${collectionFieldName}.${index}.role_cooperation_status`);
          const isRoleConsortiumMemberNameVisible = cooperationStatusValue
            === cooperationStatuses.consortiumEmployee.id
            || cooperationStatusValue === cooperationStatuses.agreementSignedByConsortium.id;

          const roleInProjectsValue = watch(`${collectionFieldName}.${index}.role_in_project`);
          const isImplementedProjectsVisible = roleInProjectsValue
            === roleInProjects.rdManager.id || roleInProjectsValue === roleInProjects.rdModuleManager.id
            || roleInProjectsValue === roleInProjects.moduleManager.id;

          return (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                collectionFieldName,
                index,
              )}
              isReadonly={isReadonly}
              itemIndex={index}
              fieldsOrder={Object.keys(projectTeamSubfields)}
              key={field.id}
              buttonRemoveVisible={fields.length > minRowNumber}
              isIndexed
            >
              {/* Imię */}
              <FormField name={`${collectionFieldName}.${index}.first_name`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Nazwisko */}
              <FormField name={`${collectionFieldName}.${index}.last_name`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Wykształcenie */}
              <FormField name={`${collectionFieldName}.${index}.education`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.education.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Tytuł naukowy/ stopień naukowy */}
              <FormField name={`${collectionFieldName}.${index}.academic_degree`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Rola w projekcie */}
              <FormField name={`${collectionFieldName}.${index}.role_in_project`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== roleInProjects.rdManager.id
                        && event.target.value !== roleInProjects.rdModuleManager.id) {
                        resetField(`${collectionFieldName}.${index}.implemented_projects`, { defaultValue: FIELD_TYPES_MAP.collection });
                      }
                    }}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.roleInProjects.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Doświadczenie naukowe i zawodowe */}
              <FormField name={`${collectionFieldName}.${index}.professional_experience`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Rola i zakres obowiązków w module */}
              <FormField name={`${collectionFieldName}.${index}.role_and_responsibilities`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    multiline
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Wymiar zaangażowania w module */}
              <FormField name={`${collectionFieldName}.${index}.role_involvement_dimension`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Status współpracy */}
              <FormField name={`${collectionFieldName}.${index}.role_cooperation_status`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== cooperationStatuses.consortiumEmployee.id
                        && event.target.value !== cooperationStatuses.agreementSignedByConsortium.id) {
                        resetField(`${collectionFieldName}.${index}.role_consortium_member_name`, { defaultValue: FIELD_TYPES_MAP.text });
                      }
                    }}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.cooperationStatuses.name))}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>

              {/* Doświadczenie członka zespołu zarządzającego w projektach grantowych lub tożsamych z grantowymi */}
              <FormField name={`${collectionFieldName}.${index}.team_member_experience`} contextHelpLabel>
                {() => null}
              </FormField>
              {/* Doświadczenie w realizacji projektu grantowego */}
              <FormField name={`${collectionFieldName}.${index}.experience_in_implementing_grant_project`}>
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Doświadczenie w realizacji projektu tożsamego z projektem grantowym  */}
              <FormField
                name={`${collectionFieldName}.${index}.experience_in_implementing_project_identical_to_grant_project`}
              >
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Okres zaangażowania w ramach projektu grantowego lub tożsamego  */}
              <FormField name={`${collectionFieldName}.${index}.involvement_period_in_grant_project`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Opis doświadczenia w realizacji projektu grantowego lub tożsamego z projektem grantowym   */}
              <FormField
                name={`${collectionFieldName}.${index}.experience_description_in_grant_project_implementation`}
              >
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Stanowisko w zespole zarządzającym projektem   */}
              <FormField
                name={`${collectionFieldName}.${index}.position_in_project_management_team`}
              >
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {isRoleConsortiumMemberNameVisible && (
                <FormField name={`${collectionFieldName}.${index}.role_consortium_member_name`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
              <LabeledFieldRow
                hide={!isImplementedProjectsVisible}
                fieldConfig={implementedProjectsConfig}
                contextHelpLabel
                fullWidth
              >
                <ImplementedProjects parentIndex={index} />
              </LabeledFieldRow>

              <LabeledFieldRow fieldConfig={commitmentPersonConfig} contextHelpLabel fullWidth>
                <CommitmentPerson parentIndex={index} />
              </LabeledFieldRow>

            </CollectionFieldContainer>
          );
        })}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="oNh9yZLv6XZI3WM"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                projectTeamFieldObject,
              )}
            >
              Dodaj członka zespołu
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Pozostały personel B+R (jeśli dotyczy) */}
      <FormField name="rd_project_team_other_personnel">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Personel B+R planowany do zaangażowania (jeśli dotyczy) */}
      <FormField name="rd_project_team_personnel_planned_to_be_involved">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Sposób zarządzania modułem (ścieżka decyzyjna) */}
      <FormField name="rd_project_team_module_manage">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
