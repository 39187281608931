import {
  Button,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { request } from '../../../_services';
import {
  API_ROUTE,
  EXPERT_PANEL_TYPES,
} from '../../../_constants';
import { useCustomSnackbar } from '../../../_hooks';

/**
 * Application Acceptance Dialog element.
 *
 * @param {object} props - root props
 * @param {object} props.payload - payload
 * @param {string} props.expertPanelExpertsId - expert panel experts id
 * @param {string} props.panelType - panel type
 * @param {Function} props.reloadData - reload data
 * @param {boolean} props.isReadonly - is readonly
 * @returns {ApplicationAcceptanceDialog}
 */
function ApplicationAcceptanceDialog({
  payload, expertPanelExpertsId, panelType, reloadData, isReadonly,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const handleSubmit = async () => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertPanelExpertByUsers}/${expertPanelExpertsId}`, {
      ...payload,
      accepted: true,
    });

    reloadData();

    statusSuccess
      ? successNotification('Wniosek został zaakceptowany.')
      : errorNotification('Nie udało się zaakceptować wniosku. Spróbuj ponownie.');
  };

  if (panelType === EXPERT_PANEL_TYPES.withExpert) {
    return (
      <MenuItem
        key={uniqid()}
        variant="right-border"
        id={uniqid()}
        onClick={handleSubmit}
      >
        Przyjmij wniosek
      </MenuItem>
    );
  }

  return (
    <Button
      type="button"
      id="x83emK0NDFtZ3VP"
      variant="contained"
      color="secondary"
      onClick={handleSubmit}
      disabled={isReadonly}
    >
      Przyjmij wniosek
    </Button>
  );
}

export default ApplicationAcceptanceDialog;

ApplicationAcceptanceDialog.propTypes = {
  payload: PropTypes.objectOf(Object).isRequired,
  expertPanelExpertsId: PropTypes.string.isRequired,
  panelType: PropTypes.string,
  reloadData: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
};

ApplicationAcceptanceDialog.defaultProps = {
  panelType: EXPERT_PANEL_TYPES.module,
};
