import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import {
  SubstantiveResourcesIncubationProgramPdf,
} from './ApplicantAndProjectPartnersResources/SubstantiveResourcesIncubationProgram.pdf';
import { useApplicationToPdfContext } from '../../../../Context';
import { getImplementersAndApplicantNameForPdf } from '../../../../_helpers/getImplementersAndApplicantName';
import { printStyle } from '../../print.style';

/**
 * ApplicantAndProjectPartnersResourcesPdf element.
 *
 * @returns {ApplicantAndProjectPartnersResourcesPdf}
 */
function ApplicantAndProjectPartnersResourcesPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
  } = useApplicationToPdfContext();
  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];

  return (
    <CollectionWrapper name="applicant_and_project_partners_resources">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];
        const previousRowIndex = getIndexForPreviousRowData(item.set_uuid);

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.entity_name`}
                value={item.entity_name}
                oldValue={previousRowData?.entity_name}
                externalDictionary={getImplementersAndApplicantNameForPdf(applicantName, implementers, id)}
              />
            </View>
            <SubstantiveResourcesIncubationProgramPdf
              fieldName={collectionName}
              index={index}
              previousRowIndex={previousRowIndex}
            />
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.technical_resources`}
                value={item.technical_resources}
                oldValue={previousRowData?.technical_resources}
              />
              <RowField
                name={`${collectionName}.${index}.experience_in_service_delivery`}
                value={item.experience_in_service_delivery}
                oldValue={previousRowData?.experience_in_service_delivery}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.initiative`}
                value={item.initiative}
                oldValue={previousRowData?.initiative}
              />
              <RowField
                name={`${collectionName}.${index}.subject_and_scope`}
                value={item.subject_and_scope}
                oldValue={previousRowData?.subject_and_scope}
              />
              <RowField
                name={`${collectionName}.${index}.cooperation_course`}
                value={item.cooperation_course}
                oldValue={previousRowData?.cooperation_course}
              />
              <RowField
                name={`${collectionName}.${index}.services_effect`}
                value={item.services_effect}
                oldValue={previousRowData?.services_effect}
              />
              <RowField
                name={`${collectionName}.${index}.tasks_scope`}
                value={item.tasks_scope}
                oldValue={previousRowData?.tasks_scope}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ApplicantAndProjectPartnersResourcesPdf;
