import { XMLParser } from 'fast-xml-parser';
import { sortBy } from 'lodash';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';

/**
 * Will return Rss news list
 *
 * @returns {Array} News list
 */
export const getRssNews = async () => {
  const response = await request.getXML(API_ROUTE.rssFeedGet);
  const responseTxt = await response.text();

  if (!response.ok) {
    return [];
  }

  const options = {
    allowBooleanAttributes: true,
  };
  const parser = new XMLParser(options);
  const newsList = [];
  const parsedNewsList = parser.parse(responseTxt);
  const channelItem = parsedNewsList.rss.channel.item;
  const isCollection = Array.isArray(channelItem);
  const newsItems = isCollection ? channelItem : [channelItem];

  const mappedNewsList = newsItems.map((item) => ({
    title: item.title?.toString() || '',
    description: item.description || '',
    pubDate: item.pubDate ?? '',
    pined: isCollection ? item['ext-rss:pin'] === true : newsItems['ext-rss:pin'] === true,
  }));

  newsList.push(...mappedNewsList);

  return sortBy(newsList, ({ pubDate }) => new Date(pubDate)).reverse();
};
