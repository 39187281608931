import { Box } from '@mui/material';
import uniqid from 'uniqid';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../styles/theme';

/**
 * File/attachment icon.
 *
 * @param {object} props - root props
 * @param {string} props.extension - extension text to render inside icon
 * @returns {FileIcon}
 */
export function FileIcon({ extension }) {
  const fontSize = useMemo(() => {
    if (extension.length === 3) {
      return '96px';
    }

    if (extension.length === 4) {
      return '75px';
    }

    if (extension.length === 5) {
      return '60px';
    }

    return '30px';
  }, [extension]);

  return (
    <Box sx={{
      width: '50px',
      height: '60px',
    }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id={`svg_${uniqid()}`}
        version="1.1"
        viewBox="0 0 319.96 343.55"
      >
        <path
          style={{
            fill: theme.palette.secondary.main,
          }}
          transform="translate(-523.38 -212.22)"
          /* eslint-disable-next-line max-len */
          d="M648,555.77q-50.88,0-101.73-.06c-13.63,0-22.79-9.34-22.8-23.22q0-39.12,0-78.24,0-78.25,0-156.5a10.44,10.44,0,0,1,3.21-8c23.82-23.78,48.15-48.15,74.37-74.5a9.52,9.52,0,0,1,7.24-3.07q27.52,0,55,0h14.95q36.69,0,73.37,0c13.64,0,22.81,9.36,22.83,23.23q0,37.71,0,75.43v35.71H804.6c5.34,0,10.68,0,16,0,13.34.08,22.68,9.51,22.69,22.95,0,30.56,0,62.94,0,105,0,14.28-9.29,23.56-23.64,23.63l-15.46,0H774.39v5.21c0,3,0,6,0,9,0,6.65,0,13.52,0,20.28-.08,13.52-9.41,23-22.69,23Q699.84,555.77,648,555.77ZM536.71,530.11c0,9.71,2.61,12.36,12.16,12.36H711.26l22.53,0q8.76,0,17.51,0c6.18,0,9.52-3.31,9.65-9.49.06-3.25,0-6.54,0-9.74,0-1.38,0-2.76,0-4.15V498.25H586c-15.24,0-24.35-9.15-24.35-24.46q0-15.27,0-30.53c.06-23.54.12-47.89-.19-71.83a23.67,23.67,0,0,1,24.59-24.93h.23c22.21.16,46.59.24,76.74.24q23,0,46,0t46,0h5.59l0-1c0-.54.06-1,.09-1.51.06-.94.11-1.75.11-2.59q0-52.49,0-105c0-8.2-2.9-11.11-11.08-11.11H613.44v16.84c0,12,0,23.86,0,35.69,0,15.17-9,24.23-24.11,24.26H536.71Zm282-45.3c8.35,0,11.31-2.95,11.32-11.28q0-51.16,0-102.32c0-8.25-3.09-11.32-11.37-11.32H586.36c-8.4,0-11.23,2.87-11.23,11.37q0,51.17,0,102.33c0,8.38,2.84,11.22,11.27,11.22H818.72Zm-271.32-196,2.25.1.49,0c.5,0,1.11.07,1.73.07l22.37,0L590,289c6.81,0,10-3.26,10-10.22,0-12.59,0-25.39,0-37.77a10.81,10.81,0,0,0-.22-2c0-.14,0-.26-.06-.35l-.27-2Z"
        />
        <path
          style={{
            fill: theme.palette.secondary.main,
          }}
          transform="translate(-523.38 -212.22)"
          /* eslint-disable-next-line max-len */
          d="M687.14,302.32H664.08q-8.85,0-17.69,0c-2.83,0-6.45-.8-7.62-4.52a6.51,6.51,0,0,1,.83-5.92,7.36,7.36,0,0,1,6-2.84q6.32-.06,12.65,0h46.2l23.76,0a8.3,8.3,0,0,1,6.28,2.47,6,6,0,0,1,1.48,4.8c-.42,3.81-3.23,6-7.72,6-5.93,0-11.87,0-17.8,0Z"
        />
        <text
          y="242.02711"
          x="71.537216"
          xmlSpace="preserve"
        >
          <tspan
            style={{
              fontSize: `${fontSize}`,
              lineHeight: 2.25,
              letterSpacing: '1px',
              fontFamily: 'Montserrat-Light,sans-serif',
              fill: theme.palette.secondary.dark,
            }}
            rotate="0 0 0 0 0 0"
            y="242.02711"
            x="71.537216"
          >
            {extension.toUpperCase()}
          </tspan>
        </text>
      </svg>
    </Box>
  );
}

FileIcon.propTypes = {
  extension: PropTypes.string,
};

FileIcon.defaultProps = {
  extension: 'unknown',
};
