import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * GrantProjectManagementTeam Pdf element.
 *
 * @returns {GrantProjectManagementTeamPdf}
 */
function GrantProjectManagementTeamPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Doświadczenie i kompetencje 2 kluczowych członków zespołu zarządzającego projektem grantowym */}
        <RowField name="grant_project_management_team_experience_and_competence_two_key_members" />
        {/* Struktura organizacyjna zespołu zarządzającego projektem grantowym */}
        <RowField name="grant_project_management_team_organizational_structure" />
        {/* Tabela "Nazwa stanowiska, wymagane kompetencje oraz doświadczenie" */}
        <CollectionWrapper name="grant_project_management_job_title_required_competencies_and_experience">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.responsibility_area`}
                      value={item.responsibility_area}
                      oldValue={previousRowData?.responsibility_area}
                      dictionaryName={DICTIONARIES.responsibilityAreas.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.job_title_required_competencies_and_experience`}
                      value={item.job_title_required_competencies_and_experience}
                      oldValue={previousRowData?.job_title_required_competencies_and_experience}
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default GrantProjectManagementTeamPdf;
