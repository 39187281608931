/**
 * Function to format pkd codes field value.
 *
 * @param {Array} pkdCodes - pkd codes data
 * @returns {string}
 */
export const formatPkdCodesFieldValue = (pkdCodes) => pkdCodes.map(({
  name: pkdName, code, '@id': id,
}) => ({
  id,
  label: `${code} ${pkdName}`,
}));
