/**
 * Protest result statuses.
 *
 * @type {object}
 */
export const PROTEST_RESULT_STATUSES = {
  positive: 'positive',
  negative: 'negative',
  unconsidered: 'unconsidered',
};

export const PROTEST_RESULT_STATUSES_TRANSLATION = {
  [PROTEST_RESULT_STATUSES.positive]: 'Pozytywny',
  [PROTEST_RESULT_STATUSES.negative]: 'Negatywny',
  [PROTEST_RESULT_STATUSES.unconsidered]: 'Pozostawiony bez rozpatrzenia',
};
