import {
  Link,
  useLocation,
} from 'react-router-dom';
import {
  Grid,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import LoginGovLogo from '../LoginGovLogo';
import { useHover } from '../../_hooks';
import { styleClassesLoginMethod } from './LoginMethod.style';

/**
 * Select login method component.
 *
 * @returns {LoginMethod}
 */
export default function LoginMethod() {
  const classes = styleClassesLoginMethod;
  const { search } = useLocation();
  const [hoverRef, isHovered] = useHover();

  return (
    <Paper variant="outlined" elevation={0} sx={classes.loginMethodPaper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" sx={classes.loginMethodHeader}>Zaloguj się</Typography>
          <span style={classes.chooseLoginMethod}>wybierz sposób logowania</span>
        </Grid>
        <Grid item lg={3} />
        <Grid item xs={12} lg={12}>
          <Button
            ref={hoverRef}
            id="eAPzmobbiZVDpB2"
            variant="outlined"
            color="primary"
            sx={{
              ...classes.loginGovBtn,
              ...classes.jumboBtn,
            }}
            size="large"
          >
            {!isHovered
              ? <LoginGovLogo />
              : <Typography fontWeight="bold">Funkcjonalność nie jest dostępna</Typography>}
          </Button>
          <Link
            to={{
              pathname: '/auth/login-email',
              search,
            }}
            style={classes.linkWithBtn}
          >
            <Button
              id="EOdLOovP4rWmVvF"
              variant="outlined"
              sx={{
                ...classes.loginWithPassBtn,
                ...classes.jumboBtn,
              }}
              size="large"
              color="inherit"
            >
              Login i hasło
            </Button>
          </Link>
        </Grid>
        <Grid item lg={3} />
      </Grid>
    </Paper>
  );
}
