import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import ExpensesSummaryTable from './ExpensesSummary.table';
import {
  useElementContext,
  useReloadListener,
} from '../../../../../Context';
import { request } from '../../../../../_services';
import { API_ROUTE } from '../../../../../_constants';
import { ContainerLoader } from '../../../../Application/Application/ContainerLoader';
/**
 * Expenses summary component
 *
 * @returns {ExpensesSummary}
 */
const ExpensesSummary = forwardRef((_, ref) => {
  const {
    reload, watch,
  } = useReloadListener();
  const {
    id: elementId, fieldsConfig,
  } = useElementContext();
  const { id: applicationId } = useParams();
  const { setValue } = useFormContext();
  const [expensesSummaryData, setExpensesSummaryData] = useState({});
  const [currentReloadElement, setCurrentReloadElement] = useState('');
  const [isLoaded, setLoaded] = useState(false);
  const watcherName = `expenses-summary-${elementId}`;

  const handleReloadData = (reloadElementId) => {
    reload(watcherName);
    setCurrentReloadElement(`expenses-summary-${reloadElementId}`);
  };

  useImperativeHandle(ref, () => ({
    handleReloadData,
  }));

  const getExpensesSummaryData = async () => {
    const {
      payload, statusSuccess,
    } = await request
      .get(API_ROUTE.hrfElementSummary
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId));

    if (statusSuccess) {
      setExpensesSummaryData(payload);
      setValue(`hrf_element_summary-${elementId}`, payload[`hrf_element_summary-${elementId}`]);
      setLoaded(true);
    }
  };

  useEffect(() => {
    getExpensesSummaryData();
  }, [elementId, currentReloadElement]);

  useEffect(() => {
    if (currentReloadElement === elementId) {
      getExpensesSummaryData();
    }
  }, [watch(watcherName)]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  if (
    !(!(expensesSummaryData?.length === 0
      && !(`hrf_element_summary-${elementId}` in expensesSummaryData))
      && typeof expensesSummaryData[`hrf_element_summary-${elementId}`] !== 'string')
  ) {
    return '';
  }

  return (
    <>
      <Typography variant="pageHeading" textAlign="left">
        {fieldsConfig[`hrf_element_summary-${elementId}`].label}
      </Typography>
      <ExpensesSummaryTable data={expensesSummaryData[`hrf_element_summary-${elementId}`]} />
    </>
  );
});

export default ExpensesSummary;
