import PropTypes from 'prop-types';
import {
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { printStyle } from '../../print.style';

const singleElementStyle = StyleSheet.create({
  elementWrapper: {
    marginBottom: 20,
  },
});

/**
 * Pdf single element
 *
 * @param {object} props - root props
 * @param {string} props.title - element title
 * @param {Node} props.children - children component
 * @returns {SingleElement}
 */
function SingleElement({
  title, children,
}) {
  return (
    <View style={singleElementStyle.elementWrapper}>
      {title && (
        <Text style={printStyle.sectionTitle}>
          {title}
        </Text>
      )}
      <View>
        {children}
      </View>
    </View>
  );
}

export default SingleElement;

SingleElement.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

SingleElement.defaultProps = {
  title: null,
};
