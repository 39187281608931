import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import uniqid from 'uniqid';
import {
  API_ROUTE,
  HELPCENTER_VISIBLE_ON,
} from '../../_constants';
import { CustomDataGrid } from '../DataGrid/CustomDataGrid';
import { request } from '../../_services';
import { useGlobalContext } from '../../Context';

/**
 * List od help center messages
 *
 * @param {object} props - root props
 * @param {object} props.editFormHandler - function to invoke form edition
 * @returns {HelpCenterList}
 */
export function HelpCenterList({
  editFormHandler,
}) {
  const { notify } = useGlobalContext();
  const [reload, setReload] = useState(0);

  const columns = [
    {
      field: 'title',
      headerName: 'Nazwa',
      type: 'text',
      flex: 1,
    },
    {
      field: 'visibleOn',
      headerName: 'Profil wyświetlania',
      valueFormatter: ({ value }) => value.map((destination) => HELPCENTER_VISIBLE_ON[destination]).join(', '),
      filterType: 'multipleSelect',
      valueOptions: Object.entries(HELPCENTER_VISIBLE_ON).map(([value, label]) => ({
        id: value,
        label,
      })),
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      filterable: false,
      headerName: 'Akcje',
      getActions: (params) => getRowActions(params),
    },
  ];

  /**
   * Returns row actions.
   *
   * @param {string} messageId - message ID
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => [
    <Button
      key={`edit-${row.id}`}
      id={uniqid()}
      onClick={() => editFormHandler(row)}
      variant="contained"
      color="secondary"
      size="small"
    >
      Edytuj
    </Button>,
    <Button
      key={`delete-${row.id}`}
      id={uniqid()}
      onClick={() => handleDeleteMessage(row)}
      variant="contained"
      aria-label="Usuń kafelek pomocy"
      color="primary"
      size="small"
    >
      Usuń
    </Button>,
  ];

  const handleDeleteMessage = async (row) => {
    const { statusSuccess } = await request.delete(
      `${API_ROUTE.helpCenter}/${row.id}`,
    );

    if (statusSuccess) {
      setReload((prevState) => prevState + 1);
      notify('Kafelek pomocy został usunięty.', 'success');
    }
  };

  return (
    <CustomDataGrid
      apiSource={API_ROUTE.helpCenter}
      columns={columns}
      reloadListener={reload}
      name="help-center-grid"
    />
  );
}

HelpCenterList.propTypes = {
  editFormHandler: PropTypes.func.isRequired,
};
