import { useGridApiContext } from '@mui/x-data-grid';
import {
  Box,
  Button,
  ButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import uniqid from 'uniqid';
import { GridTextFilter } from './Filters/GridTextFilter';
import { GridSingleSelectFilter } from './Filters/GridSingleSelectFilter';
import { GridDateFilter } from './Filters/GridDateFilter';
import { GridMultipleSelectFilter } from './Filters/GridMultipleSelectFilter';

/**
 * Grid filters box as Data Grid Toolbar replacement.
 *
 * @param {object} props - root props
 * @param {Function} props.setFilterModel - function to apply filters
 * @param {object} props.filterModel - filter model
 * @returns {GridFilterToolbar}
 */
export function GridFilterToolbar({
  setFilterModel, filterModel,
}) {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;
  const columns = state?.columns?.lookup || {};

  const [cleanListener, updateCleanListener] = useState(0);
  const [filterValues, setFilterValues] = useState(filterModel);

  /**
   * Clears all filters and bump cleanListener to inform inputs to clean itselfs.
   */
  const clearAllFilters = () => {
    updateCleanListener((current) => current + 1);
    setFilterValues({});
  };

  /**
   * Function helper to set filter value by its field name.
   *
   * @param {string} fieldName - filtered field name
   * @param {any} value - filter value
   */
  const setFilterValue = (fieldName, value) => {
    setFilterValues((s) => ({
      ...s,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (cleanListener === 0 && Object.keys(filterValues).length === 0) {
      return () => {
      };
    }

    const timer = setTimeout(() => {
      setFilterModel(filterValues);
    }, 600);

    return () => clearTimeout(timer);
  }, [filterValues, setFilterModel]);

  /**
   * Retuns component matched by column type.
   *
   * @todo move to separated util
   * @param {object} columnData - data grid column data
   * @returns {Element|string}
   */
  const getInputComponent = (columnData) => {
    const {
      filterType, type: columnType, field: fieldName,
    } = columnData;
    const type = filterType || columnType;

    const visibleColumns = apiRef.current.getVisibleColumns() || [];
    const visibleColumnsNames = visibleColumns.map(({ field }) => field);

    if (!visibleColumnsNames.includes(fieldName)) {
      return '';
    }

    const filtersProps = {
      id: columnData.filterId || uniqid('gridfilter-', `-${columnData.field}`),
      cleanListener,
      columnData,
      applyFilter: setFilterValue,
      initialValue: filterValues[fieldName],
    };

    switch (type) {
    case 'string':
      return (
        <GridTextFilter
          {...filtersProps}
        />
      );
    case 'singleSelect':
      return (
        <GridSingleSelectFilter
          {...filtersProps}
        />
      );
    case 'date':
      return (
        <GridDateFilter
          {...filtersProps}
        />
      );
    case 'multipleSelect':
      return (
        <GridMultipleSelectFilter
          {...filtersProps}
        />
      );
    default:
      return '';
    }
  };

  return (
    <Box sx={{ display: 'flex' }} aria-live="polite" tabIndex={-1}>
      <ButtonGroup
        size="small"
        variant="outlined"
        sx={{
          top: '-35px',
          position: 'absolute',
          right: 0,
          borderRadius: 0,
        }}
        color="secondary"
      >
        <Button
          aria-label="Wyczyść wszystkie filtry"
          onClick={clearAllFilters}
          id={uniqid()}
        >
          Wyczyść filtry
        </Button>
      </ButtonGroup>

      {Object.entries(columns).map(([_, columnData]) => {
        if (!columnData.filterable) {
          return (
            <Box key={columnData.field} sx={{ width: columnData.computedWidth }} />
          );
        }

        return (
          <Box
            key={columnData.field}
            sx={{
              width: columnData.computedWidth,
              marginBottom: '5px',
            }}
          >
            {getInputComponent(columnData)}
          </Box>
        );
      })}
    </Box>
  );
}

GridFilterToolbar.propTypes = {
  setFilterModel: PropTypes.func.isRequired,
  filterModel: PropTypes.instanceOf(Object),
};

GridFilterToolbar.defaultProps = {
  filterModel: {},
};
