/**
 * Scope of startup incubation for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const scopeOfStartupIncubation = [
  {
    id: 'horizontal_incubation',
    label: 'inkubacja horyzontalna',
  },
  {
    id: 'industry_incubation',
    label: 'inkubacja branżowa ',
  },
];
