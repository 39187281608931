export const EVALUATION_SHEET_STATUS = {
  edition: 'edition',
  sentToParp: 'sent_to_parp',
  blocked: 'blocked',
  unblocked: 'unblocked',
  sentToApprove: 'sent_to_approve',
  approved: 'approved',
};

export const EVALUATION_SHEET_STATUS_TRANSLATIONS = {
  edition: 'W edycji',
  sent_to_parp: 'Wysłany do PARP',
  blocked: 'Zablokowany',
  unblocked: 'Odblokowany',
  sent_to_approve: 'Wysłany do zatwierdzenia',
  approved: 'Zatwierdzony',
};

export const ADDITIONAL_MODULE_LABELS = {
  kryteriaOgolem: 'Kryteria ogólne',
  modulowaFinansowa: 'Ocena modułowa-finansowa',
  ocenaFinansowa: 'Ocena finansowa',
  kryteriaRankingujace: 'Kryteria rankingujące',
};

export const ADDITIONAL_MODULE_IDS = {
  ocenaFinansowa: 'ocena-finansowa',
  kryteriaOgolem: 'kryteria-ogolem',
  modulowaFinansowa: 'modulowa-finansowa',
  kryteriaModulowe: 'kryteria-modulowe',
  kryteriaRankingujace: 'kryteria-rankingujace',
};

export const CRITERIA_BUSINESS_GROUP = {
  ogolnaFinansowa: 'Ogólna-finansowa',
  modulowaFinansowa: 'Modułowa-finansowa',
  ogolnaModulowa: 'Ogólna-modułowa',
  ogolna: 'Ogólna',
  modulowa: 'Modułowa',
};

export const EXPERT_PANEL_TYPES = {
  module: 'module',
  withExpert: 'with_expert',
};

export const MODULE_STATUSES = {
  accepted: 'accepted',
  refused: 'refused',
  readonly: 'readonly',
  waiting: 'waiting',
  waitingForFinancial: 'waitingForFinancial',
};
export const EXPERT_PANEL_ROLES = {
  expert: 'expert',
  leading_expert: 'leading_expert',
  financial: 'financial',
  financialLeadingExpert: 'financialLeadingExpert',
  readonly: 'readonly',
  employeeOrAdmin: 'employeeOrAdmin',
  financial_expert: 'financial_expert',
};

export const ACCESS_TO_FIELDS_BY_ROLE = {
  expert: {
    points: false,
    finalJustification: false,
    justification: true,
    questions: true,
  },
  leading_expert: {
    points: true,
    finalJustification: true,
    justification: true,
    questions: true,
  },
  financial: {
    points: false,
    finalJustification: false,
    justification: true,
    questions: true,
  },
  financialLeadingExpert: {
    points: true,
    finalJustification: true,
    justification: true,
    questions: true,
  },
  applicationLeadingExpert: {
    points: true,
    finalJustification: true,
    justification: true,
    questions: true,
  },
  readonly: {
    points: false,
    finalJustification: false,
    justification: false,
    questions: false,
  },
  employeeOrAdmin: {
    points: false,
    finalJustification: true,
    justification: false,
    questions: true,
  },
  financial_expert: {
    points: true,
    finalJustification: true,
    justification: true,
    questions: true,
  },
};

export const FILTER_KEYS_BY_DATE = [
  'finalDecisionDate',
  'acceptanceDate',
  'finalEvaluationDate',
  'refusalDate',
];
