import PropTypes from 'prop-types';
import { Header } from './Header';
import { Footer } from './index';
import { NeedToAcceptRedirect } from './Statement/NeedToAcceptRedirect';
import { useAuth } from '../_security';
import { useGlobalContext } from '../Context';
import { theme } from '../styles/theme';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';

const mainContainerStyles = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  minHeight: '65vh',
};

/**
 * Page content wrapper.
 *
 * @param {object} props - root props
 * @param {HTMLElement|HTMLElement[]} props.children - children elements
 * @param {Array} props.breadcrumbList - breadcrumb list
 * @returns {HTMLElement|HTMLElement[]} - wrapped elements
 */
export default function ContentWrapper({
  children, breadcrumbList,
}) {
  const { authenticated } = useAuth();
  const { root: { forceReload } } = useGlobalContext();

  return (
    <div key={forceReload}>
      {authenticated && (
        <NeedToAcceptRedirect />
      )}
      <Header />
      {authenticated && (
        <Breadcrumbs breadcrumbList={breadcrumbList} />
      )}
      <main style={mainContainerStyles}>
        {children}
      </main>
      <Footer />
    </div>
  );
}

ContentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  breadcrumbList: PropTypes.string,
};

ContentWrapper.defaultProps = {
  breadcrumbList: [],
};
