import {
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AsteriskedText } from '../AsteriskedText';

/**
 * Statement checkbox.
 *
 * @param {object} props root props
 * @param {object} props.statement statement object
 * @param {string} props.labelClassName label className
 * @param {Function} props.checkHandler checkbox click handler
 * @returns {StatementCheckbox}
 */
export function StatementCheckbox({
  statement, labelClassName, checkHandler,
}) {
  const {
    id,
    acceptance_required: acceptanceRequired,
    content,
  } = statement;

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={(
          <Checkbox
            onChange={(event) => checkHandler(id, event.target.checked)}
            color="primary"
            name={`statement-${id}`}
            id={`statement-${id}`}
            inputProps={{
              'aria-label': content,
              'aria-required': acceptanceRequired,
            }}
          />
        )}
        label={(
          <AsteriskedText
            textContent={content}
            printSymbol={acceptanceRequired}
          />
        )}
        sx={{
          mb: (t) => t.spacing(1),
          p: (t) => t.spacing(2),
          fontSize: '14px !important',
          width: '100%',
          ...labelClassName,
        }}
      />
    </FormControl>
  );
}

StatementCheckbox.propTypes = {
  statement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    acceptance_required: PropTypes.bool.isRequired,
  }).isRequired,
  labelClassName: PropTypes.string,
  checkHandler: PropTypes.func.isRequired,
};

StatementCheckbox.defaultProps = {
  labelClassName: '',
};
