/**
 * Appraisal commission statuses.
 *
 * @type {object}
 */
export const APPRAISAL_COMMISSION_STATUSES = {
  PARP: 'parp',
  PARP_EXPERTS: 'parpExperts',
  EXTERNAL_EXPERTS: 'externalExperts',
};

/**
 * Appraisal commission statuses translations.
 *
 * @type {object}
 */
export const APPRAISAL_COMMISSION_STATUSES_TRANSLATIONS = {
  [APPRAISAL_COMMISSION_STATUSES.PARP]: 'PARP',
  [APPRAISAL_COMMISSION_STATUSES.PARP_EXPERTS]: 'Eksperci PARP',
  [APPRAISAL_COMMISSION_STATUSES.EXTERNAL_EXPERTS]: 'Eksperci zewnętrzni',
};
