/**
 * Panel types.
 *
 * @type {object}
 */
export const PANEL_TYPES = {
  module: 'module',
  moduleFirstStage: 'module_first_stage',
  withExpert: 'with_expert',
  withoutExpert: 'without_expert',
};

/**
 * Panel types translations.
 *
 * @type {object}
 */
export const PANEL_TYPES_TRANSLATIONS = {
  [PANEL_TYPES.module]: 'Modułowy',
  [PANEL_TYPES.moduleFirstStage]: 'Modułowy 1 etap',
  [PANEL_TYPES.withExpert]: 'Z ekspertem wiodącym',
  [PANEL_TYPES.withoutExpert]: 'Bez eksperta wiodącego',
};
