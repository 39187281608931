import PropTypes from 'prop-types';
import {
  Text,
  View,
} from '@react-pdf/renderer';
import PageWrapper from '../../../PrintToPdf/Components/PageWrapper/PageWrapper';
import { PANEL_TYPES } from '../../../../_constants';
import PrintoutSupplementCallWithModules from './PrintoutSupplementCallWithModules';
import PrintoutSupplementCallWithoutModules from './PrintoutSupplementCallWithoutModules';
import { printStyle } from '../../../PrintToPdf/print.style';
import { formatDateString } from '../../../../_helpers';

/**
 * PrintoutSupplementCallTemplate element.
 *
 * @param {object} props - root props
 * @param {object} props.supplementCallData - supplement call data
 * @param {string} props.applicationNumber - application number
 * @param {string} props.sentToApplicantDate - sent to applicant date
 * @returns {PrintoutSupplementCallTemplate}
 */
function PrintoutSupplementCallTemplate({
  supplementCallData, applicationNumber, sentToApplicantDate,
}) {
  const {
    panelType, evaluationSheetItems, items,
  } = supplementCallData;

  const filteredSupplementCallData = items.filter(({ evaluationItems }) => evaluationItems.length > 0);

  return (
    <PageWrapper hideWatermark>
      <View>
        <Text style={{
          ...printStyle.title,
          fontSize: 16,
          textAlign: 'left',
        }}
        >
          {`Numer wniosku o dofinansowanie ${applicationNumber}`}
        </Text>
      </View>
      <View>
        <Text style={{
          ...printStyle.title,
          fontSize: 16,
          textAlign: 'left',
        }}
        >
          {`Wezwanie z dnia ${formatDateString(sentToApplicantDate)}`}
        </Text>
      </View>
      {panelType === PANEL_TYPES.module && (
        <PrintoutSupplementCallWithModules modules={filteredSupplementCallData} />
      )}
      {panelType === PANEL_TYPES.withExpert && (
        <PrintoutSupplementCallWithoutModules evaluationSheetItems={evaluationSheetItems} />
      )}
    </PageWrapper>
  );
}

export default PrintoutSupplementCallTemplate;

PrintoutSupplementCallTemplate.propTypes = {
  supplementCallData: PropTypes.instanceOf(Object).isRequired,
  sentToApplicantDate: PropTypes.string.isRequired,
  applicationNumber: PropTypes.string.isRequired,
};
