import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { styled } from '@mui/system';
import NewsDialog from './News.dialog';
import { newsStyleClasses } from './News.list.style';
import { formatDateString } from '../../../_helpers';
import { DATE } from '../../../_constants';

/**
 * PinnedNews element.
 *
 * @param {object} props - root props
 * @param {object} props.pinnedNewsData - pinned news data
 * @returns {PinnedNews}
 */
function PinnedNews({ pinnedNewsData }) {
  const classes = newsStyleClasses;

  const NewsLead = styled('div')({
    fontSize: '1.15em',
    lineHeight: '1.2em',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  });

  return (
    <>
      <NewsLead>
        <NewsDialog newsData={pinnedNewsData} sx={classes.itemTitle}>
          {formatDateString(pinnedNewsData.pubDate, DATE.defaultFormatWithoutTime)}
        </NewsDialog>
        <NewsDialog newsData={pinnedNewsData} sx={classes.itemContent}>
          {pinnedNewsData.title}
        </NewsDialog>
      </NewsLead>
      <Divider sx={classes.boldDivider} />
    </>
  );
}

export default PinnedNews;

PinnedNews.propTypes = {
  pinnedNewsData: PropTypes.instanceOf(Object),
};

PinnedNews.defaultProps = {
  pinnedNewsData: {},
};
