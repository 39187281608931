import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../../Context';

/**
 * Substantive resources incubation program pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.fieldName - field name
 * @param {number} props.index - current parent index
 * @param {number} props.previousRowIndex - previous row index
 * @returns {SubstantiveResourcesIncubationProgramPdf}
 */
export function SubstantiveResourcesIncubationProgramPdf({
  fieldName, index, previousRowIndex,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      fieldsConfig,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const substantiveResourceConfig = fieldsConfig?.[fieldName]?.fields?.substantive_resources_for_the_incubation_program;
  const substantiveResourcesData = initialFormData[fieldName][index]
    ?.substantive_resources_for_the_incubation_program || [];
  const previousSubstantiveResourcesData = previousInitialFormData?.[fieldName]?.[previousRowIndex]
    ?.substantive_resources_for_the_incubation_program || [];

  return (
    <CollectionWrapper
      name={`${fieldName}.${index}.substantive_resources_for_the_incubation_program`}
      externalFieldConfig={substantiveResourceConfig}
      externalData={substantiveResourcesData}
      previousExternalData={previousSubstantiveResourcesData}
    >
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, childIndex) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={childIndex} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${childIndex}.first_and_last_name`}
                value={item.first_and_last_name}
                oldValue={previousRowData?.first_and_last_name}
              />
              <RowField
                name={`${collectionName}.${childIndex}.education`}
                value={item.education}
                oldValue={previousRowData?.education}
              />
              <RowField
                name={`${collectionName}.${childIndex}.scientific_title`}
                value={item.scientific_title}
                oldValue={previousRowData?.scientific_title}
              />
              <RowField
                name={`${collectionName}.${childIndex}.role`}
                value={item.role}
                oldValue={previousRowData?.role}
              />
              <RowField
                name={`${collectionName}.${childIndex}.work_experience`}
                value={item.work_experience}
                oldValue={previousRowData?.work_experience}
              />
              <RowField
                name={`${collectionName}.${childIndex}.responsibilities`}
                value={item.responsibilities}
                oldValue={previousRowData?.responsibilities}
              />
              <RowField
                name={`${collectionName}.${childIndex}.experience_in_working_with_startups`}
                value={item.experience_in_working_with_startups}
                oldValue={previousRowData?.experience_in_working_with_startups}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

SubstantiveResourcesIncubationProgramPdf.propTypes = {
  fieldName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  previousRowIndex: PropTypes.number,
};

SubstantiveResourcesIncubationProgramPdf.defaultProps = {
  previousRowIndex: null,
};
