/**
 * Element helper functions.
 *
 * @type {{hasSingleModule: (function(string): boolean), hasDictionaries: (function(string): boolean)}}
 */
export const elementHelper = {
  hasSingleModule: (name) => [
    'Hrf',
    'Indicators',
    'Risks',
    'ModuleImplementationPotential',
    'TechnicalResourcesAndIntangibleAssets',
  ].includes(name),
  hasDictionaries: (name) => ['Hrf', 'Indicators'].includes(name),
};
