import {
  Box,
  Button,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { StatementCheckbox } from '../../Features/Statement/Statement.checkbox';
import { AsteriskedText } from '../../Features/AsteriskedText';
import { request } from '../../_services';
import {
  API_ROUTE,
  PROFILES,
} from '../../_constants';

const classes = {
  expertStatement: {
    backgroundColor: '#F8EEF0',
  },
  applicantStatement: {
    backgroundColor: '#E6F3F7',
  },
  operatorStatement: {
    backgroundColor: '#D3F5F3',
  },
  managementInstitution: {
    backgroundColor: '#D3F5D5',
  },
};

/**
 * Dialog for statement acceptance.
 *
 * @param {object} props root props
 * @param {string} props.profile selected profile
 * @param {Function} props.submitHandler dialog submit handler
 * @returns {UserAcceptanceDialog}
 */
export function UserAcceptanceDialog({
  profile, submitHandler,
}) {
  const [statements, setStatements] = useState({
    [PROFILES.applicant]: [],
    [PROFILES.expert]: [],
    [PROFILES.operator]: [],
    [PROFILES.managementInstitution]: [],
  });
  const [selectedStatements, setSelectedStatements] = useState({});
  const [allRequiredChecked, setAllRequiredChecked] = useState(false);

  useEffect(() => {
    const loadApiData = async () => {
      const dateTime = new Date().toISOString();
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.statements}?startsAt[before]=${dateTime}&outdatedAt[after]=${dateTime}`
      );
      if (!statusSuccess) {
        return;
      }

      const filtredStatementByProfile = (data, profileLoggedUser) => data
        .filter((statement) => statement.related_profiles.includes(profileLoggedUser));

      setStatements((s) => ({
        ...s,
        [PROFILES.applicant]: filtredStatementByProfile(payload, PROFILES.applicant),
        [PROFILES.expert]: filtredStatementByProfile(payload, PROFILES.expert),
        [PROFILES.operator]: filtredStatementByProfile(payload, PROFILES.operator),
        [PROFILES.managementInstitution]: filtredStatementByProfile(payload, PROFILES.managementInstitution),
      }));
    };
    loadApiData();
  }, []);

  useEffect(() => {
    statements[profile].forEach(({
      id, acceptance_required: acceptanceRequired,
    }) => {
      setSelectedStatements((s) => ({
        ...s,
        [id]: {
          value: false,
          acceptanceRequired,
        },
      }));
    });
  }, [profile, statements]);

  useEffect(() => {
    setAllRequiredChecked(true);
    Object.entries(selectedStatements).forEach(({ 1: statementValue }) => {
      const {
        value, acceptanceRequired,
      } = statementValue;

      if (acceptanceRequired && !value) {
        setAllRequiredChecked(false);
      }
    });
  }, [selectedStatements]);

  /**
   * Handle statements check.
   *
   * @param {string} statementId - statement id
   * @param {boolean} checked - statement checked state
   */
  const handleStatementCheck = (statementId, checked) => {
    setSelectedStatements((s) => ({
      ...s,
      [statementId]: {
        ...s[statementId],
        value: checked,
      },
    }));
  };

  /**
   * Properly map accepted statements and forward to submitHandler
   */
  const handleSubmitInternal = () => {
    const acceptedStatements = Object.entries(selectedStatements).filter(({ 1: formData }) => formData.value === true);

    submitHandler(
      acceptedStatements.map((entry) => entry[0])
    );
  };

  return (
    <>
      {statements[profile].map((statement) => (
        <StatementCheckbox
          statement={statement}
          labelClassName={classes[`${profile}Statement`]}
          checkHandler={handleStatementCheck}
          key={statement.id}
        />
      ))}
      <div>
        <AsteriskedText textContent=" - oświadczenie wymagane" />
      </div>
      <Box mt={5}>
        <Button
          disabled={!allRequiredChecked || statements[profile].length === 0}
          variant="contained"
          color="secondary"
          aria-label="Zaakceptuj zaznaczone oświadczenia"
          id="d13dAH8ULXWOaPV"
          onClick={handleSubmitInternal}
        >
          Akceptuj
        </Button>
      </Box>
    </>
  );
}

UserAcceptanceDialog.propTypes = {
  profile: PropTypes.oneOf([
    PROFILES.expert,
    PROFILES.applicant,
    PROFILES.operator,
    PROFILES.managementInstitution,
  ]).isRequired,
  submitHandler: PropTypes.func.isRequired,
};
