import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { getOwnIndicators } from '../../../../../_helpers';
import { useApplicationToPdfContext } from '../../../../../Context';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Ue Horizontal Policies Project Compliance Other Pdf element.
 *
 * @param {object} props - root props
 * @param {number} props.collectionName - collection name
 * @param {object} props.item - collection data item
 * @param {number} props.index - index of collection data item
 * @param {object} props.previousRowData - previous row data
 * @param {Array} props.collectionData - collection data item
 * @returns {UeHorizontalPoliciesProjectComplianceOtherPdf}
 */
function UeHorizontalPoliciesProjectComplianceOtherPdf({
  collectionName, item, index, previousRowData, collectionData,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      elementsConfig,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const combinedIndicatorsDictionary = [
    ...getOwnIndicators(
      elementsConfig,
      initialFormData,
      item.indicators,
    ),
    ...getOwnIndicators(
      elementsConfig,
      previousInitialFormData,
      previousRowData?.indicators,
    ),
  ];

  return (
    <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
      <View style={printStyle.table}>
        <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
          <RowField
            name={`${collectionName}.${index}.impact_description`}
            value={item.impact_description}
            oldValue={previousRowData?.impact_description}
            isSingleRow
          />
          <RowField
            name={`${collectionName}.${index}.indicators`}
            value={item.indicators}
            oldValue={previousRowData?.indicators}
            externalDictionary={combinedIndicatorsDictionary.map((indicator) => ({
              '@id': indicator.id,
              name: indicator.name,
            }))}
          />
        </FieldSorter>
      </View>
    </SingleCollectionWrapper>
  );
}

export default UeHorizontalPoliciesProjectComplianceOtherPdf;

UeHorizontalPoliciesProjectComplianceOtherPdf.propTypes = {
  collectionName: PropTypes.number.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  previousRowData: PropTypes.instanceOf(Object),
  collectionData: PropTypes.arrayOf(Object).isRequired,
};

UeHorizontalPoliciesProjectComplianceOtherPdf.defaultProps = {
  previousRowData: {},
};
