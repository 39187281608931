/**
 * Criteria types.
 *
 * @type {object}
 */
export const CRITERIA_TYPES = {
  basicCriteria: 'Kryteria podstawowe',
  conclusiveCriteria: 'Kryteria rozstrzygające',
  recommendedAmounts: 'Rekomendowane kwoty',
};
