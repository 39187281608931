import { pl as plLocale } from 'date-fns/locale/pl';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { SnackbarProvider } from 'notistack';
import { useRef } from 'react';
import { plPL as plPickers } from '@mui/x-date-pickers/locales';
import { theme } from './styles/theme';
import { FastContact } from './Dialogs/FastContact/FastContact';
import { useAuth } from './_security';
import { AuthBrowserRouter } from './_security/AuthBrowserRouter';
import './App.css';
import { ReloadListenerProvider } from './Context';

/**
 * Create <IconButton> component
 *
 * @param {Function} handleDismiss - handler for snackbar dismiss
 * @param {string} key - snackbar key
 * @returns {IconButton}
 */
function createIconButton(handleDismiss, key) {
  return (
    <IconButton onClick={handleDismiss(key)}>
      <CloseIcon style={{ color: '#ffffff' }} />
    </IconButton>
  );
}

/**
 * App entrypoint.
 *
 * @returns {App} - app root
 */
export function App() {
  const { authenticated } = useAuth();

  const notistackRef = useRef();
  const handleDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          classes={{
            variantSuccess: theme.palette.success.main,
            variantError: theme.palette.error.main,
          }}
          ref={notistackRef}
          action={(key) => (
            createIconButton(handleDismiss, key)
          )}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={plLocale}
            localeText={{
              ...plPickers.components.MuiLocalizationProvider.defaultProps.localeText,
              fieldMonthPlaceholder: (params) => (
                params.contentType === 'digit' ? 'mm' : params.format
              ),
              fieldYearPlaceholder: () => 'rrrr',
              fieldDayPlaceholder: () => 'dd',
              fieldHoursPlaceholder: () => 'gg',
              fieldMinutesPlaceholder: () => 'mm',
            }}
          >
            <ReloadListenerProvider>
              <CssBaseline />
              <div className="app">
                <AuthBrowserRouter>
                  {authenticated && (
                    <FastContact />
                  )}
                </AuthBrowserRouter>
              </div>
            </ReloadListenerProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
