import PropTypes from 'prop-types';
import {
  StyleSheet,
  View,
} from '@react-pdf/renderer';

const tableRowStyle = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
});

/**
 * Pdf table row
 *
 * @param {object} props - root props
 * @param {Node} props.children - children component
 * @returns {PrintoutTableRow}
 */
function PrintoutTableRow({ children }) {
  return (
    <View style={tableRowStyle.row}>
      {children}
    </View>
  );
}

export default PrintoutTableRow;

PrintoutTableRow.propTypes = {
  children: PropTypes.node.isRequired,
};
