import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import { CHARS_LIMIT } from '../_constants';

/** Count chars at field to reach given limit
 *
 * @param {number} props - root props
 * @param {number} props.valueLength - current number of used signs
 * @param {number} [props.maxLength] - given limit of chars at input
 * @param {boolean} [props.onOutline] - set counter on the input's outline (styled variant)
 * @returns {CharsCounter}
 */
export default function CharsCounter({
  valueLength,
  maxLength,
  onOutline,
}) {
  if (onOutline) {
    return (
      <InputAdornment
        position="end"
        sx={(theme) => ({
          '& > p': {
            position: 'relative',
            zIndex: '2',
            backgroundColor: '#fff',
            color: 'rgba(0, 0, 0, 0.54)',
            margin: '0',
            padding: theme.spacing(0.5),
            fontSize: '0.75rem',
            fontWeight: '400',
            lineHeight: '1.66',
            letterSpacing: '0.03333em',
          },
          '&.MuiInputAdornment-positionEnd': {
            position: 'absolute',
            top: '-0.75rem',
            right: '1rem',
          },
          '&.MuiInputAdornment-root': {
            display: 'block',
          },
        })}
      >
        {`${valueLength}/${maxLength}`}
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="end">
      {`${valueLength}/${maxLength}`}
    </InputAdornment>
  );
}

CharsCounter.propTypes = {
  valueLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  onOutline: PropTypes.bool,
};

CharsCounter.defaultProps = {
  maxLength: CHARS_LIMIT.LONG,
  onOutline: false,
};
