import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import ClusterMembersNumberPdf from './PkwEligibility/ClusterMembersNumber.pdf';

/**
 * PKW Eligibility pdf element renderer.
 *
 * @returns {PkwEligibilityPdf}
 */
function PkwEligibilityPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <>
      <View style={printStyle.table}>
        <RowField name="pkw_eligibility_does_it_apply" />
      </View>
      {initialFormData?.pkw_eligibility_does_it_apply === BOOLEAN_VALUES.TRUE && (
        <>
          <View style={printStyle.table}>
            {/* 1.Koordynator posiada osobowość prawną */}
            <RowField name="pkw_eligibility_coordinator_has_legal_personality" />
            {/* 2.Uzasadnienie */}
            <RowField name="pkw_eligibility_coordinator_has_legal_personality_justification" isSingleRow />
            {/* 3.Data rozpoczęcia działalności klastra */}
            <RowField name="pkw_eligibility_cluster_start_date" />
            {/* 4.Uzasadnienie */}
            <RowField name="pkw_eligibility_cluster_start_date_justification" isSingleRow />
            {/* 5.Klaster posiada aktualny dokument strategii/planu rozwoju */}
            <RowField name="pkw_eligibility_cluster_has_current_strategy_document" />
            {/* 6.Uzasadnienie */}
            <RowField name="pkw_eligibility_cluster_has_current_strategy_document_justification" isSingleRow />
            {/* 7.Ponadregionalny charakter działalności klastra (%) */}
            <RowField name="pkw_eligibility_cluster_activities_supra_regional_nature" />
          </View>
          {/* 8.Liczba członków klastra w podziale na województwa */}
          <ClusterMembersNumberPdf />
          <View style={printStyle.table}>
            {/* 9.Struktura klastra: min. 35 członków, z czego przynajmniej 2/3 liczby członków
          powinny stanowić przedsiębiorstwa – dane obliczone zgodnie z regułą dotyczącą powiązań i partnerstw */}
            <RowField name="pkw_eligibility_cluster_structure" />
            {/* 10.Uzasadnienie */}
            <RowField name="pkw_eligibility_cluster_structure_justification" isSingleRow />
            {/* 11.Udział członków będących w klastrze powyżej 1 roku (%) */}
            <RowField name="pkw_eligibility_share_members_who_have_been_in_cluster_for_more_than_1_year" />
            {/* 12.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_share_members_who_have_been_in_cluster_for_more_than_1_year_justification"
              isSingleRow
            />
            {/* 13.Środki pochodzące ze składek członkowskich (udział w przychodach koordynatora klastra)
          przeznaczone na działalność klastra (%) */}
            <RowField name="pkw_eligibility_funds_from_membership_fees" />
            {/* 14.Uzasadnienie */}
            <RowField name="pkw_eligibility_funds_from_membership_fees_justification" isSingleRow />
            {/* 15.Etykieta: Zatrudnienie w przedsiębiorstwach klastra */}
            <RowField name="pkw_eligibility_employment_in_enterprises" isSingleRow />
            {/* 16.Stan na koniec ostatniego zakończonego roku obrotowego zatrudnionych zgodnie z przepisami
           kodeksu pracy (w przeliczeniu na pełne etaty), na podstawie umów o charakterze cywilnoprawnym,
           kontraktów menadżerskich, czy na zasadach B2B */}
            <RowField name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code_and_others" />
            {/* 17.Stan na koniec ostatniego zakończonego roku obrotowego zatrudnionych zgodnie z przepisami
           kodeksu pracy (w przeliczeniu na pełne etaty) */}
            <RowField name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code" />
            {/* 18.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_employed_in_accordance_with_provisions_labor_code_justification"
              isSingleRow
            />
            {/* 19.Koordynator klastra ma na stronie ofertę usługową dla członków klastra */}
            <RowField name="pkw_eligibility_cluster_coordinator_has_service_offering_on_website" />
            {/* 20.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_cluster_coordinator_has_service_offering_on_website_justification"
              isSingleRow
            />
            {/* 21.Koordynator klastra udostępnia członkom infrastrukturę (badawczą, demonstracyjną lub
              informatyczną) */}
            <RowField name="pkw_eligibility_cluster_coordinator_provides_members_with_infrastructure" />
            {/* 22.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_cluster_coordinator_provides_members_with_infrastructure_justification"
              isSingleRow
            />
            {/* 23.Liczba wspólnych projektów (z ustalonym harmonogramem działań i budżetem) realizowanych w ostatnim
          roku przed złożeniem wniosku, w których brał udział koordynator klastra z min. 2 członkami klastra lub min. 3
          członkami klastra, z czego co najmniej 1 był przedsiębiorcą */}
            <RowField name="pkw_eligibility_joint_projects_number" />
            {/* 24.Uzasadnienie */}
            <RowField name="pkw_eligibility_joint_projects_number_justification" isSingleRow />
            {/* 25.Przedsiębiorstwa wchodzące w skład klastra, które wprowadziły innowację produktową lub procesową
          powiązaną z procesem produkcji, w ostatnim zakończonym roku obrotowym (%) */}
            <RowField name="pkw_eligibility_enterprises_in_cluster_that_have_introduced_product_innovation" />
            {/* 26.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_enterprises_in_cluster_that_have_introduced_product_innovation_justification"
              isSingleRow
            />
            {/* 27.Przedsiębiorstwa wchodzące w skład klastra prowadzące działalność B+R w ostatnim zakończonym roku
          obrotowym (%) */}
            <RowField name="pkw_eligibility_enterprises_in_cluster_engaged_in_rd_activities" />
            {/* 28.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_enterprises_in_cluster_engaged_in_rd_activities_justification"
              isSingleRow
            />
            {/* 29.Wspólny udział koordynatora klastra razem z członkami w wydarzeniach branżowych/ targach (również
            międzynarodowych) w ostatnim roku przed złożeniem wniosku (liczba wydarzeń – szt.) */}
            <RowField name="pkw_eligibility_joint_participation_in_industry_events" />
            {/* 30.Uzasadnienie */}
            <RowField name="pkw_eligibility_joint_participation_in_industry_events_justification" isSingleRow />
            {/* 31.Przedsiębiorstwa wchodzące w skład klastra prowadzące działalność eksportową w ostatnim zakończonym
          roku obrotowym (%) */}
            <RowField name="pkw_eligibility_enterprises_in_cluster_engaged_in_export_activities" />
            {/* 32.Uzasadnienie */}
            <RowField
              name="pkw_eligibility_enterprises_in_cluster_engaged_in_export_activities_justification"
              isSingleRow
            />
          </View>
        </>
      )}
    </>
  );
}

export default PkwEligibilityPdf;
