import { useSnackbar } from 'notistack';

/**
 * useCustomSnackbar hook.
 *
 * @see https://notistack.com/
 * @returns {useCustomSnackbar}
 */
export function useCustomSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  const successNotification = (
    message = 'Dane zostały wprowadzone',
    horizontal = 'right',
    vertical = 'bottom',
    autoHideDuration = 5000
  ) => enqueueSnackbar(
    message,
    {
      variant: 'success',
      autoHideDuration,
      anchorOrigin: {
        horizontal,
        vertical,
      },
    }
  );

  const errorNotification = (
    message = 'Wystąpił błąd podczas zapisywania danych',
    horizontal = 'right',
    vertical = 'bottom',
    autoHideDuration = 5000
  ) => enqueueSnackbar(
    message,
    {
      variant: 'error',
      autoHideDuration,
      anchorOrigin: {
        horizontal,
        vertical,
      },
    }
  );

  return {
    successNotification,
    errorNotification,
  };
}
