/**
 * Result statuses.
 *
 * @type {object}
 */
export const RESULT_STATUSES = {
  positive: 'positive',
  negative: 'negative',
};

/**
 * Result statuses translations.
 *
 * @type {object}
 */
export const RESULT_STATUSES_TRANSLATIONS = {
  [RESULT_STATUSES.positive]: 'Pozytywny',
  [RESULT_STATUSES.negative]: 'Negatywny',
};
