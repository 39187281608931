import {
  APPLICATION_STATUSES,
  APPLICATION_STATUSES_TRANSLATIONS,
} from '../_constants';
/**
 * Application statuses values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const applicationStatusesOptions = [
  {
    id: APPLICATION_STATUSES.edition,
    label: APPLICATION_STATUSES_TRANSLATIONS.edition,
  },
  {
    id: APPLICATION_STATUSES.submitted,
    label: APPLICATION_STATUSES_TRANSLATIONS.submitted,
  },
  {
    id: APPLICATION_STATUSES.withdrawn,
    label: APPLICATION_STATUSES_TRANSLATIONS.withdrawn,
  },
  {
    id: APPLICATION_STATUSES.evaluation,
    label: APPLICATION_STATUSES_TRANSLATIONS.evaluation,
  },
  {
    id: APPLICATION_STATUSES.supplementation,
    label: APPLICATION_STATUSES_TRANSLATIONS.supplementation,
  },
  {
    id: APPLICATION_STATUSES.autocorrect,
    label: APPLICATION_STATUSES_TRANSLATIONS.autocorrect,
  },
  {
    id: APPLICATION_STATUSES.approved,
    label: APPLICATION_STATUSES_TRANSLATIONS.approved,
  },
  {
    id: APPLICATION_STATUSES.rejected,
    label: APPLICATION_STATUSES_TRANSLATIONS.rejected,
  },
  {
    id: APPLICATION_STATUSES.invalidated,
    label: APPLICATION_STATUSES_TRANSLATIONS.invalidated,
  },
];
