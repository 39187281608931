import {
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ReactComponent as LSILogo } from '../../images/logo_LSI.svg';
import { homeCardsStyleClasses } from '../../styles/homeCards';
import { useAuth } from '../../_security';

/**
 * Start view for employee
 *
 * @returns {HomeCards}
 */
function HomeCards() {
  const classes = homeCardsStyleClasses;
  const {
    firstName, lastName,
  } = useAuth();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} xl={8}>
        <Divider sx={classes.overHeading} />
        <Typography variant="pageHeading" sx={classes.sectionHeading}>Profil pracownika</Typography>
        <Stack
          id="EW5pj9jVx48wPqR"
          sx={classes.cards}
          direction="row"
          justifyContent="space-evenly"
        >
          <Card
            sx={{
              ...classes.card,
              maxWidth: '500px',
              width: '100%',
            }}
          >
            <CardContent>
              <div style={classes.cardNameBlock}>
                <span style={classes.cardName}>
                  {`Witaj ${firstName} ${lastName}`}
                </span>
              </div>
              <LSILogo style={classes.cardIcon} />
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default HomeCards;
