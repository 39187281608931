import { useState } from 'react';
import {
  Accordion,
  Typography,
} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { theme } from '../../styles/theme';

const classes = {
  dropdownItem: {
    backgroundColor: '#ffffff',
    listStyle: 'none',
    textAlign: 'left',
    width: '100%',
    borderWidth: 0,
    '&:hover:not(.MuiExpanded)': {
      backgroundColor: '#eaeaea',
    },
  },
  accordionSubmenu: {
    position: 'static',
    fontSize: '1em',
    padding: 0,
    marginTop: '0 !important',
  },
  accordionMenuTitle: {
    padding: 0,
    width: '100%',
    border: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: theme.spacing(2),
    },
  },
};

/**
 * Dropdown item for navigation
 *
 * @param {object} props - root props
 * @param {string} props.id - item id
 * @param {string} props.title - dropdown item title
 * @param {Node} props.children - provider children elements
 * @returns {SubAccordionItem}
 */
function SubAccordionItem({
  id, title, children,
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      component="button"
      key={`accordion-${id}`}
      sx={{
        ...classes.accordionSubmenu,
        ...classes.dropdownItem,
      }}
      elevation={0}
      expanded={expanded}
      aria-expanded={expanded}
      onClick={() => setExpanded(!expanded)}
      TransitionProps={{ timeout: 1 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={id}
        sx={classes.accordionMenuTitle}
        role=""
      >
        <Typography
          display="inline-block"
          width="100%"
          px={2}
          pb={1}
          pt={0}
          fontFamily='"Montserrat-Light", sans-serif'
          lineHeight="1.5em"
          fontSize="0.875em"
          textTransform="none"
          color={theme.palette.text.primary}
          sx={{ textDecoration: 'none' }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
}
SubAccordionItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
export default SubAccordionItem;
