import PropTypes from 'prop-types';
import {
  Box,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { FieldsList } from './FieldsList';
import { FieldEditForm } from './FieldEdit.form';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { useCustomSnackbar } from '../../_hooks';
import { useApplicationTemplateStyles } from '../../styles/applicationTemplate.styles';
import { theme } from '../../styles/theme';

/**
 * CollectionField edit form component.
 *
 * @param {object} props - root props
 * @param {object} props.collectionFieldData - current field data
 * @param {Function} props.reloadTemplateElement - reload template element
 * @param {Function} props.backHandler - back button handler
 * @param {string} props.templateElementId - application template ID
 * @param {Function} props.loadTemplateElement - load template element
 * @returns {FieldEditForm}
 */
export function CollectionField({
  collectionFieldData,
  reloadTemplateElement,
  backHandler,
  templateElementId,
  loadTemplateElement,
}) {
  const [selectedField, setSelectedField] = useState(null);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const { successNotification } = useCustomSnackbar();

  if (fieldsOrder === []) {
    setFieldsOrder(
      collectionFieldData.fields.map((fieldData) => fieldData.uuid)
    );
  }

  /**
   * Build API PUT URL.
   *
   * @param {string} uuid - table field UUID
   * @returns {string} - request url
   */
  const makeUpdateUrl = (uuid) => API_ROUTE.editElementField
    .replace(':templateElementId', templateElementId)
    .replace(':fieldUuid', uuid);

  const submitHandler = async (payloadData, uuid) => {
    const payload = {
      ...payloadData,
      fieldsOrder,
    };
    const {
      statusSuccess,
    } = await request.put(
      makeUpdateUrl(uuid),
      payload
    );

    if (statusSuccess) {
      backHandler();
      successNotification();
    }
  };

  return (
    <>
      {!selectedField && (
        <>
          <FieldEditForm
            fieldData={collectionFieldData}
            backHandler={backHandler}
            submitHandler={submitHandler}
            templateElementId={templateElementId}
          />
          <Box my={4}>
            <Divider />
          </Box>
          <Box
            sx={{
              ...useApplicationTemplateStyles.collectionFieldContainer,
              margin: '0 !important',
              padding: `${theme.spacing(4, 2)} !important`,
            }}
          >
            <FieldsList
              fieldsData={collectionFieldData.fields}
              selectedFieldHandler={setSelectedField}
              reloadTemplateElement={reloadTemplateElement}
              fieldsOrderSetter={setFieldsOrder}
              templateElementId={templateElementId}
              listTitle={`Lista zastosowanych pól dla zestawu: ${collectionFieldData.title}`}
            />
          </Box>
        </>
      )}

      {selectedField && (
        <>
          {selectedField.type === 'collection' ? (
            <CollectionField
              collectionFieldData={selectedField}
              reloadTemplateElement={loadTemplateElement}
              backHandler={() => {
                loadTemplateElement(true);
                setSelectedField(null);
              }}
              templateElementId={templateElementId}
              loadTemplateElement={loadTemplateElement}
            />
          ) : (
            <FieldEditForm
              fieldData={selectedField}
              backHandler={() => {
                reloadTemplateElement();
                setSelectedField(null);
              }}
              templateElementId={templateElementId}
            />
          )}
        </>
      )}
    </>
  );
}

CollectionField.propTypes = {
  collectionFieldData: PropTypes.instanceOf(Object).isRequired,
  reloadTemplateElement: PropTypes.func.isRequired,
  backHandler: PropTypes.func.isRequired,
  templateElementId: PropTypes.string.isRequired,
  loadTemplateElement: PropTypes.func.isRequired,
};
