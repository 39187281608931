import {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useElementContext } from '../../../../Context';
import { getFieldConfig } from '../../../../_helpers';

/**
 * FieldConfig component used in application view.
 * Component returns only config for field
 *
 * @param {object} props - root props
 * @param {string} props.name - field name
 * @param {Function} props.children - function will be invoked as children
 * @returns {FieldConfig}
 */
function FieldConfig({
  name, children,
}) {
  const {
    fieldsConfig,
  } = useElementContext();

  /**
   * Field config based on field name.
   * Note that field can be nested. Dot separated notation is used to retrieve nested field.
   * Field also can be indexed (for collection fields) like 'field.0.name` is
   * equivalent of `field.name`.
   */
  const fieldConfig = useMemo(() => getFieldConfig(fieldsConfig, name), [name]);

  const callbackPayload = useMemo(() => ({
    ...fieldConfig,
    name,
  }), [fieldConfig, name]);

  const ConfigWrapper = useCallback((callback) => (
    callback({
      name,
      ...callbackPayload,
    })
  ));

  if (!fieldConfig) {
    return '';
  }

  return (
    <>
      {ConfigWrapper(children)}
    </>
  );
}

export default FieldConfig;

FieldConfig.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
