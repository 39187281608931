import { StatusCodes } from 'http-status-codes';
import { API_ROUTE } from '../_constants';
// eslint-disable-next-line import/no-cycle
import {
  authHeader,
  clearUserSession,
} from '../_helpers';
import { sessionService } from '../_security';

const apiVersionHeader = process.env.REACT_APP_V1_API_HEADER;
const apiUiPathHeaderEnabled = process.env.REACT_APP_UI_PATH_API_HEADER_ENABLED || 0;

/**
 * Handle unauthorized.
 * Removes user session from cookie and redirect to login page.
 *
 * @todo should be moved and be called by context dispatch?
 */
const handleUnauthorized = () => {
  clearUserSession();
  if (window.location.pathname !== '/login') {
    window.history.replaceState({ page: 1 }, 'Zaloguj się ponownie', '/login');
    window.history.go(0);
  }
};

/**
 * Handle API response.
 *
 * @todo refactorize whole dirty service
 * @param {object} response - response data
 * @returns {object} - parsed response data
 */
const handleResponse = async (response) => {
  const {
    ok: statusSuccess,
    status: statusCode,
    url,
  } = response;

  const isRouteUnauthorized = statusCode === StatusCodes.UNAUTHORIZED
    && (url !== API_ROUTE.token && url !== API_ROUTE.refreshToken);

  if (isRouteUnauthorized) {
    handleUnauthorized();
  }

  let jsonData = [];
  try {
    if (statusCode !== StatusCodes.NO_CONTENT) {
      jsonData = await response.json();
    }
  } catch (error) {
    jsonData = [];
  }

  if (statusSuccess && statusCode !== StatusCodes.NO_CONTENT) {
    const {
      'hydra:member': hydraMember, 'hydra:totalItems': hydraTotalItems,
    } = jsonData;

    return {
      statusCode,
      statusSuccess,
      payload: hydraMember ?? jsonData,
      itemsCount: hydraTotalItems ?? undefined,
    };
  }

  if ('@type' in jsonData) {
    const {
      '@type': hydraType,
      'hydra:description': hydraDescription,
      'hydra:title': hydraTitle,
      violations,
    } = jsonData;

    const responseData = {
      statusCode,
      statusSuccess,
      message: hydraDescription,
      title: hydraTitle,
      payload: [],
    };

    if (hydraType === 'hydra:Error') {
      return responseData;
    }

    if (hydraType === 'ConstraintViolationList') {
      return {
        ...responseData,
        violations,
      };
    }
  }

  if ('violations' in jsonData) {
    const { violations } = jsonData;

    return {
      statusSuccess,
      statusCode,
      payload: [],
      violations,
    };
  }

  return {
    statusSuccess,
    statusCode,
    payload: jsonData,
  };
};

export const request = {
  /**
   * Make request with GET method.
   *
   * @param {string} url - URL
   * @param {string} contentType - request content type
   * @param {Function} abortControllerSignal - abort controller signal
   * @returns {object} - response data
   */
  get: async (url, contentType = apiVersionHeader, abortControllerSignal = null) => makeRequest(
    url,
    'GET',
    null,
    true,
    contentType,
    true,
    null,
    false,
    abortControllerSignal,
  ),

  /**
   * Make request with POST method.
   *
   * @param {string} url - URL
   * @param {object} payload - request payload
   * @param {boolean} secured - append authorization header
   * @param {string} contentType - request content type
   * @param {boolean} stringifyJson - response will be stringified to json
   * @param {string|null} acceptType - request accept type
   * @param {boolean} isFileUpload - is file upload
   * @returns {object} - response data
   */
  post: async (
    url,
    payload,
    secured = true,
    contentType = apiVersionHeader,
    stringifyJson = true,
    acceptType = null,
    isFileUpload = false,
  ) => makeRequest(
    url,
    'POST',
    payload,
    secured,
    contentType,
    stringifyJson,
    acceptType,
    isFileUpload
  ),

  /**
   * Short for `post` file upload.
   *
   * @param {string} url - URL
   * @param {File} file - file to upload
   * @param {object} extraPayload - multipart form extra payload
   * @returns {object} - response data
   */
  postFile: async (
    url,
    file,
    extraPayload
  ) => {
    const multipartData = new FormData();
    multipartData.append('file', file);
    Object.keys(extraPayload).forEach((key) => {
      multipartData.append(key, extraPayload[key]);
    });

    return makeRequest(
      url,
      'POST',
      multipartData,
      true,
      apiVersionHeader,
      false,
      null,
      true
    );
  },

  /**
   * Make request with PUT method.
   *
   * @param {string} url - URL
   * @param {object} payload - request payload
   * @param {string} contentType - request content type
   * @returns {object} - response data
   */
  put: async (url, payload, contentType = apiVersionHeader) => makeRequest(
    url,
    'PUT',
    payload,
    contentType
  ),

  /**
   * Make request with DELETE method.
   *
   * @param {string} url - URL
   * @returns {object} - response data
   */
  delete: async (url) => makeRequest(url, 'DELETE'),

  /**
   * Get XML file
   *
   * @param {string} url - URL
   * @returns {object} - response data
   */
  getXML: async (url) => fetch(url),

  /**
   * Make POST request, sending XML file
   *
   * @param {string} url - URL
   * @param {Blob} xml - XML file
   * @returns {object} - response data
   */
  postXML: async (url, xml) => {
    const formData = new FormData();
    formData.append('file', xml);

    return request.post(
      url,
      formData,
      true,
      null,
      false,
      'application/json',
      true
    );
  },

  /**
   * Handle file download.
   *
   * @param {string} url - url
   * @param {boolean} secured - append authorization header
   */
  download: async (url, secured = true) => {
    const headers = authHeader(secured);
    const {
      username, switched,
    } = sessionService.getUserEntries();
    if (secured && switched) {
      headers['X-Switch-User'] = username;
    }
    const response = await fetch(url, { headers });

    const contentDispositionHeader = response.headers.get('Content-Disposition');
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    const filename = contentDispositionHeader.includes('filename*=utf-8')
      ? decodeURI(
        contentDispositionHeader?.split('filename*=utf-8\'\'')?.pop().toString()
        || contentDispositionHeader?.split('filename*=utf-8')?.pop().toString()
      )
      : contentDispositionHeader?.split('filename=').pop();
    const filenameToSet = filename.split('"')[1]?.toString() || filename.toString();
    link.setAttribute('download', filenameToSet);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  },

  /**
   * Send request to API to retrieve swiched user data.
   *
   * @param {string} username - username
   * @returns {object}
   */
  userSwitch: async (username) => {
    const response = await fetch(API_ROUTE.usersMe, {
      method: 'GET',
      headers: {
        ...authHeader(true),
        'X-Switch-User': username,
      },
    });

    return handleResponse(response);
  },
};

/**
 * Make external API request.
 *
 * @param {string} url - URL
 * @param {string} method - request method
 * @param {object} payload - request payload
 * @param {boolean} secured - append authorization header
 * @param {string} contentType - request content type
 * @param {boolean} stringifyJson - response will be stringified to json
 * @param {string} acceptType - request accept type
 * @param {boolean} isFileUpload - is file upload
 * @param {Function} abortControllerSignal - abort controller signal
 * @returns {object} - response data
 */
const makeRequest = async (
  url,
  method,
  payload,
  secured = true,
  contentType = apiVersionHeader,
  stringifyJson = true,
  acceptType = null,
  isFileUpload = false,
  abortControllerSignal = null,
) => {
  const buildBody = () => {
    if (!payload) {
      return null;
    }
    if (payload && stringifyJson) {
      return JSON.stringify(payload);
    }

    return payload;
  };

  const headers = {
    method,
    headers: {
      ...authHeader(secured),
      Accept: acceptType || contentType,
      'Content-Type': contentType,
    },
    body: buildBody(),
  };

  if (abortControllerSignal) {
    headers.signal = abortControllerSignal;
  }

  const {
    username, switched,
  } = sessionService.getUserEntries();
  if (secured && switched) {
    headers.headers['X-Switch-User'] = username;
  }

  if (Number(apiUiPathHeaderEnabled) === 1) {
    headers.headers['X-Parp-Ui-Path'] = window.location.pathname;
  }

  if (isFileUpload) {
    delete headers.headers['Content-Type'];
  }

  const response = await fetch(url, headers);

  return handleResponse(response);
};
