import { Grid } from '@mui/material';
import logoParp from '../../images/logo_PARP_grupa_PFR.svg';
import { theme } from '../../styles/theme';

const classes = {
  highlighted: {
    color: theme.palette.primary.main,
  },
  line: {
    display: 'block',
  },
  offset: {
    paddingLeft: theme.spacing(3),
  },
  logoParp: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: '52px',
    width: 'auto',
    float: 'left',
  },
};

/**
 * Contact information fragment of LSI Footer.
 *
 * @returns {ContactInfo}
 */
export function ContactInfo() {
  return (
    <Grid container direction="column">
      <Grid item container>
        <Grid item xs={6} md={6} lg={3}>
          <img src={logoParp} style={classes.logoParp} alt="logo PARP Grupa PFR" />
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={3}
          py={0.5}
          pl={2}
          textAlign="left"
          lineHeight="1.15em"
          fontSize="0.75rem"
          borderLeft={`2px solid ${theme.palette.brandGray.main}`}
        >
          <span style={classes.line}>Polska Agencja</span>
          <span style={classes.line}>Rozwoju</span>
          <span style={classes.line}>Przedsiębiorczości</span>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={3}
          py={0.5}
          pl={2}
          textAlign="left"
          lineHeight="1.15em"
          fontSize="0.75rem"
          borderLeft={`2px solid ${theme.palette.brandGray.main}`}
        >
          <span style={classes.highlighted}>Biuro w Warszawie</span>
          <span style={classes.line}>ul. Pańska 81/83,</span>
          <span style={classes.line}>00-834 Warszawa</span>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={3}
          py={0.5}
          pl={2}
          textAlign="left"
          lineHeight="1.15em"
          fontSize="0.75rem"
          borderLeft={`2px solid ${theme.palette.brandGray.main}`}
        >
          <span style={classes.line}>tel.: (22) 432 80 80,</span>
          <span style={{
            ...classes.line,
            ...classes.offset,
          }}
          >
            (22) 432 71 25,
          </span>
          <span style={classes.line}>fax: (22) 432 86 20</span>
        </Grid>
      </Grid>
    </Grid>
  );
}
