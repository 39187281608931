export const ACTION_TYPES = {
  CHECK_AVAILABILITY: 'CHECK_AVAILABILITY',
  TOGGLE_AVAILABILITY: 'TOGGLE_AVAILABILITY',
  ON_REQUEST_SEND: 'ON_REQUEST_SEND',
  ON_REQUEST_NOT_SEND: 'ON_REQUEST_NOT_SEND',
  LOGIN: 'LOGIN',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT: 'LOGOUT',
  SET_IS_EXPIRED: 'SET_IS_EXPIRED',
  DARK_MODE: 'DARK_MODE',
  SET_FONT_SIZE: 'SET_FONT_SIZE',
  SET_PROFILE: 'SET_PROFILE',
  GLOBAL_NOTIFICATION: 'GLOBAL_NOTIFICATION',
  CLEAR_GLOBAL_NOTIFICATION: 'CLEAR_GLOBAL_NOTIFICATION',
  FORCE_RELOAD: 'FORCE_RELOAD',
  SET_PROFILE_SWITCHING: 'SET_PROFILE_SWITCHING',
  DICTIONARY_POPULATE: 'DICTIONARY_POPULATE',
  RESOURCE_FETCH_START: 'RESOURCE_FETCH_START',
  RESOURCE_FETCH_FINISH: 'RESOURCE_FETCH_FINISH',
  OPEN_MENU_GROUP: 'OPEN_MENU_GROUP',
  CLOSE_MENUS: 'CLOSE_MENUS',
  DICTIONARY_LOAD_QUEUE_ADD: 'DICTIONARY_LOAD_QUEUE_ADD',
  DICTIONARY_LOAD_QUEUE_SUBTRACT: 'DICTIONARY_LOAD_QUEUE_SUBTRACT',
  APPLICATION_SAVE: 'APPLICATION_SAVE',
  APPLICATION_VERIFY_CORRECTNESS: 'APPLICATION_VERIFY_CORRECTNESS',
  APPLICATION_PRINT: 'APPLICATION_PRINT',
  APPLICATION_SUBMIT: 'APPLICATION_SUBMIT',
  OPEN_APPLICATION_CONFIRM_DIALOG: 'OPEN_APPLICATION_CONFIRM_DIALOG',
  CLOSE_APPLICATION_CONFIRM_DIALOG: 'CLOSE_APPLICATION_CONFIRM_DIALOG',
  RESET_BUTTONS_STATE: 'RESET_BUTTONS_STATE',
  SET_PAGE_LOCK: 'SET_PAGE_LOCK',
  SET_PAGE_UNLOCK: 'SET_PAGE_UNLOCK',
  APPLICATION_IS_CORRECTION: 'APPLICATION_IS_CORRECTION',
  SET_PREVIOUS_PATH: 'SET_PREVIOUS_PATH',
  SHOW_NEW_MESSAGE_DIALOG: 'SHOW_NEW_MESSAGE_DIALOG',
  NOT_SHOW_NEW_MESSAGE_DIALOG: 'NOT_SHOW_NEW_MESSAGE_DIALOG',
};
