/**
 * Expert application form statuses.
 *
 * @type {object}
 */
export const EXPERT_APPLICATION_FORM_STATUSES = {
  inEdition: 'editing',
  inCorrection: 'correction',
  underEvaluation: 'evaluation',
  accepted: 'accepted',
  rejected: 'rejected',
  withdrawn: 'withdrawn',
};

/**
 * Expert application form statuses translations.
 *
 * @type {object}
 */
export const EXPERT_APPLICATION_FORM_STATUSES_TRANSLATIONS = {
  [EXPERT_APPLICATION_FORM_STATUSES.inEdition]: 'W edycji',
  [EXPERT_APPLICATION_FORM_STATUSES.accepted]: 'Zaakceptowany',
  [EXPERT_APPLICATION_FORM_STATUSES.rejected]: 'Odrzucony',
  [EXPERT_APPLICATION_FORM_STATUSES.underEvaluation]: 'W trakcie oceny',
  [EXPERT_APPLICATION_FORM_STATUSES.withdrawn]: 'Wycofany',
  [EXPERT_APPLICATION_FORM_STATUSES.inCorrection]: 'W uzupełnieniu',
};
