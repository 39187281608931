export const AdditionalActivityFormValidation = {
  stepServices: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Czy kandydat na eksperta świadczy usługi w ramach STEP?"',
    },
  },
  innovationCoachServices: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Czy kandydat na eksperta świadczy usługi w ramach Innovation Coach?"',
    },
  },
  appraiserRights: {
    required: {
      value: true,
      message:
        'Proszę uzupełnić pole "Czy kandydat na eksperta posiada uprawnienia rzeczoznawcy lub jest wpisany '
          + 'na listę biegłych sądowych?"',
    },
  },
  domain: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Dziedzina"',
    },
  },
  type: {
    required: {
      value: true,
      message: 'Proszę uzupełnić pole "Rodzaj wpisu"',
    },
  },
};
