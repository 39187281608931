import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
  DICTIONARIES,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';

/**
 * Venture feasibility
 *
 * @returns {VentureFeasibility}
 */
export function VentureFeasibility() {
  const {
    watch, resetField,
  } = useFormContext();
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();

  const { get } = useDictionaryLoader(
    DICTIONARIES.ventureFeasibilityEconomicOrEnvironmentalEffect,
  );

  const collectionName = 'venture_feasibility_economic_or_environmental_effects';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const completedProjectsConfig = fieldsConfig[collectionName];
  const subfields = completedProjectsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = completedProjectsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <FieldSorter>
      {/* Czy wnioskodawca uwzględnia we wniosku koszty opracowania Studium Wykonalności? */}
      <FormField name="venture_feasibility_does_applicant_include_development_costs">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'venture_feasibility_date_and_type_confirming_document',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
                resetField(
                  'venture_feasibility_contractor_name',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
                resetField(
                  'venture_feasibility_contractor_selecting_method',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {watch('venture_feasibility_does_applicant_include_development_costs') === BOOLEAN_VALUES.TRUE && (
        <>
          {/* Data i rodzaj dokumentu potwierdzającego wykonanie Studium Wykonalności */}
          <FormField name="venture_feasibility_date_and_type_confirming_document">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Nazwa wykonawcy Studium Wykonalności */}
          <FormField name="venture_feasibility_contractor_name">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Sposób wyboru wykonawcy Studium Wykonalności */}
          <FormField name="venture_feasibility_contractor_selecting_method">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
      {/* Kiedy Studium Wykonalności zostało opłacone?  */}
      <FormField name="venture_feasibility_payment_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Zakres analizy przedsięwzięcia */}
      <FormField name="venture_feasibility_venture_analysis_scope" contextHelpLabel>
        {() => null}
      </FormField>
      {/*  Analiza społeczno-gospodarcza, w tym określenie lokalizacji przedsięwzięcia, charakterystyki otoczenia
      społeczno- gospodarczego oraz zakresu prac B+R (jeśli dotyczy) niezbędnych do realizacji przedsięwzięcia. */}
      <FormField name="venture_feasibility_socio_economic_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Analiza instytucjonalno-prawna, w tym doświadczenie Wnioskodawcy w projektach inwestycyjnych,
      analiza wykonalności przedsięwzięcia - organizacyjna, prawna , terminowa. */}
      <FormField name="venture_feasibility_institutional_and_legal_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Analiza techniczna i technologiczna, w tym opis techniczny przedsięwzięcia, analiza wykonalności,
      analiza opcji i wybór optymalnego rozwiązania (techniczne, inwestycyjne) oraz określenie wskaźników
      – środowiskowych wskaźników gospodarki o obiegu zamkniętym. */}
      <FormField name="venture_feasibility_technical_and_technological_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Analiza finansowa, w tym;
- założenia do analizy finansowej i przepływów;
- określenie wskaźników efektywności finansowej;
- określenie nakładów inwestycyjnych;
- określenie właściwego poziomu dofinansowania inwestycji;
- analiza sposobu zapewnienia finansowej trwałości przedsięwzięcia. */}
      <FormField name="venture_feasibility_financial_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Plan finansowania przedsięwzięcia, w tym określenie etapów realizacji przedsięwzięcia,
      terminów tych etapów (kwartalnych) oraz osoby/ osób odpowiedzialnych za wykonanie poszczególnych etapów. */}
      <FormField name="venture_feasibility_venture_financing_plan">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Efekt ekonomiczny lub środowiskowy dla Wnioskodawcy i jego otoczenia z realizacji przedsięwzięcia */}
      <FormField name="venture_feasibility_economic_or_environmental_effect">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Uzasadnienie wskazanego/ych wybranych efektu/ów */}
      <FormField name="venture_feasibility_effects_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Ocena szans powodzenia przedsięwzięcia */}
      <FormField name="venture_feasibility_venture_assessment_success_chances">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Ocena zagrożeń związanych z przedsięwzięciem */}
      <FormField name="venture_feasibility_venture_risks_assessment">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Mocne strony przedsięwzięcia */}
      <FormField name="venture_feasibility_venture_advantages">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Słabe strony przedsięwzięcia */}
      <FormField name="venture_feasibility_venture_disadvantages">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Analiza wariantów alternatywnych przedsięwzięcia */}
      <FormField name="venture_feasibility_venture_alternative_variants_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Efekt ekonomiczny lub środowiskowy dla Wnioskodawcy i jego otoczenia z realizacji przedsięwzięcia" */}
      <LabeledFieldRow fieldConfig={completedProjectsConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Efekt ekonomiczny lub środowiskowy */}
            <FormField name={`${collectionName}.${index}.economic_or_environmental_effect`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.ventureFeasibilityEconomicOrEnvironmentalEffect.name))}
                  renderSelectAllButton={false}
                  error={error}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {/* Uzasadnienie wybranych efektów */}
            <FormField name={`${collectionName}.${index}.effect_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="X40FNN1Dkc8vTLr"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
