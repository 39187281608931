import { useState } from 'react';
import { validationEndpointByRuleName } from './validationTypesViaAPI';
import { request } from '../../../../_services';

/**
 * Api application validator
 *
 * @param {object} props - root props
 * @param {Array} props.rules - rules
 * @param {string} props.fieldName - field name
 * @param {Function} props.setError - handle error
 * @returns {useApiApplicationValidator}
 */
export function useApiApplicationValidator({
  fieldName, setError, rules,
}) {
  const [isLoading, setLoading] = useState(false);

  const queuedValidations = [];

  rules?.forEach(({ name }) => {
    if (name in validationEndpointByRuleName) {
      queuedValidations.push(validationEndpointByRuleName[name]);
    }
  });

  const handleApiCall = async (value) => {
    setLoading(true);
    const validationsToResolve = queuedValidations.map(({ path }) => request.post(path, {
      required: null,
      value,
      options: [],
    }));

    const validations = await Promise.all(validationsToResolve);

    setLoading(false);
    validations.forEach((response) => {
      const {
        statusSuccess, violations,
      } = response;

      if (!statusSuccess) {
        setError(fieldName, {
          type: 'manual',
          message: violations[0].message,
        });
      }
    });
  };

  return {
    isLoading,
    validate: handleApiCall,
  };
}
