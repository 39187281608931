import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  DATE,
  DICTIONARIES,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Br implementation potential pdf component
 *
 * @returns {BrImplementationPotentialPdf}
 */
function BrImplementationPotentialPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="results_implementation_way" dictionaryName={DICTIONARIES.resultImplementationMethods.name} />
        <RowField name="results_implementation_description" isSingleRow />
        <RowField name="estimated_implementation_date" formatDate={DATE.dateWithoutDay} />
        <RowField name="implementation_as_part_of_innovations_module" />
        <RowField name="intellectual_property_rights_label" isSingleRow />
        <RowField name="barriers_absence_demonstration" isSingleRow />
        <RowField name="technical_condition_examination_label" isSingleRow />
        <RowField name="examination_date_technology_condition" />
        <RowField
          name="examination_technology_condition_conducted_by"
          dictionaryName={DICTIONARIES.technicalExaminationPerformers.name}
        />
        <RowField name="used_databases" />
        <RowField name="subject_classification_way" />
        <RowField name="used_keywords" />
        <RowField name="results_description" isSingleRow />
        <RowField name="protection_subject" isSingleRow />
        <RowField name="law_regulations_description" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default BrImplementationPotentialPdf;
