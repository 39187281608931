import PropTypes from 'prop-types';
import {
  format,
  isValid,
  isAfter,
} from 'date-fns';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  InputLabel,
  FormControl,
  TextField,
  Button,
} from '@mui/material';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';
import CharsCounter from '../../CharsCounter';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import {
  useGlobalContext,
  useGlobalDialog,
  useReloadListener,
} from '../../../Context';
import { handleError } from '../../../_helpers';

/**
 * Form of application form correction.
 *
 * @param {object} props - root props
 * @param {boolean} props.isReadonly - is read only
 * @param {object} props.initialData - initial data of correction
 * @param {string} props.applicationFormId - application form id
 * @returns {ApplicationFormCorrectionForm}
 */
export default function ApplicationFormCorrectionForm({
  isReadonly, initialData, applicationFormId,
}) {
  const { closeAll } = useGlobalDialog();
  const { notify } = useGlobalContext();
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'application-form-correction';

  const {
    control, handleSubmit, setError, formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      finalSubmissionAt: initialData?.finalSubmissionAt ? new Date(initialData?.finalSubmissionAt) : null,
      description: initialData?.description || '',
    },
    mode: 'all',
  });

  const submitForm = async (formData) => {
    const finalSubmissionAt = format(formData.finalSubmissionAt, 'yyyy-MM-dd');
    const payload = {
      ...formData,
      applicationForm: `/lsi/auth/api/application-forms/${applicationFormId}`,
      finalSubmissionAt,
    };
    const requestMethod = !initialData
      ? request.post(API_ROUTE.applicationFormCorrections, payload)
      : request.put(`${API_ROUTE.applicationFormCorrections}/${initialData.id}`, payload);
    const {
      statusSuccess, violations = [],
    } = await requestMethod;

    window.scroll(0, 0);
    closeAll();
    reload(watcherName);

    if (!statusSuccess) {
      handleError(setError, violations);

      notify('Wystąpił błąd podczas zapisu wezwania', 'error');

      return;
    }
    notify(`Wezwanie zostało ${!initialData ? 'dodane' : 'zmienione'}`, 'success');
  };

  return (
    <>
      <InputLabel htmlFor="finalSubmissionAt" sx={{ mb: (theme) => theme.spacing(2) }}>
        Ostateczna data przesłania uzupełnień
      </InputLabel>
      <FormControl
        variant="outlined"
        fullWidth
        sx={{ mb: (theme) => theme.spacing(3) }}
      >
        <Controller
          rules={{
            validate: {
              isValidDate: (dateValue) => isValid(dateValue) || 'Nieprawidłowa wartość',
              ifAfterStartsAt: (dateValue) => isAfter(dateValue, new Date())
                || 'Data wezwania do uzupełnień powinna być późniejsza od daty dzisiejszej.',
            },
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Ostateczna data przesłania uzupełnień"',
            },
          }}
          name="finalSubmissionAt"
          control={control}
          render={({
            field: {
              onChange, value, name,
            },
            fieldState: {
              error,
            },
          }) => (
            <CustomDesktopDatePicker
              onChange={onChange}
              value={value}
              name={name}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
              ariaLabel="Ostateczna data przesłania uzupełnień"
              label="Ostateczna data przesłania uzupełnień"
            />
          )}
        />
      </FormControl>
      <InputLabel htmlFor="description" sx={{ mb: (theme) => theme.spacing(2) }}>
        Uwagi PARP
      </InputLabel>
      <FormControl
        variant="outlined"
        fullWidth
        sx={{ mb: (theme) => theme.spacing(3) }}
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Uwagi PARP"',
            },
          }}
          name="description"
          control={control}
          render={({
            field: {
              onChange, value, name,
              onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              placeholder="Uwagi PARP"
              onBlur={onBlur}
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
              }}
              inputProps={{
                maxLength: 10000,
              }}
              multiline
            />
          )}
        />
      </FormControl>
      {!isReadonly && (
        <SaveCancelButtons
          saveTitle="Wyślij"
          saveDisabled={isReadonly || isSubmitting}
          saveButtonId="ZTBYjmbKi7ohkfd"
          cancelButtonId="3qdyw2n7NY5dKNE"
          cancelHandler={closeAll}
          saveHandler={handleSubmit(submitForm)}
        />
      )}
      {isReadonly && (
        <Button
          variant="outlined"
          color="primary"
          id="08n6zVLK2rRikRH"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      )}

    </>
  );
}

ApplicationFormCorrectionForm.propTypes = {
  isReadonly: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  applicationFormId: PropTypes.string.isRequired,
};

ApplicationFormCorrectionForm.defaultProps = {
  isReadonly: false,
  initialData: null,
};
