import {
  SPECIALIZATIONS_TYPES,
  SPECIALIZATIONS_TYPES_TRANSLATIONS,
} from '../_constants';

export const getSpecializationsName = (arr) => arr?.specializations
  .map((specialization) => Object.keys(SPECIALIZATIONS_TYPES)
    .find((key) => SPECIALIZATIONS_TYPES[key] === specialization))
  .map((specializationKey) => SPECIALIZATIONS_TYPES_TRANSLATIONS[specializationKey])
  .join(', ');
