import PropTypes from 'prop-types';
import {
  Grid,
  InputLabel,
} from '@mui/material';

/**
 * Field wrapper component
 *
 * @param {object} props - root props
 * @param {string} props.label - field label
 * @param {string} props.name - field name for form
 * @param {Node} props.children - children component
 * @param {object} props.sx - styles
 * @returns {FieldWrapper}
 */
function FieldWrapper({
  label, name, children, sx,
}) {
  return (
    <>
      <Grid item xs={children ? 3 : 10} display="flex" alignItems="center">
        <InputLabel
          htmlFor={name}
          variant="noShorten"
          sx={sx}
        >
          {label}
        </InputLabel>
      </Grid>
      {children && (
        <Grid item xs={7}>
          {children}
        </Grid>
      )}
    </>
  );
}

export default FieldWrapper;

FieldWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([null]),
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sx: PropTypes.instanceOf(Object),
};

FieldWrapper.defaultProps = {
  children: null,
  sx: {},
};
