import {
  Button,
  Box,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TitledContainer } from '../../TitledContainer';
import { API_ROUTE } from '../../../_constants';
import { request } from '../../../_services';
import { FileIcon } from '../../FileUpload/FileIcon';
import { useAuth } from '../../../_security';

/**
 * Statements application component.
 *
 * @returns {StatementsApplication}
 */
export function StatementsApplication() {
  const {
    firstName, lastName,
  } = useAuth();

  const loadHandler = async () => {
    const {
      payload: {
        reference,
      }, statusSuccess,
    } = await request.post(API_ROUTE.statementsApplication, {
      payload: {
        first_name: firstName,
        last_name: lastName,
      },
    });

    if (statusSuccess) {
      window.open(`${API_ROUTE.statementsApplication}/${reference}`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <TitledContainer
      title="Oświadczenia"
      key="statements-applciation"
      variant="slim"
      textAlign="left"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: (theme) => theme.spacing(3) }}
      >
        <Box display="flex" alignItems="center">
          <FileIcon extension="pdf" />
          <Typography ml={1}>
            Oświadczenia do wniosku aplikacyjnego
          </Typography>
          <CheckIcon color="secondary" />
        </Box>
        <Button
          id="thP8Okk1BLImMaS"
          variant="contained"
          color="secondary"
          onClick={loadHandler}
        >
          Pobierz
        </Button>
      </Box>
    </TitledContainer>
  );
}
