import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import RowField from '../../../Components/RowField/RowField';

/**
 * MarketDemandAndCostEffectivenessImplementationPdf element.
 *
 * @returns {MarketDemandAndCostEffectivenessImplementationPdf}
 */
function MarketDemandAndCostEffectivenessImplementationPdf() {
  return (
    <View style={printStyle.table}>
      <RowField name="innovation_implementation_module_essence_market_needs" isSingleRow />
      <RowField name="innovation_implementation_module_essence_competence" isSingleRow />
      <RowField name="innovation_implementation_module_essence_product_innovation" isSingleRow />
      <RowField name="innovation_implementation_module_essence_applicant_offer" />
      <RowField name="innovation_implementation_module_essence_competitors_offer" />
      <RowField name="innovation_implementation_module_essence_destination_market" />
      <RowField name="innovation_implementation_module_essence_innovation_implementation_plan" />
      <RowField name="innovation_implementation_module_essence_process_innovation" isSingleRow />
      <RowField name="innovation_implementation_module_essence_influence_on_production_cycle" />
      <RowField name="innovation_implementation_module_essence_implementation_benefits" />
      <RowField name="innovation_implementation_module_essence_process_implementation_plan" />
      <RowField name="innovation_implementation_module_essence_innovation_impact" />
      <RowField name="innovation_implementation_module_essence_innovation_subject" isSingleRow />
    </View>
  );
}

export default MarketDemandAndCostEffectivenessImplementationPdf;
