import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import Email from '@mui/icons-material/CommentOutlined';
import { ContactForLoggedIn } from '../../Features/Contact';
import { useGlobalDialog } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  contactBtn: {
    position: 'fixed',
    bottom: theme.spacing(2.5),
    right: '0',
    zIndex: 10,
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0),
  },

  icon: {
    fontSize: '3rem',
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  dialog: {
    zIndex: '9001 !important',
    animation: `$lazyFadeEffect 1000ms ${theme.transitions.easing.easeIn}`,
  },
  '@keyframes lazyFadeEffect': {
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 0,
    },
    '60%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
  },
};

/**
 * Component which allows logged-in users fast entrance to the contact form by clicking a floating icon
 *
 * @returns {FastContact}
 */
export function FastContact() {
  const {
    render, closeAll,
  } = useGlobalDialog();

  return (
    <aside>
      <Tooltip title="Formularz kontaktowy">
        <IconButton
          sx={classes.contactBtn}
          aria-label="Otwórz formularz zgłoszeniowy"
          size="small"
          onClick={() => render(
            <>
              <ContactForLoggedIn
                headingVariant="dialogHeading"
                onSuccess={() => {
                  closeAll();
                  window.scroll(0, 0);
                }}
              />
              <Box display="flex" justifyContent="flex-end" width="100%" mt={5}>
                <Button
                  variant="outlined"
                  onClick={closeAll}
                  color="primary"
                >
                  Zamknij
                </Button>
              </Box>
            </>,
            null,
            {
              maxWidth: 'lg',
              variant: 'contained',
              color: 'primary',
              sx: classes.dialog,
            }
          )}
        >
          <Email
            sx={classes.icon}
            aria-hidden="true"
          />
        </IconButton>
      </Tooltip>
    </aside>
  );
}
