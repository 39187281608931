import {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  Button,
  Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../Context';
import MaterialAndFinancialScheduleDialog from './MaterialAndFinancialSchedule/MaterialAndFinancialSchedule.dialog';
import MaterialAndFinancialScheduleTable from './MaterialAndFinancialSchedule/MaterialAndFinancialSchedule.table';
import { request } from '../../../_services';
import {
  API_ROUTE,
  EXPENSES_TYPE,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import ExpensesSummary from './MaterialAndFinancialSchedule/ExpensesSummary/ExpensesSummary';
import FieldConfig from '../../Application/Application/FieldConfig/FieldConfig';

/**
 * Material and financial schedule
 *
 * @returns {MaterialAndFinancialSchedule}
 * */
function MaterialAndFinancialSchedule() {
  const expensesSummaryRef = useRef();
  const [isIntermediateTask, setIsIntermediateTask] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const { id: applicationId } = useParams();
  const [modal, setModal] = useState(false);
  const {
    id: elementId, fieldsConfig, isReadonly,
  } = useElementContext();
  const [reloadDataListener, setReloadDataListener] = useState('');
  const [reloadActualExpensesDataListener, setReloadActualExpensesDataListener] = useState('');
  const [reloadLumpSumExpensesDataListener, setReloadLumpSumExpensesDataListener] = useState('');

  const [tasks, setTasks] = useState([]);
  const [actualExpensesData, setActualExpensesData] = useState([]);
  const [lumpSumExpensesData, setLumpSumExpensesData] = useState([]);

  const { control } = useFormContext();

  const { append } = useFieldArray({
    control,
    name: `tasks-${elementId}`,
  });

  const tasksConfig = fieldsConfig[`tasks-${elementId}`];
  const subfields = tasksConfig?.fields || {};
  const fieldObject = subfields ? Object.keys(subfields).reduce((prev, key) => ({
    ...prev,
    [key]: subfields[key].defaultValue,
  }), {}) : {};

  /**
   * Adds item to contact_persons collection.
   */
  const addItem = async () => {
    append(fieldObject);
    handleModal();
  };

  /**
   * Triggers listener to reload data from API.
   *
   * @param {string} reloadedElementId - current reloaded element
   */
  const reloadData = useCallback((reloadedElementId) => {
    setReloadDataListener(reloadedElementId);
  }, []);

  const handleModal = useCallback(() => {
    setModal((prevState) => !prevState);
  }, []);

  const getTasks = async () => {
    const {
      payload, statusSuccess,
    } = await request
      .get(API_ROUTE.applicationTasks
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId));

    if (statusSuccess) {
      setTasks(payload);
      setReloadDataListener('');
      setLoaded(true);
      setIsIntermediateTask(payload.some((task) => task.indirect_costs));
    }
  };

  const reloadExpensesData = (reloadElementId, expensesType) => {
    if (expensesType === EXPENSES_TYPE.actualExpenses) {
      setReloadActualExpensesDataListener(reloadElementId);

      expensesSummaryRef?.current?.handleReloadData(reloadElementId);

      return;
    }

    setReloadLumpSumExpensesDataListener(reloadElementId);

    expensesSummaryRef?.current?.handleReloadData(reloadElementId);
  };

  const getActualExpenses = async () => {
    const {
      payload, statusSuccess,
    } = await request
      .get(`${API_ROUTE.applicationExpenses
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/actual-expenses`);

    if (statusSuccess) {
      setActualExpensesData(payload);
      reloadExpensesData('', EXPENSES_TYPE.actualExpenses);
      setLoaded(true);
    }
  };

  const getLumpSumExpenses = async () => {
    const {
      payload, statusSuccess,
    } = await request
      .get(`${API_ROUTE.applicationExpenses
        .replace(':applicationId', applicationId)
        .replace(':elementId', elementId)}/lump-sum-expenses`);

    if (statusSuccess) {
      setLumpSumExpensesData(payload);
      reloadExpensesData('', EXPENSES_TYPE.flatRateExpenses);
      setLoaded(true);
    }
  };

  useEffect(() => {
    getTasks();
    getActualExpenses();
    getLumpSumExpenses();
  }, [elementId]);

  useEffect(() => {
    if (reloadDataListener === elementId) {
      getTasks();
    }
  }, [reloadDataListener]);

  useEffect(() => {
    if (reloadActualExpensesDataListener === elementId) {
      getActualExpenses();
    }
  }, [reloadActualExpensesDataListener]);

  useEffect(() => {
    if (reloadLumpSumExpensesDataListener === elementId) {
      getLumpSumExpenses();
    }
  }, [reloadLumpSumExpensesDataListener]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {tasks.map((task, index) => (
        <MaterialAndFinancialScheduleTable
          key={task.task_id}
          data={task}
          itemIndex={index.toString()}
          reloadData={reloadData}
          expensesSummaryRef={expensesSummaryRef}
          actualExpensesData={actualExpensesData}
          lumpSumExpensesData={lumpSumExpensesData}
          reloadActualExpensesDataListener={reloadActualExpensesDataListener}
          reloadLumpSumExpensesDataListener={reloadLumpSumExpensesDataListener}
          reloadExpensesData={reloadExpensesData}
        />
      ))}

      {modal && (
        <MaterialAndFinancialScheduleDialog
          closeModal={handleModal}
          isOpen={modal}
          reloadData={reloadData}
          isIntermediateTask={isIntermediateTask}
        />
      )}

      {!isReadonly && (
        <Grid item xs={12}>
          <Button
            id="u6xIz0iUOpPIANb"
            type="button"
            variant="contained"
            color="success"
            onClick={addItem}
          >
            Dodaj zadanie
          </Button>
        </Grid>
      )}

      <FieldConfig name={`hrf_element_summary-${elementId}`}>
        {() => (
          <Grid item xs={12}>
            <ExpensesSummary ref={expensesSummaryRef} />
          </Grid>
        )}
      </FieldConfig>
    </>
  );
}

export default MaterialAndFinancialSchedule;
