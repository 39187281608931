import PropTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import uniqid from 'uniqid';
import { debounce } from 'lodash';
import { request } from '../../_services';

/**
 * MUI Autocomplete with searchable options by API.
 * The main difference between "CustomAutocomplete" and this component is that
 * "CustomAutocomplete" accepts pre-made options that do not change.
 * This component fetch options from API. after each keystroke.
 *
 * @see https://mui.com/components/autocomplete/#search-as-you-type
 * @param {object} props - root props
 * @param {string} props.id - id prop passed to input component
 * @param {boolean} props.disabled - disabled prop passed to input component
 * @param {(string|Array)} props.initialValue - already selected option value or array of options
 * @param {string} props.initialValueFilterBy - if "initialValue" is entered, a query will be sent to filter
 * by the this value, ex. `../books?{initialValueFilterBy}={initialValue}
 * @param {string} props.baseUrl - API URL to send search requests
 * @param {string} props.filterBy - input text will be passed to search URL, ex. `../books?{filterBy}={inputValue}`
 * @param {Function} props.optionParser - function to process single row fetched from API which convert
 * row data into Autocomplete supported format - {label, value}
 * @param {Function} props.changeHandler - function invoked on value selection
 * @param {Array} props.charLimitExclusionWords - filter request will be sent only if input value has length greater
 * or equal to 3. However, the search may take place with the shorter
 * words specified in this property. Ex. ['to', 'ni'] will send filter
 * request after user type 'to' or 'ni' in search input.
 * @param {number} props.minCharsToRequest - min chars to request
 * @param {object} props.staticFilters - extra filters appended to request URL, ex. {town: 'Warszawa'} will append
 * to URL string `&town=Warszawa`. If property value is array, multiple fitlers
 * will be appended, ex. {town: ['Warszawa', 'Krakow']} will append
 * `&town[]=Warsaw&town[]=Krakow`
 * @param {object} props.textFieldProps - props passed to text field
 * @param {object} ref - ref to pass functions from imperative handle
 * @param {boolean} props.multiple - if true allow to choose more than one option. Note: value have to be an array
 * @param {Function} props.customFilter - function to filter server result
 * @param {object} props.error - field errors
 * @throws Error on passing 'initialValue' without 'initialValueFilterBy'
 * @returns {ApiAutocomplete}
 */
export const ApiAutocomplete = forwardRef(({
  id,
  disabled,
  initialValue,
  initialValueFilterBy,
  baseUrl,
  filterBy,
  optionParser,
  changeHandler,
  charLimitExclusionWords,
  minCharsToRequest,
  staticFilters,
  textFieldProps,
  multiple,
  customFilter,
  error,
}, ref) => {
  const abortControllerRef = useRef(null);
  const [value, setValue] = useState(multiple ? [] : null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  if (initialValue && !initialValueFilterBy) {
    throw new Error('"initialValueFilterBy" must be set if you passing "initialValue".');
  }

  if (multiple && !Array.isArray(initialValue)) {
    throw new Error('prop "multiple"  recognized, so "initialValue" have to be an array.');
  }

  const memorizedInitialValue = useMemo(() => initialValue, [id]);

  /**
   * Initial value load effect.
   */
  useEffect(() => {
    if (initialValue === '' || initialValue === null || !initialValue.length) {
      return;
    }

    const buildFilterBy = () => {
      const url = baseUrl.includes('?') ? `${baseUrl}&` : `${baseUrl}?`;
      if (multiple) {
        const parts = initialValue.map((initial) => `${initialValueFilterBy}[]=${initial}`);

        return `${url}${parts.join('&')}`;
      }

      return `${url}${initialValueFilterBy}=${initialValue}`;
    };

    const loadInitialData = async () => {
      setLoading(true);
      const { payload } = await request.get(buildFilterBy());

      if (multiple) {
        setValue(payload.map(optionParser));

        setLoading(false);

        return;
      }
      if (payload[0]) {
        const option = optionParser(payload[0]);
        setOptions([option]);
        setValue(option);
        setLoading(false);
      }
    };

    loadInitialData();
  }, [memorizedInitialValue]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setOptions([]);
    }
  }, [inputValue]);

  useImperativeHandle(ref, () => ({
    /**
     * Clears selected value and options.
     */
    clear: () => {
      setValue(multiple ? [] : null);
      setInputValue('');
      setOptions([]);
    },
  }));

  /**
   * Normalized API filters to URL string.
   */
  const normalizedStaticFilters = useMemo(() => {
    if (!staticFilters) {
      return '';
    }

    return Object.entries(staticFilters).map(([filterKey, filterValue]) => {
      if (typeof filterValue === 'string') {
        return `${filterKey}=${filterValue}`;
      }

      if (Array.isArray(filterValue)) {
        return filterValue.map((filterEntry) => `${filterKey}[]=${filterEntry}`).join('&');
      }

      return null;
    }).join('&');
  }, [staticFilters]);

  /**
   * Send requests to API with 1500ms throttle delay.
   */
  const loadApiData = useMemo(() => debounce(async (requestUrl, callback) => {
    try {
      setOptions([]);
      setLoading(true);
      const { payload } = await request.get(
        requestUrl,
        process.env.REACT_APP_V1_API_HEADER,
        abortControllerRef.current.signal
      );
      callback(payload || []);

      setLoading(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, 1500), []);

  /**
   * Handle search box keystroke change.
   */
  const handleSearch = useCallback(
    (searchValue) => {
      if (
        (searchValue === '' || searchValue.length < minCharsToRequest)
        && !charLimitExclusionWords.includes(searchValue.toLowerCase())
      ) {
        return;
      }

      const url = baseUrl.includes('?') ? `${baseUrl}&` : `${baseUrl}?`;

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }

      abortControllerRef.current = new AbortController();

      loadApiData(
        `${url}${filterBy}=${searchValue}&order[${filterBy}]=asc&${normalizedStaticFilters}`,
        (payload) => {
          setOptions(payload
            .filter(customFilter)
            .map((payloadObject) => optionParser(payloadObject)));
        }
      );
    },
    [baseUrl, charLimitExclusionWords, filterBy, loadApiData, normalizedStaticFilters, optionParser],
  );

  return (
    <Autocomplete
      id={id}
      disabled={disabled}
      value={value}
      inputValue={inputValue}
      options={options}
      loading={loading}
      // @see https://mui.com/components/autocomplete/#search-as-you-type
      filterOptions={(_) => _}
      renderOption={(props, option) => (
        <li {...props} key={option?.id || option.value}>
          {option.label}
        </li>
      )}
      onChange={(_, newValue, reason) => {
        setValue(newValue);
        changeHandler(newValue);

        if (reason === 'selectOption' && abortControllerRef.current) {
          abortControllerRef.current.abort();
          setLoading(false);
        }
      }}
      onInputChange={(_event, newInputValue) => {
        if (newInputValue !== value?.label) {
          handleSearch(newInputValue);
        }
        if (newInputValue === '') {
          setOptions([]);
        }

        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error?.message}
          {...textFieldProps}
          inputRef={inputRef}
        />
      )}
      ChipProps={{ sx: { borderRadius: 0 } }}
      noOptionsText={inputValue.length < minCharsToRequest ? `Wprowadź minimum ${minCharsToRequest} znaki` : 'Brak wyników'}
      isOptionEqualToValue={(option, comparisonValue) => option?.id === comparisonValue?.id}
      multiple={multiple}
    />
  );
});

ApiAutocomplete.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  optionParser: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  initialValueFilterBy: PropTypes.string,
  charLimitExclusionWords: PropTypes.arrayOf(PropTypes.string),
  staticFilters: PropTypes.instanceOf(Object),
  textFieldProps: PropTypes.instanceOf(Object),
  multiple: PropTypes.bool,
  customFilter: PropTypes.func,
  error: PropTypes.objectOf(Object),
  minCharsToRequest: PropTypes.number,
};

ApiAutocomplete.defaultProps = {
  id: uniqid('apiautocomplete-'),
  disabled: false,
  initialValue: '',
  initialValueFilterBy: '',
  charLimitExclusionWords: [],
  staticFilters: {},
  textFieldProps: {},
  multiple: false,
  customFilter: () => true,
  error: null,
  minCharsToRequest: 3,
};
