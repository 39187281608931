import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import RowField from '../../../Components/RowField/RowField';
import { IndicatorsHelper } from '../../../../../_helpers/applicationElement';
import { useApplicationToPdfContext } from '../../../../../Context';

/**
 * Indicator Row Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.indicator - indicator data
 * @param {string} props.name - main indicator name
 * @param {number} props.index - current indicator index
 * @param {object} props.previousIndicator - previous indicator
 * @returns {IndicatorRowPdf}
 */
function IndicatorRowPdf({
  indicator, name, index, previousIndicator,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const projectDateStart = initialFormData?.project_start_date;
  const projectDateEnd = initialFormData?.project_finish_date;

  return (
    <View style={printStyle.table}>
      <PrintoutTableRow>
        <PrintoutTableCell bgColor="gray" rowSpan={1} isTextBold>
          {indicator.name}
        </PrintoutTableCell>
      </PrintoutTableRow>
      <RowField
        name={`${name}.${index}.unit`}
        value={indicator.unit}
        oldValue={previousIndicator?.unit}
      />
      <RowField
        name={`${name}.${index}.breakdown_by_gender`}
        value={indicator.breakdown_by_gender}
        oldValue={previousIndicator?.breakdown_by_gender}
      />
      <RowField
        name={`${name}.${index}.base_year`}
        value={indicator?.base_year?.toString()}
        oldValue={previousIndicator?.base_year?.toString()}
        externalDictionary={IndicatorsHelper.getListOfYearsForIndicators(
          projectDateStart,
          indicator.base_year_range,
        ).map((year) => ({
          '@id': year.value.toString(),
          name: year.label,
        }))}
        skipFilterDictionary
      />
      <RowField
        name={`${name}.${index}.base_value_o`}
        value={indicator.base_value_o}
        oldValue={previousIndicator?.base_value_o}
      />
      <RowField
        name={`${name}.${index}.target_year`}
        value={indicator?.target_year?.toString()}
        oldValue={previousIndicator?.target_year?.toString()}
        externalDictionary={IndicatorsHelper.getListOfYearsForIndicators(
          projectDateEnd,
          indicator.target_year_range,
        ).map((year) => ({
          '@id': year.value.toString(),
          name: year.label,
        }))}
        skipFilterDictionary
      />
      <RowField
        name={`${name}.${index}.target_value_o`}
        value={indicator.target_value_o}
        oldValue={previousIndicator?.target_value_o}
      />
      {indicator.breakdown_by_gender && (
        <>
          <RowField
            name={`${name}.${index}.target_value_k`}
            value={indicator.target_value_k}
            oldValue={previousIndicator?.target_value_k}
          />
          <RowField
            name={`${name}.${index}.target_value_m`}
            value={indicator.target_value_m}
            oldValue={previousIndicator?.target_value_m}
          />
        </>
      )}
      <RowField
        name={`${name}.${index}.measuring_method`}
        value={indicator.measuring_method}
        oldValue={previousIndicator?.measuring_method}
        isSingleRow
      />
    </View>
  );
}

export default IndicatorRowPdf;

IndicatorRowPdf.propTypes = {
  indicator: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  previousIndicator: PropTypes.instanceOf(Object),
};

IndicatorRowPdf.defaultProps = {
  previousIndicator: {},
};
