import { useMemo } from 'react';
import {
  ReportProblemOutlined as WarnIcon,
  CheckCircleOutline as SuccessIcon,
  InfoOutlined as InfoIcon,
  ErrorOutline as ErrorIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { alertStyleClasses } from './Alert.style';

/**
 * Alert component used for notifications
 *
 * @param {object} props - root props
 * @param {string} props.severity - severity status
 * @param {Node} props.children - provider children elements
 * @param {string} props.title - title
 * @param {boolean} props.fullWidth - is full width
 * @param {boolean} props.modal - is modal
 * @param {Function} props.closeHandler - close handler
 * @param {object} props.sx - styles
 * @param {string} props.minHeight - min height value
 * @returns {Alert}
 */
export function Alert({
  severity, children, title, fullWidth, modal, closeHandler, sx, minHeight,
}) {
  let alertIcon;
  const alertClass = () => {
    switch (severity) {
    case 'error':
      alertIcon = <ErrorIcon sx={alertStyleClasses.alertIcon} />;

      return 'alertError';
    case 'success':
      alertIcon = <SuccessIcon sx={alertStyleClasses.alertIcon} />;

      return 'alertSuccess';
    case 'info':
      alertIcon = <InfoIcon sx={alertStyleClasses.alertIcon} />;

      return 'alertInfo';
    case 'warn':
    case 'warning':
      alertIcon = <WarnIcon sx={alertStyleClasses.alertIcon} />;

      return 'alertWarn';
    case 'status':
      return 'alertStatus';
    case 'validation':
      alertIcon = <ErrorIcon sx={alertStyleClasses.alertIcon} />;

      return 'alertValidation';
    default:
      return new Error('Sorry, there is not such type of alert');
    }
  };

  const currentAlertClass = alertClass();
  const isModal = modal ? alertStyleClasses.modal : {};

  const AlertContainer = styled('div')(useMemo(
    () => (fullWidth ? {
      ...alertStyleClasses.alert,
      ...sx,
      ...alertStyleClasses[currentAlertClass],
      ...isModal,
      ...alertStyleClasses.fullWidth,
      minHeight,
    } : {
      ...alertStyleClasses.alert,
      ...sx,
      ...alertStyleClasses[currentAlertClass],
      ...isModal,
      ...alertStyleClasses.fullWidth,
      minHeight,
    })
  ), [fullWidth, sx, minHeight, modal]);

  return (
    <AlertContainer role={severity === 'status' ? 'status' : 'alert'}>
      { title
        ? (<span style={alertStyleClasses.alertTitle}>{title}</span>)
        : ''}
      { alertIcon }
      {' '}
      { children }
      {closeHandler && (
        <IconButton
          sx={alertStyleClasses.closeButton}
          aria-label="dismiss-notification"
          onClick={closeHandler}
          size="large"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </AlertContainer>
  );
}

Alert.propTypes = {
  severity: PropTypes.oneOf(['error', 'success', 'warn', 'info', 'status']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  modal: PropTypes.bool,
  closeHandler: PropTypes.func,
  sx: PropTypes.instanceOf(Object),
  minHeight: PropTypes.string,
};

Alert.defaultProps = {
  severity: '',
  children: null,
  title: '',
  fullWidth: false,
  modal: false,
  closeHandler: null,
  sx: {},
  minHeight: '50px',
};
