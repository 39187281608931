import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Project Collaboration Pdf element.
 *
 * @returns {ProjectCollaborationPdf}
 */
function ProjectCollaborationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const { projectCollaborationTypes: {
    research, nonGovernmental,
  } } = ELEMENTS_DICTIONARIES_MAP;

  const collaborationTypeValues = initialFormData?.project_collaboration_project_collaboration_type;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="project_collaboration_project_collaboration_type"
          dictionaryName={DICTIONARIES.projectCollaborationTypes.name}
        />
        {(collaborationTypeValues?.includes(research.id) || collaborationTypeValues?.includes(nonGovernmental.id)) && (
          <CollectionWrapper name="cooperating_entity">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.name`}
                      value={item.name}
                      oldValue={previousRowData?.name}
                    />
                    <RowField
                      name={`${collectionName}.${index}.nip`}
                      value={item.nip}
                      oldValue={previousRowData?.nip}
                    />
                    <RowField
                      name={`${collectionName}.${index}.krs`}
                      value={item.krs}
                      oldValue={previousRowData?.krs}
                    />
                    <RowField
                      name={`${collectionName}.${index}.description`}
                      value={item.description}
                      oldValue={previousRowData?.description}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.effects_and_benefits`}
                      value={item.effects_and_benefits}
                      oldValue={previousRowData?.effects_and_benefits}
                      isSingleRow
                    />
                  </FieldSorter>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default ProjectCollaborationPdf;
