import { API_ROUTE } from '../_constants';
import { request } from '../_services';

/**
 * Auth helper functions.
 */
export const authService = {
  /**
   * Send request to authentication API.
   *
   * @param {string} username - user name
   * @param {string} password - user password
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   */
  handleLogin: async (username, password, onSuccess, onFailure) => {
    const authUrl = API_ROUTE.token;

    const {
      statusSuccess, payload,
    } = await request.post(
      authUrl,
      {
        username,
        password,
      },
      false,
      'application/json',
    );

    statusSuccess ? onSuccess(payload) : onFailure(payload);
  },

  /**
   * Send request to create account.
   *
   * @param {object} payload - register payload
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   * @returns {void}
   */
  handleRegister: async (payload, onSuccess, onFailure) => {
    const registerUrl = API_ROUTE.register;

    const {
      statusSuccess, payload: responsePayload, violations,
    } = await request.post(
      registerUrl,
      payload,
      false,
      'application/json'
    );

    statusSuccess ? onSuccess(responsePayload) : onFailure(violations || payload);
  },

  /**
   * Send request to refresh token
   *
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   * @returns {void}
   */
  handleRefreshSession: async (onSuccess, onFailure) => {
    const refreshTokenUrl = API_ROUTE.refreshToken;

    const {
      statusSuccess, payload,
    } = await request.get(refreshTokenUrl);

    statusSuccess ? onSuccess(payload) : onFailure(payload);
  },

  /**
   * Send request to logout
   *
   * @param {boolean} isLogoutOfAllSessions - log out of all sessions
   * @param {Function} onSuccess - function invoked on request success
   * @param {Function} onFailure - function invoked on request failure
   * @returns {void}
   */
  handleLogout: async (isLogoutOfAllSessions, onSuccess, onFailure) => {
    const additionalParams = isLogoutOfAllSessions ? '?allSession' : '';
    const {
      statusSuccess,
    } = await request.get(`${API_ROUTE.logout}${additionalParams}`);

    statusSuccess ? onSuccess() : onFailure();
  },
};
