import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import RowConfig from '../../../Components/RowConfig/RowConfig';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../../_constants';
import RowField from '../../../Components/RowField/RowField';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getAdditionalStylesByStatus,
  getIndexForPreviousRowData,
  getRemovedRowData,
  numberWithSeparator,
} from '../../../../../_helpers';

/**
 * Actual Expenses Table Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.elementId - element id
 * @param {string} props.taskId - task id
 * @param {object} props.additionalStyles - additional styles
 * @returns {ActualExpensesTablePdf}
 */
function ActualExpensesTablePdf({
  elementId, taskId, additionalStyles,
}) {
  const {
    managedApplicationTemplate: {
      initialFormData,
      fieldsConfig,
    },
    id,
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const fieldNamePrefix = `actual_expenses-${elementId}`;

  const actualExpensesConfig = fieldsConfig[fieldNamePrefix];
  const subfields = actualExpensesConfig?.fields || {};

  const rowsCount = [
    subfields?.name,
    subfields?.cost_category,
    subfields?.total_expenses,
    subfields?.eligible_expenses,
    subfields?.vat,
    subfields?.co_financing,
    subfields?.co_financing_percent,
  ].filter((field) => field).length;

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];
  const hasImplementers = initialFormData?.has_implementers;

  const getExpensesByElementId = (data, key) => {
    if (key in data && data[key]) {
      return data[key];
    }

    return [];
  };

  const actualExpensesData = getExpensesByElementId(initialFormData, fieldNamePrefix);
  const filteredExpensesByTaskId = actualExpensesData.filter((expense) => expense.task_id === taskId);

  const previousActualExpensesData = getExpensesByElementId(previousInitialFormData, fieldNamePrefix);
  const previousFilteredExpensesByTaskId = previousActualExpensesData.filter((expense) => expense.task_id === taskId);

  const addedActualExpenses = getAddedRowData(
    filteredExpensesByTaskId,
    previousFilteredExpensesByTaskId,
    'actual_expense_id'
  );
  const removedActualExpenses = getRemovedRowData(
    filteredExpensesByTaskId,
    previousFilteredExpensesByTaskId,
    'actual_expense_id'
  );
  const expensesData = withChanges ? [...addedActualExpenses, ...removedActualExpenses] : filteredExpensesByTaskId;

  if (expensesData.length === 0) {
    return '';
  }

  return (
    <View style={{
      ...additionalStyles,
    }}
    >
      <Text style={printStyle.sectionTitle}>
        Wydatki rzeczywiste
      </Text>
      {expensesData.map((expense) => {
        const itemIndex = actualExpensesData
          .findIndex(({ actual_expense_id: actualExpenseId }) => actualExpenseId === expense.actual_expense_id);
        const previousExpenseData = previousFilteredExpensesByTaskId[
          getIndexForPreviousRowData(previousFilteredExpensesByTaskId, expense.actual_expense_id, 'actual_expense_id')
        ];

        return (
          <View
            style={[printStyle.sectionWrapperWithMargin, printStyle.table, getAdditionalStylesByStatus(expense.status)]}
            key={expense.actual_expense_id}
          >
            <PrintoutTableRow>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.name`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.cost_category`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.total_expenses`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.eligible_expenses`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.vat`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing_percent`}
              >
                {({
                  label,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
            </PrintoutTableRow>
            <PrintoutTableRow>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.name`}
                value={expense.name}
                oldValue={previousExpenseData?.name}
              >
                {({
                  fieldValue,
                }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.cost_category`}
                value={expense.cost_category}
                oldValue={previousExpenseData?.cost_category}
                dictionaryName={DICTIONARIES.costCategories.name}
                skipFilterDictionary
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.total_expenses`}
                value={numberWithSeparator(expense.total_expenses)}
                oldValue={numberWithSeparator(previousExpenseData?.total_expenses)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.eligible_expenses`}
                value={numberWithSeparator(expense.eligible_expenses)}
                oldValue={numberWithSeparator(previousExpenseData?.eligible_expenses)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.vat`}
                value={expense.vat}
                oldValue={previousExpenseData?.vat}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing`}
                value={numberWithSeparator(expense.co_financing)}
                oldValue={numberWithSeparator(previousExpenseData?.co_financing)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing_percent`}
                value={expense.co_financing_percent}
                oldValue={previousExpenseData?.co_financing_percent}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
            </PrintoutTableRow>
            {hasImplementers === BOOLEAN_VALUES.TRUE && ( // conditional display of 'Realizator'
              <RowField
                name={`${fieldNamePrefix}.${itemIndex}.implementer_id`}
                value={expense.implementer_id}
                oldValue={previousExpenseData?.implementer_id}
                externalDictionary={combinedApplicantName}
              />
            )}
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.subcontracts`}
              value={expense.subcontracts}
              oldValue={previousExpenseData?.subcontracts}
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.description_and_justification`}
              value={expense.description_and_justification}
              oldValue={previousExpenseData?.description_and_justification}
              isSingleRow
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.subcontracting`}
              value={expense.subcontracting}
              oldValue={previousExpenseData?.subcontracting}
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.estimation_methods`}
              value={expense.estimation_methods}
              oldValue={previousExpenseData?.estimation_methods}
              isSingleRow
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.digital_cost_category_id`}
              value={expense.digital_cost_category_id}
              oldValue={previousExpenseData?.digital_cost_category_id}
              dictionaryName={DICTIONARIES.digitalCostCategories.name}
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.spending_on_accessibility`}
              value={expense.spending_on_accessibility}
              oldValue={previousExpenseData?.spending_on_accessibility}
              dictionaryName={DICTIONARIES.cstLimitCategories.name}
            />
          </View>
        );
      })}
    </View>
  );
}

export default ActualExpensesTablePdf;

ActualExpensesTablePdf.propTypes = {
  elementId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  additionalStyles: PropTypes.instanceOf(Object),
};

ActualExpensesTablePdf.defaultProps = {
  additionalStyles: {},
};
