import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { MonetaryField } from '../../MonetaryField';
import { Alert } from '../../Alert';

/**
 * Applicant Export Activities
 *
 * @returns {ApplicantExportActivities}
 */
export function ApplicantExportActivities() {
  const secondClosedFinancialYear = 'applicant_export_activities_second_closed_financial_year';
  const firstClosedFinancialYear = 'applicant_export_activities_first_closed_financial_year';

  const { isReadonly } = useElementContext();

  const {
    setValue, getValues,
  } = useFormContext();

  const countApplicantIncomeSecondClosedFinancialYear = () => {
    const [incomeAmountExportActivitiesValue, incomeAmountValue] = getValues([
      'applicant_export_activities_second_closed_financial_year_applicant_income_amount_export_activities',
      'applicant_export_activities_second_closed_financial_year_applicant_income_amount',
    ]);
    if (incomeAmountValue) {
      setValue(
        `${secondClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`,
        Number(incomeAmountExportActivitiesValue) / Number(incomeAmountValue) * 100
      );
    }
  };

  const countExportOfProductRevenues = () => {
    const [incomeAmountFromProductsExportValue, incomeAmountExportActivitiesValue] = getValues([
      'applicant_export_activities_second_closed_financial_year_applicant_income_amount_from_products_export',
      'applicant_export_activities_second_closed_financial_year_applicant_income_amount_export_activities',
    ]);
    if (incomeAmountExportActivitiesValue) {
      setValue(
        `${secondClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`,
        Number(incomeAmountFromProductsExportValue) / Number(incomeAmountExportActivitiesValue) * 100
      );
    }
  };

  const countApplicantIncomeFirstClosedFinancialYear = () => {
    const [incomeAmountExportActivitiesValue, incomeAmountValue] = getValues([
      'applicant_export_activities_first_closed_financial_year_applicant_income_amount_export_activities',
      'applicant_export_activities_first_closed_financial_year_applicant_income_amount',
    ]);
    if (incomeAmountValue) {
      setValue(
        `${firstClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`,
        Number(incomeAmountExportActivitiesValue) / Number(incomeAmountValue) * 100
      );
    }
  };

  const countExportOfProductRevenuesFirstClosedFinancialYear = () => {
    const [incomeAmountFromProductsExportValue, incomeAmountExportActivitiesValue] = getValues([
      'applicant_export_activities_first_closed_financial_year_applicant_income_amount_from_products_export',
      'applicant_export_activities_first_closed_financial_year_applicant_income_amount_export_activities',
    ]);
    if (incomeAmountExportActivitiesValue) {
      setValue(
        `${firstClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`,
        Number(incomeAmountFromProductsExportValue) / Number(incomeAmountExportActivitiesValue) * 100
      );
    }
  };

  return (
    <FieldSorter>
      {/* 1.Liczba zamkniętych lat obrachunkowych, w trakcie których wnioskodawca prowadził działalność eksportową */}
      <FormField name="applicant_export_activities_number_closed_years">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <NumericFormat
            id={name}
            name={name}
            onValueChange={(values) => onChange({
              target: {
                name,
                value: values.floatValue,
              },
            })}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            error={!!error}
            helperText={error?.message}
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
          />
        )}
      </FormField>
      {/* 2.Opis dotychczasowego doświadczenia wnioskodawcy w prowadzeniu działalności eksportowej */}
      <FormField name="applicant_export_activities_applicant_previous_experience_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 3.Etykieta: Drugi zamknięty rok obrachunkowy poprzedzający rok złożenia wniosku o dofinansowanie */}
      <FormField name={secondClosedFinancialYear} contextHelpLabel>
        {() => null}
      </FormField>
      {/* 4.Kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności gospodarczej */}
      <FormField name={`${secondClosedFinancialYear}_applicant_income_amount`}>
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countApplicantIncomeSecondClosedFinancialYear();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 5.w tym kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności eksportowej */}
      <FormField name={`${secondClosedFinancialYear}_applicant_income_amount_export_activities`}>
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countApplicantIncomeSecondClosedFinancialYear();
              countExportOfProductRevenues();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 6.Udział (%) przychodów Wnioskodawcy z prowadzenia działalności eksportowej w stosunku do
      sumy przychodów osiągniętych z prowadzenia działalności gospodarczej */}
      <FormField
        name={`${secondClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`}
      >
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>

      {/* 7.w tym kwota przychodów z eksportu produktu/ów będących przedmiotem projektu */}
      <FormField
        name="applicant_export_activities_second_closed_financial_year_applicant_income_amount_from_products_export"
      >
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countExportOfProductRevenues();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 8.Udział (%) przychodów z eksportu produktu/ów będących przedmiotem projektu w stosunku do
      osiągniętej kwoty przychodów Wnioskodawcy z prowadzenia działalności eksportowej */}
      <FormField name={`${secondClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`}>
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>

      {/* 9.Etykieta: Zamknięty rok obrachunkowy poprzedzający rok złożenia wniosku o dofinansowanie */}
      <FormField name="applicant_export_activities_first_closed_financial_year" contextHelpLabel>
        {() => null}
      </FormField>
      {/* 10.Kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności gospodarczej */}
      <FormField name="applicant_export_activities_first_closed_financial_year_applicant_income_amount">
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countApplicantIncomeFirstClosedFinancialYear();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 11.w tym kwota przychodów Wnioskodawcy osiągniętych z prowadzenia działalności eksportowej */}
      <FormField
        name="applicant_export_activities_first_closed_financial_year_applicant_income_amount_export_activities"
      >
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countApplicantIncomeFirstClosedFinancialYear();
              countExportOfProductRevenuesFirstClosedFinancialYear();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 12.Udział (%) przychodów Wnioskodawcy z prowadzenia działalności eksportowej w stosunku do sumy
      przychodów osiągniętych z prowadzenia działalności gospodarczej */}
      <FormField
        name={`${firstClosedFinancialYear}_applicant_income_amount_export_activities_percent_participation`}
      >
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>

      {/* 13.w tym kwota przychodów z eksportu produktu/ów będących przedmiotem projektu */}
      <FormField
        name="applicant_export_activities_first_closed_financial_year_applicant_income_amount_from_products_export"
      >
        {({
          value, onChange, onBlur, error, name,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              countExportOfProductRevenuesFirstClosedFinancialYear();
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowedDecimalSeparators: ['.', ','],
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 14.Udział (%) przychodów z eksportu produktu/ów będących przedmiotem projektu w stosunku do
      osiągniętej kwoty przychodów Wnioskodawcy z prowadzenia działalności eksportowej */}
      <FormField
        name={`${firstClosedFinancialYear}_applicant_income_amount_from_products_export_percent_participation`}
      >
        {({ value }) => (
          <Alert severity="status">
            {value && Number(value).toFixed(2)}
          </Alert>
        )}
      </FormField>
      {/* Adres firmowej wizytówki na Portalu Promocji Eksportu */}
      <FormField name="applicant_export_activities_company_business_card_address">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
