import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * GreeningModuleEssence Pdf element.
 *
 * @returns {GreeningModuleEssencePdf}
 */
function GreeningModuleEssencePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const isGreeningModuleEssenceIsGreenfield = initialFormData?.greening_module_essence_is_greenfield
    === BOOLEAN_VALUES.TRUE;

  const excludedImplementationScope = [
    '/lsi/recruitments/api/implementation-scopes/5880f33c-71d0-4fed-84a7-0b68b1e6941c',
    '/lsi/recruitments/api/implementation-scopes/2ef0a13a-66d5-4b24-b7f0-76e639560072',
    '/lsi/recruitments/api/implementation-scopes/247997db-f427-49d1-89bb-cea07fc3c55e',
  ];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="greening_module_essence_module_purpose" />
        <CollectionWrapper name="greening_module_scope">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const greeningModuleScopeValue = item.implementation_scope;
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                  <RowField
                    name={`${collectionName}.${index}.implementation_scope`}
                    value={item.implementation_scope}
                    oldValue={previousRowData?.implementation_scope}
                    dictionaryName={DICTIONARIES.implementationScopes.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.action_justification`}
                    value={item.action_justification}
                    oldValue={previousRowData?.action_justification}
                  />
                  {!excludedImplementationScope.includes(greeningModuleScopeValue) && (
                    <>
                      <RowField
                        name={`${collectionName}.${index}.solution_innovation_level`}
                        value={item.solution_innovation_level}
                        oldValue={previousRowData?.solution_innovation_level}
                        dictionaryName={DICTIONARIES.solutionInnovationLevels.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.innovation_level_justification`}
                        value={item.innovation_level_justification}
                        oldValue={previousRowData?.innovation_level_justification}
                      />
                    </>
                  )}
                </FieldSorter>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        <RowField name="greening_module_essence_is_greenfield" />
        {isGreeningModuleEssenceIsGreenfield && (
          <RowField name="greening_module_essence_other_investment_comparison" />
        )}
      </FieldSorter>
    </View>
  );
}

export default GreeningModuleEssencePdf;
