export const ADMIN_DEPARTMENTS_IDS = [
  'cf205244-f279-41da-b81a-f15771b87ff7',
  '4e221fb4-db14-4d4a-a6a3-076c8db66b6d',
  '18c2f343-4477-4c18-ba47-3f412e5d6a81',
  '22c283a0-fd00-49db-b1f3-748fc7c4d72a',
  'ae1485a0-a528-486a-ac47-09b3df2df66d',
  'acb38d23-9b2f-486d-add6-227f7a63664f',
  '708fffbc-3be8-4cbe-b3a0-f614dd4ad623',
  'fcb20f12-c9fd-426b-bd86-431a5ddf2fb3',
  '325a40d0-2949-4d5d-91a3-80fc0bfd2339',
  'acb5130c-378d-4be5-8df9-cc878ffdd709',
  '92485fd1-f079-4230-956b-984763d8bde0',
  'bb6646cc-43ea-4f7a-b70f-0d60b33cbbb9',
];
