import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';

/**
 * Human Resources Innovation Implementation Pdf element.
 *
 * @returns {HumanResourcesInnovationImplementationPdf}
 */
function HumanResourcesInnovationImplementationPdf() {
  return (
    <View style={printStyle.table}>
      <RowField name="human_resources_innovation_implementation_description_of_human_resources" />
    </View>
  );
}

export default HumanResourcesInnovationImplementationPdf;
