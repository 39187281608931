import { ACTION_TYPES } from '../_constants/types/actionTypes';

/**
 * Thunk dispatch functions.
 */
export const THUNK_FUNC = {
  /**
   * Context dispatch data for RESOURCE_FETCH_START.
   *
   * @param {string} requestName - request name
   * @returns {object} - context dispatch data
   */
  fetchStart: (requestName) => ({
    type: ACTION_TYPES.RESOURCE_FETCH_START,
    name: requestName,
  }),

  /**
   * Context dispatch data for RESOURCE_FETCH_FINISH.
   *
   * @param {string} requestName - request name
   * @returns {object} - context dispatch data
   */
  fetchFinish: (requestName) => ({
    type: ACTION_TYPES.RESOURCE_FETCH_FINISH,
    name: requestName,
  }),

  /**
   * Context dispatch data for DICTIONARY_POPULATE.
   *
   * @param {string} name - dictionary name
   * @param {Array} values - api response payload
   * @returns {object} - context dispatch data
   */
  populateDictionary: (name, values) => ({
    type: ACTION_TYPES.DICTIONARY_POPULATE,
    name,
    values,
  }),

  /**
   * Context dispatch to prevent dictionary being loaded multiple times.
   *
   * @param {string} name - dictionary name
   * @returns {object} - context dispatch data
   */
  preloadDictionary: (name) => ({
    type: ACTION_TYPES.DICTIONARY_POPULATE,
    name,
    values: [],
  }),

  /**
   * Context dispatch to increment dictionary load queue count.
   *
   * @returns {object} - context dispatch data
   */
  dictionaryLoadQueueAdd: () => ({
    type: ACTION_TYPES.DICTIONARY_LOAD_QUEUE_ADD,
  }),

  /**
   * Context dispatch to decrement dictionary load queue count.
   *
   * @returns {object} - context dispatch data
   */
  dictionaryLoadQueueSubtract: () => ({
    type: ACTION_TYPES.DICTIONARY_LOAD_QUEUE_SUBTRACT,
  }),

  /**
   * Context dispatch to open global notification.
   *
   * @param {string} message - notification message
   * @param {string} severity - alert severity
   * @returns {object} - context dispatch data
   */
  notification: (message, severity = 'info') => ({
    type: ACTION_TYPES.GLOBAL_NOTIFICATION,
    title: message,
    severity,
  }),
};
