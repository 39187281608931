import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../../Context';
import CharsCounter from '../../../CharsCounter';
import { FormField } from '../../../Application/Application/FormField';
import CollectionFieldContainer from '../../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../../_helpers';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';

/**
 * AuditsExperience component
 *
 * @returns {AuditsExperience}
 */
export default function AuditsExperience() {
  const {
    control, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'project_implementation_justification_goz_audits_experience',
  });

  const auditsExperienceConfig = fieldsConfig.project_implementation_justification_goz_audits_experience;
  const subfields = auditsExperienceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = auditsExperienceConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <>
      {fields.map((field, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            'project_implementation_justification_goz_audits_experience',
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={field.id}
          isIndexed
        >
          {/* Nazwa firmy, w której przeprowadzony był audyt GOZ */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_company_name`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Termin przeprowadzenia audytu GOZ – data od */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_start_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Termin przeprowadzenia audytu GOZ – data do */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_end_date`}>
            {({
              onChange, value, name, label, onBlur, error,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Branża firmy, w której przeprowadzony był audyt */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_company_industry`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Zakres audytu */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_scope`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Rezultaty audytu */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_results`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Doświadczenie we wdrożeniu wyników audytu z zakresu GOZ */}
          <FormField name={`project_implementation_justification_goz_audits_experience.${index}.goz_audit_results_implementation_experience`}>
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}
      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="eBBFy5f2zxTbjHu"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </>
  );
}
