import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';

/**
 * PrintoutOpener element.
 *
 * @param {object} props - root props
 * @param {string} props.url - pdf url
 * @param {Function} props.backdrop - backdrop setter
 * @param {Node} props.children - children element
 * @param {Function} props.resetData - reset data
 * @returns {PrintoutOpener}
 */
function PrintoutOpener({
  url, children, backdrop, resetData,
}) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.onload = () => {
          setIsLoading(false);
          resetData();
        };

        return;
      }
      setIsLoading(false);
      resetData();
      // eslint-disable-next-line no-console
      console.warn('Unsuccessful printout window opening');
    }
    setIsLoading(false);
  }, [url]);

  if (children) {
    return children;
  }

  return isLoading ? backdrop(isLoading) : null;
}

export default PrintoutOpener;

PrintoutOpener.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  backdrop: PropTypes.func.isRequired,
  resetData: PropTypes.func,
};

PrintoutOpener.defaultProps = {
  url: null,
  children: null,
  resetData: () => {},
};
