export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_REPORT_ADMIN: 'ROLE_REPORT_ADMIN',
  ROLE_REPORT_GENERAL: 'ROLE_REPORT_GENERAL',
  ROLE_REPORT_SPECIFIC: 'ROLE_REPORT_SPECIFIC',
  ROLE_USER: 'ROLE_USER',
  ROLE_EMPLOYEE: 'ROLE_EMPLOYEE',
  ROLE_APPLICANT: 'ROLE_APPLICANT',
  ROLE_EXPERT: 'ROLE_EXPERT',
  ROLE_EXPERT_PREVIEW: 'ROLE_EXPERT_PREVIEW',
  ROLE_EXPERT_EDIT: 'ROLE_EXPERT_EDIT',
  ROLE_SECRETARY: 'ROLE_SECRETARY',
  ROLE_RECRUITMENT: 'ROLE_RECRUITMENT',
  ROLE_RECRUITMENT_PREVIEW: 'ROLE_RECRUITMENT_PREVIEW',
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_MANAGEMENT_INSTITUTION: 'ROLE_MANAGEMENT_INSTITUTION',
  ROLE_RECOMMENDATIONS_SUPERVISOR: 'ROLE_RECOMMENDATIONS_SUPERVISOR',
  ROLE_RECOMMENDATIONS_DFK: 'ROLE_RECOMMENDATIONS_DFK',
};
