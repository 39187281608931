import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';

/**
 * SupportSoFarReceived Pdf element.
 *
 * @returns {SupportSoFarReceivedPdf}
 */
function SupportSoFarReceivedPdf() {
  return (
    <View style={printStyle.table}>
      {/* Pomoc na udział MSP w targach w roku bieżącym [EUR] */}
      <RowField name="support_so_far_received_participation_aid_sme" />
    </View>
  );
}

export default SupportSoFarReceivedPdf;
