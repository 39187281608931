import {
  PRINT_METHODS,
  PRINT_METHODS_TRANSLATIONS,
} from '../_constants';

/**
 * Print methods values for CustomAutocomplete component.
 *
 * @type {Array}
 */
export const printMethodsOptions = [
  {
    id: PRINT_METHODS.client,
    label: PRINT_METHODS_TRANSLATIONS.client,
  },
  {
    id: PRINT_METHODS.server,
    label: PRINT_METHODS_TRANSLATIONS.server,
  },
];
