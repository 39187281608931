import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import { useApplicationToPdfContext } from '../../../../../Context';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';

/**
 * Competences Module Essence Forms Pdf
 *
 * @returns {CompetencesModuleEssenceFormsPdf}
 */
function CompetencesModuleEssenceFormsPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  return (
    <CollectionWrapper name="competences_module_essence_forms">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <RowField
              name={`${collectionName}.${index}.support_type`}
              value={item.support_type}
              oldValue={previousRowData?.support_type}
              dictionaryName={DICTIONARIES.compentenceModuleEssenceType.name}
            />
            <RowField
              name={`${collectionName}.${index}.support_scope`}
              value={item.support_scope}
              oldValue={previousRowData?.support_scope}
            />
            <RowField
              name={`${collectionName}.${index}.implementer`}
              value={item.implementer}
              oldValue={previousRowData?.implementer}
              externalDictionary={combinedApplicantName}
            />
            <RowField
              name={`${collectionName}.${index}.target_group`}
              value={item.target_group}
              oldValue={previousRowData?.target_group}
              dictionaryName={DICTIONARIES.compentenceModuleEssenceTargetGroup.name}
            />
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default CompetencesModuleEssenceFormsPdf;
