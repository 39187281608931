import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  CHARS_LIMIT,
  API_ROUTE,
} from '../../_constants';
import CharsCounter from '../../Features/CharsCounter';
import { CustomAutocomplete } from '../../Features/CustomAutocomplete/CustomAutocomplete';
import { request } from '../../_services';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';

/**
 * Operators configurator form component.
 *
 * @returns {OperatorsConfiguratorForm}
 */
export default function OperatorsConfiguratorForm() {
  const [operators, setOperators] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const { control } = useFormContext();

  useEffect(() => {
    /**
     * Loads api data.
     */
    const loadApiData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(`${API_ROUTE.operators}?itemsPerPage=100`);

      if (statusSuccess) {
        setOperators(payload);
      }
      setLoaded(true);
    };

    loadApiData();
  }, []);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  const classes = {
    customAutocomplete: {
      '&.MuiFormControl-root .MuiChip-root': {
        opacity: 12,
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="formName">
          Nazwa formularza
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <Controller
            name="formName"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                variant="outlined"
                defaultValue={value}
                error={!!error}
                helperText={error?.message}
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} /> }}
                inputProps={{ maxLength: CHARS_LIMIT.LONG }}
                onChange={onChange}
                onBlur={onBlur}
                required
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="instruction">
          Instrukcja
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <Controller
            name="instruction"
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <CKEditor
                  id={name}
                  editor={ClassicEditor}
                  config={{
                    language: 'pl',
                    link: {
                      addTargetToExternalLinks: true,
                    },
                    removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
                  }}
                  data={value}
                  onChange={(_event, editor) => {
                    onChange({
                      target: {
                        name,
                        value: editor.getData(),
                      },
                    });
                  }}
                />
                {!!error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="operatorLabel">
          Etykieta operatora
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <Controller
            name="operatorLabel"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                variant="outlined"
                defaultValue={value}
                error={!!error}
                helperText={error?.message}
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} /> }}
                inputProps={{ maxLength: CHARS_LIMIT.LONG }}
                onChange={onChange}
                onBlur={onBlur}
                required
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="operators">
          Operatorzy
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl
          variant="outlined"
          fullWidth
          sx={classes.customAutocomplete}
        >
          <Controller
            name="operators"
            control={control}
            render={({
              field: {
                onChange,
                value,
                name,
              },
              fieldState: { error },
            }) => (
              <CustomAutocomplete
                multiple
                id={name}
                options={operators}
                renderSelectAllButton={false}
                initialValue={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </FormControl>
      </Grid>

    </Grid>
  );
}
