import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';

/**
 * TeamImplementingProgramConcept element renderer.
 *
 * @returns {TeamImplementingProgramConcept}
 */
export function TeamImplementingProgramConcept() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    watch, setValue, resetField,
  } = useFormContext();
  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.teamImplementingProgramConceptResponsibilityAreas,
  );
  const collectionName = 'team_implementing_program_concept_experience';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const keyMembersOfTeamExperienceConfig = fieldsConfig[collectionName];
  const subfields = keyMembersOfTeamExperienceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = keyMembersOfTeamExperienceConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Struktura organizacyjna zespołu realizującego koncepcję programu */}
      <FormField name="team_implementing_program_concept_organizational_structure_team">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Doświadczenie i kompetencje kluczowych członków zespołu realizującego koncepcję
       programu, w tym ekspertów oceniających pomysły biznesowe" */}
      <LabeledFieldRow fieldConfig={keyMembersOfTeamExperienceConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => {
          const isBusinessVenturesDevelopment = watch(`${collectionName}.${index}.innovative_business_ventures_development`)
            === BOOLEAN_VALUES.TRUE;
          const isCreatingNewTechnology = watch(`${collectionName}.${index}.creating_new_technology`) === BOOLEAN_VALUES.TRUE;
          const isInvestmentProcessesRelations = watch(`${collectionName}.${index}.investment_processes_relations`)
            === BOOLEAN_VALUES.TRUE;

          return (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                collectionName,
                index,
              )}
              itemIndex={index}
              isReadonly={isReadonly}
              fieldsOrder={Object.keys(subfields)}
              buttonRemoveVisible={fields.length > minRowNumber}
              key={field.id}
              isIndexed
            >
              {/*  Imię i nazwisko */}
              <FormField name={`${collectionName}.${index}.first_and_last_name`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Wykształcenie */}
              <FormField name={`${collectionName}.${index}.education`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Nazwa stanowiska/ funkcji */}
              <FormField name={`${collectionName}.${index}.job_title`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Opis doświadczenia związanego z pełnioną funkcją w projekcie */}
              <FormField name={`${collectionName}.${index}.experience_description`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Etykieta:Doświadczenie w zakresie */}
              <FormField name={`${collectionName}.${index}.field_experience`} contextHelpLabel>
                {() => null}
              </FormField>
              {/* Rozwoju innowacyjnych przedsięwzięć biznesowych */}
              <FormField name={`${collectionName}.${index}.innovative_business_ventures_development`}>
                {({
                  name, onChange, value, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                        resetField(`${collectionName}.${index}.innovative_business_ventures_development_activities_examples`, { defaultValue: FIELD_TYPES_MAP.text });
                      }
                    }}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Przykłady przedsięwzięć/ działań potwierdzających wskazane doświadczenie */}
              {isBusinessVenturesDevelopment && (
                <FormField name={`${collectionName}.${index}.innovative_business_ventures_development_activities_examples`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
              {/* Tworzeniu lub komercjalizacji nowych technologii */}
              <FormField name={`${collectionName}.${index}.creating_new_technology`}>
                {({
                  name, onChange, value, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                        resetField(`${collectionName}.${index}.creating_new_technology_activities_examples`, { defaultValue: FIELD_TYPES_MAP.text });
                      }
                    }}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Przykłady przedsięwzięć/ działań potwierdzających wykazane doświadczenie */}
              {isCreatingNewTechnology && (
                <FormField name={`${collectionName}.${index}.creating_new_technology_activities_examples`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
              {/* Relacji procesów inwestycyjnych venture capital */}
              <FormField name={`${collectionName}.${index}.investment_processes_relations`}>
                {({
                  name, onChange, value, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                        resetField(`${collectionName}.${index}.investment_processes_relations_activities_examples`, { defaultValue: FIELD_TYPES_MAP.text });
                      }
                    }}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Przykłady przedsięwzięć/ działań potwierdzających wykazane doświadczenie */}
              {isInvestmentProcessesRelations && (
                <FormField name={`${collectionName}.${index}.investment_processes_relations_activities_examples`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
              {/* Zakres zadań w projekcie */}
              <FormField name={`${collectionName}.${index}.project_tasks_scope`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Obszar odpowiedzialności */}
              <FormField name={`${collectionName}.${index}.responsibility_areas`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.teamImplementingProgramConceptResponsibilityAreas.name))}
                    disabled={isReadonly}
                    error={error}
                    multiple
                    renderSelectAllButton={false}
                  />
                )}
              </FormField>
            </CollectionFieldContainer>
          );
        })}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="3mY14Dm7HGFy5nB"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
