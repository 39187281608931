import {
  Box,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useCustomSnackbar } from '../../_hooks';
import { EmptyDatasetRow } from '../../Features';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { trim } from '../../_helpers';

/**
 * ModulesSelectionList component
 *
 * @param {object} props root props
 * @param {Array} props.modulesSelections modules selections of application template
 * @param {Function} props.selectHandler id of selected object will be passed to this function
 * @param {Function} props.onRemoveSuccess function called after successfull delete
 * @returns {ModulesSelectionList} modules selections list
 */
export function ModulesSelectionList({
  modulesSelections,
  selectHandler,
  onRemoveSuccess,
}) {
  const { errorNotification } = useCustomSnackbar();

  /**
   * Handle Modules Selection delete.
   *
   * @param {string} modulesSelectionId modules selection id
   */
  const handleDelete = async (modulesSelectionId) => {
    const { statusSuccess } = await request.delete(
      `${API_ROUTE.modulesSelections}/${modulesSelectionId}`
    );

    if (statusSuccess) {
      onRemoveSuccess();
      errorNotification(`Usunięto konfigurację ID: ${modulesSelectionId}`);
    }
  };

  return (
    <TableContainer>
      <Typography variant="pageHeading" textAlign="center">
        Lista konfiguracji
      </Typography>
      <Box display="flex" justifyContent="flex-end" mt={-6}>
        <Button
          id="NZTZ6q76fhFX3Cq"
          color="success"
          variant="contained"
          aria-label="Dodaj moduł"
          onClick={() => selectHandler({})}
        >
          Dodaj moduł
        </Button>
      </Box>
      <Table
        aria-label="Lista konfiguracji"
        aria-colcount={5}
        aria-rowcount={modulesSelections.length}
      >
        <TableHead>
          <TableRow>
            <TableCell width="26%" scope="column">Instrukcja</TableCell>
            <TableCell width="10%" scope="column">Kroki</TableCell>
            <TableCell width="26%" scope="column">Wybrane moduły obligatoryjne</TableCell>
            <TableCell width="26%" scope="column">Wybrane moduły fakultatywne</TableCell>
            <TableCell width="12%" scope="column">Akcje</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modulesSelections.length === 0 && (
            <EmptyDatasetRow />
          )}
          {modulesSelections.map((modulesSelection) => (
            <TableRow key={`row-${modulesSelection.id}`}>
              <TableCell>
                {trim(modulesSelection.description)}
              </TableCell>
              <TableCell>
                {modulesSelection.groups.join(', ')}
              </TableCell>
              <TableCell>
                {modulesSelection.obligatoryModules.map(({ name }) => name)
                  .join(', ')}
              </TableCell>
              <TableCell>
                {modulesSelection.facultativeModules.map(({ name }) => name)
                  .join(', ')}
              </TableCell>
              <TableCell>
                <ButtonGroup orientation="vertical">
                  <Box mb={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      id={uniqid()}
                      onClick={() => selectHandler(modulesSelection)}
                    >
                      Edytuj
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    id={uniqid()}
                    aria-label="Usuń konfigurację modułów"
                    onClick={() => handleDelete(modulesSelection.id)}
                  >
                    Usuń
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ModulesSelectionList.propTypes = {
  modulesSelections: PropTypes.arrayOf(Object),
  selectHandler: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
};

ModulesSelectionList.defaultProps = {
  modulesSelections: [],
};
