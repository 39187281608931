import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

/**
 * Captcha component.
 *
 * @param {Function} onChange - function invoked on verification event
 * @returns {Captcha}
 */
export function Captcha({
  onChange,
}) {
  const captchaRef = useRef(null);

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_SITE_KEY}
      onChange={onChange}
      ref={captchaRef}
    />
  );
}

Captcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};
