import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * InnovationDescription pdf element.
 *
 * @returns {InnovationDescriptionPdf}
 */
function InnovationDescriptionPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Czy projekt dotyczy innowacji produktowej (produktu ) na poziomie kraju? */}
        <RowField name="innovation_description_product_innovation_at_national_level" />
        {/* Czy projekt dotyczy innowacji produktowej na poziomie powyżej kraju? */}
        <RowField name="innovation_description_product_innovation_above_national_level" />
        {/* Czy innowacja produktowa dotyczy wyrobu? */}
        <RowField name="innovation_description_product_innovation_involves_choice" />
        {/* Czy innowacja produktowa dotyczy usługi? */}
        <RowField name="innovation_description_product_innovation_involves_service" />
        {/* Innowacja produktowa uwzględnia znaczące udoskonalenia w zakresie cech i zastosowań
      co najmniej pod względem */}
        <RowField
          name="product_innovation_features_and_applications_improvements"
          dictionaryName={DICTIONARIES.productInnovationImprovement.name}
        />
        {/* Nazwa produktu */}
        <RowField name="innovation_description_product_name" />
        {/* Opis innowacji produktowej wnioskodawcy */}
        <RowField name="innovation_description_applicant_product_innovation" isSingleRow />
        {/* Oferta konkurencji */}
        <RowField name="innovation_description_competitor_offerings" isSingleRow />
        {/* Nowe lub znacząco udoskonalone cechy i funkcjonalności produktu przesądzające o innowacyjności produktu */}
        <RowField name="innovation_description_innovative_product_features" isSingleRow />
        {/* Badanie innowacji produktowej (jeśli dotyczy) */}
        <RowField name="innovation_description_product_innovation_research" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default InnovationDescriptionPdf;
