import { useFormContext } from 'react-hook-form';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Digital innovation application element.
 *
 * @returns {DigitalInnovation}
 */
function DigitalInnovation() {
  const { isReadonly } = useElementContext();
  const {
    watch, resetField,
  } = useFormContext();
  const { application } = useApplicationContext();
  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  const isDigitalInnovationImplementation = watch('digital_innovation_is_implementation_digital_innovation')
    === BOOLEAN_VALUES.TRUE;

  return (
    <FieldSorter>
      {/* Czy projekt dotyczy opracowania innowacji cyfrowej na poziomie kraju? */}
      <FormField name="digital_innovation_is_developing_digital_innovation">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'digital_innovation_implementation_digital_innovation_module',
                  { defaultValue: FIELD_TYPES_MAP.limited_choice }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            renderSelectAllButton={false}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Czy projekt dotyczy wdrożenia innowacji cyfrowej na poziomie kraju? */}
      <FormField name="digital_innovation_is_implementation_digital_innovation">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            renderSelectAllButton={false}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Moduł z wdrożeniem innowacji cyfrowej na poziomie kraju */}
      {isDigitalInnovationImplementation && (
        <FormField name="digital_innovation_implementation_digital_innovation_module">
          {({
            onChange, value, name, filterOptions, onBlur, error,
          }) => (
            <CustomAutocomplete
              id={name}
              initialValue={value}
              onChange={onChange}
              onBlur={onBlur}
              options={filterOptions(modules)}
              disabled={isReadonly}
              multiple
              renderSelectAllButton={false}
              error={error}
            />
          )}
        </FormField>
      )}
    </FieldSorter>
  );
}

export default DigitalInnovation;
