import PropTypes from 'prop-types';
import {
  InputLabel,
  TextField,
  Box,
  FormControl,
} from '@mui/material';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useCustomSnackbar } from '../../../_hooks';
import CharsCounter from '../../CharsCounter';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { formatDateString } from '../../../_helpers';
import { Alert } from '../../Alert';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { useGlobalDialog } from '../../../Context';

/**
 * ApplicationRejectionDialog
 *
 * @param {object} props - root props
 * @param {object} props.payload - payload
 * @param {string} props.expertPanelExpertsId - expert panel experts id
 * @param {Function} props.reloadData - reload data
 * @param {boolean} props.isReadonly - is readonly
 * @returns {ApplicationRejectionDialog}
 */
export function ApplicationRejectionDialog({
  payload, expertPanelExpertsId, reloadData, isReadonly,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { closeAll } = useGlobalDialog();
  const {
    control, handleSubmit, formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      refusalRemarks: '',
      refused: true,
    },
    mode: 'all',
  });

  const onSubmit = async (values) => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertPanelExpertByUsers}/${expertPanelExpertsId}`, {
      ...payload,
      ...values,
    });

    closeAll();
    reloadData();

    statusSuccess
      ? successNotification('Wniosek został odrzucony pomyślnie.')
      : errorNotification('Nie udało się odrzucić wniosku. Spróbuj ponownie.');
  };

  return (
    <>
      <InputLabel htmlFor="refusalRemarks">Powód odrzucenia wniosku</InputLabel>
      <FormControl variant="outlined" fullWidth margin="normal">
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Powów odrzucenia wniosku"',
            },
          }}
          name="refusalRemarks"
          control={control}
          render={({
            field: {
              onChange, value, name,
              onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              onBlur={onBlur}
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={1000} />),
              }}
              inputProps={{
                maxLength: 1000,
              }}
              multiline
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="rejectionDate">Data odrzucenia</InputLabel>
      <Alert severity="status" sx={{ mt: (theme) => theme.spacing(1) }}>
        {formatDateString(new Date())}
      </Alert>
      <Box mt={3} display="flex" justifyContent="center">
        <SaveCancelButtons
          saveButtonId="HAc7AxDr2FcIsRK"
          cancelButtonId="k7YFXU4afhwefhC"
          cancelHandler={closeAll}
          saveHandler={handleSubmit(onSubmit)}
          saveTitle="Odrzucam wniosek"
          saveDisabled={isSubmitting || isReadonly}
          cancelDisabled={isSubmitting || isReadonly}
        />
      </Box>
    </>
  );
}
ApplicationRejectionDialog.propTypes = {
  payload: PropTypes.objectOf(Object).isRequired,
  expertPanelExpertsId: PropTypes.string.isRequired,
  reloadData: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
};
