import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import WorksTypePdf from './InnovationImplementationModuleEssence/WorksType.pdf';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import RdWorkImplementersPdf from './InnovationImplementationModuleEssence/RdWorkImplementers.pdf';
import InnovationImplementationPdf from './InnovationImplementationModuleEssence/InnovationImplementation.pdf';
import MarketDemandAndCostEffectivenessImplementationPdf
  from './InnovationImplementationModuleEssence/MarketDemandAndCostEffectivenessImplementation.pdf';

/**
 * Innovation Implementation Module Essence Pdf element.
 *
 * @returns {InnovationImplementationModuleEssencePdf}
 */
function InnovationImplementationModuleEssencePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const resultsInRdModuleValue = initialFormData?.innovation_implementation_module_essence_results_in_rd_module;
  const isResultsInRdModule = resultsInRdModuleValue === BOOLEAN_VALUES.FALSE;

  return (
    <>
      <View style={printStyle.table}>
        {/* Cel modułu */}
        <RowField name="innovation_implementation_module_essence_purpose" />
        {/* Prace będące wynikiem modułu B+R */}
        <RowField name="innovation_implementation_module_essence_results_in_rd_module" />
      </View>
      <View style={printStyle.table}>
        <RowField
          name="innovation_implementation_module_essence_innovation_type"
          dictionaryName={DICTIONARIES.innovationTypes.name}
        />
      </View>
      {isResultsInRdModule && (
        <>
          <InnovationImplementationPdf />
          <MarketDemandAndCostEffectivenessImplementationPdf />
          {/* Tabela Rodzaj prac */}
          <WorksTypePdf />
        </>
      )}
      <View style={printStyle.table}>
        <RowField
          name="innovation_implementation_module_essence_intellectual_property_rights_regulation_method"
          isSingleRow
        />
        <RowField name="innovation_implementation_module_essence_intellectual_property_rights" isSingleRow />
        <RowField name="innovation_implementation_module_essence_intellectual_property_right_protection_subject" />
        <RowField name="innovation_implementation_module_essence_regulating_rights_method_description" />
      </View>
      {/* Tabela Wykonawcy/sprzedawcy prac badawczo-rozwojowych zleconych / zakupionych przez wnioskodawcę */}
      <RdWorkImplementersPdf />
    </>
  );
}

export default InnovationImplementationModuleEssencePdf;
