import {
  Button,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import {
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { MenuTabRow } from '../../Features/ApplicationTemplate/Edit/MenuTabRow';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { useCustomSnackbar } from '../../_hooks';
import { CustomAutocomplete } from '../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * Menu tabs form
 *
 * @param {object} props - root props
 * @param {Array} props.menuTabs - menu tabs
 * @param {Array} props.elementsDictionary - elements list
 * @param {Array} props.templateMenuTabOptions - template menu tab options
 * @param {Function} props.closeHandler - handle close dialog
 * @param {string} props.applicationTemplateId - application template ID
 * @returns {MenuTabsForm}
 */
function MenuTabsForm({
  menuTabs, elementsDictionary, templateMenuTabOptions, closeHandler, applicationTemplateId,
}) {
  const templateMenuTabRef = useRef(null);
  const { errorNotification } = useCustomSnackbar();

  const form = useForm({
    defaultValues: {
      menuTabs,
      templateMenuTab: null,
    },
    mode: 'all',
  });

  const {
    control, getValues, setValue, handleSubmit, setError, formState: { isSubmitting }, watch,
  } = form;

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'menuTabs',
    keyName: 'key',
  });

  /**
   * Add menu tab handler.
   */
  const addMenuTab = () => {
    if (!getValues('templateMenuTab')) {
      setError('templateMenuTab', {
        type: 'manual',
        message: 'To pole nie może być puste.',
      });

      return;
    }

    append({
      id: `newMenuTab${fields.length}`,
      templateMenuTab: templateMenuTabOptions.find((option) => option['@id'] === getValues('templateMenuTab')) || {},
      templateElements: [],
      ordinalNumber: fields.length + 1,
    });

    templateMenuTabRef.current.clear();
  };

  /**
   * Save menu tabs handler.
   *
   * @param {object} values - data form
   */
  const saveMenuTabs = async (values) => {
    const elementsToResolve = values.menuTabs.map((menuTab) => {
      const isNewElement = menuTab.id.substring(0, 3) === 'new';

      return isNewElement
        ? request.post(API_ROUTE.menuTabs, {
          applicationTemplate: `/lsi/recruitments/api/application-templates/${applicationTemplateId}`,
          templateMenuTab: menuTab.templateMenuTab['@id'],
          ordinalNumber: menuTab.ordinalNumber,
          templateElements: menuTab.templateElements.map((templateElement) => templateElement['@id']),
        })
        : request.put(`${API_ROUTE.menuTabs}/${menuTab.id}`, {
          ordinalNumber: menuTab.ordinalNumber,
          templateElements: menuTab.templateElements.map((templateElement) => templateElement['@id']),
        });
    });

    const responses = await Promise.all(elementsToResolve);

    closeHandler();

    values.menuTabs.forEach((menuTab, index) => {
      if (!responses[index].statusSuccess) {
        errorNotification(`Nie udało się zaaktualizować pozycji menu - ${menuTab.templateMenuTab.name}`);
      }
    });
  };

  const handleDrag = (event) => {
    const {
      destination,
      source,
    } = event;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId
      && destination.index === source.index) {
      return;
    }

    const tabs = [...fields];
    tabs.splice(
      event.destination.index,
      0,
      tabs.splice(event.source.index, 1)[0]
    );

    setValue('menuTabs', tabs.map((tab, index) => ({
      ...tab,
      ordinalNumber: index + 1,
    })));
  };

  const getFilteredMenuOptions = () => templateMenuTabOptions.filter((templateMenuTabOption) => fields
    .every((menuTab) => menuTab.templateMenuTab.id !== templateMenuTabOption.id));

  const filteredElementsDictionary = useCallback((index) => {
    const options = elementsDictionary.filter((elementDictionary) => {
      const filteredOptions = [];
      getValues('menuTabs').forEach((field) => filteredOptions.push(...field.templateElements));

      return filteredOptions.every((filteredOption) => filteredOption.id !== elementDictionary.id);
    }).map(({
      id, label, value,
    }) => ({
      id,
      label,
      '@id': value,
    }));

    return [...options, ...getValues(`menuTabs.${index}.templateElements`)];
  }, [watch('menuTabs')]);

  const removeMenuTab = (index) => {
    remove(index);

    setValue('menuTabs', fields
      .filter((_, filterIndex) => filterIndex !== index)
      .map((field, currentIndex) => ({
        ...field,
        ordinalNumber: currentIndex + 1,
      })));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="templateMenuTab"
            control={control}
            render={({
              field: {
                onChange,
                value,
                name,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                ref={templateMenuTabRef}
                id={name}
                options={getFilteredMenuOptions()}
                initialValue={value}
                renderSelectAllButton={false}
                onChange={onChange}
                textFieldProps={{
                  error: !!error,
                  name,
                  label: 'Dodaj pozycję menu',
                }}
                error={error}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2} justifyContent="center" alignItems="center" display="flex">
        <Button
          id="O99U0Fb9Qje12tL"
          onClick={addMenuTab}
          variant="contained"
          color="success"
        >
          Dodaj
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bolder" fontSize="larger" mt={2}>Wybrane pozycje</Typography>
        <FormProvider {...form}>
          <TableContainer>
            <Table aria-label="Lista zakładek menu">
              <TableHead>
                <TableRow>
                  <TableCell width="30%" scope="column">Menu</TableCell>
                  <TableCell width="60%" scope="column">Elementy wniosku</TableCell>
                  <TableCell width="10%" scope="column">Usuń</TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDrag}>
                <Droppable droppableId="menu-tabs-droppable">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      aria-label="Lista dodanych elementów"
                    >
                      {fields.map((menuTab: Object, index: number) => (
                        <MenuTabRow
                          menuTabData={menuTab}
                          index={index}
                          key={`menu-tab-row-${menuTab.id}`}
                          elementsDictionary={filteredElementsDictionary(index)}
                          removeItem={removeMenuTab}
                          menuTabs={fields}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </FormProvider>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center">
        <SaveCancelButtons
          cancelHandler={closeHandler}
          cancelButtonId="8mO2NzPfbk8IPHc"
          cancelDisabled={isSubmitting}
          saveHandler={handleSubmit(saveMenuTabs)}
          saveButtonId="2Ez5cR7FV6YqxEH"
          saveDisabled={isSubmitting}
        />
      </Grid>
    </Grid>
  );
}

export default MenuTabsForm;

MenuTabsForm.propTypes = {
  menuTabs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  elementsDictionary: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  templateMenuTabOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  closeHandler: PropTypes.func.isRequired,
  applicationTemplateId: PropTypes.string.isRequired,
};
