import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import {
  Fade,
  Grid,
  LinearProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import { VerticalTabs } from '../../Features/VerticalTabs';
import {
  Career,
  Education,
  Address,
  PersonalData,
  LanguageSkills,
  AdditionalActivity,
  PersonalDataAttachments,
} from '../../Features/Expert';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import {
  ExpertPersonalDataContext,
  useExpertApplicationFormContext,
  useGlobalContext,
  useReloadListener,
} from '../../Context';
import { useAuth } from '../../_security';

/**
 * Data to application page
 *
 * @param {object} props - root props
 * @param {Function} props.closeHandler - function to close modal
 * @param {boolean} props.expertId - expert id
 * @param {boolean} props.isReadonly - is read only
 * @returns {ExpertApplicationData}
 */
function ExpertApplicationData({
  closeHandler, isReadonly, expertId,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: userId } = useAuth();
  const {
    isApplicationFormStatusNotInEditionAndInCorrection, applicationFormData,
  } = useExpertApplicationFormContext();
  const [value, setValue] = useState(0);
  const [hashTriggered, setHashTriggered] = useState(false);
  const [personalDataCreated, setPersonalDataCreated] = useState(false);
  const [expertPersonalData, setExpertPersonalData] = useState(null);
  const [hasAttachmentsProperty, setHasAttachmentsProperty] = useState(false);
  const { notify } = useGlobalContext();
  const {
    reload, watch,
  } = useReloadListener();
  const watcherName = 'application-form-data';

  const expertPersonalDataId = applicationFormData?.expertPersonalData?.owner?.id;

  useEffect(() => {
    /**
     * Creates ExpertPersonalData resource.
     */
    const createExpertPersonalData = async () => {
      if (personalDataCreated) {
        return;
      }

      const {
        statusSuccess,
      } = await request.post(API_ROUTE.expertPersonalData, {});

      if (!statusSuccess) {
        navigate('/');
        notify('Wystąpił błąd w trakcie tworzenia zasobu.', 'error');
      }

      if (statusSuccess) {
        setPersonalDataCreated(true);
      }
    };

    /**
     * Loads ExpertPersonalData resource from API.
     */
    const loadExpertPersonalData = async () => {
      if (isApplicationFormStatusNotInEditionAndInCorrection) {
        loadSubmittedPersonalData();

        return;
      }

      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.users}/${expertPersonalDataId || expertId || userId}/expert-personal-data`);
      if (!statusSuccess && (!expertId || !expertPersonalDataId)) {
        createExpertPersonalData();

        return;
      }

      if (!statusSuccess) {
        notify('Wystąpił błąd podczas pobierania danych.', 'error');

        return;
      }

      setExpertPersonalData(payload);
      setHasAttachmentsProperty('attachments' in payload);
    };

    /**
     * Loads SubmittedPersonalData resource from API.
     */
    const loadSubmittedPersonalData = async () => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.applicationForms}/${applicationFormData.id}/submitted-personal-data`);

      if (!statusSuccess) {
        notify('Wystąpił błąd podczas pobierania danych.', 'error');

        return;
      }

      setExpertPersonalData(payload);
      setHasAttachmentsProperty('attachments' in payload);
    };

    loadExpertPersonalData();
  }, [personalDataCreated, watch(watcherName)]);

  const tabsConfig = [
    {
      title: 'Dane osobowe',
      id: 'yozmO3BraFG0chV',
      component: <PersonalData />,
      accessRight: true,
    },
    {
      title: 'Adres',
      id: 'VLuU6zErM9gRpbM',
      component: <Address />,
      accessRight: true,
    },
    {
      title: 'Przebieg pracy zawodowej',
      id: 'UI1SxnvoubGDEs1',
      component: <Career />,
      accessRight: true,
    },
    {
      title: 'Wykształcenie',
      id: '3ND7wprX7AogMeW',
      component: <Education />,
      accessRight: true,
    },
    {
      title: 'Znajomość języków',
      id: 'F2TQgquBdWUggrm',
      component: <LanguageSkills />,
      accessRight: true,
    },
    {
      title: 'Dodatkowa działalność eksperta',
      id: 'TepT7zxgtpLBQfX',
      component: <AdditionalActivity />,
      accessRight: true,
    },
    {
      title: 'Załączniki',
      id: 'yu24P3nZ5VQaIqU',
      component: <PersonalDataAttachments />,
      accessRight: true,
    },
  ].filter((tab) => tab.accessRight);

  if (location.hash && !hashTriggered) {
    tabsConfig.forEach(({ id }, index) => {
      if (`#${id}` === location.hash) {
        setHashTriggered(true);
        setValue(index);
      }
    });
  }

  /**
   * Render selected component.
   *
   * @returns {object|string} component if user has access, otherwise empty string
   */
  const getComponent = () => {
    if (!tabsConfig[value]) {
      return '';
    }

    return tabsConfig[value].component;
  };

  /**
   * Find index and open menu tab by id.
   *
   * @param {string} id - tabs config id
   */
  const openTabById = (id) => {
    tabsConfig.forEach((item, index) => {
      if (item.id === id) {
        setValue(index);
      }
    });
  };

  if (!expertPersonalData) {
    return <LinearProgress color="secondary" />;
  }

  return (
    <Fade timeout={600} in>
      <Grid
        container
        spacing={3}
        direction="row"
        sx={{
          marginTop: (theme) => theme.spacing(0.5),
          marginBottom: (theme) => theme.spacing(3),
        }}
      >
        <Grid item lg={3} md={3} xs={12}>
          <VerticalTabs
            ariaLabel="Opcje dotyczące danych do wniosku aplikacyjnego"
            ariaPrefix="settings-tab"
            selectionHandler={setValue}
            initialSelectedIndex={value}
            menuItems={tabsConfig.map((tab) => ({
              title: tab.title,
              id: tab.id,
            }))}
          />
        </Grid>
        <Grid item lg={9} md={9} xs={12}>
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <ExpertPersonalDataContext.Provider value={{
            ...expertPersonalData,
            hasAttachmentsProperty,
            closeHandler,
            reloadData: () => reload(watcherName),
            isReadonly,
            expertId: expertId || expertPersonalDataId,
            selectionHandler: (index) => openTabById(index),
          }}
          >
            {getComponent()}
          </ExpertPersonalDataContext.Provider>
        </Grid>
      </Grid>
    </Fade>
  );
}

export default ExpertApplicationData;

ExpertApplicationData.propTypes = {
  closeHandler: PropTypes.func,
  isReadonly: PropTypes.bool,
  expertId: PropTypes.string,
};

ExpertApplicationData.defaultProps = {
  closeHandler: null,
  isReadonly: false,
  expertId: null,
};
