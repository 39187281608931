/**
 * Cryterion type iris.
 *
 * @type {object}
 */
export const CRYTERION_TYPE_IRIS = {
  substantivePreparation: '/lsi/auth/api/criterion-types/672405d5-e59c-40f4-9783-414656f4e893',
  cooperationWithParpEmployee: '/lsi/auth/api/criterion-types/8afe7ec8-ed83-4814-82ae-2236fa01316c',
  expertFormulatingCommentsMethod: '/lsi/auth/api/criterion-types/fed99ebc-12e0-466b-b537-2e0740a9b9b9',
  punctuality: '/lsi/auth/api/criterion-types/dac3f25a-c578-4021-adcb-4f2d5cf58443',
  justificationsQuality: '/lsi/auth/api/criterion-types/afb5bf77-4d8d-4110-93f0-084a5f95452b',
};

/**
 * Modules numeration.
 *
 * @type {object}
 */
export const CRYTERION_TYPES_ORDER = {
  [CRYTERION_TYPE_IRIS.substantivePreparation]: 1,
  [CRYTERION_TYPE_IRIS.cooperationWithParpEmployee]: 2,
  [CRYTERION_TYPE_IRIS.expertFormulatingCommentsMethod]: 3,
  [CRYTERION_TYPE_IRIS.punctuality]: 4,
  [CRYTERION_TYPE_IRIS.justificationsQuality]: 5,
};
