/**
 * Deprecated session clean. SessionService must be used.
 *
 * @deprecated
 */
export const clearUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userName');
  sessionStorage.removeItem('profile');
  sessionStorage.removeItem('roles');
};
