import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { theme } from '../../styles/theme';

const classes = {
  timerBox: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.brandGray.contrastText,
    padding: theme.spacing(0.5, 2),
    fontSize: '1rem',
    fontWeight: 800,
    minWidth: '70px',
    textAlign: 'center',
  },
};

/**
 * Renders timer.
 *
 * @param {object} props - root props
 * @param {number} props.time - session end time
 * @returns {Timer}
 */
function Timer({ time }) {
  return (
    <div style={classes.timerBox}>
      {format(new Date(time * 1000), 'mm:ss')}
    </div>
  );
}

export default Timer;

Timer.propTypes = {
  time: PropTypes.number.isRequired,
};
