import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * StartupApplicantInformation Pdf element.
 *
 * @returns {StartupApplicantInformationPdf}
 */
function StartupApplicantInformationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name="startup_applicant_information_activity_fiscal_years_number"
          dictionaryName={DICTIONARIES.startupApplicantInformationActivityFiscalYearsNumber.name}
        />
        <RowField name="startup_applicant_information_acceleration_program_participation" />
        {initialFormData?.startup_applicant_information_acceleration_program_participation
        === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="startup_applicant_information_acceleration_program">
            {({
              collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.acceleration_program_name`}
                        value={item.acceleration_program_name}
                        oldValue={previousRowData?.acceleration_program_name}
                        isSingleRow
                      />
                      <RowField
                        name={`${collectionName}.${index}.running_entity`}
                        value={item.running_entity}
                        oldValue={previousRowData?.running_entity}
                        isSingleRow
                      />
                      <RowField
                        name={`${collectionName}.${index}.participation_start_date`}
                        value={item.participation_start_date}
                        oldValue={previousRowData?.participation_start_date}
                      />
                      <RowField
                        name={`${collectionName}.${index}.participation_end_date`}
                        value={item.participation_end_date}
                        oldValue={previousRowData?.participation_end_date}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}

        <RowField name="startup_applicant_information_acceleration_startup_competition_honorable_mention" />
        {initialFormData?.startup_applicant_information_acceleration_startup_competition_honorable_mention
        === BOOLEAN_VALUES.TRUE && (
          <CollectionWrapper name="startup_applicant_information_startup_competition_participation">
            {({
              collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper key={item.set_uuid} index={index}>
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.competition_name`}
                        value={item.competition_name}
                        oldValue={previousRowData?.competition_name}
                        isSingleRow
                      />
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.competition_organizing_entity`}
                        value={item.competition_organizing_entity}
                        oldValue={previousRowData?.competition_organizing_entity}
                        isSingleRow
                      />
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.competition_level`}
                        value={item.competition_level}
                        oldValue={previousRowData?.competition_level}
                        dictionaryName={DICTIONARIES.startupApplicantInformationCompetitionLevel.name}
                      />
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.honorable_mention_type`}
                        value={item.honorable_mention_type}
                        oldValue={previousRowData?.honorable_mention_type}
                        isSingleRow
                      />
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.honorable_mention_received_date`}
                        value={item.honorable_mention_received_date}
                        oldValue={previousRowData?.honorable_mention_received_date}
                      />
                      <RowField
                        name={`startup_applicant_information_startup_competition_participation.${index}.honorable_mention_object`}
                        value={item.honorable_mention_object}
                        oldValue={previousRowData?.honorable_mention_object}
                        dictionaryName={DICTIONARIES.startupApplicantInformationHonorableMentionObject.name}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
        <RowField
          name="startup_applicant_information_is_applicant_business_activity_conducted_at_least_financial_year"
        />
      </FieldSorter>
    </View>
  );
}

export default StartupApplicantInformationPdf;
