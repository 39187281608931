/**
 * Account state translations.
 *
 * @type {object}
 */
export const ACCOUNT_STATE_TRANSLATIONS = {
  activated: 'Aktywowane',
  created: 'Utworzone',
  anonymized: 'Zanonimizowane',
};
