import {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  Controller,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { theme } from '../../../styles/theme';
import {
  API_ROUTE,
  APPLICATION_CONTEXT_HELP_TEXT,
} from '../../../_constants';
import { request } from '../../../_services';
import CharsCounter from '../../CharsCounter';
import { ConfirmDialog } from '../../../Dialogs';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { ApplicationFormAttachmentsField } from './ApplicationFormAttachmentsField';
import InfoPopover from '../../InfoPopover/InfoPopover';
import {
  useGlobalContext,
  useGlobalDialog,
} from '../../../Context';
import { handleError } from '../../../_helpers';

const classes = {
  label: {
    color: theme.palette.text.brand,
    marginTop: theme.spacing(3),
  },
};

/**
 * Reason other form of the industry expert candidate.
 *
 * @param {object} props - root props
 * @param {object} props.otherDomain - related other domain object
 * @param {object} props.attachmentsData - attachments data
 * @param {string} props.applicationFormId - application form id
 * @param {boolean} props.isReadonly - is readonly
 * @returns {OtherDomainJustificationForm}
 */
export function OtherDomainJustificationForm({
  otherDomain, attachmentsData, applicationFormId, isReadonly,
}) {
  const [otherDomainJustificationsCreated, setOtherDomainJustificationsCreated] = useState(false);
  const [otherDomainJustificationId, setOtherDomainJustificationId] = useState(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [visibleNotification, setVisibleNotification] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { notify } = useGlobalContext();
  const { closeAll } = useGlobalDialog();

  const form = useForm({
    defaultValues: {
      keywords: [],
      universityDegree: '',
      universityDegreeAttachments: [],
      threeYearsOfExperience: '',
      threeYearsOfExperienceAttachments: [],
      doctoralDegreeInDomain: '',
      doctoralDegreeInDomainAttachments: [],
      euProjectsParticipant: '',
      euProjectsParticipantAttachments: [],
      experienceInEvaluating: '',
      experienceInEvaluatingAttachments: [],
    },
    mode: 'all',
  });

  const {
    control, formState: { isDirty }, reset, handleSubmit, setError, watch, setValue,
  } = form;

  useEffect(() => {
    const loadAttachments = async (path, fieldName) => {
      const {
        statusSuccess, payload,
      } = await request.get(`${API_ROUTE.otherDomainJustifications}/${path}`);

      if (statusSuccess) {
        setValue(fieldName, payload.map(({ '@id': id }) => id));
      }
    };
    /**
     * Load OtherJustificationsData resource from API.
     */
    const otherDomainJustificationsData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationOtherDomainJustifications
          .replace(':applicationFormId', applicationFormId)}?otherDomain=${otherDomain['@id']}`
      );

      if (!statusSuccess) {
        return;
      }

      const otherDomainJustificationData = payload[0];
      setOtherDomainJustificationId(otherDomainJustificationData?.id);

      if (otherDomainJustificationData?.id) {
        loadAttachments(`${otherDomainJustificationData.id}/university-degree-attachments`, 'universityDegreeAttachments');
        loadAttachments(`${otherDomainJustificationData.id}/three-years-of-experience-attachments`, 'threeYearsOfExperienceAttachments');
        loadAttachments(`${otherDomainJustificationData.id}/doctoral-degree-in-domain-attachments`, 'doctoralDegreeInDomainAttachments');
        loadAttachments(`${otherDomainJustificationData.id}/eu-projects-participant-attachments`, 'euProjectsParticipantAttachments');
        loadAttachments(`${otherDomainJustificationData.id}/experience-in-evaluating-attachments`, 'experienceInEvaluatingAttachments');
      }

      if (!otherDomainJustificationData) {
        setLoaded(true);

        return;
      }

      reset({
        keywords: otherDomainJustificationData.keywords,
        universityDegree: otherDomainJustificationData.universityDegree,
        threeYearsOfExperience: otherDomainJustificationData.threeYearsOfExperience,
        doctoralDegreeInDomain: otherDomainJustificationData.doctoralDegreeInDomain,
        euProjectsParticipant: otherDomainJustificationData.euProjectsParticipant,
        experienceInEvaluating: otherDomainJustificationData.experienceInEvaluating,
      });

      setOtherDomainJustificationsCreated(true);
      setLoaded(true);
    };

    otherDomainJustificationsData();
  }, [applicationFormId]);

  const submitForm = async (formData) => {
    const requestFunc = otherDomainJustificationsCreated
      ? request.put(`${API_ROUTE.otherDomainJustifications}/${otherDomainJustificationId}`, formData)
      : request.post(`${API_ROUTE.otherDomainJustifications}`, {
        ...formData,
        applicationForm: `/lsi/auth/api/application-forms/${applicationFormId}`,
        otherDomain: otherDomain['@id'],
      });
    const {
      statusSuccess, violations = [],
    } = await requestFunc;

    window.scroll(0, 0);

    if (statusSuccess) {
      notify('Dane zostały wprowadzone', 'success');
      closeAll();

      return;
    }

    handleError(setError, violations);
    setVisibleNotification(true);
  };

  if (!loaded) {
    return <CircularProgress size={50} color="secondary" />;
  }

  return (
    <FormProvider {...form}>
      <Box mb={2}>
        <Typography fontWeight={700}>
          {`Dotyczy dziedziny: ${otherDomain.name}`}
        </Typography>

        <InputLabel htmlFor="keywords" sx={classes.label}>
          Słowa kluczowe
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                validate: {
                  atLeastFive: (keywordsBag) => keywordsBag?.length >= 5
                    || 'Dodaj przynajmniej 5 słów kluczowych. Kolejne słowa kluczowe'
                    + ' proszę oddzielać poprzez naciśnięcie przycisku ENTER.',
                },
                required: {
                  value: true,
                  message: 'Dodaj przynajmniej 5 słów kluczowych. Kolejne słowa kluczowe'
                    + ' proszę oddzielać poprzez naciśnięcie przycisku ENTER.',
                },
              }}
              name="keywords"
              control={control}
              render={({
                field: {
                  onChange, name, onBlur, value,
                },
                fieldState: {
                  error,
                },
              }) => (
                <Autocomplete
                  id={name}
                  name={name}
                  multiple
                  options={[]}
                  freeSolo
                  onChange={(_, newValue) => {
                    onChange({ target: {
                      name,
                      value: newValue,
                    } });
                  }}
                  defaultValue={value}
                  onBlur={onBlur}
                  renderInput={(params) => (
                    <TextField
                      error={!!error}
                      helperText={error?.message || 'Proszę uzupełnić słowa kluczowe.'
                        + ' Kolejne słowa kluczowe proszę oddzielać poprzez naciśnięcie przycisku ENTER'}
                      defaultValue={value}
                      {...params}
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.keywords}
          </InfoPopover>
        </Box>

        <InputLabel htmlFor="universityDegree" sx={classes.label}>
          Kandydat na eksperta posiada co najmniej wykształcenie wyższe, tytuł magistra lub równoważny.
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="universityDegree"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalent}
          </InfoPopover>
        </Box>

        <ApplicationFormAttachmentsField
          infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.holdsMasterDegreeOrEquivalentAttachments}
          labelColor={theme.palette.text.brand}
          fieldName="universityDegreeAttachments"
          attachmentsData={attachmentsData}
        />

        <Typography
          fontSize="1rem"
          fontWeight={600}
          mt={4}
        >
          Kandydat na eksperta posiada wiedzę i udokumentowane doświadczenie zawodowe,
          w dziedzinie do oceny której aplikuje:
        </Typography>

        <InputLabel htmlFor="threeYearsOfExperience" variant="noShorten" sx={classes.label}>
          Potwierdzenie przynajmniej trzyletniego doświadczenia zawodowego
          (niezależnie od formy zatrudnienia) w okresie ostatnich pięciu lat w
          dziedzinie, do której kandydat na eksperta aplikuje
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="threeYearsOfExperience"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperience}
          </InfoPopover>
        </Box>

        {watch('threeYearsOfExperience') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.threeYearsOfExperienceAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="threeYearsOfExperienceAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <Typography
          fontSize="1rem"
          fontWeight={600}
          mt={4}
        >
          Udokumentowanie przez kandydata na eksperta co najmniej jednej z poniższych form aktywności zawodowej
          lub naukowej:
        </Typography>

        <InputLabel htmlFor="doctoralDegreeInDomain" variant="noShorten" sx={classes.label}>
          Uzyskanie co najmniej stopnia naukowego doktora w danej dziedzinie
        </InputLabel>
        <Box display="flex" mt={1} mb={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="doctoralDegreeInDomain"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.doctoralDegreeInDomain}
          </InfoPopover>
        </Box>

        {watch('doctoralDegreeInDomain') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.doctoralDegreeInDomainAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="doctoralDegreeInDomainAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <InputLabel htmlFor="euProjectsParticipant" variant="noShorten" sx={classes.label}>
          Udział w co najmniej dwóch zakończonych projektach współfinansowanych ze środków
          Europejskiego Funduszu Społecznego, Europejskiego Funduszu Rozwoju Regionalnego
          lub programów Unii Europejskiej zarządzanych centralnie przez Komisję Europejską
          jako co najmniej członek kluczowego personelu projektu w dziedzinie, do której kandydat aplikuje
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="euProjectsParticipant"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.euProjectsParticipant}
          </InfoPopover>
        </Box>

        {watch('euProjectsParticipant') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.implementationParticipantAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="euProjectsParticipantAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        <InputLabel htmlFor="experienceInEvaluating" variant="noShorten" sx={classes.label}>
          Udokumentowane doświadczenie w zakresie oceny 10 wniosków aplikacyjnych współfinasowanych
          ze środków Europejskiego Funduszu Społecznego, Europejskiego Funduszu Rozwoju Regionalnego
          lub programów Unii Europejskiej zarządzanych centralnie przez Komisję Europejską w dziedzinie,
          do której kandydat aplikuje
        </InputLabel>
        <Box display="flex" mt={1}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              name="experienceInEvaluating"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                  onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  placeholder="Uzasadnienie"
                  onBlur={onBlur}
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value?.length} maxLength={10000} />),
                  }}
                  inputProps={{
                    maxLength: 10000,
                  }}
                  multiline
                />
              )}
            />
          </FormControl>
          <InfoPopover>
            {APPLICATION_CONTEXT_HELP_TEXT.experienceInEvaluating}
          </InfoPopover>
        </Box>

        {watch('experienceInEvaluating') && (
          <ApplicationFormAttachmentsField
            infoPopoverChildren={APPLICATION_CONTEXT_HELP_TEXT.implementationParticipantAttachments}
            labelColor={theme.palette.text.brand}
            fieldName="experienceInEvaluatingAttachments"
            attachmentsData={attachmentsData}
          />
        )}

        {!isReadonly && (
          <Box mt={3}>
            <SaveCancelButtons
              saveButtonId="ZiQsF3JWu6f7uIN"
              cancelButtonId="Zo6xveiKsiSULFd"
              cancelHandler={isDirty ? () => setDialogOpened(true) : () => closeAll()}
              saveHandler={handleSubmit(submitForm)}
            />
          </Box>
        )}
        {visibleNotification && (
          <Typography color="primary" align="left" aria-live="assertive" mt={3}>
            Wystąpił błąd podczas zapisu uzasadnienia branżowego
          </Typography>
        )}
        <ConfirmDialog
          open={dialogOpened}
          dialogId="IfCBjxK2aK3Eo7r"
          dialogTitle="Formularz zawiera niezapisane dane"
          confirmButtonLabel="ok"
          closeHandler={() => setDialogOpened(false)}
          confirmButtonHandler={closeAll}
        >
          <Typography textAlign="center" fontSize="large">
            Formularz zawiera niezapisane dane. Czy chcesz zamknąć formularz i utracić dane?
          </Typography>
        </ConfirmDialog>
      </Box>
    </FormProvider>
  );
}

OtherDomainJustificationForm.propTypes = {
  otherDomain: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  attachmentsData: PropTypes.instanceOf(Object),
  applicationFormId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
};

OtherDomainJustificationForm.defaultProps = {
  otherDomain: {},
  attachmentsData: null,
};
