import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Box,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import InfoPopover from '../../InfoPopover/InfoPopover';
import { useExpertApplicationFormContext } from '../../../Context';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * ApplicationFormAttachmentsField component.
 *
 * @param {object} props - root props
 * @param {string} props.fieldName - name of field label
 * @param {boolean} props.required - is required
 * @param {string} props.labelColor - color of field label
 * @param {Node} props.infoPopoverChildren - info popover children
 * @param {object} props.attachmentsData - attachments data
 * @returns {ApplicationFormAttachmentsField}
 */
export function ApplicationFormAttachmentsField({
  fieldName, required, labelColor, infoPopoverChildren, attachmentsData,
}) {
  const { control } = useFormContext();
  const { attachmentsData: attachmentsDataFromContext } = useExpertApplicationFormContext();

  const noOptionsText = 'Brak dodanych załączników. W celu uzupełnienia pola '
    + 'proszę dodać dokumenty w sekcji Załączniki do wniosku';

  return (
    <Box mt={3}>
      <InputLabel
        htmlFor={fieldName}
        required={required}
        sx={{
          color: labelColor,
          fontSize: '1rem',
          textAlign: 'left',
          mb: (theme) => theme.spacing(1),
        }}
      >
        Dokument potwierdzający (załącznik)
      </InputLabel>
      <Box display="flex">
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name={fieldName}
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                noOptionsText={noOptionsText}
                id={name}
                textFieldProps={{
                  placeholder: 'Nazwa dokumentu potwierdzającego',
                  error: !!error,
                  required: true,
                }}
                initialValue={value}
                onBlur={onBlur}
                multiple
                renderSelectAllButton={false}
                onChange={onChange}
                options={attachmentsData || attachmentsDataFromContext}
                optionsMapKeys={['@id', 'description']}
                error={error}
              />
            )}
          />
        </FormControl>
        {infoPopoverChildren && (
          <InfoPopover>
            {infoPopoverChildren}
          </InfoPopover>
        )}
      </Box>
    </Box>
  );
}

ApplicationFormAttachmentsField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  labelColor: PropTypes.string,
  infoPopoverChildren: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.oneOf([null]),
  ]),
  attachmentsData: PropTypes.instanceOf(Object),
};

ApplicationFormAttachmentsField.defaultProps = {
  required: false,
  labelColor: '#757575',
  infoPopoverChildren: null,
  attachmentsData: null,
};
