import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ElementEditForm } from './ElementEdit.form';
import { FieldsList } from './FieldsList';
import { FieldEditForm } from './FieldEdit.form';
import { CollectionField } from './CollectionField';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { useReloadListener } from '../../Context';

/**
 * Dialog used to edit an element of application template
 *
 * @param {object} props - root props
 * @param {string} props.templateElementId - element id
 * @param {Function} props.closeHandler - handler closing the dialog
 * @returns {ElementEditDialog}
 */
export function ElementEditDialog({
  templateElementId,
  closeHandler,
}) {
  const open = Boolean(templateElementId);
  const [selectedField, setSelectedField] = useState(null);
  const [requestProgress, setRequestProgress] = useState(false);
  const [fieldsOrder, setFieldsOrder] = useState([]);
  const [templateElement, setTemplateElement] = useState({
    id: '',
    title: '',
    elementDefinition: {
      fields: [],
      title: '',
    },
    modules: [],
    groups: [],
    label: '',
    contextHelp: '',
  });
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'application-template-edit';

  const collectionFieldType = 'collection';

  const loadTemplateElement = useCallback(async (isBackHandling = false) => {
    const { payload } = await request.get(`${API_ROUTE.templateElement}/${templateElementId}`);
    setTemplateElement(payload);

    if (selectedField?.type === collectionFieldType && !isBackHandling) {
      setSelectedField(payload.elementDefinition.fields.find(({ uuid }) => selectedField.uuid === uuid));
    }

    const { elementDefinition: { fields } } = payload;
    setFieldsOrder(
      fields.map((fieldData) => fieldData.uuid)
    );
  }, [templateElementId, selectedField]);

  useEffect(() => {
    loadTemplateElement();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      maxWidth="xl"
      fullWidth
      variant="outlined"
      color="secondary"
    >
      <DialogTitle component="div">
        <Typography variant="dialogHeading">
          {templateElement.elementDefinition.title}
        </Typography>
      </DialogTitle>
      {requestProgress && (
        <LinearProgress color="secondary" />
      )}
      <DialogContent>
        {!selectedField && templateElement.id && (
          <>
            <ElementEditForm
              elementData={templateElement}
              closeDialogHandler={closeHandler}
              reloadHandler={() => {
                reload(watcherName);
                loadTemplateElement();
              }}
              requestProgressSetter={setRequestProgress}
              fieldsOrder={fieldsOrder}
            />
            <Box my={4}>
              <Divider />
            </Box>
            <FieldsList
              templateElementId={templateElementId}
              fieldsData={templateElement.elementDefinition.fields}
              selectedFieldHandler={setSelectedField}
              closeDialogHandler={closeHandler}
              reloadTemplateElement={loadTemplateElement}
              fieldsOrderSetter={setFieldsOrder}
            />
          </>
        )}
        {selectedField && (
          <>
            {selectedField.type === collectionFieldType ? (
              <CollectionField
                collectionFieldData={selectedField}
                reloadTemplateElement={loadTemplateElement}
                backHandler={() => {
                  loadTemplateElement(true);
                  setSelectedField(null);
                }}
                templateElementId={templateElementId}
                loadTemplateElement={loadTemplateElement}
              />
            ) : (
              <FieldEditForm
                fieldData={selectedField}
                backHandler={() => {
                  loadTemplateElement(true);
                  setSelectedField(null);
                }}
                templateElementId={templateElementId}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

ElementEditDialog.propTypes = {
  templateElementId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
