export const getIndicatorTargetValueFromHrf = (formData, item, existIndicator) => {
  const hrfAllTasks = Object
    .keys(formData)
    .filter((filterKey) => filterKey.includes('tasks-'))
    ?.map((key) => formData[key]?.map((task) => task.task_id))
    ?.flat();

  const getHrfSumByItemKey = (itemKey) => {
    const filtredFormData = Object.entries(formData)
      .filter(([key]) => (key.substring(0, 15) === 'actual_expenses' || key.substring(0, 17) === 'lump_sum_expenses'))
      .map(([_, arr]) => arr);

    const expensesDataValues = [];
    filtredFormData.filter((data) => data).forEach((data) => expensesDataValues.push(...data));

    // calculated sum expenses
    return expensesDataValues?.reduce((itemPrev, accPrev) => {
      if (accPrev?.task_id === '' || !hrfAllTasks.includes(accPrev?.task_id)) {
        return itemPrev;
      }

      return itemPrev + Number(accPrev?.[itemKey] || 0);
    }, 0) || 0;
  };

  const hrfTotalExpenses = getHrfSumByItemKey('total_expenses');
  const hrfTotalCofinancing = getHrfSumByItemKey('co_financing');

  const indicatorsIdsToCalculateTargetValue = [
    'd0638226-9a36-464c-bc26-4db8ce88e966',
    '95a6f90d-314c-4739-ac62-55e91fc72189',
  ];

  if (indicatorsIdsToCalculateTargetValue.includes(item?.indicator?.id)) {
    return (hrfTotalExpenses - hrfTotalCofinancing).toFixed(2);
  }

  return (existIndicator?.target_value_o?.toString()
    ? existIndicator.target_value_o
    : item.defaultTargetValue
  );
};
