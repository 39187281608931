import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { MonetaryField } from '../../MonetaryField';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * ApplicantExperience element renderer.
 *
 * @returns {ApplicantExperience}
 */
export function ApplicantExperience() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const collectionName = 'applicant_experience_information_about_applicant_completed_projects_for_sme';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const completedProjectsConfig = fieldsConfig[collectionName];
  const subfields = completedProjectsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = completedProjectsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <FieldSorter>
      {/* Opis prowadzonej przez wnioskodawcę działalności  */}
      <FormField name="applicant_experience_business_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      projektów inwestycyjnych */}
      <FormField name="applicant_experience_services_provided_for_investment_projects">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      projektów zawierających komponent szkoleniowy  */}
      <FormField name="applicant_experience_services_provided_for_projects_with_training_component">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
       projektów zawierających komponent doradczy */}
      <FormField name="applicant_experience_services_provided_for_projects_with_consulting_component">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opisz rodzaj i zakres usług świadczonych na rzecz MŚP przez wnioskodawcę i partnerów w zakresie
      wykorzystania i stosowania zielonych technologii z uwzględnieniem stosowania zasady DNSH */}
      <FormField name="applicant_experience_services_provided_in_use_and_application_green_technologies">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Informacja o zrealizowanych przez wnioskodawcę projektach na rzecz MŚP" */}
      <LabeledFieldRow fieldConfig={completedProjectsConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* a) Tytuł projektu */}
            <FormField name={`${collectionName}.${index}.project_title`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* b) Numer projektu */}
            <FormField name={`${collectionName}.${index}.project_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* c) Wartość projektu w PLN */}
            <FormField name={`${collectionName}.${index}.project_value_pln`}>
              {({
                value, onChange, onBlur, error, name,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {/* d) Okres realizacji od: */}
            <FormField name={`${collectionName}.${index}.implementation_start_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  views={['month', 'year']}
                  ariaLabel={label}
                  format="yyyy-MM"
                  mask="____-__"
                  placeholder="rrrr-mm"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* e) Okres realizacji do: */}
            <FormField name={`${collectionName}.${index}.implementation_end_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  views={['month', 'year']}
                  ariaLabel={label}
                  format="yyyy-MM"
                  mask="____-__"
                  placeholder="rrrr-mm"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* f) Źródło finansowania   */}
            <FormField name={`${collectionName}.${index}.funding_source`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* g) Rola pełniona w projekcie */}
            <FormField name={`${collectionName}.${index}.role_in_project`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* h) Liczba odbiorców */}
            <FormField name={`${collectionName}.${index}.recipients_number`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <NumericFormat
                  id={name}
                  name={name}
                  onValueChange={(values) => onChange({
                    target: {
                      name,
                      value: values.floatValue,
                    },
                  })}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={0}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="KDJSY0WWjPTPfVr"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Data zamknięcia ostatniego roku obrotowego trwającego co najmniej 12 miesięcy */}
      <FormField name="last_fiscal_year_closing_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
