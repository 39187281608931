import {
  FILE_UPLOAD_DEFAULT_ERROR_MESSAGE,
  FILE_UPLOAD_ERROR_MESSAGES,
} from '../_constants';

/**
 * Returns message by code.
 *
 * @param {string} code - error code
 * @returns {string}
 */
export const getMessageByCode = (code) => FILE_UPLOAD_ERROR_MESSAGES[code]
  ?? FILE_UPLOAD_DEFAULT_ERROR_MESSAGE;
