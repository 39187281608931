import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { MonetaryField } from '../../MonetaryField';

/**
 * Applicant experience in acceleration programs element.
 *
 * @returns {ApplicantExperienceInImplementationAccelerationPrograms}
 */
export function ApplicantExperienceInImplementationAccelerationPrograms() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();
  const collectionFieldName = 'applicant_experience_in_implementation_acceleration_programs';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const applicantExperienceConfig = fieldsConfig[collectionFieldName];
  const subfields = applicantExperienceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = applicantExperienceConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <LabeledFieldRow fieldConfig={applicantExperienceConfig} contextHelpLabel fullWidth>
      {fields.map((item, index) => (
        <CollectionFieldContainer
          onRemove={() => removeItem(
            fields,
            minRowNumber,
            remove,
            setValue,
            collectionFieldName,
            index,
          )}
          buttonRemoveVisible={fields.length > minRowNumber}
          isReadonly={isReadonly}
          itemIndex={index}
          fieldsOrder={Object.keys(subfields)}
          key={item.id}
          isIndexed
        >
          {/* Nazwa programu akceleracji */}
          <FormField name={`${collectionFieldName}.${index}.name`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Krótki opis programu akceleracji, w tym liczba rund akceleracji */}
          <FormField name={`${collectionFieldName}.${index}.short_description`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                multiline
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Data rozpoczęcia programu akceleracji */}
          <FormField name={`${collectionFieldName}.${index}.start_date`}>
            {({
              name, onChange, value, error, onBlur, label,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Data zakończenia programu akceleracji */}
          <FormField name={`${collectionFieldName}.${index}.end_date`}>
            {({
              name, onChange, value, error, onBlur, label,
            }) => (
              <CustomDesktopDatePicker
                ariaLabel={label}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Źródła finansowania programu akceleracji  */}
          <FormField name={`${collectionFieldName}.${index}.funding_sources`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                multiline
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Kluczowi partnerzy współpracujący w ramach zrealizowanego programu akceleracji  */}
          <FormField name={`${collectionFieldName}.${index}.key_partners`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                multiline
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Efekty działalności akceleratora  */}
          <FormField name={`${collectionFieldName}.${index}.activities_effect`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                multiline
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Opis dwóch wybranych przypadków akcelerowanych rozwiązań  */}
          <FormField name={`${collectionFieldName}.${index}.two_selected_cases_description`}>
            {({
              name, onChange, value, maxLength, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                multiline
                onChange={onChange}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                onBlur={onBlur}
              />
            )}
          </FormField>
          {/* Kwota całkowitego budżetu programu */}
          <FormField name={`${collectionFieldName}.${index}.program_budget_amount`}>
            {({
              name, onChange, value, error, onBlur,
            }) => (
              <TextField
                defaultValue={value}
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                disabled={isReadonly}
                error={!!error}
                fullWidth
                InputProps={{
                  inputComponent: MonetaryField,
                }}
                helperText={error?.message}
              />
            )}
          </FormField>
        </CollectionFieldContainer>
      ))}

      {!isReadonly && fields.length < maxRowNumber && (
        <Grid item xs={12}>
          <Button
            id="OmZSG2951U94h58"
            variant="contained"
            color="secondary"
            onClick={() => addItem(
              fields,
              maxRowNumber,
              append,
              fieldObject,
            )}
          >
            Dodaj
          </Button>
        </Grid>
      )}
    </LabeledFieldRow>
  );
}
