import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import ShowIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale/pl';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { darken } from '@mui/material/styles';
import {
  API_ROUTE,
  CHARS_LIMIT,
  DISCLAIMER,
} from '../../_constants';
import { GoBack } from '../GoBack';
import { request } from '../../_services';
import { usePasswordRequirements } from '../../_hooks';
import {
  useAuth,
  Captcha,
} from '../../_security';
import { registerValidation } from './Register.validation';
import CharsCounter from '../CharsCounter';
import { useGlobalContext } from '../../Context';
import { theme } from '../../styles/theme';

const classes = {
  formCtrl: {
    marginBottom: theme.spacing(2),
    '& .Mui-focused input.MuiInputBase-inputAdornedEnd+.MuiInputAdornment-positionEnd>p': {
      visibility: 'visible',
    },
  },
  confirmationBox: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  registerPaper: {
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  registerBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
    fontSize: '1.3em',
    lineHeight: '3rem',
    textTransform: 'inherit',
    borderWidth: '3px',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
  visibility: {
    display: 'flex',
    order: '1',
    position: 'absolute',
    zIndex: 3,
    right: 0,
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit',
    },
  },
  btnText: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    fontSize: '1rem',
    textDecoration: 'underline',
    padding: 0,
    verticalAlign: 'baseline',
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
};

/**
 * Register form component.
 *
 * @returns {Register}
 */
export default function Register() {
  const { notify } = useGlobalContext();
  const navigate = useNavigate();
  const { onRegister } = useAuth();
  const [visibility, setVisibility] = useState({
    password: false,
    confirmedPassword: false,
  });
  const [isPasswordRequirementsOpen, setIsPasswordRequirementsOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const defaultValues = {
    firstName: '',
    lastName: '',
    password: '',
    confirmedPassword: '',
    pesel: '',
    email: '',
    termsConfirmation: false,
    reCaptcha: null,
  };

  const {
    control, handleSubmit, setError, formState, watch,
  } = useForm({
    defaultValues,
    mode: 'all',
  });

  const {
    errors, isValid, isDirty,
  } = formState;

  const {
    checkPassword,
    renderViolations,
  } = usePasswordRequirements();

  const handleSuccess = (payload) => {
    const registerDate = format(new Date(payload.createdAt), 'd MMMM yyyy, HH:mm', { locale: pl });
    const message = `${registerDate} utworzono nowe konto. Sprawdź skrzynkę pocztową podanego adresu email.`;

    navigate('/login');
    setTimeout(() => {
      notify(message, 'success');
    }, 100);
    window.scrollTo(0, 0);
  };

  const handleError = (violations) => {
    setSubmitting(false);
    violations.map(({
      propertyPath, message,
    }) => setError(propertyPath, {
      type: 'manual',
      message,
    }));
  };

  const onSubmit = async (values) => {
    setSubmitting(true);
    const requestObject = {
      firstName: values.firstName,
      lastName: values.lastName,
      plainPassword: values.password,
      repeatedPlainPassword: values.confirmedPassword,
      pesel: values.pesel,
      email: values.email,
      reCaptcha: values.reCaptcha,
    };

    await onRegister(
      requestObject,
      handleSuccess,
      handleError,
    );
  };

  const onBlurValidationHandler = async (name, value) => {
    if (value.length) {
      const requestPayload = {
        required: null,
        value,
        options: [],
      };

      const validationResponse = await request.post(
        `${API_ROUTE.validations}/${name}`,
        requestPayload,
        false
      );

      const {
        statusSuccess,
        violations,
      } = validationResponse;

      if (!statusSuccess) {
        setError(name, {
          type: 'manual',
          message: violations[0].message,
        });
      }
    }
  };

  const handleVisibility = (name) => {
    setVisibility((prevVisibility) => ({
      ...visibility,
      [name]: !prevVisibility[name],
    }));
  };

  const downloadTermsOfUse = async () => {
    await request.download(
      `${API_ROUTE.termsOfUse}`,
      false
    );
  };

  return (
    <Paper variant="outlined" elevation={0} sx={classes.registerPaper}>
      <Grid container justifyContent="center">
        <Grid item xs={12} mt={2} mb={4} ml={1}>
          <GoBack />
        </Grid>
        <Grid item xs={12} lg={8} xl={6}>
          <Typography variant="h3" fontSize="2rem" mb={2}>Zarejestruj konto</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.firstName}
                name="firstName"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Imię"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} onOutline />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.lastName}
                name="lastName"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Nazwisko"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} onOutline />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.MEDIUM }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.pesel}
                name="pesel"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="PESEL"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.PESEL} onOutline />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.PESEL }}
                    onChange={onChange}
                    onBlur={(event) => {
                      onBlurValidationHandler(name, value);
                      onBlur(event);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.email}
                name="email"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="E-mail"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} onOutline />
                      ),
                    }}
                    inputProps={{ maxLength: CHARS_LIMIT.LONG }}
                    onChange={onChange}
                    onBlur={(event) => {
                      onBlurValidationHandler(name, value);
                      onBlur(event);
                    }}
                    required
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.password}
                name="password"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Hasło"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <>
                          <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} onOutline />
                          <InputAdornment position="end" sx={classes.visibility}>
                            <IconButton
                              id="dTKB0pviElUq6dZ"
                              aria-label="przełącz widoczność hasła"
                              data-testid="passwordVisibilityIcon1"
                              onClick={() => handleVisibility(name)}
                              size="large"
                            >
                              {visibility[name] ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.MEDIUM,
                      type: visibility[name] ? 'text' : 'password',
                    }}
                    onChange={(event) => {
                      onChange(event);
                      checkPassword(event.target.value);
                    }}
                    onBlur={(event) => {
                      onBlur(event);
                      setIsPasswordRequirementsOpen(false);
                    }}
                    required
                    onFocus={() => setIsPasswordRequirementsOpen(true)}
                  />
                )}
              />
              {(isPasswordRequirementsOpen || !!errors?.password) && renderViolations()}
            </FormControl>
            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={{
                  ...registerValidation.confirmedPassword,
                  validate: (value) => value === watch('password')
                    || 'Wpisz poprawne hasło. Hasło i powtórzone hasło nie są ze sobą zgodne.',
                }}
                name="confirmedPassword"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    label="Powtórz hasło"
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <>
                          <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.MEDIUM} onOutline />
                          <InputAdornment position="end" sx={classes.visibility}>
                            <IconButton
                              id="nynwgZ0iyAaMKV2"
                              aria-label="przełącz widoczność hasła"
                              data-testid="passwordVisibilityIcon2"
                              onClick={() => handleVisibility(name)}
                              size="large"
                            >
                              {visibility[name] ? <HideIcon /> : <ShowIcon />}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.MEDIUM,
                      type: visibility[name] ? 'text' : 'password',
                    }}
                    onChange={onChange}
                    onBlur={onBlur}
                    required
                  />
                )}
              />
            </FormControl>
            <Paper sx={classes.confirmationBox}>
              <Controller
                rules={registerValidation.termsConfirmation}
                name="termsConfirmation"
                control={control}
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          name={name}
                          color="secondary"
                        />
                      )}
                      label={(
                        <>
                          Akceptuję&nbsp;
                          <Button
                            id="j5sio80Kf8hHgsc"
                            sx={classes.btnText}
                            onClick={downloadTermsOfUse}
                            title="Pobierz regulamin"
                          >
                            Regulamin LSI
                          </Button>
                        </>
                      )}
                    />
                    {!!error && (
                      <FormHelperText
                        error
                        component={Box}
                      >
                        {error?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />

              <Box
                pt={1}
                border={`1px dotted ${theme.palette.brandGray40.main}`}
                fontSize="0.75rem"
                fontWeight={100}
                textAlign="left"
                color="#484c4e"
              >
                <p>
                  {DISCLAIMER.paragraph1.pl}
                </p>
                <p>
                  {DISCLAIMER.paragraph2.pl}
                </p>
                <p>
                  {DISCLAIMER.paragraph3.pl}
                </p>
              </Box>
            </Paper>

            <FormControl fullWidth sx={classes.formCtrl}>
              <Controller
                rules={registerValidation.reCaptcha}
                name="reCaptcha"
                control={control}
                render={({
                  field: {
                    onChange, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <Captcha
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                  />
                )}
              />
            </FormControl>

            <div style={classes.btnWrapper}>
              <Button
                disabled={!isDirty || !isValid || isSubmitting}
                id="A1N2eoMbBIv3MCX"
                sx={classes.registerBtn}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Zarejestruj
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
