import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import CompetencesModuleEssenceFormsPdf from './CompetencesModuleEssence/CompetencesModuleEssenceForms.pdf';

/**
 * Essence of the module - competence pdf component
 *
 * @returns {CompetencesModuleEssencePdf}
 */
function CompetencesModuleEssencePdf() {
  const { application } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <View style={printStyle.table}>
      <RowField name="competences_module_essence_module_purpose" />
      <RowField
        name="competences_module_essence_module_name"
        dictionaryName={DICTIONARIES.modules.name}
      />
      <RowField
        name="competences_module_essence_scope"
        dictionaryName={DICTIONARIES.compentenceModuleEssenceScope.name}
      />
      <RowField name="competences_module_essence_reason" />
      <CompetencesModuleEssenceFormsPdf />
      <RowField
        name="competences_module_essence_obligatory_module"
        externalDictionary={modules}
      />
    </View>
  );
}

export default CompetencesModuleEssencePdf;
