import {
  Box,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import LogotypesConfiguratorTable from './LogotypesConfigurator.table';
import LogotypesConfiguratorForm from './LogotypesConfigurator.form';

/**
 * Logotypes configurator dialog component
 *
 * @param {object} props - root props
 * @param {Function} props.closeHandler - modal close handler
 * @returns {LogotypesConfiguratorDialog}
 */
export function LogotypesConfiguratorDialog({ closeHandler }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [logotypeData, setLogotypeData] = useState({});

  return (
    <>
      {showAddForm ? (
        <LogotypesConfiguratorForm
          initialData={logotypeData}
          closeHandler={() => setShowAddForm(false)}
        />
      ) : (
        <>
          <Button
            id="Zdfyd2JNC5qU311"
            type="button"
            color="success"
            variant="contained"
            onClick={() => {
              setLogotypeData(undefined);
              setShowAddForm(true);
            }}
            sx={{ mb: (theme) => theme.spacing(6) }}
          >
            Dodaj
          </Button>
          <LogotypesConfiguratorTable onEdit={(row) => {
            setLogotypeData(row);
            setShowAddForm(true);
          }}
          />
          <Box display="flex" justifyContent="flex-end" mt={6}>
            <Button
              id="S4uK4hGIzaE2K5m"
              type="button"
              variant="outlined"
              onClick={closeHandler}
            >
              Zamknij
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

LogotypesConfiguratorDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
};
