import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ModuleClassificationPdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @returns {ModuleClassificationPdf}
 */
function ModuleClassificationPdf({ elementData }) {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
  } = useApplicationToPdfContext();
  const elementName = elementData.elementDefinition.name;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField
          name={`${elementName}_kis_domain_area`}
          dictionaryName={DICTIONARIES.kisDomainAreas.name}
        />
        <RowField name={`${elementName}_kis_domain`} />
        <RowField name={`${elementName}_kis_explanation`} />
        <RowField
          name={`${elementName}_pkd`}
          value={initialFormData[`${elementName}_pkd`]?.label}
          oldValue={previousInitialFormData?.[`${elementName}_pkd`]?.label}
        />
        <RowField name={`${elementName}_business_description`} />
      </FieldSorter>
    </View>
  );
}

export default ModuleClassificationPdf;

ModuleClassificationPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
