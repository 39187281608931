import {
  useEffect,
  useState,
} from 'react';
import { MessageDialog } from '../HelpersDialogs';
import {
  API_ROUTE,
  VERSION,
} from '../../_constants';
import { request } from '../../_services';

/**
 * VersionDialog component.
 *
 * @returns {VersionDialog}
 */
export function VersionDialog() {
  const [apiAuthVersion, setApiAuthVersion] = useState('brak danych');
  const [apiRecruitmentVersion, setApiRecruitmentVersion] = useState('brak danych');

  /**
   * Gets version from API.
   *
   * @param {string} endpointUrl - endpoint URL
   * @param {Function} setter - state setter
   */
  async function getVersion(endpointUrl, setter) {
    const {
      statusSuccess, payload,
    } = await request.get(endpointUrl);

    if (statusSuccess) {
      setter(payload?.version);
    }
  }

  useEffect(() => {
    getVersion(API_ROUTE.versionAuth, setApiAuthVersion);
    getVersion(API_ROUTE.versionRecruitments, setApiRecruitmentVersion);
  }, []);

  const appsVersionContent = (
    <ul
      style={{
        listStyle: 'none',
        paddingInlineStart: 0,
      }}
    >
      <li>{`Front: ${process.env.REACT_APP_VERSION || VERSION}`}</li>
      <li>{`Auth API: ${apiAuthVersion}`}</li>
      <li>{`Recruitment API: ${apiRecruitmentVersion}`}</li>
    </ul>
  );

  return (<MessageDialog>{appsVersionContent}</MessageDialog>);
}
