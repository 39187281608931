import {
  Divider,
  Grid,
} from '@mui/material';
import { AttachmentsBox } from '../../FileUpload';
import { API_ROUTE } from '../../../_constants';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { FieldSorter } from './FieldSorter';

/**
 * Attachments element renderer.
 *
 * @returns {Attachments}
 */
export function Attachments() {
  const {
    currentElementFieldsConfig, isReadonly,
  } = useElementContext();
  const { id: applicationId } = useApplicationContext();

  /**
   * Filtered fields of attachments element.
   * Contains only `attachments` type fields. That type of fields will be rendered automatically within this element.
   */
  const attachmentFields = Object
    .values(currentElementFieldsConfig)
    .filter((fieldConfig) => fieldConfig.type === 'attachments');

  /**
   * Saves uploaded attachments data to form.
   *
   * @param {Function} onChangeCallback - form onChange callback
   * @param {Array} filesList - files array
   * @param {string} fieldName - attachment field name
   */
  const saveFilesToForm = (onChangeCallback, filesList, fieldName) => {
    const mappedData = filesList.map(({ iri }) => iri);

    onChangeCallback({
      target: {
        name: fieldName,
        value: mappedData,
      },
    });
  };

  return (
    <FieldSorter>
      {attachmentFields.map((field) => (
        <LabeledFieldRow
          key={field.uuid}
          fieldConfig={field}
          fullWidth
          contextHelpLabel
          labelContainerProps={{
            sx: {
              fontSize: '1.1em',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
              maxWidth: 1 / 2,
              '& label': {
                fontWeight: 800,
              },
            },
          }}
        >
          <FormField name={field.name} labelled={false}>
            {({
              name, minAttachments, maxAttachments, maxFilesize, acceptedFileExtensions, value, onChange,
            }) => (
              <Grid item xs={12}>
                <AttachmentsBox
                  minAttachments={minAttachments}
                  maxAttachments={maxAttachments}
                  maxSize={maxFilesize}
                  allowedExtensions={acceptedFileExtensions}
                  requestPayload={{
                    linkedObjectIri: `/lsi/recruitments/api/applications/${applicationId}`,
                  }}
                  uploadedFilesCallback={(uploadedFiles) => saveFilesToForm(onChange, uploadedFiles, name)}
                  existingFiles={value}
                  fetchUrl={`${API_ROUTE.applications}/${applicationId}/attachments`}
                  isReadonly={isReadonly}
                />
              </Grid>
            )}
          </FormField>
          <Divider sx={{ margin: (theme) => theme.spacing(1, 0) }} />
        </LabeledFieldRow>
      ))}
    </FieldSorter>
  );
}
