import {
  Controller,
  useForm,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import {
  isAfter,
  isBefore,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { SaveCancelButtons } from '../../../SaveCancelButtons';
import { request } from '../../../../_services';
import { API_ROUTE } from '../../../../_constants';
import { Alert } from '../../../Alert';
import CharsCounter from '../../../CharsCounter';
import CustomDesktopDatePicker from '../../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { useGlobalDialog } from '../../../../Context';
import { theme } from '../../../../styles/theme';

/**
 * Expert education edit/create form.
 *
 * @param {object} props - root props
 * @param {object|null} props.existingData - existing education data
 * @param {Function} props.reloadData - reload data
 * @param {Array} props.educationAttachmentsData - education attachments data
 * @param {Function} props.selectionHandler - selection handler
 * @param {boolean} props.isReadonly - is readonly
 * @returns {EducationForm}
 */
export function EducationForm({
  existingData, reloadData, educationAttachmentsData, selectionHandler, isReadonly,
}) {
  /**
   * Prevents submit button from multiple click.
   */
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const { closeAll } = useGlobalDialog();
  const currentYear = endOfYear(new Date());
  const minYear = startOfYear(new Date('1950'));

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      universityName: existingData?.universityName || '',
      universityDepartment: existingData?.universityDepartment || '',
      studyField: existingData?.studyField || '',
      graduationYear: existingData?.graduationYear ? new Date(existingData?.graduationYear?.toString()) : '',
      academicTitle: existingData?.academicTitle || '',
      attachments: existingData?.attachments || [],
    },
    mode: 'all',
  });

  useEffect(() => {
    reloadData();
  }, []);

  const normalizeRequestPayload = (payload) => ({
    ...payload,
    graduationYear: payload?.graduationYear ? (new Date(payload.graduationYear)).getFullYear() : null,
  });

  /**
   * Send request to API for resource create.
   *
   * @param {object} formData - form data
   */
  const createHandler = async (formData) => {
    setSaveTriggered(true);

    const { statusSuccess } = await request.post(
      API_ROUTE.expertEducations,
      normalizeRequestPayload(formData),
    );

    if (statusSuccess) {
      closeAll();
      reloadData();

      return;
    }

    setSaveError(true);
    setSaveTriggered(false);
  };

  /**
   * Send request to API for resource edit.
   *
   * @param {object} formData - form data
   */
  const editHandler = async (formData) => {
    const { statusSuccess } = await request.put(
      `${API_ROUTE.expertEducations}/${existingData.id}`,
      normalizeRequestPayload(formData),
    );

    if (statusSuccess) {
      closeAll();
      reloadData();

      return;
    }

    setSaveError(true);
  };

  return (
    <>
      <Grid container aria-colcount={2} spacing={2}>
        {educationAttachmentsData?.length === 0 && (
          <>
            <Grid item xs={3} />
            <Grid item xs={7}>
              <Box textAlign="center" border={`1px solid ${theme.palette.error.dark}`} p={1.5}>
                <Typography color="error" fontWeight="bold">
                  Przed wypełnieniem formularza dodaj w sekcji Załączniki
                  przynajmniej jeden załącznik potwierdzający wykształcenie
                </Typography>
                <Button
                  id="KdTyw6JNC5oW311"
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    closeAll();
                    selectionHandler('yu24P3nZ5VQaIqU');
                  }}
                  sx={{ m: (t) => t.spacing(2) }}
                >
                  Dodaj załącznik
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2} />
          </>
        )}
        <Grid item xs={3}>
          <InputLabel htmlFor="universityName">
            Nazwa uczelni
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Nazwa uczelni"',
                },
              }}
              name="universityName"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value.length} maxLength={250} />),
                  }}
                  inputProps={{ maxLength: 250 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="universityDepartment">
            Nazwa wydziału
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Nazwa wydziału"',
                },
              }}
              name="universityDepartment"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value.length} maxLength={250} />),
                  }}
                  inputProps={{ maxLength: 250 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="studyField">
            Kierunek/specjalizacja
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Kierunek/specjalizacja"',
                },
              }}
              name="studyField"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value.length} maxLength={250} />),
                  }}
                  inputProps={{ maxLength: 250 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="graduationYear">
            Rok ukończenia uczelni
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                validate: {
                  afterDate: (dateValue) => !dateValue || isAfter(dateValue, minYear)
                    || 'Wartość w polu "Rok ukończenia uczelni" musi być większa niż 1950',
                  beforeDate: (dateValue) => !dateValue || isBefore(dateValue, currentYear)
                    || `Wartość w polu "Rok ukończenia uczelni" musi być mniejsza niż ${(new Date()).getFullYear()}`,
                },
              }}
              name="graduationYear"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  minDate={new Date('1951-01-01')}
                  maxDate={new Date()}
                  name={name}
                  views={['year']}
                  error={!!error}
                  helperText={error?.message}
                  ariaLabel="Rok ukończenia uczelni"
                  format="yyyy"
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="academicTitle">
            Uzyskany tytuł/stopień naukowy
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Uzyskany tytuł/stopień naukowy"',
                },
              }}
              name="academicTitle"
              control={control}
              render={({
                field: {
                  onChange, value, name,
                },
                fieldState: {
                  error,
                },
              }) => (
                <TextField
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<CharsCounter valueLength={value.length} maxLength={5000} />),
                  }}
                  inputProps={{ maxLength: 5000 }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <InputLabel htmlFor="attachments">
            Dokument potwierdzający wykształcenie
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Controller
              rules={{
                required: {
                  value: true,
                  message: 'Proszę uzupełnić pole "Dokument potwierdzający wykształcenie"',
                },
              }}
              name="attachments"
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  noOptionsText="Brak dodanych załączników.
                    W celu uzupełnienia pola proszę dodać dokumenty w sekcji Załączniki"
                  id={name}
                  textFieldProps={{
                    placeholder: 'Wybierz z listy nazwę dokumentu potwierdzającego wykształcenie',
                    error: !!error,
                    required: true,
                  }}
                  initialValue={value}
                  onBlur={onBlur}
                  multiple
                  renderSelectAllButton={false}
                  onChange={onChange}
                  options={educationAttachmentsData}
                  optionsMapKeys={['@id', 'description']}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>

      </Grid>
      {saveError && (
        <Alert severity="error">Wystąpił nieoczekiwany błąd zapisu.</Alert>
      )}
      {!isReadonly && (
        <Box display="flex" justifyContent="center" mt={5} mb={3}>
          <SaveCancelButtons
            saveButtonId="3lizzyny774iK72"
            cancelButtonId="QBwpVYjTFMekMfc"
            cancelHandler={closeAll}
            saveHandler={handleSubmit(existingData === null ? createHandler : editHandler)}
            saveDisabled={saveTriggered}
          />
        </Box>
      )}
    </>
  );
}

EducationForm.propTypes = {
  existingData: PropTypes.oneOfType([
    PropTypes.shape({
      universityName: PropTypes.string,
      universityDepartment: PropTypes.string,
      studyField: PropTypes.string,
      graduationYear: PropTypes.number,
      academicTitle: PropTypes.string,
      id: PropTypes.string,
    }),
    PropTypes.oneOf([null]),
  ]),
  reloadData: PropTypes.func.isRequired,
  educationAttachmentsData: PropTypes.arrayOf(Object).isRequired,
  selectionHandler: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
};

EducationForm.defaultProps = {
  existingData: null,
};
