import { theme } from '../../../styles/theme';

export const newsStyleClasses = {
  newsBulletin: {
    padding: theme.spacing(2),
    textAlign: 'left',
  },
  newsHeading: {
    fontSize: '2.5em',
    textAlign: 'center',
  },
  itemContent: {
    paddingLeft: theme.spacing(3),
    fontSize: '1em',
    display: 'block',
    fontWeight: 400,
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
    lineHeight: '1.2em',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  itemTitle: {
    position: 'relative',
    top: '-8px',
    color: theme.palette.secondary.main,
    fontWeight: 700,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  boldDivider: {
    height: '3px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  titleDot: {
    display: 'inline-block',
    height: '13px',
    width: '13px',
    position: 'relative',
    top: '-10px',
    left: '-7px',
    backgroundColor: '#fff',
    marginRight: theme.spacing(2),
    borderColor: theme.palette.secondary.main,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderRadius: '50%',
  },
  more: {
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(3),
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '1.2em',
  },
};
