import { darken } from '@mui/material/styles';
import { theme } from '../../styles/theme';

export const styleClassesContactForLoggedIn = {
  inputContainer: {
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    backgroundColor: '#f2f2f2',
    padding: theme.spacing(4),
  },
  boxTitle: {
    color: darken(theme.palette.primary.main, 0.25),
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
  },
  boxSubtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontSize: '1.5rem',
  },
  content: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  divider: {
    height: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  saveButton: {
    marginTop: theme.spacing(1),
    fontSize: '1.3em',
    textTransform: 'none',
  },
  closeButton: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: '1.3em',
    textTransform: 'none',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contentDivider: {
    margin: `${theme.spacing(1)} 43% ${theme.spacing(3)}`,
    height: '4px',
    backgroundColor: theme.palette.primary.main,
  },
};
