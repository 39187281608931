/**
 * Calculate total expenses value
 *
 * @param {string} rateValue - rate variable from expenses dialog form
 * @param {string} numberOfRatesValue - number of rates variable from expenses dialog form
 * @returns {number}
 */
export const calculateTotalExpenses = (rateValue, numberOfRatesValue) => {
  if (rateValue && numberOfRatesValue) {
    return parseFloat(rateValue * numberOfRatesValue).toFixed(2);
  }

  return 0;
};

/**
 * Calculate grant percentage value
 *
 * @param {string} grantValue - grant variable from expenses dialog form
 * @param {string} qualifiedExpensesValue - qualified expenses variable from expenses dialog form
 * @returns {number}
 */
export const calculateCoFinancingPercent = (grantValue, qualifiedExpensesValue) => {
  if (grantValue && qualifiedExpensesValue) {
    return parseFloat(grantValue / qualifiedExpensesValue * 100).toFixed(2);
  }

  return '0';
};

/**
 * Calculate eligible expenses value
 *
 * @param {string} rateValue - rate value from expenses dialog form
 * @param {string} rateCount - rate count from expenses dialog form
 * @returns {number | string}
 */
export const calculateEligibleExpenses = (rateValue, rateCount) => {
  if (rateValue && rateCount) {
    return parseFloat(rateValue * rateCount).toFixed(2);
  }

  return '0';
};
