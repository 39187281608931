import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';

/**
 * ProjectImplementationConcept Pdf element.
 *
 * @returns {ProjectImplementationConceptPdf}
 */
function ProjectImplementationConceptPdf() {
  return (
    <View style={printStyle.table}>
      <RowField name="project_implementation_concept_preparation_for_announcement_of_the_competition" isSingleRow />
      <RowField name="project_implementation_concept_launching_the_competition" isSingleRow />
      <RowField name="project_implementation_concept_applications_evaluation" isSingleRow />
      <RowField name="project_implementation_concept_create_contracts" isSingleRow />
      <RowField name="project_implementation_concept_implementation_contracts" isSingleRow />
      <RowField name="project_implementation_concept_settlement_contracts" isSingleRow />
      <RowField name="project_implementation_concept_operators_checks" isSingleRow />
      <RowField name="project_implementation_concept_jw_reporting" isSingleRow />
      <RowField name="project_implementation_concept_sustainability_and_impact_of_project_results" isSingleRow />
    </View>
  );
}

export default ProjectImplementationConceptPdf;
