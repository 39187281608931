import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * InnovatorLabProgramConcept Pdf element.
 *
 * @returns {InnovatorLabProgramConceptPdf}
 */
function InnovatorLabProgramConceptPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Struktura programu */}
        <RowField name="innovator_lab_program_concept_program_structure" isSingleRow />
        {/* Etykieta: Działania w ramach koncepcji */}
        <RowField name="innovator_lab_program_concept_activities_within_concept" isSingleRow />
        {/* Nabór */}
        <RowField name="innovator_lab_program_concept_recruitment" isSingleRow />
        {/* Preselekcja */}
        <RowField name="innovator_lab_program_concept_preselection" isSingleRow />
        {/* Mentoring */}
        <RowField name="innovator_lab_program_concept_mentoring" isSingleRow />
        {/* Etykieta: Przyjęte metody pracy z pomysłodawcami */}
        <RowField name="innovator_lab_program_concept_working_methods" isSingleRow />
        {/* Sposoby badania potrzeby pomysłodawców rozpoczynających udział w programie */}
        <RowField name="innovator_lab_program_concept_research_methods" isSingleRow />
        {/* Przyjęte metody i narzędzia mentoringu z uwzględnieniem zidentyfikowanych potrzeb pomysłodawców */}
        <RowField name="innovator_lab_program_concept_mentoring_methods_and_tools" isSingleRow />
        {/* Tryb pracy z pomysłodawcami */}
        <RowField name="innovator_lab_program_concept_working_mode" isSingleRow />
        {/* Planowane rodzaje wsparcia pomysłodawców wraz z uzasadnieniem w kontekście potrzeb grupy docelowej */}
        <RowField name="innovator_lab_program_concept_types_of_support_plans" isSingleRow />
        {/* Sposób weryfikacji hipotez biznesowych pomysłodawców w relacji z otoczeniem rynkowym (w tym potencjalnymi
         klientami) oraz rodzaje działań następczych */}
        <RowField name="innovator_lab_program_concept_verifying_credibility_method" isSingleRow />
        {/* Zakres pisemnej końcowej informacji zwrotnej udzielanej pomysłodawcom */}
        <RowField name="innovator_lab_program_concept_information_written_scope" isSingleRow />
        {/* Etykieta: Opis ogólnych założeń koncepcji programu */}
        <RowField name="innovator_lab_program_concept_description_general_program_assumptions_concept" isSingleRow />
        {/*  Warunki i zasady przerywania uczestnictwa w programie w przypadku braku postępów Pomysłodawcy
       w realizacji opisanych działań i/lub braku zaangażowania Pomysłodawcy */}
        <RowField name="innovator_lab_program_concept_discontinuation_participation_conditions" isSingleRow />
        {/* Narzędzia i metody badania postępu i prawidłowości prac zaplanowanych wspólnie z Pomysłodawcą
      w tym planowane typy kamieni milowych (śródokresowe i końcowe) */}
        <RowField name="innovator_lab_program_concept_methods_research_progress" isSingleRow />
        {/* Przewidywany zakres i zasady współpracy z Partnerami (jeśli dotyczy) */}
        <RowField name="innovator_lab_program_concept_cooperative_principles" isSingleRow />
        {/* Sposoby zapewnienia skutecznego wsparcia Pomysłodawców z obszarów całej Polski */}
        <RowField name="innovator_lab_program_concept_ways_effective_support" isSingleRow />
        {/* Działania informacyjno-promocyjne programu */}
        <RowField name="innovator_lab_program_concept_program_information_and_promotion_activities" isSingleRow />
        {/* Założone rezultaty programu i prawdopodobieństwo ich osiągnięcia */}
        <RowField name="innovator_lab_program_concept_program_results" isSingleRow />
        {/* Informacje potwierdzające skuteczność zastosowanej koncepcji */}
        <RowField name="innovator_lab_program_concept_effectiveness_confirmation" isSingleRow />
        {/* Zidentyfikowane ryzyka oraz działania planowane do podjęcia/podjęte w celu
      ich eliminacji lub minimalizacji prawdopodobieństwa ich wystąpienia */}
        <RowField name="innovator_lab_program_concept_identified_risks" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default InnovatorLabProgramConceptPdf;
