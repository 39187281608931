import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../../Context';
import RowField from '../../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../../_constants';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Internationalization Module Essence Property Protection Pdf element.
 *
 * @returns {InternationalizationModuleEssencePropertyProtectionPdf}
 */
function InternationalizationModuleEssencePropertyProtectionPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  // eslint-disable-next-line max-len
  const otherTicketModeId = '/lsi/recruitments/api/internationalization-module-essence-ticket-modes/41d41d5f-5be9-4143-8e89-3745eede2123';

  return (
    <CollectionWrapper name="internationalization_module_essence_property_protection">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.applicant_name`}
                  value={item.applicant_name}
                  oldValue={previousRowData?.applicant_name}
                  externalDictionary={combinedApplicantName}
                />
                <RowField
                  name={`${collectionName}.${index}.title`}
                  value={item.title}
                  oldValue={previousRowData?.title}
                />
                <RowField
                  name={`${collectionName}.${index}.ticket_category`}
                  value={item.ticket_category}
                  oldValue={previousRowData?.ticket_category}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceTicketCategories.name}
                />
                <RowField
                  name={`${collectionName}.${index}.ticket_mode`}
                  value={item.ticket_mode}
                  oldValue={previousRowData?.ticket_mode}
                  dictionaryName={DICTIONARIES.internationalizationModuleEssenceTicketModes.name}
                />
                {item.ticket_mode === otherTicketModeId && (
                  <RowField
                    name={`${collectionName}.${index}.other_ticket_mode`}
                    value={item.other_ticket_mode}
                    oldValue={previousRowData?.other_ticket_mode}
                  />
                )}
                <RowField
                  name={`${collectionName}.${index}.protection_scope`}
                  value={item.protection_scope}
                  oldValue={previousRowData?.protection_scope}
                />
                <RowField
                  name={`${collectionName}.${index}.countries`}
                  value={item.countries}
                  oldValue={previousRowData?.countries}
                  dictionaryName={DICTIONARIES.countries.name}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default InternationalizationModuleEssencePropertyProtectionPdf;
