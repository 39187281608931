import { theme } from './theme';

export const usePrintoutStyles = {
  paper: {
    padding: theme.spacing(5),
  },
  backdrop: {
    zIndex: 9999,
  },
};
