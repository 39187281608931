import {
  Dialog,
  DialogContent,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import ExpertApplicationFormData from '../../../Pages/Expert/ApplicationFormData';

/**
 * Expert personal data
 *
 * @param {object} props - root props
 * @param {Function} props.closeHandler - function to close modal
 * @param {boolean} props.isReadonly - is read only
 * @returns {ExpertPersonalData}
 */
export default function ExpertPersonalData({
  closeHandler, isReadonly,
}) {
  const [dialogOpen, setDialogOpen] = useState(true);

  return (
    <Dialog
      color="secondary"
      fullWidth
      maxWidth="lg"
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false);
        closeHandler();
      }}
      aria-label="expertPersonalData"
      scroll="body"
    >
      <DialogContent sx={{ p: (theme) => theme.spacing(3) }}>
        <ExpertApplicationFormData
          closeHandler={() => {
            setDialogOpen(false);
            closeHandler();
          }}
          isReadonly={isReadonly}
        />
      </DialogContent>
    </Dialog>
  );
}

ExpertPersonalData.propTypes = {
  closeHandler: PropTypes.func,
  isReadonly: PropTypes.bool,
};

ExpertPersonalData.defaultProps = {
  closeHandler: null,
  isReadonly: false,
};
