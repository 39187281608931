import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Human Resources Potential Rd Infrastructure Pdf element.
 *
 * @returns {HumanResourcesPotentialRdInfrastructurePdf}
 */
function HumanResourcesPotentialRdInfrastructurePdf() {
  const { cooperationStatuses } = ELEMENTS_DICTIONARIES_MAP;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <CollectionWrapper name="human_resources_potential_rd_infrastructure_human_resources">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                  <RowField
                    name={`${collectionName}.${index}.first_name`}
                    value={item.first_name}
                    oldValue={previousRowData?.first_name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.last_name`}
                    value={item.last_name}
                    oldValue={previousRowData?.last_name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.education`}
                    value={item.education}
                    oldValue={previousRowData?.education}
                  />
                  <RowField
                    name={`${collectionName}.${index}.employment_place`}
                    value={item.employment_place}
                    oldValue={previousRowData?.employment_place}
                  />
                  <RowField
                    name={`${collectionName}.${index}.role_in_project`}
                    value={item.role_in_project}
                    oldValue={previousRowData?.role_in_project}
                    dictionaryName={DICTIONARIES.roleInProjects.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.cooperation_status`}
                    value={item.cooperation_status}
                    oldValue={previousRowData?.cooperation_status}
                    dictionaryName={DICTIONARIES.cooperationStatuses.name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.experience`}
                    value={item.experience}
                    oldValue={previousRowData?.experience}
                  />
                  {item.cooperation_status === cooperationStatuses.plannedPerson.id && (
                    <RowField
                      name={`${collectionName}.${index}.job_description`}
                      value={item.job_description}
                      oldValue={previousRowData?.job_description}
                    />
                  )}
                  <RowField
                    name={`${collectionName}.${index}.duties_scope`}
                    value={item.duties_scope}
                    oldValue={previousRowData?.duties_scope}
                  />
                  <RowField
                    name={`${collectionName}.${index}.commitment_dimension`}
                    value={item.commitment_dimension}
                    oldValue={previousRowData?.commitment_dimension}
                  />
                </FieldSorter>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        <RowField name="human_resources_potential_rd_infrastructure_management_method" />
      </FieldSorter>
    </View>
  );
}

export default HumanResourcesPotentialRdInfrastructurePdf;
