import PropTypes from 'prop-types';
import { useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import uniqid from 'uniqid';

/**
 * MUI controlled DesktopDatePicker component.
 *
 * @param {object} props - root props
 * @param {boolean} props.ampm - is ampm
 * @param {object|string|number|Date} props.value - initial value
 * @param {boolean} props.disabled - is disabled
 * @param {Array} props.views - views
 * @param {string|Date} props.minDate - minDate
 * @param {string|Date} props.maxDate - maxDate
 * @param {string} props.name - name
 * @param {boolean} props.error - is error
 * @param {string} props.helperText - helper text
 * @param {string} props.ariaLabel - aria label
 * @param {string} props.label - label
 * @param {Function} props.onChange - function invoked on option accept
 * @param {Function} props.onBlur - function passed after lost focus
 * @param {string} props.format - input format
 * @param {string} props.mask - mask
 * @param {boolean} props.fullWidth - full width
 * @param {string} props.placeholder - placeholder text
 * @param {boolean} props.required - is required
 * @returns {CustomDesktopDatePicker}
 */
export default function CustomDesktopDatePicker({
  onChange,
  value,
  name,
  disabled,
  ariaLabel,
  label,
  ampm,
  error,
  helperText,
  mask,
  format,
  views,
  onBlur,
  minDate,
  maxDate,
  fullWidth,
  placeholder,
  required,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DesktopDatePicker
      label={label}
      ampm={ampm.toString()}
      value={value}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      clearable
      disabled={disabled}
      views={views}
      slotProps={{
        textField: {
          id: name,
          value: value ? new Date(value) : null,
          variant: 'outlined',
          fullWidth,
          helperText,
          error: !!error,
          'aria-label': ariaLabel,
          color: 'secondary',
          required,
          onClick: () => !disabled && setIsOpen((prevState) => !prevState),
          onBlur,
          placeholder,
        },
      }}
      onChange={(newDate) => {
        onChange({
          target: {
            name,
            value: newDate,
          },
        });
      }}
      format={format}
      mask={mask}
      onClose={() => setIsOpen(false)}
      open={isOpen}
    />
  );
}

CustomDesktopDatePicker.propTypes = {
  ampm: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOf([null]),
    PropTypes.instanceOf(Object),
  ]),
  disabled: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.string),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  format: PropTypes.string,
  mask: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

CustomDesktopDatePicker.defaultProps = {
  ampm: false,
  value: null,
  disabled: false,
  views: ['year', 'month', 'day'],
  minDate: null,
  maxDate: null,
  name: uniqid(),
  error: false,
  helperText: '',
  ariaLabel: undefined,
  label: undefined,
  onBlur: () => {},
  format: 'yyyy-MM-dd',
  mask: '____-__-__',
  fullWidth: false,
  placeholder: 'rrrr-mm-dd',
  required: false,
};
