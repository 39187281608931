import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * GreeningModuleEssence component
 *
 * @returns {GreeningModuleEssence}
 */
function GreeningModuleEssence() {
  const {
    control, watch, setValue, resetField, reset,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.implementationScopes, DICTIONARIES.solutionInnovationLevels);

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'greening_module_scope',
  });

  const greeningModuleScopeConfig = fieldsConfig.greening_module_scope;
  const subfields = greeningModuleScopeConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = greeningModuleScopeConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);
  const excludedImplementationScope = [
    '/lsi/recruitments/api/implementation-scopes/5880f33c-71d0-4fed-84a7-0b68b1e6941c',
    '/lsi/recruitments/api/implementation-scopes/2ef0a13a-66d5-4b24-b7f0-76e639560072',
    '/lsi/recruitments/api/implementation-scopes/247997db-f427-49d1-89bb-cea07fc3c55e',
  ];

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Cel modułu */}
      <FormField name="greening_module_essence_module_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres realizacji modułu (zaplanowane efekty środowiskowe) */}
      <LabeledFieldRow fieldConfig={greeningModuleScopeConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => {
          const greeningModuleScopeValue = watch(`greening_module_scope.${index}.implementation_scope`);

          return (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                'greening_module_scope',
                index,
              )}
              isReadonly={isReadonly}
              itemIndex={index}
              fieldsOrder={Object.keys(subfields)}
              key={field.id}
              buttonRemoveVisible={fields.length > minRowNumber}
              isIndexed
            >
              {/* Zakres realizacji modułu (zaplanowane efekty środowiskowe) */}
              <FormField name={`greening_module_scope.${index}.implementation_scope`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (excludedImplementationScope.includes(greeningModuleScopeValue)) {
                        reset((formValues) => ({
                          ...formValues,
                          [`greening_module_scope.${index}.solution_innovation_level`]: FIELD_TYPES_MAP.limited_choice,
                          [`greening_module_scope.${index}.innovation_level_justification`]: FIELD_TYPES_MAP.text,
                        }));
                      }
                    }}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.implementationScopes.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Uzasadnienie racjonalności podejmowanych działań dla planowanego efektu środowiskowego w */}
              <FormField name={`greening_module_scope.${index}.action_justification`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {!excludedImplementationScope.includes(greeningModuleScopeValue) && (
                <>
                  {/* Poziom innowacyjności rozwiązania */}
                  <FormField name={`greening_module_scope.${index}.solution_innovation_level`}>
                    {({
                      onChange, value, name, filterOptions, onBlur, error,
                    }) => (
                      <CustomAutocomplete
                        id={name}
                        initialValue={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        options={filterOptions(get(DICTIONARIES.solutionInnovationLevels.name))}
                        disabled={isReadonly}
                        error={error}
                      />
                    )}
                  </FormField>
                  {/* Uzasadnienie poziomu innowacyjności rozwiązania */}
                  <FormField name={`greening_module_scope.${index}.innovation_level_justification`}>
                    {({
                      onChange, value, name, maxLength, onBlur, error,
                    }) => (
                      <TextField
                        multiline
                        id={name}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        defaultValue={value}
                        variant="outlined"
                        InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                        inputProps={{ maxLength }}
                        disabled={isReadonly}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  </FormField>
                </>
              )}
            </CollectionFieldContainer>
          );
        })}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="TtYWW4j5Hs9bx21"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Inwestycja typu "greenfield" */}
      <FormField name="greening_module_essence_is_greenfield">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'greening_module_essence_other_investment_comparison',
                  { defaultValue: FIELD_TYPES_MAP.text }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Inwestycja typu "greenfield" */}
      {watch('greening_module_essence_is_greenfield') === BOOLEAN_VALUES.TRUE && (
        <FormField name="greening_module_essence_other_investment_comparison">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              multiline
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
    </FieldSorter>
  );
}

export default GreeningModuleEssence;
