/**
 * Expert statuses.
 *
 * @type {object}
 */
export const EXPERT_STATUSES = {
  precandidate: 'precandidate',
  expert: 'expert',
  terminatedContract: 'terminated_contract',
  signedContract: 'signed_contract',
  candidate: 'candidate',
};

export const EXPERT_STATUSES_TRANSLATION = {
  [EXPERT_STATUSES.expert]: 'Ekspert',
  [EXPERT_STATUSES.signedContract]: 'Umowa podpisana',
  [EXPERT_STATUSES.candidate]: 'Kandydat',
  [EXPERT_STATUSES.precandidate]: 'Wstępny kandydat',
  [EXPERT_STATUSES.terminatedContract]: 'Umowa rozwiązana',
};
