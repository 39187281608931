import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  MenuItem,
  Paper,
  Portal,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';
import { BlobProvider } from '@react-pdf/renderer';
import { request } from '../../../_services';
import {
  ADDITIONAL_MODULE_IDS,
  API_ROUTE,
  PANEL_TYPES,
} from '../../../_constants';
import {
  iriToId,
  getTransformedPrintoutAssessmentSheet,
} from '../../../_helpers';
import PrintoutOpener from '../PrintApplication/PrintoutOpener';
import PrintoutAssessmentSheetWithModules from './PrintoutAssessmentSheetWithModules';
import PrintoutAssessmentSheetWithoutModules from './PrintoutAssessmentSheetWithoutModules';
import { useGlobalContext } from '../../../Context';
import { usePrintoutStyles } from '../../../styles/printout';

/**
 * PrintoutAssessmentSheet element.
 *
 * @param {object} props - root props
 * @param {string} props.evaluationSheetId - evaluation sheet id
 * @param {string} props.applicationId - application id
 * @returns {PrintoutAssessmentSheet}
 */
function PrintoutAssessmentSheet({
  evaluationSheetId, applicationId,
}) {
  const [isLoading, setLoading] = useState(false);
  const [printoutData, setPrintoutData] = useState({});
  const { notify } = useGlobalContext();

  const sortByObject = {
    [ADDITIONAL_MODULE_IDS.kryteriaOgolem]: 0,
    [ADDITIONAL_MODULE_IDS.kryteriaRankingujace]: 1,
    [ADDITIONAL_MODULE_IDS.kryteriaModulowe]: 2,
    [ADDITIONAL_MODULE_IDS.ocenaFinansowa]: 3,
    [ADDITIONAL_MODULE_IDS.modulowaFinansowa]: 4,
  };

  const getEvaluationSheet = async () => {
    setLoading(true);

    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/read-printable-data`);

    if (statusSuccess) {
      const applicantName = await getApplicantName();
      const {
        modules, panelType,
      } = await getExpertPanel(iriToId(payload.expertPanel));

      if (panelType && applicantName) {
        const additionalDataByPanelType = panelType === PANEL_TYPES.module
          ? getTransformedPrintoutAssessmentSheet(payload.items, modules)
            .sort((a, b) => sortByObject[a.criteriaType] - sortByObject[b.criteriaType])
          : payload.items;

        setPrintoutData({
          items: additionalDataByPanelType,
          application: {
            ...payload.application,
            applicantName,
          },
          panelType,
        });
      }

      if (!panelType || !applicantName) {
        notify('Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.', 'error');
      }
    }

    setLoading(false);
  };

  const getExpertPanel = async (expertPanelId) => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertPanels}/${expertPanelId}/read-printable-data`);

    if (statusSuccess) {
      return {
        modules: payload.modules,
        panelType: payload.criteriaSet.panelType,
      };
    }

    notify('Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.', 'error');

    return {
      modules: [],
      panelType: null,
    };
  };

  const getApplicantName = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.applications}/${applicationId}/field_data/applicant_name`);

    if (statusSuccess) {
      return payload;
    }

    return null;
  };

  const getBackdropComponent = () => (
    <Portal>
      <Backdrop open={isLoading} sx={usePrintoutStyles.backdrop}>
        <Paper elevation={3} sx={usePrintoutStyles.paper}>
          <Typography>
            Proszę czekać... Trwa generowanie wydruku arkusza ocen projektu.
          </Typography>
        </Paper>
      </Backdrop>
    </Portal>
  );

  const getMenuItem = () => (
    <MenuItem
      key={uniqid()}
      variant="right-border"
      id={uniqid()}
      onClick={getEvaluationSheet}
    >
      {isLoading ? 'Generowanie PDF...' : 'Wydruk arkusza'}
    </MenuItem>
  );

  if (Object.values(printoutData).length === 0) {
    return (
      <>
        {getMenuItem()}
        {isLoading && getBackdropComponent()}
      </>
    );
  }

  return (
    <BlobProvider document={printoutData?.panelType === PANEL_TYPES.module ? (
      <PrintoutAssessmentSheetWithModules application={printoutData.application} modules={printoutData.items} />
    ) : (
      <PrintoutAssessmentSheetWithoutModules
        application={printoutData.application}
        criteriaItems={printoutData.items}
      />
    )}
    >
      {({
        url, loading, error,
      }) => {
        if (url && !loading && !error) {
          setLoading(loading);
          setPrintoutData({});
        }

        if (!url && loading) {
          getBackdropComponent();
        }

        return (
          <PrintoutOpener
            loading={loading}
            error={error}
            url={url}
            backdrop={getBackdropComponent}
          >
            {getMenuItem()}
          </PrintoutOpener>
        );
      }}
    </BlobProvider>
  );
}

export default PrintoutAssessmentSheet;

PrintoutAssessmentSheet.propTypes = {
  evaluationSheetId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
};
