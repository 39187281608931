import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import { useApplicationToPdfContext } from '../../../../Context';
import InternationalizationModuleEssenceProductInternationalizationPdf
  from './InternationalizationModuleEssence/InternationalizationModuleEssenceProductInternationalization.pdf';
import InternationalizationModuleEssencePromotionPdf
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePromotion.pdf';
import InternationalizationModuleEssencePropertyProtectionPdf
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePropertyProtection.pdf';
import InternationalizationModuleEssencePropertyDefencePdf
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePropertyDefence.pdf';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../../_constants';

/**
 * InternationalizationModuleEssence element.
 *
 * @returns {InternationalizationModuleEssencePdf}
 */
function InternationalizationModuleEssencePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const { internationalizationModuleEssenceScopes } = ELEMENTS_DICTIONARIES_MAP;

  const internationalizationModuleEssenceScope = initialFormData?.internationalization_module_essence_scope || [];

  return (
    <>
      <View style={printStyle.table}>
        <RowField name="internationalization_module_essence_consulting_services" />
        <RowField
          name="internationalization_module_essence_scope"
          dictionaryName={DICTIONARIES.internationalizationModuleEssenceScopes.name}
        />
        <RowField name="internationalization_module_essence_module_purpose" />
      </View>
      {internationalizationModuleEssenceScope
        .includes(internationalizationModuleEssenceScopes.productsInternationalization.id) && (
        <>
          {/* Tabela "Produkt będący przedmiotem umiędzynarodowienia (promocji zagranicznej)"" */}
          <InternationalizationModuleEssenceProductInternationalizationPdf />
          {/* Tabela "Wydarzenia promujące, w których będzie uczestniczył wnioskodawca" */}
          <InternationalizationModuleEssencePromotionPdf />
        </>
      )}
      {/* Tabela "Uzyskanie ochrony własności przemysłowej poza Polską" */}
      {internationalizationModuleEssenceScope
        .includes(internationalizationModuleEssenceScopes.propertyProtection.id) && (
        <InternationalizationModuleEssencePropertyProtectionPdf />
      )}
      {internationalizationModuleEssenceScope
        .includes(internationalizationModuleEssenceScopes.propertyDefence.id) && (
        <InternationalizationModuleEssencePropertyDefencePdf />
      )}
    </>
  );
}

export default InternationalizationModuleEssencePdf;
