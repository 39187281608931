import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';

/**
 * BusinessModelDescription element renderer.
 *
 * @returns {BusinessModelDescription}
 */
export function BusinessModelDescription() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Etykieta: Propozycja wartości */}
      <FormField name="business_model_description_value_proposition" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Zidentyfikowany problem/problemy na które odpowiada produkt */}
      <FormField name="business_model_description_product_addressed_problems">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zidentyfikowana potrzeba/potrzeby klientów na które odpowiada produkt */}
      <FormField name="business_model_description_product_addressed_customer_needs">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Sparametryzowane przewagi konkurencyjne produktu w stosunku do istniejących na
      rynku rozwiązań konkurencyjnych */}
      <FormField name="business_model_description_product_competetive_advantages">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Segment klientów */}
      <FormField name="business_model_description_customer_segment" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Zidentyfikowana grupa/grupy odbiorców produktu */}
      <FormField name="business_model_description_product_audience_group">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Rynek docelowy i zapotrzebowanie rynkowe na produkt  */}
      <FormField name="business_model_description_product_market_and_demand">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Relacje z klientami */}
      <FormField name="business_model_description_customer_relations" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Zidentyfikowane sposoby pozyskiwania i zatrzymywania klientów */}
      <FormField name="business_model_description_customer_attraction_and_retention">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zidentyfikowane kanały komunikacji  */}
      <FormField name="business_model_description_identified_communication_channels">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zidentyfikowane kanały dystrybucji i sprzedaży */}
      <FormField name="business_model_description_identified_distribution_and_sales_channels">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: l. Kluczowe działania */}
      <FormField name="business_model_description_key_activities" contextHelpLabel>
        {() => null}
      </FormField>
      {/* m. Zidentyfikowane działania do podjęcia, aby oferować produkt dla klientów wybranymi kanałami, relacjami */}
      <FormField name="business_model_description_product_offer_actions_through_selected_channels">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* n. Zdiagnozowane czynniki strategiczne warunkujące prawidłową realizację projektu, wdrożenie produktu  */}
      <FormField name="business_model_description_strategic_factors_for_proper_implementation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: o. Strumienie przychodów */}
      <FormField name="business_model_description_revenue_streams" contextHelpLabel>
        {() => null}
      </FormField>
      {/* p. Zidentyfikowane źródła przychodów ze sprzedaży produktu */}
      <FormField name="business_model_description_revenue_sources_from_product_sales">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* r. Polityka cenowa produktu  */}
      <FormField name="business_model_description_product_pricing_policy">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* s. Uzasadnienie dla szacowanej wysokości przychodów */}
      <FormField name="business_model_description_estimated_revenue_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: t. Struktura kosztów */}
      <FormField name="business_model_description_costs_structure" contextHelpLabel>
        {() => null}
      </FormField>
      {/* u. Nakłady finansowe potrzebne do realizacji przedsięwzięcia */}
      <FormField name="business_model_description_project_financial_outlay">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Kanały */}
      <FormField name="business_model_description_channels" contextHelpLabel>
        {() => null}
      </FormField>
    </FieldSorter>
  );
}
