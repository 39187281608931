import { Link } from 'react-router-dom';
import { visuallyHidden } from '@mui/utils';
import {
  Typography,
  useMediaQuery,
} from '@mui/material';
import logo from '../images/logo_LSI_kolor.svg';
import { ACTION_TYPES } from '../_constants';
import { useGlobalContext } from '../Context';
import { theme } from '../styles/theme';

const logoStyles = {
  root: {
    textAlign: 'left',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
};

/**
 * App logo component.
 *
 * @returns {Logo}
 */
export default function Logo() {
  const classes = logoStyles;
  const isBreakpointUpLg = useMediaQuery((t) => t.breakpoints.up('lg'));
  const {
    handleAction, root: {
      isLock, confirmMessage,
    },
  } = useGlobalContext();
  const linkClickHandler = (event) => {
    // eslint-disable-next-line no-alert,no-restricted-globals
    if (isLock && !confirm(confirmMessage)) {
      return event.preventDefault();
    }

    return handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  };

  return (
    <div style={classes.root}>
      <Typography
        style={visuallyHidden}
        component="h1"
      >
        Lokalny System Informatyczny
      </Typography>
      <Link to="/" onClick={linkClickHandler}>
        <img
          id="Rtu9g40Q5ZwGKBx"
          src={logo}
          alt="logo Polska Agencja Rozwoju Przedsiębiorczości, grupa PFR, Lokalny System Informatyczny"
          style={{
            ...classes.logo,
            maxWidth: isBreakpointUpLg ? '70%' : '148px',
          }}
        />
      </Link>
    </div>
  );
}
