import {
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { CHARS_LIMIT } from '../../_constants';
import CharsCounter from '../../Features/CharsCounter';

/**
 * Step configurator form component.
 *
 * @returns {StepConfiguratorForm} - component
 */
function StepConfiguratorForm() {
  const { control } = useFormContext();

  const {
    fields,
  } = useFieldArray({
    control,
    name: 'steps',
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="formName">
          Nazwa formularza
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <Controller
            name="formName"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                variant="outlined"
                defaultValue={value}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} />),
                }}
                inputProps={{ maxLength: CHARS_LIMIT.LONG }}
                onChange={onChange}
                onBlur={onBlur}
                required
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} display="flex" alignItems="center">
        <InputLabel htmlFor="instruction">
          Instrukcja
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth>
          <Controller
            name="instruction"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                variant="outlined"
                defaultValue={value}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.STORY} />),
                }}
                inputProps={{ maxLength: CHARS_LIMIT.STORY }}
                onChange={onChange}
                onBlur={onBlur}
                required
                rows={4}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold" mb={2}>
          Kroki:
        </Typography>
      </Grid>
      {fields.map(({ id }, index) => (
        <Fragment key={id}>
          <Grid item xs={12} md={4} display="flex" alignItems="center">
            <InputLabel htmlFor={`steps.${index}.step`}>
              {`Krok ${index + 1}`}
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <Controller
                name={`steps.${index}.step`}
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    variant="outlined"
                    defaultValue={value}
                    error={!!error}
                    helperText={error?.message}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}

export default StepConfiguratorForm;
