import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';

/**
 * Project financial resources element renderer.
 *
 * @returns {ProjectFinancialResources}
 */
export function ProjectFinancialResources() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Opis zasobów finansowych niezbędnych do realizacji projektu (posiadanych lub
        możliwych do pozyskania źródeł finansowania) oraz na zapewnienie płynności finansowej
        z uwzględnieniem dofinansowania w trakcie realizacji projektu */}
      <FormField name="project_financial_resources_project_implementation_necessary_financial_resources_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* W przypadku, gdy wnioskodawca będzie korzystał z zewnętrznych źródeł finansowania projektu
      należy podać rodzaj finansowania (kredyt bankowy, pożyczka, leasing) oraz numer i datę
      wystawienia dokumentu potwierdzającego finansowanie projektu */}
      <FormField name="project_financial_resources_type_of_financing">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Plan finansowania projektu, w tym określenie etapów realizacji projektu, terminów
      tych etapów (kwartalnych) oraz kosztów dotyczących każdego z etapów */}
      <FormField name="project_financial_resources_financing_plan">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
