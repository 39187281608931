import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * National Key Cluster Participation Pdf element.
 *
 * @returns {NationalKeyClusterParticipationPdf}
 */
function NationalKeyClusterParticipationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="national_key_cluster_participation_number_closed_years" />
        <RowField name="national_key_cluster_participation_member" />
        {initialFormData?.national_key_cluster_participation_member === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField name="national_key_cluster_participation_name" />
            <RowField name="national_key_cluster_participation_date" />
          </>
        )}
      </FieldSorter>
    </View>
  );
}

export default NationalKeyClusterParticipationPdf;
