import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ServicesImplementation pdf element.
 *
 * @returns {ServicesImplementationPdf}
 */
function ServicesImplementationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="services_implementation_service_provision_standard" />
        <RowField name="services_implementation_control_mechanism" />
      </FieldSorter>
    </View>
  );
}

export default ServicesImplementationPdf;
