export const PRINT_STATUSES = {
  queued: 'queued',
  inProgress: 'in_progress',
  failed: 'failed',
  finished: 'finished',
};

export const PRINT_STATUSES_TRANSLATIONS = {
  [PRINT_STATUSES.queued]: 'W kolejce',
  [PRINT_STATUSES.inProgress]: 'W trakcie przygotowania',
  [PRINT_STATUSES.failed]: 'Wystąpił błąd',
  [PRINT_STATUSES.finished]: 'Gotowy do pobrania',
};
