/**
 * Gets property from object by dot separated string.
 *
 * @param {object} object - target object
 * @param {string} path - dot path
 * @param {any} defaultValue - default value on failure
 * @returns {any}
 */
const resolvePath = (object, path, defaultValue = '') => path
  .split('.')
  .reduce((o, p) => (o ? o[p] : defaultValue), object);

/*
 * Registered processors array.
 * Note that order is important, after each run, the data is overwritten by next processor.
 * Feel free to extend this.
 */
const processors = [
  /**
   * Automatically renders nested object data (field defined as dot separated string).
   * There is no need for manually append `renderCell` function to every column
   * that fields are dot separated string.
   *
   * @param {object[]} columns - data grid columns
   * @returns {object[]}
   */
  (columns) => columns.map((columnData) => {
    const hasValueGetter = 'valueGetter' in columnData;
    if (hasValueGetter || !columnData?.field?.includes('.')) {
      return columnData;
    }

    return {
      ...columnData,
      valueGetter: (params) => resolvePath(params.row, columnData.field),
    };
  }),
];

/**
 * Data grid columns processor.
 *
 * @param {object[]} columns - data grid columns
 * @returns {object[]}
 */
export const columnsProcessor = (columns) => {
  let processedColumns = Array.from(columns);
  processors.forEach((processorFunc) => { processedColumns = processorFunc(processedColumns); });

  return processedColumns;
};
