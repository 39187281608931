import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import {
  API_ROUTE,
  CHARS_LIMIT,
  URL,
} from '../../../_constants';
import { expertPersonalDataValidation } from './PersonalData.form.validation';
import {
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../Context';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { request } from '../../../_services';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import InfoPopover from '../../InfoPopover/InfoPopover';
import { handleError } from '../../../_helpers';
import { theme } from '../../../styles/theme';

const classes = {
  container: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    },
  },
};

/**
 * Personal data form for expert
 *
 * @param {object} props - root props
 * @param {object} props.citizenshipDictionary - dictionary for citizenship input
 * @param {object} props.user - user personal data
 * @param {Function} props.reloadData - reload data from API
 * @param {Function} props.closeHandler - function to close modal
 * @param {boolean} props.isReadonly - is read only
 * @returns {PersonalDataForm}
 */
export function PersonalDataForm({
  citizenshipDictionary, user, reloadData, closeHandler, isReadonly,
}) {
  const navigate = useNavigate();
  const personalData = useExpertPersonalDataContext();
  const { notify } = useGlobalContext();

  const {
    control, watch, clearErrors, handleSubmit, setValue, setError,
  } = useForm({
    defaultValues: {
      citizenship: personalData?.citizenship || '',
      tin: personalData?.tin || '',
      identityDocumentType: personalData?.identityDocumentType || '',
      identityDocumentNumber: personalData.identityDocumentNumber || '',
      runningBusiness: personalData.runningBusiness === null ? null : personalData.runningBusiness.toString(),
      nip: personalData.nip || '',
    },
    mode: 'all',
    shouldUnregister: true,
  });

  const runningBusinessFieldValue = watch('runningBusiness');
  const citizenshipFieldValue = watch('citizenship');

  /**
   * Handle form submit request.
   *
   * @param {object} formData - form data to send
   */
  const submitForm = async (formData) => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.expertPersonalData}/${personalData.id}`, {
      ...formData,
      runningBusiness: formData.runningBusiness === 'true',
    });

    if (statusSuccess) {
      notify('Wprowadzono dane osobowe', 'success');
      reloadData();
      window.scrollTo(0, 0);

      return;
    }

    handleError(setError, violations);

    notify('Wystąpił błąd podczas zapisu danych osobowych', 'error');
    window.scrollTo(0, 0);
  };
  const citizenshipPL = 'PL';

  return (
    <Grid
      container
      spacing={2}
      id="CxpBs2LU0nJJVtz"
      mb={2}
      sx={classes.container}
    >
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="7pXmJenYZPbDNQa">
          Imię
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            id="7pXmJenYZPbDNQa"
            name="firstName"
            defaultValue={user?.firstName}
            variant="outlined"
            disabled
            inputProps={{ readOnly: true }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="a5Tk3jEfGxBtq2L">
          Nazwisko
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            id="a5Tk3jEfGxBtq2L"
            name="lastName"
            defaultValue={user?.lastName}
            variant="outlined"
            disabled
            inputProps={{ readOnly: true }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="bMVKxaVB9kRrSjE">
          Email
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            id="bMVKxaVB9kRrSjE"
            name="email"
            defaultValue={user?.email}
            variant="outlined"
            disabled
            inputProps={{ readOnly: true }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="citizenship">
          Obywatelstwo
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            rules={expertPersonalDataValidation.citizenship}
            name="citizenship"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                id={name}
                textFieldProps={{
                  error: !!error,
                  inputProps: {
                    required: true,
                  },
                }}
                disabled={isReadonly}
                initialValue={value}
                onBlur={onBlur}
                onChange={(event) => {
                  onChange(event);
                  clearErrors(['nip', 'tin']);
                }}
                options={Object.entries(citizenshipDictionary).map(([id, label]) => ({
                  id,
                  label,
                }))}
                optionsMapKeys={['id', 'label']}
                error={error}
              />
            )}
          />
        </FormControl>
      </Grid>
      {citizenshipFieldValue === citizenshipPL && (
        <>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="3kjgUvHhkWgEjPH">
              PESEL
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <TextField
                helperText={!isReadonly && (
                  <Typography component="span" fontSize={12} color={theme.palette.text.brand}>
                    Wartość może zostać zmieniona w
                    <Link
                      style={{
                        marginLeft: theme.spacing(0.5),
                        color: theme.palette.brandGray.main,
                      }}
                      to={`${URL.users.account.settings}#34vfCTjdnNIVbhg`}
                    >
                      ustawieniach konta
                    </Link>
                  </Typography>
                )}
                id="3kjgUvHhkWgEjPH"
                name="pesel"
                defaultValue={user?.pesel}
                disabled
                variant="outlined"
                inputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
        </>
      )}
      {citizenshipFieldValue !== citizenshipPL && (
        <>
          <Grid item xs={12} md={3}>
            <InputLabel htmlFor="tin">
              TIN
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="tin"
                control={control}
                rules={{
                  required: citizenshipFieldValue !== citizenshipPL ? expertPersonalDataValidation.tin.required : {},
                }}
                render={({
                  field: {
                    onChange, value = '', name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.TIN} />),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.TIN,
                      required: citizenshipFieldValue !== citizenshipPL,
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="EgM6mV0bSjUkxUR">
          Numer telefonu
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <TextField
            helperText={!isReadonly && (
              <Typography component="span" fontSize={12} color={theme.palette.text.brand}>
                Wartość może zostać zmieniona w
                <Link
                  style={{
                    marginLeft: theme.spacing(0.5),
                    color: theme.palette.brandGray.main,
                  }}
                  to={`${URL.users.account.settings}#34vfCTjdnNIVbhg`}
                >
                  ustawieniach konta
                </Link>
              </Typography>
            )}
            id="EgM6mV0bSjUkxUR"
            name="phoneNumber"
            defaultValue={user?.phoneNumber}
            variant="outlined"
            disabled
            inputProps={{ readOnly: true }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="identityDocumentType" variant="noShorten">
          Rodzaj dokumentu tożsamości
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="identityDocumentType"
            control={control}
            rules={expertPersonalDataValidation.identityDocumentType}
            render={({
              field: {
                onChange, value = '', name,
                onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.TIN} />),
                }}
                inputProps={{
                  maxLength: CHARS_LIMIT.TIN,
                  required: true,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel htmlFor="identityDocumentNumber" variant="noShorten">
          Seria i numer dokumentu tożsamości
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="identityDocumentNumber"
            control={control}
            rules={expertPersonalDataValidation.identityDocumentNumber}
            render={({
              field: {
                onChange, value = '', name,
                onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.SHORT} />),
                }}
                inputProps={{
                  maxLength: CHARS_LIMIT.SHORT,
                  required: true,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3} textAlign="start">
        <InputLabel htmlFor="personalDataRunningBusiness" variant="noShorten">
          Czy ekspert usługi na rzecz PARP świadczył będzie i rozliczał w ramach prowadzonej działalności gospodarczej?
        </InputLabel>
        <InfoPopover noBorder>
          Zaznacz &quot;tak&quot; jeżeli zamierzasz się rozliczać z PARP jako osoba fizyczna prowadząca
          jednoosobową działalność gospodarczą.
        </InfoPopover>
      </Grid>
      <Grid item xs={12} md={7}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="runningBusiness"
            control={control}
            rules={expertPersonalDataValidation.runningBusiness}
            render={({
              field: {
                onChange,
                value = '',
                name,
              },
              fieldState: {
                error,
              },
            }) => (
              <>
                <RadioGroup
                  name={name}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(event, newValue);
                    if (newValue === 'false') {
                      setValue('nip', '');
                    }
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={isReadonly}
                    value="true"
                    control={<Radio color="secondary" />}
                    label="Tak"
                  />
                  <FormControlLabel
                    disabled={isReadonly}
                    value="false"
                    control={<Radio color="secondary" />}
                    label="Nie"
                  />
                </RadioGroup>
                <FormHelperText error={!!error}>{error?.message}</FormHelperText>
              </>
            )}
          />
        </FormControl>
      </Grid>
      {runningBusinessFieldValue === 'true' && (
        <>
          <Grid item xs={12} md={3}>
            <InputLabel required={citizenshipFieldValue === citizenshipPL} htmlFor="nip">
              NIP
            </InputLabel>
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Controller
                name="nip"
                control={control}
                rules={{
                  required: runningBusinessFieldValue === 'true' && citizenshipFieldValue === citizenshipPL
                    ? expertPersonalDataValidation.nip.required : {},
                  pattern: expertPersonalDataValidation.nip.pattern,
                }}
                render={({
                  field: {
                    onChange, value = '', name,
                    onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    onBlur={onBlur}
                    InputProps={{
                      endAdornment: (<CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.NIP} />),
                    }}
                    inputProps={{
                      maxLength: CHARS_LIMIT.NIP,
                      required: runningBusinessFieldValue === 'true' && citizenshipFieldValue === citizenshipPL,
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </>
      )}
      {!isReadonly && (
        <Grid item xs={12} mt={4}>
          <SaveCancelButtons
            saveButtonId="5DSiBTfJQp5aE31"
            cancelButtonId="IMLqCyVtbz5gSqo"
            cancelHandler={closeHandler ? () => closeHandler() : () => { navigate('/'); }}
            saveHandler={handleSubmit(submitForm)}
          />
        </Grid>
      )}
    </Grid>
  );
}

PersonalDataForm.propTypes = {
  citizenshipDictionary: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  reloadData: PropTypes.func.isRequired,
  closeHandler: PropTypes.func,
  isReadonly: PropTypes.bool,
};

PersonalDataForm.defaultProps = {
  closeHandler: null,
  isReadonly: false,
};
