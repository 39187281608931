/**
 * Manual error handling for react hook form
 *
 * @param {Function} setError - set error
 * @param {Array} violations - violations
 */
export const handleError = (setError, violations) => {
  violations.map(({
    propertyPath, message,
  }) => setError(propertyPath, {
    type: 'manual',
    message,
  }));
};
