import {
  Divider,
  FormControl,
  Grid,
  Grow,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import uniqid from 'uniqid';
import { darken } from '@mui/material/styles';
import {
  API_ROUTE,
  USER_ISSUE_STATUSES,
} from '../../_constants';
import { request } from '../../_services';
import { Alert } from '../Alert';
import { theme } from '../../styles/theme';

const classes = {
  inputContainer: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-adornedEnd fieldset': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },
    '& .Mui-disabled': {
      color: '#757575',
      '-webkitTextFillColor': '#757575',
    },
  },
};

/**
 * Issue preview.
 *
 * @param {object} props - root props
 * @param {string} props.issueId - issue UUID
 * @param {string} props.ariaLabelledBy - aria-labelledby value
 * @returns {IssuePreview}
 */
export function IssuePreview({
  ariaLabelledBy, issueId,
}) {
  const [previewError, setPreviewError] = useState('');
  const [issueData, setIssueData] = useState({});

  const fetchIssueDataForPreview = async () => {
    const {
      payload,
      statusSuccess,
      message,
    } = await request.get(`${API_ROUTE.issuesGet}/${issueId}`);

    if (!statusSuccess) {
      if (!message) {
        setPreviewError('Wystąpił błąd pobierania danych zgłoszenia.');

        return;
      }
      setPreviewError(message);

      return;
    }

    if (payload) {
      setIssueData({
        fullName: payload?.full_name || '',
        status: payload?.status || 'Brak danych',
        email: payload?.email || '',
        phoneNumber: payload?.phone_number || '',
        applicationNumber: payload?.application_number || '',
        subject: payload?.subject || '',
        description: payload?.description || '',
        redmineIssueId: payload?.redmine_issue_id || 'Brak danych',
      });
    }
  };

  useEffect(() => {
    fetchIssueDataForPreview();
  }, []);

  return (
    <div id={uniqid()} aria-labelledby={ariaLabelledBy}>
      <Divider sx={{
        height: '5px',
        backgroundColor: theme.palette.primary.main,
      }}
      />
      {!!previewError && (
        <Alert severity="error">{previewError}</Alert>
      )}
      <Grow in>
        <Grid container my={3}>
          <Grid item xs={12} p={10} sx={{ backgroundColor: '#f2f2f2' }}>
            <Box textAlign="center" pb={5}>
              <Typography fontSize="1.25rem" color={darken(theme.palette.primary.main, 0.25)}>
                <span>Zgłoszenie id: </span>
                <strong>{issueData.redmineIssueId}</strong>
              </Typography>
              <Typography mt={1} mb={4} fontSize="1rem">
                <span>Status:&nbsp;</span>
                <span>{USER_ISSUE_STATUSES[issueData.status] ?? 'Nieznany status'}</span>
              </Typography>
            </Box>
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.fullName && (
                <TextField
                  id="X4oTG2FJnHMgOPy"
                  defaultValue={issueData.fullName}
                  label="Imię i Nazwisko"
                  disabled
                />
              )}
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.email && (
                <TextField
                  id="wduRkyZHNhif156"
                  defaultValue={issueData.email}
                  label="E-mail"
                  disabled
                />
              )}
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.phoneNumber && (
                <TextField
                  id="NtwsB3wLvBf0lMV"
                  defaultValue={issueData.phoneNumber}
                  label="Telefon"
                  disabled
                />
              )}
            </FormControl>
            <Divider sx={{ my: (t) => t.spacing(3) }} />
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.subject && (
                <TextField
                  id="3GAKyqdZr8wctxk"
                  defaultValue={issueData.subject}
                  label="Temat zgłoszenia"
                  disabled
                />
              )}
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.applicationNumber && (
                <TextField
                  id="XHX7ZC9vlnR6ELH"
                  defaultValue={issueData.applicationNumber}
                  label="Numer wniosku"
                  disabled
                />
              )}
            </FormControl>
            <FormControl fullWidth sx={classes.inputContainer}>
              {issueData.description && (
                <TextField
                  id="MmarsObR9UR7JZ5"
                  defaultValue={issueData.description}
                  label="Opis"
                  disabled
                  multiline
                  rows={5}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grow>
    </div>
  );
}

IssuePreview.propTypes = {
  ariaLabelledBy: PropTypes.string.isRequired,
  issueId: PropTypes.string.isRequired,
};
