import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { FieldSorter } from '../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationTemplateStyles } from '../../../styles/applicationTemplate.styles';

/**
 * Collection field container
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {Array|undefined} props.fieldsOrder - custom fields order by name
 * @param {boolean} props.isReadonly - is readonly
 * @param {Function} props.onRemove - remove method
 * @param {string | number} props.itemIndex - item index
 * @param {boolean} props.buttonRemoveVisible - props show or hide delete button
 * @param {boolean} props.isIndexed - props show or hide Grid with element number
 * @param {boolean} props.sx - custom styles object at Material UI convention
 * @returns {CollectionFieldContainer}
 */
function CollectionFieldContainer({
  children, fieldsOrder, isReadonly, onRemove, itemIndex, buttonRemoveVisible, isIndexed, sx,
}) {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        ...useApplicationTemplateStyles.collectionFieldContainer,
        ...sx,
      }}
    >
      {isIndexed && (
        <Grid container>
          <Typography fontWeight="bold" fontSize={18}>
            {`${itemIndex + 1}.`}
          </Typography>
        </Grid>
      )}
      <FieldSorter fieldsOrder={fieldsOrder}>
        {children}
      </FieldSorter>
      {(!isReadonly && buttonRemoveVisible) && (
        <Grid item xs={12} display="flex" justifyContent="flex-end" mt={5}>
          <Button
            id={uniqid()}
            onClick={() => onRemove(itemIndex)}
            variant="outlined"
            color="primary"
            sx={{
              backgroundColor: '#fff',
            }}
          >
            Usuń
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default CollectionFieldContainer;

CollectionFieldContainer.propTypes = {
  children: PropTypes.node.isRequired,
  fieldsOrder: PropTypes.oneOfType([
    PropTypes.arrayOf(Object),
    PropTypes.instanceOf(undefined),
  ]),
  isReadonly: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  itemIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  buttonRemoveVisible: PropTypes.bool,
  isIndexed: PropTypes.bool,
  sx: PropTypes.instanceOf(Object),
};

CollectionFieldContainer.defaultProps = {
  fieldsOrder: undefined,
  buttonRemoveVisible: true,
  isIndexed: false,
  sx: {},
};
