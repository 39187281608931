import FieldWrapper from '../FieldWrapper';

/**
 * Max length auto validation
 *
 * @returns {MaxLengthAuto}
 */
function MaxLengthAuto() {
  return (
    <FieldWrapper
      name="maxLengthAuto"
      label={'Maksymalna długość tekstu na podstawie określonego limitu w polu "Liczba znaków"'}
    />
  );
}

export default MaxLengthAuto;
