import {
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Button,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import uniqid from 'uniqid';
import { getRssNews } from './getRssNews';
import PinnedNews from './PinnedNews';
import SingleNews from './SingleNews';
import { newsStyleClasses } from './News.list.style';

/**
 * News list component.
 *
 * @returns {NewsList} - news list component
 */
export default function NewsList() {
  const classes = newsStyleClasses;
  const [newsLimit, setNewsLimit] = useState(4);
  const [newsListData, setNewsListData] = useState([]);

  const moreLessText = (newsLimit > 4) ? 'Mniej' : 'Więcej';

  useEffect(() => {
    /**
     * Load rss data
     */
    async function fetchData() {
      const newsData = await getRssNews();

      setNewsListData(newsData);
    }
    fetchData();
  }, []);

  /**
   * Expand news list
   */
  const moreLess = () => {
    setNewsLimit(newsLimit === 4 ? newsListData.length : 4);
  };

  const mappedNews = useMemo(() => {
    let pinnedElement = null;
    const elements = [];

    newsListData.forEach((newsData) => {
      if (pinnedElement === null && newsData.pined) {
        pinnedElement = newsData;

        return;
      }

      elements.push(newsData);
    });

    if (!pinnedElement) {
      const [firstItem] = elements;
      pinnedElement = firstItem;
      elements.splice(0, 1);
    }

    return [pinnedElement, elements];
  }, [newsListData]);

  const [pinnedElement, elements] = mappedNews;

  return (
    <div style={classes.newsBulletin}>
      <Typography variant="h3" sx={classes.newsHeading}>Aktualności</Typography>
      <Divider sx={classes.boldDivider} />
      {newsListData.length !== 0 && (
        <>
          <PinnedNews pinnedNewsData={pinnedElement} />
          <ul key="news-list">
            {elements.map((item) => (
              <SingleNews key={uniqid()} newsData={item} />
            )).slice(0, newsLimit)}
          </ul>
          <Tooltip title="Więcej aktualności" key="more">
            <Button
              id="QqdK7xVO5dSLgjC"
              onClick={moreLess}
              sx={classes.more}
            >
              {`${moreLessText} `}
              <Typography style={visuallyHidden}>na temat aktualności</Typography>
            </Button>
          </Tooltip>
        </>
      )}
      {newsListData.length === 0 && (
        <span>Brak aktualnych informacji</span>
      )}
    </div>
  );
}
