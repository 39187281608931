import PropTypes from 'prop-types';
import {
  Text,
  View,
} from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import RowConfig from '../../../Components/RowConfig/RowConfig';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
} from '../../../../../_constants';
import RowField from '../../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getAdditionalStylesByStatus,
  getChangesInField,
  getIndexForPreviousRowData,
  getRemovedRowData,
  numberWithSeparator,
} from '../../../../../_helpers';
import { getImplementersAndApplicantNameForPdf } from '../../../../../_helpers/getImplementersAndApplicantName';

/**
 * FlatRateExpensesTablePdf element.
 *
 * @param {object} props - root props
 * @param {string} props.elementId - element id
 * @param {string} props.taskId - task id
 * @param {object} props.additionalStyles - additional styles
 * @returns {FlatRateExpensesTablePdf}
 */
function FlatRateExpensesTablePdf({
  elementId, taskId, additionalStyles,
}) {
  const {
    managedApplicationTemplate: {
      fieldsConfig,
      initialFormData,
    },
    id,
    getDictionary,
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const combinedApplicantName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];
  const fieldNamePrefix = `lump_sum_expenses-${elementId}`;

  const getExpensesByElementId = (data, key) => data[key] ?? [];

  const flatRateExpensesData = getExpensesByElementId(initialFormData, fieldNamePrefix);
  const filteredExpensesByTaskId = flatRateExpensesData.filter((expense) => expense.task_id === taskId);

  const previousFlatRateExpensesData = getExpensesByElementId(previousInitialFormData, fieldNamePrefix);
  const previousFilteredExpensesByTaskId = previousFlatRateExpensesData.filter((expense) => expense.task_id === taskId);

  const addedFlatRateExpenses = getAddedRowData(
    filteredExpensesByTaskId,
    previousFilteredExpensesByTaskId,
    'lump_sum_expense_id'
  );
  const removedFlatRateExpenses = getRemovedRowData(
    filteredExpensesByTaskId,
    previousFilteredExpensesByTaskId,
    'lump_sum_expense_id'
  );
  const expensesData = withChanges ? [...addedFlatRateExpenses, ...removedFlatRateExpenses] : filteredExpensesByTaskId;

  const flatRateExpensesConfig = fieldsConfig[fieldNamePrefix];
  const subfields = flatRateExpensesConfig?.fields || {};

  const hasImplementers = initialFormData?.has_implementers;

  const rowsCount = [
    subfields?.lump_sum_type,
    subfields?.cost_name_in_words,
    subfields?.cost_name_from_dictionary,
    subfields?.cost_category,
    subfields?.total_expenses,
    subfields?.eligible_expenses,
    subfields?.co_financing,
    subfields?.co_financing_percent,
    'Informacje o metodzie uproszczonej',
    subfields?.confirmation_documents,
  ].filter((field) => field).length;

  if (expensesData.length === 0) {
    return '';
  }

  return (
    <View style={additionalStyles}>
      <Text style={printStyle.sectionTitle}>
        Koszty uproszczone
      </Text>
      {expensesData.map((expense) => {
        const itemIndex = flatRateExpensesData
          .findIndex(({ actual_expense_id: actualExpenseId }) => actualExpenseId === expense.lump_sum_expense_id);

        const flatRateTypeValue = getDictionary(`${DICTIONARIES.lumpSumTypes.name}-${elementId}`)
          .find(({ '@id': lumpSumTypeId }) => lumpSumTypeId === expense.lump_sum_type)?.name;

        const isUnitRate = flatRateTypeValue === 'Stawka jednostkowa';
        const isFlatRate = flatRateTypeValue === 'Stawka ryczałtowa';
        const isLumpSum = flatRateTypeValue === 'Kwota ryczałtowa';

        const previousExpenseData = previousFilteredExpensesByTaskId[
          getIndexForPreviousRowData(
            previousFilteredExpensesByTaskId,
            expense.lump_sum_expense_id,
            'lump_sum_expense_id'
          )
        ];

        return (
          <View
            style={[printStyle.sectionWrapperWithMargin, printStyle.table, getAdditionalStylesByStatus(expense.status)]}
            key={expense.lump_sum_expense_id}
          >
            <PrintoutTableRow>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.lump_sum_type`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.cost_name_in_words`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.cost_name_from_dictionary`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.cost_category`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.total_expenses`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.eligible_expenses`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.co_financing`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig name={`${fieldNamePrefix}.${itemIndex}.co_financing_percent`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                Informacje o metodzie uproszczonej
              </PrintoutTableCell>
              <RowConfig name={`lump_sum_expenses-${elementId}.${itemIndex}.confirmation_documents`}>
                {({ label }) => (
                  <PrintoutTableCell rowSpan={rowsCount} bgColor="gray">
                    {label}
                  </PrintoutTableCell>
                )}
              </RowConfig>
            </PrintoutTableRow>
            <PrintoutTableRow>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.lump_sum_type`}
                value={expense.lump_sum_type}
                oldValue={previousExpenseData?.lump_sum_type}
                dictionaryName={DICTIONARIES.lumpSumTypes.name}
                skipFilterDictionary
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.cost_name_in_words`}
                value={expense.cost_name_in_words}
                oldValue={previousExpenseData?.cost_name_in_words}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.cost_name_from_dictionary`}
                value={expense.cost_name_from_dictionary}
                oldValue={previousExpenseData?.cost_name_from_dictionary}
                dictionaryName={DICTIONARIES.lumpSumCostNames.name}
                skipFilterDictionary
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.cost_category`}
                value={expense.cost_category}
                oldValue={previousExpenseData?.cost_category}
                dictionaryName={DICTIONARIES.costCategories.name}
                skipFilterDictionary
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.total_expenses`}
                value={numberWithSeparator(expense.total_expenses)}
                oldValue={numberWithSeparator(previousExpenseData?.total_expenses)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.eligible_expenses`}
                value={numberWithSeparator(expense.eligible_expenses)}
                oldValue={numberWithSeparator(previousExpenseData?.eligible_expenses)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing`}
                value={numberWithSeparator(expense.co_financing)}
                oldValue={numberWithSeparator(previousExpenseData?.co_financing)}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <RowConfig
                name={`${fieldNamePrefix}.${itemIndex}.co_financing_percent`}
                value={expense.co_financing_percent}
                oldValue={previousExpenseData?.co_financing_percent}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
              <PrintoutTableCell rowSpan={rowsCount}>
                {isFlatRate && `Stawka ryczałtowa: ${getChangesInField(previousExpenseData?.lump_sum_rate, expense.lump_sum_rate)}`}
                {isLumpSum && `Wskaźnik: ${getChangesInField(previousExpenseData?.indicator, expense.indicator)} - Wartość wskaźnika: ${getChangesInField(previousExpenseData?.indicator_value, expense.indicator_value)}`}
                {isUnitRate && `Wartość stawki: ${getChangesInField(previousExpenseData?.rate_value, expense.rate_value)} - Liczba stawek: ${getChangesInField(previousExpenseData?.rate_count, expense.rate_count)}`}
              </PrintoutTableCell>
              <RowConfig
                name={`lump_sum_expenses-${elementId}.${itemIndex}.confirmation_documents`}
                value={expense.confirmation_documents}
                dictionaryName={DICTIONARIES.confirmationDocuments.name}
              >
                {({ fieldValue }) => (
                  <PrintoutTableCell rowSpan={rowsCount}>
                    {fieldValue}
                  </PrintoutTableCell>
                )}
              </RowConfig>
            </PrintoutTableRow>
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.estimation_methods`}
              value={expense.estimation_methods}
              oldValue={previousExpenseData?.estimation_methods}
              isSingleRow
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.expense_justification`}
              value={expense.expense_justification}
              oldValue={previousExpenseData?.expense_justification}
              isSingleRow
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.description_and_justification`}
              value={expense.description_and_justification}
              oldValue={previousExpenseData?.description_and_justification}
              isSingleRow
            />
            <RowField
              name={`${fieldNamePrefix}.${itemIndex}.spending_on_accessibility`}
              value={expense.spending_on_accessibility}
              oldValue={previousExpenseData?.spending_on_accessibility}
              dictionaryName={DICTIONARIES.cstLimitCategories.name}
            />
            {hasImplementers === BOOLEAN_VALUES.TRUE && (
              <RowField
                name={`${fieldNamePrefix}.${itemIndex}.implementer_id`}
                value={expense.implementer_id}
                oldValue={previousExpenseData?.implementer_id}
                externalDictionary={combinedApplicantName}
                skipFilterDictionary
              />
            )}
          </View>
        );
      })}
    </View>
  );
}

export default FlatRateExpensesTablePdf;

FlatRateExpensesTablePdf.propTypes = {
  elementId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  additionalStyles: PropTypes.instanceOf(Object),
};

FlatRateExpensesTablePdf.defaultProps = {
  additionalStyles: {},
};
