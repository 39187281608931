import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { BOOLEAN_VALUES } from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Market Transformation Pdf element.
 *
 * @returns {MarketTransformationPdf}
 */
function MarketTransformationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    application,
  } = useApplicationToPdfContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="market_transformation_is_potential_to_transform_the_existing_target_market" />
        {initialFormData?.market_transformation_is_potential_to_transform_the_existing_target_market
        === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField
              name="market_transformation_potential_to_transform_the_existing_target_market_justification_module"
              externalDictionary={modules}
            />
            <RowField
              name="market_transformation_potential_to_transform_the_existing_target_market_justification"
              isSingleRow
            />
          </>
        )}
        <RowField name="market_transformation_is_potential_to_create_a_new_market" />
        {initialFormData?.market_transformation_is_potential_to_create_a_new_market === BOOLEAN_VALUES.TRUE && (
          <>
            <RowField
              name="market_transformation_potential_to_create_a_new_market_module"
              externalDictionary={modules}
            />
            <RowField
              name="market_transformation_potential_to_create_a_new_market_justification"
              isSingleRow
            />
          </>
        )}
      </FieldSorter>
    </View>
  );
}

export default MarketTransformationPdf;
