import {
  ADDITIONAL_MODULE_IDS,
  ADDITIONAL_MODULE_LABELS,
} from '../_constants';
import { getExternalIdsToCriteriaItem } from './assessmentSheet';

/**
 * Get transformed modules
 *
 * @param {Array} modules - modules
 * @returns {Array}
 */
const getTransformedModules = (modules) => [
  ...modules,
  {
    id: ADDITIONAL_MODULE_IDS.ocenaFinansowa,
    name: ADDITIONAL_MODULE_LABELS.ocenaFinansowa,
  },
  {
    id: ADDITIONAL_MODULE_IDS.modulowaFinansowa,
    name: ADDITIONAL_MODULE_LABELS.modulowaFinansowa,
  },
  {
    id: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
    name: ADDITIONAL_MODULE_LABELS.kryteriaOgolem,
  },
].map(({
  name, id,
}) => ({
  moduleName: name,
  moduleId: id,
  evaluationItems: [],
}));

/**
 * Find module index by module id
 *
 * @param {Array} modules - modules
 * @param {string} moduleId - module id
 * @returns {number | null}
 */
const findModuleIndexByModuleId = (modules, moduleId) => modules.findIndex((module) => module.moduleId === moduleId);

const getTransformedEvaluationItems = (evaluationItems, questions) => evaluationItems.map((evaluationItem) => ({
  evaluationItemName: evaluationItem.criteriaItem.name,
  evaluationItemId: evaluationItem.id,
  moduleId: getExternalIdsToCriteriaItem(
    evaluationItem.criteriaItem.businessGroup,
    evaluationItem.criteriaItem.moduleId || ''
  ),
  questions: questions
    .filter(({ evaluationItem: { id: evaluationItemId } }) => evaluationItemId === evaluationItem.id)
    .map(({
      answer, question, evaluationItem: { id: evaluationItemId },
    }) => ({
      answer,
      question,
      evaluationItemId,
    })),
}));

/**
 * Get transformed supplement call
 *
 * @param {Array} modules - modules
 * @param {Array} supplementCallData - supplement call data
 * @returns {object}
 */
export const getTransformedSupplementCall = (modules, supplementCallData) => {
  const transformedData = {
    unlockApplication: supplementCallData.unlockApplication,
    items: getTransformedModules(modules),
    questions: supplementCallData.questions,
    applicationId: supplementCallData.evaluationSheet.application.applicationId,
    panelType: supplementCallData.evaluationSheet.expertPanel.criteriaSet.panelType,
    evaluationSheetItems: getTransformedEvaluationItems(
      supplementCallData.evaluationSheet.items,
      supplementCallData.questions,
    ).filter(({ questions }) => questions.length > 0),
  };

  const transformedEvaluationItems = getTransformedEvaluationItems(
    supplementCallData.evaluationSheet.items,
    supplementCallData.questions,
  ).filter(({ questions }) => questions.length > 0);

  transformedEvaluationItems.forEach((item) => {
    const moduleIndex = findModuleIndexByModuleId(transformedData.items, item.moduleId);

    if (moduleIndex !== -1) {
      transformedData.items[moduleIndex].evaluationItems.push(item);
    }
  });

  return transformedData;
};
