import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';

/**
 * HumanResourcesGreening element renderer.
 *
 * @returns {HumanResourcesGreening}
 */
export function HumanResourcesGreening() {
  const { isReadonly } = useElementContext();

  return (
    <FormField name="human_resources_greening_description_of_human_resources">
      {({
        onChange, value, name, maxLength, onBlur, error,
      }) => (
        <TextField
          multiline
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={value}
          variant="outlined"
          InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
          inputProps={{ maxLength }}
          disabled={isReadonly}
          error={!!error}
          helperText={error?.message}
        />
      )}
    </FormField>
  );
}
