import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useCustomSnackbar } from '../../_hooks';
import { attachmentsBoxStyle } from './AttachmentsBox.style';
import { FileList } from './FileList';
import {
  convertStorageUnit,
  iriToId,
  getMessageByCode,
} from '../../_helpers';
import {
  FILE_LIMIT_EXCEEDED,
  API_ROUTE,
} from '../../_constants';
import { request } from '../../_services';
import { FILE_STATE } from './fileState';
import { SaveCancelButtons } from '../SaveCancelButtons';
import { theme } from '../../styles/theme';

/**
 * Drag & drop file upload input with customized box.
 *
 * @param {object} props - root props
 * @param {number} props.minAttachments - min files count
 * @param {number} props.maxAttachments - max files count
 * @param {number} props.maxSize - max file size
 * @param {Array} props.allowedExtensions - allowed extensions
 * @param {object} props.requestPayload - file post optional payload
 * @param {Function} props.uploadedFilesCallback - uploaded files will be passed to this function
 * @param {Array} props.existingFiles - existing files iris will be attached to files list
 * @param {string} props.fetchUrl - api endpoint to fetch attachments data by IDs
 * @param {string} props.id - id forwarded to input
 * @param {boolean} props.isReadonly - props provide value is read only
 * @param {string} props.apiUrl - API URL
 * @param {string} props.itemsPerPage - number of returned existing attachments
 * @param {Function} props.onDownload - on download
 * @param {Function} props.hideItemRemoveButtonCallback - callback to hide item remove button
 * @returns {AttachmentsBox}
 */
export function AttachmentsBox({
  minAttachments,
  maxAttachments,
  maxSize,
  allowedExtensions,
  requestPayload,
  uploadedFilesCallback,
  existingFiles,
  fetchUrl,
  id,
  isReadonly,
  apiUrl,
  itemsPerPage,
  onDownload,
  hideItemRemoveButtonCallback,
}) {
  const { errorNotification } = useCustomSnackbar();
  const [files, setFiles] = useState([]);
  /**
   * Files accepted by pre-validator, waiting to be named by the user.
   */
  const [pendingFiles, setPendingFiles] = useState({});

  /**
   * Prevent files loading from API more than once.
   */
  const [preLoaded, setPreLoaded] = useState(false);

  /**
   * Are files still loaded from API.
   */
  const [filesDataLoading, setFilesDataLoading] = useState(false);

  const dragAndDropDescriptionId = uniqid();

  useEffect(() => {
    setFilesDataLoading(true);

    /**
     * Loads existing files from application into form.
     */
    const loadExistingFiles = async () => {
      if (existingFiles.length === 0 || preLoaded) {
        setFilesDataLoading(false);
        setPreLoaded(true);

        return;
      }

      const requestQuery = `id[]=${existingFiles
        .filter((element) => !!element)
        .map((iri) => iriToId(iri)).join('&id[]=')}`;
      const {
        payload, statusSuccess,
      } = await request.get(`${fetchUrl}?itemsPerPage=${itemsPerPage}&${requestQuery}`);

      if (statusSuccess) {
        const mappedFiles = payload.map(({
          originalFilename, description, fileSize, '@id': iri, extension, createdAt,
        }) => ({
          file: {
            name: originalFilename,
            size: fileSize,
          },
          iri,
          description,
          status: FILE_STATE.uploaded,
          extension,
          createdAt,
        }));

        setFiles(mappedFiles);
      }
      setPreLoaded(true);
      setFilesDataLoading(false);
    };

    loadExistingFiles();
  }, [existingFiles, preLoaded]);

  /**
   * Whether input supports multiple files.
   */
  const isMultiupload = maxAttachments > 1;

  /**
   * Renders snackbar with info about file rejection.
   *
   * @param {string} fileName - rejected file name
   * @param {string} message - rejection message
   */
  const printFileUploadError = useCallback((fileName, message) => {
    const errorParsedMessage = (
      <div
        aria-live="polite"
      >
        <p>{message}</p>
        <Typography variant="caption">{fileName}</Typography>
      </div>
    );

    errorNotification(errorParsedMessage, 'left', 'bottom', 2000);
  }, [errorNotification]);

  /**
   * Handle rejected files.
   *
   * @param {Array} fileErrors - file errors
   */
  const handleFileErrors = useCallback((fileErrors) => {
    if (!fileErrors.length) {
      return;
    }

    if (fileErrors.length > maxAttachments) {
      printFileUploadError('', FILE_LIMIT_EXCEEDED);

      return;
    }

    fileErrors.forEach(({
      errors, file,
    }) => {
      printFileUploadError(
        file.name,
        getMessageByCode(errors[0].code)
      );
    });
  }, [maxAttachments, printFileUploadError]);

  /**
   * Upload files to API.
   *
   * @param {object} mappedFiles - files to upload, mapped with keys
   */
  const uploadFiles = useCallback(async (mappedFiles) => {
    const newStateFiles = {};
    Object.assign(newStateFiles, mappedFiles);

    /**
     * Uploads single file.
     *
     * @param {string} fileId - file unique id
     * @param {File} file - file to upload
     * @param {string} description - file description
     */
    const uploadFile = async (fileId, file, description) => {
      const {
        statusSuccess, payload, violations = [],
      } = await request.postFile(
        apiUrl,
        file,
        {
          ...requestPayload,
          description,
        }
      );

      newStateFiles[fileId].status = FILE_STATE.error;

      if (violations.length) {
        newStateFiles[fileId].error = violations[0]?.message || 'Nie można zapisać tego pliku';
      }

      if (statusSuccess && payload?.['@id']) {
        newStateFiles[fileId].status = FILE_STATE.uploaded;
        newStateFiles[fileId].iri = payload['@id'] || '';
        newStateFiles[fileId].createdAt = payload.createdAt;
        uploadedFilesCallback([...files, ...Object.values(newStateFiles)]);
      }

      setFiles([...files, ...Object.values(newStateFiles)]);
    };

    Object.entries(newStateFiles).forEach(([fileId, fileData]) => {
      uploadFile(fileId, fileData.file, fileData.description || fileData.file.name);
    });
  }, [files, requestPayload, uploadedFilesCallback]);

  /**
   * File drop handler.
   *
   * @param {File[]} acceptedFiles - files array
   * @param {Array} rejectedFiles - rejected files array
   */
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    handleFileErrors(rejectedFiles);

    if ((files.length + acceptedFiles.length) > maxAttachments) {
      printFileUploadError('', FILE_LIMIT_EXCEEDED);

      return;
    }
    const mappedFiles = {};
    acceptedFiles.forEach((file) => {
      const filenameParts = file.name.split('.');
      const fileId = uniqid();

      mappedFiles[fileId] = {
        status: FILE_STATE.pending,
        file,
        iri: null,
        name: filenameParts[0] || file.name,
        description: filenameParts[0],
        error: null,
        extension: filenameParts.pop(),
      };
    });

    setPendingFiles(mappedFiles);
  }, [files, handleFileErrors, maxAttachments, printFileUploadError, uploadFiles]);

  /**
   * Removes file by index from files list.
   *
   * @param {number} fileIndex - file index
   */
  const removeFileHandler = (fileIndex) => {
    const currentFiles = Array.from(files);
    currentFiles.splice(fileIndex, 1);
    uploadedFilesCallback(currentFiles);
    setFiles(currentFiles);
  };

  /**
   * Check if file has supported extension.
   *
   * This is support for file without specified 'type'.
   *
   * @see https://github.com/react-dropzone/react-dropzone/issues/171
   * @see https://github.com/react-dropzone/react-dropzone/issues/888
   * @param {File} file - uploaded file
   * @returns {object|null}
   */
  const supportedExtension = (file) => {
    if (file.name.split('.').length === 1) {
      return {
        code: 'unknown-ext',
      };
    }

    const extension = file.name.split('.').pop();
    if (!allowedExtensions.includes(extension.toLowerCase())) {
      return {
        code: 'file-invalid-type',
      };
    }

    return null;
  };

  const MAX_FILE_SIZE = 30000000;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: maxAttachments,
    maxSize: maxSize > MAX_FILE_SIZE ? MAX_FILE_SIZE : maxSize,
    validator: supportedExtension,
  });

  /**
   * Combined styles for attachments box.
   */
  const style = useMemo(() => ({
    ...attachmentsBoxStyle.baseStyle,
    ...(isDragActive ? attachmentsBoxStyle.activeStyle : {}),
    ...(isDragAccept ? attachmentsBoxStyle.acceptStyle : {}),
    ...(isDragReject ? attachmentsBoxStyle.rejectStyle : {}),
  }), [
    isDragActive,
    isDragAccept,
    isDragReject,
  ]);

  /**
   * Update description of pending file.
   *
   * @param {Event} event - input change event
   * @param {object} event.target - event target
   * @param {string} event.target.value - updated filename
   * @param {string} pendingFileId - pending file identifier
   * @param {string} originalFilename - original filename, will be used in case of empty new value
   */
  const updatePendingFileDescription = ({ target: { value } }, pendingFileId, originalFilename) => {
    setPendingFiles((s) => ({
      ...s,
      [pendingFileId]: {
        ...s[pendingFileId],
        description: value || originalFilename,
      },
    }));
  };

  /**
   * Forwards all pending files to upload func.
   */
  const handleSubmitPendingFiles = async () => {
    setFiles([...files, ...Object.values(pendingFiles)]);

    await uploadFiles(pendingFiles);

    setPendingFiles({});
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={isReadonly ? 12 : 6}>
          <FileList
            files={files}
            removeHandler={removeFileHandler}
            loading={filesDataLoading}
            isReadonly={isReadonly}
            apiUrl={apiUrl}
            onDownload={onDownload}
            hideItemRemoveButtonCallback={hideItemRemoveButtonCallback}
          />
        </Grid>
        {!isReadonly && (
          <Grid item xs={12} md={6}>
            <Paper elevation={0} {...getRootProps({ style })}>
              <BackupIcon fontSize="large" color="secondary" />
              <input
                {...getInputProps()}
                id={id}
                aria-labelledby={dragAndDropDescriptionId}
              />
              <Typography
                id={dragAndDropDescriptionId}
                color="secondary"
              >
                Przeciągnij i upuść
                {isMultiupload ? ' pliki ' : ' plik '}
                tutaj lub dodaj z dysku
              </Typography>
              <Box
                textAlign="center"
                color={theme.palette.brandGray.main}
                mt={2}
                maxWidth="35vh"
              >
                {minAttachments !== 0 && files.length < minAttachments && (
                  <Typography
                    variant="caption"
                    display="block"
                    fontWeight={800}
                    color={theme.palette.error.light}
                  >
                    {`Minimalna liczba załączników: ${minAttachments}`}
                  </Typography>
                )}
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                  }}
                  data-testid="maxAttachments"
                >
                  <b>Maksymalna liczba załączników:&nbsp;</b>
                  {`${maxAttachments}`}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  <b>Maksymalna wielkość pliku:&nbsp;</b>
                  {(convertStorageUnit.bytesToMegabytes(maxSize)).toFixed(1)}
                  &nbsp;MB
                </Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  <b>Dozwolone formaty:&nbsp;</b>
                  {allowedExtensions.join(', ') || '-'}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={Object.keys(pendingFiles).length > 0}
        onClose={() => setPendingFiles({})}
        maxWidth="sm"
        fullWidth
        variant="outlined"
        color="secondary"
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle>
          Nazwij plik
        </DialogTitle>
        <DialogContent>
          {Object.entries(pendingFiles).map(([fileId, fileData]) => (
            <TextField
              id={`name-pending-file-${fileId}`}
              key={fileId}
              fullWidth
              label={`Nazwij plik - ${fileData.file.name}`}
              defaultValue={fileData.file.name.slice(0, fileData.file.name.lastIndexOf('.'))}
              onChange={(event) => updatePendingFileDescription(event, fileId, fileData.file.name.split('.')[0])}
              sx={{ mb: (t) => t.spacing(2) }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <SaveCancelButtons
            cancelHandler={() => setPendingFiles({})}
            saveHandler={handleSubmitPendingFiles}
            saveButtonId="save-pending-files"
            cancelButtonId="discard-pending-files"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

AttachmentsBox.propTypes = {
  minAttachments: PropTypes.number,
  maxAttachments: PropTypes.number,
  maxSize: PropTypes.number,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  requestPayload: PropTypes.instanceOf(Object),
  uploadedFilesCallback: PropTypes.func,
  existingFiles: PropTypes.arrayOf(PropTypes.string),
  fetchUrl: PropTypes.string,
  id: PropTypes.string,
  isReadonly: PropTypes.bool,
  apiUrl: PropTypes.string,
  itemsPerPage: PropTypes.number,
  onDownload: PropTypes.func,
  hideItemRemoveButtonCallback: PropTypes.func,
};

AttachmentsBox.defaultProps = {
  minAttachments: 0,
  maxAttachments: 1,
  maxSize: 1000000,
  allowedExtensions: [],
  requestPayload: {},
  uploadedFilesCallback: () => {},
  existingFiles: [],
  fetchUrl: '',
  id: uniqid(),
  isReadonly: false,
  apiUrl: API_ROUTE.attachmentsRecruitments,
  itemsPerPage: 100,
  onDownload: null,
  hideItemRemoveButtonCallback: () => false,
};
