import {
  Button,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

/**
 * Pair of common buttons, properly customized.
 *
 * @see https://gitlab.parp.gov.pl/parp/lsi-frontend/-/wikis/Przyciski-Anuluj-i-Zapisz
 * @param {object} props root props
 * @param {Function} props.saveHandler - function invoked on save button click
 * @param {Function} props.cancelHandler - function invoked on cancel button click
 * @param {boolean} props.saveDisabled - is save button disabled
 * @param {boolean} props.cancelDisabled - is cancel button disabled
 * @param {string} props.saveTitle - save button title
 * @param {string} props.cancelTitle - cancel button title
 * @param {string} props.variant - buttons position variant
 * @param {string} props.saveButtonId - save button ID
 * @param {string} props.cancelButtonId - cancel button ID
 * @param {boolean} props.doubleConfirm - double click on save button will be used
 * @returns {SaveCancelButtons}
 */
export const SaveCancelButtons = forwardRef(({
  saveHandler,
  cancelHandler,
  saveDisabled,
  cancelDisabled,
  saveTitle,
  cancelTitle,
  variant,
  saveButtonId,
  cancelButtonId,
  doubleConfirm,
}, ref) => {
  const [confirmed, setConfirmed] = useState(false);

  useImperativeHandle(ref, () => ({
    /**
     * Resets save button to default state.
     */
    reset: () => {
      setConfirmed(false);
    },
  }));

  /**
   * Gets save button props.
   *
   * @returns {object}
   */
  const getSaveButtonProps = () => ({
    color: 'secondary',
    onClick: saveHandler,
    ...(doubleConfirm && ({
      color: confirmed ? 'error' : 'secondary',
      onClick: confirmed ? saveHandler : () => setConfirmed(true),
    })),
  });

  /**
   * Gets save button title.
   *
   * @returns {string|Element}
   */
  const getSaveButtonTitle = () => {
    if (!doubleConfirm) {
      return saveTitle;
    }

    return !confirmed ? saveTitle : (
      <>
        <ErrorOutlineIcon sx={{ mr: (theme) => theme.spacing(1) }} />
        Kliknij, aby potwierdzić
      </>
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent={variant === 'center' ? 'center' : 'normal'}
      spacing={1}
    >
      <Button
        aria-live="assertive"
        disabled={saveDisabled}
        variant="contained"
        id={saveButtonId}
        sx={{ minWidth: '100px' }}
        {...getSaveButtonProps()}
      >
        {getSaveButtonTitle()}
      </Button>
      <Button
        variant="outlined"
        disabled={cancelDisabled}
        color="error"
        onClick={cancelHandler}
        sx={{ minWidth: '100px' }}
        id={cancelButtonId}
      >
        {cancelTitle}
      </Button>
    </Stack>
  );
});

SaveCancelButtons.propTypes = {
  saveHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  saveTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'center']),
  saveButtonId: PropTypes.string,
  cancelButtonId: PropTypes.string,
  doubleConfirm: PropTypes.bool,
};

SaveCancelButtons.defaultProps = {
  saveTitle: 'Zapisz',
  cancelTitle: 'Anuluj',
  saveDisabled: false,
  cancelDisabled: false,
  variant: 'default',
  saveButtonId: 'saveButton',
  cancelButtonId: 'cancelButton',
  doubleConfirm: false,
};
