import PropTypes from 'prop-types';
import {
  Document,
  Image,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import logosImage from '../../../../images/logos.jpg';
import { printStyle } from '../../print.style';
import Watermark from '../Watermark';

/**
 * Pdf page wrapper
 *
 * @param {object} props - root props
 * @param {Node} props.children - children component
 * @param {string} props.title - document tile
 * @param {boolean} props.isReadonly - is readonly
 * @param {string} props.applicationStatus - application status
 * @param {string} props.expertApplicationStatus - expert's application status
 * @param {boolean} props.hideWatermark - hide watermark
 * @param {string} props.applicationNumber - application number
 * @returns {PageWrapper}
 */
function PageWrapper({
  children, title, isReadonly, applicationStatus, expertApplicationStatus, hideWatermark, applicationNumber,
}) {
  return (
    <Document>
      <Page wrap style={printStyle.body}>
        <View fixed style={printStyle.header}>
          <Image
            source={logosImage}
          />
        </View>
        {title && (
          <Text style={printStyle.title}>
            {title}
          </Text>
        )}
        {children}

        {applicationNumber && (
          <Text fixed style={printStyle.applicationNumber}>
            Numer wniosku:
            {applicationNumber}
          </Text>
        )}
        <Text
          fixed
          style={printStyle.pageNumber}
          render={({
            pageNumber, totalPages,
          }) => (
            `Strona ${pageNumber}/ ${totalPages}`
          )}
        />
        <Watermark
          scenario={title}
          isReadonly={isReadonly}
          hideWatermark={hideWatermark}
          applicationStatus={applicationStatus}
          expertApplicationStatus={expertApplicationStatus}
        />
      </Page>
    </Document>
  );
}

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isReadonly: PropTypes.bool,
  applicationStatus: PropTypes.string,
  expertApplicationStatus: PropTypes.string,
  hideWatermark: PropTypes.bool,
  applicationNumber: PropTypes.string.isRequired,
};

PageWrapper.defaultProps = {
  applicationStatus: 'undefinedStatus',
  expertApplicationStatus: 'undefinedStatus',
  isReadonly: false,
  hideWatermark: false,
  title: null,
};
