import {
  createContext,
  useContext,
} from 'react';

/**
 * ExpertPersonalDataContext context wrapper.
 * Contains expert personal data from API.
 *
 * @type {React.Context}
 */
export const ExpertPersonalDataContext = createContext({
  /**
   * Expert address object.
   */
  address: {
    id: null,
    residenceAddress: {},
    mailingAddress: {},
  },
  /**
   * Expert education entries.
   */
  educations: [],
  /**
   * Expert careers entries.
   */
  careers: [],

  /**
   * Is residence address same as mailing address.
   */
  residenceSameAsMailingAddress: false,

  /**
   * Expert has running business
   */
  runningBusiness: null,

  /**
   * Expert known languages entries.
   */
  knownLanguages: {
    english: null,
    french: null,
    german: null,
    polish: null,
  },

  /**
   * Expert has attachments property to render.
   */
  hasAttachmentsProperty: false,

  /**
   * Expert attachments iri.
   */
  attachments: [],

  /**
   * Expert education attachments iri.
   */
  educationAttachments: [],

  /**
   * Function to close main modal with expert personal data.
   */
  closeHandler: () => {},

  /**
   * Function to reload data from API.
   */
  reloadData: () => {},

  /**
   * Function to select menu tab index.
   */
  selectionHandler: () => {},

  /**
   * Is read only.
   */
  isReadonly: false,

  /**
   * Expert id.
   */
  expertId: null,
});

/**
 * ExpertPersonalDataContext hook consumer.
 *
 * @returns {object}
 */
export const useExpertPersonalDataContext = () => useContext(ExpertPersonalDataContext);
