import {
  APPLICATION_STATUSES_TRANSLATIONS,
  DATE,
} from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { useApplicationContext } from '../../../Context';
import { implementationLevelParser } from './implementationLevelHelper';
import { Alert } from '../../Alert';
import { FieldSorter } from './FieldSorter';
import { formatDateString } from '../../../_helpers';

const classes = {
  alert: {
    margin: 0,
  },
};

/**
 * Program general information element renderer.
 *
 * @returns {GeneralProgramInformation}
 */
export function GeneralProgramInformation() {
  const {
    application,
  } = useApplicationContext();

  const {
    implementationLevel, description,
  } = application.recruitment;
  const programNames = implementationLevelParser(implementationLevel);

  return (
    <FieldSorter>
      <FormField name="application_number">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {application.applicationNumber}
          </Alert>
        )}
      </FormField>

      <FormField name="application_submission_date">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {formatDateString(application.submissionDate, DATE.defaultFormatWithSeconds)}
          </Alert>
        )}
      </FormField>

      <FormField name="project_status">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {APPLICATION_STATUSES_TRANSLATIONS[application.status]}
          </Alert>
        )}
      </FormField>

      <FormField name="program">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {programNames.program}
          </Alert>
        )}
      </FormField>

      {programNames.priorytet && (
        <FormField name="priority">
          {() => (
            <Alert
              severity="status"
              sx={classes.alert}
            >
              {programNames.priorytet}
            </Alert>
          )}
        </FormField>
      )}

      {programNames.dzialanie && (
        <FormField name="activity">
          {() => (
            <Alert
              severity="status"
              sx={classes.alert}
            >
              {`${programNames.dzialanie} ${description ? `- ${description}` : ''}`}
            </Alert>
          )}
        </FormField>
      )}

      <FormField name="contracting_institution">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {implementationLevel?.instytucjaProwadzaca}
          </Alert>
        )}
      </FormField>

      <FormField name="project_settlement_institution">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {implementationLevel?.instytucjaProwadzaca}
          </Alert>
        )}
      </FormField>

      <FormField name="recruitment_number">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {application.recruitment.pelnyNumerNaboruWSl}
          </Alert>
        )}
      </FormField>

      <FormField name="project_type">
        {() => (
          <Alert
            severity="status"
            sx={classes.alert}
          >
            {application.recruitment.recruitmentType}
          </Alert>
        )}
      </FormField>
    </FieldSorter>
  );
}
