import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  API_ROUTE,
  GROUPS,
  CHARS_LIMIT,
} from '../../_constants';
import { request } from '../../_services';
import { elementHelper } from '../../_helpers';
import { useCustomSnackbar } from '../../_hooks';
import CharsCounter from '../../Features/CharsCounter';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import { CustomAutocomplete } from '../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * Application template element edit form
 *
 * @param {object} props - root props
 * @param {object} props.elementData - element data
 * @param {Function} props.closeDialogHandler - handle dialog close
 * @param {Function} props.reloadHandler - reload data handler
 * @param {Function} props.requestProgressSetter - request progress setter
 * @param {Array} props.fieldsOrder - fields order
 * @returns {ElementEditForm}
 */
export function ElementEditForm({
  elementData,
  closeDialogHandler,
  reloadHandler,
  requestProgressSetter,
  fieldsOrder,
}) {
  const { successNotification } = useCustomSnackbar();
  const {
    id: elementId,
    elementDefinition,
  } = elementData;

  const hasSingleModule = elementHelper.hasSingleModule(elementDefinition['@type']);

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      label: elementData?.label || '',
      groups: elementData?.groups || [],
      contextHelp: elementData?.contextHelp || '',
      modules: hasSingleModule
        ? elementData?.modules[0]?.id || null
        : elementData?.modules.map((module) => module.id) || [],
    },
    mode: 'all',
  });

  const [modules, setModules] = useState([]);

  /**
   * Modules selection input will not be displayed if it is "attachments".
   */
  const renderModulesSelect = elementDefinition.name !== 'attachments';

  useEffect(() => {
    const loadModules = async () => {
      const { payload } = await request.get(API_ROUTE.modules);

      setModules(payload);
    };

    loadModules();
  }, [elementData]);

  const onSubmit = async (values) => {
    requestProgressSetter(true);

    const modulesValue = values?.modules ? [`/lsi/recruitments/api/modules/${values?.modules}`] : [];

    const payload = {
      label: values.label,
      groups: values.groups,
      contextHelp: values.contextHelp,
      modules: hasSingleModule ? modulesValue
        : values.modules.map((moduleId) => `/lsi/recruitments/api/modules/${moduleId}`),
      fieldsOrder,
    };

    const { statusSuccess } = await request.put(
      `${API_ROUTE.templateElement}/${elementId}`,
      payload
    );

    requestProgressSetter(false);
    if (statusSuccess) {
      reloadHandler();
      successNotification();
    }
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Nazwa"',
            },
          }}
          name="label"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Nazwa"
              required
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} onOutline />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.LONG }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Kroki"',
            },
          }}
          name="groups"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <CustomAutocomplete
              id={name}
              multiple
              options={GROUPS.map((group) => ({
                '@id': group,
                name: group,
              }))}
              onChange={onChange}
              onBlur={onBlur}
              initialValue={value}
              textFieldProps={{
                name,
                label: 'Kroki',
                required: true,
                error: !!error,
              }}
              error={error}
            />
          )}
        />
      </FormControl>

      {renderModulesSelect && (
        <FormControl fullWidth margin="normal">
          <Controller
            name="modules"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                id={name}
                multiple={!hasSingleModule}
                options={modules.map(({
                  id: moduleId, name: moduleLabel,
                }) => ({
                  '@id': moduleId,
                  name: moduleLabel,
                }))}
                onChange={onChange}
                onBlur={onBlur}
                initialValue={value}
                textFieldProps={{
                  name,
                  label: 'Moduł',
                  error: !!error,
                }}
                error={error}
              />
            )}
          />
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <Controller
          name="contextHelp"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              label="Pomoc kontekstowa"
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} onOutline />
                ),
              }}
              inputProps={{ maxLength: CHARS_LIMIT.LONG }}
            />
          )}
        />
      </FormControl>
      <Box display="flex" justifyContent="center" mt={3}>
        <SaveCancelButtons
          cancelHandler={closeDialogHandler}
          cancelButtonId="4y0nA1U9UGZpUMb"
          saveHandler={handleSubmit(onSubmit)}
          saveButtonId="Dq3KGgQtsWYgg4p"
        />
      </Box>
    </>
  );
}

ElementEditForm.propTypes = {
  elementData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(Object).isRequired,
    contextHelp: PropTypes.string.isRequired,
    modules: PropTypes.arrayOf(Object).isRequired,
    elementDefinition: PropTypes.shape({
      name: PropTypes.string,
      '@type': PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  closeDialogHandler: PropTypes.func.isRequired,
  reloadHandler: PropTypes.func.isRequired,
  requestProgressSetter: PropTypes.func.isRequired,
  fieldsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
};
