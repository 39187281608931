import { View } from '@react-pdf/renderer';
import { printStyle } from '../../../print.style';
import SingleCollectionWrapper from '../../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import CollectionWrapper from '../../../Components/CollectionWrapper/CollectionWrapper';
import { DICTIONARIES } from '../../../../../_constants';
import RowField from '../../../Components/RowField/RowField';
import { FieldSorter } from '../../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Works Type Pdf element.
 *
 * @returns {WorksTypePdf}
 */
function WorksTypePdf() {
  return (
    <CollectionWrapper name="innovation_implementation_module_essence_work_types">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                <RowField
                  name={`${collectionName}.${index}.work_types`}
                  value={item.work_types}
                  oldValue={previousRowData?.work_types}
                  dictionaryName={DICTIONARIES.innovationImplementationModuleEssenceWorkTypes.name}
                />
                <RowField
                  name={`${collectionName}.${index}.rd_work_scope`}
                  value={item.rd_work_scope}
                  oldValue={previousRowData?.rd_work_scope}
                />
                <RowField
                  name={`${collectionName}.${index}.work_value`}
                  value={item.work_value}
                  oldValue={previousRowData?.work_value}
                />
                <RowField
                  name={`${collectionName}.${index}.method_for_assets_inclusion`}
                  value={item.method_for_assets_inclusion}
                  oldValue={previousRowData?.method_for_assets_inclusion}
                />
              </FieldSorter>
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default WorksTypePdf;
