import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../../../print.style';
import PrintoutTableRow from '../../../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../../../Components/Table/PrintoutTableCell';
import { useApplicationToPdfContext } from '../../../../../Context';
import {
  getAddedRowData,
  getIndexForPreviousRowData,
  getRemovedRowData,
  getChangesInField,
} from '../../../../../_helpers';

/**
 * Project Budget By Implementer Table Pdf element.
 *
 * @param {object} props - root props
 * @param {string} props.label - label
 * @param {Array} props.data - data
 * @param {string} props.fieldName - field name
 * @returns {ProjectBudgetByImplementerTablePdf}
 */
function ProjectBudgetByImplementerTablePdf({
  label, data, fieldName,
}) {
  const {
    previousInitialFormData, withChanges,
  } = useApplicationToPdfContext();

  const previousProjectBudgetData = previousInitialFormData[fieldName] || [];
  const projectBudgetTotal = data.pop();
  const previousProjectBudgetTotal = previousProjectBudgetData.pop();

  const combinedProjectBudgetData = withChanges ? [
    ...getAddedRowData(data, previousProjectBudgetData, 'name'),
    ...getRemovedRowData(data, previousProjectBudgetData, 'name'),
  ] : data;

  if (combinedProjectBudgetData.length === 0) {
    return '';
  }

  return (
    <View style={printStyle.sectionWrapperWithMargin}>
      <Text style={printStyle.sectionTitle}>
        {label}
      </Text>
      <PrintoutTableRow>
        <PrintoutTableCell rowSpan={8}>Realizator</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Wydatki ogółem</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Rzeczywiste wydatki kwalifikowane</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Ryczałtowe wydatki kwalifikowane</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Udział kosztów uproszczonych</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Wydatki kwalifikowane</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>Dofinansowanie</PrintoutTableCell>
        <PrintoutTableCell rowSpan={8}>% dofinansowania</PrintoutTableCell>
      </PrintoutTableRow>
      {combinedProjectBudgetData?.map((item) => {
        const previousRowData = previousProjectBudgetData[getIndexForPreviousRowData(
          data,
          item.name,
          'name'
        )];

        return (
          <PrintoutTableRow key={item.name}>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.name, item.name)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.total_expenses, item.total_expenses)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.actual_eligible_expenses, item.actual_eligible_expenses)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.lump_sum_eligible_expenses, item.lump_sum_eligible_expenses)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.share_of_lump_sum_expenses, item.share_of_lump_sum_expenses)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.eligible_expenses, item.eligible_expenses)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.co_financing, item.co_financing)}
            </PrintoutTableCell>
            <PrintoutTableCell rowSpan={8} bgColor={item.status || 'white'}>
              {getChangesInField(previousRowData?.co_financing_percent, item.co_financing_percent)}
            </PrintoutTableCell>
          </PrintoutTableRow>
        );
      })}
      <PrintoutTableRow>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(previousProjectBudgetTotal?.name, projectBudgetTotal?.name)}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(previousProjectBudgetTotal?.total_expenses, projectBudgetTotal?.total_expenses)}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(
            previousProjectBudgetTotal?.actual_eligible_expenses,
            projectBudgetTotal?.actual_eligible_expenses
          )}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(
            previousProjectBudgetTotal?.lump_sum_eligible_expenses,
            projectBudgetTotal?.lump_sum_eligible_expenses
          )}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(
            previousProjectBudgetTotal?.share_of_lump_sum_expenses,
            projectBudgetTotal?.share_of_lump_sum_expenses
          )}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(previousProjectBudgetTotal?.eligible_expenses, projectBudgetTotal?.eligible_expenses)}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(previousProjectBudgetTotal?.co_financing, projectBudgetTotal?.co_financing)}
        </PrintoutTableCell>
        <PrintoutTableCell rowSpan={5} bgColor="gray">
          {getChangesInField(
            previousProjectBudgetTotal?.co_financing_percent,
            projectBudgetTotal?.co_financing_percent
          )}
        </PrintoutTableCell>
      </PrintoutTableRow>
    </View>
  );
}

export default ProjectBudgetByImplementerTablePdf;

ProjectBudgetByImplementerTablePdf.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  fieldName: PropTypes.string.isRequired,
};
