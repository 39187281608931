import {
  createContext,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

/**
 * Expert application form to pdf context wrapper.
 * Remember that context just matches the shape that the consumers expect.
 *
 * @type {React.Context}
 */
const ExpertApplicationFormToPdfContext = createContext({
  /**
   * Expert application form identifier.
   */
  applicationId: '',
  /**
   * Expert personal data.
   */
  expertPersonalData: {},
  /**
   * Expert data.
   */
  expertData: {},
  /**
   * Citizenship data.
   */
  citizenshipData: {},
  /**
   * Countries data.
   */
  countriesData: {},
  /**
   * Attachments data.
   *
   */
  attachmentsData: [],
  /**
   * applicationStatementAttachments data.
   *
   */
  applicationStatementAttachmentsData: [],
  /**
   * trainingCertificatesCbaAttachments data.
   *
   */
  trainingCertificatesCbaAttachmentsData: [],
  /**
   * kisDomains data.
   *
   */
  kisDomainsData: [],
  /**
   * kisDomainAreas data.
   *
   */
  kisDomainAreasData: [],
  /**
   * otherDomains data.
   *
   */
  otherDomainsData: [],
  /**
   * otherDomains data.
   *
   */
  financialDomainsData: [],
  /**
   * domainJustifications data.
   *
   */
  domainJustificationsData: [],
  /**
   * oecdScienceDomains data.
   *
   */
  oecdScienceDomainsData: [],
  /**
   * naceScienceDomains data.
   *
   */
  naceScienceDomainsData: [],
  /**
   * otherDomainJustifications data.
   *
   */
  otherDomainJustificationsData: [],
});

/**
 * Application context provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {object} props.value - value passed to provider
 * @returns {React.Context.Provider}
 */
export function ExpertApplicationFormToPdfContextProvider({
  children, value,
}) {
  return (
    <ExpertApplicationFormToPdfContext.Provider value={value}>
      {children}
    </ExpertApplicationFormToPdfContext.Provider>
  );
}

ExpertApplicationFormToPdfContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

/**
 * ExpertApplicationFormToPdfContext hook consumer.
 *
 * @returns {object}
 */
export const useExpertApplicationFormToPdfContext = () => useContext(ExpertApplicationFormToPdfContext);
