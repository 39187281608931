import {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  View,
  Text,
} from '@react-pdf/renderer';
import { getFieldConfig } from '../../../../_helpers';
import { useApplicationToPdfContext } from '../../../../Context';
import { printStyle } from '../../print.style';
import { PDF_ROW_STATUS } from '../../../../_constants';

export const collectionStyle = StyleSheet.create({
  collectionWrapper: {
    marginTop: 10,
  },
});

/**
 * Pdf collection wrapper
 *
 * @param {object} props - root props
 * @param {string} props.name - collection name
 * @param {Node} props.children - children component
 * @param {object} props.externalFieldConfig - field config
 * @param {Array} props.externalData - external data
 * @param {Array} props.previousExternalData - previous external data
 * @returns {CollectionWrapper}
 */
function CollectionWrapper({
  name, children, externalFieldConfig, externalData, previousExternalData,
}) {
  const {
    managedApplicationTemplate: {
      fieldsConfig,
      initialFormData,
    },
    previousInitialFormData,
    withChanges,
  } = useApplicationToPdfContext();

  const collectionData = externalData || initialFormData[name] || [];
  const previousCollectionData = previousExternalData || previousInitialFormData[name] || [];

  const getIndexForPreviousRowData = (setUuid) => previousCollectionData.findIndex((item) => item.set_uuid === setUuid);

  const removedCollectionData = previousCollectionData
    ?.filter((item) => !collectionData.some(({ set_uuid: setUuid }) => item.set_uuid === setUuid)).map((item) => ({
      ...item,
      status: PDF_ROW_STATUS.removed,
    }));

  const collectionDataWithNewItemsHighlighted = collectionData.map((item) => {
    if (!previousCollectionData.some(({ set_uuid: setUuid }) => setUuid === item.set_uuid)) {
      return {
        ...item,
        status: PDF_ROW_STATUS.added,
      };
    }

    return {
      ...item,
      status: null,
    };
  });

  const fieldConfig = useMemo(() => Object.values(externalFieldConfig).length !== 0
    || getFieldConfig(fieldsConfig, name), [name]);

  const callbackPayload = useMemo(() => ({
    collectionName: name,
    collectionData: withChanges ? [...collectionDataWithNewItemsHighlighted, ...removedCollectionData] : collectionData,
    previousCollectionData,
    getIndexForPreviousRowData,
  }), [fieldConfig, name]);

  const ConfigWrapper = useCallback((callback) => callback(callbackPayload), [name]);

  if (!fieldConfig || callbackPayload.collectionData.length === 0) {
    return '';
  }

  return (
    <View style={collectionStyle.collectionWrapper}>
      {(fieldConfig?.label || fieldConfig?.title) && (
        <Text style={printStyle.sectionTitle}>
          {`Tabela - ${fieldConfig?.label || fieldConfig?.title}`}
        </Text>
      )}
      {ConfigWrapper(children)}
    </View>
  );
}

export default CollectionWrapper;

CollectionWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  externalFieldConfig: PropTypes.objectOf(Object),
  externalData: PropTypes.arrayOf(Object),
  previousExternalData: PropTypes.arrayOf(Object),
};

CollectionWrapper.defaultProps = {
  externalFieldConfig: {},
  externalData: null,
  previousExternalData: null,
};
