import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantInnovation pdf element.
 *
 * @returns {ApplicantInnovationPdf}
 */
function ApplicantInnovationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Wnioskodawca ponosił nakłady na działalność badawczo-rozwojową w ciągu ostatnich
       trzech lat obrotowych przed złożeniem wniosku o dofinansowanie */}
        <RowField name="applicant_innovation_is_applicant_incurred_operating_expenses" />
        {/* Opis doświadczenia wnioskodawcy w prowadzeniu działalności B+R */}
        <RowField name="applicant_innovation_applicant_experience_in_conducting_rd_activities" isSingleRow />
        {/* Wnioskodawca wdrożył innowacje produktowe lub w procesach biznesowych w ciągu ostatnich
       trzech lat obrotowych przed złożeniem wniosku o dofinansowanie */}
        <RowField name="applicant_innovation_applicant_implemented_product_innovations" />
        {/* Opis doświadczenia wnioskodawcy we wdrażaniu innowacji produktowej lub procesowej */}
        <RowField name="applicant_innovation_applicant_experience_in_implemented_product_innovations" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ApplicantInnovationPdf;
