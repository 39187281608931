import { TextField } from '@mui/material';
import {
  isBefore,
  isValid,
  parseISO,
} from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { DICTIONARIES } from '../../../_constants';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Project general information element renderer.
 *
 * @returns {ProjectGeneralInformation}
 */
export function ProjectGeneralInformation() {
  const { isReadonly } = useElementContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.projectAreas,
    DICTIONARIES.projectImplementationRegions,
    DICTIONARIES.launchPlatforms,
    DICTIONARIES.projectGeneralInformationCompetitionCategory
  );
  const { setValue } = useFormContext();

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Tytuł projektu */}
      <FormField name="project_title">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis projektu */}
      <FormField name="project_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Grupy docelowe */}
      <FormField name="target_groups">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data rozpoczęcia realizacji projektu */}
      <FormField name="project_start_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            value={value ? new Date(value) : value}
            onChange={onChange}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={(event) => {
              onBlur(event);
              if (value) {
                const date = new Date(value);
                date.setHours(8);

                setValue(name, date);
              }
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data zakończenia realizacji projektu */}
      <FormField
        name="project_finish_date"
        rules={{
          validate: {
            isBeforeDate: (value, formData) => isBefore(formData.project_start_date, isValid(value)
              ? value : parseISO(value)) || !isValid(value)
            || 'Wartość wprowadzona w polu "Data zakończenia realizacji projektu" nie może być wczesniejsza, '
            + 'niż wartość wprowadzona w polu "Data rozpoczęcia realizacji projektu"',
          },
        }}
      >
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value ? new Date(value) : value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={(event) => {
              onBlur(event);
              if (value) {
                const date = new Date(value);
                date.setHours(23);

                setValue(name, date);
              }
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Dziedzina projektu */}
      <FormField name="project_area">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            multiple
            renderSelectAllButton={false}
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.projectAreas.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Numer projektu w ramach programu DIGITAL/DEP */}
      <FormField name="project_number_digital_dep">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data podpisania umowy z programu DIGITAL/DEP */}
      <FormField name="signing_the_contract_date_digital_dep">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value ? new Date(value) : value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Planowana data podpisania umowy z programu DIGITAL/DEP */}
      <FormField name="planned_signing_the_contract_date_digital_dep">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value ? new Date(value) : value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Porównanie z zakresu projektów finansowanych z programów FENG oraz DIGITAL */}
      <FormField name="projects_financed_from_the_feng_and_digital_programs_comparison">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodnośc z zakresem naboru */}
      <FormField name="recruitment_scope_compliance">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Obszar realizacji przedsięwzięcia */}
      <FormField name="project_implementation_regions">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            renderSelectAllButton={false}
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.projectImplementationRegions.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      {/* Platforma startowa */}
      <FormField name="launch_platform">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            renderSelectAllButton={false}
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.launchPlatforms.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Inny program inkubacji, jaki? */}
      <FormField name="other_incubation_program">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nazwa produktu */}
      <FormField name="project_general_information_product_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Krótki opis produktu */}
      <FormField name="project_general_information_short_product_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Kategoria konkursu */}
      <FormField name="project_general_information_competition_category">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            renderSelectAllButton={false}
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.projectGeneralInformationCompetitionCategory.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
