import { Link } from 'react-router-dom';
import logoGov from '../images/wezel_krajowy_2.png';

/**
 * Login by login.gov.pl logo component.
 *
 * @returns {LoginGovLogo}
 */
export default function LoginGovLogo() {
  return (
    <div>
      <Link to="">
        <img
          src={logoGov}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
          alt="login.gov.pl"
        />
      </Link>
    </div>
  );
}
