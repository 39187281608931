import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';

/**
 * Applicant experience in acceleration programs pdf element.
 *
 * @returns {ApplicantExperienceInImplementationAccelerationProgramsPdf}
 */
function ApplicantExperienceInImplementationAccelerationProgramsPdf() {
  return (
    <CollectionWrapper name="applicant_experience_in_implementation_acceleration_programs">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.name`}
                value={item.name}
                oldValue={previousRowData?.name}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.short_description`}
                value={item.short_description}
                oldValue={previousRowData?.short_description}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.start_date`}
                value={item.start_date}
                oldValue={previousRowData?.start_date}
              />
              <RowField
                name={`${collectionName}.${index}.end_date`}
                value={item.end_date}
                oldValue={previousRowData?.end_date}
              />
              <RowField
                name={`${collectionName}.${index}.funding_sources`}
                value={item.funding_sources}
                oldValue={previousRowData?.funding_sources}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.key_partners`}
                value={item.key_partners}
                oldValue={previousRowData?.key_partners}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.activities_effect`}
                value={item.activities_effect}
                oldValue={previousRowData?.activities_effect}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.two_selected_cases_description`}
                value={item.two_selected_cases_description}
                oldValue={previousRowData?.two_selected_cases_description}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.program_budget_amount`}
                value={item.program_budget_amount}
                oldValue={previousRowData?.program_budget_amount}
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default ApplicantExperienceInImplementationAccelerationProgramsPdf;
