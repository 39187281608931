import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Cancelable message dialog
 *
 * @param {object} props - root props
 * @returns {ConfirmDialog}
 */
export function ConfirmDialog(props) {
  const {
    dialogId,
    open,
    children,
    closeHandler,
    dialogTitle,
    cancelButtonLabel,
    cancelButtonColor,
    confirmButtonLabel,
    confirmButtonDisabled,
    confirmButtonColor,
    confirmButtonHandler,
    maxWidth,
    fullWidth,
    color,
  } = props;

  return (
    <Dialog
      onClose={closeHandler}
      aria-labelledby={dialogId}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      variant="outlined"
      color={color}
    >
      <DialogTitle id={dialogId} component="h1">{dialogTitle}</DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={confirmButtonHandler}
          color={confirmButtonColor}
          disabled={confirmButtonDisabled}
        >
          {confirmButtonLabel}
        </Button>
        <Button
          variant="outlined"
          onClick={closeHandler}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus
          color={cancelButtonColor}
        >
          {cancelButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  dialogId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  cancelButtonLabel: PropTypes.string,
  cancelButtonColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit', 'error']),
  confirmButtonLabel: PropTypes.string,
  confirmButtonColor: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit', 'error']),
  confirmButtonHandler: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool,
  confirmButtonDisabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

ConfirmDialog.defaultProps = {
  dialogId: 'dialog',
  dialogTitle: 'Potwierdź wykonanie akcji',
  cancelButtonLabel: 'anuluj',
  cancelButtonColor: 'secondary',
  confirmButtonLabel: 'zatwierdź',
  confirmButtonColor: 'secondary',
  confirmButtonDisabled: false,
  children: null,
  maxWidth: false,
  fullWidth: false,
  color: 'secondary',
};
