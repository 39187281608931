import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
} from '@mui/material';
import { theme } from '../styles/theme';

/**
 * Default simple variant.
 *
 * @param {Node} children - provider children elements
 * @param {string} title - title of the container
 * @param {string} textAlign - one of 'center', 'left', 'right'
 * @returns {Element}
 */
function DefaultVariant(children, title, textAlign) {
  return (
    <div>
      <Typography
        display="flex"
        textAlign={textAlign}
        variant="pageHeading"
        width="100%"
        height="4rem"
        color={theme.palette.text.brand}
        mt={0.5}
        mb={2}
        px={2}
        sx={{
          backgroundColor: theme.palette.brandGray20.main,
        }}
      >
        {title}
      </Typography>
      <div>
        {children}
      </div>
    </div>
  );
}

/**
 * Slim variant with divider.
 *
 * @param {Node} children - provider children elements
 * @param {string} title - title of the container
 * @param {string} textAlign - one of 'center', 'left', 'right'
 * @returns {Element}
 */
function SlimVariant(children, title, textAlign) {
  return (
    <div>
      <Divider
        sx={{
          borderTop: `2px solid ${theme.palette.secondary.main}`,
          backgroundColor: theme.palette.secondary.main,
        }}
        color="secondary"
      />
      <Typography
        textAlign={textAlign}
        variant="pageHeading"
        mb={2}
        mt={0.5}
        sx={{
          backgroundColor: '#e4e7e9',
          padding: theme.spacing(1, 1, 0.5, 2),
        }}
      >
        {title}
      </Typography>
      <div>
        {children}
      </div>
    </div>
  );
}

/**
 * Slim blue variant without divider.
 *
 * @param {Node} children - provider children elements
 * @param {string} title - title of the container
 * @param {string} textAlign - one of 'center', 'left', 'right'
 * @returns {Element}
 */
function SlimBlueVariant(children, title, textAlign) {
  return (
    <div>
      <Typography
        textAlign={textAlign}
        variant="pageHeading"
        color="#fff"
        fontSize="1.3rem"
        pl={2}
        fontFamily="Montserrat-Light,sans-serif"
        sx={{ backgroundColor: theme.palette.secondary.main }}
      >
        {title}
      </Typography>
      <div>
        {children}
      </div>
    </div>
  );
}

/**
 * Customized children container.
 *
 * @param {object} props - root props
 * @param {string|Node|Element} props.title - title string or element appended to container
 * @param {Array} props.children - container children
 * @param {string} props.variant - style variant
 * @param {string} props.id - root element ID
 * @param {string} props.textAlign - text align
 * @returns {TitledContainer} - box with title
 */
export function TitledContainer({
  title,
  children,
  variant,
  id,
  textAlign,
}) {
  const componentArgs = [children, title, textAlign];

  const map = {
    default: () => DefaultVariant(...componentArgs),
    slim: () => SlimVariant(...componentArgs),
    'slim-blue': () => SlimBlueVariant(...componentArgs),
  };

  return (
    <section
      style={{
        marginBottom: theme.spacing(4),
        width: '100%',
      }}
      id={id}
    >
      {map[variant]()}
    </section>
  );
}

TitledContainer.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
  variant: PropTypes.oneOf(['default', 'slim', 'slim-blue']),
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  id: PropTypes.string,

};

TitledContainer.defaultProps = {
  children: null,
  variant: 'default',
  id: undefined,
  textAlign: 'center',
};
