import PropTypes from 'prop-types';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { convertStorageUnit } from '../../../_helpers';
import { CustomAutocomplete } from '../../../Features/CustomAutocomplete/CustomAutocomplete';

/**
 * Attachments field component.
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - current field data
 * @returns {AttachmentsField}
 */
export function AttachmentsField({
  fieldData,
}) {
  const {
    control,
  } = useFormContext();
  const [modules, setModules] = useState([]);
  const applicationConfig = useMemo(() => ({
    fileUploadMaxSize: 30000000,
    fileUploadExtensions: [
      'xls',
      'xlsx',
      'doc',
      'docx',
      'zip',
      'rar',
      '7z',
      'txt',
      'rtf',
      'pdf',
      'jpeg',
      'png',
      'bmp',
      'gif',
      'tiff',
      'tif',
      'jpg',
      'ppt',
      'pptx',
      'xades',
      'pades',
      'sig',
      'cades',
      'xml',
      'odt',
      'ods',
      'odp',
      'odg',
      'odf',
      'xlsm',
    ],
  }), [fieldData]);

  /**
   * Loads data from API.
   *
   */
  const loadApiData = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.modules);
    if (statusSuccess) {
      setModules(payload);
    }
  };

  /**
   * Fetch modules from API.
   */
  useEffect(() => {
    loadApiData();
  }, [fieldData]);

  /**
   * Recalculate given megabytes value to bytes.
   *
   * @param {Event} props - event
   * @param {object} props.target - event target
   * @param {string} props.target.name - input name
   * @param {string} props.target.value - input value
   * @returns {object}
   */
  const handleFilesizeChange = ({ target: {
    name, value,
  } }) => {
    const valueInBytes = convertStorageUnit.megabytesToBytes(value);
    const limitExceeded = valueInBytes > applicationConfig.fileUploadMaxSize;

    const formDataValue = limitExceeded ? applicationConfig.fileUploadMaxSize : valueInBytes;

    return {
      target: {
        name,
        value: formDataValue,
      },
    };
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <Controller
          name="minAttachments"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={(event) => {
                onChange({
                  target: {
                    name,
                    value: Number(event.target.value),
                  },
                });
              }}
              defaultValue={value}
              variant="outlined"
              label="Liczba minimalna"
              required
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="maxAttachments"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={(event) => {
                onChange({
                  target: {
                    name,
                    value: Number(event.target.value),
                  },
                });
              }}
              defaultValue={value}
              variant="outlined"
              label="Liczba makskymanlna"
              required
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="modules"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                onChange={onChange}
                initialValue={value}
                onBlur={onBlur}
                textFieldProps={{
                  label: 'Moduł',
                }}
                multiple
                options={modules}
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="acceptedFileExtensions"
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <CustomAutocomplete
                id={name}
                onChange={onChange}
                initialValue={value}
                onBlur={onBlur}
                textFieldProps={{
                  label: 'Dozwolone typy plików',
                }}
                multiple
                options={applicationConfig.fileUploadExtensions.map((extension) => ({
                  '@id': extension,
                  name: extension,
                }))}
              />
              {!!error && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Controller
          name="maxFilesize"
          rules={{
            validate: (value) => value > 0 || 'Wartość musi być większa od zera.',
          }}
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              name={name}
              onChange={(event) => onChange(handleFilesizeChange(event))}
              defaultValue={Math.round(convertStorageUnit.bytesToMegabytes(value))}
              variant="outlined"
              label="Maksymalna wielkość pliku"
              required
              onBlur={onBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    MB
                  </InputAdornment>
                ),
              }}
              inputProps={{
                max: convertStorageUnit.bytesToMegabytes(applicationConfig.fileUploadMaxSize),
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}

AttachmentsField.propTypes = {
  fieldData: PropTypes.shape({
    minAttachments: PropTypes.number,
    maxAttachments: PropTypes.number,
    module: PropTypes.instanceOf(Object),
    acceptedFileExtensions: PropTypes.arrayOf(PropTypes.string),
    maxFilesize: PropTypes.number,
  }).isRequired,
};
