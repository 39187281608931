/**
 * Payment recommendation statuses.
 *
 * @type {object}
 */
export const PAYMENT_RECOMMENDATION_STATUSES = {
  editing: 'editing',
  editingBySupervisor: 'editing_by_supervisor',
  sent: 'sent',
  approved: 'approved',
  approvedNoFunds: 'approved_no_funds',
  correcting: 'correcting',
};

/**
 * Payment recommendation statuses translations.
 *
 * @type {object}
 */
export const PAYMENT_RECOMMENDATION_STATUSES_TRANSLATIONS = {
  [PAYMENT_RECOMMENDATION_STATUSES.editing]: 'W edycji (w trakcie analizy)',
  [PAYMENT_RECOMMENDATION_STATUSES.editingBySupervisor]: 'W edycji (wysłany do przełożonego)',
  [PAYMENT_RECOMMENDATION_STATUSES.sent]: 'Przesłany do DFK',
  [PAYMENT_RECOMMENDATION_STATUSES.approved]: 'Zatwierdzony przez DFK',
  [PAYMENT_RECOMMENDATION_STATUSES.approvedNoFunds]: 'Zatwierdzony przez DFK – brak środków',
  [PAYMENT_RECOMMENDATION_STATUSES.correcting]: 'Cofnięty przez DFK',
};

/**
 * Payment recommendation transition statuses.
 *
 * @type {object}
 */
export const PAYMENT_RECOMMENDATION_TRANSITION_STATUSES = {
  send: 'send',
  forwardToSupervisor: 'forward_to_supervisor',
  approve: 'approve',
  approveNoFunds: 'approve_no_funds',
  cancelApproval: 'cancel_approval',
  correct: 'correct',
};
