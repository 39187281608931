import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Clear } from '@mui/icons-material';
import {
  useEffect,
  useState,
} from 'react';

/**
 * Text filter for MUI Data Grid.
 *
 * @param {object} props - root props
 * @param {object} props.columnData - grid column data
 * @param {Function} props.applyFilter - apply filter function
 * @param {number} props.cleanListener - on change this number, input value should be restored to default
 * @param {string} props.id - id forwarded to input component
 * @param {string} props.initialValue - initial value
 * @returns {GridTextFilter}
 */
export function GridTextFilter({
  columnData, applyFilter, cleanListener, id, initialValue,
}) {
  const { field } = columnData;
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (cleanListener !== 0) {
      setInputValue('');
    }
  }, [cleanListener]);

  /**
   * Handle input change.
   *
   * @param {string} value - input value
   */
  const handleChange = (value) => {
    setInputValue(value);
    applyFilter(field, value);
  };

  return (
    <TextField
      sx={{
        width: '90%',
      }}
      id={id}
      onChange={({ target: { value } }) => handleChange(value)}
      variant="standard"
      placeholder="Szukaj"
      value={inputValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {inputValue.length > 0 && (
              <IconButton onClick={() => handleChange('')}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

GridTextFilter.propTypes = {
  id: PropTypes.string.isRequired,
  columnData: PropTypes.instanceOf(Object).isRequired,
  applyFilter: PropTypes.func.isRequired,
  cleanListener: PropTypes.number.isRequired,
  initialValue: PropTypes.string,
};

GridTextFilter.defaultProps = {
  initialValue: '',
};
