import {
  Button,
  FormControl,
  Grid,
  TextField,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { darken } from '@mui/material/styles';
import { GoBack } from '../GoBack';
import { request } from '../../_services';
import {
  API_ROUTE,
  URL,
  SUPPORTED_PURPOSES,
  REGEX,
} from '../../_constants';
import { Captcha } from '../../_security';
import { useGlobalContext } from '../../Context';
import { handleError } from '../../_helpers';
import { theme } from '../../styles/theme';

const classes = {
  passwordReminderPaper: {
    backgroundColor: '#f2f2f2',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(16),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  passwordReminderBtn: {
    marginTop: theme.spacing(4),
    fontSize: '1.3em',
    lineHeight: '3rem',
    textTransform: 'inherit',
    borderWidth: '3px',
    borderColor: darken(theme.palette.primary.main, 0.25),
    '&:hover': {
      borderWidth: '3px',
      borderColor: darken(theme.palette.primary.main, 0.25),
    },
  },
};

/**
 * Change a password component
 *
 * @returns {PasswordReminder}
 */
export function PasswordReminder() {
  const [data, setData] = useState({});
  const {
    notify, handleAction,
  } = useGlobalContext();
  const navigate = useNavigate();

  const {
    control, handleSubmit, setError, getValues, formState,
  } = useForm({
    defaultValues: {
      email: '',
      login: '',
      reCaptcha: null,
    },
    mode: 'all',
  });

  const {
    isDirty, isValid,
  } = formState;

  const onSubmit = async (formValues) => {
    handleAction({ type: 'ON_REQUEST_SEND' });
    const values = {
      ...formValues,
      purpose: SUPPORTED_PURPOSES.passwordChangeByUserPurpose,
    };

    const {
      payload, violations,
    } = await request.post(API_ROUTE.remindPassword, values);

    handleAction({ type: 'ON_REQUEST_NOT_SEND' });

    setData({
      ...payload,
      violations,
    });
  };

  useEffect(() => {
    if (data.violations) {
      handleError(setError, data.violations);
    }

    if (data.accepted_at) {
      navigate({
        pathname: URL.users.login,
      });

      const message = `Jeżeli podane dane są prawidłowe, to na adres e-mail: "${getValues().email}"
        zapisany w koncie użytkownika "${getValues().login}"
        została wysłana wiadomość z odnośnikiem do zmiany hasła.`;

      setTimeout(() => {
        notify(message, 'info');
      }, 100);
    }
  }, [data]);

  return (
    <Paper variant="outlined" elevation={0} sx={classes.passwordReminderPaper}>
      <Grid container>
        <Grid item xs={12} mt={2} mb={4} ml={1}>
          <GoBack />
        </Grid>
        <Grid item lg={3} />
        <Grid item xs={12} lg={6}>
          <form style={{ padding: (t) => t.spacing(2) }} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h3" fontSize="2rem" mb={2}>Zmień hasło</Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.brand}
              my={2}
            >
              Link do zmiany hasła wyślemy Ci na adres e-mail podany przy rejestracji
            </Typography>
            <FormControl fullWidth sx={{ mb: (t) => t.spacing(2) }} variant="outlined">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "Wpisz login"',
                  },
                }}
                name="login"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    label="Wpisz login"
                    required
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: (t) => t.spacing(2) }} variant="outlined">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę uzupełnić pole "E-mail"',
                  },
                  pattern: REGEX.email,
                }}
                name="email"
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    id={name}
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    variant="outlined"
                    label="Wpisz e-mail podany przy rejestracji"
                    required
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: (t) => t.spacing(2) }} variant="outlined">
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: 'Proszę zaakceptować reCaptcha',
                  },
                }}
                name="reCaptcha"
                control={control}
                render={({
                  field: {
                    onChange, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <Captcha
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                  />
                )}
              />
            </FormControl>

            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={classes.passwordReminderBtn}
                variant="contained"
                color="primary"
                fullWidth
                data-testid="remindPassword"
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Wyślij
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item lg={3} />
      </Grid>
    </Paper>
  );
}
