import { DataGrid } from '@mui/x-data-grid';
import { MenuItem } from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import uniqid from 'uniqid';
import { ButtonMenu } from '../../ButtonMenu';
import { ConfirmDialog } from '../../../Dialogs';
import { theme } from '../../../styles/theme';
import { formatDateString } from '../../../_helpers';
import {
  API_ROUTE,
  DATE,
} from '../../../_constants';
import {
  useGlobalDialog,
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../Context';
import { request } from '../../../_services';
import { CareerForm } from './Career.form';

/**
 * Expert careers list.
 *
 * @returns {CareersList}
 */
export function CareersList() {
  const [deleteCareerData, setDeleteCareerData] = useState(null);
  const defaultSortModel = [{
    field: 'employedFrom',
    sort: 'desc',
  }];
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const {
    careers, reloadData, isReadonly,
  } = useExpertPersonalDataContext();
  const { render } = useGlobalDialog();
  const { notify } = useGlobalContext();

  const deleteHandler = async (rowData, callback) => {
    const { statusSuccess } = await request.delete(`${API_ROUTE.expertCareers}/${rowData.id}`);

    callback();

    if (statusSuccess) {
      reloadData();
      notify('Usunięto miejsce pracy', 'warning');

      return;
    }

    notify('Wystąpił nieoczekiwany błąd podczas próby usunięcia miejsca pracy', 'error');
  };

  useEffect(() => {
    setSortModel(defaultSortModel);
  }, [careers]);

  /**
   * Returns actions for each row.
   *
   * @param {object} props - root props
   * @param {object} props.row - career data
   * @returns {ButtonMenu[]}
   */
  const getActions = ({ row }) => [
    <ButtonMenu
      buttonTitle="Opcje"
      openButtonId={uniqid()}
      buttonProps={{ color: 'secondary' }}
    >
      <MenuItem
        role="menuitem"
        key={uniqid()}
        onClick={() => render(
          <CareerForm
            reloadData={reloadData}
            isReadonly={isReadonly}
            existingData={row}
          />,
          !isReadonly ? 'Edytuj miejsce pracy' : 'Podgląd miejsca pracy',
        )}
      >
        {!isReadonly ? 'Edytuj' : 'Podgląd'}
      </MenuItem>
      {!isReadonly && (
        <MenuItem
          role="menuitem"
          key={uniqid()}
          onClick={() => setDeleteCareerData(row)}
          sx={{ color: theme.palette.primary.main }}
        >
          Usuń
        </MenuItem>
      )}
    </ButtonMenu>,
  ];

  const columns = [
    {
      field: 'employerName',
      headerName: 'Nazwa pracodawcy',
      flex: 1,
    },
    {
      field: 'position',
      headerName: 'Stanowisko',
      flex: 1,
    },
    {
      field: 'employedFrom',
      headerName: 'Data zatrudnienia od',
      flex: 1,
      valueFormatter: ({ value }) => formatDateString(value, DATE.defaultFormatWithoutTime),
    },
    {
      field: 'employedTo',
      headerName: 'Data zatrudnienia do',
      flex: 1,
      type: 'date',
      valueFormatter: ({ value }) => formatDateString(value, DATE.defaultFormatWithoutTime, 'Obecnie'),
    },
    {
      field: 'actions',
      headerName: 'Akcje',
      type: 'actions',
      width: 100,
      getActions,
    },
  ];

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={columns}
        rows={careers}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        autoHeight
        hideFooter
        disableColumnMenu
      />
      <ConfirmDialog
        dialogTitle="Potwierdź usunięcie miejsca pracy"
        confirmButtonColor="primary"
        confirmButtonLabel="Usuń"
        confirmButtonHandler={() => {
          deleteHandler(deleteCareerData, () => setDeleteCareerData(null));
        }}
        closeHandler={() => setDeleteCareerData(null)}
        open={!!deleteCareerData}
        maxWidth="xl"
        fullWidth
      >
        {deleteCareerData && (
          <DataGrid
            disableVirtualization
            columns={columns.filter(({ type }) => type !== 'actions')}
            rows={[deleteCareerData]}
            autoHeight
            hideFooter
            disableColumnMenu
          />
        )}
      </ConfirmDialog>
    </>
  );
}
