import { DataGrid } from '@mui/x-data-grid';
import { MenuItem } from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import { ButtonMenu } from '../../../ButtonMenu';
import RemoveEducationDialog from './RemoveEducation.dialog';
import {
  useExpertPersonalDataContext,
  useGlobalDialog,
} from '../../../../Context';
import { EducationForm } from './Education.form';

/**
 * Educations list data grid view.
 *
 * @param {object} props - root props
 * @param {Array} props.educationAttachmentsData - education attachments data
 * @returns {EducationsList}
 */
export function EducationsList({ educationAttachmentsData }) {
  const defaultSortModel = [{
    field: 'graduationYear',
    sort: 'desc',
  }];
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const {
    educations, isReadonly, reloadData, selectionHandler,
  } = useExpertPersonalDataContext();
  const { render } = useGlobalDialog();

  useEffect(() => {
    setSortModel(defaultSortModel);
  }, [educations]);

  /**
   * Returns actions for each row.
   *
   * @param {object} props - root props
   * @param {object} props.row - education data
   * @returns {ButtonMenu[]}
   */
  const getActions = ({ row }) => [
    <ButtonMenu
      buttonTitle="Opcje"
      openButtonId={uniqid()}
      buttonProps={{ color: 'secondary' }}
    >
      <MenuItem
        role="menuitem"
        key={uniqid()}
        onClick={() => render(
          <EducationForm
            existingData={row}
            reloadData={reloadData}
            educationAttachmentsData={educationAttachmentsData}
            selectionHandler={selectionHandler}
            isReadonly={isReadonly}
          />,
          !isReadonly ? 'Edytuj wykształcenie' : 'Podgląd wykształcenia',
        )}
      >
        {!isReadonly ? 'Edytuj' : 'Podgląd'}
      </MenuItem>
      {!isReadonly && (
        <RemoveEducationDialog columns={columns} deleteEducationData={row} />
      )}
    </ButtonMenu>,
  ];

  const columns = [
    {
      field: 'universityName',
      headerName: 'Nazwa uczelni',
      flex: 1,
    },
    {
      field: 'universityDepartment',
      headerName: 'Nazwa wydziału',
      flex: 1,
    },
    {
      field: 'graduationYear',
      headerName: 'Rok ukończenia uczelni',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Akcje',
      type: 'actions',
      width: 100,
      getActions,
    },
  ];

  return (
    <DataGrid
      disableVirtualization
      columns={columns}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      rows={educations}
      autoHeight
      hideFooter
      disableColumnMenu
    />
  );
}

EducationsList.propTypes = {
  educationAttachmentsData: PropTypes.arrayOf(Object).isRequired,
};
