import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';

/**
 * Innovator lab program concept
 *
 * @returns {InnovatorLabProgramConcept}
 */
function InnovatorLabProgramConcept() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Struktura programu */}
      <FormField name="innovator_lab_program_concept_program_structure">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Działania w ramach koncepcji */}
      <FormField name="innovator_lab_program_concept_activities_within_concept" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Nabór */}
      <FormField name="innovator_lab_program_concept_recruitment">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Preselekcja */}
      <FormField name="innovator_lab_program_concept_preselection">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Mentoring */}
      <FormField name="innovator_lab_program_concept_mentoring">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Przyjęte metody pracy z pomysłodawcami */}
      <FormField name="innovator_lab_program_concept_working_methods" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Sposoby badania potrzeby pomysłodawców rozpoczynających udział w programie */}
      <FormField name="innovator_lab_program_concept_research_methods">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przyjęte metody i narzędzia mentoringu z uwzględnieniem zidentyfikowanych potrzeb pomysłodawców */}
      <FormField name="innovator_lab_program_concept_mentoring_methods_and_tools">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tryb pracy z pomysłodawcami */}
      <FormField name="innovator_lab_program_concept_working_mode">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Planowane rodzaje wsparcia pomysłodawców wraz z uzasadnieniem w kontekście potrzeb grupy docelowej */}
      <FormField name="innovator_lab_program_concept_types_of_support_plans">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Sposób weryfikacji hipotez biznesowych pomysłodawców w relacji z otoczeniem rynkowym (w tym potencjalnymi
         klientami) oraz rodzaje działań następczych */}
      <FormField name="innovator_lab_program_concept_verifying_credibility_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres pisemnej końcowej informacji zwrotnej udzielanej pomysłodawcom */}
      <FormField name="innovator_lab_program_concept_information_written_scope">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Opis ogólnych założeń koncepcji programu */}
      <FormField name="innovator_lab_program_concept_description_general_program_assumptions_concept" contextHelpLabel>
        {() => null}
      </FormField>
      {/*  Warunki i zasady przerywania uczestnictwa w programie w przypadku braku postępów Pomysłodawcy
       w realizacji opisanych działań i/lub braku zaangażowania Pomysłodawcy */}
      <FormField name="innovator_lab_program_concept_discontinuation_participation_conditions">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Narzędzia i metody badania postępu i prawidłowości prac zaplanowanych wspólnie z Pomysłodawcą
      w tym planowane typy kamieni milowych (śródokresowe i końcowe) */}
      <FormField name="innovator_lab_program_concept_methods_research_progress">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przewidywany zakres i zasady współpracy z Partnerami (jeśli dotyczy) */}
      <FormField name="innovator_lab_program_concept_cooperative_principles">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Sposoby zapewnienia skutecznego wsparcia Pomysłodawców z obszarów całej Polski */}
      <FormField name="innovator_lab_program_concept_ways_effective_support">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Działania informacyjno-promocyjne programu */}
      <FormField name="innovator_lab_program_concept_program_information_and_promotion_activities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Założone rezultaty programu i prawdopodobieństwo ich osiągnięcia */}
      <FormField name="innovator_lab_program_concept_program_results">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Informacje potwierdzające skuteczność zastosowanej koncepcji */}
      <FormField name="innovator_lab_program_concept_effectiveness_confirmation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zidentyfikowane ryzyka oraz działania planowane do podjęcia/podjęte w celu
      ich eliminacji lub minimalizacji prawdopodobieństwa ich wystąpienia */}
      <FormField name="innovator_lab_program_concept_identified_risks">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}

export default InnovatorLabProgramConcept;
