const elementsMap = {
  legalForm: {
    civilPartnerships: {
      id: '/lsi/recruitments/api/legal-entity-types/0065f45d-51dc-4e06-bf5d-70e4fcae30b3',
      label: 'Spółki cywilne prowadzące działalność na podstawie umowy zawartej zgodnie z Kodeksem cywilnym',
    },
    individualBusinessActivity: {
      id: '/lsi/recruitments/api/legal-entity-types/2945b3ad-359e-4c91-be7b-b62f0d818f18',
      label: 'Osoby fizyczne prowadzące działalność gospodarczą',
    },
  },
  domesticIndividualBusinessActivity: {
    id: '/lsi/recruitments/api/property-forms/eb58fef7-3b3c-4f47-b8e2-1006bf6751f3',
    label: 'Krajowe osoby fizyczne',
  },
};

/**
   * Creates options depending on whether an option
   * "civilPartnerships" or "individualBusinessActivity" has been selected
   * for the "CustomAutocomplete" component.
   *
   * @param {Function} filterOptions - function to filter options by selected in config recruitment
   * @param {string} legalFormValue - legal form field value
   * @param {Array} propertyForms - property forms options
   * @returns {Array}
   */
export const getPropertyFormWhetherLegalForm = (filterOptions, legalFormValue, propertyForms) => {
  const selectedOptions = filterOptions(propertyForms);

  if (legalFormValue === elementsMap.legalForm.civilPartnerships.id
    || legalFormValue === elementsMap.legalForm.individualBusinessActivity.id) {
    return selectedOptions.filter(({ '@id': id }) => id === elementsMap.domesticIndividualBusinessActivity.id);
  }

  return selectedOptions;
};
