import {
  useForm,
  Controller,
} from 'react-hook-form';
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AttachmentsBox } from '../../FileUpload';
import { request } from '../../../_services';
import {
  API_ROUTE,
  URL,
} from '../../../_constants';
import { Alert } from '../../Alert';
import { SaveCancelButtons } from '../../SaveCancelButtons';
import { ConfirmDialog } from '../../../Dialogs';
import CharsCounter from '../../CharsCounter';
import { useGlobalContext } from '../../../Context';
import { handleError } from '../../../_helpers';

/**
 * Form for application withdrawal.
 *
 * @param {object} props - root props
 * @param {object} props.applicationData - application data
 * @param {boolean} props.hasActiveWithdrawal - has any active withdrawal
 * @returns {ApplicationWithdrawalForm}
 */
export function ApplicationWithdrawalForm({
  applicationData,
  hasActiveWithdrawal,
}) {
  const navigate = useNavigate();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const saveButtonRef = useRef({
    reset: () => {
    },
  });
  const { notify } = useGlobalContext();

  const {
    control, handleSubmit, formState, setError,
  } = useForm({
    defaultValues: {
      description: '',
      attachments: [],
      application: applicationData['@id'],
    },
    mode: 'all',
  });
  const { isValid } = formState;

  const submit = async (payload) => {
    setFormSubmitting(true);

    const {
      statusSuccess, violations = [],
    } = await request.post(
      API_ROUTE.applicationWithdrawals,
      payload
    );

    if (statusSuccess) {
      navigate(URL.application.myApplications);

      setTimeout(() => {
        notify(
          `Prośba o wycofanie wniosku o numerze ${applicationData.applicationNumber} z oceny została przesłana do PARP. Status wniosku na Wycofany zostanie zmieniony po akceptacji PARP.`,
          'success'
        );
      }, 100);

      return;
    }

    handleError(setError, violations);

    saveButtonRef.current.reset();
    setOpenConfirmDialog(false);
    setFormSubmitting(false);
  };

  useEffect(() => {
    if (hasActiveWithdrawal) {
      setError('application', {
        type: 'manual',
        message: 'Dla tego wniosku został już złożony wniosek o wycofanie wniosku o dofinansowanie.',
      });
    }
  }, []);

  return (
    <Grid
      container
      aria-colcount={2}
      spacing={2}
    >
      <Grid item xs={4}>
        <InputLabel htmlFor="description" variant="noShorten">
          Uzasadnienie/powody wycofania wniosku
        </InputLabel>
      </Grid>
      <Grid item xs={8}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Uzupełnij pole "Uzasadnienie/powody wycofania wniosku"',
              },
            }}
            render={({
              field: {
                value, name, onChange,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                multiline
                error={!!error}
                helperText={error?.message}
                id={name}
                name={name}
                fullWidth
                value={value}
                variant="outlined"
                onChange={onChange}
                disabled={hasActiveWithdrawal}
                InputProps={{
                  endAdornment: (<CharsCounter valueLength={value.length} maxLength={1000} />),
                }}
                inputProps={{ maxLength: 1000 }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ mb: (theme) => theme.spacing(3) }}>
        <Typography
          color="secondary"
          sx={{
            fontWeight: 700,
            fontSize: '1.3rem',
            mb: (theme) => theme.spacing(2),
          }}
        >
          Załączniki
        </Typography>
        <InputLabel htmlFor="attachments" variant="noShorten">
          Pismo uzasadniające wycofanie wniosku
        </InputLabel>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="attachments"
          control={control}
          rules={{
            validate: {
              atLeastOne: (val) => val?.length > 0
                || 'Wniosek o wycofanie wniosku musi zawierać przynajmniej jeden załącznik',
            },
          }}
          render={({
            field: {
              value, name, onChange,
            },
            fieldState: {
              error,
            },
          }) => (
            <>
              <AttachmentsBox
                id={name}
                minAttachments={1}
                maxAttachments={3}
                maxSize={15000000}
                allowedExtensions={[
                  'xls',
                  'xlsx',
                  'doc',
                  'docx',
                  'zip',
                  '7z',
                  'pdf',
                  'jpeg',
                  'png',
                  'jpg',
                  'xades',
                  'cades',
                  'pades',
                  'xml',
                ]}
                uploadedFilesCallback={(uploadedFiles) => {
                  onChange({
                    target: {
                      name,
                      value: uploadedFiles.map(({ iri }) => iri),
                    },
                  });
                }}
                existingFiles={value}
                isReadonly={hasActiveWithdrawal}
                fetchUrl={API_ROUTE.applicationWithdrawalsAttachments}
              />
              {!!error && (
                <Alert severity="error">
                  {error?.message}
                </Alert>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="application"
          control={control}
          render={({
            fieldState: {
              error,
            },
          }) => (
            <>
              {!!error && (
                <Alert severity="error">{error?.message}</Alert>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ConfirmDialog
          closeHandler={() => setOpenConfirmDialog(false)}
          open={openConfirmDialog}
          confirmButtonHandler={handleSubmit(submit)}
          dialogTitle="Wycofanie wniosku o dofinansowanie"
          confirmButtonDisabled={formSubmitting || !isValid}
          confirmButtonLabel="Tak"
          cancelButtonLabel="Nie"
        >
          Czy na pewno wycofać wniosek o dofinansowanie z naboru?
        </ConfirmDialog>
        <SaveCancelButtons
          variant="center"
          saveDisabled={formSubmitting || !isValid || openConfirmDialog || hasActiveWithdrawal}
          cancelHandler={() => navigate(URL.application.myApplications)}
          saveTitle="Wycofaj wniosek"
          saveHandler={() => setOpenConfirmDialog(true)}
        />
      </Grid>
    </Grid>
  );
}

ApplicationWithdrawalForm.propTypes = {
  applicationData: PropTypes.instanceOf(Object).isRequired,
  hasActiveWithdrawal: PropTypes.bool,
};

ApplicationWithdrawalForm.defaultProps = {
  hasActiveWithdrawal: false,
};
