import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../_constants';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
  iriToId,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * PromotionalActivities element renderer.
 *
 * @returns {PromotionalActivities}
 */
export function PromotionalActivities() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    setValue, watch,
  } = useFormContext();
  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.promotionalActivitiesEconomicSector,
    DICTIONARIES.promotionalActivitiesType,
    DICTIONARIES.countries,
    DICTIONARIES.promotionalActivitiesEconomicSectorsEvent,
  );

  const { promotionalActivitiesType } = ELEMENTS_DICTIONARIES_MAP;

  const getOptions = () => {
    const economicSectorsValue = watch('promotional_activities_economic_sectors');

    const economicSectorsEvents = get(DICTIONARIES.promotionalActivitiesEconomicSectorsEvent.name)
      .filter((
        { promotionalActivitiesEconomicSectorId }
      ) => promotionalActivitiesEconomicSectorId === iriToId(economicSectorsValue));

    return economicSectorsEvents;
  };
  const noOptionsText = getOptions().length === 0
    ? `Uzupełnij pole ${fieldsConfig.promotional_activities_economic_sectors.label}` : 'Brak opcji';

  const collectionName = 'promotional_activities_selected_promotional_activities';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const selectedPromotionalActivitiesConfig = fieldsConfig[collectionName];
  const subfields = selectedPromotionalActivitiesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = selectedPromotionalActivitiesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Sektor gospodarki, którego dotyczy projekt */}
      <FormField name="promotional_activities_economic_sectors">
        {({
          name, onChange, value, error, onBlur, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            renderSelectAllButton={false}
            options={filterOptions(get(DICTIONARIES?.promotionalActivitiesEconomicSector?.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Tabela Wybrane działania promocyjne, w tym wydarzenia targowe kluczowe dla
      wskazanego sektora gospodarki, w których wnioskodawca weźmie udział w roli wystawcy i/lub zwiedzającego */}
      <LabeledFieldRow fieldConfig={selectedPromotionalActivitiesConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Typ działania */}
            <FormField name={`${collectionName}.${index}.activities_type`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.promotionalActivitiesType.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Nazwa wydarzenia targowego */}
            {(watch(`${collectionName}.${index}.activities_type`) === promotionalActivitiesType.exhibitorFairs.id)
              ? (
                <FormField name={`${collectionName}.${index}.trade_fair_event_name`}>
                  {({
                    name, onChange, value, error, onBlur, filterOptions,
                  }) => (
                    <CustomAutocomplete
                      id={name}
                      initialValue={value}
                      noOptionsText={noOptionsText}
                      onChange={onChange}
                      onBlur={onBlur}
                      renderSelectAllButton={false}
                      options={filterOptions(getOptions())}
                      disabled={isReadonly}
                      error={error}
                    />
                  )}
                </FormField>
              ) : (
                <FormField name={`${collectionName}.${index}.trade_fair_event_activity_name`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
            {/* Kraj wydarzenia targowego */}
            <FormField name={`${collectionName}.${index}.trade_fair_event_country`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  renderSelectAllButton={false}
                  options={filterOptions(get(DICTIONARIES.countries.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Termin (rok) */}
            <FormField name={`${collectionName}.${index}.year_deadline`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  views={['year']}
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  format="yyyy"
                  placeholder="rrrr"
                  mask="____"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Adres oficjalnej strony internetowej wydarzenia branżowego (jeśli dotyczy) */}
            <FormField name={`${collectionName}.${index}.web_page`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Stoisko narodowe na wydarzeniu branżowym */}
            <FormField name={`${collectionName}.${index}.national_stand_at_industry_event`}>
              {({
                name, onChange, value, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="HhWVMreTRlvKFsA"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Rynki docelowe, na terenie których będą prowadzone działania promocyjne przewidziane w
      projekcie (kraj, region) */}
      <FormField name="promotional_activities_target_markets">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
