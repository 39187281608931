import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import uniqid from 'uniqid';
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { request } from '../../../../_services';
import { API_ROUTE } from '../../../../_constants';
import {
  useExpertPersonalDataContext,
  useGlobalContext,
} from '../../../../Context';
import { ConfirmDialog } from '../../../../Dialogs';
import { theme } from '../../../../styles/theme';

/**
 * RemoveEducationDialog element.
 *
 * @param {object} props - root props
 * @param {object} props.deleteEducationData - delete education data
 * @param {Array} props.columns - data grid columns
 * @returns {RemoveEducationDialog}
 */
function RemoveEducationDialog({
  deleteEducationData, columns,
}) {
  const [isOpen, setOpen] = useState(false);
  const {
    reloadData,
  } = useExpertPersonalDataContext();
  const { notify } = useGlobalContext();
  const handleDelete = async () => {
    const { statusSuccess } = await request.delete(`${API_ROUTE.expertEducations}/${deleteEducationData.id}`);

    setOpen(false);
    if (statusSuccess) {
      reloadData();
      notify('Usunięto wpis wykształcenia', 'warning');

      return;
    }

    notify('Wystąpił nieoczekiwany błąd podczas próby usunięcia wpisu', 'error');
  };

  return (
    <>
      <MenuItem
        role="menuitem"
        key={uniqid()}
        onClick={() => setOpen(true)}
        sx={{ color: theme.palette.primary.main }}
      >
        Usuń
      </MenuItem>
      <ConfirmDialog
        dialogTitle="Potwierdź usunięcie wpisu wykształcenia"
        confirmButtonColor="primary"
        confirmButtonLabel="Usuń"
        confirmButtonHandler={handleDelete}
        closeHandler={() => setOpen(false)}
        open={isOpen}
        maxWidth="xl"
        fullWidth
      >
        {deleteEducationData && (
          <DataGrid
            disableVirtualization
            columns={columns.filter(({ type }) => type !== 'actions')}
            rows={[deleteEducationData]}
            autoHeight
            hideFooter
            disableColumnMenu
          />
        )}
      </ConfirmDialog>
    </>
  );
}

export default RemoveEducationDialog;

RemoveEducationDialog.propTypes = {
  deleteEducationData: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.arrayOf(Object).isRequired,
};
