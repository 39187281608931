import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { useApplicationToPdfContext } from '../../../../Context';
import { ELEMENTS_DICTIONARIES_MAP } from '../../../../_constants';

/**
 * MarketDemandAndRdProfitabilityPdf element.
 *
 * @returns {MarketDemandAndRdProfitabilityPdf}
 */
function MarketDemandAndRdProfitabilityPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  const { innovationTypes } = ELEMENTS_DICTIONARIES_MAP;

  const isProductInnovationsValue = initialFormData?.rd_module_essence_innovation_type?.includes(
    innovationTypes.productInnovation.id
  );
  const isInnovationInTheBusinessProcessValue = initialFormData?.rd_module_essence_innovation_type?.includes(
    innovationTypes.innovationInTheBusinessProcess.id
  );

  return (
    <>
      {(isProductInnovationsValue || isInnovationInTheBusinessProcessValue) && (
        <View style={printStyle.table}>
          {(isProductInnovationsValue || isInnovationInTheBusinessProcessValue) && (
            <RowField name="market_demand_and_rd_profitability_competitiveness" />
          )}
          {isProductInnovationsValue && (
            <>
              <RowField name="market_demand_and_rd_profitability_product_innovation" isSingleRow />
              <RowField name="market_demand_and_rd_profitability_applicant_offer" isSingleRow />
              <RowField name="market_demand_and_rd_profitability_competition_offer" isSingleRow />
              <RowField name="market_demand_and_rd_profitability_target_market" isSingleRow />
              <RowField name="market_demand_and_rd_profitability_launching_plan" isSingleRow />
            </>
          )}
          {isInnovationInTheBusinessProcessValue && (
            <>
              <RowField name="market_demand_and_rd_profitability_process_innovation" isSingleRow />
              <RowField name="market_demand_and_rd_profitability_innovation_influence" />
              <RowField name="market_demand_and_rd_profitability_launching_profits" />
              <RowField name="market_demand_and_rd_profitability_implementation_plan" />
            </>
          )}
          {(isProductInnovationsValue || isInnovationInTheBusinessProcessValue) && (
            <RowField name="market_demand_and_rd_profitability_implementation_profitability" isSingleRow />
          )}
        </View>
      )}
    </>
  );
}

export default MarketDemandAndRdProfitabilityPdf;
