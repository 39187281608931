import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * ApplicantExperienceInInnovativeBusinessIdeasDevelopment element renderer.
 *
 * @returns {ApplicantExperienceInInnovativeBusinessIdeasDevelopment}
 */
export function ApplicantExperienceInInnovativeBusinessIdeasDevelopment() {
  const {
    control, setValue,
  } = useFormContext();

  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'applicant_experience_in_innovative_business_ideas_development',
  });

  const config = fieldsConfig.applicant_experience_in_innovative_business_ideas_development;
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <>
      {/* Tabela " Doświadczenie wnioskodawcy w rozwoju innowacyjnych pomysłów biznesowych" */}
      <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'applicant_experience_in_innovative_business_ideas_development',
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Nazwa programu */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.program_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Krótki opis i cel programu */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.short_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Daty rozpoczęcia programu */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.start_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Daty zakończenia programu */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.end_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  onChange={onChange}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  ariaLabel={label}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nazwy i charakterystyka partnerów współpracujących w ramach zrealizowanego programu (jeśli dotyczy) */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.name_and_characteristics_partners`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Uzyskane efekty */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.effects_obtained`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Opis działań na rzecz co najmniej dwóch wspieranych pomysłów/ startupów */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.activities_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Kwota całkowitego budżetu programu wraz ze źródłami finansowania */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.total_rogram_budget_amount`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Liczba wspartych pomysłów/ startupów */}
            <FormField name={`applicant_experience_in_innovative_business_ideas_development.${index}.ideas_supported_number`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="YIol1f7R6CzF5Mg"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </>
  );
}
