import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ClusterCoordinatorPotential Pdf element.
 *
 * @returns {ClusterCoordinatorPotentialPdf}
 */
function ClusterCoordinatorPotentialPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="cluster_coordinator_potential_technical_and_human_resources" isSingleRow />
        <RowField name="cluster_coordinator_potential_sources_and_method_financing" isSingleRow />
        <RowField name="cluster_coordinator_potential_key_risks" isSingleRow />
        <RowField name="cluster_coordinator_potential_capacity_development" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ClusterCoordinatorPotentialPdf;
