import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  useElementContext,
  useApplicationContext,
} from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementersAndApplicantName,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * Essence of the module - competence component
 *
 * @returns {CompetencesModuleEssence}
 */
function CompetencesModuleEssence() {
  const { id: applicationId } = useParams();
  const {
    control, getValues, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const { application } = useApplicationContext();

  const modules = [...application.obligatoryModules, ...application.facultativeModules];
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.compentenceModuleEssenceScope,
    DICTIONARIES.compentenceModuleEssenceType,
    DICTIONARIES.compentenceModuleEssenceTargetGroup,
    DICTIONARIES.modules,
  );

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'competences_module_essence_forms',
  });

  const competencesModuleEssenceFormConfig = fieldsConfig.competences_module_essence_forms;
  const subfields = competencesModuleEssenceFormConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = competencesModuleEssenceFormConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Cel modułu */}
      <FormField name="competences_module_essence_module_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nazwa modułu, którego dotyczy obszar rozwoju kompetencji */}
      <FormField name="competences_module_essence_module_name">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.modules.name))}
            multiple
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Zakres realizacji modułu – obszary, w których planowane jest pozyskanie/ rozwój/ doskonalenie kompetencji */}
      <FormField name="competences_module_essence_scope">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.compentenceModuleEssenceScope.name))}
            multiple
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie zakresu realizacji modułu */}
      <FormField name="competences_module_essence_reason">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Wykaz planowanych form wsparcia w obszarze rozwoju kompetencji" */}
      <LabeledFieldRow fieldConfig={competencesModuleEssenceFormConfig} contextHelpLabel fullWidth>
        {fields.map((item, index) => (
          <CollectionFieldContainer
            key={item.id}
            isReadonly={isReadonly}
            itemIndex={index}
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              'contact_persons',
              index,
            )}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Rodzaj planowanej formy wsparcia */}
            <FormField name={`competences_module_essence_forms.${index}.support_type`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.compentenceModuleEssenceType.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis/zakres planowanej formy wsparcia */}
            <FormField name={`competences_module_essence_forms.${index}.support_scope`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Nazwa wnioskodawcy lub konsorcjanta objętego wsparciem */}
            <FormField name={`competences_module_essence_forms.${index}.implementer`}>
              {({
                name, onChange, value, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementersAndApplicantName(getValues, applicationId)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Grupa docelowa objęta wsparciem */}
            <FormField name={`competences_module_essence_forms.${index}.target_group`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.compentenceModuleEssenceTargetGroup.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="n8jyn0LGnCb7bCg"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Moduł obligatoryjny stanowiący podstawę do wyliczenia limitu kosztów kwalifikowalnych w ramach modułu */}
      <FormField name="competences_module_essence_obligatory_module">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(modules)}
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}

export default CompetencesModuleEssence;
