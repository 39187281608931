import uniqid from 'uniqid';

/**
 * Set a new index for set_number key in collection item
 *
 * @param {string} collectionName - collection field name
 * @param {number} index - item index
 * @param {Function} setValue - provide this function from hook useForm - react-hook-form
 * @param {Array} fields - fields list from hook useFieldArray
 */
export const setNumberToCollectionField = (collectionName, index, setValue, fields) => {
  setValue(collectionName, fields
    .filter((_, filterIndex) => filterIndex !== index)
    .map((field, currentIndex) => ({
      ...field,
      set_number: currentIndex + 1,
    })));
};

/**
 * Get single collection item
 *
 * @param {object} subfields - subfields
 * @param {Array} fields - fields list from hook useFieldArray
 * @param {number} index - index of current CollectionField
 * @returns {object}
 */
export const getCollectionFieldObject = (subfields, fields, index) => (subfields
  ? Object.keys(subfields).reduce((prev, key) => ({
    ...prev,
    [key]: subfields[key].defaultValue,
    set_number: index || fields.length + 1,
    set_uuid: uniqid(),
  }), {}) : {});

/**
 * Get field config
 *
 * @param {object} fieldsConfig - field config
 * @param {string} name - field name
 * @returns {object | undefined}
 */
export const getFieldConfig = (fieldsConfig, name) => {
  const fieldNameParts = name.split('.');

  const subfield = (rootName, subfieldName, secondSubfieldName) => {
    if (!fieldsConfig[rootName].fields) {
      return undefined;
    }

    if (!fieldsConfig[rootName].fields[subfieldName]) {
      return undefined;
    }

    if (fieldsConfig[rootName].fields[subfieldName]?.type === 'collection') {
      return {
        ...fieldsConfig[rootName].fields[subfieldName]?.fields[secondSubfieldName],
        name,
      };
    }

    return {
      ...fieldsConfig[rootName].fields[subfieldName],
      name,
    };
  };

  if (fieldNameParts.length === 1) {
    return fieldsConfig[name];
  }

  if (fieldNameParts.length === 2) {
    return subfield(fieldNameParts[0], fieldNameParts[1]);
  }

  if (fieldNameParts.length === 5) {
    return subfield(fieldNameParts[0], fieldNameParts[2], fieldNameParts[4]);
  }

  return subfield(fieldNameParts[0], fieldNameParts[2]);
};

/**
 * Set multiple initial value for CustomAutocomplete
 *
 * @param {any} value - current field value
 * @param {Array} options - autocomplete selection options
 * @returns {Array}
 */
export const getMultipleInitialValue = (value, options) => {
  if (Array.isArray(value)) {
    return options.filter(({ id: optionId }) => value.includes(optionId));
  }

  if (value.length > 0) {
    return [options.find(({ id: optionId }) => value === optionId)];
  }

  return [];
};

/**
 * Set single initial value for CustomAutocomplete
 *
 * @param {any} value - current field value
 * @param {Array} options - autocomplete selection options
 * @returns {string | number | null}
 */
export const getSingleInitialValue = (value, options) => {
  if (!Array.isArray(value)) {
    return options.find(({ id: optionId }) => value === optionId);
  }

  if (value.length > 0) {
    return options.find(({ id: optionId }) => value[0] === optionId);
  }

  return null;
};

/**
 * Get handle change object for CustomAutocomplete with array initial value
 *
 * @param {object[]} selectedValue - selected field value
 * @param {string} fieldName - field name
 * @returns {object}
 */
export const getMultipleObjectForHandleChange = (selectedValue, fieldName) => ({
  target: {
    name: fieldName,
    value: selectedValue.map(({ id }) => id),
  },
});

/**
 * Get handle change object for CustomAutocomplete with single initial value
 *
 * @param {object[]} selectedValue - selected field value
 * @param {string} fieldName - field name
 * @returns {object}
 */
export const getSingleObjectForHandleChange = (selectedValue, fieldName) => ({
  target: {
    name: fieldName,
    value: selectedValue?.id ?? null,
  },
});

/**
 * Adds item to collection.
 *
 * @param {Array} fields - fields
 * @param {number} maxRowNumber - max row number
 * @param {Function} append - function to append item
 * @param {object} fieldObject - object of field
 */
export const addItem = (fields, maxRowNumber, append, fieldObject) => {
  if (fields.length + 1 > maxRowNumber) {
    return;
  }

  append(fieldObject);
};

/**
   * Removes item from collection.
   *
   * @param {Array} fields - fields
   * @param {number} minRowNumber - min row number
   * @param {Function} remove - function to remove item
   * @param {Function} setValue - function to set value
   * @param {string} collectionFieldName - name of collection field
   * @param {number} index - field index
   */
export const removeItem = (fields, minRowNumber, remove, setValue, collectionFieldName, index) => {
  if (fields.length - 1 < minRowNumber) {
    return;
  }

  remove(index);
  setNumberToCollectionField(
    collectionFieldName,
    index,
    setValue,
    fields,
  );
};
