import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import uniqid from 'uniqid';

/**
 * Single field violations
 *
 * @param {object} props - root props
 * @param {string} props.label - field label
 * @param {Array} props.violations - violations
 * @returns {SingleField}
 */
function SingleField({
  label, violations,
}) {
  if (violations.length === 0) {
    return '';
  }

  return (
    <Box>
      {label && (
        <Typography variant="h5" fontFamily="Montserrat-Light, sans-serif" fontWeight="bold" mb={2}>
          {`Pole "${label}":`}
        </Typography>
      )}
      <Box pl={2}>
        <List>
          {violations.map((violation) => (
            <ListItem key={uniqid()} sx={{ borderLeft: (t) => `2px solid ${t.palette.primary.main}` }}>
              {violation}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default SingleField;

SingleField.propTypes = {
  label: PropTypes.string,
  violations: PropTypes.arrayOf(Object).isRequired,
};

SingleField.defaultProps = {
  label: '',
};
