import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ApplicantExperienceInInnovativeBusinessIdeasDevelopment Pdf element.
 *
 * @returns {ApplicantExperienceInInnovativeBusinessIdeasDevelopmentPdf}
 */
function ApplicantExperienceInInnovativeBusinessIdeasDevelopmentPdf() {
  return (
    <View style={printStyle.table}>
      <CollectionWrapper name="applicant_experience_in_innovative_business_ideas_development">
        {({
          collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
        }) => collectionData.map((item, index) => {
          const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

          return (
            <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
              <View style={printStyle.table}>
                <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                  <RowField
                    name={`${collectionName}.${index}.program_name`}
                    value={item.program_name}
                    oldValue={previousRowData?.program_name}
                  />
                  <RowField
                    name={`${collectionName}.${index}.short_description`}
                    value={item.short_description}
                    oldValue={previousRowData?.short_description}
                  />
                  <RowField
                    name={`${collectionName}.${index}.start_date`}
                    value={item.start_date}
                    oldValue={previousRowData?.start_date}
                  />
                  <RowField
                    name={`${collectionName}.${index}.end_date`}
                    value={item.end_date}
                    oldValue={previousRowData?.end_date}
                  />
                  <RowField
                    name={`${collectionName}.${index}.name_and_characteristics_partners`}
                    value={item.name_and_characteristics_partners}
                    oldValue={previousRowData?.name_and_characteristics_partners}
                  />

                  <RowField
                    name={`${collectionName}.${index}.effects_obtained`}
                    value={item.effects_obtained}
                    oldValue={previousRowData?.effects_obtained}
                  />
                  <RowField
                    name={`${collectionName}.${index}.activities_description`}
                    value={item.activities_description}
                    oldValue={previousRowData?.activities_description}
                  />
                  <RowField
                    name={`${collectionName}.${index}.total_rogram_budget_amount`}
                    value={item.total_rogram_budget_amount}
                    oldValue={previousRowData?.total_rogram_budget_amount}
                  />
                  <RowField
                    name={`${collectionName}.${index}.ideas_supported_number`}
                    value={item.ideas_supported_number}
                    oldValue={previousRowData?.ideas_supported_number}
                  />
                </FieldSorter>
              </View>
            </SingleCollectionWrapper>
          );
        })}
      </CollectionWrapper>
    </View>
  );
}

export default ApplicantExperienceInInnovativeBusinessIdeasDevelopmentPdf;
