import { theme } from './theme';

export const homeCardsStyleClasses = {
  cards: {
    backgroundColor: '#f8f7f7',
    padding: '48px 0',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  card: {
    cursor: 'pointer',
    minWidth: '150px',
    borderBottom: '4px solid #0c7492',
    borderTop: '2px solid #b5b2b1',
    borderLeft: '2px solid #b5b2b1',
    borderRight: '2px solid #b5b2b1',
    boxShadow: '0 0 10px rgb(0 0 0 / 20%)',
    margin: theme.spacing(2),
    marginTop: '0 !important',
    // [theme.breakpoints.down('lg')]: {
    //   marginTop: '20px !important',
    // },
  },
  cardNameBlock: {
    width: '100%',
  },
  cardName: {
    fontFamily: '"Montserrat-Light",sans-serif',
    fontSize: '1.75em',
    color: '#000',
  },
  cardReportNameBlock: {
    width: '100%',
  },
  cardReportName: {
    fontFamily: '"Montserrat-Light",sans-serif',
    fontSize: '1em',
    color: '#000',
  },
  cardIcon: {
    marginTop: theme.spacing(4),
    width: '80px',
    height: '60px',
    fill: '#0c7492',
  },
  sectionHeading: {
    backgroundColor: '#f7f7f7',
    marginBottom: theme.spacing(6),
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
  },
  overHeading: {
    borderColor: '#0c7492',
    borderStyle: 'solid',
    borderWidth: '1px',
    marginBottom: '4px',
  },
};
