import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';

/**
 * ApplicantInnovation element renderer.
 *
 * @returns {ApplicantInnovation}
 */
export function ApplicantInnovation() {
  const { isReadonly } = useElementContext();

  return (
    <FieldSorter>
      {/* Wnioskodawca ponosił nakłady na działalność badawczo-rozwojową w ciągu ostatnich
       trzech lat obrotowych przed złożeniem wniosku o dofinansowanie */}
      <FormField name="applicant_innovation_is_applicant_incurred_operating_expenses">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Opis doświadczenia wnioskodawcy w prowadzeniu działalności B+R */}
      <FormField name="applicant_innovation_applicant_experience_in_conducting_rd_activities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wnioskodawca wdrożył innowacje produktowe lub w procesach biznesowych w ciągu ostatnich
       trzech lat obrotowych przed złożeniem wniosku o dofinansowanie */}
      <FormField name="applicant_innovation_applicant_implemented_product_innovations">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Opis doświadczenia wnioskodawcy we wdrażaniu innowacji produktowej lub procesowej */}
      <FormField name="applicant_innovation_applicant_experience_in_implemented_product_innovations">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
