import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/material';
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import OperatorsConfiguratorForm from './OperatorsConfigurator.form';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import { request } from '../../_services';
import { API_ROUTE } from '../../_constants';
import { handleError } from '../../_helpers';
import { useGlobalContext } from '../../Context';
import { ContainerLoader } from '../../Features/Application/Application/ContainerLoader';

/**
 * Operators configurator dialog component
 *
 * @param {object} props - root props
 * @param {Function} props.closeHandler - modal close handler
 * @param {string} props.applicationTemplateId application template id
 * @returns {OperatorsConfiguratorDialog}
 */
export function OperatorsConfiguratorDialog({
  applicationTemplateId, closeHandler,
}) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [operatorsSelection, setOperatorsSelection] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const { notify } = useGlobalContext();

  const form = useForm({
    defaultValues: {
      formName: '',
      instruction: '',
      operatorLabel: '',
      operators: [],
    },
  });

  const {
    handleSubmit, reset, setError,
  } = form;

  useEffect(() => {
    /**
     * Loads api data.
     */
    const loadApiData = async () => {
      const {
        payload, statusSuccess,
      } = await request.get(
        `${API_ROUTE.applicationTemplates}/${applicationTemplateId}/operators-selections`
      );

      const [operatorsSelectionData] = payload;

      if (statusSuccess) {
        if (operatorsSelectionData) {
          setOperatorsSelection(operatorsSelectionData);
          reset({
            formName: operatorsSelectionData.formName,
            instruction: operatorsSelectionData.instruction,
            operatorLabel: operatorsSelectionData.operatorLabel,
            operators: operatorsSelectionData.operators.map(({ '@id': id }) => id),
          });
        }

        setLoaded(true);

        return;
      }

      closeHandler();
      notify(
        'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
        'error'
      );
    };

    if (applicationTemplateId) {
      loadApiData();
    }
  }, [applicationTemplateId]);

  const submitForm = async (formData) => {
    setSubmitting(true);

    const payload = {
      ...formData,
      applicationTemplate: `/lsi/recruitments/api/application-templates/${applicationTemplateId}`,
    };

    const {
      statusSuccess, violations = [],
    } = operatorsSelection
      ? await request.put(`${API_ROUTE.operatorsSelections}/${operatorsSelection?.id}`, payload)
      : await request.post(API_ROUTE.operatorsSelections, payload);
    setSubmitting(false);

    statusSuccess
      ? notify(`Konfiguracja operatorów została ${operatorsSelection ? 'zmieniona' : 'dodana'}`, 'success')
      : notify(`Wystąpił błąd podczas ${operatorsSelection ? 'zmieny' : 'dodawania'} konfiguracji operatorów`, 'error');

    if (statusSuccess) {
      closeHandler();

      return;
    }
    handleError(setError, violations);
  };

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      <FormProvider {...form}>
        <OperatorsConfiguratorForm />
      </FormProvider>
      <Box mt={5} display="flex" justifyContent="center" width="100%">
        <SaveCancelButtons
          cancelHandler={closeHandler}
          cancelButtonId="VBHUr3oDa523w0G"
          saveHandler={handleSubmit(submitForm)}
          saveButtonId="uTT8eG796ccduL3"
          saveDisabled={isSubmitting}
        />
      </Box>
    </>
  );
}

OperatorsConfiguratorDialog.propTypes = {
  applicationTemplateId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
};
