import { theme } from './theme';

export const useApplicationTemplateStyles = {
  container: {
    padding: theme.spacing(1, 2),
    width: '100%',
    marginLeft: 0,
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingBottom: theme.spacing(1),
    },
  },
  collectionFieldContainer: {
    display: 'block',
    border: `1px solid ${theme.palette.brandGray20.main}`,
    padding: theme.spacing(1, 2),
    width: '100%',
    margin: theme.spacing(0, 0, 5, 0),
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    '& .MuiFormLabel-root': {
      transform: 'unset',
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    backgroundColor: '#f3f3f3',
    marginTop: theme.spacing(1.5),
  },
};
