import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';
import { ApplicationValidationForm } from './ApplicationValidation.form';
import { useGlobalDialog } from '../../../Context';

/**
 * Application validation dialog page.
 *
 * @param {object} props - root props
 * @param {string} props.applicationFormDataId - application form data id
 * @returns {ApplicationValidationDialog}
 */
export function ApplicationValidationDialog({
  applicationFormDataId,
}) {
  const [violationsData, setViolationsData] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const { closeAll } = useGlobalDialog();

  useEffect(() => {
    /**
    * Loads apllication violations from API.
    */
    const loadApplicationViolations = async () => {
      const { payload } = await request.get(`${API_ROUTE.applicationForms}/${applicationFormDataId}/validate`);

      setViolationsData([]);
      if (payload.length > 0) {
        setViolationsData(payload);
      }
      setLoaded(true);
    };

    loadApplicationViolations();
  }, []);

  return (
    <ApplicationValidationForm
      violationsData={violationsData}
      closeHandler={closeAll}
      isLoaded={isLoaded}
    />
  );
}

ApplicationValidationDialog.propTypes = {
  applicationFormDataId: PropTypes.string.isRequired,
};
